import { Component, Input, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  Client3pdConfiguration,
  Client3pdTransactionStatusConfiguration,
  ThirdParty,
} from "@deliver-sense-librarian/data-schema";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import * as moment from "moment";
@Component({
  selector: "app-third-party-report-thresholds",
  templateUrl: "./third-party-report-thresholds.component.html",
  styleUrls: ["./third-party-report-thresholds.component.scss"],
})
export class ThirdPartyReportThresholdsComponent implements OnInit {
  @Input() client3pdConfiguration: Client3pdConfiguration;
  @Input() clientThirdParties: ThirdParty[];
  public configForm: FormGroup;
  constructor(
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.setupConfigurationForm();
  }
  setupConfigurationForm() {
    this.configForm = this.fb.group({
      taxVarianceThreshold: new FormControl(
        this.client3pdConfiguration.taxVarianceThreshold
          ? this.client3pdConfiguration.taxVarianceThreshold
          : 500,
        Validators.required
      ),
      salesVarianceThreshold: new FormControl(
        this.client3pdConfiguration.salesVarianceThreshold
          ? this.client3pdConfiguration.salesVarianceThreshold
          : 500,
        Validators.required
      ),
      remittanceVarianceThreshold: new FormControl(
        this.client3pdConfiguration.remittanceVarianceThreshold
          ? this.client3pdConfiguration.remittanceVarianceThreshold
          : 500,
        Validators.required
      ),
      taxRateVarianceThreshold: new FormControl(
        this.client3pdConfiguration.taxRateVarianceThreshold
          ? this.client3pdConfiguration.taxRateVarianceThreshold
          : 2,
        Validators.required
      ),
      posRecordsMfTax: new FormControl(
        this.client3pdConfiguration.posRecordsMfTax
          ? this.client3pdConfiguration.posRecordsMfTax
          : false,
        Validators.required
      ),
      thirdPartyFraudThresholds: new FormArray([]),
      excessiveCountDifferenceSize: new FormControl(
        this.client3pdConfiguration.excessiveCountDifferenceSize
          ? this.client3pdConfiguration.excessiveCountDifferenceSize
          : 4,
        [Validators.required, Validators.max(100), Validators.min(0)]
      ),
    });
    this.setupFraudThresholdsArray();
  }
  private setupFraudThresholdsArray() {
    const fraudThresholdFormArray = this.configForm.get(
      "thirdPartyFraudThresholds"
    ) as FormArray;
    this.client3pdConfiguration.thirdPartyFraudThresholds.forEach(
      (dspFraudThreshold) => {
        fraudThresholdFormArray.push(
          new FormGroup({
            thirdParty: new FormControl(
              dspFraudThreshold.thirdParty,
              Validators.required
            ),
            amount: new FormControl(
              dspFraudThreshold.amount,
              Validators.required
            ),
          })
        );
      }
    );
  }
  public addFormArrayGroup() {
    const fraudThresholdFormArray = this.configForm.get(
      "thirdPartyFraudThresholds"
    ) as FormArray;
    fraudThresholdFormArray.push(
      new FormGroup({
        thirdParty: new FormControl("", Validators.required),
        amount: new FormControl(100, Validators.required),
      })
    );
  }
  removeFormArrayGroup(index) {
    const fraudThresholdFormArray = this.configForm.get(
      "thirdPartyFraudThresholds"
    ) as FormArray;
    fraudThresholdFormArray.removeAt(index);
  }
  public async save() {
    // const client3pdStatusConfigForms = this.clientThirdParties.map(clientThirdParty => clientThirdParty['clientStatusConfigForm'])
    if (this.configForm.valid) {
      const client3pdConfigFormValues = this.configForm.value;
      try {
        this.loadingService.isLoading(true, "Saving 3PD settings", {
          duration: 5000,
        });
        await this.afs
          .doc(`client3pdConfigurations/${this.client3pdConfiguration.id}`)
          .update({
            taxVarianceThreshold:
              client3pdConfigFormValues.taxVarianceThreshold,
            salesVarianceThreshold:
              client3pdConfigFormValues.salesVarianceThreshold,
            taxRateVarianceThreshold:
              client3pdConfigFormValues.taxRateVarianceThreshold,
            remittanceVarianceThreshold:
              client3pdConfigFormValues.remittanceVarianceThreshold,
            posRecordsMfTax: !!client3pdConfigFormValues.posRecordsMfTax,
            excessiveCountDifferenceSize:
              client3pdConfigFormValues.excessiveCountDifferenceSize,
            thirdPartyFraudThresholds:
              client3pdConfigFormValues.thirdPartyFraudThresholds,
            dateUpdated: moment().toDate(),
          });
        this.snackBar.open(
          "Successfully updated your 3PD Reconciliation Configurations.",
          "Dismiss",
          {
            duration: 5000,
          }
        );
        this.loadingService.isLoading(false);
      } catch (e) {
        const errSnackbar = this.snackBar.open(
          "Oops... something went wrong. Please refresh and try again",
          "Refresh Now",
          {
            duration: 5000,
          }
        );
        errSnackbar.onAction().subscribe(() => {
          window.location.reload();
        });
        this.loadingService.isLoading(false);
      }
    } else {
      this.snackBar.open("Please fill out all required fields", "Dismiss", {
        duration: 5000,
      });
    }
  }
}
