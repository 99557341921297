import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ThirdPartyReportAnomalyValidation } from "@deliver-sense-librarian/data-schema";
import { Papa } from "ngx-papaparse";
import * as _ from "lodash";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ConfirmDialogComponent } from "app/dialogs/confirm-dialog/confirm-dialog.component";
import { AfterViewInit } from "@angular/core";
import { ReconciliationReportDatUtility } from "../../../utilities/reconciliation-report-data.utility";
import { BehaviorSubject } from "rxjs";
@Component({
  selector: "app-all-anomalies",
  templateUrl: "./all-anomalies.component.html",
  styleUrls: ["./all-anomalies.component.scss"],
})
export class AllAnomaliesComponent implements OnInit, AfterViewInit {
  @Input() anomalyValidations: BehaviorSubject<
    ThirdPartyReportAnomalyValidation[]
  >;
  @Input() dataUtility: ReconciliationReportDatUtility;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  tableData: MatTableDataSource<any>;
  displayedColumns: string[] = ["location", "thirdParty", "message", "delete"];
  constructor(
    private cdr: ChangeDetectorRef,
    private afs: AngularFirestore,
    private papa: Papa,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}
  ngAfterViewInit() {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
  }
  ngOnInit(): void {
    this.anomalyValidations.subscribe((validations) => {
      validations.forEach((validation) => {
        validation["thirdPartyName"] = this.dataUtility.getThirdPartyName(
          validation.thirdParty
        );
      });
      this.tableData = null;
      setTimeout(() => {
        this.tableData = new MatTableDataSource(validations);
      });
    });
  }

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  async deleteValidation(validation: ThirdPartyReportAnomalyValidation) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Delete Validation",
        message: "Are you sure you want delete this validation?.",
        action: "Yes, Delete.",
      },
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs
          .doc(`thirdPartyReportAnomalyValidations/${validation.id}`)
          .delete();
        this.snackBar.open("Validation deleted successfully!", "Dismiss", {
          duration: 5000,
        });
      }
    });
  }
}
