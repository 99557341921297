import { User } from "@deliver-sense-librarian/data-schema";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { FirebaseAuthService } from "../services/firebase-auth.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subject } from "rxjs";
import { FormControl } from "@angular/forms";
import { Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-sso",
  templateUrl: "./sso.component.html",
  styleUrls: ["./sso.component.scss"],
})
export class SsoComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  public authCode = new FormControl();
  user: User;
  tokenId: string;
  noToken: boolean;
  constructor(
    private authService: FirebaseAuthService,
    private store: Store<any>,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      if (params.tokenId) {
        this.tokenId = params.tokenId;
      } else {
        this.noToken = true;
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  verifyAuth() {
    if (this.authCode.value.length === 6) {
      this.authCode.disable();
      this.authService
        .verifySso(this.authCode.value, this.tokenId)
        .subscribe((sfaResponse) => {
          if (sfaResponse) {
            this.router.navigateByUrl("/app");
          } else {
            this.authCode.enable();
            this.snackBar.open("Code entered is invalid.", "Dismiss", {
              duration: 5000,
            });
          }
        });
    }
  }
}
