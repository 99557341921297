import { HttpClient } from "@angular/common/http";
import { AngularFireAuth } from "@angular/fire/auth";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FirebaseAuthService } from "../../../auth/services/firebase-auth.service";
import { Store } from "@ngrx/store";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import firebase from "firebase/app";
import { AngularFirestore } from "@angular/fire/firestore";
import { User } from "@deliver-sense-librarian/data-schema";
import { Subject } from "rxjs";
import { CustomValidators } from "ng2-validation";
import { FirestoreUtilities } from "../../../utilities/firestore-utilities";
import { AngularFireStorage } from "@angular/fire/storage";
import { takeUntil, first, distinctUntilChanged } from "rxjs/operators";
import { verifyPasswordMatch } from "../../../shared/validators";
import { ConfirmDialogComponent } from "../../../dialogs/confirm-dialog/confirm-dialog.component";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { environment } from "environments/environment";
import { UiState } from "../../../redux/custom-states/uiState/ui-state";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  public user: User;
  public client: User;
  public userForm: FormGroup;
  public storagePath: string;
  public providerData: any;
  private uiState: UiState;
  passwordProvider: string;
  googleProvider: string;
  microsoftProvider: string;
  linkPasswordForm: FormGroup;
  constructor(
    public authService: FirebaseAuthService,
    private afAuth: AngularFireAuth,
    private store: Store<any>,
    private http: HttpClient,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private router: Router,
    private storage: AngularFireStorage,
    private loadingService: LoadingDialogService,
    private afs: AngularFirestore
  ) {}

  ngOnInit() {
    this.store
      .select((store) => store.uiState)
      .pipe(
        distinctUntilChanged((a, b) => {
          return a.authUser === b.authUser;
        })
      )
      .subscribe((uiState$) => {
        if (uiState$.authUser && uiState$.client) {
          this.uiState = uiState$;
          this.client = uiState$.client;
          this.storagePath = `users/${this.uiState.authUser.id}/picture/`;
          this.fetchUserInformation();
          this.checkForAuthProviders();
          this.setupLinkPasswordForm();
        }
      });
  }
  setupLinkPasswordForm() {
    this.linkPasswordForm = this.fb.group(
      {
        email: new FormControl(null, [
          Validators.required,
          CustomValidators.email,
        ]),
        password: new FormControl(null, [
          Validators.required,
          Validators.minLength(8),
        ]),
        confirmPassword: new FormControl(null, Validators.required),
      },
      { validator: verifyPasswordMatch }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  private fetchUserInformation() {
    this.afs
      .doc(`users/${this.uiState.authUser.id}`)
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user$) => {
        this.user = FirestoreUtilities.objectToType(user$);
        this.setupUserForm();
      });
  }
  private setupUserForm() {
    this.userForm = this.fb.group({
      firstName: new FormControl(this.user.firstName, Validators.required),
      lastName: new FormControl(this.user.lastName, Validators.required),
    });
  }
  clearPicture() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Remove Profile Picture",
        message:
          "Are you sure you want to remove your profile picture? Your avatar will now display your initials.",
        action: "Yes, Remove",
      },
    });
    confirmDialog.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await FirestoreUtilities.deleteStorageFile(
          this.user.picturePath,
          this.storage
        );
        await this.afs
          .doc(`users/${this.user.id}`)
          .update({ picturePath: null });
        this.snackBar.open("Picture removed successfully!", "Dismiss", {
          duration: 5000,
        });
      }
    });
  }
  saveUserForm() {
    if (this.userForm.valid) {
      const formValue = this.userForm.value;
      this.afs.doc(`users/${this.user.id}`).update({
        firstName: formValue.firstName,
        lastName: formValue.lastName,
      });
      this.snackBar.open("Successfully updated your profile.", "Dismiss", {
        duration: 5000,
      });
    } else {
      this.snackBar.open("Please complete all required fields", "Dismiss", {
        duration: 5000,
      });
    }
  }

  async resetPassword() {
    await this.authService.resetPassword(this.user.email);
    this.snackBar.open(
      `An email link to reset your password has been sent to ${this.user.email}`,
      "Dismiss",
      {
        duration: 5000,
      }
    );
  }

  async pictureUploaded($event) {
    if ($event.path) {
      if (this.user.picturePath) {
        try {
          await FirestoreUtilities.deleteStorageFile(
            this.user.picturePath,
            this.storage
          );
        } catch (e) {
          this.snackBar.open(
            "Oops... something went wrong. Please refresh the page and try again.",
            "Dismiss",
            {
              duration: 5000,
            }
          );
        }
      }
      await this.afs.doc(`users/${this.user.id}`).update({
        picturePath: $event.path,
      });
      this.snackBar.open("Profile updated successfully!", "Dismiss", {
        duration: 5000,
      });
    }
  }

  async linkProvider(providerName: "microsoft" | "google") {
    const providerTitle = providerName === "microsoft" ? "Microsoft" : "Google";
    try {
      await this.authService.linkProviderAccount(providerName);
      this.snackBar.open(
        `Successfully linked provider. You can now sign into DeliverSense with your ${providerTitle}`,
        "Dismiss",
        { duration: 5000 }
      );
    } catch (e) {
      const message = e.message
        ? e.message
        : `Error linking to ${providerTitle}.`;
      this.snackBar.open(message, "Dismiss", { duration: 5000 });
    }
  }
  private checkForAuthProviders() {
    this.afAuth.user.pipe(takeUntil(this.destroy$)).subscribe((user$) => {
      const providerData = user$.providerData;
      providerData.forEach((provider: firebase.UserInfo) => {
        switch (provider.providerId) {
          case "password":
            this.passwordProvider = provider.providerId;
            break;
          case "google.com":
            this.googleProvider = provider.providerId;
            break;
          case "microsoft.com":
            this.microsoftProvider = provider.providerId;
            break;
        }
      });
    });
  }
  async unlinkAuthProvider(providerId) {
    const providerTitle =
      providerId === "microsoft.com" ? "Microsoft" : "Google";
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Unlink",
        message: `Are you sure you want to unlink your ${providerTitle} account? After unlinking ${providerTitle} you will have to sign back in for security purposes.`,
        action: "Yes, Delete",
      },
    });
    confirmDialog.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.authService.unlinkAuthProvider(providerId);
        this.snackBar.open(
          `Unlinked ${providerTitle} successfully.`,
          "Dismiss",
          { duration: 5000 }
        );
      }
    });
  }

  deleteUserAccount() {
    const confirmRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Delete Account",
        message: `Are you sure you want delete your DeliverSense account? This action cannot be undone, and all settings, data, and access associated with your account will be removed.`,
        action: "Yes, delete my account.",
        typeConfirm: this.user.email,
      },
    });
    confirmRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        const authHeader = await this.authService.getAuthHeader();
        this.loadingService.isLoading(true, `Deleting your account...`);
        const registerUrl = `${environment.apiUrl}users/${this.user.id}`;
        await this.http
          .delete(registerUrl, authHeader)
          .pipe(first())
          .toPromise();
        this.authService.signOut();
        this.router.navigateByUrl("/login");
        this.loadingService.isLoading(false);
      }
    });
  }
}
