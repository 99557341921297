import {
  animate,
  group,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Location } from "@deliver-sense-librarian/data-schema";
import { CanceledTransaction } from "app/components/ds-modules/3pd-reconciliation/reconciliation-sub-reports/canceled-orders-summary/canceled-orders-summary-report.component";
import * as moment from "moment";

export function markFields(form) {
  Object.keys(form.controls).forEach((field) => {
    form.get(field).markAsTouched({ onlySelf: true });
  });
}
export function getTimeFormatted(time) {
  let processedTime = time;
  if (time) {
    let dateTime = time && time["seconds"] ? time.toDate() : null;
    if (dateTime) {
      const hours = moment(dateTime).hour();
      const minutes = moment(dateTime).minutes();
      return `${hours}:${minutes}`;
    }
    return getTimeFromMinutesFromMidnight(processedTime);
  } else {
    return "";
  }
}
export function getTimeFromMinutesFrom(minutesFromMidnight) {
  const hours = Math.floor(minutesFromMidnight / 60);
  const minutes = minutesFromMidnight % 60;
  return `${hours}:${minutes}`;
}
export function getSize(obj) {
  const size = new TextEncoder().encode(JSON.stringify(obj)).length;
  const kiloBytes = size / 1024;
}
export function getTimeFromMinutesFromMidnight(minutesFromMidnight: number) {
  const hours = Math.floor(minutesFromMidnight / 60);
  const adjustedHours = hours == 0 ? 12 : hours > 12 ? hours - 12 : hours;
  const meridian = hours == 0 ? "AM" : hours > 12 ? "PM" : "AM";
  const minutes = minutesFromMidnight % 60;
  return `${adjustedHours}:${minutes} ${meridian}`;
}
export function generateRandomString(length) {
  let text = "";
  const char_list =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    text += char_list.charAt(Math.floor(Math.random() * char_list.length));
  }
  return text;
}
export function downloadDataAsFile(
  exportData,
  exportName = "deliver-sense-export",
  fileType = "csv"
) {
  const dataStr =
    `${
      fileType === "json"
        ? "data:text/json;charset=utf-8,"
        : "data:text;charset=utf-8,"
    }` + encodeURIComponent(exportData);
  const downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute(
    "download",
    exportName + `${fileType === "json" ? ".json" : ".csv"}`
  );
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

export const thirdPartyReportingModule = "vlBJZeknUiMFjkSqj6F6";
export const exemptSalesManagementModule = "WHTZNRSd7qRMiz2nTO84";
export const auditTrailsModule = "ru8bwRnug0X6Q6bLZwHX";

export function getErrorCodeMessage(code) {
  const errorCodeMap = {};
  return errorCodeMap[code] ? errorCodeMap[code] : "Unknown error occurred.";
}

export const tableExpandAnimation = [
  trigger("detailExpand", [
    state("collapsed", style({ height: "0px", minHeight: "0" })),
    state("expanded", style({ height: "*" })),
    transition(
      "expanded <=> collapsed",
      animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
    ),
  ]),
];

export const SlideInOutAnimation = [
  trigger("slideInOut", [
    transition(":enter", [
      style({ transform: "translateX(-100%)" }),
      animate("200ms ease-in", style({ transform: "translateX(0%)" })),
    ]),
    transition(":leave", [
      animate("200ms ease-in", style({ transform: "translateX(200%)" })),
    ]),
  ]),
];
export function getLocationAddress(location: Location) {
  return `${location.addressLine1}${
    location.addressLine2 ? ` ${location.addressLine2}` : ""
  }, ${location.addressCity}, ${location.addressState} ${
    location.addressPostalCode
  }`;
}

export function getCanceledOrderDisputeReason(
  canceledTransaction: CanceledTransaction
) {
  let step1 = "";
  if (canceledTransaction.minutesToCancel < 10) {
    step1 = `Order was prepared by restaurant`;
  } else {
    step1 = `Order was prepared by restaurant within the ${canceledTransaction.minutesToCancel} minutes it took for DoorDash to cancel the order`;
  }
  let step2 = "";
  if (
    canceledTransaction.metadata &&
    canceledTransaction.metadata
      .toLowerCase()
      .indexOf("wrong order handed to dasher") > -1
  ) {
    step2 = `${step1}, and the correct order was passed off to the dasher. Customer receiving incorrect order is dasher's fault and therefore the restaurant is entitled to the payout for the order. `;
  } else {
    step2 = `${step1} and therefore the restaurant is entitled to the payout for the order. `;
  }
  return `${step2} The POS check number of this completed, prepared order is: ${canceledTransaction.posOrderId}.`;
}
export const staticImageLocations = {
  amexLogo:
    "https://firebasestorage.googleapis.com/v0/b/vexed-creative.appspot.com/o/static%2Famex_logo.png?alt=media&token=68517f1c-174c-40b3-ae36-243433c375f3",
  visaLogo:
    "https://firebasestorage.googleapis.com/v0/b/vexed-creative.appspot.com/o/static%2Fvisa.png?alt=media&token=00ee179e-6bb9-4ba2-877e-c198dbb1503a",
  discoverLogo:
    "https://firebasestorage.googleapis.com/v0/b/vexed-professional.appspot.com/o/static%2Fdiscover_logo.jpg?alt=media&token=842f9169-8033-4691-a353-c6f5cc2f3206",
  masterCardLogo:
    "https://firebasestorage.googleapis.com/v0/b/vexed-creative.appspot.com/o/static%2Fmc_logo.png?alt=media&token=369d153f-cff2-4798-821c-6a706b1f28bc",
  imagePlaceholder:
    "https://firebasestorage.googleapis.com/v0/b/vexed-creative.appspot.com/o/static%2Fimage-placeholder.png?alt=media&token=238f185d-cfb9-4f07-8bf3-61b25b9bc007",
  lpSecondImage:
    "https://firebasestorage.googleapis.com/v0/b/vexed-creative.appspot.com/o/static%2Flight_and_dark.jpg?alt=media&token=43fe5c25-7f5f-4de7-97a0-4b075395cfc0",
  lpMainImage:
    "https://firebasestorage.googleapis.com/v0/b/vexed-creative.appspot.com/o/static%2Fvexli_car_hood.jpg?alt=media&token=0bb73ace-1244-4b4a-b0af-f78efca46f10",
  logoLarge:
    "https://firebasestorage.googleapis.com/v0/b/deliver-sense-web-test.appspot.com/o/static%2Flogo-medium.png?alt=media&token=f5d41819-8886-4edc-94dd-79e860d7850d",
  logoLargeWhite:
    "https://firebasestorage.googleapis.com/v0/b/deliver-sense-web-test.appspot.com/o/static%2Flogo-medium-white.png?alt=media&token=bce2639c-bfa1-4552-bdb2-9cc2a68a06b4",
  logoXtraLarge:
    "https://firebasestorage.googleapis.com/v0/b/deliver-sense-web-test.appspot.com/o/static%2Flogo_large.png?alt=media&token=907ba9cf-4d74-4393-a8c8-defaaf9b95bb",
  logoXtraLargeWhite:
    "https://firebasestorage.googleapis.com/v0/b/deliver-sense-web-test.appspot.com/o/static%2Flogo_large_white.png?alt=media&token=e6691f0a-84a4-4ec4-8641-d8c11e7812b8",
  iconOfficial:
    "https://firebasestorage.googleapis.com/v0/b/deliver-sense-web-test.appspot.com/o/static%2Ficon-512x512.png?alt=media&token=9ba2e21d-f664-4a8e-a6f9-6c07f9075d44",
  offeringPlaceholder:
    "https://firebasestorage.googleapis.com/v0/b/prodville-test.appspot.com/o/static%2Foffering-placeholder.png?alt=media&token=49ecba43-627e-4ab5-b7e8-d5855ea2c30d",
  videoPlaceholder:
    "https://firebasestorage.googleapis.com/v0/b/vexed-creative.appspot.com/o/static%2Fvideo-sample-placeholder.png?alt=media&token=c272b052-1235-4087-945f-b6e9008d3e61",
  cookie:
    "https://firebasestorage.googleapis.com/v0/b/vexed-professional-lab.appspot.com/o/static%2Fcookie.png?alt=media&token=a81d25a2-0655-4659-85b8-877669ba7b36",
};

export const placeholderUrl = staticImageLocations.imagePlaceholder;
export const cardImageSources = {
  Visa: staticImageLocations.visaLogo,
  Discover: staticImageLocations.discoverLogo,
  MasterCard: staticImageLocations.masterCardLogo,
  "American Express": staticImageLocations.amexLogo,
};
