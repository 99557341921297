import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Subject, lastValueFrom } from "rxjs";
import { UiState } from "app/redux/custom-states/uiState/ui-state";
import { first, takeUntil } from "rxjs/operators";
import {
  ClientModule,
  DsModuleAddons,
  DsModules,
  ReportGroup,
  ReportGroupTypes,
} from "@deliver-sense-librarian/data-schema";
import * as moment from "moment";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
//@TODO pull this out of 3PD-reports to top leve since it is generic across reporting
@Component({
  selector: "app-new-tpd-report",
  templateUrl: "./new-tpd-report.component.html",
  styleUrls: ["./new-tpd-report.component.scss"],
})
export class NewReportComponent implements OnInit {
  @Input() groups: ReportGroup[] = [];
  @Input() selectedGroup: string;
  @Input() type: ReportGroupTypes;
  @Output() saveCompleted = new EventEmitter();
  private destroy$ = new Subject();
  uiState$: UiState;
  newReportForm: any;
  reportTypeName: string;
  collection: string;
  maxReportLength: number;
  clientThirdPartyReportingModule: ClientModule;
  constructor(
    private store: Store<any>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore
  ) {}

  ngOnInit(): void {
    this.setReportType();
    this.store
      .select((store) => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((uiState$: UiState) => {
        if (uiState$.authUser && uiState$.client) {
          this.uiState$ = uiState$;
          this.setupNewReportForm();
        }
      });
  }
  private setReportType() {
    switch (this.type) {
      case ReportGroupTypes.trendAnalysis:
        this.collection = "trendAnalysisReports";
        this.reportTypeName = "Trend Analysis";
        this.maxReportLength = 6;
        break;
      case ReportGroupTypes.thirdPartyReconciliation:
        this.collection = "thirdPartyReports";
        this.reportTypeName = "3PD Reconciliation";
        this.maxReportLength = 2;
        break;
      case ReportGroupTypes.errorCharge:
        this.collection = "errorChargeReports";
        this.reportTypeName = "Error Charge";
        this.maxReportLength = 3;
        break;
      case ReportGroupTypes.payoutSummary:
        this.collection = "payoutReports";
        this.reportTypeName = "Payout Report";
        this.maxReportLength = 3;
    }
  }
  private setupNewReportForm() {
    this.newReportForm = this.fb.group({
      name: new FormControl("", Validators.required),
      client: new FormControl(this.uiState$.client.id, Validators.required),
      creator: new FormControl(this.uiState$.authUser.id, Validators.required),
      group: new FormControl(
        this.selectedGroup ? this.selectedGroup : "ungrouped",
        Validators.required
      ),
      startDate: new FormControl("", Validators.required),
      endDate: new FormControl("", Validators.required),
    });
  }
  public async save() {
    if (this.newReportForm.valid) {
      const formValues = this.newReportForm.value;
      const date = moment().toDate();
      this.clientThirdPartyReportingModule = FirestoreUtilities.mapToType(
        lastValueFrom(
          await this.afs
            .collection("clientModules", (ref) =>
              ref
                .where("module", "==", DsModules.ThirdPartyDeliveryReporting)
                .where("client", "==", this.uiState$.client.id)
            )
            .snapshotChanges()
            .pipe(first())
        )
      );
      const saveResult = await this.afs.collection(this.collection).add({
        client: formValues.client,
        name: formValues.name,
        creator: formValues.creator,
        group: formValues.group,
        startDate: moment(formValues.startDate)
          .startOf("day")
          .add(4, "hours")
          .toDate(),
        endDate: moment(formValues.endDate).endOf("day").toDate(),
        creatorTimezone: moment().format("Z"),
        dateCreated: date,
        dateUpdated: date,
        includePosInRec: !!this.clientThirdPartyReportingModule?.addons?.find(
          (addon) => addon.moduleAddon === DsModuleAddons["POS Reconciliation"]
        )?.active,
      });
      this.snackBar.open("Successfully created new report", "Dismiss", {
        duration: 5000,
      });
      this.saveCompleted.emit(saveResult.id);
    }
  }

  getReportMinDate() {
    return moment(this.newReportForm.get("startDate").value).toDate();
  }

  getReportMaxDate() {
    return moment(this.newReportForm.get("endDate").value).toDate();
  }

  getMaxEndDate() {
    if (this.newReportForm.get("startDate").valid) {
      return moment(this.newReportForm.get("startDate").value)
        .add(this.maxReportLength, "month")
        .toDate();
    }
  }

  dateMinimum(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }
      const controlDate = moment(this.newReportForm.get("startDate").value);
      if (!controlDate.isValid()) {
        return null;
      }
      const validationDate = moment(date);
      return controlDate.isSameOrBefore(validationDate)
        ? null
        : {
            "date-minimum": {
              "date-minimum": validationDate.toDate(),
              actual: controlDate.toDate(),
            },
          };
    };
  }
}
