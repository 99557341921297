import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import {
  PosSystem,
  ThirdParty,
  ClientPosSystem,
  ClientThirdParty,
  Conversion,
  DataUploadEvent,
  DataUploadEventStatuses,
  DataUploadEventFragment,
  DataUploadEventGroup,
  DataUploadEventTypes,
  CustomReport,
} from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { UiState } from "app/redux/custom-states/uiState/ui-state";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { LoadingDialogService } from "../../../services/loading-dialog.service";
import * as _ from "lodash";
import { InformationDialogComponent } from "app/dialogs/information-dialog/information-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { UploadDocumentService } from "../../../services/upload-document.service";
import * as moment from "moment";
import { Papa } from "ngx-papaparse";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AngularFireStorage } from "@angular/fire/storage";
import { ConfirmDialogComponent } from "app/dialogs/confirm-dialog/confirm-dialog.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-new-data-upload",
  templateUrl: "./new-data-upload.component.html",
  styleUrls: ["./new-data-upload.component.scss"],
})
export class NewDataUploadComponent implements OnInit {
  @Output() uploadCompleteEmitter = new EventEmitter();
  selectedGroup: string;
  groups: DataUploadEventGroup[] = [];
  newUploadFormGroup: FormGroup;
  transactionConversions: Conversion[] = [];
  transactionTypes = [
    DataUploadEventTypes.POS,
    DataUploadEventTypes["3PD"],
    DataUploadEventTypes["Custom Report"],
  ];
  destroy$ = new Subject();
  posSystems: PosSystem[] = [];
  thirdParties: ThirdParty[] = [];
  uiState: UiState;
  storagePath: string;
  expectedFields = [];
  requiredFields: any[];
  file: any;
  fileToUpload: any;
  fileName: string;
  uniqueFileName: string;
  rowCount: any;
  config: PerfectScrollbarConfigInterface = {};
  jsonData: any;
  locationConversion: Conversion;
  customReports: CustomReport[] = [];
  selectedCustomReport: CustomReport;
  showUploadPeriodEnd: boolean;

  constructor(
    private loadingService: LoadingDialogService,
    private fb: FormBuilder,
    private papa: Papa,
    private uploadDocumentService: UploadDocumentService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private store: Store<any>,
    private storage: AngularFireStorage,
    private router: Router,
    private afs: AngularFirestore
  ) {}

  ngOnInit(): void {
    this.store
      .select((store) => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (uiState$: UiState) => {
        if (
          uiState$.authUser &&
          uiState$.client &&
          uiState$.clientPosSystems &&
          uiState$.clientThirdParties
        ) {
          this.uiState = uiState$;
          this.storagePath = `clients/${this.uiState.client.id}/3pd/dataUploads`;
          this.posSystems = <PosSystem[]>(
            this.uiState.clientPosSystems.map(
              (clientPosSystem: ClientPosSystem) => clientPosSystem.posSystem
            )
          );
          this.thirdParties = <ThirdParty[]>(
            this.uiState.clientThirdParties.map(
              (clientThirdParty: ClientThirdParty) =>
                clientThirdParty.thirdParty
            )
          );
          await this.fetchCustomReports();
          await this.fetchUploadGroups();
          this.setupFormGroup();
        }
      });
  }
  private async fetchCustomReports() {
    this.customReports = FirestoreUtilities.mapToType(
      await this.afs
        .collection("customReports")
        .snapshotChanges()
        .pipe(first())
        .toPromise()
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private setupFormGroup() {
    this.newUploadFormGroup = this.fb.group({
      selectedTransactionType: new FormControl("", Validators.required),
      conversionSource: new FormControl("", Validators.required),
      group: new FormControl(this.selectedGroup, Validators.required),
      uploadPeriodEnd: new FormControl("", Validators.required),
      notes: new FormControl(""),
    });
    this.setupListeners();
  }

  private setupListeners() {
    this.newUploadFormGroup
      .get("selectedTransactionType")
      .valueChanges.subscribe(async (transactionType) => {
        this.expectedFields = [];
        if (
          transactionType === DataUploadEventTypes.POS ||
          transactionType === DataUploadEventTypes["3PD"]
        ) {
          this.toggleUploadPeriodEndDateControl(true);
        } else if (transactionType === DataUploadEventTypes["Custom Report"]) {
          this.toggleUploadPeriodEndDateControl(false);
        }
      });
    this.newUploadFormGroup
      .get("conversionSource")
      .valueChanges.subscribe(async (conversionSource) => {
        const transactionType = this.newUploadFormGroup.get(
          "selectedTransactionType"
        ).value;
        this.loadingService.isLoading(true, "Loading expected fields...");
        this.expectedFields = [];
        this.transactionConversions = null;
        setTimeout(async () => {
          try {
            if (transactionType === DataUploadEventTypes.POS) {
              await this.getPosTransactionConversionValues(conversionSource);
            } else if (transactionType === DataUploadEventTypes["3PD"]) {
              await this.getThirdPartyTransactionConversionValues(
                conversionSource
              );
            } else if (
              transactionType === DataUploadEventTypes["Custom Report"]
            ) {
              let parentCustomReport;
              this.selectedCustomReport = this.customReports.find(
                (customReport) => customReport.id === conversionSource
              );
              if (this.selectedCustomReport.customReportParent) {
                parentCustomReport = <CustomReport>(
                  FirestoreUtilities.objectToType(
                    await this.afs
                      .doc(
                        `customReports/${this.selectedCustomReport.customReportParent}`
                      )
                      .snapshotChanges()
                      .pipe(first())
                      .toPromise()
                  )
                );
              }
              this.mapExpectedCustomReportFields(
                parentCustomReport
                  ? parentCustomReport
                  : this.selectedCustomReport
              );
            }
            this.loadingService.isLoading(false);
          } catch (e) {
            this.snackBar.open(
              "Error loading expected fields. Please refresh and try again",
              "Dismiss",
              { duration: 5000 }
            );
            this.loadingService.isLoading(false);
          }
        });
      });
  }
  private toggleUploadPeriodEndDateControl(required) {
    const uploadPeriodEndDateControl =
      this.newUploadFormGroup.get("uploadPeriodEnd");
    if (required) {
      uploadPeriodEndDateControl.setValidators(Validators.required);
      uploadPeriodEndDateControl.updateValueAndValidity();
      this.showUploadPeriodEnd = true;
    } else {
      uploadPeriodEndDateControl.setValidators(null);
      uploadPeriodEndDateControl.updateValueAndValidity();
      this.showUploadPeriodEnd = false;
    }
  }
  private mapExpectedCustomReportFields(customReport: CustomReport) {
    this.transactionConversions = customReport.reportConversionMappings;
    // @TODO this.locationConversion = this.transactionConversions.find(conversion => conversion.keyOut === this.selectedCustomReport.fragmentationField);
    this.locationConversion = this.transactionConversions.find(
      (conversion) => conversion.keyOut === "location"
    );
    this.expectedFields = [];
    this.requiredFields = [];
    this.transactionConversions.forEach((conversion: Conversion) => {
      if (conversion.algorithm && conversion.algorithm[0]) {
        conversion.algorithm[0].fields.forEach((field) => {
          if (
            !this.expectedFields.find(
              (existingField) => existingField === field
            )
          ) {
            this.expectedFields.push(field);
          }
        });
      } else {
        if (
          !this.expectedFields.find(
            (existingField) => existingField === conversion.keyIn
          )
        ) {
          this.expectedFields.push(conversion.keyIn);
        }
      }
      this.requiredFields = this.expectedFields;
    });
  }
  private async getPosTransactionConversionValues(accountId) {
    this.transactionConversions = FirestoreUtilities.mapToType(
      await this.afs
        .collection("clientPosTransactionConversions", (ref) =>
          ref
            .where("client", "==", this.uiState.client.id)
            .where("posSystem", "==", accountId)
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise()
    );
    this.locationConversion = this.transactionConversions.find(
      (conversion) => conversion.keyOut === "location"
    );
    this.mapRequiredFields();
  }

  private getThirdPartyTransactionConversionValues(accountId) {
    const thirdParty = this.thirdParties.find(
      (tp) => tp.id === accountId
    ) as ThirdParty;
    let parentThirdParty = null;
    if (thirdParty.duplicateOf) {
      parentThirdParty = this.thirdParties.find(
        (tp) => tp.id === thirdParty.duplicateOf
      ) as ThirdParty;
    }
    // @TODO pull in clientThirdPartyConversionOverrides to get location override
    this.transactionConversions = parentThirdParty
      ? parentThirdParty.reportConversionMappings
      : thirdParty.reportConversionMappings;
    this.locationConversion = this.transactionConversions.find(
      (conversion) => conversion.keyOut === "location"
    );
    this.mapRequiredFields();
  }

  public isRequiredField(field) {
    return _.includes(this.requiredFields, field);
  }
  public setFileInformation(file) {
    this.file = file;
    this.fileName = file.name;
  }

  private isFileMissingFields(fields) {
    const missingFields = _.differenceWith(
      this.requiredFields,
      fields,
      _.isEqual
    );
    return missingFields;
  }
  private async parseData(csvData) {
    return new Promise((resolve, reject) => {
      this.papa.parse(csvData, {
        header: true,
        worker: true,
        skipEmptyLines: true,
        complete: async (result) => {
          this.jsonData = result.data;
          const missingFields = this.isFileMissingFields(result.meta.fields);
          if (missingFields.length > 0) {
            alert(
              `UPLOAD FAILED: This file is missing the following fields: ${missingFields}`
            );
            reject(true);
          } else {
            this.rowCount = this.jsonData?.length;
            if (this.rowCount >= 50000) {
              const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                  title: "WARNING: LARGE UPLOAD",
                  message: `This has ${this.rowCount} transactions. Are you sure you want to upload?`,
                  action: "Yes, Upload.",
                },
              });
              dialogRef.afterClosed().subscribe(async (confirmed) => {
                if (confirmed) {
                  await this.completeUpload();
                } else {
                  this.snackBar.open("Upload cancelled.", "Dismiss", {
                    duration: 5000,
                  });
                }
                resolve(true);
              });
            } else {
              await this.completeUpload();
              resolve(true);
            }
          }
        },
      });
    });
  }
  private async completeUpload(isCustomReport?: boolean) {
    let rowCounter = 2;
    this.jsonData.forEach((row) => {
      // if (isCustomReport) {
      //   row;
      // } else {
      row.locationId = this.getLocationField(row);
      // }
      row.batchRow = rowCounter;
      rowCounter++;
    });
  }
  getLocationField(row) {
    if (this.locationConversion) {
      return row[this.locationConversion.keyIn];
    }
  }

  private mapRequiredFields() {
    this.expectedFields = [];
    this.requiredFields = [];
    const requiredKeyOuts =
      this.newUploadFormGroup.get("selectedTransactionType").value === "POS"
        ? ["location", "date", "id", "account"]
        : ["location, date, id"];
    this.transactionConversions.forEach((conversion: Conversion) => {
      const isRequiredConversion = _.includes(
        requiredKeyOuts,
        conversion.keyOut
      );
      if (conversion.algorithm && conversion.algorithm[0]) {
        conversion.algorithm[0].fields.forEach((field) => {
          if (
            !this.expectedFields.find(
              (existingField) => existingField === field
            )
          ) {
            this.expectedFields.push(field);
            if (isRequiredConversion) {
              this.requiredFields.push(field);
            }
          }
        });
      } else {
        if (
          !this.expectedFields.find(
            (existingField) => existingField === conversion.keyIn
          )
        ) {
          this.expectedFields.push(conversion.keyIn);
          if (isRequiredConversion) {
            this.requiredFields.push(conversion.keyIn);
          }
        }
      }
    });
  }
  async uploadFile() {
    const file = this.file;
    const lastDot = this.file.name.lastIndexOf(".");
    const ext = this.file.name.substring(lastDot + 1);
    if (file && ext === "csv") {
      this.uniqueFileName = this.afs.createId() + ".json";
      try {
        await this.parseData(this.file);
        await this.fullFileUpload();
        this.loadingService.isLoading(false);
      } catch (e) {
        console.error(e);
        this.loadingService.isLoading(false);
        this.snackBar.open(
          "Oops... something went wrong with the upload. Please check the file and try again.",
          "Dismiss",
          { duration: 5000 }
        );
      } finally {
        this.file = null;
      }
    } else {
      this.transactionConversions = [];
      this.dialog.open(InformationDialogComponent, {
        panelClass: "invisible-panel-dialog",
        data: {
          title: "ERROR: Incorrect File Format",
          message: "The transaction file must be a .csv file",
        },
      });
    }
  }
  async fullFileUpload() {
    const locationGroupedRows = _.groupBy(this.jsonData, "locationId");
    const locationDataFiles = Object.keys(locationGroupedRows).map(
      (locationId) => {
        const rowCount = locationGroupedRows[locationId].length;
        const jsonStr = JSON.stringify(locationGroupedRows[locationId]);
        var blob = new Blob([jsonStr], { type: "application/json" });
        const locationIdFieldId =
          locationId.length > 20 ? locationId.substring(0, 20) : locationId;
        const fileName =
          `${locationIdFieldId}-` + this.afs.createId() + ".json";
        const filePath = `${this.storagePath}/${fileName}`;
        return { filePath, fileName, blob, rowCount };
      }
    );
    const progress = { progress: 0 };
    const progressStep = 95 / locationDataFiles.length;
    const uploadDate = moment().toDate();
    const batchId = this.afs.createId();
    const newUploadEventResult = await this.createDataUploadEvent(
      batchId,
      uploadDate,
      locationDataFiles.length
    );
    this.loadingService.isLoading(true, "Uploading File...", progress);
    const dataUploadEventFragments = await Promise.all(
      locationDataFiles.map(async (locationDataFile) => {
        const uploadResult = await this.uploadDocumentService.uploadSingle(
          locationDataFile.blob,
          locationDataFile.filePath,
          null,
          this.destroy$
        );
        const dataUploadEventFragment = new DataUploadEventFragment();
        dataUploadEventFragment.status = DataUploadEventStatuses.running;
        dataUploadEventFragment.filePath = uploadResult.filePath;
        dataUploadEventFragment.fileName = locationDataFile.fileName;
        dataUploadEventFragment.fileSize = uploadResult.fileSize;
        dataUploadEventFragment.client = this.uiState.client.id;
        dataUploadEventFragment.rowCount = locationDataFile.rowCount;
        dataUploadEventFragment.type =
          this.newUploadFormGroup.value.selectedTransactionType;
        dataUploadEventFragment.account =
          this.newUploadFormGroup.value.conversionSource;
        dataUploadEventFragment.dataUploadEvent = newUploadEventResult.id;
        progress.progress = progress.progress += progressStep;
        return dataUploadEventFragment;
      })
    );
    const dataUploadEventFragmentsChunks = _.chunk(
      dataUploadEventFragments,
      499
    );
    const dataUploadEventFragmentsRef = this.afs.collection(
      "dataUploadEventFragments"
    );
    await Promise.all(
      dataUploadEventFragmentsChunks.map((chunk) => {
        const batch = this.afs.firestore.batch();
        chunk.forEach((dataUploadEventFragment: DataUploadEventFragment) => {
          const newId = this.afs.createId();
          batch.set(
            dataUploadEventFragmentsRef.doc(newId).ref,
            dataUploadEventFragment.toJSONObject()
          );
        });
        return batch.commit();
      })
    );
    if (this.newUploadFormGroup.value.group !== "ungrouped") {
      await this.afs
        .doc(`dataUploadEventGroups/${this.newUploadFormGroup.value.group}`)
        .update({ dateUpdated: moment().toDate() });
    }
    this.loadingService.isLoading(false);
    this.snackBar.open("Data upload process started successfully!", "Dismiss", {
      duration: 5000,
    });
    // this.router.navigate(["/app/data-uploads"]);
    this.uploadCompleteEmitter.emit(true);
  }

  private async createDataUploadEvent(batchId, uploadDate, fragmentsCount) {
    const formGroupValues = this.newUploadFormGroup.value;
    const dataUploadEvent = new DataUploadEvent();
    dataUploadEvent.batchId = batchId;
    dataUploadEvent.user = this.uiState.authUser.id;
    dataUploadEvent.client = this.uiState.client.id;
    dataUploadEvent.rowCount = this.rowCount;
    dataUploadEvent.uploadDate = uploadDate;
    dataUploadEvent.fileName = this.file.name;
    dataUploadEvent.group = formGroupValues.group;
    dataUploadEvent.status = DataUploadEventStatuses.running;
    dataUploadEvent.type = formGroupValues.selectedTransactionType;
    if (dataUploadEvent.type === DataUploadEventTypes["Custom Report"]) {
      dataUploadEvent.customReport = formGroupValues.conversionSource;
    } else {
      dataUploadEvent.account = formGroupValues.conversionSource;
      dataUploadEvent.uploadPeriodEnd = formGroupValues.uploadPeriodEnd;
    }
    dataUploadEvent.notes = formGroupValues.notes
      ? formGroupValues.notes
      : null;
    dataUploadEvent.errorFragments = 0;
    dataUploadEvent.fragments = fragmentsCount;
    dataUploadEvent.committedFragments = 0;
    dataUploadEvent.validatedFragments = 0;
    dataUploadEvent.clearedFragments = 0;
    const createdUploadEvent = await this.afs
      .collection("dataUploadEvents")
      .add(dataUploadEvent.toJSONObject());
    return createdUploadEvent;
  }

  getParentThirdParty(parentId) {
    return this.thirdParties.find((dsp) => dsp.id === parentId);
  }

  private async fetchUploadGroups() {
    this.groups = <DataUploadEventGroup[]>FirestoreUtilities.mapToType(
      await this.afs
        .collection("dataUploadEventGroups", (ref) =>
          ref.where("client", "==", this.uiState.client.id)
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise()
    ).sort((a, b) => {
      return moment(a.dateUpdated.toDate()).isBefore(
        moment(b.dateUpdated.toDate())
      )
        ? 1
        : -1;
    });
    this.groups.push(
      new DataUploadEventGroup({ name: "Un-Grouped", id: "ungrouped" })
    );
    this.selectedGroup = this.groups[0].id;
  }
}
