import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import {
  PosSystem,
  ThirdParty,
  ClientPosSystem,
  ClientThirdParty,
  Conversion,
  DataUploadEvent,
  DataUploadEventStatuses,
  DataUploadEventFragment,
  DataUploadEventGroup,
  DataUploadEventTypes,
  CustomReport,
  ClientUploadControl,
  ClientThirdPartyTransactionConversion,
  ClientCustomReportConversionOverride,
  DataSchema,
  DataSchemaField,
  ClientOtherReport,
  DataUploadEventProgressCounter,
} from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { UiState } from "app/redux/custom-states/uiState/ui-state";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { Subject, combineLatest } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { LoadingDialogService } from "../../../services/loading-dialog.service";
import * as _ from "lodash";
import { MatDialog } from "@angular/material/dialog";
import { UploadDocumentService } from "../../../services/upload-document.service";
import * as moment from "moment";
import { Papa } from "ngx-papaparse";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UploadExpectedFieldsDialogComponent } from "app/dialogs/upload-expected-fields/upload-expected-fields-dialog.component";
import { UploadControlsChecklistDialogComponent } from "app/dialogs/upload-controls-checklist-dialog/upload-controls-checklist-dialog.component";

@Component({
  selector: "app-multiple-data-upload",
  templateUrl: "./multiple-data-upload.component.html",
  styleUrls: ["./multiple-data-upload.component.scss"],
})
export class MultipleDataUploadComponent implements OnInit {
  @Output() uploadCompleteEmitter = new EventEmitter();
  @Input() selectedGroup: string;
  groups: DataUploadEventGroup[] = [];
  newUploadFormGroup: FormGroup;
  transactionTypes = [
    DataUploadEventTypes.POS,
    DataUploadEventTypes["3PD"],
    DataUploadEventTypes["Custom Report"],
    DataUploadEventTypes["Fill In"],
  ];

  destroy$ = new Subject();
  posSystems: PosSystem[] = [];
  thirdParties: ThirdParty[] = [];
  uiState: UiState;
  storagePath: string;
  file: any;
  fileToUpload: any;
  fileName: string;
  uniqueFileName: string;
  rowCount: any;
  config: PerfectScrollbarConfigInterface = {};
  jsonData: any;
  fragmentationConversion: Conversion;
  customReports: CustomReport[] = [];
  files: any[];
  uploadProcessStarted: boolean;
  uploadProcessComplete: boolean;
  clientThirdPartyTransactionConversions: ClientThirdPartyTransactionConversion[] =
    [];
  clientCustomReportConversions: ClientCustomReportConversionOverride[] = [];

  constructor(
    private loadingService: LoadingDialogService,
    private fb: FormBuilder,
    private papa: Papa,
    private uploadDocumentService: UploadDocumentService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private store: Store<any>,
    private afs: AngularFirestore
  ) {}

  ngOnInit(): void {
    this.store
      .select((store) => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (uiState$: UiState) => {
        if (
          uiState$.authUser &&
          uiState$.client &&
          uiState$.clientPosSystems &&
          uiState$.clientThirdParties
        ) {
          this.uiState = uiState$;
          this.storagePath = `clients/${this.uiState.client.id}/3pd/dataUploads`;
          this.posSystems = <PosSystem[]>this.uiState.clientPosSystems
            .filter((clientPosSystem: ClientPosSystem) => {
              return clientPosSystem.active;
            })
            .map(
              (clientPosSystem: ClientPosSystem) => clientPosSystem.posSystem
            );
          this.thirdParties = <ThirdParty[]>(
            this.uiState.clientThirdParties.map(
              (clientThirdParty: ClientThirdParty) =>
                clientThirdParty.thirdParty
            )
          );
          await this.fetchClientConversionOverrides();
          await this.fetchCustomReports();
          await this.fetchUploadGroups();
          // this.setupFormGroup();
        }
      });
  }
  private async fetchClientConversionOverrides() {
    const [
      clientDspConversionOverridesQuery,
      clientCustomReportConversionOverridesQuery,
    ] = await Promise.all([
      this.afs
        .collection("client3pdTransactionConversionOverrides", (ref) =>
          ref.where("client", "==", this.uiState.client.id)
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise(),
      this.afs
        .collection("clientCustomReportConversionOverrides", (ref) =>
          ref.where("client", "==", this.uiState.client.id)
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise(),
    ]);
    this.clientThirdPartyTransactionConversions = FirestoreUtilities.mapToType(
      clientDspConversionOverridesQuery
    );
    this.clientCustomReportConversions = FirestoreUtilities.mapToType(
      clientCustomReportConversionOverridesQuery
    );
  }
  private async fetchCustomReports() {
    const [customReports$, clientOtherReports$] = await Promise.all([
      this.afs
        .collection("customReports")
        .snapshotChanges()
        .pipe(first())
        .toPromise(),
      this.afs
        .collection("clientOtherReports", (ref) =>
          ref.where("client", "==", this.uiState.client.id)
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise(),
    ]);
    const clientOtherReports = <ClientOtherReport[]>(
      FirestoreUtilities.mapToType(clientOtherReports$)
    );
    this.customReports = <CustomReport[]>(
      FirestoreUtilities.mapToType(customReports$).filter((customReport) =>
        clientOtherReports.find(
          (clientOtherReport) =>
            clientOtherReport.customReport === customReport.id
        )
      )
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  resetUpload() {
    this.files = null;
    this.uploadProcessStarted = false;
    this.uploadProcessComplete = false;
  }
  async detectFiles(event) {
    this.files = [];
    if (event.length > 0) {
      Object.keys(event).forEach((key) => this.files.push(event[key]));
    } else {
      for (var i = 0; i < event.target.files.length; i++) {
        this.files.push(event.target.files[i]);
      }
    }
    if (this.files?.length > 0) {
      this.setupFilesUploadForm();
    }
  }
  setupFilesUploadForm() {
    this.newUploadFormGroup = this.fb.group({
      group: new FormControl(this.selectedGroup, Validators.required),
      uploadPeriodEnd: new FormControl("", Validators.required),
      fileGroups: new FormArray([]),
    });
    this.setupFileGroupArray();
  }
  private async setupFileGroupArray() {
    const fileGroupArray = this.getFileGroupArray();
    await Promise.all(
      this.files.map(async (file) => {
        const uploadFormGroup = this.fb.group(
          {
            fileName: new FormControl(file.name, Validators.required),
            selectedTransactionType: new FormControl("", Validators.required),
            conversionSource: new FormControl("", Validators.required),
            notes: new FormControl(""),
            showUploadPeriodEnd: new FormControl(false),
            requiredFields: new FormControl([]),
            expectedFields: new FormControl([]),
            fragmentationConversionKeyIn: new FormControl([]),
            fileData: new FormControl(),
            rowCount: new FormControl(),
            uploadProgress: new FormControl(0),
            uploadControls: new FormArray([]),
          },
          { validators: [CsvFilesOnly("fileName"), MissingFieldsValidator()] }
        );
        await this.parseData(uploadFormGroup, file);
        fileGroupArray.push(uploadFormGroup);
        this.setupListeners(uploadFormGroup);
        return;
      })
    );
  }
  getFileGroupArray() {
    return this.newUploadFormGroup.get("fileGroups") as FormArray;
  }
  removeFileFromGroup(index) {
    this.getFileGroupArray().removeAt(index);
  }
  private setupListeners(uploadFormGroup) {
    // uploadFormGroup
    //   .get("selectedTransactionType")
    //   .valueChanges.subscribe(async (transactionType) => {
    //     const expectedFields = [];
    //     if (
    //       transactionType === DataUploadEventTypes.POS ||
    //       transactionType === DataUploadEventTypes["3PD"]
    //     ) {
    //       this.toggleUploadPeriodEndDateControl(uploadFormGroup, true);
    //       uploadFormGroup.get("showUploadPeriodEnd").patchValue(true);
    //     } else if (transactionType === DataUploadEventTypes["Custom Report"]) {
    //       this.toggleUploadPeriodEndDateControl(uploadFormGroup, false);
    //     }
    //   });
    uploadFormGroup
      .get("conversionSource")
      .valueChanges.subscribe(async (conversionSource) => {
        const transactionType = uploadFormGroup.get(
          "selectedTransactionType"
        ).value;
        this.loadingService.isLoading(true, "Loading expected fields...");

        setTimeout(async () => {
          try {
            if (transactionType === DataUploadEventTypes.POS) {
              await this.getPosTransactionConversionValues(
                uploadFormGroup,
                conversionSource
              );
            } else if (transactionType === DataUploadEventTypes["3PD"]) {
              await this.getThirdPartyTransactionConversionValues(
                uploadFormGroup,
                conversionSource
              );
            } else if (
              transactionType === DataUploadEventTypes["Custom Report"]
            ) {
              let parentCustomReport;
              const selectedCustomReport = this.customReports.find(
                (customReport) => customReport.id === conversionSource
              );
              if (selectedCustomReport.customReportParent) {
                parentCustomReport = <CustomReport>(
                  FirestoreUtilities.objectToType(
                    await this.afs
                      .doc(
                        `customReports/${selectedCustomReport.customReportParent}`
                      )
                      .snapshotChanges()
                      .pipe(first())
                      .toPromise()
                  )
                );
              }

              this.mapExpectedCustomReportFields(
                uploadFormGroup,
                parentCustomReport ? parentCustomReport : selectedCustomReport
              );
            }
            this.loadingService.isLoading(false);
          } catch (e) {
            this.snackBar.open(
              "Error loading expected fields. Please refresh and try again",
              "Dismiss",
              { duration: 5000 }
            );
            this.loadingService.isLoading(false);
          }
        });
      });
  }
  private async mapExpectedCustomReportFields(
    uploadFormGroup: FormGroup,
    customReport: CustomReport
  ) {
    const transactionConversions = customReport.reportConversionMappings;
    const customReportDataSchema = <DataSchema>(
      FirestoreUtilities.objectToType(
        await this.afs
          .doc(`dataSchemas/${customReport.dataSchema}`)
          .snapshotChanges()
          .pipe(first())
          .toPromise()
      )
    );
    this.fragmentationConversion = transactionConversions.find(
      (conversion) =>
        conversion.keyOut === customReportDataSchema.fragmentationField
    );

    const requiredFields = _.flatten(
      customReportDataSchema.fields.map((field) => {
        if (field.required) {
          return transactionConversions.find(
            (conversion) => conversion.keyOut === field.key
          ).algorithm[0].fields;
        }
        return [];
      })
    );
    uploadFormGroup
      .get("fragmentationConversionKeyIn")
      .patchValue(this.fragmentationConversion.keyIn);
    uploadFormGroup.get("requiredFields").patchValue(requiredFields);
  }
  async setUploadControls(uploadFormGroup: FormGroup, sourceId: string) {
    const uploadControlsArray = uploadFormGroup.get(
      "uploadControls"
    ) as FormArray;
    uploadControlsArray.patchValue([]);
    const uploadControls = FirestoreUtilities.mapToType(
      await this.afs
        .collection("clientUploadControls", (ref) =>
          ref
            .where("client", "==", this.uiState.client.id)
            .where("source", "==", sourceId)
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise()
    ) as ClientUploadControl[];
    uploadControls.forEach((uploadControl) => {
      uploadControlsArray.push(
        new FormGroup({
          description: new FormControl(uploadControl.description),
          complete: new FormControl("", Validators.requiredTrue),
        })
      );
    });
    uploadFormGroup.get("uploadControls").updateValueAndValidity();
  }
  private async getPosTransactionConversionValues(uploadFormGroup, accountId) {
    const transactionConversions = FirestoreUtilities.mapToType(
      await this.afs
        .collection("clientPosTransactionConversions", (ref) =>
          ref
            .where("client", "==", this.uiState.client.id)
            .where("posSystem", "==", accountId)
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise()
    );
    this.setUploadControls(uploadFormGroup, accountId);
    this.mapRequiredFields(uploadFormGroup, transactionConversions);
  }

  private getThirdPartyTransactionConversionValues(uploadFormGroup, accountId) {
    const thirdParty = this.thirdParties.find(
      (tp) => tp.id === accountId
    ) as ThirdParty;
    let parentThirdParty = null;
    if (thirdParty.duplicateOf) {
      parentThirdParty = this.thirdParties.find(
        (tp) => tp.id === thirdParty.duplicateOf
      ) as ThirdParty;
    }
    const transactionConversions = parentThirdParty
      ? parentThirdParty.reportConversionMappings
      : thirdParty.reportConversionMappings;
    this.setUploadControls(uploadFormGroup, accountId);

    this.mapRequiredFields(uploadFormGroup, transactionConversions, thirdParty);
  }

  public isRequiredField(requiredFields, field) {
    return _.includes(requiredFields, field);
  }
  public setFileInformation(file) {
    this.file = file;
    this.fileName = file.name;
  }

  private async parseData(uploadFormGroup: FormGroup, csvData) {
    return new Promise((resolve, reject) => {
      this.papa.parse(csvData, {
        header: true,
        worker: true,
        skipEmptyLines: true,
        complete: async (result) => {
          const jsonData = result.data;
          const rowCount = jsonData?.length;
          uploadFormGroup.get("rowCount").patchValue(rowCount);
          uploadFormGroup.get("fileData").patchValue(jsonData);
          resolve(true);
        },
      });
    });
  }
  private async setRowCounts(
    jsonData: any[],
    uploadFormGroup: FormGroup,
    isCustomReport?: boolean
  ) {
    let rowCounter = 2;

    jsonData.forEach((row) => {
      // if (isCustomReport) {
      //   row;
      // } else {
      row.fragmentationKey = this.getFragmentationField(
        row,
        uploadFormGroup.get("fragmentationConversionKeyIn").value
      );
      // }
      row.batchRow = rowCounter;
      rowCounter++;
    });
  }
  getFragmentationField(row, fragmentationConversionKeyIn) {
    if (fragmentationConversionKeyIn) {
      return row[fragmentationConversionKeyIn];
    }
  }

  private mapRequiredFields(
    uploadFormGroup,
    transactionConversions,
    thirdParty?: ThirdParty
  ) {
    const expectedFields = [];
    const requiredFields = [];
    const selectedTransactionType = uploadFormGroup.get(
      "selectedTransactionType"
    ).value;
    const requiredKeyOuts =
      selectedTransactionType === "POS"
        ? ["location", "date", "id", "account"]
        : ["location", "date", "id"];
    let thirdPartyOverrideLocationConvertion;
    if (thirdParty) {
      thirdPartyOverrideLocationConvertion =
        selectedTransactionType === "3PD"
          ? this.clientThirdPartyTransactionConversions.find(
              (conversion) =>
                conversion.thirdParty === thirdParty.id &&
                conversion.keyOut === "location"
            )
          : selectedTransactionType === "Customer Report"
          ? this.clientCustomReportConversions
          : null;
    }
    const locationConversion = thirdPartyOverrideLocationConvertion
      ? thirdPartyOverrideLocationConvertion
      : transactionConversions.find(
          (conversion) => conversion.keyOut === "location"
        );
    transactionConversions.forEach((conversion: Conversion) => {
      const isRequiredConversion = _.includes(
        requiredKeyOuts,
        conversion.keyOut
      );
      if (conversion.algorithm && conversion.algorithm[0]) {
        conversion.algorithm[0].fields.forEach((field) => {
          if (
            !expectedFields.find((existingField) => existingField === field)
          ) {
            expectedFields.push(field);
            if (isRequiredConversion) {
              requiredFields.push(field);
            }
          }
        });
      } else {
        if (
          !expectedFields.find(
            (existingField) => existingField === conversion.keyIn
          )
        ) {
          expectedFields.push(conversion.keyIn);
          if (isRequiredConversion) {
            requiredFields.push(conversion.keyIn);
          }
        }
      }
    });
    uploadFormGroup
      .get("fragmentationConversionKeyIn")
      .patchValue(locationConversion.keyIn);
    uploadFormGroup.get("expectedFields").patchValue(expectedFields);
    uploadFormGroup.get("requiredFields").patchValue(requiredFields);
  }
  async uploadFile(uploadFormGroup: FormGroup) {
    try {
      await this.fullFileUpload(uploadFormGroup);
      this.loadingService.isLoading(false);
    } catch (e) {
      console.error(e);
      this.loadingService.isLoading(false);
      this.snackBar.open(
        "Oops... something went wrong with the upload. Please check the file and try again.",
        "Dismiss",
        { duration: 5000 }
      );
    } finally {
      // this.file = null;
    }
  }
  async commitUploads() {
    if (this.newUploadFormGroup.valid) {
      this.uploadProcessStarted = true;
      const filesToUpload = this.newUploadFormGroup.get(
        "fileGroups"
      ) as FormArray;
      await Promise.all(
        filesToUpload.controls.map((fileFormGroup: FormGroup) => {
          return this.uploadFile(fileFormGroup);
        })
      );
      this.uploadProcessComplete = true;
    } else {
      this.snackBar.open(
        "Please complete all file upload settings.",
        "Dismiss",
        { duration: 5000 }
      );
    }
  }
  async fullFileUpload(uploadFormGroup: FormGroup) {
    const jsonData = uploadFormGroup.get("fileData").value;
    await this.setRowCounts(jsonData, uploadFormGroup);
    const fragmentGroupedRows = _.groupBy(jsonData, "fragmentationKey");
    const fragmentDataFiles = Object.keys(fragmentGroupedRows).map(
      (fragmentationKey) => {
        const rowCount = fragmentGroupedRows[fragmentationKey].length;
        const jsonStr = JSON.stringify(fragmentGroupedRows[fragmentationKey]);
        var blob = new Blob([jsonStr], { type: "application/json" });
        const locationIdFieldId =
          fragmentationKey.length > 20
            ? fragmentationKey.substring(0, 20)
            : fragmentationKey;
        const fileName =
          `${locationIdFieldId}-` + this.afs.createId() + ".json";
        const filePath = `${this.storagePath}/${fileName}`;
        return { filePath, fileName, blob, rowCount };
      }
    );
    let progress = 0;
    const progressStep = 95 / fragmentDataFiles.length;
    const uploadDate = moment().toDate();
    const batchId = this.afs.createId();
    const dataUploadEventId = this.afs.createId();

    // this.loadingService.isLoading(true, "Uploading File...", progress);
    const dataUploadEventFragments = await Promise.all(
      fragmentDataFiles.map(async (locationDataFile) => {
        const uploadResult = await this.uploadDocumentService.uploadSingle(
          locationDataFile.blob,
          locationDataFile.filePath,
          null,
          this.destroy$
        );
        const dataUploadEventFragment = new DataUploadEventFragment();
        dataUploadEventFragment.status = DataUploadEventStatuses.running;
        dataUploadEventFragment.filePath = uploadResult.filePath;
        dataUploadEventFragment.fileName = locationDataFile.fileName;
        dataUploadEventFragment.fileSize = uploadResult.fileSize;
        dataUploadEventFragment.client = this.uiState.client.id;
        dataUploadEventFragment.rowCount = locationDataFile.rowCount;
        dataUploadEventFragment.type =
          uploadFormGroup.value.selectedTransactionType;
        dataUploadEventFragment.account =
          uploadFormGroup.value.conversionSource;
        dataUploadEventFragment.dataUploadEvent = dataUploadEventId;
        progress = progress += progressStep;
        uploadFormGroup.get("uploadProgress").patchValue(progress.toFixed(2));
        return dataUploadEventFragment;
      })
    );
    const counters = await this.createProgressCounters(
      dataUploadEventFragments,
      dataUploadEventId
    );
    await this.createDataUploadEvent(
      uploadFormGroup,
      dataUploadEventId,
      batchId,
      uploadDate,
      fragmentDataFiles.length,
      counters.length
    );
    const dataUploadEventFragmentsChunks = _.chunk(
      dataUploadEventFragments,
      499
    );
    const dataUploadEventFragmentsRef = this.afs.collection(
      "dataUploadEventFragments"
    );
    await Promise.all(
      dataUploadEventFragmentsChunks.map((chunk) => {
        const batch = this.afs.firestore.batch();
        chunk.forEach((dataUploadEventFragment: DataUploadEventFragment) => {
          const newId = this.afs.createId();
          batch.set(
            dataUploadEventFragmentsRef.doc(newId).ref,
            dataUploadEventFragment.toJSONObject()
          );
        });
        return batch.commit();
      })
    );
    if (this.newUploadFormGroup.value.group !== "ungrouped") {
      await this.afs
        .doc(`dataUploadEventGroups/${this.newUploadFormGroup.value.group}`)
        .update({ dateUpdated: moment().toDate() });
    }
    this.loadingService.isLoading(false);
    this.snackBar.open("Data upload process started successfully!", "Dismiss", {
      duration: 5000,
    });
    uploadFormGroup.get("uploadProgress").patchValue(100);
    // this.router.navigate(["/app/data-uploads"]);
    // this.uploadCompleteEmitter.emit(true);
  }

  async createProgressCounters(
    dataUploadEventFragments: DataUploadEventFragment[],
    dataUploadEventId: string
  ) {
    const fragmentCounterChunks = _.chunk(dataUploadEventFragments, 20);
    const progressCounters = [];
    fragmentCounterChunks.map(async (chunk) => {
      const progressCounter = new DataUploadEventProgressCounter();
      progressCounter.id = this.afs.createId();
      progressCounter.dataUploadEvent = dataUploadEventId;
      progressCounter.fragmentCount = chunk.length;
      progressCounter.status = DataUploadEventStatuses.running;
      progressCounter.clearedFragments = 0;
      progressCounter.validatedFragments = 0;
      progressCounter.errorFragments = 0;
      progressCounter.committedFragments = 0;
      progressCounters.push(progressCounter);
      chunk.forEach((fragment) => {
        fragment.progressCounter = progressCounter.id;
      });
    });
    const progressCounterChunks = _.chunk(progressCounters, 450);
    await Promise.all(
      progressCounterChunks.map((chunk) => {
        const batch = this.afs.firestore.batch();
        chunk.forEach((progressCounter: DataUploadEventProgressCounter) => {
          const id = progressCounter.id;
          delete progressCounter.id;
          batch.set(
            this.afs.doc(`dataUploadEventProgressCounters/${id}`).ref,
            progressCounter.toJSONObject()
          );
        });
        return batch.commit();
      })
    );
    return progressCounters;
  }

  private async createDataUploadEvent(
    uploadFormGroup,
    dataUploadEventId,
    batchId,
    uploadDate,
    fragmentsCount,
    progressCounterCount
  ) {
    const formGroupValues = uploadFormGroup.value;
    const dataUploadEvent = new DataUploadEvent();
    dataUploadEvent.batchId = batchId;
    dataUploadEvent.user = this.uiState.authUser.id;
    dataUploadEvent.client = this.uiState.client.id;
    dataUploadEvent.rowCount = formGroupValues.rowCount;
    dataUploadEvent.uploadDate = uploadDate;
    dataUploadEvent.fileName = formGroupValues.fileName;
    dataUploadEvent.group = this.newUploadFormGroup.get("group").value;
    dataUploadEvent.status = DataUploadEventStatuses.running;
    dataUploadEvent.type = formGroupValues.selectedTransactionType;
    if (dataUploadEvent.type === DataUploadEventTypes["Custom Report"]) {
      dataUploadEvent.customReport = formGroupValues.conversionSource;
    } else {
      dataUploadEvent.account = formGroupValues.conversionSource;
      dataUploadEvent.uploadPeriodEnd = moment(
        this.newUploadFormGroup.get("uploadPeriodEnd").value
      ).toDate();
    }
    dataUploadEvent.notes = formGroupValues.notes
      ? formGroupValues.notes
      : null;
    dataUploadEvent.errorFragments = 0;
    dataUploadEvent.fragments = fragmentsCount;
    dataUploadEvent.committedFragments = 0;
    dataUploadEvent.validatedFragments = 0;
    dataUploadEvent.clearedFragments = 0;
    dataUploadEvent.errorCounters = 0;
    dataUploadEvent.committedCounters = 0;
    dataUploadEvent.validatedCounters = 0;
    dataUploadEvent.clearedCounters = 0;
    dataUploadEvent.progressCounters = progressCounterCount;
    const createdUploadEvent = await this.afs
      .doc(`dataUploadEvents/${dataUploadEventId}`)
      .set(dataUploadEvent.toJSONObject());
    return createdUploadEvent;
  }

  getParentThirdParty(parentId) {
    return this.thirdParties.find((dsp) => dsp.id === parentId);
  }

  private async fetchUploadGroups() {
    this.groups = <DataUploadEventGroup[]>FirestoreUtilities.mapToType(
      await this.afs
        .collection("dataUploadEventGroups", (ref) =>
          ref.where("client", "==", this.uiState.client.id)
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise()
    ).sort((a, b) => {
      return moment(a.dateUpdated.toDate()).isBefore(
        moment(b.dateUpdated.toDate())
      )
        ? 1
        : -1;
    });
    this.groups.push(
      new DataUploadEventGroup({ name: "Un-Grouped", id: "ungrouped" })
    );
    this.selectedGroup = this.groups[0].id;
  }
  showExpectedFields(uploadFormGroup: FormGroup) {
    this.dialog.open(UploadExpectedFieldsDialogComponent, {
      panelClass: "invisible-panel-dialog",
      data: {
        expectedFields: uploadFormGroup.get("expectedFields").value,
        requiredFields: uploadFormGroup.get("requiredFields").value,
      },
    });
  }
  openUploadControlChecklist(uploadFormGroup: FormGroup) {
    const controlsArray = uploadFormGroup.get("uploadControls")
      .value as FormArray;
    this.dialog.open(UploadControlsChecklistDialogComponent, {
      panelClass: "invisible-panel-dialog",
      data: { formGroup: uploadFormGroup },
    });
  }
}
export function CsvFilesOnly(controlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const name = control.value;
    const lastDot = name.lastIndexOf(".");
    const ext = name.substring(lastDot + 1);
    if (control.errors && !control.errors.mustMatch) {
      return;
    }
    if (ext !== "csv") {
      control.setErrors({ mustBeCsv: true });
    } else {
      control.setErrors(null);
    }
  };
}
export function MissingFieldsValidator() {
  return (formGroup: FormGroup) => {
    const requiredFields = formGroup.controls["requiredFields"];
    const expectedFields = formGroup.controls["expectedFields"];
    const fileFields =
      formGroup.controls["fileData"].value?.length > 0
        ? _.uniq(Object.keys(formGroup.controls["fileData"].value[0]))
        : [];
    const missingFields = _.differenceWith(
      requiredFields.value,
      fileFields,
      _.isEqual
    );
    if (missingFields.length > 0) {
      expectedFields.setErrors({ missingRequirdFields: [missingFields] });
    } else {
      expectedFields.setErrors(null);
    }
  };
}
