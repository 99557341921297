import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TrendAnalysisReport } from '@deliver-sense-librarian/data-schema';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { TrendAnalysisUtilities } from '../trend-analysis.utils';

declare function require(name:string);
const HC_Regression = require('highcharts-regression');
HC_Regression(Highcharts);
HC_exporting(Highcharts);
@Component({
  selector: 'app-trend-chart',
  templateUrl: './trend-chart.component.html',
  styleUrls: ['./trend-chart.component.scss']
})
export class TrendChartComponent implements OnInit {
  @Input() chartData: any[] = [];
  @Input() chartTitle: string;
  @Input() trendAnalysisReport: TrendAnalysisReport;
  @Output() resetGroup = new EventEmitter();
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options;
  constructor() {

  }
  ngOnInit(): void {
    const data = this.chartData;
    this.chartOptions = {
      series: data,
      title: {
        text: this.chartTitle
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Date'
        }
      },
      yAxis: {
        title: {
          text: TrendAnalysisUtilities.getTrendTypeName(this.trendAnalysisReport.trendType)
        }
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle'
      },
      chart: {
        zoomType: 'x',
        type: 'line'
      },
      credits: {
        enabled: true,
        text: 'DeliverSense',
        href: 'https://deliversense.com'
      },
      plotOptions: {
        series: {
          label: {
          },
        }
      },

    };
  }

  // @Input() analysisData: {chartData, chartLabels};
  // public chartType: ChartType = 'line';
  // public chartLegend = true;
  // public chartLabels: Label[];
  // public chartData: ChartDataSets[];
  // public chartOptions = {
  //   responsive: true,
  // };
  // //@ts-ignore
  // public chartOptions: (ChartOptions & { annotation: any }) = {
  //   responsive: true,
  // };
  // public chartColors: Color[] = [
  //   {
  //     borderColor: '#009688',
  //     backgroundColor: 'rgba(255,0,0,0.0)',
  //   },
  // ];
  // constructor() {

  //  }

  // ngOnInit(): void {
  //   if (this.analysisData) {
  //     this.chartLabels = this.analysisData.chartLabels
  //     this.chartData = [this.analysisData.chartData];
  //   }
  // }


}
