import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  ThirdPartyReport,
  UserView,
  Client,
  Entity,
} from "@deliver-sense-librarian/data-schema";
import { UiState } from "app/redux/custom-states/uiState/ui-state";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";

@Component({
  selector: "app-entity-selector-dialog",
  templateUrl: "./entity-selector-dialog.component.html",
  styleUrls: ["./entity-selector-dialog.component.scss"],
})
export class EntitySelectorDialogComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns: string[] = ["select", "name"];
  tableData: MatTableDataSource<Entity>;
  uiState: UiState;
  selection = new SelectionModel<Entity>(true, []);

  private destroy$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<EntitySelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { entities: Entity[] },
    private afs: AngularFirestore,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.tableData = new MatTableDataSource(this.data.entities);
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {}

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableData.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.tableData.data.forEach((row) => {
          this.selection.select(row);
        });
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  closeAndShare() {
    this.dialogRef.close(this.selection.selected.map((entity) => entity));
  }
}
