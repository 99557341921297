import {
  recColumns,
  ThirdParty,
  Location,
  PriorPeriodAdjustmentTransaction,
  ThirdPartyReportFragmentVarianceAnalysisReport,
  TransactionAITypes,
} from "@deliver-sense-librarian/data-schema";
import { Workbook, Worksheet } from "exceljs";
import moment from "moment";
import {
  ReconciliationDrillDownReportTypes,
  ReconciliationReportDatUtility,
} from "../reconciliation-report-data.utility";
import { data } from "jquery";

const dspOptions = ["Uber", "GrubHub", "DoorDash"];

export async function compileYTCJournalEntries(
  recSummaryWorkbook: Workbook,
  dataUtility: ReconciliationReportDatUtility
) {
  const thirdPartyReportVarianceAnalysisReports =
    await dataUtility.fetchReconciliationReportDrillDownFragments(
      ReconciliationDrillDownReportTypes.varianceAnalysisReports
    );
  const ppaTransactions = await dataUtility.fetchPriorPeriodAdjustments(
    dataUtility.locations
  );
  const adjustmentsCFRSheet =
    recSummaryWorkbook.addWorksheet("3pdAdjustmentsCFR");
  const adjustmentsORISheet =
    recSummaryWorkbook.addWorksheet("3pdAdjustmentsORI");
  const comissionFeesCFR = recSummaryWorkbook.addWorksheet(
    "3pdComissionFeesCFR"
  );
  const comissionFeesORI = recSummaryWorkbook.addWorksheet(
    "3pdComissionFeesORI"
  );
  compileAdjustmentsJournalEntrySheet(
    adjustmentsCFRSheet,
    dataUtility,
    thirdPartyReportVarianceAnalysisReports,
    ppaTransactions,
    true
  );
  compileAdjustmentsJournalEntrySheet(
    adjustmentsORISheet,
    dataUtility,
    thirdPartyReportVarianceAnalysisReports,
    ppaTransactions,
    false
  );
  compileComissionSheet(comissionFeesCFR, dataUtility, true);
  compileComissionSheet(comissionFeesORI, dataUtility, false);
  // if (!skipChecks) {
  //   const jeCheckSheet = recSummaryWorkbook.addWorksheet("JE Check");
  //   compileJeCheckSheet(jeCheckSheet);
  // }
  return [
    adjustmentsCFRSheet,
    adjustmentsORISheet,
    comissionFeesCFR,
    comissionFeesORI,
  ];
}

function compileAdjustmentsJournalEntrySheet(
  adjustmentsSheet: Worksheet,
  dataUtility: ReconciliationReportDatUtility,
  thirdPartyReportVarianceAnalysisReports: ThirdPartyReportFragmentVarianceAnalysisReport[],
  ppaTransactions: PriorPeriodAdjustmentTransaction[],
  isCFR: boolean
) {
  const weekEndingDate = moment(
    dataUtility.existingReport.endDate.toDate()
  ).format("M/D/YYYY");
  const headerRow = adjustmentsSheet.addRow([
    "JOURNAL",
    "DATE",
    "DESCRIPTION",
    "REFERENCE_NO",
    "LINE_NO",
    "ACCT_NO",
    "LOCATION_ID",
    "DEPT_ID",
    "DOCUMENT",
    "MEMO",
    "DEBIT",
    "CREDIT",
  ]);
  let rowCount = 2;

  dataUtility.locations
    .filter((location) => {
      if (isCFR) {
        return location.entity === "EL3Qo8G3rQt9Rjx5QwSk";
      } else {
        return location.entity === "9s1eBM80PD9KMj0qukWU";
      }
    })
    .forEach((location) => {
      dataUtility.thirdParties.forEach((dsp) => {
        const cancelationFee = calcCancelationFee(
          location,
          dsp,
          thirdPartyReportVarianceAnalysisReports,
          ppaTransactions
        );
        // Expense Account
        adjustmentsSheet.addRow([
          "GJ",
          weekEndingDate,
          "3RD PARTY CANCELLATION FEES",
          "3RD PARTY CANCELLATION FEES",
          rowCount - 1,
          "50208",
          isCFR ? "ETO1" : "ET02",
          `T-${location.locationId.substring(1, location.locationId.length)}`,
          "",
          dsp.name,
          cancelationFee >= 0 ? cancelationFee : "", // if the adjustment is positive, debit the 5000 series account
          cancelationFee < 0 ? Math.abs(cancelationFee) : "", // if it is negative put in credit (absolute) for 5000
        ]);
        rowCount++;
        //Credit Account
        adjustmentsSheet.addRow([
          "GJ",
          weekEndingDate,
          "3RD PARTY CANCELLATION FEES",
          "3RD PARTY CANCELLATION FEES",
          rowCount - 1,
          "11001",
          isCFR ? "ETO1" : "ET02",
          `T-${location.locationId.substring(1, location.locationId.length)}`,
          "",
          dsp.name,
          cancelationFee < 0 ? Math.abs(cancelationFee) : "",
          cancelationFee >= 0 ? cancelationFee : "",
        ]);
        rowCount++;
      });
    });
}

function calcCancelationFee(
  location: Location,
  dsp: ThirdParty,
  thirdPartyReportVarianceAnalysisReports: ThirdPartyReportFragmentVarianceAnalysisReport[],
  ppaTransactions: PriorPeriodAdjustmentTransaction[]
) {
  const varAnalysis = thirdPartyReportVarianceAnalysisReports.find(
    (report) =>
      report.location === location.locationId && report.thirdParty === dsp.id
  );
  const salesVariance = varAnalysis.salesVarianceAmount;
  const vaAdjustments = -varAnalysis.salesVarianceReasons
    .filter((reason) => reason.type === TransactionAITypes.adjustment)
    .reduce((sum: number, reason) => {
      return (sum += reason.sale);
    }, 0);
  const matchingPpaRefundTransactions = ppaTransactions.filter((ppat) => {
    return (
      ppat.location === location.locationId &&
      ppat.account === dsp.id &&
      ppat.isErrorCharge
    );
  });
  const ppaRefundTransactionsAmount = -matchingPpaRefundTransactions.reduce(
    (sum: number, ppat) => {
      // determine the amount of the cutomer refund (DoorDash in transaction.saleCorrection, all other DSPS in transaction.sale)
      const amount =
        ppat.transaction.sale !== 0
          ? ppat.transaction.sale
          : ppat.transaction.saleCorrection !== 0
          ? ppat.transaction.saleCorrection
          : 0;
      return (sum += amount);
    },
    0
  );
  return +(salesVariance + vaAdjustments + ppaRefundTransactionsAmount).toFixed(
    2
  );
}
function compileComissionSheet(
  comissionFeeSheet: Worksheet,
  dataUtility: ReconciliationReportDatUtility,
  isCFR: boolean
) {
  const weekEndingDate = moment(
    dataUtility.existingReport.endDate.toDate()
  ).format("M/D/YYYY");
  const headerRow = comissionFeeSheet.addRow([
    "JOURNAL",
    "DATE",
    "DESCRIPTION",
    "REFERENCE_NO",
    "LINE_NO",
    "ACCT_NO",
    "LOCATION_ID",
    "DEPT_ID",
    "DOCUMENT",
    "MEMO",
    "DEBIT",
    "CREDIT",
  ]);
  let rowCount = 2;
  dataUtility.locations
    .filter((location) => {
      if (isCFR) {
        return location.entity === "EL3Qo8G3rQt9Rjx5QwSk";
      } else {
        return location.entity === "9s1eBM80PD9KMj0qukWU";
      }
    })
    .forEach((location) => {
      dataUtility.thirdParties.forEach((dsp) => {
        ["Delivery", "Catering", "Pickup", "Promo", "Other Charges"].forEach(
          (type) => {
            const amount = Math.abs(
              getFeeExpense(dataUtility, type, dsp, location)
            );
            //DEBIT
            comissionFeeSheet.addRow([
              "GJ",
              weekEndingDate,
              "3RD PARTY COMMISSION FEES",
              "3RD PARTY COMMISSION FEES",
              rowCount - 1,
              "50204",
              isCFR ? "ETO1" : "ET02",
              `T-${location.locationId.substring(
                1,
                location.locationId.length
              )}`,
              "",
              `${dsp.name} ${type} Expense`,
              amount,
              "",
            ]);
            rowCount++;
            //CREDIT
            comissionFeeSheet.addRow([
              "GJ",
              weekEndingDate,
              "3RD PARTY COMMISSION FEES",
              "3RD PARTY COMMISSION FEES",
              rowCount - 1,
              "11001",
              isCFR ? "ETO1" : "ET02",
              `T-${location.locationId.substring(
                1,
                location.locationId.length
              )}`,
              "",
              `${dsp.name} ${type} Expense`,
              "",
              amount,
            ]);
          }
        );
      });
    });
}
function getFeeExpense(
  dataUtility: ReconciliationReportDatUtility,
  feeType: string,
  dsp: ThirdParty,
  location: Location
) {
  const locationReport = dataUtility.reportData.find((locationReport) => {
    return (
      locationReport.locationId === location.locationId &&
      locationReport.thirdParty === dsp.id
    );
  });
  if (locationReport) {
    switch (feeType) {
      case "Delivery":
        return locationReport.deliveryFees ? locationReport.deliveryFees : 0;
      case "Pickup":
        return locationReport.pickupFees ? locationReport.pickupFees : 0;
      case "Catering":
        return locationReport.cateringFees ? locationReport.cateringFees : 0;
      case "Promo":
        return locationReport.promoFees ? locationReport.promoFees : 0;
      case "Other Charges":
        const otherCharges = locationReport.thirdPartyOtherCharges
          ? locationReport.thirdPartyOtherCharges
          : 0;
        const misc = locationReport.thirdPartyMisc
          ? locationReport.thirdPartyMisc
          : 0;
        return otherCharges + misc;
    }
  }
  return 0;
}
