import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  AfterViewInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  ThirdParty,
  TransactionI,
  ThirdPartyRating,
} from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { downloadDataAsFile, getTimeFormatted } from "app/shared/ds-constant";
import moment from "moment";
import { Papa } from "ngx-papaparse";

@Component({
  selector: "app-customer-ratings-table",
  templateUrl: "./customer-ratings-table.component.html",
  styleUrls: ["./customer-ratings-table.component.scss"],
})
export class CustomerRatingsTableComponent implements OnInit, AfterViewInit {
  @Input() thirdParties: ThirdParty[] = [];
  @Input() ratings: ThirdPartyRating[];
  @Output() openTransactionHistory = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public keys: string[] = [];

  public tableData: MatTableDataSource<ThirdPartyRating>;
  public displayedColumns: string[] = [
    "location",
    "thirdParty",
    "date",
    "rating",
    "description",
    "tags",
    "ratingType",
  ];
  constructor(
    private papa: Papa,
    private dialog: MatDialog,
    private store: Store<any>
  ) {}

  ngOnInit(): void {
    this.prepareTableData();
    this.tableData = new MatTableDataSource(this.ratings);
  }
  ngAfterViewInit() {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
  }
  private prepareTableData() {
    this.ratings.forEach((rating) => {
      rating["thirdPartyName"] = this.getThirdPartyName(rating.thirdParty);
      rating["dateText"] = moment(rating.date.toDate()).format("l");
    });
  }
  public exportReport() {
    const data = this.tableData.data.map((row) => {
      return {
        location: row.location,
        thirdParty: row["thirdPartyName"],
        date: moment(row.date.toDate()).format("MM/DD/YYYY"),
        rating: row.rating,
        description: row.description,
        tags: row.tags,
        ratingType: row.ratingType,
      };
    });
    const results = this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const fileName = `Customer-Ratings`;
    downloadDataAsFile(results, fileName, "csv");
  }
  public getThirdPartyName(thirdPartyId) {
    const thirdParty = this.thirdParties.find(
      (thirdParty) => thirdParty.id === thirdPartyId
    );
    return thirdParty ? `${thirdParty.name}` : "";
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
}
