import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SupportDialogComponent } from "../../../dialogs/support-dialog/support-dialog.component";
import {
  User,
  Client,
  ClientModule,
  DsModuleAddons,
} from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { combineAll, takeUntil } from "rxjs/operators";
import { from, Subject } from "rxjs";
import { AngularFirestore } from "@angular/fire/firestore";
import { FirestoreUtilities } from "../../../utilities/firestore-utilities";
import {
  exemptSalesManagementModule,
  thirdPartyReportingModule,
} from "../../../shared/ds-constant";
import { UiState } from "../../../redux/custom-states/uiState/ui-state";
import { ToggleShowDataUploadAction } from "../../../redux/custom-states/uiState/ui-state-actions/application-state-actions";
import { HttpClient } from "@angular/common/http";
import { AngularFireStorage } from "@angular/fire/storage";
import { Router } from "@angular/router";
import { FirebaseAuthService } from "app/auth/services/firebase-auth.service";
import { ConfirmDialogComponent } from "app/dialogs/confirm-dialog/confirm-dialog.component";
import {
  UnsetSelectedClientAction,
  ClearSfaAction,
  SetAccountClientAction,
} from "app/redux/custom-states/uiState/ui-state-actions";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { FormControl } from "@angular/forms";
import { environment } from "environments/environment";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Output() toggleSidenav = new EventEmitter<void>();

  menuitems = [
    {
      state: "dashboard",
      name: "DASHBOARD",
      id: "dashboard-link",
      type: "link",
      icon: "tachometer-alt",
    },
    {
      state: "organization",
      name: "ORGANIZATION",
      id: "org-nav-link",
      type: "sub",
      icon: "sitemap",
      children: [
        { state: "team", icon: "users", name: "TEAM MEMBERS" },
        { state: "entities", icon: "city", name: "ENTITIES" },
        { state: "locations", icon: "globe", name: "LOCATIONS" },
      ],
    },
  ];
  public user: User;
  public client: Client;
  public uiState: UiState;
  private destroy$ = new Subject();
  private clientModules: ClientModule[] = [];
  public selectedClient = new FormControl();
  public availableClients: Client[] = [];
  public loadedVersion = environment.appVersion;
  constructor(
    private auth: FirebaseAuthService,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private dialog: MatDialog,
    private http: HttpClient,
    private router: Router,
    private loadingService: LoadingDialogService,
    private store: Store<any>
  ) {}

  ngOnInit(): void {
    this.store
      .select((state) => state.uiState)
      .subscribe((uiState$) => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$;
          this.getClientModules();
          if (this.selectedClient && !this.selectedClient.value) {
            this.selectedClient.patchValue(this.client.id);
            this.selectedClient.valueChanges.subscribe((value) => {
              if (value === "-1") {
                this.clearClientSelection();
              } else {
                this.changeSelectedClient(value);
              }
            });
          }
          this.getAvailableClients();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  showSupportDialog() {
    this.dialog.open(SupportDialogComponent);
  }
  openDataUploadWindow() {
    this.store.dispatch(new ToggleShowDataUploadAction(true));
  }
  private getClientModules() {
    this.afs
      .collection("clientModules", (ref) =>
        ref.where("client", "==", this.client.id)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((clientModules$) => {
        this.clientModules = FirestoreUtilities.mapToType(clientModules$);
        if (this.clientModules.length > 0) {
          this.clientModules = this.clientModules.filter(
            (clientModule: ClientModule) => {
              return clientModule.active;
            }
          );
        }
        this.addActiveClientModuleLinks();
      });
  }

  private addActiveClientModuleLinks() {
    this.menuitems.splice(2, 4); // WTF DOES THIS DO??? Increment +1 in the delete parameter if adding new top level tabs
    if (this.uiState.clientRole > 1) {
      this.clientModules.forEach((clientModule: ClientModule) => {
        switch (clientModule.module.toString()) {
          case thirdPartyReportingModule:
            const thirdPartyChildren = [];
            if (
              this.uiState.authUser.internalRole >= 1 ||
              this.uiState.clientRole >= 2
            ) {
              thirdPartyChildren.push({
                state: "list",
                icon: null,
                name: "ALL REPORTS",
              });
            }
            // If the authUser is internal or has an admin+ client role they can modify report settings and search all transactions
            if (
              this.uiState.authUser.internalRole >= 1 ||
              this.uiState.clientRole >= 3
            ) {
              thirdPartyChildren.push({
                state: "settings",
                icon: null,
                name: "REPORT SETTINGS",
              });
            }

            this.menuitems.splice(3, 0, {
              state: "3pd-reports",
              name: "3PD RECONCILIATION",
              id: "third-party-report-module-link",
              type: "sub",
              icon: "table",
              children: thirdPartyChildren,
            });
            // Trend Analysis

            this.menuitems.splice(4, 0, {
              state: "trend-analysis",
              name: "TREND ANALYSIS",
              id: "trend-analysis-module-link",
              type: "link",
              icon: "chart-line",
            });
            break;
          case exemptSalesManagementModule:
            this.menuitems.splice(7, 0, {
              state: "exemption-certificates",
              id: "exemption-certificate-module-link",
              name: "EXEMPTION CERTIFICATES",
              type: "link",
              icon: "certificate",
            });
            break;
        }
      });
    }
  }

  isUserClientAdmin() {
    return this.uiState.clientRole > 2;
  }

  async logout() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "SIGNOUT?",
        message: "Are you sure you sign out?.",
        action: "Yes, sign out.",
      },
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.auth.signOut();
        await this.router.navigate([""]);
        window.location.reload();
      }
    });
  }

  public getClient(clientId) {
    const clientMatch = this.availableClients.find(
      (_client) => _client.id === clientId
    );
    return clientMatch;
  }

  public async clearClientSelection() {
    this.selectedClient = null;
    this.store.dispatch(new UnsetSelectedClientAction());
    const sfaToken = localStorage.getItem("sfaToken");
    if (sfaToken) {
      this.store.dispatch(new ClearSfaAction(sfaToken));
    }
    this.router.navigate(["/client-selection"]);
  }

  private getAvailableClients() {
    this.afs
      .collection(`users/${this.user.id}/clientRoles`)
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (clientRoles$) => {
          const clientRoles = FirestoreUtilities.mapToType(clientRoles$);
          const clientRequests = clientRoles
            .map((clientRole) => {
              if (clientRole.role) {
                return this.afs
                  .doc(`clients/${clientRole.resource}`)
                  .snapshotChanges();
              }
            })
            .filter((req) => !!req);
          if (clientRequests.length > 0) {
            from(clientRequests)
              .pipe(combineAll(), takeUntil(this.destroy$))
              .subscribe((clients$) => {
                this.availableClients = <Client[]>(
                  FirestoreUtilities.mergeToType(clients$)
                    .filter((client) => !!client?.active)
                    .sort((a, b) =>
                      a.name.charAt(0) > b.name.charAt(0) ? 1 : -1
                    )
                );
                // const clientLogos = this.availableClients.map((client) => {
                //   if (client.logoPath) {
                //     return this.storage
                //       .ref(client.logoPath)
                //       .getDownloadURL()
                //       .pipe(
                //         map((url) => {
                //           client.logo = url;
                //         })
                //       );
                //   } else {
                //     return of();
                //   }
                // });
                // from(clientLogos)
                //   .pipe(takeUntil(this.destroy$), combineAll())
                //   .subscribe((clientLogoMappings$) => {});
              });
          }
        },
        (e) => {
          throw new Error(e);
        }
      );
  }

  changeSelectedClient(clientId) {
    const selectedClient = this.availableClients.find(
      (client) => client.id === clientId
    );
    if (selectedClient && selectedClient.id !== this.client.id) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: "Confirm Client Selection Change",
          message: "Are you sure you want to change your client selection?",
          action: "Yes.",
        },
      });
      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          const sfaToken = localStorage.getItem("sfaToken");
          if (sfaToken) {
            this.store.dispatch(new ClearSfaAction(sfaToken));
          }
          if (selectedClient.sfaRequired) {
            this.loadingService.isLoading(true, "Changing client...");
            this.store.dispatch(new UnsetSelectedClientAction());
            this.router.navigate(["/sfa", selectedClient.id]);
            // purely ux to let user know what is happening
            setTimeout(() => {
              this.loadingService.isLoading(false);
            });
          } else {
            this.store.dispatch(new SetAccountClientAction(selectedClient));
            this.loadingService.isLoading(true, "Changing client...");
            this.router.navigate(["client-selection"]);
            setTimeout(() => {
              window.location.reload();
              this.loadingService.isLoading(false);
            });
          }
        } else {
          this.selectedClient.patchValue(this.client.id);
        }
      });
    }
  }
}
