import { Component, OnInit, Input, ElementRef } from "@angular/core";
import { LocationReport } from "../../third-party-delivery-analytics-engine";
import { ThirdParty, Location } from "@deliver-sense-librarian/data-schema";
import * as Highcharts from "highcharts";
import more from "highcharts/highcharts-more";
import HC_exporting from "highcharts/modules/exporting";
HC_exporting(Highcharts);
more(Highcharts);
import { FormControl, Validators } from "@angular/forms";
import * as _ from "lodash";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ReconciliationReportDatUtility } from "../../utilities/reconciliation-report-data.utility";
declare const $: any;

@Component({
  selector: "app-reconciliation-charts",
  templateUrl: "./reconciliation-charts.component.html",
  styleUrls: ["./reconciliation-charts.component.scss"],
})
export class ReconciliationChartsComponent implements OnInit {
  @Input() dataUtility: ReconciliationReportDatUtility;
  selectedLocations = new FormControl([], Validators.required);
  selectedThirdParty = new FormControl("", Validators.required);
  selectedVarianceType = new FormControl("", Validators.required);
  showChart = false;
  Highcharts: typeof Highcharts = Highcharts;

  data: any;
  varianceTypes = ["Sales", "Tax", "Remittance"];
  label = "";
  self = this;
  public bubbleChartOptions: Highcharts.Options = {
    chart: {
      type: "bubble",
      plotBorderWidth: 1,
      zoomType: "xy",
      events: {
        load: function () {
          $(".highcharts-scrollbar").show();
        },
      },
    },

    legend: {
      enabled: false,
    },

    title: {
      text: "",
    },

    subtitle: {
      text: "Click and drag to zoom",
    },

    xAxis: {
      gridLineWidth: 1,
      title: {
        text: "Location Id",
      },
      labels: {
        format: "{value}",
      },
      // plotLines: [{
      //   color: 'black',
      //   dashStyle: 'dot',
      //   width: 2,
      //   value: 65,
      //   label: {
      //     rotation: 0,
      //     y: 15,
      //     style: {
      //       fontStyle: 'italic'
      //     },
      //     text: 'Safe fat intake 65g/day'
      //   },
      //   zIndex: 3
      // }],
      accessibility: {
        rangeDescription: "",
      },
    },

    yAxis: {
      startOnTick: false,
      endOnTick: false,
      title: {
        text: "Total Sales At Location",
      },
      labels: {
        format: "${value}",
      },
    },

    tooltip: {
      useHTML: true,
      headerFormat: "<table>",
      pointFormat:
        '<tr><th colspan="2"><h3>{point.location}</h3></th></tr>' +
        "<tr><th>Location Sales:</th><td>${point.y}</td></tr>" +
        "<tr><th>{point.type}:</th><td>${point.variance}</td></tr>",
      footerFormat: "</table>",
      followPointer: true,
    },
    credits: {
      enabled: true,
      text: "DeliverSense.com",
      href: "https://deliversense.com",
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
          format: "{point.location}",
        },
      },
    },

    series: [],
  };

  constructor(private snackBar: MatSnackBar) {}

  ngOnInit() {}
  getLocationName(locationId) {
    const location = this.dataUtility.locations.find(
      (l) => l.locationId == locationId
    );
    return location ? location.name : "";
  }
  private normalizeRadius(r, min, max) {
    r = Math.abs(r);
    let normalizedR;
    if (r !== 0) {
      normalizedR = +(r - min) / (max - min);
    }
    if (normalizedR === 0 || normalizedR * 30 < 5) {
      return 5;
    }
    return normalizedR * 30;
  }

  async updateChartData() {
    this.showChart = false;
    setTimeout(() => {
      if (
        this.selectedThirdParty.valid &&
        this.selectedLocations.valid &&
        this.selectedVarianceType.valid
      ) {
        const locations = this.selectedLocations.value;
        const thirdParty = this.selectedThirdParty.value;
        const varianceType = this.selectedVarianceType.value;
        this.data = this.dataUtility.reportData
          .filter((row) =>
            this.dataUtility.locations.find((location) => {
              return location.locationId === row.locationId;
            })
          )
          .map((row) => {
            if (row.thirdParty === thirdParty) {
              const x = +row.locationId;
              const y = row.posNetSales;
              let v;
              let z;
              let type;
              switch (varianceType) {
                case "Tax":
                  v = +(row.posNetTax - row.thirdPartyNetTax).toFixed(2);
                  this.bubbleChartOptions.title.text =
                    "Tax Variance by Location and Total POS Sales";
                  type = "Tax Variance";
                  break;
                case "Remittance":
                  v = +(row.expectedRemittance - row.actualRemittance).toFixed(
                    2
                  );
                  this.bubbleChartOptions.title.text =
                    "Remittance Variance by Location and Total POS Sales";
                  type = "Remittance Variance";
                  break;
                default:
                  v = +(row.posNetSales - row.thirdPartyNetSales).toFixed(2);
                  this.bubbleChartOptions.title.text =
                    "Sales Variance by Location";
                  type = "Sales Variance";
              }
              z = Math.abs(v);
              if (!!x && !!y && !!v && !!z) {
                return {
                  x,
                  y,
                  z,
                  location: `${row.locationId}`,
                  variance: v,
                  type: type,
                };
              }
            }
          })
          .filter((d) => !!d);
        const maxSales = _.maxBy(this.data, (o) => o["y"])["y"];
        const maxVariance = _.maxBy(this.data, (o) => o["z"])["z"];
        const minVariance = _.minBy(this.data, (o) => o["z"])["z"];
        this.data.forEach((set) => {
          set.z = this.normalizeRadius(set.z, minVariance, maxVariance);
        });
        //@ts-ignore
        this.bubbleChartOptions.series[0] = { data: this.data };
        this.showChart = true;
      } else {
        this.snackBar.open(
          "Please select one or more locations, a third party, and a variance type."
        );
      }
    }, 300);
  }
  private getVariance(locationId) {
    const location = this.dataUtility.reportData.find(
      (location) => location.locationId.toString() === locationId.toString()
    );
    switch (this.selectedVarianceType.value) {
      case "Tax":
        return Math.abs(location.posNetTax - location.thirdPartyNetTax).toFixed(
          2
        );
      case "Remittance":
        return Math.abs(
          location.expectedRemittance - location.actualRemittance
        ).toFixed(2);
      default:
        return Math.abs(
          location.posNetSales - location.thirdPartyNetSales
        ).toFixed(2);
    }
  }
}
