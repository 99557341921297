import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ElementRef,
} from "@angular/core";
import {
  Client,
  ReportGroupTypes,
  ThirdPartyReport,
  User,
  ReportShare,
} from "@deliver-sense-librarian/data-schema";
import { UiState } from "app/redux/custom-states/uiState/ui-state";
import { from, Subject } from "rxjs";
import { combineAll, first, takeUntil } from "rxjs/operators";
import { AngularFirestore } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import moment from "moment";

@Component({
  selector: "app-third-party-delivery-shared-reports",
  templateUrl: "./third-party-delivery-shared-reports.component.html",
  styleUrls: ["./third-party-delivery-shared-reports.component.scss"],
})
export class ThirdPartyDeliverySharedReportsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public user: User;
  public client: Client;
  public uiState: UiState;
  private destroy$ = new Subject();
  loadingWidgetData: boolean;
  reports: ThirdPartyReport[];

  constructor(
    private store: Store<any>,
    private afs: AngularFirestore,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.store
      .select((store) => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((uiState$) => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$;
          this.getRecentTPDReports();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  ngAfterViewInit() {}
  getRecentTPDReports() {
    this.loadingWidgetData = true;
    this.afs
      .collection("reportShares", (ref) =>
        ref
          .where("client", "==", this.client.id)
          .where("toUser", "==", this.user.id)
          .where("type", "==", ReportGroupTypes.thirdPartyReconciliation)
          .orderBy("dateCreated", "desc")
          .limit(10)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((sharedReportsQuery$) => {
        const reportShares = <ReportShare[]>(
          FirestoreUtilities.mapToType(sharedReportsQuery$)
        );
        if (reportShares.length > 0) {
          const reportRequests = reportShares.map((reportShare) => {
            return this.afs
              .doc(`thirdPartyReports/${reportShare.report}`)
              .snapshotChanges();
          });
          from(reportRequests)
            .pipe(combineAll(), first())
            .subscribe(
              (reportsQuery$) => {
                this.reports = <ThirdPartyReport[]>(
                  FirestoreUtilities.mergeToType(reportsQuery$).sort(
                    (a: ThirdPartyReport, b: ThirdPartyReport) => {
                      return moment(a.dateUpdated.toDate()).isSameOrBefore(
                        b.dateUpdated.toDate()
                      )
                        ? 1
                        : -1;
                    }
                  )
                );
                this.loadingWidgetData = false;
              },
              (e) => {
                console.log(`error thirdPartyReports: ${e.message}`);
              }
            );
        } else {
          this.reports = [];
          this.loadingWidgetData = false;
        }
      });
  }
}
