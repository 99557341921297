import { Component, OnDestroy, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { combineAll, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { from, Subject, combineLatest } from "rxjs";
import {
  PosTransaction,
  User,
  Client,
  Location,
  UserRoles,
} from "@deliver-sense-librarian/data-schema";
import { FirestoreUtilities } from "../../../utilities/firestore-utilities";
import { Store } from "@ngrx/store";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormControl, Validators } from "@angular/forms";
import { LoadingDialogService } from "../../../services/loading-dialog.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormGroup, FormBuilder } from "@angular/forms";
import * as moment from "moment";

@Component({
  selector: "app-exemption-certificate-management",
  templateUrl: "./exemption-certificate-management.component.html",
  styleUrls: ["./exemption-certificate-management.component.scss"],
})
export class ExemptionCertificateManagementComponent
  implements OnInit, OnDestroy
{
  public locations: Location[] = [];
  private user: User;
  private destroy$ = new Subject();
  public transactions: PosTransaction[];
  public storagePath: any;
  private client: Client;
  noTransactions = false;
  transactionSearchForm: FormGroup;
  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    combineLatest([
      this.store
        .select((store) => store.uiState.client)
        .pipe(distinctUntilChanged((a, b) => a === b)),
      this.store
        .select((store) => store.uiState.authUser)
        .pipe(distinctUntilChanged((a, b) => a === b)),

      this.store
        .select((store) => store.uiState.locations)
        .pipe(distinctUntilChanged((a, b) => a === b)),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([client$, authUser$, locations$]) => {
        if (client$ && authUser$ && locations$?.length > 0) {
          this.user = authUser$;
          this.client = client$;
          this.getLocations(locations$);
          this.storagePath = `clients/${this.client.id}/exemptionCertificates/`;
          this.setupSearchForm();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  private getLocations(storedLocations) {
    if (this.user.internalRole > 0) {
      this.afs
        .collection("locations", (ref) =>
          ref.where("client", "==", this.client.id).where("active", "==", true)
        )
        .snapshotChanges()
        .pipe(takeUntil(this.destroy$))
        .subscribe((locationsQueryResults$) => {
          this.locations = <Location[]>(
            FirestoreUtilities.mapToType(locationsQueryResults$)
          );
        });
    } else {
      FirestoreUtilities.getUserAccessibleResourcesOfType(
        "locations",
        this.afs,
        storedLocations,
        [UserRoles.admin, UserRoles.contributor]
      ).subscribe((locations$) => {
        this.locations = locations$
          .filter((location) => !!location.active)
          .sort((a, b) => (a.locationId < b.locationId ? -1 : 1));
      });
    }
  }

  setupSearchForm() {
    this.transactionSearchForm = this.fb.group({
      locations: new FormControl([], Validators.required),
      startDate: new FormControl("", Validators.required),
      endDate: new FormControl("", Validators.required),
      documentedFilter: new FormControl(),
    });
  }
  runSearch() {
    // @TODO make this a form
    this.transactions = null;

    if (this.transactionSearchForm.valid) {
      const formValues: { locations; startDate; endDate; documentedFilter } =
        this.transactionSearchForm.value;
      const reportLocations = this.locations.filter((location) =>
        formValues.locations.find(
          (locationId) => locationId === location.locationId
        )
      );
      const start = formValues.startDate;
      const end = formValues.endDate;
      const transactionQueries = reportLocations.map((location) => {
        return this.afs
          .collection("posTransactions", (ref) =>
            ref
              .where("location", "==", location.locationId)
              .where("isTaxExempt", "==", true)
              .where("client", "==", this.client.id)
              .where("date", "<=", end)
              .where("date", ">=", start)
              .orderBy("date", "desc")
          )
          .snapshotChanges();
      });
      this.loadingService.isLoading(
        true,
        "Searching for tax exempt transactions."
      );
      from(transactionQueries)
        .pipe(combineAll(), takeUntil(this.destroy$))
        .subscribe(
          (transactions$) => {
            const transactions = FirestoreUtilities.mergeCollectionToType(
              transactions$
            )
              .filter((transaction) => {
                if (formValues.documentedFilter === "onlyDocumented") {
                  return transaction.isDocumented === true;
                } else if (formValues.documentedFilter === "onlyUndocumented") {
                  return !transaction.isDocumented;
                } else {
                  return true;
                }
              })
              .sort((a, b) => {
                return moment(a.date.toDate()).isSameOrBefore(
                  moment(b.date.toDate())
                )
                  ? 1
                  : -1;
              });
            if (transactions.length > 0) {
              transactions.forEach((transaction) => {
                const transactionLocation = this.locations.find(
                  (l) => l.locationId === transaction.location
                );
                if (transactionLocation) {
                  transaction["state"] = transactionLocation.addressState;
                }
              });
              setTimeout(() => {
                this.noTransactions = false;
                this.transactions = transactions;
                this.fetchTransactionExemptionCertificates();
              });
            } else {
              this.loadingService.isLoading(false);
              this.noTransactions = true;
            }
          },
          (e) => {
            this.loadingService.isLoading(false);
            console.error(`posTransactions: ${e.message}`);
            this.snackBar.open(
              "Oops... something went wrong. Please refresh and try again",
              "Dismiss",
              { duration: 5000 }
            );
          }
        );
    } else {
      this.snackBar.open("Please select all required fields.", "Dismiss", {
        duration: 5000,
      });
    }
  }
  private async fetchTransactionExemptionCertificates() {
    const exCertRequests = this.transactions.map((transaction) => {
      return this.afs
        .collection("exemptionCertificates", (ref) =>
          ref
            .where("posTransaction", "==", transaction.id)
            .where("client", "==", this.client.id)
        )
        .snapshotChanges();
    });
    from(exCertRequests)
      .pipe(combineAll(), takeUntil(this.destroy$))
      .subscribe((exCerts$) => {
        const exemptionCertificates =
          FirestoreUtilities.mergeCollectionToType(exCerts$);

        this.transactions.forEach((transaction) => {
          const matchingExCert = exemptionCertificates.find(
            (exc) => exc.posTransaction === transaction.id
          );
          if (matchingExCert) {
            transaction["exemptionCertificate"] = matchingExCert;
          }
        });
        this.loadingService.isLoading(false);
      });
  }
}
