import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as moment from "moment";
import { group } from "@angular/animations";
import {
  ReportGroup,
  ThirdPartyReport,
} from "@deliver-sense-librarian/data-schema";

@Component({
  selector: "app-edit-rec-report-info-dialog",
  templateUrl: "./edit-rec-report-info-dialog.component.html",
  styleUrls: ["./edit-rec-report-info-dialog.component.scss"],
})
export class EditRecReportInfoDialogComponent implements OnInit {
  public reportForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<EditRecReportInfoDialogComponent>,
    public fb: FormBuilder,
    public afs: AngularFirestore,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA)
    public data: { report: ThirdPartyReport; reportGroups: ReportGroup[] }
  ) {}

  ngOnInit() {
    this.setupReportForm();
  }

  private setupReportForm() {
    this.reportForm = this.fb.group({
      name: new FormControl(this.data.report.name, Validators.required),
      group: new FormControl(this.data.report.group, Validators.required),
    });
  }
  public async save() {
    if (this.reportForm.valid) {
      await this.afs.doc(`thirdPartyReports/${this.data.report.id}`).update({
        name: this.reportForm.value.name,
        group: this.reportForm.value.group,
        dateUpdated: moment().toDate(),
      });
      this.snackBar.open("Report info updated successfully.", "Dismiss", {
        duration: 5000,
      });
    } else {
      this.snackBar.open("Please provide a name for the report.", "Dismiss", {
        duration: 5000,
      });
    }
    this.dialogRef.close({
      name: this.reportForm.value.name,
      group: this.reportForm.value.group,
    });
  }
}
