import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ThirdParty, Entity } from "@deliver-sense-librarian/data-schema";
import { take, takeUntil, first } from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { ReplaySubject, Subject } from "rxjs";
import { MatSelect } from "@angular/material/select";
import * as _ from "lodash";
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
  selector: "app-delivery-partner-search",
  templateUrl: "./delivery-partner-search.component.html",
  styleUrls: ["./delivery-partner-search.component.scss"],
})
export class DeliveryPartnerSearchComponent implements OnInit {
  @Input() thirdParties: ThirdParty[];
  @Input() selectedThirdParties: FormControl;
  @Input() multiple = true;
  @ViewChild("multiSelect", { static: true }) multiSelect: MatSelect;
  public thirdPartiesFilterCtrl: FormControl = new FormControl();
  public filteredThirdParties: ReplaySubject<ThirdParty[]> = new ReplaySubject<
    ThirdParty[]
  >(1);
  private destroy$ = new Subject();
  constructor(private afs: AngularFirestore) {}

  ngOnInit() {
    this.initializeComponent();
  }

  async initializeComponent() {
    this.filteredThirdParties.next(this.thirdParties.slice());
    this.thirdPartiesFilterCtrl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterThirdParties();
      });
    this.filteredThirdParties.next(this.thirdParties.slice());
    this.thirdPartiesFilterCtrl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterThirdParties();
      });
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredThirdParties
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe((val) => {
        if (selectAllValue) {
          this.selectedThirdParties.patchValue(
            val.map((thirdParty) => thirdParty.id)
          );
          this.selectedThirdParties.updateValueAndValidity();
        } else {
          this.selectedThirdParties.patchValue([]);
          this.selectedThirdParties.updateValueAndValidity();
        }
      });
  }

  filterThirdParties() {
    if (!this.thirdParties) {
      return;
    }
    // get the search keyword
    let search = this.thirdPartiesFilterCtrl.value;
    if (!search) {
      this.filteredThirdParties.next(this.thirdParties.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredThirdParties.next(
      this.thirdParties.filter(
        (thirdParty) =>
          thirdParty.name.toLowerCase().indexOf(search) > -1 ||
          thirdParty.id.indexOf(search) > -1
      )
    );
  }

  getThirdPartyName(thirdPartyId: string) {
    const thirdParty = this.thirdParties?.find(
      (dsp) => dsp.id === thirdPartyId
    );
    return thirdParty ? thirdParty.name : "";
  }
}
