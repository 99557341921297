import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import {
  Client,
  ReportGroupTypes,
  ReportShare,
  ThirdPartyReport,
  User,
} from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { ConfirmDialogComponent } from "app/dialogs/confirm-dialog/confirm-dialog.component";
import { UiState } from "app/redux/custom-states/uiState/ui-state";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import _ from "lodash";
import moment from "moment";
import { Subject, from, combineLatest } from "rxjs";
import { takeUntil, combineAll, first } from "rxjs/operators";

@Component({
  selector: "app-shared-by-me-reports",
  templateUrl: "./shared-by-me-reports.component.html",
  styleUrls: ["./shared-by-me-reports.component.scss"],
})
export class SharedByMeReportsComponent implements OnInit, OnDestroy {
  @Input() selectedReportType: ReportGroupTypes;
  @Input() client: Client;
  @Input() user: User;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<ReportShare>;
  public displayedColumns: string[] = [
    "reportName",
    "date",
    "toUser",
    "unshare",
  ];
  private reportTypeCollectionName:
    | "thirdPartyReports"
    | "errorChargeReports"
    | "trendAnalysisReports"
    | "payoutReports";
  private destroy$ = new Subject();
  private reportShares: ReportShare[] = [];
  reportTypeRoute: string;
  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private router: Router
  ) {}

  ngOnInit() {
    this.setReportTypeInfo();
    this.getReports();
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  private getReports() {
    this.afs
      .collection("reportShares", (ref) =>
        ref
          .where("client", "==", this.client.id)
          .where("fromUser", "==", this.user.id)
          .where("type", "==", this.selectedReportType)
          .orderBy("dateCreated", "desc")
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((sharedReportsQuery$) => {
        this.reportShares = <ReportShare[]>(
          FirestoreUtilities.mapToType(sharedReportsQuery$)
        );

        const reportRequests = [];
        const toUserRequests = [];
        if (this.reportShares.length > 0) {
          this.reportShares.forEach((reportShare) => {
            reportRequests.push(
              this.afs
                .doc(`${this.reportTypeCollectionName}/${reportShare.report}`)
                .snapshotChanges()
            );
            toUserRequests.push(
              this.afs.doc(`userViews/${reportShare.toUser}`).snapshotChanges()
            );
          });
          combineLatest([
            from(reportRequests).pipe(combineAll(), first()),
            from(toUserRequests).pipe(combineAll(), first()),
          ]).subscribe(
            ([reportsQuery$, toUserViews$]) => {
              const reports = <ThirdPartyReport[]>(
                FirestoreUtilities.mergeToType(reportsQuery$)
                  .filter((report) => !!report)
                  .sort((a: ThirdPartyReport, b: ThirdPartyReport) => {
                    return moment(a.dateUpdated.toDate()).isSameOrBefore(
                      b.dateUpdated.toDate()
                    )
                      ? 1
                      : -1;
                  })
              );
              const toUserViews = FirestoreUtilities.mergeToType(
                toUserViews$
              ).filter((item) => !!item);
              this.reportShares.forEach((reportShare) => {
                const reportMatch = reports.find(
                  (thirdPartyReport) =>
                    thirdPartyReport.id === reportShare.report
                );
                const toUserMatch = toUserViews.find(
                  (userView) => userView.id === reportShare.toUser
                );
                if (reportMatch) {
                  reportShare["reportName"] = reportMatch.name;
                  reportShare["creator"] = reportMatch.creator;
                }
                if (toUserMatch) {
                  reportShare["toUserView"] = toUserMatch;
                }
              });
              this.tableData = new MatTableDataSource(this.reportShares);
              this.tableData.paginator = this.paginator;
              this.tableData.sort = this.sort;
            },
            (e) => {
              console.log(
                `error ${this.reportTypeCollectionName}: ${e.message}`
              );
            }
          );
        }
      });
  }
  setReportTypeInfo() {
    switch (this.selectedReportType) {
      case ReportGroupTypes.errorCharge:
        this.reportTypeCollectionName = "errorChargeReports";
        this.reportTypeRoute = "error-charge-manager";
        break;
      case ReportGroupTypes.thirdPartyReconciliation:
        this.reportTypeCollectionName = "thirdPartyReports";
        this.reportTypeRoute = "3pd-reports";
        break;
      case ReportGroupTypes.trendAnalysis:
        this.reportTypeCollectionName = "trendAnalysisReports";
        this.reportTypeRoute = "trend-analysis";
        break;
      case ReportGroupTypes.payoutSummary:
        this.reportTypeCollectionName = "payoutReports";
        this.reportTypeRoute = "payout-reporting";
        break;
    }
  }
  goToReport(reportShare) {
    this.router.navigate(
      [`/app/${this.reportTypeRoute}/${reportShare.report}`],
      {
        queryParams: {
          shared: !(reportShare.creator === this.user.id),
        },
      }
    );
  }
  async deleteReportShare(row: ThirdPartyReport) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm UnShare",
        message: `Are you sure you want unshare this report with ${row["toUserView"].firstName} ${row["toUserView"].lastName}?`,
        action: "Yes, Unshare",
      },
    });
    confirmDialog.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`reportShares/${row.id}`).delete();
        this.snackBar.open("Unshare successfully!", "Dismiss", {
          duration: 5000,
        });
      }
    });
  }
}
