import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  AfterViewInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  ThirdParty,
  ThirdPartyPayout,
} from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { downloadDataAsFile, getTimeFormatted } from "app/shared/ds-constant";
import moment from "moment";
import { Papa } from "ngx-papaparse";

@Component({
  selector: "app-payouts-table",
  templateUrl: "./payouts-table.component.html",
  styleUrls: ["./payouts-table.component.scss"],
})
export class PayoutsTableComponent implements OnInit, AfterViewInit {
  @Input() thirdParties: ThirdParty[] = [];
  @Input() thirdPartyPayouts: ThirdPartyPayout[];
  @Output() openTransactionHistory = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public keys: string[] = [];

  public tableData: MatTableDataSource<ThirdPartyPayout>;
  public displayedColumns: string[] = [
    "location",
    "thirdParty",
    "payoutDate",
    "payoutStatus",
    "payout",
    "sales",
    "tip",
    "adjustments",
    "deliveryFees",
    "tax",
    "taxRemitted",
    "misc",
    "promoFees",
    "taxOnFees",
    "backupWithholdingTax",
    "payoutId",
    "transactionId",
    "transactionsStartDate",
    "transactionsEndDate",
    "id",
  ];
  descriptionToView: any;
  activePopover: any;
  constructor(
    private papa: Papa,
    private dialog: MatDialog,
    private store: Store<any>
  ) {}

  ngOnInit(): void {
    this.flattenTransactionData();
    this.tableData = new MatTableDataSource(this.thirdPartyPayouts);
  }
  ngAfterViewInit() {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
  }
  private flattenTransactionData() {
    this.thirdPartyPayouts.forEach((payout) => {
      payout["thirdPartyName"] = this.getThirdPartyName(payout);
    });
  }
  public exportReport() {
    const data = this.tableData.data.map((row) => {
      return {
        id: row["id"],
        location: row.location,
        thirdParty: row["thirdPartyName"],
        payoutDate: row.payoutDate,
        payout: row.payout,
        sale: row.sales,
        tip: row.tip,
        adjustments: row.adjustments,
        tax: row.tax,
        taxRemitted: row.taxRemitted,
        misc: row["misc"] ? row["misc"] : 0,
        promoFee: row.promoFees,
        deliveryFee: row.deliveryFees,
        taxOnFees: row.taxOnFees,
        backupWithholdingTax: row.backupWithholdingTax,
        transactionId: row.transactionId,
        payoutStatus: row.payoutStatus,
        payoutId: row.payoutId,
      };
    });
    const results = this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const location = data[0] ? data[0].location : "";
    const fileName = `${data[0] && data[0]["type"]}-${location}-Payouts`;
    downloadDataAsFile(results, fileName, "csv");
  }
  public getThirdPartyName(transaction) {
    const thirdPartyId = transaction.account
      ? transaction.account
      : transaction.thirdParty;
    const thirdParty = this.thirdParties.find(
      (thirdParty) => thirdParty.id === thirdPartyId
    );
    return thirdParty ? `${thirdParty.name}` : "";
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
}
