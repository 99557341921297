import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  ThirdPartyReportFragmentAnomaly,
  ThirdPartyReportFragmentVarianceAnalysisReport,
  ThirdPartyReportAnomalyValidation,
  User,
} from "@deliver-sense-librarian/data-schema";
import { Papa } from "ngx-papaparse";
import * as _ from "lodash";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatCheckboxChange } from "@angular/material/checkbox";
import * as moment from "moment";
import { downloadDataAsFile } from "app/shared/ds-constant";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { takeUntil, catchError } from "rxjs/operators";
import { PopoverDirective } from "ngx-bootstrap/popover";
import { of, Subject, BehaviorSubject } from "rxjs";
import { ConfirmDialogComponent } from "app/dialogs/confirm-dialog/confirm-dialog.component";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import {
  ReconciliationDrillDownReportTypes,
  ReconciliationReportDatUtility,
} from "../../utilities/reconciliation-report-data.utility";
@Component({
  selector: "app-anomaly-analysis",
  templateUrl: "./anomaly-analysis.component.html",
  styleUrls: ["./anomaly-analysis.component.scss"],
})
export class AnomalyAnalysisComponent implements OnInit, OnDestroy {
  @Input() exportUtility: ReconciliationReportExportUtility;
  @Input() dataUtility: ReconciliationReportDatUtility;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  thirdPartyReportVarianceAnalysisReports: ThirdPartyReportFragmentVarianceAnalysisReport[];
  anomalyValidations: ThirdPartyReportAnomalyValidation[] = [];
  anomalyValidationsSubject = new BehaviorSubject([]);
  tableData: MatTableDataSource<any>;
  anomalyValidationControl = new FormControl("");
  validationToEdit: ThirdPartyReportAnomalyValidation;
  anomalies: ThirdPartyReportFragmentAnomaly[];
  anomalyReports: ThirdPartyReportFragmentVarianceAnalysisReport[] = [];
  displayedColumns: string[] = [
    "location",
    "thirdParty",
    "anomalies",
    "anomalyVerified",
    "anomalyNotes",
  ];
  activePopover: PopoverDirective;
  destroy$ = new Subject();
  loadingAnomalyValidations: boolean;
  filter3pdControl = new FormControl("");
  filterValidationControl = new FormControl(false);
  mutableAnomalyReports: ThirdPartyReportFragmentVarianceAnalysisReport[] = [];
  currentFilter: string;
  constructor(
    private cdr: ChangeDetectorRef,
    private afs: AngularFirestore,
    private papa: Papa,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}
  ngOnInit(): void {
    this.initializeReport();
    this.listenForFilters();
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  private listenForFilters() {
    this.filter3pdControl.valueChanges.subscribe((dspFilterId) => {
      const hideValidated = this.filterValidationControl.value;
      this.filterData(dspFilterId, hideValidated);
    });
    this.filterValidationControl.valueChanges.subscribe((hideValidated) => {
      const dspIdFilter = this.filter3pdControl.value;
      this.filterData(dspIdFilter, hideValidated);
    });
  }
  private filterData(dspId?: string, hideValidated?: boolean) {
    this.tableData = null;
    let mutableAnomalyReports = Object.assign([], this.anomalyReports);
    if (dspId) {
      mutableAnomalyReports = mutableAnomalyReports.filter((anomalyReport) => {
        return anomalyReport.thirdParty === dspId;
      });
    }
    if (hideValidated) {
      mutableAnomalyReports = mutableAnomalyReports.filter((anomalyReport) => {
        return !anomalyReport.validation?.validated;
      });
    }
    this.mutableAnomalyReports = mutableAnomalyReports;
    setTimeout(() => {
      this.setTableData();
    });
  }
  private async initializeReport() {
    this.loadingAnomalyValidations = true;
    this.thirdPartyReportVarianceAnalysisReports =
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.varianceAnalysisReports
      );
    this.filterVaForAnomalies();
    this.getValidationsAndSetupTable();
  }
  private filterVaForAnomalies() {
    this.anomalyReports = this.thirdPartyReportVarianceAnalysisReports
      .filter((va) => va.anomalies && va.anomalies.length > 0)
      .sort((a, b) => (+a.location < +b.location ? -1 : 1));
  }
  private getValidationsAndSetupTable() {
    this.afs
      .collection("thirdPartyReportAnomalyValidations", (ref) =>
        ref.where("thirdPartyReport", "==", this.dataUtility.existingReport.id)
      )
      .snapshotChanges()
      .pipe(
        takeUntil(this.destroy$),
        catchError(() => {
          this.loadingAnomalyValidations = false;
          this.snackBar.open(
            "Error loading anomaly analysis. Please refresh and try again",
            "Dismiss",
            { duration: 5000 }
          );
          return of();
        })
      )
      .subscribe((validationsQueryResults$) => {
        this.anomalyValidations = FirestoreUtilities.mapToType(
          validationsQueryResults$
        );
        this.anomalyValidationsSubject.next(this.anomalyValidations);
        this.compileTableData();
        if (this.filter3pdControl.value || this.filterValidationControl.value) {
          this.filterData(
            this.filter3pdControl.value,
            this.filterValidationControl.value
          );
        } else {
          this.setTableData();
        }
        this.loadingAnomalyValidations = false;
      });
  }
  private compileTableData() {
    this.anomalyReports.forEach((anomalyReport) => {
      anomalyReport["thirdPartyName"] = this.dataUtility.getThirdPartyName(
        anomalyReport.thirdParty
      );
      const existingValidation = this.anomalyValidations.find((validation) => {
        return (
          validation.location === anomalyReport.location &&
          validation.thirdParty === anomalyReport.thirdParty
        );
      });
      if (existingValidation) {
        anomalyReport["validation"] = existingValidation;
      }
    });
    this.mutableAnomalyReports = this.anomalyReports;
  }
  private setTableData() {
    this.tableData = new MatTableDataSource(this.mutableAnomalyReports);
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.cdr.detectChanges();
    if (this.currentFilter) {
      this.applyFilter(this.currentFilter);
    }
  }
  async saveAnomalyReason() {
    if (this.anomalyValidationControl.valid) {
      if (this.validationToEdit.id) {
        this.updateValidationReason();
      } else {
        this.createValidationReason();
      }
      this.activePopover.hide();
      this.activePopover = null;
      this.anomalyValidationControl.reset();
      this.anomalyValidationControl.updateValueAndValidity();
    }
  }
  async updateValidationReason() {
    const message = this.anomalyValidationControl.value
      ? this.anomalyValidationControl.value
      : null;
    await this.afs
      .doc(`thirdPartyReportAnomalyValidations/${this.validationToEdit.id}`)
      .update({ message: message, dateUpdated: moment().toDate() });
  }
  async createValidationReason() {
    this.validationToEdit.message = this.anomalyValidationControl.value;
    await this.afs
      .collection("thirdPartyReportAnomalyValidations")
      .add(this.validationToEdit.toJSONObject());
  }
  getAnomaliesText(anomalies: ThirdPartyReportFragmentAnomaly[]) {
    if (anomalies?.length > 0) {
      return anomalies.reduce((prev, anomaly) => {
        return `${prev} <li>${
          anomaly.date ? moment(anomaly.date.toDate()).format("MM/DD/YYYY") : ""
        } - ${anomaly.type}: 3PD ${anomaly.thirdPartyTransactionsCount}, POS ${
          anomaly.posTransactionCount
        }${anomaly.description ? ` (${anomaly.description})` : ""}</li>`;
      }, "");
    }
  }
  getAnomaliesCsv(anomalies: any) {
    const anomaliesText = anomalies.map((anomaly) => {
      return anomaly.date
        ? `${moment(anomaly.date.toDate()).format("MM/DD/YYYY")} - ${
            anomaly.type
          }: 3PD ${anomaly.thirdPartyTransactionsCount}, POS ${
            anomaly.posTransactionCount
          }${anomaly.description ? ` (${anomaly.description})` : ""}`
        : "";
    });
    return _.join(anomaliesText, "\n");
  }
  exportReport() {
    const data = Object.assign(
      this.tableData.data.map(
        (row: ThirdPartyReportFragmentVarianceAnalysisReport) => {
          const formatted = {
            Location: row.location,
            "Third Party": row["thirdPartyName"],
            Anomalies: this.getAnomaliesCsv(row.anomalies),
            Verified: row.anomalyVerified,
            Notes: row.anomalyReason,
          };
          return formatted;
        }
      )
    );
    const results = this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const fileName = `${this.dataUtility.existingReport.name}_Anomaly_Report`;
    downloadDataAsFile(results, fileName, "csv");
  }
  private createNewAnomalyValidation(
    anomaly: ThirdPartyReportFragmentVarianceAnalysisReport
  ) {
    const thirdPartyReportAnomalyValidation =
      new ThirdPartyReportAnomalyValidation();
    thirdPartyReportAnomalyValidation.location = anomaly.location;
    thirdPartyReportAnomalyValidation.thirdParty = anomaly.thirdParty;
    thirdPartyReportAnomalyValidation.validatedBy = this.dataUtility.user.id;
    thirdPartyReportAnomalyValidation.thirdPartyReport =
      this.dataUtility.existingReport.id;
    thirdPartyReportAnomalyValidation.client = this.dataUtility.client.id;
    thirdPartyReportAnomalyValidation.validated = false;
    return thirdPartyReportAnomalyValidation;
  }
  setAnomalyValidationToEdit(anomaly, activePopover: PopoverDirective) {
    this.validationToEdit = anomaly.validation
      ? anomaly.validation
      : this.createNewAnomalyValidation(anomaly);
    this.activePopover = activePopover;
    this.anomalyValidationControl.patchValue(this.validationToEdit.message);
    this.anomalyValidationControl.updateValueAndValidity();
  }
  async updateAnomalyVerification(
    event: MatCheckboxChange,
    vaReport: ThirdPartyReportFragmentVarianceAnalysisReport
  ) {
    if (vaReport["validation"]) {
      await this.afs
        .doc(`thirdPartyReportAnomalyValidations/${vaReport["validation"].id}`)
        .update({
          validated: event.checked ? true : false,
        });
    } else {
      const validation = this.createNewAnomalyValidation(vaReport);
      validation.validated = event.checked;
      await this.afs
        .collection("thirdPartyReportAnomalyValidations")
        .add(validation.toJSONObject());
    }
    this.snackBar.open("Anomaly verification updated.", "Dismiss", {
      duration: 5000,
    });
  }
  public applyFilter(filterValue: string) {
    this.currentFilter = filterValue;
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  async deleteValidation(validation: ThirdPartyReportAnomalyValidation) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Delete Validation",
        message: "Are you sure you want delete this validation?.",
        action: "Yes, Delete.",
      },
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs
          .doc(`thirdPartyReportAnomalyValidations/${validation.id}`)
          .delete();
        this.snackBar.open("Validation deleted successfully!", "Dismiss", {
          duration: 5000,
        });
      }
    });
  }
}
