import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ReconciliationReportDatUtility } from "../../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../../utilities/reconciliation-report-filtering.utility";
import { FormControl } from "@angular/forms";
import { AfterViewInit } from "@angular/core";
import { ThirdPartyPerformanceSummaryReport } from "@deliver-sense-librarian/data-schema";
import { ReconciliationReportExportUtility } from "../../../utilities/reconciliation-report-export.utility";
import { TransactionsDialogComponent } from "app/dialogs/transactions-dialog/transactions-dialog.component";

@Component({
  selector: "app-third-party-performance-summary-table",
  templateUrl: "./third-party-performance-summary-table.component.html",
  styleUrls: ["./third-party-performance-summary-table.component.scss"],
})
export class ThirdPartyPerformanceSummaryTableComponent
  implements OnInit, AfterViewInit
{
  @Input() data: ThirdPartyPerformanceSummaryReport[] = [];
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @Input() exportUtility: ReconciliationReportExportUtility;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<ThirdPartyPerformanceSummaryReport>;
  public displayedColumns: string[] = [
    "location",
    "thirdParty",
    "grossSales",
    "netSales",
    "transactionCount",
    "errorChargeCount",
    "errorChargeCountRate",
    "errorChargeAmount",
    "errorChargeAmountRate",
    "canceledOrderCount",
    "canceledOrderCountRate",
    "canceledOrderAmount",
    "canceledOrderAmountRate",
  ];
  public filter3pdsControl = new FormControl([]);
  public filterLocationsControl = new FormControl([]);
  constructor(private dialog: MatDialog) {}
  ngOnInit(): void {
    this.tableData = new MatTableDataSource(this.data);
  }
  ngAfterViewInit(): void {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.filteringUtility.set3pdAndLocationFilterPredicate(
      this.tableData,
      this.filter3pdsControl,
      this.filterLocationsControl
    );
  }

  public async openTransactionViewer(
    report: ThirdPartyPerformanceSummaryReport
  ) {
    this.dialog.open(TransactionsDialogComponent, {
      panelClass: "invisible-panel-dialog",
      data: {
        transactions: report.canceledOrdersLog,
      },
    });
  }
}
