import { AngularFirestore } from "@angular/fire/firestore";
import {
  ThirdPartyReportFragment,
  Location,
  TransactionAITypes,
  ThirdParty,
  ThirdPartyReconciliationLocationData,
  ThirdPartyReport,
  Client3pdConfiguration,
  ClientModule,
  ThirdPartyReportFragmentTransactionAnalysis,
  ThirdPartyReportFragmentCategoryReport,
  ThirdPartyReportFragmentVarianceAnalysisReport,
  ThirdPartyReportFragmentDailyDrillDownReport,
  ThirdPartyReportFragmentFeeReport,
  ThirdPartyReportFragmentErrorChargeLog,
  ThirdPartyReportFragmentDataLog,
  ThirdPartyReportFragmentPayoutReport,
  ThirdPartyReportFragmentTaxRateReport,
  ThirdPartyTransaction,
  ThirdPartyDeliveryIds,
  User,
  ThirdPartyReportFragmentDispatchReport,
  ThirdPartyCategoryReport,
  Client,
  ThirdPartyRating,
  ThirdPartyPerformanceSummaryReport,
  ThirdPartyReportNetCash,
  DsModuleAddons,
  ThirdPartyReportFragmentAdjustmentLog,
  PriorPeriodAdjustmentTypes,
  PriorPeriodAdjustmentTransaction,
  ThirdPartyReportCanceledOrderDisputeLog,
  PayoutReportFragmentLog,
  ThirdPartyAnalyticsSummary,
  ThirdPartyReportNote,
  UserView,
} from "@deliver-sense-librarian/data-schema";
import _ from "lodash";
import { FirestoreUtilities } from "../../../../utilities/firestore-utilities";
import bluebird from "bluebird";
import moment from "moment";
import { first } from "rxjs/operators";
import { Subject, firstValueFrom, lastValueFrom } from "rxjs";

export enum ReconciliationDrillDownReportTypes {
  "statusReports" = "statusReports",
  "varianceAnalysisReports" = "varianceAnalysisReports",
  "transactionAnalysisReports" = "transactionAnalysisReports",
  "dailyDrillDowns" = "dailyDrillDowns",
  "feeReports" = "feeReports",
  "errorChargeLogs" = "errorChargeLogs",
  "adjustmentLogs" = "adjustmentLogs",
  "dataLogs" = "dataLogs",
  "payoutAnalysisReports" = "payoutAnalysisReports",
  "taxRateAnalysisReports" = "taxRateAnalysisReports",
  "thirdPartyPerformanceSummaryReport" = "thirdPartyPerformanceSummaryReport",
  "depositReports" = "depositReports",
  "canceledOrderDisputeLogs" = "thirdPartyReportCanceledOrderDisputeLogs",
}

export class ReconciliationReportDatUtility {
  public endDateText: string;
  public startDateText: string;
  public get clientThirdPartyReportingModule(): ClientModule {
    return this._clientThirdPartyReportingModule;
  }
  public set clientThirdPartyReportingModule(value: ClientModule) {
    this._clientThirdPartyReportingModule = value;
  }
  public get user(): User {
    return this._user;
  }
  public set user(value: User) {
    this._user = value;
  }
  public filteredThirdParties: ThirdParty[] = [];
  public get client3pdConfiguration(): Client3pdConfiguration {
    return this._client3pdConfiguration;
  }
  public set client3pdConfiguration(value: Client3pdConfiguration) {
    this._client3pdConfiguration = value;
  }
  public get existingReport(): ThirdPartyReport {
    return this._existingReport;
  }
  public set existingReport(value: ThirdPartyReport) {
    this._existingReport = value;
  }
  public get client(): Client {
    return this._client;
  }
  public set client(value: Client) {
    this._client = value;
  }
  public get thirdParties(): ThirdParty[] {
    return this._thirdParties;
  }
  public set thirdParties(value: ThirdParty[]) {
    this._thirdParties = value;
  }
  public get locations(): Location[] {
    return this._locations;
  }
  public set locations(value: Location[]) {
    this._locations = value;
  }

  public errorChargeTransactions: ThirdPartyTransaction[] = [];
  public errorChargeTransactionsLoaded = false;
  public bankTransactions = [];
  public bankTransactionsLoaded = false;
  public analyticsSummaries: ThirdPartyAnalyticsSummary[] = [];
  public analyticsSummariesLoaded = false;

  public reconciliationDrillDownReportTypeMappings = {
    statusReports: {
      collection: "thirdPartyReportFragmentCategoryReports",
      loadedIndicator: false,
      data: <ThirdPartyReportFragmentCategoryReport[]>[],
    },
    varianceAnalysisReports: {
      collection: "thirdPartyReportFragmentVarianceAnalysisReports",
      loadedIndicator: false,
      data: <ThirdPartyReportFragmentVarianceAnalysisReport[]>[],
    },

    dailyDrillDowns: {
      collection: "thirdPartyReportFragmentDailyDrillDownReports",
      loadedIndicator: false,
      data: <ThirdPartyReportFragmentDailyDrillDownReport[]>[],
    },
    depositReports: {
      collection: "payoutReportFragmentLogs",
      loadedIndicator: false,
      data: <PayoutReportFragmentLog[]>[],
    },
    feeReports: {
      collection: "thirdPartyReportFragmentFeeReports",
      loadedIndicator: false,
      data: <ThirdPartyReportFragmentFeeReport[]>[],
    },
    adjustmentLogs: {
      collection: "thirdPartyReportFragmentAdjustmentLogs",
      loadingIndicator: false,
      data: <ThirdPartyReportFragmentAdjustmentLog[]>[],
    },
    errorChargeLogs: {
      collection: "thirdPartyReportFragmentErrorChargeLogs",
      loadedIndicator: false,
      data: <ThirdPartyReportFragmentErrorChargeLog[]>[],
    },
    dataLogs: {
      collection: "thirdPartyReportFragmentDataLogs",
      loadedIndicator: false,
      data: <ThirdPartyReportFragmentDataLog[]>[],
    },
    payoutAnalysisReports: {
      collection: "thirdPartyReportFragmentPayoutReports",
      loadedIndicator: false,
      data: <ThirdPartyReportFragmentPayoutReport[]>[],
    },
    taxRateAnalysisReports: {
      collection: "thirdPartyReportFragmentTaxRateReports",
      loadedIndicator: false,
      data: <ThirdPartyReportFragmentTaxRateReport[]>[],
    },
    customerRatings: {
      collection: "thirdPartyRatings",
      loadingIndicator: false,
      data: <ThirdPartyRating[]>[],
    },
    thirdPartyPerformanceSummaryReport: {
      collection: "thirdPartyPerformanceSummaryReports",
      loadingIndicator: false,
      data: <ThirdPartyPerformanceSummaryReport[]>[],
    },
    priorPeriodAdjustments: {
      collection: "priorPeriodAdjustmentTransactions",
      loadingIndicator: false,
      data: <PriorPeriodAdjustmentTransaction[]>[],
    },
    canceledOrderDisputeLogs: {
      collection: "thirdPartyReportCanceledOrderDisputeLogs",
      loadedIndicator: false,
      data: <ThirdPartyReportCanceledOrderDisputeLog[]>[],
    },
  };

  constructor(
    public afs: AngularFirestore,
    private _user: User,
    private _locations: Location[],
    private _thirdParties: ThirdParty[],
    private _client: Client,
    private _existingReport: ThirdPartyReport,
    private _client3pdConfiguration: Client3pdConfiguration,
    private _clientThirdPartyReportingModule: ClientModule,
    public reportFragments: ThirdPartyReportFragment[],
    public reportData: ThirdPartyReconciliationLocationData[],
    public destroy: Subject<any>
  ) {
    this.filteredThirdParties = _thirdParties;
    if (!_existingReport.includeDispatchInRec) {
      this.filteredThirdParties = _thirdParties.filter(
        (dsp) => dsp.id !== ThirdPartyDeliveryIds["Olo Dispatch"]
      );
    }
    this.setRelativeStartAndEndDates();
  }
  setRelativeStartAndEndDates() {
    const currentOffset = moment().format("Z");
    this.endDateText = moment(this.existingReport.endDate.toDate())
      .utc()
      .utcOffset(
        this.existingReport.creatorTimezone
          ? this.existingReport.creatorTimezone
          : currentOffset
      )
      .format("l");
    this.startDateText = moment(this.existingReport.startDate.toDate())
      .utc()
      .utcOffset(
        this.existingReport.creatorTimezone
          ? this.existingReport.creatorTimezone
          : currentOffset
      )
      .format("l");
  }
  /**
   *
   */
  public async fetchThirdPartyRatings(locations?: Location[]) {
    if (
      !this.reconciliationDrillDownReportTypeMappings.customerRatings
        .loadingIndicator
    ) {
      const valid3PDs = [
        `${ThirdPartyDeliveryIds.DoorDash}`,
        `${ThirdPartyDeliveryIds.GrubHub}`,
        `${ThirdPartyDeliveryIds["Uber Eats"]}`,
      ];
      const thirdPartiesWithRatings = this.thirdParties.filter((dsp) => {
        return (
          valid3PDs.indexOf(dsp.id) !== -1 ||
          valid3PDs.indexOf(`${dsp.duplicateOf}`) !== -1
        );
      });
      const startDate = moment(this.existingReport.startDate.toDate())
        .startOf("day")
        .toDate();
      const endDate = moment(this.existingReport.endDate.toDate())
        .endOf("day")
        .toDate();
      const ratingsQueries$ = _.flatten(
        thirdPartiesWithRatings.map((dsp) => {
          return _.chunk(
            this.locations.map((l) => l.locationId),
            10
          ).map((locationIdChunk) => {
            return lastValueFrom(
              this.afs
                .collection("thirdPartyRatings", (ref) =>
                  ref
                    .where("client", "==", this.client.id)
                    .where("thirdParty", "==", dsp.id)
                    .where("location", "in", locationIdChunk)
                    .where("date", ">=", startDate)
                    .where("date", "<=", endDate)
                )
                .snapshotChanges()
                .pipe(first())
            );
          });
        })
      );
      const ratings = FirestoreUtilities.mergeCollectionToType(
        await Promise.all(ratingsQueries$)
      );
      this.reconciliationDrillDownReportTypeMappings.customerRatings.loadingIndicator =
        true;
      this.reconciliationDrillDownReportTypeMappings.customerRatings.data =
        ratings;
    }
    if (locations?.length > 0) {
      return this.reconciliationDrillDownReportTypeMappings.customerRatings.data.filter(
        (report) =>
          !!locations.find(
            (location) => location.locationId === report.location
          )
      );
    }
    return this.reconciliationDrillDownReportTypeMappings.customerRatings.data;
  }
  /**
   * FRAGMENTED REPORT COMPILATION FLOW START
   */
  public async fetchReconciliationReportDrillDownFragments(
    reportType: ReconciliationDrillDownReportTypes,
    locations?: Location[]
  ) {
    const fragmentRequests: Promise<any>[] = [];
    const fragmentReferenceField =
      reportType === ReconciliationDrillDownReportTypes.depositReports
        ? "reportFragment"
        : "thirdPartyReportFragment";
    const isCollectionLoaded =
      this.reconciliationDrillDownReportTypeMappings[reportType]
        .loadedIndicator;
    if (!isCollectionLoaded) {
      _.chunk(this.reportFragments, 10).forEach(
        (fragmentChunk: ThirdPartyReportFragment[]) => {
          fragmentRequests.push(
            this.afs
              .collection(
                this.reconciliationDrillDownReportTypeMappings[reportType]
                  .collection,
                (ref) =>
                  ref
                    .where(
                      fragmentReferenceField,
                      "in",
                      fragmentChunk.map((fragment) => fragment.id)
                    )
                    .where("client", "==", this.client.id)
              )
              .snapshotChanges()
              .pipe(first())
              .toPromise()
          );
        }
      );
      return this.assignFragments(fragmentRequests, reportType, locations);
    } else {
      if (locations?.length > 0) {
        return this.reconciliationDrillDownReportTypeMappings[
          reportType
        ].data.filter(
          (report) =>
            !!locations.find(
              (location) => location.locationId === report.location
            )
        );
      }
      return this.reconciliationDrillDownReportTypeMappings[reportType].data;
    }
  }

  private async assignFragments(
    fragmentRequests: Promise<any>[],
    reportType: ReconciliationDrillDownReportTypes,
    locations?: Location[]
  ) {
    const resolvedRequests = _.flatten(await Promise.all(fragmentRequests));
    this.reconciliationDrillDownReportTypeMappings[reportType].data =
      FirestoreUtilities.mapToType(resolvedRequests).filter(
        (report) =>
          !!this.locations.find(
            (location) => location.locationId === report.location
          )
      );
    this.reconciliationDrillDownReportTypeMappings[reportType].loadedIndicator =
      true;
    // Set Third Party Name
    this.reconciliationDrillDownReportTypeMappings[reportType].data.forEach(
      (row) => {
        if (row.thirdParty) {
          row.thirdPartyName = this.getThirdPartyName(row.thirdParty);
        }
      }
    );
    const dataToReturn =
      this.reconciliationDrillDownReportTypeMappings[reportType].data;

    if (!!locations && locations.length > 0) {
      return dataToReturn.filter(
        (report) =>
          !!locations.find(
            (location) => location.locationId === report.location
          )
      );
    }
    return dataToReturn;
  }

  /**
   * COMPILED BREAKOUT REPORTS
   */
  public async compileCategoryReports(
    type: "status" | "fees",
    locations?: Location[]
  ) {
    let reportSet;
    if (type === "status") {
      if (
        !this.reconciliationDrillDownReportTypeMappings.statusReports
          .loadedIndicator
      ) {
        reportSet = await this.fetchReconciliationReportDrillDownFragments(
          ReconciliationDrillDownReportTypes.statusReports
        );
      } else {
        reportSet =
          this.reconciliationDrillDownReportTypeMappings.statusReports.data;
      }
    } else {
      if (
        !this.reconciliationDrillDownReportTypeMappings.feeReports
          .loadedIndicator
      ) {
        reportSet = await this.fetchReconciliationReportDrillDownFragments(
          ReconciliationDrillDownReportTypes.feeReports
        );
      } else {
        reportSet =
          this.reconciliationDrillDownReportTypeMappings.feeReports.data;
      }
    }
    if (!!reportSet && !!locations && locations.length > 0) {
      reportSet = reportSet.filter(
        (report) =>
          !!locations.find(
            (location) => location.locationId === report.location
          )
      );
    }
    let categoryReports: ThirdPartyCategoryReport[] = [];
    if (reportSet) {
      categoryReports = _.flatten(
        reportSet.map(
          (fragmentCategoryReport: ThirdPartyReportFragmentCategoryReport) => {
            const categoryReports =
              fragmentCategoryReport.categoryReports as ThirdPartyCategoryReport[];
            return categoryReports.map((categoryReport) => {
              return {
                location: fragmentCategoryReport.location,
                thirdPartyName: this.getThirdPartyName(
                  fragmentCategoryReport.thirdParty
                ),
                ...categoryReport,
              };
            });
          }
        )
      );
    }
    return categoryReports;
  }
  public async compileDailyDrillDownBreakoutReports(
    type:
      | "transactionAnalysis"
      | "fraudIndicators"
      | "errorTransactionAnalysis",
    locations?: Location[]
  ) {
    // CHECK IF DAILY DRILL DOWNS HAVE BEEN LOADED.. IF NOT, INITIATE LOAD
    if (
      !this.reconciliationDrillDownReportTypeMappings.dailyDrillDowns
        .loadedIndicator
    ) {
      await this.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.dailyDrillDowns
      );
    }
    let reports: ThirdPartyReportFragmentTransactionAnalysis[];
    switch (type) {
      case "transactionAnalysis":
        reports = this.compileTransactionAnalysisReports();
        break;
      case "fraudIndicators":
        reports = this.compilePotentialFraudIndicators();
        break;
      case "errorTransactionAnalysis":
        reports = this.compileErrorTransactionAnalysis();
        break;
    }
    if (!!locations && locations.length > 0) {
      return reports.filter(
        (report) =>
          !!locations.find(
            (location) => location.locationId === report.location
          )
      );
    }
    return reports;
  }
  private compileTransactionAnalysisReports(): ThirdPartyReportFragmentTransactionAnalysis[] {
    const transactionAnalysisReports: ThirdPartyReportFragmentTransactionAnalysis[] =
      _.flatten(
        this.reconciliationDrillDownReportTypeMappings.dailyDrillDowns.data.map(
          (dayDrillDown) => {
            return _.flatten(
              dayDrillDown.report
                .map((report) => {
                  if (report.analysis?.length > 0) {
                    return report.analysis.filter(
                      (
                        analysis: ThirdPartyReportFragmentTransactionAnalysis
                      ) => {
                        return (
                          !!analysis &&
                          analysis.type &&
                          analysis.type !== TransactionAITypes.pricingIssues &&
                          analysis.type !== TransactionAITypes.errorCharge &&
                          analysis.type !== TransactionAITypes.potentialFraud &&
                          analysis.description !== "Ad Spend"
                        );
                      }
                    );
                  } else {
                    return null;
                  }
                })
                .filter((row) => !!row)
            );
          }
        )
      );
    return transactionAnalysisReports;
  }
  public compilePotentialFraudIndicators(): ThirdPartyReportFragmentTransactionAnalysis[] {
    return _.flatten(
      this.reconciliationDrillDownReportTypeMappings.dailyDrillDowns.data.map(
        (dayDrillDown) => {
          return _.flatten(
            dayDrillDown.report
              .map((report) => {
                if (report.analysis?.length > 0) {
                  return report.analysis.filter((analysis) => {
                    return (
                      !!analysis &&
                      analysis.type &&
                      analysis.type === TransactionAITypes.potentialFraud
                    );
                  });
                }
              })
              .filter((row) => !!row)
          );
        }
      )
    );
  }
  public compileErrorTransactionAnalysis(): ThirdPartyReportFragmentTransactionAnalysis[] {
    return _.flatten(
      this.reconciliationDrillDownReportTypeMappings.dailyDrillDowns.data.map(
        (dayDrillDown) => {
          return _.flatten(
            dayDrillDown.report
              .map((report) => {
                if (report.analysis?.length > 0) {
                  return report.analysis.filter((analysis) => {
                    return (
                      !!analysis &&
                      analysis.type &&
                      analysis.type === TransactionAITypes.errorCharge
                    );
                  });
                }
              })
              .filter((row) => !!row)
          );
        }
      )
    );
  }

  /**
   * SUPPORTING DATA RETRIEVALS
   */

  public async fetchCanceledOrderDisputeLogs() {
    if (
      !this.reconciliationDrillDownReportTypeMappings.canceledOrderDisputeLogs
        .loadedIndicator
    ) {
      const canceledOrderDisputeLogs = FirestoreUtilities.mapToType(
        await this.afs
          .collection("thirdPartyReportCanceledOrderDisputeLogs", (ref) =>
            ref
              .where("thirdPartyReport", "==", this.existingReport.id)
              .where("client", "==", this.client.id)
          )
          .snapshotChanges()
          .pipe(first())
          .toPromise()
      );
      this.reconciliationDrillDownReportTypeMappings.canceledOrderDisputeLogs.data =
        canceledOrderDisputeLogs;
      this.reconciliationDrillDownReportTypeMappings.canceledOrderDisputeLogs.loadedIndicator =
        true;
    }
    return this.reconciliationDrillDownReportTypeMappings
      .canceledOrderDisputeLogs.data;
  }

  public async fetchAnomalyValidations() {
    return FirestoreUtilities.mapToType(
      await this.afs
        .collection("thirdPartyReportAnomalyValidations", (ref) =>
          ref
            .where("thirdPartyReport", "==", this.existingReport.id)
            .where("client", "==", this.client.id)
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise()
    );
  }
  async fetchDispatchSummaryData(): Promise<
    ThirdPartyReportFragmentDispatchReport[]
  > {
    return FirestoreUtilities.mergeCollectionToType(
      await Promise.all(
        this.reportFragments.map((fragment) => {
          return this.afs
            .collection("thirdPartyReportFragmentDispatchReports", (ref) =>
              ref
                .where("thirdPartyReportFragment", "==", fragment.id)
                .where("client", "==", fragment.client)
            )
            .snapshotChanges()
            .pipe(first())
            .toPromise();
        })
      )
    );
  }
  async fetchErrorChargeTransactions(_locations?: Location[]) {
    const locations = _locations ? _locations : this.locations;
    if (!this.errorChargeTransactionsLoaded) {
      if (
        !this.reconciliationDrillDownReportTypeMappings.errorChargeLogs
          .loadedIndicator
      ) {
        await this.fetchReconciliationReportDrillDownFragments(
          ReconciliationDrillDownReportTypes.errorChargeLogs
        );
      }
      const thirdPartyErrorTransactionAnalysis =
        await this.compileDailyDrillDownBreakoutReports(
          "errorTransactionAnalysis"
        );
      const locationIds = locations.map((location) => location.locationId);
      const thirdPartyIds = this.thirdParties.map(
        (thirdParty) => thirdParty.id
      );
      const filteredAnalysisReports = thirdPartyErrorTransactionAnalysis.filter(
        (fragmentCategoryReport) => {
          return (
            !!locationIds.find(
              (locationId) => locationId === fragmentCategoryReport.location
            ) &&
            !!thirdPartyIds.find(
              (tpId) => tpId === fragmentCategoryReport.thirdParty
            )
          );
        }
      );
      if (filteredAnalysisReports.length > 0) {
        this.thirdParties.forEach((thirdParty) => {
          if (thirdParty.duplicateOf) {
            const parent = this.thirdParties.find(
              (dsp) => dsp.id === thirdParty.duplicateOf
            );
            if (parent) {
              this.mergeParentandChildDspConfigs(thirdParty, parent);
            }
          }
        });
        const parentThirdPartyIds = _.uniq(
          this.filteredThirdParties.map((dsp) => dsp.duplicateOf)
        );
        let parentThirdParties = [];
        if (parentThirdPartyIds?.length > 0) {
          await Promise.all(
            parentThirdPartyIds.map(async (dspId) => {
              parentThirdParties.push(
                FirestoreUtilities.objectToType(
                  await lastValueFrom(
                    this.afs
                      .doc(`thirdParties/${dspId}`)
                      .snapshotChanges()
                      .pipe(first())
                  )
                )
              );
              return;
            })
          );
        }
        const errorChargeCodes = _.flatten(
          this.filteredThirdParties.map((thirdParty) => {
            if (thirdParty.errorChargeCodes?.length > 0) {
              return thirdParty.errorChargeCodes.map((errorChargeCode) => {
                return { ...errorChargeCode, thirdParty: thirdParty.id };
              });
            } else if (thirdParty.duplicateOf) {
              return parentThirdParties
                .find((parentDsp) => parentDsp.id === thirdParty.duplicateOf)
                .errorChargeCodes.map((errorChargeCode) => {
                  return { ...errorChargeCode, thirdParty: thirdParty.id };
                });
            } else {
              return [];
            }
          })
        ).filter((errorChargeCode) => !!errorChargeCode.field);
        const start = moment(this.existingReport.startDate.toDate())
          .startOf("day")
          .toDate();
        const end = moment(this.existingReport.endDate.toDate())
          .endOf("day")
          .toDate();

        const errorChargeTransactionRequests = errorChargeCodes.map(
          (errorChargeCode) => {
            return lastValueFrom(
              this.afs
                .collection("thirdPartyTransactions", (ref) =>
                  ref
                    .where("thirdParty", "==", errorChargeCode.thirdParty)
                    .where("client", "==", this.client.id)
                    .where(errorChargeCode.field, "==", errorChargeCode.code)
                    .where("date", ">=", start)
                    .where("date", "<=", end)
                )
                .snapshotChanges()
                .pipe(first())
            );
          }
        );
        const transactionQueryResults = _.flatten(
          await bluebird.mapSeries(
            errorChargeTransactionRequests,
            async (errorChargeRequest) => {
              return await errorChargeRequest;
            }
          )
        );
        this.errorChargeTransactions = <ThirdPartyTransaction[]>(
          FirestoreUtilities.mergeToType(transactionQueryResults)
            .filter(
              (transaction) =>
                !!locationIds.find(
                  (locationId) => locationId === transaction.location
                )
            )
            .sort((a, b) => (a.transactionId < b.transactionId ? -1 : 1))
        );

        this.errorChargeTransactions.forEach((transaction) => {
          const date = transaction.date.toDate();
          const errorReport = thirdPartyErrorTransactionAnalysis.find(
            (report) => report.transaction === transaction.id
          );
          if (errorReport) {
            transaction["amount"] = errorReport.sale;
          }
          const errorLog =
            this.reconciliationDrillDownReportTypeMappings.errorChargeLogs.data.find(
              (errorChargeLog) =>
                errorChargeLog.errorChargeTransaction === transaction.id
            );
          if (errorLog) {
            transaction["errorChargeLog"] = errorLog;
          }
          transaction["thirdPartyName"] = this.getThirdPartyName(
            transaction.thirdParty
          );
        });
        this.errorChargeTransactions = _.uniqBy(
          this.errorChargeTransactions,
          "id"
        );
        this.errorChargeTransactionsLoaded = true;
      }
    }
    if (this.locations?.length > 0) {
      return this.errorChargeTransactions.filter(
        (transaction) =>
          !!locations.find(
            (location) => location.locationId === transaction.location
          )
      );
    }
    return this.errorChargeTransactions;
  }
  /**
   * HELPER FUNCTIONS
   */
  getRoundedRate(rate) {
    return (+rate ? +rate * 100 : 0).toFixed(2);
  }
  getVariance(a: number, b: number) {
    return +(a - b).toFixed(2);
  }
  public getThirdPartyName(thirdPartyId) {
    if (thirdPartyId) {
      const tp = this.thirdParties.find(
        (_tp) => !!_tp && _tp.id === thirdPartyId
      );
      return tp ? tp.name : "";
    }
    return "";
  }

  public mergeParentandChildDspConfigs(child: ThirdParty, parent: ThirdParty) {
    let errorChargeCodes = parent.errorChargeCodes;
    if (errorChargeCodes) {
      errorChargeCodes.forEach((errorChargeCode) => {
        const matchingChildErrorChargeCode = child.errorChargeCodes.find(
          (childErrorChargeCode) =>
            childErrorChargeCode.field === errorChargeCode.field
        );
        if (matchingChildErrorChargeCode) {
          errorChargeCode = matchingChildErrorChargeCode;
        }
      });
    }
    let adjustmentCodes = parent.adjustmentCodes;
    if (adjustmentCodes) {
      adjustmentCodes.forEach((adjustmentCode) => {
        const matchingChildAdjustmentCode = child.adjustmentCodes.find(
          (childAdjustmentCode) =>
            childAdjustmentCode.field === adjustmentCode.field
        );
        if (matchingChildAdjustmentCode) {
          adjustmentCode = matchingChildAdjustmentCode;
        }
      });
    }
    child.errorChargeCodes = errorChargeCodes;
    child.adjustmentCodes = adjustmentCodes;
  }
  public async fetchAndAssignBankTransactions() {
    if (
      !this.reconciliationDrillDownReportTypeMappings.payoutAnalysisReports
        .loadedIndicator
    ) {
      await this.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.payoutAnalysisReports
      );
    }
    if (!this.bankTransactionsLoaded) {
      this.bankTransactions = FirestoreUtilities.mapToType(
        await this.fetchBankTransactions()
      );
      this.bankTransactions.forEach((bankTransaction) => {
        const payoutReport = new ThirdPartyReportFragmentPayoutReport();
        payoutReport.thirdParty = bankTransaction.thirdParty;
        payoutReport["bankAmount"] = bankTransaction.amount;
        payoutReport["bankDetails"] = bankTransaction.details;
        payoutReport.payoutDate = moment(bankTransaction.date.toDate()).format(
          "M/DD/YYYY"
        );
        payoutReport.location = bankTransaction.source;
        this.reconciliationDrillDownReportTypeMappings.payoutAnalysisReports.data.push(
          payoutReport
        );
      });
      this.bankTransactionsLoaded = true;
    }
    return this.bankTransactions;
  }
  private async fetchBankTransactions() {
    return this.afs
      .collection("bankPayoutTransactions", (ref) =>
        ref
          .where("client", "==", this.client.id)
          .where(
            "date",
            ">=",
            moment(this.existingReport.startDate.toDate())
              .startOf("day")
              .toDate()
          )
          .where(
            "date",
            "<=",
            moment(this.existingReport.endDate.toDate()).endOf("day").toDate()
          )
      )
      .snapshotChanges()
      .pipe(first())
      .toPromise();
  }

  public runNetCashCalcs(
    reconciliation: ThirdPartyReconciliationLocationData,
    varianceAnalysis: ThirdPartyReportFragmentVarianceAnalysisReport
  ) {
    const netCashReport = new ThirdPartyReportNetCash();
    const location = this.locations.find(
      (location) => location.locationId === reconciliation.locationId
    );
    if (location) {
      netCashReport.location = location.locationId;
      netCashReport.thirdParty = reconciliation.thirdParty;
      netCashReport["thirdPartyName"] = this.getThirdPartyName(
        reconciliation.thirdParty
      );
      netCashReport.posSales = reconciliation.posNetSales;
      netCashReport.posTax = reconciliation.posNetTax;
      netCashReport.posAdjustments =
        reconciliation.posOtherCharges + reconciliation.posOtherRevenue;
      netCashReport.posTotalCash =
        netCashReport.posSales +
        netCashReport.posTax +
        netCashReport.posAdjustments;

      netCashReport.dspGrossSales = reconciliation.thirdPartyGrossSales;
      netCashReport.dspTips = reconciliation.thirdPartyTips;
      netCashReport.errorCharges = +varianceAnalysis.salesVarianceReasons.find(
        (reason) => reason.type === TransactionAITypes.errorCharge
      )?.sale
        ? +varianceAnalysis.salesVarianceReasons.find(
            (reason) => reason.type === TransactionAITypes.errorCharge
          ).sale * -1
        : 0;
      netCashReport.dspTaxToRestaurant =
        reconciliation.thirdPartyNetTax + reconciliation.marketFacilitatorTax;
      netCashReport.nonPromoFees =
        reconciliation.deliveryFees +
        reconciliation.pickupFees +
        reconciliation.cateringFees;
      netCashReport.promoFees = reconciliation.promoFees;
      netCashReport.dspAdjustments =
        reconciliation.thirdPartyMisc +
        reconciliation.thirdPartyOtherCharges +
        reconciliation.thirdPartyOtherRevenue +
        reconciliation.backupWithholdingTax;
      netCashReport.dspTotalCash =
        netCashReport.dspGrossSales +
        netCashReport.dspTips +
        netCashReport.errorCharges +
        netCashReport.dspTaxToRestaurant +
        netCashReport.dspAdjustments +
        netCashReport.nonPromoFees +
        netCashReport.promoFees;
      netCashReport.cashVariance =
        netCashReport.posTotalCash - netCashReport.dspTotalCash;
      return netCashReport;
    }
  }

  isEcdsAddonActive() {
    return !!this.clientThirdPartyReportingModule?.addons?.find(
      (addon) =>
        addon.moduleAddon === DsModuleAddons["Error Charge Dispute Service"]
    )?.active;
  }
  isCustomerRatingsActive() {
    return !!this.clientThirdPartyReportingModule?.addons?.find(
      (addon) => addon.moduleAddon === DsModuleAddons["Customer Ratings"]
    )?.active;
  }
  isPayoutReconciliationActive() {
    return !!this.clientThirdPartyReportingModule?.addons?.find(
      (addon) => addon.moduleAddon === DsModuleAddons["Payout Reconciliation"]
    )?.active;
  }
  async fetchCanceledOrders(locations?) {
    const thirdPartyPerformanceSummaryReports = Object.assign(
      //NEW Instance so as not to modify utility doc
      await this.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.thirdPartyPerformanceSummaryReport
      )
    );
    const canceledTransactions = <ThirdPartyTransaction[]>_.flatten(
      thirdPartyPerformanceSummaryReports.map((report) => {
        return report.canceledOrdersLog?.length > 0
          ? report.canceledOrdersLog
          : [];
      })
    );
    canceledTransactions.forEach((canceledTransaction) => {
      canceledTransaction["thirdPartyName"] = this.getThirdPartyName(
        canceledTransaction.thirdParty
      );
    });
    if (locations?.length > 0) {
      return canceledTransactions.filter((transaction) => {
        return !!locations.find(
          (location) => transaction.location === location.locationId
        );
      });
    }
    return canceledTransactions;
  }
  async fetchRevenueRecoveredAdjustments(locations?) {
    try {
      const ppaTransactionsRefundAdjustments = (
        await this.fetchPriorPeriodAdjustments()
      )
        .filter((ppat) => ppat.isAdjustment)
        .map((ppa) => {
          ppa.transaction.saleAdjustment = ppa.transaction.sale
            ? ppa.transaction.sale
            : ppa.transaction.saleCorrection
            ? ppa.transaction.saleCorrection
            : 0;
          ppa["thirdPartyName"] = this.getThirdPartyName(
            ppa.transaction.thirdParty
          );
          return ppa.transaction;
        });

      const adjustmentTransactions = (
        await this.fetchReconciliationReportDrillDownFragments(
          ReconciliationDrillDownReportTypes.adjustmentLogs
        )
      )
        .filter((transaction) => {
          return (
            transaction.saleAdjustment >= 0 &&
            ((transaction.status === "Miscellaneous Payment" &&
              transaction.description === "Restaurant refunds") || //DD
              transaction.transactionType === "Account Adjustment" || //Gh
              transaction.transactionType === "ADJUSTMENT") //DD
          );
        })
        .sort((a, b) => {
          return moment(a.payoutDate, "M/DD/YYYY").isBefore(
            moment(b.payoutDate, "M/DD/YYYY")
          )
            ? -1
            : 1;
        });

      adjustmentTransactions.forEach((report) => {
        report["thirdPartyName"] = this.getThirdPartyName(report.thirdParty);
      });
      const reveueRecoverAdjustments = [
        ...ppaTransactionsRefundAdjustments,
        ...adjustmentTransactions,
      ];
      if (locations?.length > 0) {
        return reveueRecoverAdjustments.filter((adjustment) => {
          return !!locations.find(
            (location) => adjustment.location === location.locationId
          );
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
  async fetchPriorPeriodAdjustments(locations?) {
    if (
      !this.reconciliationDrillDownReportTypeMappings.priorPeriodAdjustments
        .loadingIndicator
    ) {
      const startDate = this.existingReport.startDate.toDate();
      const endDate = this.existingReport.endDate.toDate();
      let priorPeriodAdjustmentTransactions = FirestoreUtilities.mapToType(
        await firstValueFrom(
          this.afs
            .collection("priorPeriodAdjustmentTransactions", (ref) =>
              ref
                .where("uploadPeriodEnd", ">=", startDate)
                .where("uploadPeriodEnd", "<=", endDate)
                .where("client", "==", this.client.id)
                .orderBy("uploadPeriodEnd", "desc")
            )
            .snapshotChanges()
            .pipe()
        )
      );

      priorPeriodAdjustmentTransactions =
        priorPeriodAdjustmentTransactions.filter(
          (transaction) =>
            !!this.existingReport.locations.find(
              (location) => location === transaction.location
            )
        );
      priorPeriodAdjustmentTransactions.forEach((ppaTransaction) => {
        if (ppaTransaction.type === "3PD") {
          const dsp = this.thirdParties.find(
            (dsp) => dsp.id === ppaTransaction.account
          );
          ppaTransaction["accountName"] = dsp ? dsp.name : "";
        }
        ppaTransaction["changeTypeText"] = this.getChangeTypeText(
          ppaTransaction.changeType
        );
      });
      this.reconciliationDrillDownReportTypeMappings.priorPeriodAdjustments.loadingIndicator =
        true;
      this.reconciliationDrillDownReportTypeMappings.priorPeriodAdjustments.data =
        priorPeriodAdjustmentTransactions;
    }
    const ppaTrans =
      this.reconciliationDrillDownReportTypeMappings.priorPeriodAdjustments.data.filter(
        (ppaT) => {
          return ppaT.isAdjustment || ppaT.isErrorCharge;
        }
      );
    if (locations?.length > 0) {
      return ppaTrans.filter((ppaT) => {
        return !!locations.find(
          (location) => ppaT.location === location.locationId
        );
      });
    }
    return ppaTrans;
  }
  public getChangeTypeText(changeTypeNumber) {
    switch (changeTypeNumber) {
      case PriorPeriodAdjustmentTypes.noChange:
        return "No Change";
      case PriorPeriodAdjustmentTypes.newEntry:
        return "New Entry";
      case PriorPeriodAdjustmentTypes.changes:
        return "Multiple Changes";
      case PriorPeriodAdjustmentTypes.payoutDateOnly:
        return "Payout Date Only";
    }
  }

  public async compileAnalyticsBreakoutSummaries(locations?: Location[]) {
    if (!this.analyticsSummariesLoaded) {
      await this.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.varianceAnalysisReports
      );
      const thirdPartyPerformanceSummaryReports = Object.assign(
        //NEW Instance so as not to modify utility doc
        await this.fetchReconciliationReportDrillDownFragments(
          ReconciliationDrillDownReportTypes.thirdPartyPerformanceSummaryReport
        )
      );
      this.analyticsSummaries = this.reportData
        .map((locationData) => {
          const performanceReport = thirdPartyPerformanceSummaryReports.find(
            (report) =>
              report.location === locationData.locationId &&
              report.thirdParty === locationData.thirdParty
          );
          return this.runAnalyticsBreakoutCalcs(
            locationData,
            performanceReport
          );
        })
        .sort((a, b) => (a.netSales > b.netSales ? -1 : 1));
    }
    if (locations?.length > 0) {
      return this.analyticsSummaries.filter(
        (summary) =>
          !!locations.find(
            (location) => location.locationId === summary.location
          )
      );
    }
    return this.analyticsSummaries;
  }

  // public getReconciliationNotes() {
  //   this.afs
  //   .collection("thirdPartyReportNotes", (ref) =>
  //     ref
  //       .where("client", "==", this.client.id)
  //       .where("thirdPartyReport", "==", this.existingReport.id)
  //   )
  //   .snapshotChanges()
  //   .subscribe(async (queryResults$) => {
  //     const thirdPartyReportNotes = <ThirdPartyReportNote[]>(
  //       FirestoreUtilities.mapToType(queryResults$)
  //     );
  //     await this.setDataNames(thirdPartyReportNotes);
  //     this.thirdPartyReportNotes = thirdPartyReportNotes;
  //   });
  // }
  // private async setDataNames(thirdPartyReportNotes) {
  //   const creatorQuerys = thirdPartyReportNotes.map((thirdPartyReportNote) => {
  //     return this.afs
  //       .doc(`userViews/${thirdPartyReportNote.creator}`)
  //       .snapshotChanges()
  //       .pipe(first())
  //       .toPromise();
  //   });
  //   const creatorUserViews = <UserView[]>(
  //     FirestoreUtilities.mergeToType(await Promise.all(creatorQuerys))
  //   );
  //   thirdPartyReportNotes.forEach((thirdPartyReportNote) => {
  //     const creatorUserView = creatorUserViews.find(
  //       (userView) => userView.id === thirdPartyReportNote.creator
  //     );
  //     if (creatorUserView) {
  //       thirdPartyReportNote["creatorEmail"] = creatorUserView.email;
  //     }
  //   });
  //   thirdPartyReportNotes.forEach((thirdPartyReportNote) => {
  //     //@TODO wtf
  //     //@ts-ignore
  //     thirdPartyReportNote["thirdPartyNames"] =
  //       thirdPartyReportNote.thirdParties.reduce((txt, thirdPartyId) => {
  //         const thirdPartyName =
  //           this.getThirdPartyName(thirdPartyId);
  //         if (!txt) {
  //           return `${thirdPartyName}`;
  //         } else {
  //           return `${txt}, ${thirdPartyName}`;
  //         }
  //       }, ``);
  //   });
  // }

  public getCustomerRatingsSeriesData(
    locationIds,
    customerRatings: ThirdPartyRating[]
  ): { data; allDataByLocation; thirdPartyGroups } {
    const data = {};
    const locationSelectionRatings =
      locationIds?.length > 0
        ? customerRatings.filter((rating) => {
            return !!locationIds.find((l) => l === rating.location);
          })
        : customerRatings;
    const thirdPartyGroups = _.groupBy(locationSelectionRatings, "thirdParty");
    const allDataByLocation = _.groupBy(locationSelectionRatings, "location");
    Object.keys(allDataByLocation).forEach(
      (locationId) => (data[locationId] = {})
    );
    _.forEach(allDataByLocation, (value, locationId) => {
      const locationRatingsByThirdParty = _.groupBy(
        allDataByLocation[locationId],
        "thirdParty"
      );
      _.forEach(locationRatingsByThirdParty, (value, dspId) => {
        const locationThirdPartyRatings = locationRatingsByThirdParty[dspId];
        data[locationId][dspId] = +_.mean(
          locationThirdPartyRatings.map((rating) => rating.rating)
        ).toFixed(2);
      });
    });
    return { data, allDataByLocation, thirdPartyGroups };
  }
  getRatingsCount(locationId, thirdpartyId) {
    return this.reconciliationDrillDownReportTypeMappings.customerRatings.data.filter(
      (rating) =>
        rating.location === locationId && rating.thirdParty === thirdpartyId
    ).length;
  }
  private runAnalyticsBreakoutCalcs(
    reconciliation: ThirdPartyReconciliationLocationData,
    thirdPartyPerformanceReport: ThirdPartyPerformanceSummaryReport
  ) {
    const location = this.locations.find(
      (location) => location.locationId === reconciliation.locationId
    );
    const thirdParty = this.thirdParties.find(
      (dsp) => dsp.id === reconciliation.thirdParty
    );

    const analyticsSummary = new ThirdPartyAnalyticsSummary();
    analyticsSummary.location = reconciliation.locationId;
    analyticsSummary[
      "locationCityState"
    ] = `${location.addressCity}-${location.addressState}`;
    analyticsSummary.thirdParty = reconciliation.thirdParty;
    analyticsSummary["thirdPartyName"] = this.getThirdPartyName(
      reconciliation.thirdParty
    );
    // Sales
    if (
      thirdParty.id === ThirdPartyDeliveryIds["Uber Eats"] ||
      thirdParty.duplicateOf === ThirdPartyDeliveryIds["Uber Eats"]
    ) {
      const adjustedGrossSales =
        reconciliation.thirdPartyGrossSales +
        thirdPartyPerformanceReport.errorChargeAmount;
      analyticsSummary.dspGrossSales = adjustedGrossSales;
      analyticsSummary.errorCharges =
        thirdPartyPerformanceReport.errorChargeAmount * -1;
      analyticsSummary.adjustments = reconciliation.thirdPartySalesAdjustments;
    } else {
      analyticsSummary.dspGrossSales = reconciliation.thirdPartyGrossSales;
      analyticsSummary.errorCharges =
        thirdPartyPerformanceReport.errorChargeAmount * -1;
      analyticsSummary.adjustments =
        reconciliation.thirdPartySalesAdjustments -
        analyticsSummary.errorCharges;
    }
    analyticsSummary.netSales = reconciliation.thirdPartyNetSales;
    analyticsSummary.campaignPromoFees = reconciliation.campaignPromoFees * -1;
    analyticsSummary.taxableSales =
      reconciliation.thirdPartyNetSales - reconciliation.campaignPromoFees;

    analyticsSummary.canceledOrders =
      thirdPartyPerformanceReport.canceledOrderAmount;

    analyticsSummary.dspTax = +reconciliation.thirdPartyNetTax;
    analyticsSummary.dspEffectiveTaxRate =
      +reconciliation.thirdPartyAverageTaxRate.toFixed(4);
    analyticsSummary.taxRemittedToRestaurant =
      reconciliation.clientTaxResponsibility;

    analyticsSummary.mfTaxApplicable = reconciliation.mfTaxApplicable;
    analyticsSummary.partialMfTaxApplicable = reconciliation.isPartialMfTax;
    analyticsSummary.backupWithholdingTax = reconciliation.backupWithholdingTax;
    //Fees
    analyticsSummary.deliveryFees = reconciliation.deliveryFees;
    analyticsSummary.pickupFees = reconciliation.pickupFees;
    analyticsSummary.promoFees = reconciliation.promoFees;
    analyticsSummary.cateringFees = reconciliation.cateringFees;
    analyticsSummary.totalFees =
      analyticsSummary.deliveryFees +
      analyticsSummary.pickupFees +
      analyticsSummary.promoFees +
      analyticsSummary.cateringFees;
    analyticsSummary.miscFees = reconciliation.thirdPartyMisc;
    analyticsSummary.creditCardProcessingFees =
      reconciliation.thirdPartyOtherCharges;

    //@TODO Specific Rosati's Calcs (need to factor out)
    analyticsSummary["royaltyCalc"] =
      analyticsSummary.netSales + analyticsSummary.campaignPromoFees;

    return analyticsSummary;
  }
  isTransactionErrorCharge(
    transaction: ThirdPartyTransaction,
    thirdParty: ThirdParty
  ) {
    let isErrorChargeField = "";
    if (thirdParty.errorChargeCodes && thirdParty.errorChargeCodes.length > 0) {
      for (const errorChargeCode of thirdParty.errorChargeCodes) {
        if (transaction[errorChargeCode.field] === errorChargeCode.code) {
          isErrorChargeField = errorChargeCode.field;
          break;
        }
      }
      return isErrorChargeField;
    }
    return isErrorChargeField;
  }
  isTransactionAdjustment(
    transaction: ThirdPartyTransaction,
    thirdParty: ThirdParty
  ) {
    let isAdjustmentField = "";
    if (thirdParty.adjustmentCodes && thirdParty.adjustmentCodes.length > 0) {
      for (const errorChargeCode of thirdParty.adjustmentCodes) {
        if (transaction[errorChargeCode.field] === errorChargeCode.code) {
          isAdjustmentField = errorChargeCode.field;
          break;
        }
      }
      return isAdjustmentField;
    }
    return isAdjustmentField;
  }
}
