import {
    Directive, ElementRef, AfterViewChecked,
    Input, HostListener
} from '@angular/core';

@Directive({
    selector: '[appElementDynamicHeight]'
})
export class ElementDynamicHeightDirective implements AfterViewChecked {
    // class name to match height
    @Input()
    appElementDynamicHeight: string;
    @Input() minHeight: number;
    @Input() maxHeight: number;

    constructor(private el: ElementRef) {
    }

    ngAfterViewChecked() {
        this.matchHeight(this.el.nativeElement, this.appElementDynamicHeight);

    }

    @HostListener('window:resize')
    onResize() {
        // call our matchHeight function here
        this.matchHeight(this.el.nativeElement, this.appElementDynamicHeight);
    }

    matchHeight(parent: HTMLElement, className: string) {
        // match height logic here
        let matchPx = 0;
        if (this.minHeight && parent.offsetWidth < this.minHeight) {
            matchPx = this.minHeight;
        } else if (this.maxHeight && parent.offsetWidth > this.maxHeight) {
            matchPx = this.maxHeight;
        } else {
            matchPx = parent.offsetWidth;
        }
        parent.style.height = matchPx + 'px';
        // if (!parent) {
        //     return;
        // }
        //
        // // step 1: find all the child elements with the selected class name
        // const children = parent.getElementsByClassName(className);
        //
        // if (!children) {
        //     return;
        // }
        // // step 1b: reset all children height
        // Array.from(children).forEach((x: HTMLElement) => {
        //     x.style.height = 'initial';
        // });
        //
        // // step 2a: get all the child elements heights
        // const itemHeights = Array.from(children)
        //     .map(x => x.getBoundingClientRect().height);
        //
        // // step 2b: find out the tallest
        // const maxHeight = itemHeights.reduce((prev, curr) => {
        //     return curr > prev ? curr : prev;
        // }, 0);
        //
        // // step 3: update all the child elements to the tallest height
        // Array.from(children)
        //     .forEach((x: HTMLElement) => x.style.height = `${maxHeight}px`);
    }
}
