import { SelectionModel } from "@angular/cdk/collections";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  Input,
  ViewChild,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatOption } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ClientInvalidDataLog } from "@deliver-sense-librarian/data-schema";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { downloadDataAsFile } from "app/shared/ds-constant";
import { Papa } from "ngx-papaparse";

@Component({
  selector: "app-invalid-locations-table",
  templateUrl: "./invalid-locations-table.component.html",
  styleUrls: ["./invalid-locations-table.component.scss"],
})
export class InvalidLocationsTableComponent implements OnInit {
  @Input() data: {
    remove: boolean;
    value: string;
    transactions: number;
    knownInvalidLocation: boolean;
  }[];
  @Input() clientInvalidDataLogs: ClientInvalidDataLog[] = [];
  @Output() selectedLocationsChange = new EventEmitter();
  @ViewChild("locationAllSelected") private locationsAllSelected: MatOption;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    "select",
    "location",
    "transactions",
    "knownInvalidLocation",
  ];
  public locationSelection: SelectionModel<any[]>;

  constructor(
    private cdr: ChangeDetectorRef,
    private afs: AngularFirestore,
    private papa: Papa,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private loadingService: LoadingDialogService
  ) {}
  ngOnInit(): void {
    if (this.data) {
      this.setTableData();
      this.locationSelection.changed.subscribe((changeEvent) => {
        this.selectedLocationsChange.emit(this.locationSelection.selected);
      });
    }
  }

  setTableData() {
    this.locationSelection = new SelectionModel<any>(
      true,
      this.data.filter((error) => !!error.remove)
    );
    this.tableData = new MatTableDataSource(
      this.data.sort((a, b) => (!a.knownInvalidLocation ? -1 : 1))
    );
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.cdr.detectChanges();
  }

  isAllLocationsSelected() {
    const numSelected = this.locationSelection.selected.length;
    const numRows = this.tableData.data.length;
    return numSelected === numRows;
  }

  masterLocationsToggle() {
    this.isAllLocationsSelected()
      ? this.locationSelection.clear()
      : this.tableData.data.forEach((row) => {
          this.locationSelection.select(row);
        });
  }

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  public async export() {
    const data = Object.assign(
      this.data.map((data) => {
        return {
          Location: data.value,
          Transactions: data.transactions,
          "Known Invalid Location": !!data.knownInvalidLocation,
        };
      })
    );
    const results = this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const fileName = `Upload-invalid-locations`;
    downloadDataAsFile(results, fileName, "csv");
  }
}
