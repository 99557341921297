import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import {
  UserView,
  ClientRole,
  Client,
  OrganizationRole,
  UserRoles,
} from "@deliver-sense-librarian/data-schema";
import { Subject, from } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { takeUntil, combineAll, first } from "rxjs/operators";
import { AngularFirestore } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";
import { UiState } from "../../../../redux/custom-states/uiState/ui-state";
import { FirestoreUtilities } from "../../../../utilities/firestore-utilities";
import { ConfirmDialogComponent } from "app/dialogs/confirm-dialog/confirm-dialog.component";
import * as _ from "lodash";
import { tableExpandAnimation } from "../../../../shared/ds-constant";
import { UnsetSelectedClientAction } from "../../../../redux/custom-states/uiState/ui-state-actions/authentication-actions";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
@Component({
  selector: "app-user-clients",
  templateUrl: "./user-clients.component.html",
  styleUrls: ["./user-clients.component.scss"],
  animations: tableExpandAnimation,
})
export class UserClientsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<UserView>;
  public displayedColumns: string[] = ["name", "role", "remove"];
  expandedElement: any | null;
  destroy$ = new Subject();
  user: any;
  client: any;
  uiState: any;
  clients: any[];
  constructor(
    private store: Store<any>,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore
  ) {}

  ngOnInit() {
    this.store
      .select((store) => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((uiState$) => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$ as UiState;
          this.getUserClientsAndRole();
        }
      });
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  getUserClientsAndRole() {
    const clientsQuery = this.uiState.authorizedClients.map(
      (clientRole: ClientRole) => {
        return this.afs.doc(`clients/${clientRole.resource}`).snapshotChanges();
      }
    );
    from(clientsQuery)
      .pipe(combineAll(), takeUntil(this.destroy$))
      .subscribe((clients$) => {
        this.clients = <Client[]>(
          FirestoreUtilities.mergeToType(clients$).filter(
            (client) => !!client.active
          )
        );
        this.clients.forEach((client) => {
          const userClientRole = this.uiState.authorizedClients.find(
            (c) => c.resource === client.id
          );
          if (userClientRole) {
            client["userRole"] = userClientRole.role;
          }
        });
        this.tableData = new MatTableDataSource(this.clients);
        setTimeout(() => {
          this.tableData.paginator = this.paginator;
          this.tableData.sort = this.sort;
        });
      });
  }
  getUserClientRoleText(role: number) {
    switch (+role) {
      case UserRoles.admin:
        return "Admin";
      case UserRoles.contributor:
        return "Contributor";
      case UserRoles.viewer:
        return "Viewer";
    }
  }
  removeClientAccess(client: Client) {
    const confirmRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Remove Access",
        message: `Are you sure you want to remove your access to ${client.name}? You will lose all access to the client and all organization roles will be removed.`,
        action: "Yes, remove.",
        typeConfirm: client.name,
      },
    });
    confirmRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        this.afs
          .collection(
            `users/${this.user.id}/clientRoles/${client.id}/organizationRoles`
          )
          .snapshotChanges()
          .pipe(first())
          .subscribe(async (organizationRoles$) => {
            const organizationRoles =
              FirestoreUtilities.mapToType(organizationRoles$);
            const orgRoleChunks = _.chunk(organizationRoles, 475);
            try {
              await Promise.all(
                orgRoleChunks.map((chunk) => {
                  const batch = this.afs.firestore.batch();
                  chunk.forEach((orgRole: OrganizationRole) => {
                    batch.delete(
                      this.afs.doc(
                        `users/${this.user.id}/clientRoles/${client.id}/organizationRoles/${orgRole.id}`
                      ).ref
                    );
                  });
                  return batch.commit();
                })
              );
              await this.afs
                .doc(`users/${this.user.id}/clientRoles/${client.id}`)
                .delete();
              if (client.id === this.client.id) {
                this.store.dispatch(new UnsetSelectedClientAction());
                this.router.navigateByUrl("/client-selection");
              }
              this.snackBar.open(
                "Client access removed successfully!",
                "Dismiss",
                {
                  duration: 5000,
                }
              );
            } catch (e) {
              this.snackBar.open(
                "Oops.. something went wrong. Please refresh and try again.",
                "Dismiss",
                {
                  duration: 5000,
                }
              );
            }
          });
      }
    });
  }
}
