import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CustomerRatingsTableComponent } from "./customer-ratings-table.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "app/material.module";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { FileUploadModule } from "../file-upload/file-upload.module";
import { TypeSearchModule } from "../type-search/type-search.module";

@NgModule({
  declarations: [CustomerRatingsTableComponent],
  exports: [CustomerRatingsTableComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    FileUploadModule,
    TypeSearchModule,
    PerfectScrollbarModule,
  ],
})
export class CustomerRatingsTableModule {}
