import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import {
  ThirdParty,
  TransactionI,
  ClientThirdParty,
  ThirdPartyReportTransactionFlag,
  ThirdPartyReport,
} from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { TransactionHistoryDialogComponent } from "../transaction-history-dialog/transaction-history-dialog.component";
import { SanitizeHtmlPipe } from "../../shared/pipes/sanitize-html.pipe";
import { AngularFirestore } from "@angular/fire/firestore";
import { FirestoreUtilities } from "../../utilities/firestore-utilities";

@Component({
  selector: "app-transactions-dialog",
  templateUrl: "./transactions-dialog.component.html",
  styleUrls: ["./transactions-dialog.component.scss"],
})
export class TransactionsDialogComponent implements OnInit {
  public thirdParties: ThirdParty[];
  public destroy$ = new Subject();
  public tableData: MatTableDataSource<TransactionI>;
  public clientThirdParties: ClientThirdParty[];
  thirdPartyReportTransactionFlags: ThirdPartyReportTransactionFlag[] = [];
  loadingTransactionFlags = true;

  constructor(
    private dialog: MatDialog,
    private store: Store<any>,
    public dialogRef: MatDialogRef<TransactionsDialogComponent>,
    public afs: AngularFirestore,
    @Inject(MAT_DIALOG_DATA)
    public data: { transactions: any[]; thirdPartyReport?: ThirdPartyReport }
  ) {}

  ngOnInit() {
    this.store
      .select((store) => store.uiState.clientThirdParties)
      .pipe(takeUntil(this.destroy$))
      .subscribe((clientThirdParties$: ClientThirdParty[]) => {
        if (clientThirdParties$ && clientThirdParties$.length > 0) {
          this.thirdParties = <ThirdParty[]>(
            clientThirdParties$.map(
              (clientThirdParty) => clientThirdParty.thirdParty
            )
          );
        }
      });
    if (this.data.thirdPartyReport) {
      this.fetchTransactionFlags();
    } else this.loadingTransactionFlags = false;
  }

  private async fetchTransactionFlags() {
    this.loadingTransactionFlags = true;
    this.thirdPartyReportTransactionFlags = <ThirdPartyReportTransactionFlag[]>(
      FirestoreUtilities.mapToType(
        await this.afs
          .collection("thirdPartyReportTransactionFlags", (ref) =>
            ref.where("thirdPartyReport", "==", this.data.thirdPartyReport.id)
          )
          .snapshotChanges()
          .pipe(first())
          .toPromise()
      )
    );
    this.data.transactions.forEach((transaction) => {
      const matchingFlag = this.thirdPartyReportTransactionFlags.find(
        (flag) => flag.transaction.id === transaction.id
      );
      transaction.flag = matchingFlag ? matchingFlag : null;
    });
    this.loadingTransactionFlags = false;
  }
  openTransactionHistory(transaction) {
    this.dialog.open(TransactionHistoryDialogComponent, {
      panelClass: "invisible-panel-dialog",
      data: {
        transaction,
      },
    });
  }
}
