import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { Papa } from "ngx-papaparse";
import {
  ThirdPartyReportFragmentTransactionAnalysis,
  TransactionAITypes,
} from "@deliver-sense-librarian/data-schema";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "lodash";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import { ReconciliationReportDatUtility } from "../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../utilities/reconciliation-report-filtering.utility";
import { getTimeFromMinutesFromMidnight } from "app/shared/ds-constant";
import { TADrillDownSelection } from "../daily-drill-down/daily-drill-down.component";

@Component({
  selector: "app-transaction-analysis",
  templateUrl: "./transaction-analysis.component.html",
  styleUrls: ["./transaction-analysis.component.scss"],
})
export class TransactionAnalysisComponent implements OnInit {
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() exportUtility: ReconciliationReportExportUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @Input() preTASelection: TADrillDownSelection;
  @Output() clearResults = new EventEmitter();
  @Output() jumpToDailyDrillDown = new EventEmitter();

  transactionAnalysisReports: ThirdPartyReportFragmentTransactionAnalysis[] =
    [];

  loadingTransactionAnalysis = false;
  constructor() {}

  ngOnInit(): void {
    this.initializeDataAndTable();
  }
  private async initializeDataAndTable() {
    this.loadingTransactionAnalysis = true;
    this.transactionAnalysisReports =
      await this.dataUtility.compileDailyDrillDownBreakoutReports(
        "transactionAnalysis"
      );
    this.loadingTransactionAnalysis = false;
  }
  emitDdShortCut(row: ThirdPartyReportFragmentTransactionAnalysis) {
    this.jumpToDailyDrillDown.emit({
      locationId: row.location,
      thirdPartyId: row.thirdParty,
    });
  }
}
