import { TrendAnalysisTypes } from "@deliver-sense-librarian/data-schema";
export class TrendAnalysisUtilities {
public static getTrendTypeName(trendType: TrendAnalysisTypes) {
    switch (trendType) {
      case TrendAnalysisTypes.sales:
        return 'Sales';
      case TrendAnalysisTypes.salesVariance:
        return 'Sales Variance';
      case TrendAnalysisTypes.taxVariance:
        return 'Tax Variance';
      case TrendAnalysisTypes.errorChargesCount:
        return 'Error Charge Count';
      case TrendAnalysisTypes.errorChargesTotal:
        return 'Error Charge Totals';
      default:
        return '';
    }
  }
}