import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { Papa } from "ngx-papaparse";
import {
  ThirdPartyReportFragmentVarianceAnalysisReport,
  TransactionAITypes,
  ThirdPartyReportAnomalyValidation,
} from "@deliver-sense-librarian/data-schema";
import { AngularFirestore } from "@angular/fire/firestore";
import * as _ from "lodash";
import { MatSnackBar } from "@angular/material/snack-bar";
import { first } from "rxjs/operators";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import {
  ReconciliationDrillDownReportTypes,
  ReconciliationReportDatUtility,
} from "../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../utilities/reconciliation-report-filtering.utility";

@Component({
  selector: "app-variance-analysis",
  templateUrl: "./variance-analysis.component.html",
  styleUrls: ["./variance-analysis.component.scss"],
})
export class VarianceAnalysisComponent implements OnInit {
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() exportUtility: ReconciliationReportExportUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @Output() jumpToDailyDrillDown = new EventEmitter();
  @Output() jumpToTransactionAnalysis = new EventEmitter();
  @Output() jumpToErrorCharges = new EventEmitter();
  public varianceAnalysisFragments: ThirdPartyReportFragmentVarianceAnalysisReport[];
  public displayedColumns: string[] = [];
  anomalyValidations: ThirdPartyReportAnomalyValidation[] = [];
  loadingVarianceAnalysis = false;
  constructor(
    private cdr: ChangeDetectorRef,
    private afs: AngularFirestore,
    private papa: Papa,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.initializeReport();
  }
  private async initializeReport() {
    this.loadingVarianceAnalysis = true;
    try {
      await Promise.all([
        this.fetchVarianceAnalysisFragments(),
        await this.fetchAnomalyValidations(),
      ]);
      this.varianceAnalysisFragments.forEach(
        (row: ThirdPartyReportFragmentVarianceAnalysisReport) => {
          if (row.salesVarianceReasons) {
            let reasonSum = 0;
            row.salesVarianceReasons.forEach((reason) => {
              if (reason.type !== TransactionAITypes.potentialFraud) {
                row[reason.type] = reason.sale;
                reasonSum += +reason.sale ? +reason.sale : 0;
              }
            });
            row["reasonSum"] = +reasonSum.toFixed(2);
          }
          const existingValidation = this.anomalyValidations.find(
            (validation) => {
              return (
                validation.location === row.location &&
                validation.thirdParty === row.thirdParty
              );
            }
          );
          if (existingValidation) {
            row["validation"] = existingValidation;
          }
        }
      );
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        "Error loading variance analysis. Please refresh and try again",
        "Dismiss"
      );
    } finally {
      this.loadingVarianceAnalysis = false;
    }
  }
  private async fetchVarianceAnalysisFragments() {
    this.varianceAnalysisFragments =
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.varianceAnalysisReports
      );
  }
  private async fetchAnomalyValidations() {
    this.anomalyValidations = FirestoreUtilities.mapToType(
      await this.afs
        .collection("thirdPartyReportAnomalyValidations", (ref) =>
          ref
            .where("thirdPartyReport", "==", this.dataUtility.existingReport.id)
            .where("client", "==", this.dataUtility.client.id)
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise()
    );
  }
  emitDdShortCut(row: ThirdPartyReportFragmentVarianceAnalysisReport) {
    this.jumpToDailyDrillDown.emit({
      locationId: row.location,
      thirdPartyId: row.thirdParty,
    });
  }
  emitECShortCut(row: ThirdPartyReportFragmentVarianceAnalysisReport) {
    this.jumpToErrorCharges.emit({
      locationId: row.location,
      thirdPartyId: row.thirdParty,
    });
  }
  emitTAShortCut(event) {
    this.jumpToTransactionAnalysis.emit({
      locationId: event.row.location,
      thirdPartyId: event.row.thirdParty,
      type: event.saleVarianceType,
    });
  }
}
