import { Component, OnInit, OnDestroy } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { UiState } from "../../../../redux/custom-states/uiState/ui-state";
import { Subject, combineLatest, lastValueFrom } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { TpdReportFieldSelectionDialogComponent } from "../../../../dialogs/tpd-report-field-selection-dialog/tpd-report-field-selection-dialog.component";
import { FirestoreUtilities } from "../../../../utilities/firestore-utilities";
import {
  FormBuilder,
  FormControl,
  Validators,
  FormGroup,
  FormArray,
} from "@angular/forms";
import {
  Client3pdConfiguration,
  Client3pdTransactionStatusConfiguration,
  ClientCustomReport,
  Location,
  PosSystem,
  reconciliationTableFields,
  StandardJournalEntry,
  ThirdParty,
  ThirdPartyReportTypes,
  ThirdPartyTransactionStatus,
} from "@deliver-sense-librarian/data-schema";
import * as moment from "moment";
import * as _ from "lodash";
@Component({
  selector: "app-third-party-report-settings",
  templateUrl: "./third-party-report-settings.component.html",
  styleUrls: ["./third-party-report-settings.component.scss"],
})
export class ThirdPartyReportSettingsComponent implements OnInit, OnDestroy {
  public uiState: UiState;
  private destroy$ = new Subject();
  private client3pdConfiguration = new Client3pdConfiguration();
  public configForm: FormGroup;
  public thirdPartyStatusConfigsForm: FormGroup;
  public fields = reconciliationTableFields;
  public thirdPartyTransactionStatuses: ThirdPartyTransactionStatus[] = [];
  public client3pdTransactionStatusConfigurations: Client3pdTransactionStatusConfiguration[] =
    [];
  public clientThirdParties: ThirdParty[] = [];
  public clientPosSystems: PosSystem[] = [];
  public possibleFields = reconciliationTableFields;
  public fixedFields = ["Location Id", "3PD"];
  public defaultExportReports = [];
  clientCustomReports: ClientCustomReport[];
  standardJournalEntries: StandardJournalEntry[];
  clientLocations: Location[];
  formatLabel(value: number) {
    if (value >= 0) {
      return `$${Math.round(value / 1000)}`;
    }
    return value;
  }
  formatLabelPercent(value: number) {
    if (value >= 0) {
      return `${Math.round(value / 1000)}%`;
    }
    return value;
  }

  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.store
      .select((store) => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((uiState$: UiState) => {
        if (
          uiState$.authUser &&
          uiState$.client &&
          uiState$.clientThirdParties
        ) {
          this.uiState = uiState$;
          this.clientThirdParties = uiState$.clientThirdParties
            .map((clientTpd) => clientTpd.thirdParty)
            .filter((tpd) => !!tpd) as ThirdParty[];
          this.getClient3pdConfiguration();
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  private async getClient3pdConfiguration() {
    this.clientLocations = FirestoreUtilities.mapToType(
      await lastValueFrom(
        this.afs
          .collection("locations", (ref) =>
            ref
              .where("client", "==", this.uiState.client.id)
              .where("active", "==", true)
          )
          .snapshotChanges()
          .pipe(first())
      )
    );
    combineLatest([
      this.afs
        .collection("client3pdConfigurations", (ref) =>
          ref.where("client", "==", this.uiState.client.id)
        )
        .snapshotChanges(),
      this.afs
        .collection("client3pdTransactionStatusConfigurations", (ref) =>
          ref.where("client", "==", this.uiState.client.id)
        )
        .snapshotChanges(),
      this.afs.collection("thirdPartyTransactionStatuses").snapshotChanges(),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ([
          client3pdConfiguration$,
          client3pdTransactionStatusConfigurations$,
          thirdPartyTransactionStatuses$,
        ]) => {
          this.thirdPartyTransactionStatuses = <ThirdPartyTransactionStatus[]>(
            FirestoreUtilities.mapToType(thirdPartyTransactionStatuses$)
          );
          if (client3pdConfiguration$.length > 0) {
            this.client3pdConfiguration = FirestoreUtilities.mapToType(
              client3pdConfiguration$
            )[0] as Client3pdConfiguration;
          }
          if (client3pdTransactionStatusConfigurations$.length > 0) {
            this.client3pdTransactionStatusConfigurations =
              FirestoreUtilities.mapToType(
                client3pdTransactionStatusConfigurations$
              ) as Client3pdTransactionStatusConfiguration[];
          }
          this.setupConfigurationForm();
          this.setup3pdStatusConfigsForm();
        }
      );
  }
  async setupConfigurationForm() {
    await this.fetchReportTypes();
    this.configForm = this.fb.group({
      taxVarianceThreshold: new FormControl(
        this.client3pdConfiguration.taxVarianceThreshold
          ? this.client3pdConfiguration.taxVarianceThreshold
          : 500,
        Validators.required
      ),
      salesVarianceThreshold: new FormControl(
        this.client3pdConfiguration.salesVarianceThreshold
          ? this.client3pdConfiguration.salesVarianceThreshold
          : 500,
        Validators.required
      ),
      remittanceVarianceThreshold: new FormControl(
        this.client3pdConfiguration.remittanceVarianceThreshold
          ? this.client3pdConfiguration.remittanceVarianceThreshold
          : 500,
        Validators.required
      ),
      taxRateVarianceThreshold: new FormControl(
        this.client3pdConfiguration.taxRateVarianceThreshold
          ? this.client3pdConfiguration.taxRateVarianceThreshold
          : 2,
        Validators.required
      ),
      defaultExportReports: new FormControl(
        this.client3pdConfiguration.defaultExportReports
          ? this.client3pdConfiguration.defaultExportReports
          : this.defaultExportReports
      ),
      defaultFields: new FormControl(
        this.client3pdConfiguration.defaultFields
          ? this.client3pdConfiguration.defaultFields
          : ["Location Id", "3PD"],
        Validators.required
      ),
      posRecordsMfTax: new FormControl(
        this.client3pdConfiguration.posRecordsMfTax
          ? this.client3pdConfiguration.posRecordsMfTax
          : false,
        Validators.required
      ),
      clientThirdPartyTransactionStatusConfigurations: new FormArray([]),
    });
  }
  async saveDefaultExports() {
    const selectedDefaultExports = this.configForm.get(
      "defaultExportReports"
    ).value;
    if (selectedDefaultExports.length > 0) {
      await this.afs
        .doc(`client3pdConfigurations/${this.client3pdConfiguration.id}`)
        .update({
          defaultExportReports: selectedDefaultExports,
        });
      this.snackBar.open(
        "Default export selection updated successfully",
        "Dismiss",
        { duration: 5000 }
      );
    }
  }
  private setup3pdStatusConfigsForm() {
    this.thirdPartyStatusConfigsForm = this.fb.group({
      clientThirdParties: new FormArray([]),
    });
    this.populateStatusConfigurationArrays();
  }

  private async populateStatusConfigurationArrays() {
    const clientThirdPartiesArray = this.thirdPartyStatusConfigsForm.get(
      "clientThirdParties"
    ) as FormArray;
    this.clientThirdParties
      .sort((a, b) => {
        return a.name.charAt(0) > b.name.charAt(0) ? 1 : -1;
      })
      .forEach(async (clientThirdParty) => {
        const thirdPartyTransactionStatuses = FirestoreUtilities.mapToType(
          await this.afs
            .collection("thirdPartyTransactionStatuses", (ref) =>
              ref.where("thirdParty", "==", clientThirdParty.id)
            )
            .snapshotChanges()
            .pipe(first())
            .toPromise()
        );
        // clientThirdParty['thirdPartyTransactionStatuses'] = thirdPartyTransactionStatuses;
        const clientStatusConfigs =
          this.client3pdTransactionStatusConfigurations.filter(
            (config) => config.thirdParty === clientThirdParty.id
          );
        // create a new form group specific to the status configurations for the current 3pd
        const thirdPartyStatusConfigGroup = new FormGroup({
          thirdPartyName: new FormControl(clientThirdParty.name),
          thirdPartyId: new FormControl(clientThirdParty.id),
          // set all the available third party transaction statuses from the system to the third party object to populate dropdown options for status configs
          thirdPartyTransactionStatuses: new FormControl(
            thirdPartyTransactionStatuses
          ),
          statusConfigs: new FormArray([]),
        });
        // add the third party specific client status configuration group to the top level all client third party status configs array.
        clientThirdPartiesArray.push(thirdPartyStatusConfigGroup);
        // for all the existing status configs add new elements to the current third party
        const relativeStatusConfigs = thirdPartyStatusConfigGroup.get(
          "statusConfigs"
        ) as FormArray;
        clientStatusConfigs.forEach((statusConfig) => {
          relativeStatusConfigs.push(
            new FormGroup({
              status: new FormControl(statusConfig.status, Validators.required),
              inPos: new FormControl(statusConfig.inPos, Validators.required),
              client: new FormControl(statusConfig.client, Validators.required),
              id: new FormControl(statusConfig.id, Validators.required),
              thirdParty: new FormControl(
                statusConfig.thirdParty,
                Validators.required
              ),
              existing: new FormControl(true),
            })
          );
        });
      });
    // const statusConfigurationArray = this.configForm.get('clientThirdPartyTransactionStatusConfigurations') as FormArray;
  }
  public addFormArrayGroup(formGroup) {
    const statusFormArray = formGroup.get("statusConfigs") as FormArray;
    statusFormArray.push(
      new FormGroup({
        status: new FormControl("", Validators.required),
        inPos: new FormControl(true),
        client: new FormControl(this.uiState.client.id, Validators.required),
        id: new FormControl(this.afs.createId(), Validators.required),
        thirdParty: new FormControl(
          formGroup.get("thirdPartyId").value,
          Validators.required
        ),
      })
    );
  }

  public async removeFormArrayGroup(index, statusConfigsFormGroup) {
    const statusConfigs = statusConfigsFormGroup.get(
      "statusConfigs"
    ) as FormArray;
    const statusConfig = statusConfigs.value[index];
    if (statusConfig.existing) {
      await this.afs
        .doc(`client3pdTransactionStatusConfigurations/${statusConfig.id}`)
        .delete();
    }
    statusConfigs.removeAt(index);
    this.snackBar.open("Status config removed successfully.", "Dismiss", {
      duration: 5000,
    });
  }
  public async openFieldsSelector() {
    const defaultFieldsControl = this.configForm.get("defaultFields");
    const fieldSelectDialogRef = this.dialog.open(
      TpdReportFieldSelectionDialogComponent,
      {
        panelClass: "invisible-panel-dialog",
        data: {
          selectedFields: defaultFieldsControl.value,
        },
      }
    );
    fieldSelectDialogRef.afterClosed().subscribe((updatedFieldSelection$) => {
      if (updatedFieldSelection$) {
        const updateFieldSelection = [
          ...this.fixedFields,
          ...updatedFieldSelection$
            .map((field) => field.item)
            .filter((fieldName) => this.possibleFields.indexOf(fieldName) > -1),
        ];
        defaultFieldsControl.patchValue(
          _.sortBy(updateFieldSelection, (item) => {
            return this.possibleFields.indexOf(item);
          })
        );
        defaultFieldsControl.updateValueAndValidity();
      }
    });
  }
  public async save() {
    // const client3pdStatusConfigForms = this.clientThirdParties.map(clientThirdParty => clientThirdParty['clientStatusConfigForm'])
    if (this.configForm.valid && this.thirdPartyStatusConfigsForm.valid) {
      const client3pdConfigFormValues = this.configForm.value;
      const client3pdStatusConfigFormValues =
        this.thirdPartyStatusConfigsForm.value;
      try {
        this.loadingService.isLoading(true, "Saving 3PD settings", {
          duration: 5000,
        });
        await this.updateClient3pdConfiguration(client3pdConfigFormValues);
        await this.saveClientThirdPartyTransactionStatuses(
          client3pdStatusConfigFormValues
        );
        this.snackBar.open(
          "Successfully updated your 3PD Reconciliation Configurations.",
          "Dismiss",
          {
            duration: 5000,
          }
        );
        this.loadingService.isLoading(false);
      } catch (e) {
        const errSnackbar = this.snackBar.open(
          "Oops... something went wrong. Please refresh and try again",
          "Refresh Now",
          {
            duration: 5000,
          }
        );
        errSnackbar.onAction().subscribe(() => {
          window.location.reload();
        });
        this.loadingService.isLoading(false);
      }
    } else {
      this.snackBar.open("Please fill out all required fields", "Dismiss", {
        duration: 5000,
      });
    }
  }
  private async updateClient3pdConfiguration(form) {
    await this.afs
      .doc(`client3pdConfigurations/${this.client3pdConfiguration.id}`)
      .update({
        taxVarianceThreshold: form.taxVarianceThreshold,
        salesVarianceThreshold: form.salesVarianceThreshold,
        taxRateVarianceThreshold: form.taxRateVarianceThreshold,
        remittanceVarianceThreshold: form.remittanceVarianceThreshold,
        defaultFields: form.defaultFields,
        dateUpdated: moment().toDate(),
      });
  }

  public async saveClientThirdPartyTransactionStatuses(
    client3pdStatusConfigFormValues
  ) {
    const statusConfigSaveRequests =
      client3pdStatusConfigFormValues.clientThirdParties
        .map((clientThirdParty) => {
          if (clientThirdParty.statusConfigs.length > 0) {
            return clientThirdParty.statusConfigs.map(
              (arrayGroup: {
                status;
                id;
                thirdParty;
                posSystem;
                inPos;
                client;
              }) => {
                const statusConfig =
                  new Client3pdTransactionStatusConfiguration();
                statusConfig.thirdParty = arrayGroup.thirdParty;
                statusConfig.status = arrayGroup.status;
                statusConfig.client = arrayGroup.client;
                statusConfig.inPos = arrayGroup.inPos;
                return this.afs
                  .doc(
                    `client3pdTransactionStatusConfigurations/${arrayGroup.id}`
                  )
                  .set(statusConfig.toJSONObject());
              }
            );
          }
        })
        .filter((request) => !!request);
    await Promise.all(_.flatten(statusConfigSaveRequests));
  }
  private async fetchReportTypes() {
    const [clientCustomReportsQuery$, standardJournalEntriesQuery$] =
      await Promise.all([
        this.afs
          .collection("clientCustomReports", (ref) =>
            ref.where("client", "==", this.uiState.client.id)
          )
          .snapshotChanges()
          .pipe(first())
          .toPromise(),
        this.afs
          .collection("standardJournalEntries")
          .snapshotChanges()
          .pipe(first())
          .toPromise(),
      ]);

    const standardJournalEntries = <StandardJournalEntry[]>(
      FirestoreUtilities.mapToType(standardJournalEntriesQuery$).map(
        (jeReport) => {
          return {
            name: jeReport.name,
            value: jeReport.name,
          };
        }
      )
    );
    this.availableExportReports.push({
      groupName: "Standard Journal Entries",
      reports: standardJournalEntries,
    });
    const clientCustomReports = FirestoreUtilities.mapToType(
      clientCustomReportsQuery$
    ).map((clientCustomReport: ClientCustomReport) => {
      return {
        name: clientCustomReport.name,
        value: clientCustomReport.name,
      };
    });
    this.availableExportReports.push({
      groupName: "Custom Reports",
      reports: clientCustomReports,
    });
  }

  private availableExportReports: { groupName: string; reports: any }[] = [
    {
      groupName: "Reconciliation Report",
      reports: [
        {
          name: "Reconciliation Summary",
          value: ThirdPartyReportTypes.reconciliationSummary,
        },
        {
          name: "Reconciliation Summary Breakout",
          value: ThirdPartyReportTypes.reconciliationSummaryBreakout,
        },
        {
          name: "Reconciliation Data Report",
          value: ThirdPartyReportTypes.dsReport,
        },
        {
          name: "Variance Analysis",
          value: ThirdPartyReportTypes.varianceAnalysis,
        },
        {
          name: "Transaction Analysis",
          value: ThirdPartyReportTypes.transactionAnalysis,
        },
        { name: "Tax Summary", value: ThirdPartyReportTypes.taxSummary },
        {
          name: "Net Cash",
          value: ThirdPartyReportTypes.netCash,
        },
        {
          name: "Potential Fraud Analysis",
          value: ThirdPartyReportTypes.potentialFraudAnalysis,
        },
        {
          name: "Reconciliation Notes",
          value: ThirdPartyReportTypes.reconciliationNotes,
        },
        { name: "Tip Variance", value: ThirdPartyReportTypes.tipVariance },
      ],
    },
    {
      groupName: "3PD Analytics",
      reports: [
        {
          name: "Third Party Analytics Breakout",
          value: ThirdPartyReportTypes.thirdPartyAnalyticsBreakoutSummary,
        },
        {
          name: "Third Party Analytics Breakout Aggregated",
          value:
            ThirdPartyReportTypes.thirdPartyAnalyticsBreakoutSummaryAggregated,
        },
        {
          name: "Third Party Performance Summary",
          value: ThirdPartyReportTypes.thirdPartyPerformance,
        },
        {
          name: "Fees Summary",
          value: ThirdPartyReportTypes.feesSummary,
        },
        {
          name: "Error Charge Summary",
          value: ThirdPartyReportTypes.errorChargeSummary,
        },
        {
          name: "Adjustments Analysis",
          value: ThirdPartyReportTypes.adjustmentsAnalysis,
        },
        { name: "Fee Analysis", value: ThirdPartyReportTypes.feeAnalysis },
        {
          name: "Canceled Orders",
          value: ThirdPartyReportTypes.canceledOrders,
        },
        {
          name: "Revenue Recovered",
          value: ThirdPartyReportTypes.revenueRecovered,
        },
        {
          name: "Tax Rate Analysis",
          value: ThirdPartyReportTypes.taxRateAnalysis,
        },
        {
          name: "Status Drill Down",
          value: ThirdPartyReportTypes.statusDrillDown,
        },
        {
          name: "Payout Analysis",
          value: ThirdPartyReportTypes.payoutAnalysis,
        },
      ],
    },
    {
      groupName: "Deposits",
      reports: [
        {
          name: "Deposits Report",
          value: ThirdPartyReportTypes.depositReport,
        },
      ],
    },
    {
      groupName: "Customer Ratings",
      reports: [
        {
          name: "Customer Ratings",
          value: ThirdPartyReportTypes.customerRatings,
        },
      ],
    },
  ];
}
