import { Component, OnInit } from "@angular/core";
import { FirebaseAuthService } from "../../auth/services/firebase-auth.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AngularFirestore } from "@angular/fire/firestore";
import { Client, User } from "@deliver-sense-librarian/data-schema";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { LoadingDialogService } from "../../services/loading-dialog.service";
import { Store } from "@ngrx/store";
import { first, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AngularFireAuth } from "@angular/fire/auth";

@Component({
  selector: "app-support-dialog",
  templateUrl: "./support-dialog.component.html",
  styleUrls: ["./support-dialog.component.scss"],
})
export class SupportDialogComponent implements OnInit {
  supportForm: FormGroup;
  user: User;
  types = [
    "Feature Request",
    "General Questions",
    "Technical Issues and Bugs",
    "Billing",
  ];
  client: Client;
  private destroy$ = new Subject();

  constructor(
    private http: HttpClient,
    private afAuth: AngularFireAuth,
    public dialogRef: MatDialogRef<SupportDialogComponent>,
    private store: Store<any>,
    private fb: FormBuilder,
    private loadingService: LoadingDialogService,
    private afs: AngularFirestore,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.store
      .select((store) => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((uiState$) => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
        }
        this.setupContactForm();
      });
  }

  setupContactForm() {
    this.supportForm = this.fb.group({
      userId: new FormControl(
        this.user && this.user.id ? this.user.id : null,
        Validators.required
      ),
      userName: new FormControl(
        this.user ? `${this.user.firstName} ${this.user.lastName}` : null,
        Validators.required
      ),
      email: new FormControl(
        this.user && this.user.email ? this.user.email : "",
        Validators.required
      ),
      type: new FormControl(null, Validators.required),
      message: new FormControl(null, Validators.required),
      clientName: new FormControl(this.client.name, Validators.required),
      clientId: new FormControl(this.client.id, Validators.required),
    });
  }

  async sendMessage() {
    if (this.supportForm.valid) {
      let userName = "Unauthenticated User";
      if (this.user) {
        userName = this.user.firstName + " " + this.user.lastName;
      }
      const formValues = this.supportForm.value;
      const body = {
        userId: formValues.userId,
        name: formValues.userName,
        email: formValues.email,
        client: formValues.clientName,
        clientId: formValues.clientId,
        type: formValues.type,
        message: formValues.message,
      };
      this.loadingService.isLoading(true, "Sending your message to support...");
      const token = await this.afAuth.idToken.pipe(first()).toPromise();
      const headerObj = {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      };
      this.http
        .post(`${environment.apiUrl}supportTickets`, body, headerObj)
        .subscribe(
          () => {
            this.snackBar.open(
              "Thank you for your message! A customer support representative will reach out within 1 business day.",
              "Dismiss",
              {
                duration: 5000,
              }
            );
            this.loadingService.isLoading(false);
            this.dialogRef.close();
          },
          (err) => {
            this.loadingService.isLoading(false);
            this.snackBar.open(
              "Error sending your message. Please refresh the page and try again.",
              "Dismiss",
              {
                duration: 5000,
              }
            );
          }
        );
    } else {
      this.snackBar.open(
        "Please complete the form before sending your inquiry.",
        "Dismiss",
        {
          duration: 5000,
        }
      );
    }
  }
}
