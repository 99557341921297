import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FirebaseAuthService } from "../../auth/services/firebase-auth.service";

@Component({
  selector: "app-new-version-dialog",
  templateUrl: "./new-version-dialog.component.html",
  styleUrls: ["./new-version-dialog.component.scss"],
})
export class NewVersionDialogComponent implements OnInit {
  constructor(
    private authService: FirebaseAuthService,
    public dialogRef: MatDialogRef<NewVersionDialogComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { version: string; reauth: boolean }
  ) {}

  ngOnInit() {}
  async refresh() {
    if (this.data.reauth) {
      await this.authService.signOut();
    }
    window.location.reload();
  }
}
