import { HttpClient } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FirebaseAuthService } from "../../../auth/services/firebase-auth.service";
import { Store } from "@ngrx/store";
import { Client, User } from "@deliver-sense-librarian/data-schema";
import { FirestoreUtilities } from "../../../utilities/firestore-utilities";
import { FormControl } from "@angular/forms";
import { AngularFirestore } from "@angular/fire/firestore";
import { from, Subject, of } from "rxjs";
import { combineAll, takeUntil, map } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "../../../dialogs/confirm-dialog/confirm-dialog.component";
import {
  SetAccountClientAction,
  UnsetSelectedClientAction,
  ToggleShowDataUploadAction,
} from "../../../redux/custom-states/uiState/ui-state-actions";
import { LoadingDialogService } from "../../../services/loading-dialog.service";
import { Router } from "@angular/router";
import { AngularFireStorage } from "@angular/fire/storage";
import { ClearSfaAction } from "../../../redux/custom-states/uiState/ui-state-actions/authentication-actions";
import { MatSidenav } from "@angular/material/sidenav";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() toggleSidenav = new EventEmitter<void>();
  @Input() sidebar: MatSidenav;
  public user: User;
  public client: Client;
  public selectedClient = new FormControl();
  public availableClients: Client[] = [];
  private destroy$ = new Subject();

  constructor(
    private auth: FirebaseAuthService,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private dialog: MatDialog,
    private http: HttpClient,
    private router: Router,
    private loadingService: LoadingDialogService,
    private store: Store<any>
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
