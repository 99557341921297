import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  User,
  Client,
  UserView,
  Entity,
  Department,
  Project,
  TeamMemberInvitation,
  ClientRole,
  UserRoles,
} from "@deliver-sense-librarian/data-schema";
import { takeUntil, first, map } from "rxjs/operators";
import { Subject } from "rxjs";
import { FirestoreUtilities } from "../../../../utilities/firestore-utilities";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Store } from "@ngrx/store";
import * as moment from "moment";
import { UiState } from "../../../../redux/custom-states/uiState/ui-state";
import { ConfirmDialogComponent } from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
import { AngularFireAuth } from "@angular/fire/auth";
import { LoadingDialogService } from "../../../../services/loading-dialog.service";

@Component({
  selector: "app-team-members",
  templateUrl: "./team-members.component.html",
  styleUrls: ["./team-members.component.scss"],
})
export class TeamMembersComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<UserView>;
  public displayedColumns: string[] = [
    "firstName",
    "lastName",
    "role",
    "email",
  ];
  public user: User;
  public pendingInvites: TeamMemberInvitation[] = [];
  public teamMemberInEdit: UserView;
  public client: Client;
  public invitingNewTeamMember = false;
  public clientRole: ClientRole;
  public uiState: UiState;
  private destroy$ = new Subject();
  private teamMembers: any;

  constructor(
    private store: Store<any>,
    private http: HttpClient,
    private afAuth: AngularFireAuth,
    private loadingService: LoadingDialogService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore
  ) {}

  ngOnInit() {
    this.store
      .select((store) => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((uiState$) => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$;
          this.getTeamMembers();
          this.getPendingInvites();
          // this.getOrganizationAvailableResources();
          if (this.uiState.clientRole === 3) {
            this.displayedColumns[4] = "edit";
            this.displayedColumns[5] = "remove";
          }
        }
      });
  }
  ngAfterViewInit() {}

  /**
   * Get team members
   * Get team member current client roles
   * Get team member entites and roles
   * Get team member departments and roles
   * @private
   */
  private getTeamMembers() {
    this.afs
      .collection("userViews", (ref) =>
        ref.where("clients", "array-contains", this.client.id)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userViews$) => {
        this.teamMembers = FirestoreUtilities.mapToType(userViews$);
        this.tableData = new MatTableDataSource(this.teamMembers);
        this.tableData.paginator = this.paginator;
        this.tableData.sort = this.sort;
      });
  }

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  private getPendingInvites() {
    this.afs
      .collection("teamMemberInvitations", (ref) =>
        ref
          .where("client", "==", this.client.id)
          .where("status", "==", "pending")
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((pendingInvites$) => {
        this.pendingInvites = <TeamMemberInvitation[]>(
          FirestoreUtilities.mapToType(pendingInvites$).filter(
            (invite) => invite.email !== this.user.email
          )
        );
      });
  }

  isInviteExpired(invite: TeamMemberInvitation) {
    return moment(invite.expiration.toDate()).isSameOrBefore(moment());
  }

  async resendInvite(invite: TeamMemberInvitation) {
    await this.afs.doc(`teamMemberInvitations/${invite.id}`).update({
      expiration: moment().add(5, "days").toDate(),
      dateUpdated: moment().toDate(),
      resent: true,
    });
    this.snackBar.open("Invitation resent successfully", "Dismiss", {
      duration: 5000,
    });
  }
  async deleteInvite(invite: TeamMemberInvitation) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Delete",
        message: "Are you sure you want to delete this invitation?",
        action: "Yes, Delete",
      },
    });
    confirmDialog.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`teamMemberInvitations/${invite.id}`).delete();
        this.snackBar.open("Invitation deleted successfully!", "Dismiss", {
          duration: 5000,
        });
      }
    });
  }

  getClientRole(userView: UserView) {
    const role = userView.clientRoles[this.client.id]
      ? userView.clientRoles[this.client.id]
      : 0;
    switch (role) {
      case UserRoles.admin:
        return "admin";
      case UserRoles.contributor:
        return "contributor";
      case UserRoles.viewer:
        return "viewer";
    }
  }

  isUserClientAdmin() {
    return this.uiState.clientRole > 2;
  }
  private async getToken(): Promise<HttpHeaders> {
    const token = await this.afAuth.idToken
      .pipe(
        first(),
        map((token$) => token$)
      )
      .toPromise();
    return new HttpHeaders().set("Authorization", `Bearer ${token}`);
  }
  async removeTeamMember(teamMember: UserView) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Remove Team Member",
        message: `Are you sure you want to remove this team member? This will delete all the roles this user has for ${this.client.name}, but will not delete the user's account.`,
        action: "Yes, remove.",
      },
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        this.loadingService.isLoading(
          true,
          `Removing user from the ${this.client.name} team...`
        );
        const headerObj = await this.getToken();
        const url = `${environment.apiUrl}teamMembers/${teamMember.id}?clientId=${this.client.id}`;
        this.http.delete(url, { headers: headerObj }).subscribe(
          (response$) => {
            this.snackBar.open(
              "Successfully removed team member. It may take a moment for your team member table to update.",
              "Dismiss",
              {
                duration: 5000,
              }
            );
            this.loadingService.isLoading(false);
          },
          () => {
            this.snackBar.open(
              "Oops.. something went wrong. Please refresh and try again.",
              "Dismiss",
              {
                duration: 5000,
              }
            );
            this.loadingService.isLoading(false);
          }
        );
      }
    });
  }
  copyInviteLink(invite: TeamMemberInvitation) {
    return `${environment.appBaseUrl}/invites/${invite.id}`;
  }
}
