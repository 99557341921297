import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  ContentChild,
} from "@angular/core";
import { NgForOfContext } from "@angular/common";

@Component({
  selector: "app-dashboard-widget",
  templateUrl: "./dashboard-widget.component.html",
  styleUrls: ["./dashboard-widget.component.scss"],
})
export class DashboardWidgetComponent implements OnInit {
  @ContentChild(TemplateRef) widgetTemplate: TemplateRef<NgForOfContext<any>>;
  @Input() title: string;
  @Input() description: string;
  @Input() widgetData: any[] = [];
  @Input() noResultsText: string;
  @Input() loadingWidgetData: boolean;
  constructor() {}

  ngOnInit() {}
}
