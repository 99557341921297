import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Input,
  OnDestroy,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  Client,
  CustomReport,
  DataUploadEvent,
  DataUploadEventFragment,
  DataUploadEventProgressCounter,
  DataUploadEventStatuses,
  DataUploadFragmentWarnings,
  PosSystem,
  ThirdParty,
} from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { ConfirmDialogComponent } from "app/dialogs/confirm-dialog/confirm-dialog.component";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import moment from "moment";
import { from, interval, Subject } from "rxjs";
import { DataUploadViewDialogComponent } from "../../../../dialogs/data-upload-view-dialog/data-upload-view-dialog.component";
import {
  debounce,
  distinctUntilChanged,
  first,
  scan,
  takeUntil,
} from "rxjs/operators";
import _ from "lodash";
import { combineAll } from "rxjs/operators";
export class DataUploadEventWithCounters extends DataUploadEvent {
  dataUploadEventProgressCounters: DataUploadEventProgressCounter[];
}
@Component({
  selector: "app-data-upload-events-table",
  templateUrl: "./data-upload-events-table.component.html",
  styleUrls: ["./data-upload-events-table.component.scss"],
})
export class DataUploadEventsTableComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() uploadGroupId: string;
  @Input() client: Client;
  @Input() posSystems: PosSystem[];
  @Input() thirdParties: ThirdParty[];
  @Input() customReports: CustomReport[];
  @Input() locations: Location[];
  public displayedColumns: string[] = [
    "remove",
    "date",
    "uploadPeriodEnd",
    "type",
    "account",
    "fileName",
    "notes",
    "status",
    "view",
    // "action",
  ];
  public dataUploadEvents = [];
  public tableData: MatTableDataSource<any[]>;
  private destroy$ = new Subject();
  private reset$ = new Subject();

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private store: Store<any>,
    private afs: AngularFirestore,
    private loadingService: LoadingDialogService
  ) {}

  ngOnInit(): void {
    this.fetchDataUploadEvents();
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  ngAfterViewInit(): void {}

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  setupDataTable() {}

  private fetchDataUploadEvents() {
    this.afs
      .collection("dataUploadEvents", (ref) =>
        ref
          .where("client", "==", this.client.id)
          .where("group", "==", this.uploadGroupId)
      )
      .snapshotChanges()
      .pipe(
        takeUntil(this.destroy$),
        // scan((i) => ++i, 1),
        debounce((i) => interval(500)),
        distinctUntilChanged((a, b) => {
          const curr = FirestoreUtilities.mapToType(a);
          const prev = FirestoreUtilities.mapToType(b);
          const currStatusSum =
            curr && curr.length > 0
              ? curr.reduce((sum, item) => {
                  return (sum += item.status);
                }, 0)
              : 0;
          const prevStatusSum =
            prev && prev.length > 0
              ? prev.reduce((sum, item) => {
                  return (sum += item.status);
                }, 0)
              : 0;
          console.log(`comp: ${curr.length} ==? ${prev.length}`);
          return curr.length === prev.length && currStatusSum === prevStatusSum;
        })
      )
      .subscribe((queryResults$) => {
        this.dataUploadEvents = FirestoreUtilities.mapToType(
          queryResults$
        ).sort((a, b) => {
          return moment(a.dateUpdated.toDate()).isBefore(b.dateUpdated.toDate())
            ? 1
            : -1;
        });
        console.log("table setting");
        this.tableData = new MatTableDataSource(this.dataUploadEvents);
        this.tableData.paginator = this.paginator;
        this.tableData.sort = this.sort;
        this.fetchDataUploaEventProgressCounters();
      });
  }
  private fetchDataUploaEventProgressCounters() {
    this.reset$.next(true);
    this.reset$.complete();
    this.reset$ = new Subject();
    const progressCounterRequests = this.dataUploadEvents.map(
      (dataUploadEvent) => {
        return this.afs
          .collection("dataUploadEventProgressCounters", (ref) =>
            ref.where("dataUploadEvent", "==", dataUploadEvent.id)
          )
          .snapshotChanges();
      }
    );
    from(progressCounterRequests)
      .pipe(combineAll(), takeUntil(this.reset$))
      .subscribe((progressCountersQueries$) => {
        const progressCounters = FirestoreUtilities.mergeCollectionToType(
          progressCountersQueries$
        );
        this.dataUploadEvents.forEach((dataUploadEvent) => {
          dataUploadEvent.dataUploadEventProgressCounters =
            progressCounters.filter(
              (counter) => (counter.dataUploadEvent = dataUploadEvent.id)
            );
          if (dataUploadEvent.dataUploadEventProgressCounters?.length > 0) {
            dataUploadEvent["progress"] = this.getProgress(
              dataUploadEvent.dataUploadEventProgressCounters
            );
          }
        });
      });
  }
  getProgress(counters: DataUploadEventProgressCounter[]) {
    if (counters?.length > 0) {
      const progress = counters.reduce(
        (progressObj, counter) => {
          progressObj.fragments += +counter.fragmentCount
            ? +counter.fragmentCount
            : 0;
          progressObj.commited += +counter.committedFragments
            ? +counter.committedFragments
            : 0;
          progressObj.errors += +counter.errorFragments
            ? +counter.errorFragments
            : 0;
          progressObj.validated += +counter.validatedFragments
            ? +counter.validatedFragments
            : 0;
          progressObj.cleared += +counter.clearedFragments
            ? +counter.clearedFragments
            : 0;
          return progressObj;
        },
        {
          fragments: 0,
          errors: 0,
          validated: 0,
          commited: 0,
          cleared: 0,
        }
      );
      return +(
        (_.sum([
          progress.errors,
          progress.validated,
          progress.commited,
          progress.cleared,
        ]) /
          progress.fragments) *
        100
      ).toFixed(2);
    }
  }
  private aggregateWarnings(dataUploadEventFragments) {
    const warnings = new DataUploadFragmentWarnings();
    warnings.accountMappingErrors = [];
    warnings.missingFields = [];
    dataUploadEventFragments.forEach(
      (dataUploadFragment: DataUploadEventFragment) => {
        if (dataUploadFragment.warnings?.acccountMappingErrors?.length > 0) {
          warnings.accountMappingErrors = [
            ...warnings.accountMappingErrors,
            ...dataUploadFragment.warnings?.accountMappingErrors,
          ];
        }
        if (dataUploadFragment.warnings?.missingFields?.length > 0) {
          warnings.missingFields = [
            ...warnings.missingFields,
            ...dataUploadFragment.warnings?.missingFields,
          ];
        }
      }
    );
    warnings.accountMappingErrors = _.uniq(warnings.accountMappingErrors);
    warnings.missingFields = _.uniq(warnings.missingFields);
    return warnings;
  }
  // private aggregateWarnings(dataUploadEventFragments) {
  //   const warnings = new DataUploadFragmentWarnings();
  //   warnings.accountMappingErrors = [];
  //   dataUploadEventFragments.forEach(
  //     (dataUploadFragment: DataUploadEventFragment) => {
  //       if (dataUploadFragment.warnings) {
  //         warnings.accountMappingErrors = [
  //           ...warnings.accountMappingErrors,
  //           ...dataUploadFragment.warnings.accountMappingErrors,
  //         ];
  //       }
  //     }
  //   );
  //   return warnings;
  // }

  /**
   * Compiled Properties
   */

  uploadProcessing(upload: DataUploadEvent) {
    return (
      [
        DataUploadEventStatuses.cleaning,
        DataUploadEventStatuses.running,
        DataUploadEventStatuses.clearingBatch,
        DataUploadEventStatuses.committing,
      ].indexOf(upload.status) > -1
    );
  }
  viewDataUploadEvent(dataUploadEvent) {
    this.dialog.open(DataUploadViewDialogComponent, {
      panelClass: "invisible-panel-dialog",
      disableClose: true,
      data: {
        dataUploadEvent,
        posSystems: this.posSystems,
        thirdParties: this.thirdParties,
        locations: this.locations,
        client: this.client,
      },
    });
  }
  getStatusName(status: DataUploadEventStatuses) {
    switch (status) {
      case DataUploadEventStatuses.running:
        return "Running";
      case DataUploadEventStatuses.validated:
        return "Validated";
      case DataUploadEventStatuses.committing:
        return "Committing";
      case DataUploadEventStatuses.errors:
        return "Errors";
      case DataUploadEventStatuses.complete:
        return "Complete";
      case DataUploadEventStatuses.cleaning:
        return "Cleaning";
      case DataUploadEventStatuses.clearingBatch:
        return "Removing Transactions";
      case DataUploadEventStatuses.transactionsRemoved:
        return "Transactions Removed";
    }
  }
  getAccountName(dataUploadEvent: DataUploadEvent) {
    let account;
    if (dataUploadEvent.type === "POS") {
      account = this.posSystems.find(
        (posSystem) => posSystem.id === dataUploadEvent.account
      );
    } else if (dataUploadEvent.type === "3PD") {
      account = this.thirdParties.find(
        (thirdParty) => thirdParty.id === dataUploadEvent.account
      );
    } else if (dataUploadEvent.type === "Custom Report") {
      account = this.customReports.find(
        (customReport) => customReport.id === dataUploadEvent.customReport
      );
    }
    return account ? account.name : "";
  }
  private async updateGroup(dataUploadEvent) {
    if (dataUploadEvent.group !== "ungrouped") {
      await this.afs
        .doc(`dataUploadEventGroups/${dataUploadEvent.group}`)
        .update({ dataUpdated: moment().toDate() });
    }
  }
  /***
   * Actions
   */
  async commitUpload(dataUploadEvent: DataUploadEvent) {
    const fragments = await this.fetchDataUploadEventFragments(dataUploadEvent);
    const updateBatch = this.afs.firestore.batch();
    fragments.forEach((fragment) => {
      updateBatch.update(
        this.afs.doc(`dataUploadEventFragments/${fragment.id}`).ref,
        {
          status: DataUploadEventStatuses.committing,
        }
      );
    });
    const uploadWarnings = this.aggregateWarnings(fragments);
    await Promise.all([
      updateBatch.commit(),
      this.afs.doc(`dataUploadEvents/${dataUploadEvent.id}`).update({
        validatedFragments: 0,
        errorFragments: 0,
        committedFragments: 0,
        clearedFragments: 0,
        warnings: uploadWarnings ? uploadWarnings.toJSONObject() : null,
        status: DataUploadEventStatuses.committing,
      }),
    ]);
    await this.updateGroup(dataUploadEvent);
  }

  removeBatchTransactions(dataUploadEvent) {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Delete Transactions",
        message: `Are you sure you want delete all ${dataUploadEvent.rowCount} transactions associated with this data upload log?.`,
        action: "Yes, Delete.",
      },
    });
    confirmDialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        this.loadingService.isLoading(true, "Creating transaction removal job");
        const fragments = await this.fetchDataUploadEventFragments(
          dataUploadEvent
        );
        const deleteBatch = this.afs.firestore.batch();
        fragments.forEach((fragment) => {
          deleteBatch.update(
            this.afs.doc(`dataUploadEventFragments/${fragment.id}`).ref,
            {
              status: DataUploadEventStatuses.clearingBatch,
            }
          );
        });
        const uploadWarnings = this.aggregateWarnings(fragments);
        await Promise.all([
          deleteBatch.commit(),
          await this.afs.doc(`dataUploadEvents/${dataUploadEvent.id}`).update({
            warnings: uploadWarnings ? uploadWarnings.toJSONObject() : null,
            validatedFragments: 0,
            errorFragments: 0,
            committedFragments: 0,
            clearedFragments: 0,
            status: DataUploadEventStatuses.clearingBatch,
          }),
        ]);
        await this.updateGroup(dataUploadEvent);
        this.loadingService.isLoading(false);
        this.snackBar.open("Delete transactions job started.", "Dismiss", {
          duration: 5000,
        });
      }
    });
  }
  deleteUploadEvent(dataUploadEvent) {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Delete DataUpload Event",
        message: `Are you sure you want delete this data upload event?`,
        action: "Yes, Delete.",
      },
    });
    confirmDialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`dataUploadEvents/${dataUploadEvent.id}`).delete();
        this.snackBar.open("Upload event deleted successfully.", "Dismiss", {
          duration: 5000,
        });
      }
    });
  }
  private async fetchDataUploadEventFragments(dataUploadEvent) {
    return FirestoreUtilities.mapToType(
      await this.afs
        .collection("dataUploadEventFragments", (ref) =>
          ref
            .where("dataUploadEvent", "==", dataUploadEvent.id)
            .where("client", "==", this.client.id)
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise()
    );
  }
}
