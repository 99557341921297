import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import {
  ThirdPartyReportFragmentAdjustmentLog,
  ThirdPartyReportFragmentPayoutReport,
} from "@deliver-sense-librarian/data-schema";
import { FormBuilder } from "@angular/forms";
import { Store } from "@ngrx/store";
import _ from "lodash";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import {
  ReconciliationDrillDownReportTypes,
  ReconciliationReportDatUtility,
} from "../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../utilities/reconciliation-report-filtering.utility";
import moment from "moment";
import { FirestoreUtilities } from "../../../../../utilities/firestore-utilities";
import { first } from "rxjs/operators";

@Component({
  selector: "app-adjustments-analysis",
  templateUrl: "./adjustments-analysis.component.html",
  styleUrls: ["./adjustments-analysis.component.scss"],
})
export class AdjustmentsAnalysisComponent implements OnInit {
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() exportUtility: ReconciliationReportExportUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  thirdPartyReportFragmentAdjustmentLogs: ThirdPartyReportFragmentAdjustmentLog[] =
    [];
  data: ThirdPartyReportFragmentPayoutReport[] = [];
  loadingPayoutReports: boolean;

  constructor(
    private afs: AngularFirestore,
    private cdr: ChangeDetectorRef,
    private store: Store<any>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loadPayoutAnalysisReports();
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }
  private async loadPayoutAnalysisReports() {
    this.loadingPayoutReports = true;
    this.thirdPartyReportFragmentAdjustmentLogs =
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.adjustmentLogs
      );
    this.thirdPartyReportFragmentAdjustmentLogs.forEach((report) => {
      report["thirdPartyName"] = this.dataUtility.getThirdPartyName(
        report.thirdParty
      );
    });
    this.thirdPartyReportFragmentAdjustmentLogs =
      this.thirdPartyReportFragmentAdjustmentLogs.sort((a, b) => {
        return moment(a.payoutDate, "M/DD/YYYY").isBefore(
          moment(b.payoutDate, "M/DD/YYYY")
        )
          ? -1
          : 1;
      });
    this.loadingPayoutReports = false;
  }
}
