import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  Client,
  Entity,
  Location,
  OrganizationRole,
  ThirdParty,
  User,
  UserRoles,
  usStates,
} from "@deliver-sense-librarian/data-schema";
import { FirestoreUtilities } from "../../../../utilities/firestore-utilities";
import { Store } from "@ngrx/store";
import { ConfirmDialogComponent } from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import { LoadingDialogService } from "../../../../services/loading-dialog.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { combineLatest } from "rxjs";
import { first } from "rxjs/operators";

@Component({
  selector: "app-entity",
  templateUrl: "./entity.component.html",
  styleUrls: ["./entity.component.scss"],
})
export class EntityComponent implements OnInit {
  @Input() entity: Entity;
  @Input() selectedClient: Client;
  @Output() formSavedSuccess = new EventEmitter();
  entityForm: FormGroup;
  user: User;
  client: any;
  states = usStates;
  thirdParties: ThirdParty[] = [];
  locations: Location[] = [];
  locationRoles: OrganizationRole[];

  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private activedRoute: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private afs: AngularFirestore
  ) {}

  ngOnInit() {
    combineLatest([
      this.store.select((store) => store.uiState.authUser),
      this.store.select((store) => store.uiState.client),
      this.store.select((store) => store.uiState.clientThirdParties),
      this.store.select((store) => store.uiState.locations),
    ]).subscribe(([user$, client$, clientThirdParties$, locations$]) => {
      if (user$ && client$ && clientThirdParties$?.length > 0 && locations$) {
        this.user = user$;
        this.client = client$;
        this.locationRoles = locations$;
        this.thirdParties = <ThirdParty[]>clientThirdParties$
          ? clientThirdParties$.map((client3pd) => client3pd.thirdParty)
          : [];
        if (!this.entity) {
          this.activedRoute.params.subscribe((params$) => {
            if (params$["id"] && params$["id"] !== "new") {
              this.fetchEntity(params$["id"]);
            } else {
              this.entity = new Entity();
              this.setupEntityForm();
            }
          });
        } else {
          this.setupEntityForm();
        }
      }
    });
  }

  private fetchEntity(entityId) {
    this.afs
      .doc(`entities/${entityId}`)
      .snapshotChanges()
      .subscribe((entity$) => {
        this.entity = FirestoreUtilities.objectToType(entity$);
        this.getUserOrgResources();

        this.setupEntityForm();
      });
  }

  private async getUserOrgResources() {
    if (this.user.internalRole >= 1) {
      await this.getAllOrganizationResources();
    } else {
      await this.getAccessibleOrganizationResources();
    }
  }

  private async getAllOrganizationResources() {
    const locations = FirestoreUtilities.mapToType(
      await this.afs
        .collection("locations", (ref) =>
          ref.where("client", "==", this.client.id)
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise()
    );
    this.locations = locations.filter(
      (location) => location.entity === this.entity.id
    );
  }

  private async getAccessibleOrganizationResources() {
    this.locations = (
      await FirestoreUtilities.getUserAccessibleResourcesOfType(
        "locations",
        this.afs,
        this.locationRoles,
        [UserRoles.admin, UserRoles.contributor]
      )
        .pipe(first())
        .toPromise()
    ).filter((location) => location.entity === this.entity.id);
  }

  private setupEntityForm() {
    this.entityForm = this.fb.group({
      name: new FormControl(
        this.entity.name ? this.entity.name : "",
        Validators.required
      ),
      client: new FormControl(
        this.client ? this.client.id : "",
        Validators.required
      ),
      notes: new FormControl(this.entity.notes),
      addressLine1: new FormControl(
        this.entity.addressLine1 ? this.entity.addressLine1 : ""
      ),
      addressLine2: new FormControl(
        this.entity.addressLine2 ? this.entity.addressLine2 : ""
      ),
      addressCity: new FormControl(
        this.entity.addressCity ? this.entity.addressCity : ""
      ),
      addressState: new FormControl(
        this.entity.addressState ? this.entity.addressState : ""
      ),
      addressPostalCode: new FormControl(
        this.entity.addressPostalCode ? this.entity.addressPostalCode : ""
      ),
      invoicingEmails: new FormArray([]),
      recRecipients: new FormArray([]),
    });
    this.setupEmailArrays();
  }

  private setupEmailArrays() {
    if (this.entity.invoicingEmails) {
      const invoicingEmailsArray = this.entityForm.get(
        "invoicingEmails"
      ) as FormArray;
      this.entity.invoicingEmails.forEach((emailAddress) => {
        invoicingEmailsArray.push(
          new FormGroup({
            email: new FormControl(emailAddress, [
              Validators.required,
              Validators.email,
            ]),
          })
        );
      });
    }
    if (this.entity.recRecipients) {
      const recEmailsArray = this.entityForm.get("recRecipients") as FormArray;
      this.entity.recRecipients.forEach((emailAddress) => {
        recEmailsArray.push(
          new FormGroup({
            email: new FormControl(emailAddress, [
              Validators.required,
              Validators.email,
            ]),
          })
        );
      });
    }
  }
  private removeInvoiceEmail(index) {
    const invoicingEmailsArray = this.entityForm.get(
      "invoicingEmails"
    ) as FormArray;
    invoicingEmailsArray.removeAt(index);
  }
  private addInvoiceEmail() {
    const invoicingEmailsArray = this.entityForm.get(
      "invoicingEmails"
    ) as FormArray;
    invoicingEmailsArray.push(
      new FormGroup({
        email: new FormControl("", [Validators.required, Validators.email]),
      })
    );
  }
  private removeRecEmail(index) {
    const recEmailsArray = this.entityForm.get("recRecipients") as FormArray;
    recEmailsArray.removeAt(index);
  }
  private addRecEmail() {
    const recEmailsArray = this.entityForm.get("recRecipients") as FormArray;
    recEmailsArray.push(
      new FormGroup({
        email: new FormControl("", [Validators.required, Validators.email]),
      })
    );
  }
  public async save() {
    this.entityForm.updateValueAndValidity();
    if (this.entityForm.valid) {
      if (this.entity && this.entity.id) {
        const _entity = this.mapFormToEntity();
        await this.afs
          .doc(`entities/${this.entity.id}`)
          .update(_entity.toJSONObject());
        this.snackBar.open("Successfully updated the entity", "Dismiss", {
          duration: 5000,
        });
        this.formSavedSuccess.emit(true);
        await this.router.navigate(["/app/organization/entities"]);
      } else {
        const _entity = this.mapFormToEntity();
        const newEntity$ = await this.afs
          .collection(`entities`)
          .add(_entity.toJSONObject());
        await this.afs
          .doc(
            `users/${this.user.id}/clientRoles/${this.client.id}/organizationRoles/${newEntity$.id}`
          )
          .set({
            resource: newEntity$.id,
            role: UserRoles.admin,
            type: "entity",
          });
        this.snackBar.open("Successfully created the entity", "Dismiss", {
          duration: 5000,
        });
        this.formSavedSuccess.emit(true);
        await this.router.navigate(["/app/organization/entities"]);
      }
    } else {
      this.snackBar.open("Please fill out all required fields.", "Dismiss", {
        duration: 5000,
      });
    }
  }

  private mapFormToEntity() {
    const _entity = new Entity();
    const form = this.entityForm.value;
    _entity.name = form.name;
    _entity.primaryContact = this.user.id;
    _entity.client = this.client.id;
    _entity.addressLine1 = form.addressLine1;
    _entity.addressLine2 = form.addressLine2;
    _entity.addressCity = form.addressCity;
    _entity.addressState = form.addressState;
    _entity.addressPostalCode = form.addressPostalCode;
    _entity.recRecipients = form.recRecipients.map((control) => control.email);
    _entity.invoicingEmails = form.invoicingEmails.map(
      (control) => control.email
    );
    return _entity;
  }

  delete() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Delete",
        message:
          "Are you sure you want to delete this entity?. All departments and projects within this entity will also be deleted.",
        action: "Yes, Delete",
      },
    });
    confirmDialog.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`entities/${this.entity.id}`).delete();
        this.loadingService.isLoading(true, "Deleting entity...");
        setTimeout(() => {
          this.loadingService.isLoading(false);
          this.snackBar.open("Entity deleted successfully!", "Dismiss", {
            duration: 5000,
          });
          this.router.navigate(["/app/organization/entities"]);
        }, 1000);
      }
    });
  }
}
