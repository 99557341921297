import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
  EventEmitter,
  Output,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "lodash";
import { Papa } from "ngx-papaparse";
import {
  ThirdPartyReconciliationLocationData,
  ThirdPartyReportSummary,
  ThirdPartyReportFragmentVarianceAnalysisReport,
  TransactionAITypes,
  ThirdPartyPerformanceSummaryReport,
} from "@deliver-sense-librarian/data-schema";
import { FormControl } from "@angular/forms";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import {
  ReconciliationDrillDownReportTypes,
  ReconciliationReportDatUtility,
} from "../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../utilities/reconciliation-report-filtering.utility";
@Component({
  selector: "app-reconciliation-summary-report",
  templateUrl: "./reconciliation-summary-report.component.html",
  styleUrls: ["./reconciliation-summary-report.component.scss"],
})
export class ReconciliationSummaryReportComponent
  implements OnInit, AfterViewInit
{
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() exportUtility: ReconciliationReportExportUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @Output() jumpToDailyDrillDown = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public thirdPartyReportVarianceAnalysisReports: ThirdPartyReportFragmentVarianceAnalysisReport[];
  public filterLocationControl = new FormControl([]);
  public filterDspControl = new FormControl([]);
  public reportLocationSummaries: ThirdPartyReportSummary[];
  public loadingSummaryData: boolean;
  public thirdPartyPerformanceSummaryReports: ThirdPartyPerformanceSummaryReport[];
  constructor(
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private papa: Papa
  ) {}

  ngOnInit() {
    this.setupFilterControls();
    this.initializeTable();
  }
  ngAfterViewInit() {}
  private async initializeTable() {
    this.loadingSummaryData = true;
    this.thirdPartyReportVarianceAnalysisReports =
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.varianceAnalysisReports
      );
    this.thirdPartyPerformanceSummaryReports = Object.assign(
      //NEW Instance so as not to modify utility doc
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.thirdPartyPerformanceSummaryReport
      )
    );
    this.compileThirdPartyReportSummary();
    this.loadingSummaryData = false;
  }
  private setupFilterControls() {
    this.filterDspControl.updateValueAndValidity();
    this.filterDspControl.valueChanges.subscribe((selectedThirdParties) => {
      this.filterLocationControl.reset();
      this.filterLocationControl.updateValueAndValidity();
      this.filterTableByDsp(selectedThirdParties);
    });
  }
  filterTableByDsp(selectedThirdParties) {
    this.loadingSummaryData = true;
    const reconciliationLocationGroups = _.groupBy(
      this.dataUtility.reportData,
      "locationId"
    );
    const varianceAnalysisGroups = _.groupBy(
      this.thirdPartyReportVarianceAnalysisReports,
      "location"
    );
    const performanceReportGroups = _.groupBy(
      this.thirdPartyPerformanceSummaryReports,
      "location"
    );
    this.reportLocationSummaries = Object.keys(
      reconciliationLocationGroups
    ).map((key, value) => {
      const reconciliations = reconciliationLocationGroups[key].filter(
        (rec) => selectedThirdParties.indexOf(rec.thirdParty) > -1
      );
      const varianceAnalytics = varianceAnalysisGroups[key].filter(
        (varianceAnalysis) =>
          selectedThirdParties.indexOf(varianceAnalysis.thirdParty) > -1
      );
      const performanceReports = performanceReportGroups[key].filter(
        (performanceReport) =>
          selectedThirdParties.indexOf(performanceReport.thirdParty) > -1
      );
      return this.runSummaryCalcs(
        key,
        reconciliations,
        varianceAnalytics,
        performanceReports
      );
    });
    setTimeout(() => {
      this.loadingSummaryData = false;
    });
  }
  private compileThirdPartyReportSummary() {
    const reconciliationLocationGroups = _.groupBy(
      this.dataUtility.reportData,
      "locationId"
    );
    const varianceAnalysisGroups = _.groupBy(
      this.thirdPartyReportVarianceAnalysisReports,
      "location"
    );
    const performanceReportGroups = _.groupBy(
      this.thirdPartyPerformanceSummaryReports,
      "location"
    );

    this.reportLocationSummaries = this.dataUtility.reportData.map((report) => {
      const vaReport = this.thirdPartyReportVarianceAnalysisReports.find(
        (va) =>
          va.location === report.locationId &&
          va.thirdParty === report.thirdParty
      );
      const performanceReport = this.thirdPartyPerformanceSummaryReports.find(
        (performance) =>
          performance.location === report.locationId &&
          performance.thirdParty === report.thirdParty
      );
      return this.createSummary(report, vaReport, performanceReport);
    });
  }
  emitDdShortCut(row) {
    this.jumpToDailyDrillDown.emit({
      locationId: row.location,
      thirdPartyId: row.thirdParty,
    });
  }

  private runSummaryCalcs(
    locationId,
    reconciliations: ThirdPartyReconciliationLocationData[],
    varianceAnalytics: ThirdPartyReportFragmentVarianceAnalysisReport[],
    thirdPartyPerformanceReports: ThirdPartyPerformanceSummaryReport[]
  ) {
    const dspReportSummary = new ThirdPartyReportSummary();
    dspReportSummary.location = locationId;
    // Sales
    dspReportSummary.posSales = +reconciliations
      .reduce((sum, rec) => {
        return (sum += +rec.posNetSales ? +rec.posNetSales : 0);
      }, 0)
      .toFixed(2);
    dspReportSummary.dspSales = +reconciliations
      .reduce((sum, rec) => {
        return (sum += +rec.thirdPartyNetSales ? +rec.thirdPartyNetSales : 0);
      }, 0)
      .toFixed(2);
    dspReportSummary.salesVariance =
      dspReportSummary.posSales - dspReportSummary.dspSales;
    dspReportSummary.errorCharges = varianceAnalytics
      ? +varianceAnalytics
          .reduce((sum, varianceAi) => {
            const errorCharges = varianceAi.salesVariance
              ? varianceAi.salesVarianceReasons.find(
                  (reason) => reason.type === TransactionAITypes.errorCharge
                )
              : null;
            if (errorCharges && errorCharges.sale != 0) {
              return (sum += errorCharges.sale);
            } else {
              return sum;
            }
          }, 0)
          .toFixed(2)
      : 0;
    dspReportSummary.cancelledOrders = varianceAnalytics
      ? +varianceAnalytics
          .reduce((sum, varianceAi) => {
            const errorCharges = varianceAi.salesVariance
              ? varianceAi.salesVarianceReasons.find(
                  (reason) =>
                    reason.type === TransactionAITypes.transactionNotPaidOut
                )
              : null;
            if (errorCharges && errorCharges.sale != 0) {
              return (sum += errorCharges.sale);
            } else {
              return sum;
            }
          }, 0)
          .toFixed(2)
      : 0;
    dspReportSummary.allCanceled = thirdPartyPerformanceReports
      ? +(+thirdPartyPerformanceReports.reduce((sum, report) => {
          return (sum += +report.canceledOrderAmount
            ? +report.canceledOrderAmount
            : 0);
        }, 0)).toFixed(2)
      : 0;
    dspReportSummary.remainingVariance = +(
      dspReportSummary.salesVariance -
      (dspReportSummary.errorCharges + dspReportSummary.cancelledOrders)
    ).toFixed(2);
    // Tax
    dspReportSummary.posTax = +reconciliations
      .reduce((sum, rec) => {
        return (sum += +rec.posNetTax ? +rec.posNetTax : 0);
      }, 0)
      .toFixed(2);
    dspReportSummary.dspTax = +reconciliations
      .reduce((sum, rec) => {
        return (sum += +rec.thirdPartyNetTax ? +rec.thirdPartyNetTax : 0);
      }, 0)
      .toFixed(2);
    dspReportSummary.taxVariance =
      dspReportSummary.posTax - dspReportSummary.dspTax;
    dspReportSummary.rateError = !!reconciliations.find(
      (reconciliation) => reconciliation.taxRateVarianceErrors
    );
    //MF
    dspReportSummary.mfSales = reconciliations.reduce((sum, rec) => {
      if (rec.marketFacilitatorTax !== 0) {
        return (sum += rec.thirdPartyNetSales);
      }
      return sum;
    }, 0);
    dspReportSummary.mfTax = reconciliations.reduce((sum, rec) => {
      return (sum += rec.marketFacilitatorTax);
    }, 0);
    dspReportSummary.partialCollection = !!reconciliations.find(
      (reconciliations) => reconciliations.isPartialMfTax
    );
    //Fees
    dspReportSummary.deliveryFees = reconciliations.reduce((sum, rec) => {
      return (sum += +rec.deliveryFees ? +rec.deliveryFees : 0);
    }, 0);
    dspReportSummary.pickupFees = reconciliations.reduce((sum, rec) => {
      return (sum += +rec.pickupFees ? +rec.pickupFees : 0);
    }, 0);
    dspReportSummary.promoFees = reconciliations.reduce((sum, rec) => {
      return (sum += +rec.promoFees ? +rec.promoFees : 0);
    }, 0);
    dspReportSummary.cateringFees = reconciliations.reduce((sum, rec) => {
      return (sum += +rec.cateringFees ? +rec.cateringFees : 0);
    }, 0);

    dspReportSummary.totalFees =
      dspReportSummary.deliveryFees +
      dspReportSummary.pickupFees +
      dspReportSummary.promoFees +
      dspReportSummary.cateringFees;
    let feeCount = 0;
    const totalEffectiveFeePercentage = reconciliations.reduce((sum, rec) => {
      if (rec.totalFees !== 0) {
        feeCount++;
        return (sum += +rec.effectiveTotalFeeRate
          ? +rec.effectiveTotalFeeRate
          : 0);
      } else {
        return sum;
      }
    }, 0);
    dspReportSummary.averageFeePercentage =
      feeCount > 0
        ? +((totalEffectiveFeePercentage / feeCount) * 100).toFixed(2)
        : 0;
    dspReportSummary.campaignPromoFees = reconciliations.reduce((sum, rec) => {
      return (sum += +rec.campaignPromoFees ? +rec.campaignPromoFees : 0);
    }, 0);
    return dspReportSummary;
  }
  createSummary(
    reconciliation: ThirdPartyReconciliationLocationData,
    varianceAnalytic: ThirdPartyReportFragmentVarianceAnalysisReport,
    thirdPartyPerformanceReport: ThirdPartyPerformanceSummaryReport
  ) {
    const dspReportSummary = new ThirdPartyReportSummary();
    dspReportSummary.location = reconciliation.locationId;
    dspReportSummary["thirdParty"] = reconciliation.thirdParty;
    dspReportSummary["thirdPartyName"] = this.dataUtility.getThirdPartyName(
      reconciliation.thirdParty
    );
    // Sales
    dspReportSummary.posSales = +reconciliation.posNetSales;
    dspReportSummary.dspSales = reconciliation.thirdPartyNetSales;
    dspReportSummary.salesVariance =
      dspReportSummary.posSales - dspReportSummary.dspSales;
    dspReportSummary.errorCharges = this.getErrorChargeAmount(varianceAnalytic);
    dspReportSummary.cancelledOrders =
      this.getCancelledUnpaidOrders(varianceAnalytic);

    dspReportSummary.allCanceled = thirdPartyPerformanceReport
      ? thirdPartyPerformanceReport.canceledOrderAmount
      : 0;
    dspReportSummary.remainingVariance = +(
      dspReportSummary.salesVariance -
      (dspReportSummary.errorCharges + dspReportSummary.cancelledOrders)
    ).toFixed(2);
    // Tax
    dspReportSummary.posTax = +reconciliation.posNetTax;
    dspReportSummary.dspTax = +reconciliation.thirdPartyNetTax;
    dspReportSummary.taxVariance =
      dspReportSummary.posTax - dspReportSummary.dspTax;
    dspReportSummary.rateError = !!reconciliation.taxRateVarianceErrors;
    //MF
    dspReportSummary.mfSales =
      reconciliation.marketFacilitatorTax !== 0
        ? reconciliation.thirdPartyNetSales
        : 0;
    dspReportSummary.mfTax = reconciliation.marketFacilitatorTax;
    dspReportSummary.partialCollection = !!reconciliation.isPartialMfTax;
    //Fees
    dspReportSummary.deliveryFees = reconciliation.deliveryFees;
    dspReportSummary.pickupFees = reconciliation.pickupFees;
    dspReportSummary.promoFees = reconciliation.promoFees;
    dspReportSummary.cateringFees = reconciliation.cateringFees;
    dspReportSummary.totalFees =
      dspReportSummary.deliveryFees +
      dspReportSummary.pickupFees +
      dspReportSummary.promoFees +
      dspReportSummary.cateringFees;
    let feeCount = 0;
    dspReportSummary.averageFeePercentage = +(
      reconciliation.effectiveTotalFeeRate * 100
    ).toFixed(2);
    dspReportSummary.campaignPromoFees = reconciliation.campaignPromoFees;
    return dspReportSummary;
  }
  getErrorChargeAmount(varianceAi) {
    const errorCharges = varianceAi?.salesVariance
      ? varianceAi.salesVarianceReasons.find(
          (reason) => reason.type === TransactionAITypes.errorCharge
        )
      : null;
    return errorCharges && errorCharges.sale != 0 ? errorCharges.sale : 0;
  }
  getCancelledUnpaidOrders(varianceAi) {
    const cancelledInPos = varianceAi?.salesVariance
      ? varianceAi.salesVarianceReasons.find(
          (reason) => reason.type === TransactionAITypes.transactionNotPaidOut
        )
      : null;
    return cancelledInPos && cancelledInPos.sale != 0 ? cancelledInPos.sale : 0;
  }
}
