import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StripeService} from "./stripe.service";
import {FunctionsService} from "./functions.service";
import {LoadingDialogService} from "./loading-dialog.service";
import {HelpService} from "./help.service";
import {NotificationsService} from "./notifications.service";
import { UploadDocumentService } from './upload-document.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    StripeService,
    FunctionsService,
    LoadingDialogService,
    HelpService,
    NotificationsService,
    UploadDocumentService,
  ]
})
export class ServicesModule {
}
