import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ReconciliationReportDatUtility } from "../../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../../utilities/reconciliation-report-filtering.utility";
import { FormControl } from "@angular/forms";
import { AfterViewInit } from "@angular/core";
import {
  Location,
  ThirdPartyReportCanceledOrderDisputeLog,
  ThirdPartyTransaction,
} from "@deliver-sense-librarian/data-schema";
import { ReconciliationReportExportUtility } from "../../../utilities/reconciliation-report-export.utility";
import { TransactionsDialogComponent } from "app/dialogs/transactions-dialog/transactions-dialog.component";
import * as moment from "moment";
import { Papa } from "ngx-papaparse";
import {
  downloadDataAsFile,
  getCanceledOrderDisputeReason,
  getLocationAddress,
} from "app/shared/ds-constant";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { AngularFirestore } from "@angular/fire/firestore";
import { first, startWith } from "rxjs/operators";
import { combineLatest } from "rxjs";
import * as fs from "file-saver";
import { Workbook } from "exceljs";
import { CanceledOrderDisputeManagerDialogComponent } from "app/dialogs/canceled-order-dispute-manager-dialog/canceled-order-dispute-manager-dialog.component";
import _ from "lodash";
import { CanceledTransaction } from "../canceled-orders-summary-report.component";
import * as JSZip from "jszip";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-canceled-orders-summary-table",
  templateUrl: "./canceled-orders-summary-table.component.html",
  styleUrls: ["./canceled-orders-summary-table.component.scss"],
})
export class CanceledOrdersSummaryTableComponent
  implements OnInit, AfterViewInit
{
  @Input() data: CanceledTransaction[] = [];
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @Input() exportUtility: ReconciliationReportExportUtility;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<CanceledTransaction>;
  public displayedColumns: string[] = [
    "location",
    "thirdParty",
    "date",
    "sale",
    "saleCorrection",
    "tax",
    "taxCorrection",
    "deliveryFees",
    "pickupFees",
    "promoFees",
    "transactionId",
    "status",
    "transactionType",
    "description",
    "details",
    "inPos",
    "disputeLog",
  ];
  public filter3pdsControl = new FormControl([]);
  public filterLocationsControl = new FormControl([]);
  public filterDisputeOnly = new FormControl(false);
  constructor(
    private dialog: MatDialog,
    private papa: Papa,
    private afs: AngularFirestore,
    private loadingService: LoadingDialogService,
    private snackbar: MatSnackBar
  ) {}
  ngOnInit(): void {
    this.tableData = new MatTableDataSource(this.data);
  }
  ngAfterViewInit(): void {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.setFilterPredicate(
      this.tableData,
      this.filter3pdsControl,
      this.filterLocationsControl,
      this.filterDisputeOnly
    );
  }
  async exportCancelationDisputes() {
    const disputeEligibleByLocation = _.groupBy(
      this.tableData.data.filter((row) => !!row.disputeEligible),
      "dspStoreId"
    );
    const blobs = await Promise.all(
      Object.keys(disputeEligibleByLocation).map(async (dspStoreId: string) => {
        const cancelDisputeWorkbook = new Workbook();
        const canceledOrdersSheet = cancelDisputeWorkbook.addWorksheet(
          "Canceled Orders To Dispute"
        );
        canceledOrdersSheet.addRow([
          "Store Name",
          "Store ID",
          "Store Address",
          "Order Id",
          "Canceled Order Payment Amount",
          "Reason For Reimbursement",
        ]);
        disputeEligibleByLocation[dspStoreId].forEach((canceledTransaction) => {
          const orderLocation = <Location>(
            this.dataUtility.locations.find(
              (location) => location.locationId === canceledTransaction.location
            )
          );
          canceledOrdersSheet.addRow([
            canceledTransaction.dspStoreName,
            dspStoreId,
            getLocationAddress(orderLocation),
            canceledTransaction.transactionId,
            canceledTransaction.sale,
            getCanceledOrderDisputeReason(canceledTransaction),
          ]);
        });
        const data = await cancelDisputeWorkbook.xlsx.writeBuffer();
        const summaryXml = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        return {
          blob: summaryXml,
          fileName: `${this.dataUtility.client.name}_Store-${dspStoreId}_CanceledOrderDisputes.xlsx`,
          dspStoreId: dspStoreId,
        };
      })
    );
    let zip = new JSZip();
    blobs.forEach((fileDefinition) => {
      zip.file(fileDefinition.fileName, fileDefinition.blob);
    });
    const zipContent = await zip.generateAsync({
      type: "base64",
    });
    const link = document.createElement("a");
    link.href = "data:application/zip;base64," + zipContent;
    link.download = `${this.dataUtility.client.name}-CanceledOrderDisputeFiles-${this.dataUtility.existingReport.name}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return;
  }
  async markAllDisputed() {
    this.loadingService.isLoading(true, "Marking canceled orders as disputed");
    const disputeChunks = _.chunk(
      this.data.filter((canceledTransaction) => {
        return !!canceledTransaction.disputeEligible;
      }),
      300
    );
    const disputeLogPairs = {};
    try {
      await Promise.all(
        disputeChunks.map((chunk) => {
          const batch = this.afs.firestore.batch();
          chunk.forEach((disputedCanceledTransaction: CanceledTransaction) => {
            const newId = this.afs.createId();
            const disputeLog = this.createNewDisputeLog(
              disputedCanceledTransaction
            );
            batch.set(
              this.afs
                .collection("thirdPartyReportCanceledOrderDisputeLogs")
                .doc(newId).ref,
              disputeLog.toJSONObject()
            );
            disputeLog.id = newId;
            disputeLogPairs[disputedCanceledTransaction.id] = disputeLog;
            disputedCanceledTransaction.disputeLog = disputeLog;
          });
          return batch.commit();
        })
      );
      this.data.forEach((canceledTransaction) => {
        const foundLog = disputeLogPairs[canceledTransaction.id];
        if (foundLog) {
          canceledTransaction.disputeLog = foundLog;
        }
      });
      this.snackbar.open("All cancled orders marked disputed", "Dismiss", {
        duration: 5000,
      });
    } catch (e) {
      console.error(e);
      this.snackbar.open(
        "Oops something went wrong. Please refresh and try again",
        "Dismiss",
        { duration: 5000 }
      );
    } finally {
      this.loadingService.isLoading(false);
    }
  }
  createNewDisputeLog(canceledTransaction: CanceledTransaction) {
    const log = new ThirdPartyReportCanceledOrderDisputeLog();
    log.client = this.dataUtility.client.id;
    log.thirdPartyReport = this.dataUtility.existingReport.id;
    log.transaction = canceledTransaction.id;
    log.canceledOrderDisputed = true;
    log.canceledOrderDisputeReason =
      getCanceledOrderDisputeReason(canceledTransaction);
    return log;
  }
  exportReport() {
    const data = Object.assign(
      this.tableData.data.map((data: ThirdPartyTransaction) => {
        const orderLocation = <Location>(
          this.dataUtility.locations.find(
            (location) => location.locationId === data.location
          )
        );
        return {
          Location: data.location,
          "DSP Store Name": data.dspStoreName,
          "DSP Store ID": data.dspStoreId,
          "Location Address": orderLocation.addressLine1,
          "DSP Order Id": data.transactionId,
          Date: moment(data.date.toDate()).format("l"),
          "POS Order Id Reciept": data["posOrderId"],
          "Dispute Reason": `Order was fulfilled by resaturant (POS Check Number: ${data["posOrderId"]}) and should be paid out.`,
          "Third Party": data["thirdPartyName"],
          "Order Completed": !!data["posOrderId"],
          Sale: data.sale,
          "Sale Adjustment": data.saleCorrection,
          Tax: data.tax,
          "Tax Adjustment": data.taxCorrection,
          "Delivery Fees": data.deliveryFeeTotal,
          "Pickup Fees": data.pickupFeeTotal,
          "Promo Fees": data.promoFee,
          Status: data.status,
          "Transaction Type": data.transactionType,
          Description: data.description,
          Details: data.metadata,
        };
      })
    );
    const results = this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const fileName = `${
      this.dataUtility.client.name
    }-Canceled-Orders-Disputes_${moment(
      this.dataUtility.existingReport.startDate.toDate()
    ).format("M.DD.YYYY")}-${moment(
      this.dataUtility.existingReport.endDate.toDate()
    ).format("M.DD.YYYY")}`;
    downloadDataAsFile(results, fileName, "csv");
  }
  public async openTransactionViewer(
    canceledTransaction: ThirdPartyTransaction
  ) {
    this.dialog.open(TransactionsDialogComponent, {
      panelClass: "invisible-panel-dialog",
      data: {
        transactions: [canceledTransaction],
      },
    });
  }
  public async openPosTransactionViewer(canceledOrder, posTransactionId) {
    const posTransactionQuery = FirestoreUtilities.mapToType(
      await this.afs
        .collection("posTransactions", (ref) =>
          ref
            .where("client", "==", this.dataUtility.client.id)
            .where("transactionId", "==", posTransactionId)
            .where("location", "==", canceledOrder.location)
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise()
    );
    if (posTransactionQuery.length > 0) {
      this.dialog.open(TransactionsDialogComponent, {
        panelClass: "invisible-panel-dialog",
        data: {
          transactions: [posTransactionQuery[0]],
        },
      });
    }
  }
  public getTotalOfType(
    type:
      | "sale"
      | "saleCorrection"
      | "tax"
      | "taxCorrection"
      | "deliveryFeeTotal"
      | "pickupFeeTotal"
      | "promoFee"
  ) {
    return this.tableData.filteredData.reduce((sum, current) => {
      return (sum += +current[type] ? +current[type] : 0);
    }, 0);
  }
  openDisputeManager(canceledOrder: CanceledTransaction) {
    if (this.dataUtility.user.internalRole > 1) {
      const disputeManagerRef = this.dialog.open(
        CanceledOrderDisputeManagerDialogComponent,
        {
          panelClass: "invisible-panel-dialog",
          data: {
            transaction: canceledOrder,
            client: this.dataUtility.client,
            report: this.dataUtility.existingReport,
          },
        }
      );
      disputeManagerRef.afterClosed().subscribe((disputeLog) => {
        if (disputeLog) {
          canceledOrder.disputeLog = disputeLog;
        }
      });
    }
  }

  /**Filter Predicate */
  setFilterPredicate(
    tableData: MatTableDataSource<any>,
    filter3pdsControl,
    filterLocationsControl,
    filterDisputeOnly
  ) {
    tableData.filterPredicate = (data: any, filtersJson: string) => {
      const filterGroups = _.groupBy(JSON.parse(filtersJson), "id");
      let matchThirdPartyName = true;
      let matchLocationId = true;
      let matchDisputeEligible = true;
      if (filterGroups.thirdParty?.length > 0) {
        matchThirdPartyName = !!filterGroups.thirdParty.find((dspFilter) => {
          return (
            dspFilter.value === data.thirdParty ||
            dspFilter.name === data.thirdPartyName
          );
        });
      }
      if (filterGroups.locationId?.length > 0) {
        matchLocationId = !!filterGroups.locationId.find(
          (locationIdFilter) =>
            // Location will be locationId in rec table but location in all other reports
            locationIdFilter.value === data.locationId ||
            locationIdFilter.value === data.location
        );
      }
      if (filterGroups.disputeOnly[0].value === true) {
        matchDisputeEligible = data["disputeEligible"];
      }

      const matchAll =
        matchThirdPartyName && matchLocationId && matchDisputeEligible;
      return matchAll;
    };
    this.setupFilterListeners(
      tableData,
      filter3pdsControl,
      filterLocationsControl,
      filterDisputeOnly
    );
  }
  private setupFilterListeners(
    tableData: MatTableDataSource<any>,
    filter3pdsControl: FormControl,
    filterLocationsControl: FormControl,
    filterDisputeOnly: FormControl
  ) {
    combineLatest([
      filter3pdsControl.valueChanges.pipe(startWith([])),
      filterLocationsControl.valueChanges.pipe(startWith([])),
      filterDisputeOnly.valueChanges.pipe(startWith(false)),
    ]).subscribe(([selected3pds, selectedLocations, disputeOnly]) => {
      this.multiFilter(tableData, selected3pds, selectedLocations, disputeOnly);
    });
  }
  private multiFilter(
    tableData: MatTableDataSource<any>,
    selected3pds: string[],
    selectedLocations: string[],
    disputeOnly: boolean
  ) {
    let filters;
    let tpdFilters = [];
    let locationFilters = [];
    if (selectedLocations.length > 0) {
      locationFilters = selectedLocations.map((locationId) => {
        return { id: "locationId", value: locationId };
      });
    }

    if (selected3pds.length > 0) {
      tpdFilters = selected3pds.map((dspId) => {
        const dsp = this.dataUtility.thirdParties.find(
          (dsp) => dsp.id === dspId
        );
        return { id: "thirdParty", value: dspId, name: dsp.name };
      });
    }
    const filtersArray = [
      ...tpdFilters,
      ...locationFilters,
      { id: "disputeOnly", value: disputeOnly },
    ];
    if (filtersArray.length > 0) {
      filters = JSON.stringify(filtersArray);
    } else {
      filters = "";
    }
    tableData.filter = filters;
    tableData.paginator.firstPage();
  }
}
