// import { LocationReport } from './third-party-delivery-analytics-engine';
import {
  Client,
  Client3pdTransactionStatusConfiguration,
  Entity,
  LocationThirdParty,
  PosTransaction,
  ThirdPartyTransaction,
  Client3pdConfiguration,
  ThirdParty,
} from "@deliver-sense-librarian/data-schema";
import * as moment from "moment";
import * as _ from "lodash";
import { combineLatest } from "rxjs";
import { AngularFirestore } from "@angular/fire/firestore";
import { map } from "rxjs/operators";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
// @TODO needs to be service with dependency injection streamline operations and component data communication ; DEBT = 5
export class LocationReport {
  reportId: string;
  locationId: string;
  startDate: any;
  endDate: any;
  name: string;
  entity: string | Entity;
  client: string | Client;
  primaryContact: string;
  stateTaxRate: number;
  countyTaxRate: number;
  cityTaxRate: number;
  specialTaxRate: number;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressState: string;
  addressPostalCode: string;
  addressCountry: string;
  thirdParties: any[] | LocationThirdParty[];

  // Report Specific
  thirdParty: string;
  posTransactions: any[];
  thirdPartyTransactions: any[];

  /**
   * Sales Remittance Values
   */
  thirdPartyGrossSales: number;
  thirdPartySalesAdjustments: number; //new
  thirdPartyNetSales: number; //new
  thirdPartyTips: number;
  thirdPartyOtherCharges: number;
  thirdPartyOtherRevenue: number;
  deliveryFees: number;
  otherFees: number;
  expectedRemittance: number;
  actualRemittance: number;
  posGrossSales: number;
  posSalesAdjustments: number;
  posNetSales: number;
  posTips: number;
  posOtherCharges: number;
  posOtherRevenue: number;
  /**
   * Tax Reporting Values
   */
  thirdPartyGrossTax: number;
  thirdPartyTaxAdjustments: number; //new
  thirdPartyNetTax: number; //new
  thirdPartyEffectiveTaxRate: number;
  thirdPartyRemitted: number;
  effectiveMfRate: number; //new
  posGrossTax: number;
  posTaxAdjustments: number;
  posNetTax: number;
  locationTaxRate: number;
  expectedDeliveryFee: number;
  effectiveDeliveryFeeRate: number; //new
  expectedMfTax: number;
  marketFacilitatorTax: number;
  taxResponsibility: number; // 3pdTax - 3pdTaxRemitted
  effectiveClientTaxResponsibilityRate: number;
  taxAdjustment: number; // = posTax - taxResponsibility
}

export class ThirdPartyDeliveryAnalyticsEngine {
  public posTransactions: PosTransaction[] = [];
  public thirdPartyTransactions: ThirdPartyTransaction[] = [];
  public matchingComplete = false;
  private locationReportData: LocationReport[] = [];
  private _client3pdTransactionStatusConfigurations: Client3pdTransactionStatusConfiguration[] =
    [];

  public createDistanceMatrix(allTransactions: {
    posTransactions: any[];
    tpdTransactions: any[];
  }) {
    const distances = [];
    const matches = [];
    const matrix = {};
    let unMatchedCount = 0;
    allTransactions.posTransactions.forEach(
      (posTransaction: PosTransaction) => {
        matrix[posTransaction.id] = [];
        const posTransactionPoint: { subTotal?; tax?; date? } = {};
        posTransactionPoint.subTotal = posTransaction.sale
          ? +posTransaction.sale.toFixed(2)
          : 0;
        posTransactionPoint.tax = posTransaction.tax
          ? Math.round(posTransaction.tax) // round to 1 decimal to compensate for minor rate variances
          : 0;
        posTransactionPoint.date = posTransaction.date
          ? moment(
              moment(posTransaction.date.toDate()).format("M/D/YYYY")
            ).unix()
          : 0;
        allTransactions.tpdTransactions.forEach(
          (thirdPartyTransaction: ThirdPartyTransaction) => {
            const thirdPartyTransactionPoint: { subTotal?; tax?; date? } = {};
            thirdPartyTransactionPoint.subTotal = thirdPartyTransaction.sale
              ? +thirdPartyTransaction.sale.toFixed(2)
              : 0;
            thirdPartyTransactionPoint.tax = thirdPartyTransaction.tax
              ? Math.round(thirdPartyTransaction.tax)
              : 0;
            thirdPartyTransactionPoint.date = thirdPartyTransaction.date
              ? moment(
                  moment(thirdPartyTransaction.date.toDate()).format("M/D/YYYY")
                ).unix()
              : 0;
            const distance = this.getEuclideanDistance(
              posTransactionPoint,
              thirdPartyTransactionPoint
            );
            const relativeDistanceObject = {
              thirdPartyTransaction: thirdPartyTransaction.id,
              distance,
            };
            matrix[posTransaction.id].push(relativeDistanceObject);
          }
        );
        matrix[posTransaction.id].sort((a, b) => {
          return a.distance < b.distance ? -1 : a.distance > b.distance ? 1 : 0;
        });
        if (matrix[posTransaction.id][0]) {
          const lowestDistanceMatch = matrix[posTransaction.id][0].distance;
          distances.push(lowestDistanceMatch);
        }
      }
    );
    distances.sort((a, b) => {
      return a < b ? -1 : a > b ? 1 : 0;
    });
    allTransactions.posTransactions.forEach((posTransaction) => {
      if (matrix[posTransaction.id][0]) {
        const lowestDistanceMatch = matrix[posTransaction.id][0].distance;
        const lowestDistanceThirdPartyTransactionId =
          matrix[posTransaction.id][0].thirdPartyTransaction;
        const secondaryMatchId =
          matrix[posTransaction.id][0].distance ===
          matrix[posTransaction.id][1].distance
            ? matrix[posTransaction.id][1].thirdPartyTransaction
            : null;
        const normalizedDistance = this.normalizeDistance(
          lowestDistanceMatch,
          distances
        );
        if (normalizedDistance >= 1) {
          matches.push({
            posTransaction: posTransaction.id,
            thirdPartyTransaction: lowestDistanceThirdPartyTransactionId,
            secondaryMatchIds: secondaryMatchId,
            match: lowestDistanceMatch,
          });
        } else {
          matches.push({
            posTransaction: posTransaction.id,
            match: 1, // 1-1 = 0 --> no match
          });
        }
      }
    });
    allTransactions.tpdTransactions.forEach((thirdPartyTransaction) => {
      let matched = matches.find(
        (match) => match.thirdPartyTransaction === thirdPartyTransaction.id
      );
      if (!matched) {
        const secondaryMatch = matches.find(
          (match) => match.secondaryMatchId === thirdPartyTransaction.id
        );
        if (secondaryMatch) {
          secondaryMatch.thirdPartyTransaction =
            secondaryMatch.secondaryMatchId;
        } else {
          unMatchedCount++;
          matches.push({
            thirdPartyTransaction: thirdPartyTransaction.id,
            match: 1, // 1-1 = 0 --> no match
          });
        }
      }
    });
    matches
      .filter((match) => match.posTransaction && match.thirdPartyTransaction)
      .forEach((match) => {
        if (match.posTransaction && match.thirdPartyTransaction) {
          // if the entry has a match
          const duplicate = matches.find((otherMatch) => {
            return (
              otherMatch.posTransaction !== match.posTransaction &&
              match.thirdPartyTransaction === otherMatch.thirdPartyTransaction
            );
          });
          if (duplicate) {
            const loser =
              this.normalizeDistance(match.match, distances) >
              this.normalizeDistance(duplicate.match, distances)
                ? duplicate
                : match;
            loser.thirdPartyTransaction = null;
            loser.match = 1;
          }
        }
      });
    return { matches, distances };
  }

  public normalizeDistance(x, distances) {
    const minDistance = distances.filter((distance) => distance !== 0)[0];
    const maxDistance = distances[distances.length - 1];
    if (x === 1) {
      return 0;
    }
    if (x !== 0) {
      return +(
        (1 - (x - minDistance) / (maxDistance - minDistance)) *
        100
      ).toFixed(2);
    } else {
      return 100;
    }
  }

  private getEuclideanDistance(p1, p2) {
    const xdiff = Math.pow(p1[Object.keys(p1)[0]] - p2[Object.keys(p1)[0]], 2);
    const ydiff = Math.pow(p1[Object.keys(p1)[1]] - p2[Object.keys(p2)[1]], 2);
    const zdiff = Math.pow(p1[Object.keys(p1)[2]] - p2[Object.keys(p2)[2]], 2);
    return Math.sqrt(xdiff + ydiff + zdiff);
  }

  public perc2color(perc) {
    let r,
      g = 0;
    const b = 0;
    if (perc < 50) {
      r = 255;
      g = Math.round(5.1 * perc);
    } else {
      g = 255;
      r = Math.round(510 - 5.1 * perc);
    }
    const h = r * 0x10000 + g * 0x100 + b * 0x1;
    const hexColor = "#" + ("000000" + h.toString(16)).slice(-6);
    const rgb = this.hexToRgb(hexColor);
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.6)`;
  }

  private hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  public fetchParameterizedReportData(
    locationId: string,
    thirdParty: ThirdParty,
    startDate: Date,
    endDate: Date,
    client: Client,
    afs: AngularFirestore,
    applyStatusFilters = true,
    filterErrorCharges = true
  ) {
    const allTransactions = {
      posTransactions: [],
      tpdTransactions: [],
    };
    return combineLatest([
      afs
        .collection("thirdPartyTransactions", (ref) =>
          ref
            .where("date", ">=", startDate)
            .where("date", "<=", endDate)
            .where("thirdParty", "==", thirdParty.id)
            .where("location", "==", locationId)
            .where("client", "==", client.id)
            .orderBy("date")
        )
        .snapshotChanges(),
      afs
        .collection("posTransactions", (ref) =>
          ref
            .where("date", ">=", startDate)
            .where("date", "<=", endDate)
            .where("account", "==", thirdParty.id)
            .where("location", "==", locationId)
            .where("client", "==", client.id)
            .orderBy("date")
        )
        .snapshotChanges(),
      afs
        .collection("client3pdTransactionStatusConfigurations", (ref) =>
          ref
            .where("client", "==", client.id)
            .where("thirdParty", "==", thirdParty.id)
        )
        .snapshotChanges(),
      afs
        .collection("thirdPartyTransactionStatuses", (ref) =>
          ref.where("thirdParty", "==", thirdParty.id)
        )
        .snapshotChanges(),
    ]).pipe(
      map(
        ([
          thirdPartyTransactions$,
          posTransactions$,
          client3pdTransactionStatusConfigurations$,
          thirdPartyStatuses$,
        ]) => {
          const client3pdTransactionStatusConfigurations =
            FirestoreUtilities.mapToType(
              client3pdTransactionStatusConfigurations$
            );
          const thirdPartyTransactionStatuses =
            FirestoreUtilities.mapToType(thirdPartyStatuses$);
          allTransactions.posTransactions = posTransactions$.map(
            (transaction) => FirestoreUtilities.objectToType(transaction)
          );
          allTransactions.tpdTransactions = thirdPartyTransactions$
            .map((transaction) => FirestoreUtilities.objectToType(transaction))
            .filter(
              (transaction) =>
                !this.isTransactionErrorCharge(transaction, thirdParty) &&
                !this.isTransactionAdjustment(transaction, thirdParty)
            );
          if (applyStatusFilters) {
            allTransactions.tpdTransactions =
              this.filterTpdTransactionsByClientConfigs(
                thirdPartyTransactionStatuses,
                client3pdTransactionStatusConfigurations,
                allTransactions.tpdTransactions
              );
          }
          return allTransactions;
        }
      )
    );
  }
  private filterTpdTransactionsByClientConfigs(
    thirdPartyTransactionStatuses,
    client3pdTransactionStatusConfigurations,
    tpdTransactions
  ) {
    return tpdTransactions.filter((tpdTransaction: ThirdPartyTransaction) => {
      // get the global third party transaction status that is equal to the text value in status of the transaction
      const thirdPartyTransactionStatus = thirdPartyTransactionStatuses.find(
        (tpts) =>
          tpts.status === tpdTransaction.status &&
          tpts.thirdParty === tpdTransaction.thirdParty
      );
      if (thirdPartyTransactionStatus) {
        // if a global transaction status is found check if the client has a setting for the transaction status
        const matching3pdTransactionsConfiguration =
          client3pdTransactionStatusConfigurations.find(
            (config) =>
              config.thirdParty === tpdTransaction.thirdParty &&
              config.status === thirdPartyTransactionStatus.id
          );
        if (matching3pdTransactionsConfiguration) {
          // if a matching client transaction status configuration is found return the boolean value of "inPos" to filter
          return matching3pdTransactionsConfiguration.inPos === false
            ? false
            : true;
        }
      }
      // if both status levels are not found return the transaction to be included in the reconciliation
      return true;
    });
  }
  public enumerateDaysBetweenDates(startDate, endDate) {
    var dates = [];
    var currDate = moment(startDate).startOf("day");
    var lastDate = moment(endDate).startOf("day");
    while (currDate.add(1, "days").diff(lastDate) < 0) {
      dates.push(currDate.clone().toDate());
    }
    return dates;
  }
  isTransactionErrorCharge(
    transaction: ThirdPartyTransaction,
    thirdParty: ThirdParty
  ) {
    let isErrorChargeField = "";
    if (thirdParty.errorChargeCodes && thirdParty.errorChargeCodes.length > 0) {
      for (const errorChargeCode of thirdParty.errorChargeCodes) {
        if (transaction[errorChargeCode.field] === errorChargeCode.code) {
          isErrorChargeField = errorChargeCode.field;
          break;
        }
      }
      return isErrorChargeField;
    }
    return isErrorChargeField;
  }
  isTransactionAdjustment(
    transaction: ThirdPartyTransaction,
    thirdParty: ThirdParty
  ) {
    let isAdjustmentField = "";
    if (thirdParty.adjustmentCodes && thirdParty.adjustmentCodes.length > 0) {
      for (const errorChargeCode of thirdParty.adjustmentCodes) {
        if (transaction[errorChargeCode.field] === errorChargeCode.code) {
          isAdjustmentField = errorChargeCode.field;
          break;
        }
      }
      return isAdjustmentField;
    }
    return isAdjustmentField;
  }
}
