import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "accountingFormat" })
export class AccountingFormat implements PipeTransform {
  transform(value): any {
    let number = this.toNumber(value);
    if (number) {
      let numberWithCommas;
      const isNegative = value < 0;
      if (isNegative) {
        number = -1 * number;
        numberWithCommas = this.numberWithCommas(number);
        return `(${numberWithCommas})`;
      }
      numberWithCommas = this.numberWithCommas(number);
      return `${numberWithCommas}`;
    }
    return 0;
  }
  private toNumber(value: any) {
    let formattedNumber;
    if (value && !+value) {
      formattedNumber = !!value
        ? parseFloat(value.replace(/[^0-9\.\-]+/g, ""))
        : 0;
      return +formattedNumber.toFixed(2);
    }
    formattedNumber = value && +value ? +(+value).toFixed(2) : 0;
    return formattedNumber;
  }
  private numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
