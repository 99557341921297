import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ReconciliationReportDatUtility } from "../../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../../utilities/reconciliation-report-filtering.utility";
import { FormControl } from "@angular/forms";
import { AfterViewInit } from "@angular/core";
import { ThirdPartyAnalyticsSummary } from "@deliver-sense-librarian/data-schema";

@Component({
  selector: "app-analytics-summary-breakout-table",
  templateUrl: "./analytics-summary-breakout-table.component.html",
  styleUrls: ["./analytics-summary-breakout-table.component.scss"],
})
export class AnalyticsSummaryBreakoutTableComponent
  implements OnInit, AfterViewInit
{
  @Input() data: ThirdPartyAnalyticsSummary[] = [];
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @Input() filterLocationsControl: FormControl;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [];

  public filter3pdsControl = new FormControl([]);
  public;
  constructor(private dialog: MatDialog) {}
  ngOnInit(): void {
    this.displayedColumns = [
      "location",
      "locationCityState",
      "thirdParty",
      "dspGrossSales",
      "errorCharges",
      "adjustments",
      "netSales",
      "campaignPromoFees",
      "taxableSales",
      "canceledOrders",
      "dspTax",
      "dspEffectiveTaxRate",
      "mfTaxApplicable",
      "partialMfTaxApplicable",
      "taxRemittedToRestaurant",
      "backupWithholdingTax",
      "deliveryFees",
      "pickupFees",
      "promoFees",
      "cateringFees",
      "totalFees",
      "miscFees",
      "creditCardProcessingFees",
    ];
    this.tableData = new MatTableDataSource(this.data);
  }
  ngAfterViewInit(): void {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.filteringUtility.set3pdAndLocationFilterPredicate(
      this.tableData,
      this.filter3pdsControl,
      this.filterLocationsControl
    );
  }
  getTotalForColumn(column: string) {
    return this.tableData.filteredData.reduce((sum, row) => {
      return (sum += +row[column] ? +row[column] : 0);
    }, 0);
  }
  getRateDisplay(rate: number) {
    return +(rate * 100).toFixed(3);
  }
  exportReport() {}
}
