import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ExemptionCertificateManagementComponent } from "./exemption-certificate-management.component";
import { MaterialModule } from "../../../material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FileUploadModule } from "../../file-upload/file-upload.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ExemptTransactionsComponent } from "./exempt-transactions/exempt-transactions.component";
import { CertificateManagerComponent } from "./certificate-manager/certificate-manager.component";
import { ClientExemptionCertificateDocumentComponent } from "./client-exemption-certificate-document/client-exemption-certificate-document.component";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TypeSearchModule } from "../../type-search/type-search.module";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

@NgModule({
  declarations: [
    ExemptionCertificateManagementComponent,
    ExemptTransactionsComponent,
    CertificateManagerComponent,
    ClientExemptionCertificateDocumentComponent,
  ],
  exports: [
    ExemptTransactionsComponent,
    ClientExemptionCertificateDocumentComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    FileUploadModule,
    TypeSearchModule,
    PopoverModule.forRoot(),
    PerfectScrollbarModule,
  ],
})
export class ExemptionCertificateManagementModule {}
