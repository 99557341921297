import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  ThirdParty,
  ThirdPartyPayout,
  PayoutReportFragmentLog,
} from "@deliver-sense-librarian/data-schema";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { downloadDataAsFile } from "app/shared/ds-constant";
import _ from "lodash";
import * as moment from "moment";
import { Papa } from "ngx-papaparse";
import { combineLatest } from "rxjs";
import { first, startWith } from "rxjs/operators";
import { PayoutsDialogComponent } from "app/dialogs/payouts-dialog/payouts-dialog.component";
import { ReconciliationReportDatUtility } from "../../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../../utilities/reconciliation-report-filtering.utility";
import { TransactionsDialogComponent } from "app/dialogs/transactions-dialog/transactions-dialog.component";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
@Component({
  selector: "app-deposit-report-table",
  templateUrl: "./deposit-report-table.component.html",
  styleUrls: ["./deposit-report-table.component.scss"],
})
export class DepositReportTableComponent implements OnInit, AfterViewInit {
  @Input() thirdParties: ThirdParty[] = [];
  @Input() locations: Location[] = [];
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @Input() reportTitle: string;
  @Input() data: PayoutReportFragmentLog[] = [];

  @Output() clearResults = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    "location",
    "thirdParty",
    "date",
    "payout",
    "payoutId",
    "startDate",
    "endDate",
    "sales",
    "saleCorrection",
    "tip",
    "adjustments",
    "deliveryFees",
    "tax",
    "taxRemitted",
    "misc",
    "promoFees",
    "taxOnFees",
    "backupWithholdingTax",
    "unreportedDifference",
  ];

  public filter3pdsControl = new FormControl([]);
  public filterLocationsControl = new FormControl([]);
  constructor(
    private cdr: ChangeDetectorRef,
    private afs: AngularFirestore,
    private papa: Papa,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private loadingService: LoadingDialogService
  ) {}
  ngOnInit(): void {
    this.data.forEach((deposit) => {
      deposit["thirdPartyName"] = this.dataUtility.getThirdPartyName(
        deposit.thirdParty
      );
      deposit["dateRange"] = this.getDepositDateRange(deposit.thirdPartyPayout);
    });
    if (this.dataUtility.isPayoutReconciliationActive()) {
      this.displayedColumns.push(
        ...[
          "reconciliationTransactionsRemittance",
          "reconciliationPpaTransactionsRemittance",
          "reconciliationPayoutVariance",
          "reconciliationCanceledUnpaid",
        ]
      );
    }
    this.tableData = new MatTableDataSource(this.data);
  }
  ngAfterViewInit(): void {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.filteringUtility.set3pdAndLocationFilterPredicate(
      this.tableData,
      this.filter3pdsControl,
      this.filterLocationsControl
    );
  }

  getDepositDateRange(thirdPartyPayout: ThirdPartyPayout) {
    const transactionStartDate = thirdPartyPayout.transactionsStartDate
      ? moment(thirdPartyPayout.transactionsStartDate.toDate()).format("l")
      : "";
    const transactionEndDate = thirdPartyPayout.transactionsEndDate
      ? moment(thirdPartyPayout.transactionsEndDate.toDate()).format("l")
      : "";

    return transactionEndDate && transactionStartDate
      ? `${transactionStartDate} - ${transactionEndDate}`
      : "";
  }
  exportReport() {
    const data = Object.assign(
      this.tableData.data.map((row: PayoutReportFragmentLog) => {
        let record = {
          Location: row.location,
          "Third Party": row["thirdPartyName"],
          "Payout Date": moment(
            row.thirdPartyPayout.payoutDate.toDate()
          ).format("l"),
          "Payout ID": row.thirdPartyPayout.payoutId,
          "Total Payout": row.thirdPartyPayout.payout,
          "Date Range": row["dateRange"],
          Sales: row.thirdPartyPayout.sales,
          "Sale Correction": row.thirdPartyPayout.saleCorrection,
          Tip: row.thirdPartyPayout.tip,
          Adjustments: row.thirdPartyPayout.adjustments,
          "Delivery Fees": row.thirdPartyPayout.deliveryFees,
          Tax: row.thirdPartyPayout.tax,
          "Tax Remitted": row.thirdPartyPayout.taxRemitted,
          Misc: row.thirdPartyPayout.misc,
          "Promo Fees": row.thirdPartyPayout.promoFees,
          "Tax On Fees": row.thirdPartyPayout.taxOnFees,
          "Backup Withholding Tax": row.thirdPartyPayout.backupWithholdingTax,
          "Unreported Difference": row.thirdPartyPayout.unreportedDifference,
        };
        if (this.dataUtility.isPayoutReconciliationActive()) {
          record = {
            ...record,
            ...{
              "Payout Per Reconciliation":
                row.reconciliationTransactionsRemittance,
              "Prior Period Adjustments Payout":
                row.reconciliationPpaTransactionsRemittance,
              Variance: row.reconciliationPayoutVariance,
              "Canceled Unpaid Per Rec": row.canceledUnpaidAmount,
            },
          };
        }
        return record;
      })
    );
    const results = this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const fileName = `${this.dataUtility.existingReport.name}_Deposits_Report_${this.dataUtility.client.name}`;
    downloadDataAsFile(results, fileName, "csv");
  }

  public openPayoutDialog(thirdPartyPayout: ThirdPartyPayout) {
    this.dialog.open(PayoutsDialogComponent, {
      panelClass: "invisible-panel-dialog",
      data: {
        thirdPartyPayouts: [thirdPartyPayout],
      },
    });
  }

  public async openTransactionsDialog(transactionIds: string[]) {
    this.loadingService.isLoading(true, "Fetching transactions...");
    try {
      const transactions = FirestoreUtilities.mergeToType(
        await Promise.all(
          transactionIds.map(async (transactionId) => {
            return this.afs
              .doc(`thirdPartyTransactions/${transactionId}`)
              .snapshotChanges()
              .pipe(first())
              .toPromise();
          })
        )
      );
      this.loadingService.isLoading(false);
      this.dialog.open(TransactionsDialogComponent, {
        panelClass: "invisible-panel-dialog",
        data: {
          transactions,
        },
      });
    } catch (e) {
      this.loadingService.isLoading(false);
      console.error(e.message);
      this.snackBar.open(
        "Oops... something went wrong. Please refresh the page and try again.",
        "Dismiss",
        { duration: 5000 }
      );
    }
  }

  /**
   * Footer Row Calcs
   */
  public getPayoutTotalAmount(field: string) {
    return +this.tableData.filteredData
      .reduce((sum: number, current: PayoutReportFragmentLog) => {
        return (sum += +current.thirdPartyPayout[field]
          ? +current.thirdPartyPayout[field]
          : 0);
      }, 0)
      .toFixed(2);
  }
  public getTotalAmount(field: string) {
    return +this.tableData.filteredData
      .reduce((sum: number, current: PayoutReportFragmentLog) => {
        return (sum += +current[field] ? +current[field] : 0);
      }, 0)
      .toFixed(2);
  }
}
