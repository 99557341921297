import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SidebarComponent } from "./sidebar.component";
import { MaterialModule } from "../../../material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AccordionModule } from "../menu-accordion/accordion.module";
import { RouterModule } from "@angular/router";
import { HighchartsChartModule } from "highcharts-angular";
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from "ngx-perfect-scrollbar";
import { AvatarModule } from "../../avatar/avatar.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TypeSearchModule } from "app/components/type-search/type-search.module";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};
@NgModule({
  declarations: [SidebarComponent],
  exports: [SidebarComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    CommonModule,
    AccordionModule,
    RouterModule,
    PerfectScrollbarModule,
    HighchartsChartModule,
    FormsModule,
    ReactiveFormsModule,
    AvatarModule,
    TypeSearchModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SidebarModule {}
