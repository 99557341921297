import {
  AngularFirestore,
  CollectionReference,
  DocumentChangeAction,
  DocumentData,
} from "@angular/fire/firestore";
import {
  User,
  Client,
  OrganizationRole,
  Location,
} from "@deliver-sense-librarian/data-schema";
import { combineLatest, from, Observable, of, Subject } from "rxjs";
import { combineAll, map, switchMap, takeUntil } from "rxjs/operators";
import { AngularFireStorage } from "@angular/fire/storage";
import * as moment from "moment";

declare const inflection: any;

export class FirestoreUtilities {
  public static mapToType(actions) {
    if (actions.length > 0) {
      return actions.map((a) => {
        if (a) {
          if (a.payload.doc) {
            const data = a.payload.doc
              ? a.payload.doc.data()
              : a.payload.data();
            const id = a.payload.doc ? a.payload.doc.id : a.payload.id;
            return { id, ...data };
          } else if (a.payload) {
            const data = actions.payload.data();
            const id = actions.payload.id;
            return { id, ...data };
          }
        }
      });
    } else {
      return [];
    }
  }

  public static objectToType(actions) {
    if (actions && actions.payload.exists) {
      const data = actions.payload.data();
      const id = actions.payload.id;
      return { id, ...data };
    } else if (actions && actions.payload.doc && actions.payload.doc.exists) {
      const data = actions.payload.doc.data();
      const id = actions.payload.doc.id;
      return { id, ...data };
    } else {
      return null;
    }
  }

  public static mergeToType(actions) {
    const resultArray = [];
    if (actions instanceof Array) {
      actions.forEach((action) => {
        resultArray.push(FirestoreUtilities.objectToType(action));
      });
    } else {
      resultArray.push(FirestoreUtilities.objectToType(actions));
    }
    return resultArray;
  }

  public static mergeCollectionToType(actions) {
    const resultArray = [];
    if (actions instanceof Array) {
      actions.forEach((action) => {
        if (action instanceof Array) {
          action.forEach((item) => {
            if (item) {
              const data = item.payload.doc
                ? item.payload.doc.data()
                : item.payload.data();
              const id = item.payload.doc
                ? item.payload.doc.id
                : item.payload.id;
              resultArray.push({ id, ...data });
            }
          });
        } else if (action && action.payload) {
        }
      });
    }
    return resultArray;
  }

  public static getUserAccessibleResourcesOfType(
    type: string,
    afs: AngularFirestore,
    organizationRoles: OrganizationRole[],
    accessLevels: any[],
    clientId?: string
  ): Observable<any> {
    if (organizationRoles && organizationRoles.length > 0) {
      const resourceRequests = [];
      organizationRoles.forEach((organizationRole) => {
        if (accessLevels.indexOf(organizationRole.role) > -1) {
          resourceRequests.push(
            afs.doc(`${type}/${organizationRole.resource}`).snapshotChanges()
          );
        }
      });
      if (resourceRequests.length > 0) {
        return from(resourceRequests).pipe(
          combineAll(),
          map((documents$) => {
            let docs = FirestoreUtilities.mergeToType(documents$);
            if (clientId) {
              docs = docs.filter((doc) => doc.client === clientId);
            }
            return docs;
          })
        );
      } else {
        return of([]);
      }
    } else {
      return of([]);
    }
  }
  public static getSubcollectionParent(
    parentType: string,
    childDoc: DocumentChangeAction<any>
  ) {
    // Loop up through the parent doc references until you get to the user doc
    let parentRef: any = childDoc.payload.doc.ref.parent;
    while (
      parentRef.path &&
      parentRef.parent &&
      parentRef.parent.id !== parentType
    ) {
      parentRef = parentRef.parent;
    }
    return parentRef ? parentRef.id : null;
  }
  public static fetchChildrenFromParentCollection(
    parentCollection: any[],
    childCollectionName: string,
    childKeyInParent: string,
    afs: AngularFirestore,
    destroySubject: Subject<any>
  ) {
    if (parentCollection.length > 0) {
      const childDocumentRequests = parentCollection
        .map((parent) => {
          return afs
            .doc(`${childCollectionName}/${parent[childKeyInParent]}`)
            .snapshotChanges();
        })
        .filter((req) => !!req);
      return from(childDocumentRequests).pipe(
        combineAll(),
        takeUntil(destroySubject),
        map((children$) => {
          return FirestoreUtilities.mergeToType(children$).filter(
            (doc) => !!doc
          );
        })
      );
    } else {
      return of([]);
    }
  }

  public static queryChildrenFromParentCollection(
    parentCollection: any[],
    childCollectionName: any,
    destroySubject: Subject<any>,
    afs: AngularFirestore,
    queryParams: WhereQueryParams[]
  ) {
    if (parentCollection.length > 0) {
      const childDocumentRequests = parentCollection
        .map((parent) => {
          // @ts-ignore
          return afs
            .collection(childCollectionName, (ref) => {
              queryParams.forEach((param) => {
                switch (param.type) {
                  case "where":
                    ref.where(
                      param.key,
                      param.operator,
                      parent[param.parentValueKey]
                    );
                    break;
                  case "orderBy":
                    ref.orderBy(param.key);
                    break;
                }
              });
              return ref as CollectionReference<DocumentData>;
            })
            .snapshotChanges();
        })
        .filter((req) => !!req);
      return from(childDocumentRequests).pipe(
        combineAll(),
        takeUntil(destroySubject),
        map((children$) => {
          return FirestoreUtilities.mergeCollectionToType(children$);
        })
      );
    } else {
      return of([]);
    }
  }

  public static async deleteStorageFile(
    path: string,
    storage: AngularFireStorage
  ) {
    return storage.storage.ref(path).delete();
  }

  public static async getDownloadUrlFromPath(
    storage: AngularFireStorage,
    path: string
  ) {
    return await storage.storage.ref(path).getDownloadURL();
  }
  public static mapLocations3PDRateInfo(
    locations: Location[],
    afs: AngularFirestore,
    destroy$: Subject<any>
  ) {
    const locationsRateInformationSubscriptions = locations.map((location) => {
      return this.getLocation3pdRateInformation(location, afs, destroy$);
    });
    return from(locationsRateInformationSubscriptions).pipe(
      combineAll(),
      map((result) => result)
    );
  }
  private static getLocation3pdRateInformation(
    location: Location,
    afs: AngularFirestore,
    destroy$: Subject<any>
  ) {
    return afs
      .collection(`locations/${location.id}/thirdParties`)
      .snapshotChanges()
      .pipe(
        switchMap((locationThirdParties$) => {
          const locationThirdParties = FirestoreUtilities.mapToType(
            locationThirdParties$
          );
          const locationThirdPartyMfRates = locationThirdParties.map((ltp) => {
            return afs
              .collection(
                `locations/${location.id}/thirdParties/${ltp.id}/marketFacilitatorTaxRates`
              )
              .snapshotChanges();
          });
          const locationThirdPartyItemRates = locationThirdParties.map(
            (ltp) => {
              return afs
                .collection(
                  `locations/${location.id}/thirdParties/${ltp.id}/itemTaxRates`
                )
                .snapshotChanges();
            }
          );
          return combineLatest([
            from(locationThirdPartyMfRates).pipe(combineAll()),
            from(locationThirdPartyItemRates).pipe(combineAll()),
          ]).pipe(
            map(([ltpMfRates$, ltpItemRates$]) => {
              const ltpMfRateDocs = [].concat.apply([], ltpMfRates$);
              const ltpItemRateDocs = [].concat.apply([], ltpItemRates$);
              locationThirdParties.forEach((locationThirdParty) => {
                locationThirdParty["marketFacilitatorTaxRates"] = ltpMfRateDocs
                  .map((mfRate$) => {
                    const thirdPartyId =
                      FirestoreUtilities.getSubcollectionParent(
                        "thirdParties",
                        mfRate$
                      );
                    return thirdPartyId === locationThirdParty.id
                      ? FirestoreUtilities.objectToType(mfRate$)
                      : undefined;
                  })
                  .filter((doc) => !!doc)
                  .sort((a, b) => {
                    return moment(a.effectiveDate.toDate()).isSameOrBefore(
                      b.effectiveDate.toDate()
                    )
                      ? 1
                      : -1;
                  });
                locationThirdParty["itemTaxRates"] = ltpItemRateDocs
                  .map((itemRate$) => {
                    const thirdPartyId =
                      FirestoreUtilities.getSubcollectionParent(
                        "thirdParties",
                        itemRate$
                      );
                    return thirdPartyId === locationThirdParty.id
                      ? FirestoreUtilities.objectToType(itemRate$)
                      : undefined;
                  })
                  .filter((doc) => !!doc)
                  .sort((a, b) => {
                    return moment(a.effectiveDate.toDate()).isSameOrBefore(
                      b.effectiveDate.toDate()
                    )
                      ? 1
                      : -1;
                  });
              });
              location["thirdParties"] = locationThirdParties;
              return location;
            })
          );
        })
      );
  }
}

export class WhereQueryParams {
  type: string;
  key: string;
  operator: any;
  parentValueKey?: string;
}
