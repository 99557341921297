import { Component, Input, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import {
  Client3pdConfiguration,
  reconciliationTableFields,
  ThirdParty,
} from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { TpdReportFieldSelectionDialogComponent } from "app/dialogs/tpd-report-field-selection-dialog/tpd-report-field-selection-dialog.component";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import * as _ from "lodash";
import * as moment from "moment";

@Component({
  selector: "app-third-party-report-default-fields",
  templateUrl: "./third-party-report-default-fields.component.html",
  styleUrls: ["./third-party-report-default-fields.component.scss"],
})
export class ThirdPartyReportDefaultFieldsComponent implements OnInit {
  @Input() client3pdConfiguration: Client3pdConfiguration;
  @Input() clientThirdParties: ThirdParty[];
  public configForm: FormGroup;
  public possibleFields = reconciliationTableFields;
  public fixedFields = ["Location Id", "3PD"];
  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // this.setupConfigurationForm();
  }
  // public async openFieldsSelector() {
  //   const defaultFieldsControl = this.configForm.get('defaultFields');
  //   const fieldSelectDialogRef = this.dialog.open(TpdReportFieldSelectionDialogComponent, {
  //     panelClass: 'invisible-panel-dialog',
  //     data: {
  //       selectedFields: defaultFieldsControl.value
  //     }
  //   });
  //   fieldSelectDialogRef.afterClosed().subscribe(updatedFieldSelection$ => {
  //     if (updatedFieldSelection$) {
  //       const updateFieldSelection = [
  //         ...this.fixedFields,
  //         ...updatedFieldSelection$
  //           .map(field => field.item)
  //           .filter(fieldName => this.possibleFields.indexOf(fieldName) > -1)
  //       ];
  //       defaultFieldsControl.patchValue(_.sortBy(updateFieldSelection, (item) => {
  //         return this.possibleFields.indexOf(item);
  //       }));
  //       defaultFieldsControl.updateValueAndValidity();
  //     }
  //   });
  // }
  // setupConfigurationForm() {
  //   this.configForm = this.fb.group({
  //     defaultFields: new FormControl(this.client3pdConfiguration.defaultFields ? this.client3pdConfiguration.defaultFields : ['Location Id', '3PD'], Validators.required),
  //   });
  // }
  public async save(updatedFieldSelection) {
    // const client3pdStatusConfigForms = this.clientThirdParties.map(clientThirdParty => clientThirdParty['clientStatusConfigForm'])
    if (updatedFieldSelection) {
      const updateFieldSelection = [
        ...this.fixedFields,
        ...updatedFieldSelection
          .map((field) => field.item)
          .filter((fieldName) => this.possibleFields.indexOf(fieldName) > -1),
      ];
      this.loadingService.isLoading(true, "Saving 3PD settings", {
        duration: 5000,
      });
      try {
        await this.afs
          .doc(`client3pdConfigurations/${this.client3pdConfiguration.id}`)
          .update({
            defaultFields: updateFieldSelection,
            dateUpdated: moment().toDate(),
          });
        this.snackBar.open(
          "Successfully updated your 3PD Reconciliation Configurations.",
          "Dismiss",
          {
            duration: 5000,
          }
        );
        this.loadingService.isLoading(false);
      } catch (e) {
        const errSnackbar = this.snackBar.open(
          "Oops... something went wrong. Please refresh and try again",
          "Refresh Now",
          {
            duration: 5000,
          }
        );
        errSnackbar.onAction().subscribe(() => {
          window.location.reload();
        });
        this.loadingService.isLoading(false);
      }
    }
  }
}
