import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  ThirdPartyTransaction,
  ThirdPartyReportFragmentErrorChargeLog,
  disputeReasons,
  ThirdPartyDeliveryIds,
} from "@deliver-sense-librarian/data-schema";
import {
  downloadDataAsFile,
  getTimeFromMinutesFrom,
} from "app/shared/ds-constant";
import { ReconciliationReportDatUtility } from "../../../utilities/reconciliation-report-data.utility";
import { TransactionHistoryDialogComponent } from "../../../../../../dialogs/transaction-history-dialog/transaction-history-dialog.component";
import { ReconciliationReportFilteringUtility } from "../../../utilities/reconciliation-report-filtering.utility";
import { Form, FormControl } from "@angular/forms";
import { AfterViewInit } from "@angular/core";
import { DisputeManagerDialogComponent } from "../../../../../../dialogs/dispute-manager-dialog/dispute-manager-dialog.component";
import moment from "moment";
import { Papa } from "ngx-papaparse";
import { DrillDownSelection } from "../../daily-drill-down/daily-drill-down.component";
import _ from "lodash";
import { combineLatest } from "rxjs";
import { startWith } from "rxjs/operators";
import * as JSZip from "jszip";

export class ErrorChargeTransaction extends ThirdPartyTransaction {
  errorChargeLog: ThirdPartyReportFragmentErrorChargeLog;
  amount: number;
}
@Component({
  selector: "app-error-summary-table",
  templateUrl: "./error-summary-table.component.html",
  styleUrls: ["./error-summary-table.component.scss"],
})
export class ErrorSummaryTableComponent implements OnInit, AfterViewInit {
  @Input() data: ErrorChargeTransaction[] = [];
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @Input() preErrorChargeSelection: DrillDownSelection;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    "location",
    "thirdParty",
    "date",
    "time",
    "status",
    "transactionType",
    "refundAmount",
    "orderAmount",
    // "refundRate",
    "description",
    "meta",
    "issueCount",
    "transactionId",
    "posTransactionId",
    "workflowId",
  ];
  public filter3pdsControl = new FormControl([]);
  public filterLocationsControl = new FormControl([]);
  public filterDisputeOnly = new FormControl(false);
  public disputeReasons = disputeReasons;
  constructor(
    private dialog: MatDialog,
    private afs: AngularFirestore,
    private snackBar: MatSnackBar,
    private papa: Papa
  ) {}
  ngOnInit(): void {
    this.displayedColumns.push("eligibleForDispute");
    this.displayedColumns.push("itemsToDispute");
    if (this.dataUtility.isEcdsAddonActive()) {
      this.displayedColumns.push("disputeReason");
      this.displayedColumns.push("disputeCount");
    }
    this.data.forEach((row) => {
      if (row.transactionId.charAt(0) === "#") {
        row.transactionId = row.transactionId.substring(
          1,
          row.transactionId.length
        );
        // row["refundRate"] =
        //   +row.errorChargeLog?.parentThirdPartyTransaction?.sale !== 0
        //     ? row["amount"] /
        //       row.errorChargeLog?.parentThirdPartyTransaction?.sale
        //     : 0;
      }
    });
    this.tableData = new MatTableDataSource(this.data);
  }
  ngAfterViewInit(): void {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.setFilterPredicate(
      this.tableData,
      this.filter3pdsControl,
      this.filterLocationsControl,
      this.filterDisputeOnly
    );
    this.checkForPreSelection();
  }
  checkForPreSelection() {
    if (this.preErrorChargeSelection) {
      this.filterLocationsControl.patchValue([
        this.preErrorChargeSelection.locationId,
      ]);
      this.filter3pdsControl.patchValue([
        this.preErrorChargeSelection.thirdPartyId,
      ]);
      this.filterLocationsControl.updateValueAndValidity();
      this.filter3pdsControl.updateValueAndValidity();
    }
  }
  getFormattedTime(minutes) {
    return getTimeFromMinutesFrom(minutes);
  }
  public openTransactionHistory(transaction) {
    this.dialog.open(TransactionHistoryDialogComponent, {
      panelClass: "invisible-panel-dialog",
      data: {
        transaction,
      },
    });
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  public async openDisputeManager(errorChargeTransaction) {
    const disputeManagerRef = this.dialog.open(DisputeManagerDialogComponent, {
      panelClass: "invisible-panel-dialog",
      data: {
        transaction: errorChargeTransaction,
      },
    });
    disputeManagerRef
      .afterClosed()
      .subscribe((changes: { disputeReason: string; disputeCount: number }) => {
        if (changes) {
          (errorChargeTransaction.disputeReason = changes.disputeReason),
            (errorChargeTransaction.disputeCount = changes.disputeCount);
        }
      });
  }
  copySuccessAlert(transactionId: string) {
    this.snackBar.open(
      `Transaction ID: ${transactionId} copied to your clipboard`,
      "Dismiss",
      { duration: 4000 }
    );
  }
  async exportDisputEligible() {
    const doorDashRefunds = Object.assign(
      this.tableData.data.filter((transaction) => {
        const dsp = this.dataUtility.thirdParties.find(
          (dsp) => dsp.id === transaction.thirdParty
        );
        return (
          (dsp.id === ThirdPartyDeliveryIds.DoorDash ||
            dsp.duplicateOf === ThirdPartyDeliveryIds.DoorDash) &&
          !!transaction["errorChargeLog"]?.eligibleForDispute
        );
      })
    );
    const uberRefunds = Object.assign(
      this.tableData.data.filter((transaction) => {
        const dsp = this.dataUtility.thirdParties.find(
          (dsp) => dsp.id === transaction.thirdParty
        );
        return (
          (dsp.id === ThirdPartyDeliveryIds["Uber Eats"] ||
            dsp.duplicateOf === ThirdPartyDeliveryIds["Uber Eats"]) &&
          !!transaction["errorChargeLog"]?.eligibleForDispute
        );
      })
    );
    const grubHubRefunds = Object.assign(
      this.tableData.data.filter((transaction) => {
        const dsp = this.dataUtility.thirdParties.find(
          (dsp) => dsp.id === transaction.thirdParty
        );
        return (
          (dsp.id === ThirdPartyDeliveryIds.GrubHub ||
            dsp.duplicateOf === ThirdPartyDeliveryIds.GrubHub) &&
          !!transaction["errorChargeLog"]?.eligibleForDispute
        );
      })
    );
    await Promise.all([
      this.createDeliveryPartnerDisputeFile(doorDashRefunds, "DoorDash"),
      this.createDeliveryPartnerDisputeFile(uberRefunds, "UberEats"),
      this.createDeliveryPartnerDisputeFile(grubHubRefunds, "GrubHub"),
    ]);
  }
  private async createDeliveryPartnerDisputeFile(
    data: any[],
    deliveryPartner: string
  ) {
    const disputeRefunds = data.map((errorTransaction) => {
      const row: any = {
        Location: errorTransaction.location,
        "Order Id": errorTransaction.transactionId,
        "Refunded Amount": errorTransaction["amount"],
        "Third Party": errorTransaction["thirdPartyName"],
        Date: `${
          errorTransaction.date
            ? moment(errorTransaction.date.toDate()).format("M/D/YYYY")
            : ""
        }`,
        Time: this.getFormattedTime(errorTransaction.time),
        Status: errorTransaction.status,
        "Transaction Type": errorTransaction.transactionType,

        "Original Order Amount":
          errorTransaction["errorChargeLog"]?.parentThirdPartyTransaction?.sale,
        Description: `${
          errorTransaction.description ? errorTransaction.description : ""
        }`,
        Details: `${
          errorTransaction.metadata ? errorTransaction.metadata : ""
        }`,
        "POS Transaction Id": `${
          errorTransaction["errorChargeLog"] &&
          errorTransaction["errorChargeLog"]?.posTransaction
            ? errorTransaction["errorChargeLog"]?.posTransaction?.transactionId
            : ""
        }`,
        "Issue Count":
          errorTransaction["errorChargeLog"] &&
          errorTransaction["errorChargeLog"].issueCount
            ? +errorTransaction["errorChargeLog"].issueCount
            : "",
        "DoorDash Workflow ID": errorTransaction.vendorWorkflowId,

        "Items To Dispute":
          errorTransaction["errorChargeLog"] &&
          errorTransaction["errorChargeLog"].itemsToDispute
            ? errorTransaction["errorChargeLog"].itemsToDispute
            : "",
      };
      return row;
    });
    // const groups = _.groupBy(doorDashRefunds, "Date");
    // await Promise.all(
    // Object.keys(groups).map((date) => {
    const results = this.papa.unparse(disputeRefunds, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const fileName = `${this.dataUtility.client.name}_${deliveryPartner}-ErrorChargeDisputes_${this.dataUtility.existingReport.name}`;
    return downloadDataAsFile(results, fileName, "csv");
    // })
    // );
  }
  exportReport() {
    const data = Object.assign(
      this.tableData.data.map((errorTransaction: ThirdPartyTransaction) => {
        const row: any = {
          Location: errorTransaction.location,
          "Third Party": errorTransaction["thirdPartyName"],
          Date: `${
            errorTransaction.date
              ? moment(errorTransaction.date.toDate()).format("M/D/YYYY")
              : ""
          }`,
          Time: this.getFormattedTime(errorTransaction.time),
          Status: errorTransaction.status,
          "Transaction Type": errorTransaction.transactionType,
          "Refund Amount": errorTransaction["amount"],
          "Order Amount":
            errorTransaction["errorChargeLog"]?.parentThirdPartyTransaction
              ?.sale,
          Description: `${
            errorTransaction.description ? errorTransaction.description : ""
          }`,
          Details: `${
            errorTransaction.metadata ? errorTransaction.metadata : ""
          }`,
          "Issue Count":
            errorTransaction["errorChargeLog"] &&
            errorTransaction["errorChargeLog"].issueCount
              ? +errorTransaction["errorChargeLog"].issueCount
              : "",
          "Transaction Id": errorTransaction.transactionId,
          "POS Transaction Id": `${
            errorTransaction["errorChargeLog"] &&
            errorTransaction["errorChargeLog"]?.posTransaction
              ? errorTransaction["errorChargeLog"]?.posTransaction
                  ?.transactionId
              : ""
          }`,
          "Workflow ID": errorTransaction.vendorWorkflowId,
        };
        if (this.dataUtility.isEcdsAddonActive()) {
          (row["Dispute Eligible"] =
            errorTransaction["errorChargeLog"]?.eligibleForDispute),
            (row["Dispute Reason"] = errorTransaction?.disputeReason),
            (row["Dispute Count"] = errorTransaction?.disputeCount);
        }
        return row;
      })
    );
    const results = this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const fileName = `${this.dataUtility.existingReport.name}_Error_Charge_Summary`;
    downloadDataAsFile(results, fileName, "csv");
  }
  public getTotalErrorChargeAmount() {
    return this.tableData.filteredData.reduce((sum, current) => {
      return (sum += +current.amount ? +current.amount : 0);
    }, 0);
  }
  public getTotalOrderAmount() {
    return this.tableData.filteredData.reduce((sum, current) => {
      return (sum += +current.errorChargeLog?.parentThirdPartyTransaction?.sale
        ? +current.errorChargeLog.parentThirdPartyTransaction.sale
        : 0);
    }, 0);
  }

  /**Filter Predicate */
  setFilterPredicate(
    tableData: MatTableDataSource<any>,
    filter3pdsControl,
    filterLocationsControl,
    filterDisputeOnly
  ) {
    tableData.filterPredicate = (data: any, filtersJson: string) => {
      const filterGroups = _.groupBy(JSON.parse(filtersJson), "id");
      let matchThirdPartyName = true;
      let matchLocationId = true;
      let matchDisputeEligible = true;
      if (filterGroups.thirdParty?.length > 0) {
        matchThirdPartyName = !!filterGroups.thirdParty.find((dspFilter) => {
          return (
            dspFilter.value === data.thirdParty ||
            dspFilter.name === data.thirdPartyName
          );
        });
      }
      if (filterGroups.locationId?.length > 0) {
        matchLocationId = !!filterGroups.locationId.find(
          (locationIdFilter) =>
            // Location will be locationId in rec table but location in all other reports
            locationIdFilter.value === data.locationId ||
            locationIdFilter.value === data.location
        );
      }
      if (filterGroups.disputeOnly[0].value === true) {
        matchDisputeEligible = data.errorChargeLog?.eligibleForDispute;
      }

      const matchAll =
        matchThirdPartyName && matchLocationId && matchDisputeEligible;
      return matchAll;
    };
    this.setupFilterListeners(
      tableData,
      filter3pdsControl,
      filterLocationsControl,
      filterDisputeOnly
    );
  }
  private setupFilterListeners(
    tableData: MatTableDataSource<any>,
    filter3pdsControl: FormControl,
    filterLocationsControl: FormControl,
    filterDisputeOnly: FormControl
  ) {
    combineLatest([
      filter3pdsControl.valueChanges.pipe(startWith([])),
      filterLocationsControl.valueChanges.pipe(startWith([])),
      filterDisputeOnly.valueChanges.pipe(startWith(false)),
    ]).subscribe(([selected3pds, selectedLocations, disputeOnly]) => {
      this.multiFilter(tableData, selected3pds, selectedLocations, disputeOnly);
    });
  }
  private multiFilter(
    tableData: MatTableDataSource<any>,
    selected3pds: string[],
    selectedLocations: string[],
    disputeOnly: boolean
  ) {
    let filters;
    let tpdFilters = [];
    let locationFilters = [];
    if (selectedLocations.length > 0) {
      locationFilters = selectedLocations.map((locationId) => {
        return { id: "locationId", value: locationId };
      });
    }

    if (selected3pds.length > 0) {
      tpdFilters = selected3pds.map((dspId) => {
        const dsp = this.dataUtility.thirdParties.find(
          (dsp) => dsp.id === dspId
        );
        return { id: "thirdParty", value: dspId, name: dsp.name };
      });
    }
    const filtersArray = [
      ...tpdFilters,
      ...locationFilters,
      { id: "disputeOnly", value: disputeOnly },
    ];
    if (filtersArray.length > 0) {
      filters = JSON.stringify(filtersArray);
    } else {
      filters = "";
    }
    tableData.filter = filters;
    tableData.paginator.firstPage();
  }
}
