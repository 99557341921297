import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { ThirdPartyCategoryReport } from "@deliver-sense-librarian/data-schema";
import { FormBuilder } from "@angular/forms";
import _ from "lodash";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import { ReconciliationReportDatUtility } from "../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../utilities/reconciliation-report-filtering.utility";

@Component({
  selector: "app-status-drill-down",
  templateUrl: "./status-drill-down.component.html",
  styleUrls: ["./status-drill-down.component.scss"],
})
export class StatusDrillDownComponent implements OnInit {
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() exportUtility: ReconciliationReportExportUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  thirdPartyReportFragmentCategoryReports: ThirdPartyCategoryReport[] = [];
  ƒ;
  loadingData: boolean;
  constructor(
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loadStatusAnalysisReports();
  }
  private async loadStatusAnalysisReports() {
    this.loadingData = true;
    this.thirdPartyReportFragmentCategoryReports =
      await this.dataUtility.compileCategoryReports("status");
    this.loadingData = false;
  }
}
