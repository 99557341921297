import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { ThirdPartyReportFragmentTransactionAnalysis } from "@deliver-sense-librarian/data-schema";
import { Papa } from "ngx-papaparse";
import { ReconciliationReportDatUtility } from "../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../utilities/reconciliation-report-filtering.utility";
@Component({
  selector: "app-potential-fraud-report",
  templateUrl: "./potential-fraud-report.component.html",
  styleUrls: ["./potential-fraud-report.component.scss"],
})
export class PotentialFraudReportComponent implements OnInit {
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  public thirdPartyPotentialFraudIndicators: ThirdPartyReportFragmentTransactionAnalysis[] =
    [];
  loadingData = true;
  constructor(private cdr: ChangeDetectorRef, private papa: Papa) {}
  ngOnInit(): void {
    this.initializeDataAndTable();
  }
  async initializeDataAndTable() {
    this.loadingData = true;
    this.thirdPartyPotentialFraudIndicators =
      await this.dataUtility.compileDailyDrillDownBreakoutReports(
        "fraudIndicators"
      );
    this.loadingData = false;
  }
}
