import { Store } from "@ngrx/store";
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
} from "@angular/core";
import {
  User,
  ClientExemptionCertificateDocument,
  PosTransaction,
  ExemptionCertificate,
  Client,
} from "@deliver-sense-librarian/data-schema";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { FirestoreUtilities } from "../../../../utilities/firestore-utilities";
import { ConfirmDialogComponent } from "app/dialogs/confirm-dialog/confirm-dialog.component";
import { takeUntil, map, first } from "rxjs/operators";
import { Subject } from "rxjs";
import { AngularFireStorage } from "@angular/fire/storage";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ExemptionCertificatesListDialogComponent } from "app/dialogs/exemption-certificates-list/exemption-certificates-list.component";
import { SelectionModel } from "@angular/cdk/collections";
import { ExemptionCertificateViewerDialogComponent } from "../../../../dialogs/exemption-certificate-viewer-dialog/exemption-certificate-viewer-dialog.component";
import * as _ from "lodash";
import * as JSZip from "jszip";
import * as FileSaver from "file-saver";
import { reject } from "lodash";
import { Workbook, Worksheet } from "exceljs";

declare var JSZipUtils: any;
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import moment from "moment";
let _Promise = window.Promise;
if (!_Promise) {
  _Promise = JSZip.external.Promise;
}
@Component({
  selector: "app-exempt-transactions",
  templateUrl: "./exempt-transactions.component.html",
  styleUrls: ["./exempt-transactions.component.scss"],
})
export class ExemptTransactionsComponent implements OnInit, AfterViewInit {
  @Input() transactions: PosTransaction[] = [];
  @Input() storagePath: string;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selection = new SelectionModel<PosTransaction>(true, []);
  zip = new JSZip();
  jsonHeader = "application/json; odata=verbose";

  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    "select",
    "documented",
    "location",
    "state",
    "date",
    "sale",
    "tax",
    "description",
    "edit",
  ];
  private destroy$ = new Subject();
  private client: Client;
  private user: User;

  constructor(
    private httpClient: HttpClient,
    private store: Store<any>,
    private dialog: MatDialog,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.store
      .select((store) => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((uiState$) => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
        }
      });
    this.tableData = new MatTableDataSource(this.transactions);
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.cdr.detectChanges();
  }
  ngAfterViewInit() {}
  applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  async addExemptionCert(
    certificateDocument: ClientExemptionCertificateDocument,
    posTransaction: PosTransaction
  ) {
    const exemptionCertificate = new ExemptionCertificate();
    exemptionCertificate.client = this.client.id;
    exemptionCertificate.document = certificateDocument.id;
    exemptionCertificate.posTransaction = posTransaction.id;
    return this.afs
      .collection("exemptionCertificates")
      .add(exemptionCertificate.toJSONObject());
  }
  async removeDocumentsFromSelection() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Remove",
        message:
          "Are you sure you want remove certificates from the selected transactions?.",
        action: "Yes, Remove.",
      },
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        this.loadingService.isLoading(true);
        await this.removeAllDocuments();
        this.snackBar.open(
          "Certification removed from transactions successfully.",
          "Dismiss",
          { duration: 5000 }
        );
        this.selection.clear();
        this.loadingService.isLoading(false);
      }
    });
  }
  async removeAllDocuments() {
    const documentsToRemove = this.selection.selected.filter(
      (exemptTransaction) => !!exemptTransaction["exemptionCertificate"]
    );
    if (documentsToRemove.length > 0) {
      return await Promise.all(
        documentsToRemove.map((exemptTransaction) => {
          return this.afs
            .doc(
              `exemptionCertificates/${exemptTransaction["exemptionCertificate"].id}`
            )
            .delete();
        })
      );
    }
  }
  attachDocumentToSelection() {
    const dialogRef = this.dialog.open(
      ExemptionCertificatesListDialogComponent,
      {
        panelClass: "invisible-panel-dialog",
        data: {
          client: this.client,
        },
      }
    );
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.loadingService.isLoading(true);
        await this.removeAllDocuments();
        await Promise.all(
          this.selection.selected.map((exemptTransaction) => {
            return this.addExemptionCert(result, exemptTransaction);
          })
        );
        this.snackBar.open(
          "Exemption Certificate added to selected transactions successfully",
          "Dismiss",
          {
            duration: 5000,
          }
        );
        this.selection.clear();
        this.loadingService.isLoading(false);
      }
    });
  }

  async deleteExemptionCertificate(exemptionCertificate: ExemptionCertificate) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Delete",
        message: "Are you sure you want to remove this exemption certificate?",
        action: "Yes, delete.",
      },
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs
          .doc(`exemptionCertificates/${exemptionCertificate.id}`)
          .delete();
        // await FirestoreUtilities.deleteStorageFile(path, this.storage);
        this.snackBar.open(
          "Exemption Certificate removed successfully",
          "Dismiss",
          {
            duration: 5000,
          }
        );
      }
    });
  }

  async viewCertificateDocument(posTransaction: PosTransaction) {
    const clientExemptionCertificateDocument = FirestoreUtilities.objectToType(
      await this.afs
        .doc(
          `clientExemptionCertificateDocuments/${posTransaction["exemptionCertificate"].document}`
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise()
    );
    const certificateViewerDialogRef = this.dialog.open(
      ExemptionCertificateViewerDialogComponent,
      {
        panelClass: "invisible-panel-dialog",
        data: {
          clientExemptionCertificateDocument,
        },
      }
    );
    certificateViewerDialogRef.afterClosed().subscribe((result$) => {
      if (result$ === "remove") {
        this.deleteExemptionCertificate(posTransaction["exemptionCertificate"]);
      }
    });
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableData.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.tableData.data.forEach((row) => {
          this.selection.select(row);
        });
  }

  async exportDocumentation() {
    const certificateDocuments = await Promise.all(
      this.selection.selected
        .filter(
          (transaction) => !!transaction["exemptionCertificate"]?.document
        )
        .map(async (posTransaction: PosTransaction) => {
          return FirestoreUtilities.objectToType(
            await this.afs
              .doc(
                `clientExemptionCertificateDocuments/${posTransaction["exemptionCertificate"].document}`
              )
              .snapshotChanges()
              .pipe(first())
              .toPromise()
          );
        })
    );
    const uniqueCertificates = _.uniqBy(certificateDocuments, "id");
    const filesDownloadInfo = await Promise.all(
      uniqueCertificates.map(
        async (certificateDocument: ClientExemptionCertificateDocument) => {
          const fileName = certificateDocument.fileName;
          const lastDot = fileName.lastIndexOf(".");
          const ext = fileName.substring(lastDot + 1);
          const url = await this.storage
            .ref(certificateDocument.filePath)
            .getDownloadURL()
            .pipe(first())
            .toPromise();
          return {
            fileName: fileName,
            url: url,
          };
        }
      )
    );

    this.createZip(certificateDocuments, filesDownloadInfo, "test");
  }
  selectionContainsDocumented() {
    let containsDocumented = false;
    this.selection.selected.forEach((exemptTransaction) => {
      if (exemptTransaction && exemptTransaction["exemptionCertificate"]) {
        containsDocumented = true;
      }
    });
    return containsDocumented;
  }

  downloadUrlAsPromise(url) {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.onreadystatechange = (evt) => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(new Error("Ajax error for " + url + ": " + xhr.status));
          }
        }
      };
      xhr.send();
    });
  }
  async generateExcelFile(
    certificateDocuments: ClientExemptionCertificateDocument[]
  ) {
    const workbook = new Workbook();
    const transactionSheet = workbook.addWorksheet("exempt-transactions");
    const headerRow = transactionSheet.addRow([
      "Location",
      "Date",
      "State",
      "Sale",
      "Tax",
      "Description",
      "Documented",
      "Document File",
      "Document Notes",
    ]);
    this.selection.selected.forEach((transaction) => {
      const certificate = transaction["exemptionCertificate"]
        ? certificateDocuments.find(
            (document) =>
              document.id === transaction["exemptionCertificate"].document
          )
        : null;
      transactionSheet.addRow([
        transaction.location,
        moment(transaction.date.toDate()).format("l"),
        transaction["state"],
        transaction.sale,
        transaction.tax,
        transaction["description"],
        !!certificate ? "YES" : "NO",
        certificate ? certificate.fileName : "",
        certificate ? certificate.notes : "",
      ]);
    });
    const data = await workbook.xlsx.writeBuffer();
    return new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  }
  async createZip(
    certificateDocuments: ClientExemptionCertificateDocument[],
    files: { fileName; url }[],
    zipName: string
  ) {
    const zip = new JSZip();
    const excelFileBlob = await this.generateExcelFile(certificateDocuments);
    zip.file(`exempt-transactions.xlsx`, excelFileBlob);
    const binaryContents = await Promise.all(
      files.map(async (fileData) => {
        return this.getFile(zip, fileData);
      })
    );
    binaryContents.forEach((imageData: { fileName; binaryContent }) => {
      zip
        .folder("certificates")
        .file(imageData.fileName, imageData.binaryContent, { binary: true });
    });
    let percentage = 0;
    zip
      .generateAsync({ type: "blob" }, function updateCallback(metadata) {
        var msg = "progression : " + metadata.percent.toFixed(2) + " %";
        if (metadata.currentFile) {
          msg += ", current file = " + metadata.currentFile;
        }
        console.log(msg);
        percentage = metadata.percent | 0;
      })
      .then(
        function callback(blob) {
          FileSaver.saveAs(blob, "exempt-transaction-certifications.zip");
        },
        function (e) {
          console.error(e);
        }
      );
  }
  async getFile(zip: JSZip, fileData: { fileName; url }) {
    return await new Promise((resolve) => {
      return JSZipUtils.getBinaryContent(fileData.url, function (err, data) {
        if (err) {
          console.error(err);
          reject(err);
        }
        resolve({
          fileName: fileData.fileName,
          binaryContent: data,
        });
      });
    });
  }
  getHeaders(bAddContext, returnOp) {
    const headerCopy: any = Object.assign(
      {},
      { "Content-Type": this.jsonHeader, Accept: this.jsonHeader }
    );
    if (bAddContext) {
      const context: any = document.getElementById("__REQUESTDIGEST");
      if (context) {
        headerCopy["X-RequestDigest"] = context.value;
      }
    }
    if (returnOp) {
      const httpOptions = {
        headers: new HttpHeaders(headerCopy),
      };
      return httpOptions;
    } else {
      return headerCopy;
    }
  }
  parseResults(res) {
    if (res) {
      if (res.hasOwnProperty("d") && res.d.hasOwnProperty("results")) {
        return res.d.results;
      } else if (res.hasOwnProperty("error")) {
        const obj: any = res.error;
        obj.hasError = true;
        return obj;
      } else {
        return {
          hasError: true,
          comments: res,
        };
      }
    } else {
      return {
        hasError: true,
        comments: "Check the response in network trace",
      };
    }
  }
  copySuccessAlert() {
    this.snackBar.open("Description copied to your clipboard!", "Dismiss", {
      duration: 5000,
    });
  }
}
