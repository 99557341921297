import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ReconciliationReportExportUtility } from "../../components/ds-modules/3pd-reconciliation/utilities/reconciliation-report-export.utility";
import { ReconciliationReportDatUtility } from "../../components/ds-modules/3pd-reconciliation/utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../components/ds-modules/3pd-reconciliation/utilities/reconciliation-report-filtering.utility";
import { ThirdPartyReport } from "@deliver-sense-librarian/data-schema";
@Component({
  selector: "app-rec-report-view-dialog",
  templateUrl: "./rec-report-view-dialog.component.html",
  styleUrls: ["./rec-report-view-dialog.component.scss"],
})
export class RecReportViewDialogDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<RecReportViewDialogDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      reportType:
        | "dailyDrillDown"
        | "transactionAnalysis"
        | "errorChargeSummary";
      exportUtility: ReconciliationReportExportUtility;
      dataUtility: ReconciliationReportDatUtility;
      filteringUtility: ReconciliationReportFilteringUtility;
      thirdPartyReport: ThirdPartyReport;
      preSelection: any;
    }
  ) {}

  ngOnInit() {}
  jumpToDailyDrillDown(event) {
    this.data.reportType = "dailyDrillDown";
  }
}
