import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Store } from "@ngrx/store";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { Subject } from "rxjs";
import { distinctUntilChanged, takeUntil, first } from "rxjs/operators";
import { UiState } from "../../../../redux/custom-states/uiState/ui-state";
import { FirestoreUtilities } from "../../../../utilities/firestore-utilities";
import { ClientExemptionCertificateDocument } from "@deliver-sense-librarian/data-schema";
import * as _ from "lodash";
import { ConfirmDialogComponent } from "app/dialogs/confirm-dialog/confirm-dialog.component";
import { UploadDocumentDialogComponent } from "../../../../dialogs/upload-document-dialog/upload-document-dialog.component";
import { ReasonPromptDialogComponent } from "../../../../dialogs/reason-prompt-dialog/reason-prompt-dialog.component";
import {
  ApplicationFormNames,
  FormsContainerDialogComponent,
} from "../../../../dialogs/forms-container-dialog/forms-container-dialog.component";
@Component({
  selector: "app-certificate-manager",
  templateUrl: "./certificate-manager.component.html",
  styleUrls: ["./certificate-manager.component.scss"],
})
export class CertificateManagerComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayColumns: string[] = [
    "title",
    "jurisdiction",
    "notes",
    "fileName",
    "createdBy",
    "dateCreated",
    "actions",
  ];
  private destroy$ = new Subject();
  uiState: UiState;
  clientTaxExemptionDocuments: ClientExemptionCertificateDocument[];
  fileToUpload: any;
  notesToView: any;
  activePopover: any;

  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.store
      .select((store) => store.uiState)
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((a: UiState, b: UiState) => {
          const aComp = {
            client: a.client,
            user: a.authUser,
            thirdParties: a.clientThirdParties,
            locations: a.clientLocations,
          };
          const bComp = {
            client: b.client,
            user: b.authUser,
            thirdParties: b.clientThirdParties,
            locations: b.clientLocations,
          };
          return JSON.stringify(aComp) === JSON.stringify(bComp);
        })
      )
      .subscribe((uiState$) => {
        if (uiState$.authUser && uiState$.client) {
          this.uiState = uiState$;
          this.fetchClientExemptionCertificates();
        }
      });
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  fetchClientExemptionCertificates() {
    this.afs
      .collection("clientExemptionCertificateDocuments", (ref) =>
        ref
          .where("client", "==", this.uiState.client.id)
          .orderBy("dateCreated", "desc")
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (queryResults$) => {
        this.clientTaxExemptionDocuments = <
          ClientExemptionCertificateDocument[]
        >FirestoreUtilities.mapToType(queryResults$);
        const userViewIds = _.uniqBy(
          this.clientTaxExemptionDocuments,
          "createdBy"
        ).map((document) => document.createdBy);
        const userViews = FirestoreUtilities.mergeToType(
          await Promise.all(
            userViewIds.map((userViewId) =>
              this.afs
                .doc(`userViews/${userViewId}`)
                .snapshotChanges()
                .pipe(first())
                .toPromise()
            )
          )
        );
        this.clientTaxExemptionDocuments.forEach(
          (document) =>
            (document["userView"] = userViews.find(
              (userView) => userView.id === document.createdBy
            ))
        );
        this.tableData = new MatTableDataSource(
          this.clientTaxExemptionDocuments
        );
        this.tableData.paginator = this.paginator;
        this.tableData.sort = this.sort;
      });
  }
  setNotesToView(notes, activePopover) {
    this.notesToView = notes;
    this.activePopover = activePopover;
  }
  addCertificate() {
    const documentUploadDialog = this.dialog.open(
      FormsContainerDialogComponent,
      {
        panelClass: "invisible-panel-dialog",
        data: {
          formName: ApplicationFormNames.clientExemptionCertificateDocument,
        },
      }
    );
    // documentUploadDialog.afterClosed().subscribe(uploadResults => {
    //   if (uploadResults && uploadResults !== 'error') {
    //     const clientExemptionCertificateDocument = new ClientExemptionCertificateDocument();
    //     clientExemptionCertificateDocument.title = title;
    //     clientExemptionCertificateDocument.client = this.uiState.client.id;
    //     clientExemptionCertificateDocument.createdBy = this.uiState.authUser.id;
    //     clientExemptionCertificateDocument.filePath = uploadResults.filePath;
    //     clientExemptionCertificateDocument.fileName = uploadResults.fileName;
    //     clientExemptionCertificateDocument.fileSize = uploadResults.fileSize;
    //     this.afs.collection('clientExemptionCertificateDocuments')
    //       .add(clientExemptionCertificateDocument.toJSONObject());
    //   } else if (uploadResults === 'error') {
    //     this.snackBar.open('Error uploading file. Please check your file format or try refreshing the page.', 'Dismiss', { duration: 5000 });
    //   }
    // })
  }
  async downloadFile(
    exemptionCertificateDocument: ClientExemptionCertificateDocument
  ) {
    const fullUrlPath = await this.storage
      .ref(exemptionCertificateDocument.filePath)
      .getDownloadURL()
      .pipe(first())
      .toPromise();
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.download = exemptionCertificateDocument.filePath;
    a.href = fullUrlPath;
    a.target = "_blank";
    a.click();
  }
  editCertificate(
    exemptionCertificateDocument: ClientExemptionCertificateDocument
  ) {
    const dialogRef = this.dialog.open(FormsContainerDialogComponent, {
      panelClass: "invisible-panel-dialog",
      data: {
        formName: ApplicationFormNames.clientExemptionCertificateDocument,
        inputData: exemptionCertificateDocument,
      },
    });
  }
  deleteCertificate(
    exemptionCertificateDocument: ClientExemptionCertificateDocument
  ) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Delete Exemption Certificate",
        message: `Are you sure you want to delete this exemption certificate document. This action will remove it from all linked tax exempt transactions.`,
        action: "Yes, Delete",
      },
    });
    confirmDialog.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs
          .doc(
            `clientExemptionCertificateDocuments/${exemptionCertificateDocument.id}`
          )
          .delete();
        this.snackBar.open(
          "Successfully deleted exemption certificate document.",
          "Dismiss",
          { duration: 5000 }
        );
      }
    });
  }
}
