import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "lodash";
import { Papa } from "ngx-papaparse";
import { ThirdPartyPerformanceSummaryReport } from "@deliver-sense-librarian/data-schema";
import { FormControl } from "@angular/forms";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import {
  ReconciliationDrillDownReportTypes,
  ReconciliationReportDatUtility,
} from "../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../utilities/reconciliation-report-filtering.utility";
@Component({
  selector: "app-third-party-performance-summary-report",
  templateUrl: "./third-party-performance-summary-report.component.html",
  styleUrls: ["./third-party-performance-summary-report.component.scss"],
})
export class ThirdPartyPerformanceSummaryComponent
  implements OnInit, AfterViewInit
{
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() exportUtility: ReconciliationReportExportUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public thirdPartyPerformanceSummaryReports: ThirdPartyPerformanceSummaryReport[];
  public filterLocationControl = new FormControl([]);
  public filterDspControl = new FormControl([]);
  public loadingData: boolean;
  constructor(
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private papa: Papa
  ) {}

  ngOnInit() {
    this.initializeTable();
  }
  ngAfterViewInit() {}
  private async initializeTable() {
    this.loadingData = true;
    this.thirdPartyPerformanceSummaryReports = Object.assign(
      //NEW Instance so as not to modify utility doc
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.thirdPartyPerformanceSummaryReport
      )
    );
    // this.thirdPartyPerformanceSummaryReports.forEach((row) => {
    //   row.errorChargeAmountRate = row.errorChargeAmountRate
    //     ? +(100 * row.errorChargeAmountRate).toFixed(4)
    //     : 0;
    //   row.errorChargeCountRate = row.errorChargeCountRate
    //     ? +(100 * row.errorChargeCountRate).toFixed(4)
    //     : 0;
    //   row.canceledOrderAmountRate = row.canceledOrderAmountRate
    //     ? +(100 * row.canceledOrderAmountRate).toFixed(4)
    //     : 0;
    //   row.canceledOrderCountRate = row.canceledOrderCountRate
    //     ? +(100 * row.canceledOrderCountRate).toFixed(4)
    //     : 0;
    // });
    this.loadingData = false;
  }
}
