import {
  AfterViewChecked,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FirebaseAuthService } from "../../auth/services/firebase-auth.service";
import { Router } from "@angular/router";
import { User, Client } from "@deliver-sense-librarian/data-schema";
import { SupportDialogComponent } from "app/dialogs/support-dialog/support-dialog.component";
import { PrivacyPolicyDialogComponent } from "app/dialogs/policy-policy-dialog/privacy-policy-dialog.component";
import { TermsAndServicesDialogComponent } from "../../dialogs/terms-and-services-dialog/terms-and-services-dialog.component";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarDirective,
} from "ngx-perfect-scrollbar";
import { Store } from "@ngrx/store";

import { AngularFireStorage } from "@angular/fire/storage";
import { UiState } from "../../redux/custom-states/uiState/ui-state";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";

const SMALL_WIDTH_BREAKPOINT = 960;

@Component({
  selector: "app-layout-component",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.scss"],
})
export class AdminLayoutComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  @ViewChild("sidemenu") sidemenu: MatSidenav;
  @ViewChild(PerfectScrollbarDirective)
  public user: User;
  numberUnreadNotifications = 0;
  public initialLogo: any;
  destroy$ = new Subject();
  public isLiveChatWidgetLoaded: boolean = false;
  mediaMatcher: MediaQueryList = matchMedia(
    `(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`
  );
  url: string;
  sidePanelOpened;
  options = {
    collapsed: false,
    compact: false,
    boxed: false,
    dark: false,
    dir: "ltr",
  };
  directiveScroll: PerfectScrollbarDirective;
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
  };
  client: Client;
  showDataUpload = false;
  uiState: UiState;
  constructor(
    public store: Store<any>,
    private afs: AngularFirestore,
    private dialog: MatDialog,
    private ngRouter: Router,
    zone: NgZone
  ) {
    this.mediaMatcher.addListener((mql) =>
      zone.run(() => {
        // @ts-ignore
        this.mediaMatcher = mql;
      })
    );
  }
  ngOnInit() {
    this.url = this.ngRouter.url;
    this.checkForVersionMismatch();
    this.clearRedirects();
    this.store
      .select((store) => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((uiState$: UiState) => {
        this.showDataUpload = uiState$.showDataUpload;
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$;
          this.getUnreadNotifications();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    // this.router.unsubscribe();
  }

  ngAfterViewChecked(): void {}
  /**
   * If in redirect flow, need to purge local storage redirect indicators
   * so next login doesn't flow through to same
   */
  clearRedirects() {
    localStorage.removeItem("redirectTo");
    localStorage.removeItem("shared");
  }
  async checkForVersionMismatch() {
    // this.afs
    //   .doc(`globals/latestVersion`)
    //   .snapshotChanges()
    //   .pipe(
    //     takeUntil(this.destroy$),
    //     distinctUntilChanged((a: any, b: any) => {
    //       return (
    //         FirestoreUtilities.objectToType(a)?.value ===
    //         FirestoreUtilities.objectToType(b)?.value
    //       );
    //     })
    //   )
    //   .subscribe(
    //     (latestVersion$) => {
    //       const latestVersion: any =
    //         FirestoreUtilities.objectToType(latestVersion$);
    //       if (latestVersion.value !== environment.appVersion) {
    //         this.dialog.open(NewVersionDialogComponent, {
    //           panelClass: "invisible-panel-dialog",
    //           data: {
    //             version: latestVersion.value,
    //             reauth: latestVersion.reauth,
    //           },
    //         });
    //       }
    //     },
    //     (e) => {
    //       console.error(`Globals issue ${e.message}`);
    //     }
    //   );
  }
  runOnRouteChange(): void {
    if (this.isOver()) {
      this.sidemenu.close();
    }

    this.updatePS();
  }

  showSupportDialog() {
    this.dialog.open(SupportDialogComponent);
  }

  private getUnreadNotifications() {
    this.afs
      .collection("userNotifications", (ref) =>
        ref.where("toUser", "==", this.user.id).where("read", "==", false)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((notifications$) => {
        this.numberUnreadNotifications = notifications$.length;
      });
  }

  gotToNotifications() {
    this.ngRouter.navigateByUrl("/admin/notifications");
  }

  showPrivacyPolicy() {
    this.dialog.open(PrivacyPolicyDialogComponent);
  }

  showTermsOfService() {
    this.dialog.open(TermsAndServicesDialogComponent);
  }

  eceiveOptions($event): void {
    this.options = $event;
  }

  /**
   * From Primer
   */

  receiveOptions($event): void {
    this.options = $event;
  }

  isOver(): boolean {
    if (
      this.url === "/apps/messages" ||
      this.url === "/apps/calendar" ||
      this.url === "/apps/media" ||
      this.url === "/maps/leaflet" ||
      this.url === "/taskboard"
    ) {
      return true;
    } else {
      return this.mediaMatcher.matches;
    }
  }

  menuMouseOver(): void {
    if (this.mediaMatcher.matches && this.options.collapsed) {
      this.sidemenu.mode = "over";
    }
  }

  menuMouseOut(): void {
    if (this.mediaMatcher.matches && this.options.collapsed) {
      this.sidemenu.mode = "side";
    }
  }

  updatePS(): void {
    if (
      !this.mediaMatcher.matches &&
      !this.options.compact &&
      !!this.directiveScroll
    ) {
      setTimeout(() => {
        this.directiveScroll.update();
      }, 350);
    }
  }
}
