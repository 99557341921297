import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModule } from "../../material.module";
import {
  ThirdPartyDeliveryLatestReportsComponent,
  BillingRecentDayChargesComponent,
  BillingRecentInvoicesComponent,
} from "./widgets";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DashboardWidgetComponent } from "./dashboard-widget/dashboard-widget.component";
import { ThirdPartyDeliverySharedReportsComponent } from "./widgets/third-party-delivery-shared-reports/third-party-delivery-shared-reports.component";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { ThirdPartyPerformanceChartComponent } from "./widgets/third-party-performance-chart/third-party-performance-chart.component";
import { HighchartsChartModule } from "highcharts-angular";
import { RecentExemptTransactionsComponent } from "./widgets/recent-exempt-transactions/recent-exempt-transactions.component";
import { LocationPerformanceChartComponent } from "./widgets/location-performance-chart/location-performance-chart.component";
import { UxModule } from "../ux/ux.module";

@NgModule({
  declarations: [
    DashboardComponent,
    ThirdPartyDeliveryLatestReportsComponent,
    ThirdPartyDeliverySharedReportsComponent,
    BillingRecentDayChargesComponent,
    BillingRecentInvoicesComponent,
    DashboardWidgetComponent,
    ThirdPartyPerformanceChartComponent,
    RecentExemptTransactionsComponent,
    LocationPerformanceChartComponent,
  ],
  exports: [
    DashboardComponent,
    ThirdPartyDeliveryLatestReportsComponent,
    ThirdPartyDeliverySharedReportsComponent,
    BillingRecentDayChargesComponent,
    BillingRecentInvoicesComponent,
    DashboardWidgetComponent,
    ThirdPartyPerformanceChartComponent,
    LocationPerformanceChartComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    HighchartsChartModule,
    UxModule,
  ],
})
export class DashboardModule {}
