import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  AfterViewInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  ThirdParty,
  TransactionI,
  ThirdPartyTransaction,
  ThirdPartyReport,
  ThirdPartyReportTransactionFlag,
} from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { downloadDataAsFile, getTimeFormatted } from "app/shared/ds-constant";
import moment from "moment";
import { Papa } from "ngx-papaparse";
import { ReasonPromptDialogComponent } from "../../dialogs/reason-prompt-dialog/reason-prompt-dialog.component";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import { transition } from "@angular/animations";
import { ConfirmDialogComponent } from "app/dialogs/confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-transactions-table",
  templateUrl: "./transactions-table.component.html",
  styleUrls: ["./transactions-table.component.scss"],
})
export class TransactionsTableComponent implements OnInit, AfterViewInit {
  @Input() thirdParties: ThirdParty[] = [];
  @Input() transactions;
  @Input() thirdPartyReport: ThirdPartyReport;
  @Output() openTransactionHistory = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public keys: string[] = [];

  public tableData: MatTableDataSource<TransactionI>;
  public displayedColumns: string[] = [
    "date",
    "time",
    "payoutDate",
    "location",
    "source",
    "status",
    "transactionType",
    "thirdParty",
    "description",
    "sale",
    "saleCorrection",
    "netSale",
    "tax",
    "taxCorrection",
    "netTax",
    "tip",
    "otherCharges",
    "misc",
    "taxRemitted",
    "totalRemitted",
    "promoFee",
    "deliveryFee",
    "pickupFeeTotal",
    "cateringFee",
    "customFee",
    "campaignPromoFee",
    "transactionId",
    "pickupDate",
    "orderId",
    "workflowId",
    "metaData",
    "id",
  ];
  descriptionToView: any;
  activePopover: any;
  constructor(
    private papa: Papa,
    private dialog: MatDialog,
    private store: Store<any>,
    private afs: AngularFirestore,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.flattenTransactionData();
    if (this.thirdPartyReport) {
      this.displayedColumns = ["flagged", ...this.displayedColumns];
    }
    this.tableData = new MatTableDataSource(this.transactions);
  }
  ngAfterViewInit() {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
  }
  private flattenTransactionData() {
    this.transactions.forEach((transaction) => {
      transaction["dateText"] = moment(transaction.date.toDate()).format("l");
      transaction["pickupDateText"] = transaction.pickupDate
        ? moment(transaction.pickupDate.toDate()).format("l")
        : "";
      transaction["source"] = transaction["account"] ? "POS" : "3PD";
      transaction["timeText"] = getTimeFormatted(transaction.time);
      transaction["netSale"] = +(
        (+transaction.sale ? +transaction.sale : 0) +
        (+transaction.saleCorrection ? +transaction.saleCorrection : 0)
      ).toFixed(2);
      transaction["netTax"] = +(
        (+transaction.tax ? +transaction.tax : 0) +
        (+transaction.taxCorrection ? +transaction.taxCorrection : 0)
      ).toFixed(2);
    });
  }
  public exportReport() {
    const data = this.tableData.data.map((row) => {
      return {
        id: row["id"],
        date: moment(row.date.toDate()).format("MM/DD/YYYY"),
        location: row.location,
        type: row["account"] ? "POS" : "3PD",
        thirdParty: this.getThirdPartyName(row),
        time: row.time ? getTimeFormatted(row.time) : "",
        transactionId: row["transactionId"] ? row["transactionId"] : "",
        status: row.status,
        transactionType: row.transactionType,
        sale: row.sale,
        saleCorrection: row.saleCorrection,
        tax: row.tax,
        taxCorrection: row.taxCorrection,
        tip: row.tip,
        otherCharges: row.otherCharges,
        misc: row["misc"] ? row["misc"] : 0,
        taxRemitted: row["taxRemitted"],
        promoFee: row["promoFee"] ? row["promoFee"] : 0,
        deliveryFee: row["deliveryFeeTotal"] ? row["deliveryFeeTotal"] : 0,
        pickupFeeTotal: row["pickupFeeTotal"] ? row["pickupFeeTotal"] : 0,
        cateringFee: row["cateringFee"] ? row["cateringFee"] : 0,
        customFee: row["customFee"] ? row["customFee"] : 0,
        campaignPromoFee: row["campaignPromoFee"] ? row["campaignPromoFee"] : 0,
        remittance: row["totalRemitted"] ? row["totalRemitted"] : 0,
        description: row["description"] ? row["description"] : "",
        details: row["metadata"] ? row["metadata"] : "",
        orderId: row["orderId"] ? row["orderId"] : "",
        dateCreated: row["dateCreated"].toDate(),
      };
    });
    const results = this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const location = data[0] ? data[0].location : "";
    const fileName = `${
      data[0] && data[0]["type"]
    }-${location}-Transactions_Details`;
    downloadDataAsFile(results, fileName, "csv");
  }
  public getThirdPartyName(transaction) {
    const thirdPartyId = transaction.account
      ? transaction.account
      : transaction.thirdParty;
    const thirdParty = this.thirdParties.find(
      (thirdParty) => thirdParty.id === thirdPartyId
    );
    return thirdParty ? `${thirdParty.name}` : "";
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  setNotesToView(description, activePopover) {
    // this.descriptionToView = null;
    // this.activePopover = null;
    // setTimeout(() => {
    this.descriptionToView = description;
    this.activePopover = activePopover;
    // })
  }
  flagTransaction(transaction: TransactionI) {
    const messageDialog = this.dialog.open(ReasonPromptDialogComponent, {
      data: {
        title: "Why should this transaction be flagged",
        placeholder: "Note",
        type: "textarea",
      },
    });
    messageDialog.afterClosed().subscribe(async (note) => {
      const newFlag = new ThirdPartyReportTransactionFlag();
      newFlag.client = this.thirdPartyReport.client;
      newFlag.thirdPartyReport = this.thirdPartyReport.id;
      newFlag.transaction = transaction;
      newFlag.note = note ? note : "";
      newFlag.dateCreated = moment().toDate();
      newFlag.dateUpdated = newFlag.dateCreated;
      await this.afs
        .collection("thirdPartyReportTransactionFlags")
        .add(newFlag.toJSONObject());
      this.snackBar.open("Transaction flagged successfully.", "Dismiss", {
        duration: 500,
      });
      transaction["flag"] = newFlag;
    });
  }
  removeFlag(transaction) {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Remove Flag",
        message: "Are you sure you want remove this transaction flag?.",
        action: "Yes, Remove.",
      },
    });
    confirmDialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs
          .doc(`thirdPartyReportTransactionFlags/${transaction["flag"].id}`)
          .delete();
        this.snackBar.open("Transaction falg has been removed.", "Dimiss", {
          duration: 5000,
        });
        delete transaction["flag"];
      }
    });
  }
}
