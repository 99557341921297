import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AngularFirestore } from "@angular/fire/firestore";
import { ActivatedRoute } from "@angular/router";
import {
  first,
  takeUntil,
  distinctUntilChanged,
  combineAll,
} from "rxjs/operators";
import { from, Subject } from "rxjs";
import {
  UserRoles,
  Location,
  ThirdParty,
  ThirdPartyReportStatuses,
  ReportShare,
  ReportGroup,
  ReportGroupTypes,
  TrendAnalysisReport,
  TrendAnalysisFragment,
  TrendAnalysisFragmentDataSet,
  TrendAnalysisTypes,
  TrendAnalysisDataPoint,
} from "@deliver-sense-librarian/data-schema";
import * as moment from "moment";
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
  FormBuilder,
  FormGroup,
} from "@angular/forms";
import { FirestoreUtilities } from "../../../../utilities/firestore-utilities";
import { LoadingDialogService } from "../../../../services/loading-dialog.service";
import { UiState } from "../../../../redux/custom-states/uiState/ui-state";
import * as _ from "lodash";
import { MatExpansionPanel } from "@angular/material/expansion";
import { MatDialog } from "@angular/material/dialog";
import { TeamMemberSelectorDialogComponent } from "../../../../dialogs/team-member-selector-dialog/team-member-selector-dialog.component";
import { TrendAnalysisUtilities } from "../trend-analysis.utils";

@Component({
  selector: "app-trend-analysis-report",
  templateUrl: "./trend-analysis-report.component.html",
  styleUrls: ["./trend-analysis-report.component.scss"],
})
export class TrendAnalysisReportComponent implements OnInit, OnDestroy {
  @ViewChild(ElementRef, { static: true }) reportHeaderCard: ElementRef;
  @ViewChild(MatExpansionPanel, { static: true })
  parameterPanel: MatExpansionPanel;
  public loadingParameters = true;
  public reportName = new FormControl("", Validators.required);
  public parametersForm: FormGroup;
  public reportData: any[];
  public thirdParties: ThirdParty[] = [];
  public locations: Location[] = [];
  public reportAvailable: boolean;
  public existingReport: TrendAnalysisReport;
  public editingName = false;
  public editingGroup = false;
  private allThirdPartiesSelected: boolean;
  private trendAnalysisReportId: string;
  public uiState: UiState;
  private destroy$ = new Subject();
  public selectedTrendType = new FormControl();
  reportRunningInBackground = false;
  reportFragments: TrendAnalysisFragment[] = [];
  completeFragments: TrendAnalysisFragment[] = [];
  loadingReportFragments = true;
  errorFragments: TrendAnalysisFragment[] = [];
  sharedReport: boolean;
  reportShares: ReportShare[] = [];
  public reportGroups: ReportGroup[] = [];
  public selectedReportGroup = new FormControl();
  loadingFragmentReports: boolean;
  activePopover: any;
  errorRunningReport: boolean;
  trendTypes = [
    TrendAnalysisTypes.sales,
    TrendAnalysisTypes.salesVariance,
    TrendAnalysisTypes.taxVariance,
    TrendAnalysisTypes.errorChargesTotal,
    TrendAnalysisTypes.errorChargesCount,
  ];
  fragmentDataSets: any;
  chartData: any[];

  dataGroupControl = new FormControl("3PDs");
  groupOptions = [
    "3PDs",
    "Locations",
    // 'Entity'
  ];
  dataPoints: TrendAnalysisDataPoint[];
  chartTitle: string;

  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.store
      .select((store) => store.uiState)
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((a: UiState, b: UiState) => {
          const aComp = {
            client: a.client,
            user: a.authUser,
            thirdParties: a.clientThirdParties,
            locations: a.clientLocations,
          };
          const bComp = {
            client: b.client,
            user: b.authUser,
            thirdParties: b.clientThirdParties,
            locations: b.clientLocations,
          };
          return JSON.stringify(aComp) === JSON.stringify(bComp);
        })
      )
      .subscribe((uiState$: UiState) => {
        if (
          uiState$.authUser &&
          uiState$.client &&
          uiState$.clientThirdParties &&
          uiState$.clientLocations
        ) {
          this.uiState = uiState$;
          this.initializeReport();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private async initializeReport() {
    this.getLocations();
    this.thirdParties = this.uiState.clientThirdParties
      .map((clientThirdParty) => clientThirdParty.thirdParty)
      .filter((tp) => !!tp);
    const queryParams = await this.activatedRoute.queryParams
      .pipe(first())
      .toPromise();
    this.sharedReport = !!queryParams["shared"];
    this.activatedRoute.params.subscribe((params$) => {
      this.trendAnalysisReportId = params$["id"];
      if (this.trendAnalysisReportId) {
        this.getExistingReport();
      }
      if (!this.sharedReport) {
        this.getExistingReportShares();
      }
    });
    this.getReportGroups();
  }

  private getReportGroups() {
    this.afs
      .collection("reportGroups", (ref) =>
        ref
          .where("client", "==", this.uiState.client.id)
          .where("creator", "==", this.uiState.authUser.id)
          .where("type", "==", ReportGroupTypes.trendAnalysis)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((reportGroups$) => {
        this.reportGroups = <ReportGroup[]>(
          FirestoreUtilities.mapToType(reportGroups$)
        );
        this.reportGroups.push(
          new ReportGroup({ name: "Un-Grouped", id: "ungrouped" })
        );
      });
  }

  private getLocations() {
    FirestoreUtilities.getUserAccessibleResourcesOfType(
      "locations",
      this.afs,
      this.uiState.locations,
      [UserRoles.admin, UserRoles.contributor]
    ).subscribe((locations$) => {
      this.locations = locations$
        .filter((location) => !!location.active)
        .sort((a, b) => (a.locationId < b.locationId ? -1 : 1));
      this.loadingParameters = false;
    });
  }

  private getExistingReportShares() {
    this.afs
      .collection(`reportShares`, (ref) =>
        ref
          .where("report", "==", this.trendAnalysisReportId)
          .where("fromUser", "==", this.uiState.authUser.id)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((reportSharesQuery$) => {
        this.reportShares = <ReportShare[]>(
          FirestoreUtilities.mapToType(reportSharesQuery$)
        );
      });
  }

  private async listenForGroupChange() {
    this.selectedReportGroup.valueChanges.subscribe(async (selectedGroupId) => {
      if (selectedGroupId && this.existingReport.group !== selectedGroupId) {
        try {
          await this.afs
            .doc(`trendAnalysisReports/${this.trendAnalysisReportId}`)
            .update({
              group: selectedGroupId,
            });
          this.snackBar.open("Updated group setting successfully!", "Dismiss", {
            duration: 5000,
          });
          this.editingGroup = false;
        } catch (e) {
          console.error(e.message);
          this.snackBar.open(
            "Oops... something went wrong. Please refresh and try again",
            "Dismiss",
            { duration: 5000 }
          );
        }
      }
    });
  }

  private getExistingReport() {
    this.afs
      .doc(`trendAnalysisReports/${this.trendAnalysisReportId}`)
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (report$) => {
          this.existingReport = <TrendAnalysisReport>(
            FirestoreUtilities.objectToType(report$)
          );
          this.reportName.patchValue(this.existingReport.name);
          this.reportName.updateValueAndValidity();
          if (this.existingReport) {
            this.selectedReportGroup.patchValue(this.existingReport.group);
            this.selectedReportGroup.updateValueAndValidity();
            this.listenForGroupChange();
            this.setupParametersForm();
            this.getReportFragments();
          }
        },
        (e) => {
          console.error(e);
          this.snackBar.open(
            "Oops... something went wrong loading your report. Please refresh to try again.",
            "Dismiss",
            {
              duration: 5000,
            }
          );
        },
        () => {}
      );
  }

  private getReportFragments() {
    this.errorRunningReport = false;
    this.afs
      .collection("trendAnalysisFragments", (ref) =>
        ref
          .where("trendAnalysisReport", "==", this.existingReport.id)
          .where("client", "==", this.uiState.client.id)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result$) => {
          this.reportFragments = <TrendAnalysisFragment[]>(
            FirestoreUtilities.mapToType(result$)
          );
          this.completeFragments = this.reportFragments.filter(
            (fragment) => fragment.status === "done"
          );
          this.errorFragments = this.reportFragments.filter(
            (fragment) => fragment.status == "error"
          );
          this.loadingReportFragments = false;
          if (this.errorFragments.length > 0) {
            this.errorRunningReport = true;
          } else if (
            this.completeFragments.length > 0 &&
            this.completeFragments.length === this.reportFragments.length
          ) {
            this.getTrendAnalysisFragmentDataSets();
          }
        },
        (e) => {
          console.error(`FireStore - 'trendAnalysisFragments': ${e.message}`);
        }
      );
  }

  private async getTrendAnalysisFragmentDataSets() {
    this.loadingFragmentReports = true;
    from(
      this.reportFragments.map((fragment: TrendAnalysisFragment) => {
        return this.afs
          .collection("trendAnalysisFragmentDataSets", (ref) =>
            ref
              .where("trendAnalysisFragment", "==", fragment.id)
              .where("client", "==", fragment.client)
          )
          .snapshotChanges();
      })
    )
      .pipe(combineAll(), takeUntil(this.destroy$))
      .subscribe((dataSets) => {
        const fragmentDataSets =
          FirestoreUtilities.mergeCollectionToType(dataSets);
        this.dataPoints = _.flatten(
          fragmentDataSets.map((dataSet) => {
            return dataSet.dataPoints as TrendAnalysisDataPoint[];
          })
        ).sort((a, b) => {
          return moment(a.date.toDate()).isBefore(moment(b.date.toDate()))
            ? -1
            : 1;
        });
        this.dataPoints.forEach((dataPoint) => {
          dataPoint["dateFormatted"] = moment(dataPoint.date.toDate())
            .startOf("day")
            .format("YYYY-MM-DD");
        });
      });
    this.loadingFragmentReports = false;
    // this.fragmentDataSets = fragmentDataSetsByType;
  }
  public getTrendTypeName(trendType) {
    return TrendAnalysisUtilities.getTrendTypeName(trendType);
  }
  public groupAndRenderChart() {
    const groupBy = this.dataGroupControl.value;
    switch (groupBy) {
      case "3PDs":
        this.chartTitle = `Total ${this.getTrendTypeName(
          this.parametersForm.value.trendType
        )} By 3PD`;
        this.aggregateChartDataByLocations();
        break;
      case "Locations":
        this.chartTitle = `Total ${this.getTrendTypeName(
          this.parametersForm.value.trendType
        )} By Location`;
        this.aggregateChartDataBy3PDs();
        break;
    }
  }
  private aggregateChartDataByLocations() {
    this.chartData = [];
    const allDataByThirdParty = _.groupBy(this.dataPoints, "thirdParty");
    _.forEach(allDataByThirdParty, (value, thirdPartyKey) => {
      const thirdPartyDataByDate = _.groupBy(
        allDataByThirdParty[thirdPartyKey],
        "dateFormatted"
      );
      const aggregateSumPoints = _.map(
        thirdPartyDataByDate,
        (value, dateKey) => {
          const thirdPartyDateTransactions = thirdPartyDataByDate[dateKey];
          return [
            moment(dateKey).valueOf(),
            +thirdPartyDateTransactions
              .reduce((sum, dataPoint) => {
                return (sum += +dataPoint.dataPoint ? +dataPoint.dataPoint : 0);
              }, 0)
              .toFixed(2),
          ];
        }
      );
      this.chartData.push({
        name: this.getThirdPartyName(thirdPartyKey),
        data: aggregateSumPoints,
      });
    });
  }
  private aggregateChartDataBy3PDs() {
    this.chartData = [];
    const allDataByLocation = _.groupBy(this.dataPoints, "location");
    _.forEach(allDataByLocation, (value, locationId) => {
      const locationDataByDate = _.groupBy(
        allDataByLocation[locationId],
        "dateFormatted"
      );
      const aggregateSumPoints = _.map(locationDataByDate, (value, dateKey) => {
        const locationDateTransactions = locationDataByDate[dateKey];
        return [
          moment(dateKey).valueOf(),
          +locationDateTransactions
            .reduce((sum, dataPoint) => {
              return (sum += +dataPoint.dataPoint ? +dataPoint.dataPoint : 0);
            }, 0)
            .toFixed(2),
        ];
      });
      this.chartData.push({
        name: `${locationId}`,
        data: aggregateSumPoints,
      });
    });
  }
  private aggregateChartDataByEntity() {}
  async runReport() {
    this.chartData = null;
    this.dataPoints = null;
    setTimeout(async () => {
      if (this.parametersForm.valid) {
        this.loadingService.isLoading(true, "Creating report job...");
        const newParameters = this.parametersForm.value;
        try {
          if (this.reportFragments.length > 0) {
            await this.clearExistingFragments();
          }
          await this.updateReport();
          const selectedLocationIds = newParameters.selectedLocations;
          const selectedTrendType = newParameters.trendType;
          const selectedThirdPartyIds =
            newParameters.selectedThirdParties.filter((id) => id !== 0); // filter out 0 to account for multi select
          const start = moment(newParameters.startDate).startOf("day").toDate();
          const end = moment(newParameters.endDate).endOf("day").toDate();
          const locationsChunks = _.chunk(selectedLocationIds, 10);
          await Promise.all(
            locationsChunks.map(async (locationsChunk: string[]) => {
              await Promise.all(
                selectedThirdPartyIds.map(async (thirdPartyId) => {
                  const newFragment = new TrendAnalysisFragment();
                  newFragment.client = this.uiState.client.id;
                  newFragment.endDate = end;
                  newFragment.startDate = start;
                  newFragment.locations = locationsChunk;
                  newFragment.thirdParty = thirdPartyId;
                  newFragment.trendType = selectedTrendType;
                  newFragment.trendAnalysisReport = this.existingReport.id;
                  return this.afs
                    .collection("trendAnalysisFragments")
                    .add(newFragment.toJSONObject());
                })
              );
            })
          );
          this.loadingService.isLoading(false);
          this.reportRunningInBackground = true;
        } catch (e) {
          console.error(
            `Create Report Fragments - 'trendAnalysisFragments', ${e.message}`
          );
          this.snackBar.open(
            "Error Creating Report. Please refresh the page and try again.",
            "Dismiss",
            { duration: 5000 }
          );
          this.loadingService.isLoading(false);
        }
      } else {
        this.snackBar.open(
          "Please select all required parameters before running the report.",
          "Dismiss",
          { duration: 5000 }
        );
      }
    });
  }
  private async clearExistingFragments() {
    const fragmentChunks = _.chunk(this.reportFragments, 500);
    await Promise.all(
      fragmentChunks.map((fragmentChunk) => {
        const deleteBatch = this.afs.firestore.batch();
        fragmentChunk.forEach((fragment) => {
          deleteBatch.delete(
            this.afs.doc(`trendAnalysisFragments/${fragment.id}`).ref
          );
        });
        return deleteBatch.commit();
      })
    );
  }

  public isUserAccessRestricted() {
    return (
      this.existingReport.creator === this.uiState?.authUser.id ||
      this.uiState.clientRole < 2
    );
  }

  public getReportProgressPercentage() {
    if (this.completeFragments.length > 0) {
      return +(
        (this.completeFragments.length / this.reportFragments.length) *
        100
      ).toFixed(0);
    }
    return 0;
  }

  selectAllThirdParties() {
    if (!this.allThirdPartiesSelected) {
      this.allThirdPartiesSelected = true;
      this.parametersForm
        .get("selectedThirdParties")
        .patchValue([0, ...this.thirdParties.map((tp) => tp.id)]);
    } else {
      this.allThirdPartiesSelected = false;
      this.parametersForm.get("selectedThirdParties").patchValue([]);
    }
    this.parametersForm.get("selectedThirdParties").updateValueAndValidity();
  }

  private async updateReport() {
    if (this.parametersForm.valid) {
      const formValues = this.parametersForm.value;
      const dateTimeCapture = moment().toDate();
      await this.afs
        .doc(`trendAnalysisReports/${this.existingReport.id}`)
        .update({
          locations: formValues.selectedLocations,
          thirdParties: formValues.selectedThirdParties,
          startDate: moment(formValues.startDate).toDate(),
          endDate: moment(formValues.endDate).toDate(),
          status: ThirdPartyReportStatuses.running,
          lastRun: dateTimeCapture,
          trendType: formValues.trendType,
          dateUpdated: dateTimeCapture,
        });
      this.snackBar.open("Report updated successfully.", "Dismiss", {
        duration: 5000,
      });
    } else {
      this.snackBar.open("Please complete all required report parameters.");
    }
  }
  async saveReportName() {
    if (this.reportName.valid) {
      await this.afs
        .doc(`trendAnalysisReports/${this.existingReport.id}`)
        .update({
          name: this.reportName.value,
        });
      this.snackBar.open("Report name updated successfully.", "Dismiss", {
        duration: 5000,
      });
    } else {
      this.snackBar.open("Please provide a name for the report.", "Dismiss", {
        duration: 5000,
      });
    }
  }

  getThirdPartyName(thirdPartyId: string) {
    if (thirdPartyId) {
      const tp = this.thirdParties.find(
        (_tp) => !!_tp && _tp.id === thirdPartyId
      );
      return tp ? tp.name : "";
    }
    return "";
  }

  getLocationName(locationId: string) {
    const location = this.locations.find((l) => l.locationId === locationId);
    return location ? location.name : "";
  }

  getReportGroupName() {
    const reportGroup = this.reportGroups.find(
      (group) => group.id === this.existingReport.group
    );
    return reportGroup ? `${reportGroup.name}` : "Un-Grouped";
  }

  private setupParametersForm() {
    this.parametersForm = this.fb.group({
      selectedThirdParties: new FormControl(
        this.existingReport.thirdParties
          ? this.existingReport.thirdParties
          : [],
        Validators.required
      ),
      selectedLocations: new FormControl(
        this.existingReport.locations ? this.existingReport.locations : [],
        Validators.required
      ),
      trendType: new FormControl(
        this.existingReport.trendType ? this.existingReport.trendType : "",
        Validators.required
      ),
      startDate: new FormControl(
        this.existingReport.startDate
          ? this.existingReport.startDate.toDate()
          : [],
        Validators.required
      ),
      endDate: new FormControl(
        this.existingReport.endDate ? this.existingReport.endDate.toDate() : [],
        Validators.required
      ),
    });
  }

  getReportLocations() {
    return this.locations.filter((location) =>
      this.parametersForm
        .get("selectedLocations")
        .value.find((locationId) => locationId === location.locationId)
    );
  }

  getReportThirdParties() {
    return this.thirdParties.filter((tp) =>
      this.parametersForm
        .get("selectedThirdParties")
        .value.find((tpid) => tpid === tp.id)
    );
  }
  getReportMinDate() {
    return moment(this.parametersForm.get("startDate").value).toDate();
  }

  getReportMaxDate() {
    return moment(this.parametersForm.get("endDate").value).toDate();
  }

  getMaxEndDate() {
    if (this.parametersForm.get("startDate").valid) {
      return moment(this.parametersForm.get("startDate").value)
        .add(6, "month")
        .toDate();
    }
  }

  dateMinimum(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }
      const controlDate = moment(this.parametersForm.get("startDate").value);
      if (!controlDate.isValid()) {
        return null;
      }
      const validationDate = moment(date);
      return controlDate.isSameOrBefore(validationDate)
        ? null
        : {
            "date-minimum": {
              "date-minimum": validationDate.toDate(),
              actual: controlDate.toDate(),
            },
          };
    };
  }

  openShareReportDialog() {
    const dialogRef = this.dialog.open(TeamMemberSelectorDialogComponent, {
      panelClass: "invisible-panel-dialog",
      data: {
        client: this.uiState.client,
        report: this.existingReport,
        excludedTeamMembers: this.reportShares.map(
          (reportShare) => reportShare.toUser
        ),
      },
    });
    dialogRef.afterClosed().subscribe(async (teamMemberIds) => {
      if (teamMemberIds) {
        await Promise.all(
          teamMemberIds.map((teamMemberId) => {
            const reportShare = new ReportShare();
            reportShare.client = this.uiState.client.id;
            reportShare.fromUser = this.uiState.authUser.id;
            reportShare.toUser = teamMemberId;
            reportShare.report = this.existingReport.id;
            reportShare.type = ReportGroupTypes.trendAnalysis;
            return this.afs
              .collection("reportShares")
              .add(reportShare.toJSONObject());
          })
        );
        this.snackBar.open(
          `${this.existingReport.name} shared with ${
            teamMemberIds.length
          } team member${teamMemberIds.length > 1 ? "s" : ""} successfully `,
          "Dismiss",
          { duration: 5000 }
        );
      }
    });
  }
}
