import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import {
  ThirdPartyCategoryReport,
  ThirdPartyReportFragmentFeeReport,
} from "@deliver-sense-librarian/data-schema";
import { Papa } from "ngx-papaparse";
import { Subject } from "rxjs";
import { TransactionsDialogComponent } from "app/dialogs/transactions-dialog/transactions-dialog.component";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import {
  ReconciliationDrillDownReportTypes,
  ReconciliationReportDatUtility,
} from "../../utilities/reconciliation-report-data.utility";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { downloadDataAsFile } from "app/shared/ds-constant";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import firebase from "firebase/app";
import * as _ from "lodash";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import { first } from "rxjs/operators";
import { ReconciliationReportFilteringUtility } from "../../utilities/reconciliation-report-filtering.utility";
@Component({
  selector: "app-fee-analysis",
  templateUrl: "./fee-analysis.component.html",
  styleUrls: ["./fee-analysis.component.scss"],
})
export class FeeAnalysisComponent implements OnInit {
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() exportUtility: ReconciliationReportExportUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    "location",
    "thirdParty",
    "feeType",
    "feeAmount",
    "transactions",
    "netSales",
    "netTax",
    "totalFees",
  ];
  thirdPartyReportFragmentFeeReports: ThirdPartyReportFragmentFeeReport[] = [];
  public transactionData: any[];
  destroy$ = new Subject();
  loadingFeeAnalysis = false;
  constructor(
    private afs: AngularFirestore,
    private cdr: ChangeDetectorRef,
    private papa: Papa,
    private snackBar: MatSnackBar,
    private loadingService: LoadingDialogService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.intializeDataAndTable();
  }

  ngOnDestroy(): void {}

  ngAfterViewChecked(): void {}

  private async intializeDataAndTable() {
    this.loadingFeeAnalysis = true;
    this.thirdPartyReportFragmentFeeReports =
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.feeReports
      );
    this.thirdPartyReportFragmentFeeReports.forEach(
      (feeReport) =>
        (feeReport["thirdPartyName"] = this.dataUtility.getThirdPartyName(
          feeReport.thirdParty
        ))
    );
    this.loadingFeeAnalysis = false;

    this.tableData = new MatTableDataSource(
      this.thirdPartyReportFragmentFeeReports
    );
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.cdr.detectChanges();
  }

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  public async openTransactionViewer(report: ThirdPartyCategoryReport) {
    const transactionIds = report.transactions as string[];
    if (transactionIds.length > 0) {
      this.loadingService.isLoading(true, "Finding transactions...");
      const transactionIdBatches = _.chunk(transactionIds, 10);
      try {
        const transactions = _.flatten(
          await Promise.all(
            transactionIdBatches.map((transactionIds) => {
              return this.afs
                .collection("thirdPartyTransactions", (ref) =>
                  ref.where(
                    firebase.firestore.FieldPath.documentId(),
                    "in",
                    transactionIds
                  )
                )
                .snapshotChanges()
                .pipe(first())
                .toPromise();
            })
          )
        );
        this.loadingService.isLoading(false);
        this.dialog.open(TransactionsDialogComponent, {
          panelClass: "invisible-panel-dialog",
          data: {
            transactions: FirestoreUtilities.mapToType(transactions),
          },
        });
      } catch (e) {
        this.loadingService.isLoading(false);
        this.snackBar.open(
          `Could not find transactions. Please refresh and try again`,
          "Dismiss",
          { duration: 5000 }
        );
      }
    }
  }
  exportReport() {
    const data = Object.assign(
      this.tableData.data.map((data) => {
        return {
          Location: data.location,
          "Third Party": data["thirdPartyName"],
          "Fee Type": data.feeType,
          "Fee Amount %": data.feeAmount > 0 ? `${data.feeAmount * 100}%` : 0,
          Transactions: data.transactions ? data.transactions.length : 0,
          "Net Sales": data.netSales,
          "Net Tax": data.netTax,
          "Total Fees": data.totalFees,
        };
      })
    );
    const results = this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const fileName = `${this.dataUtility.existingReport.name}-Fee-Drill-Down`;
    downloadDataAsFile(results, fileName, "csv");
  }
}
