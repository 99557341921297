import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ReconciliationReportDatUtility } from "../../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../../utilities/reconciliation-report-filtering.utility";
import { FormControl } from "@angular/forms";
import { AfterViewInit } from "@angular/core";
import { ClientInvoicesComponent } from "../../../../../organization/clients/client-invoices/client-invoices.component";

@Component({
  selector: "app-reconciliation-summary-table",
  templateUrl: "./reconciliation-summary-table.component.html",
  styleUrls: ["./reconciliation-summary-table.component.scss"],
})
export class ReconciliationSummaryTableComponent
  implements OnInit, AfterViewInit
{
  @Input() data: any[] = [];
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @Input() filterLocationsControl: FormControl;
  @Output() jumpToDailyDrillDown = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [];

  public filter3pdsControl = new FormControl([]);
  public;
  constructor(private dialog: MatDialog) {}
  ngOnInit(): void {
    if (this.dataUtility.existingReport.includePosInRec) {
      this.displayedColumns = [
        "location",
        "thirdParty",
        "posSales",
        "dspSales",
        "salesVariance",
        "errorCharges",
        "cancelledOrders",
        "remainingVariance",
        "posTax",
        "dspTax",
        "taxVariance",
        "rateError",
        "mfSales",
        "mfTax",
        "partialCollection",
        "deliveryFees",
        "pickupFees",
        "promoFees",
        "cateringFees",
        "totalFees",
        "averageFee",
        "campaignPromoFees",
      ];
    } else {
      this.displayedColumns = [
        "location",
        "dspSales",
        "errorCharges",
        "allCancelations",
        "dspTax",
        "mfSales",
        "mfTax",
        "partialCollection",
        "deliveryFees",
        "pickupFees",
        "promoFees",
        "cateringFees",
        "totalFees",
        "averageFee",
        "campaignPromoFees",
      ];
    }
    this.tableData = new MatTableDataSource(
      this.data.sort((a, b) => {
        return a.remainingVariance === 0
          ? 1
          : a.remainingVariance > b.remainingVariance
          ? -1
          : 1;
      })
    );
  }
  ngAfterViewInit(): void {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;

    this.filteringUtility.set3pdAndLocationFilterPredicate(
      this.tableData,
      this.filter3pdsControl,
      this.filterLocationsControl
    );
  }
  getTotalForColumn(column: string) {
    return this.tableData.filteredData.reduce((sum, row) => {
      return (sum += +row[column] ? +row[column] : 0);
    }, 0);
  }
}
