import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  AfterViewInit,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ThirdParty, Location } from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { TransactionsDialogComponent } from "app/dialogs/transactions-dialog/transactions-dialog.component";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import {
  downloadDataAsFile,
  tableExpandAnimation,
} from "app/shared/ds-constant";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import moment from "moment";
import { Papa } from "ngx-papaparse";
import { first } from "rxjs/operators";
import { ThirdPartyDeliveryAnalyticsEngine } from "../../../../third-party-delivery-analytics-engine";

@Component({
  selector: "app-transaction-drill-down-table",
  templateUrl: "./transaction-drill-down-table.component.html",
  styleUrls: ["./transaction-drill-down-table.component.scss"],
  animations: tableExpandAnimation,
})
export class TransactionDrillDownTableComponent
  implements OnInit, AfterViewInit
{
  @Input() location: Location;
  @Input() thirdParty: ThirdParty;
  @Input() transactionMatches = [];
  @Input() reportTitle: string;
  @Input() analyticsEngine: ThirdPartyDeliveryAnalyticsEngine;
  @Output() reset = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    "posTransactionDate",
    "posTransactionId",
    "posTransactionSale",
    "posTransactionTax",
    "Match%",
    "thirdPartyTransactionDate",
    "thirdPartyTransactionId",
    "thirdPartyTransactionSale",
    "thirdPartyTransactionTax",
    "thirdPartyTransactionStatus",
    "thirdPartyTransactionType",
  ];

  constructor(
    private afs: AngularFirestore,
    private cdr: ChangeDetectorRef,
    private papa: Papa,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.tableData = new MatTableDataSource(this.transactionMatches);
  }
  ngAfterViewInit(): void {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.cdr.detectChanges();
  }

  async viewTransactionMatch(row) {
    const posTransaction = row.posTransaction
      ? FirestoreUtilities.objectToType(
          await this.afs
            .doc(`posTransactions/${row.posTransaction.id}`)
            .snapshotChanges()
            .pipe(first())
            .toPromise()
        )
      : null;
    const tpdTransaction = row.thirdPartyTransaction
      ? FirestoreUtilities.objectToType(
          await this.afs
            .doc(`thirdPartyTransactions/${row.thirdPartyTransaction.id}`)
            .snapshotChanges()
            .pipe(first())
            .toPromise()
        )
      : null;
    const transactions = [posTransaction, tpdTransaction].filter(
      (transaction) => !!transaction
    );
    this.dialog.open(TransactionsDialogComponent, {
      panelClass: "invisible-panel-dialog",
      data: {
        transactions,
      },
    });
  }

  async viewTransaction(transactionId, type: "POS" | "3PD") {
    const transaction = FirestoreUtilities.objectToType(
      await this.afs
        .doc(
          `${
            type === "POS" ? "posTransactions" : "thirdPartyTransactions"
          }/${transactionId}`
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise()
    );
    this.dialog.open(TransactionsDialogComponent, {
      panelClass: "invisible-panel-dialog",
      data: {
        transactions: [transaction],
      },
    });
  }
  exportReport() {
    const data = this.tableData.data.map((row) => {
      const exportRow = {
        location: this.location.locationId,
        "3PD": this.thirdParty.name,
        Date: row.posTransaction?.date
          ? moment(row.posTransaction.date.toDate()).format("MM/DD/YYYY")
          : moment(row.thirdPartyTransaction.date.toDate()).format(
              "MM/DD/YYYY"
            ),
      };
      if (row.posTransaction) {
        exportRow["POS Transaction Id"] = row.posTransaction.transactionId;
        exportRow["POS Sale"] = row.posTransaction.sale;
        exportRow["POS Tax"] = row.posTransaction.tax;
      }
      exportRow["Match %"] =
        row["Match %"] > 0 ? row["Match %"] + "%" : "No Match Found";
      if (row.thirdPartyTransaction) {
        exportRow["3PD Transaction Id"] =
          row.thirdPartyTransaction.transactionId;
        exportRow["3PD Sale"] = row.thirdPartyTransaction.sale;
        exportRow["3PD Tax"] = row.thirdPartyTransaction.tax;
        exportRow["3PD Status"] = row.thirdPartyTransaction.status;
        exportRow["3PD Type"] = row.thirdPartyTransaction.transactionType;
      }
      return exportRow;
    });
    const results = this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const fileName = `${this.reportTitle}_Transaction-Matching`;
    downloadDataAsFile(results, fileName, "csv");
  }

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
}
