import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  Entity,
  EntityThirdParty,
  Location,
  ThirdParty,
} from "@deliver-sense-librarian/data-schema";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FirestoreUtilities } from "../../../../utilities/firestore-utilities";

@Component({
  selector: "app-entity-third-parties",
  templateUrl: "./entity-third-parties.component.html",
  styleUrls: ["./entity-third-parties.component.scss"],
})
export class EntityThirdPartiesComponent implements OnInit, OnDestroy {
  @Input() thirdParties: ThirdParty[];
  @Input() locations: Location[];
  @Input() entity: Entity;
  selectedThirdPartyControl = new FormControl();
  thirdPartyRateForm: FormGroup;
  selectedThirdParty: ThirdParty;
  entityThirdParties: EntityThirdParty[];
  destroy$ = new Subject();
  existingSelectedEntityThirdParty: EntityThirdParty;
  constructor(
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private afs: AngularFirestore
  ) {}

  ngOnInit(): void {
    this.setupThirdPartySelectionListener();
    this.fetchEntityThirdParties();
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  private fetchEntityThirdParties() {
    this.afs
      .collection("entityThirdParties", (ref) =>
        ref
          .where("entity", "==", this.entity.id)
          .where("client", "==", this.entity.client)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((queryResults$) => {
        this.entityThirdParties = <EntityThirdParty[]>(
          FirestoreUtilities.mapToType(queryResults$)
        );
        if (this.selectedThirdParty) {
          this.existingSelectedEntityThirdParty = this.entityThirdParties.find(
            (etp) => etp.thirdParty === this.selectedThirdParty.id
          );
          this.setupThirdPartyForm();
        }
      });
  }
  setupThirdPartySelectionListener() {
    this.selectedThirdPartyControl.valueChanges.subscribe(
      (selectedThirdParty$) => {
        this.selectedThirdParty = selectedThirdParty$;
        this.setupThirdPartyForm();
      }
    );
  }
  private setupThirdPartyForm() {
    this.thirdPartyRateForm = null;
    this.existingSelectedEntityThirdParty = this.entityThirdParties?.find(
      (etp) => etp.thirdParty === this.selectedThirdParty.id
    );
    setTimeout(() => {
      this.thirdPartyRateForm = this.fb.group({
        deliveryFee: new FormControl(
          this.existingSelectedEntityThirdParty?.deliveryFee
            ? this.existingSelectedEntityThirdParty.deliveryFee
            : 0
        ),
        pickupFee: new FormControl(
          this.existingSelectedEntityThirdParty?.pickupFee
            ? this.existingSelectedEntityThirdParty.pickupFee
            : 0
        ),
        promoFee: new FormControl(
          this.existingSelectedEntityThirdParty?.promoFee
            ? this.existingSelectedEntityThirdParty.promoFee
            : 0
        ),
        cateringFee: new FormControl(
          this.existingSelectedEntityThirdParty?.cateringFee
            ? this.existingSelectedEntityThirdParty.cateringFee
            : 0
        ),
        customFee: new FormControl(
          this.existingSelectedEntityThirdParty?.customFee
            ? this.existingSelectedEntityThirdParty.customFee
            : 0
        ),
      });
    });
  }
  public async saveEntityThirdParty() {
    this.loadingService.isLoading(true, "Saving...");
    const formValues: {
      deliveryFee;
      pickupFee;
      promoFee;
      cateringFee;
      customFee;
    } = this.thirdPartyRateForm.value;
    let successMessage;
    if (this.existingSelectedEntityThirdParty) {
      await this.updateEntityThirdParty(formValues);
      successMessage = `Successfull updated ${this.selectedThirdParty.name} rates for ${this.entity.name}!`;
    } else {
      await this.createEntityThirdParty(formValues);
      successMessage = `Successfull created ${this.selectedThirdParty.name} rates for ${this.entity.name}!`;
    }
    this.snackBar.open(successMessage, "Dismiss", { duration: 5000 });
    this.loadingService.isLoading(false);
  }

  private async updateEntityThirdParty(formValues: {
    deliveryFee;
    pickupFee;
    promoFee;
    cateringFee;
    customFee;
  }) {
    return this.afs
      .doc(`entityThirdParties/${this.existingSelectedEntityThirdParty.id}`)
      .update({
        deliveryFee: formValues.deliveryFee,
        pickupFee: formValues.pickupFee,
        promoFee: formValues.promoFee,
        cateringFee: formValues.cateringFee,
        customFee: formValues.customFee,
      });
  }
  private async createEntityThirdParty(formValues: {
    deliveryFee;
    pickupFee;
    promoFee;
    cateringFee;
    customFee;
  }) {
    return this.afs.collection("entityThirdParties").add({
      entity: this.entity.id,
      thirdParty: this.selectedThirdParty.id,
      client: this.entity.client,
      deliveryFee: formValues.deliveryFee,
      pickupFee: formValues.pickupFee,
      promoFee: formValues.promoFee,
      cateringFee: formValues.cateringFee,
      customFee: formValues.customFee,
    });
  }
  async applyRatesToEntityLocations() {
    this.loadingService.isLoading(
      true,
      `Updating ${this.selectedThirdParty.name} rates for ${this.entity.name} locations`
    );
    try {
      await Promise.all(
        this.locations.map((location) => {
          const locationThirdParties = Object.assign(location.thirdParties);
          const locationThirdParty = locationThirdParties.find(
            (dsp) => dsp.id == this.selectedThirdParty.id
          );
          const updateObject = {
            thirdParty: this.selectedThirdParty.id,
            active: locationThirdParty ? locationThirdParty.active : false,
            ...this.thirdPartyRateForm.value,
          };
          if (locationThirdParty) {
            const locationThirdPartyIndex =
              locationThirdParties.indexOf(locationThirdParty);
            locationThirdParties.splice(
              locationThirdPartyIndex,
              1,
              updateObject
            );
          } else {
            locationThirdParties.push(updateObject);
          }
          return this.afs.doc(`locations/${location.id}`).update({
            thirdParties: locationThirdParties,
          });
        })
      );
    } catch (e) {
      console.log(e);
      this.snackBar.open(
        "Error setting location third party rates. Please refresh and try again.",
        "Dismiss",
        { duration: 5000 }
      );
    } finally {
      this.loadingService.isLoading(false);
      this.snackBar.open(
        `${this.selectedThirdParty.name} rates applied to ${this.entity.name} locations successfully`,
        "Dismiss",
        { duration: 5000 }
      );
    }
  }
}
