import { UploadDocumentService } from "../../services/upload-document.service";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from "@angular/core";
import { LoadingDialogService } from "../../services/loading-dialog.service";
import { AngularFirestore } from "@angular/fire/firestore";

import { Subject } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
})
export class FileUploadComponent implements OnInit, OnDestroy {
  @Input() storagePath: string;
  @Input() compressFile = true;
  @Input() returnFile = false;
  @Output() fileCaptured = new EventEmitter();
  @Output() imageUploadComplete = new EventEmitter();
  @Output() imageUploadFailed = new EventEmitter();
  private imageFileName;
  private destroy$ = new Subject();
  private progress = { progress: 0 };

  constructor(
    private loadingService: LoadingDialogService,
    private uploadDocumentService: UploadDocumentService,
    private afs: AngularFirestore,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {}
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  async detectFiles(event) {
    const files =
      event && event[0]
        ? event
        : event.target.files && event.target.files
        ? event.target.files
        : null;
    if (files) {
      if (!this.returnFile) {
        this.loadingService.isLoading(true, "Uploading File", this.progress);
        const name = files[0].name;
        const lastDot = name.lastIndexOf(".");
        const ext = name.substring(lastDot + 1);
        this.imageFileName = this.afs.createId() + "." + ext;
        if (ext === "pdf" || !this.compressFile) {
          try {
            const uploadResult = await this.uploadFilesToStorage(
              files[0],
              this.progress
            );
            this.loadingService.isLoading(false);
            return uploadResult;
          } catch (e) {
            console.log(`error: ${e.message}`);
            this.loadingService.isLoading(false);
            this.snackBar.open(
              "Oops... something went wrong with the upload. Please refresh and try agin"
            );
          }
        } else {
          // @ts-ignore
          // FileUploadUtilities.getOrientation(event.target.files[0], (orientation) => {
          //   event.target.files[0]['orientation'] = orientation;
          // });
          return this.uploadFilesToStorage(files[0], this.progress);
        }
      } else {
        this.fileCaptured.emit(files[0]);
      }
    }
  }

  private async uploadFilesToStorage(file, progress?: { progress }) {
    try {
      const filePath = this.storagePath + "/" + this.imageFileName;
      const uploadResult = await this.uploadDocumentService.uploadSingle(
        file,
        filePath,
        progress,
        this.destroy$
      );
      uploadResult.fileName = `${file.name}`;
      this.imageUploadComplete.emit(uploadResult);
      return uploadResult;
    } catch (e) {
      console.error(e);
      this.imageUploadFailed.emit(true);
    }
  }
}
