import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Client, User } from "@deliver-sense-librarian/data-schema";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import _ from "lodash";

@Component({
  selector: "app-client-developer",
  templateUrl: "./client-developer.component.html",
  styleUrls: ["./client-developer.component.scss"],
})
export class ClientDeveloperComponent implements OnInit {
  @Input() client: Client;
  @Input() user: User;

  secretType = "password";
  liveKeyType = "password";

  constructor(
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  copySuccessAlert() {
    this.snackBar.open("Content copied to your clipboard!", "Dismiss", {
      duration: 5000,
    });
  }

  //NOT IN USE YET
  public updateSfaRequired($event: MatCheckboxChange) {
    this.afs
      .doc(`clients/${this.client.id}`)
      .update({
        sfaRequired: $event.checked,
      })
      .then(() => {
        const message = $event.checked
          ? "SFA now required for authentication."
          : "SFA has been disabled.";
        this.snackBar.open(message, "Dismiss", {
          duration: 5000,
        });
      })
      .catch((e) => {
        this.snackBar.open(
          "Oops... something went wrong. Please refresh and check your authorizations with this client.",
          "Dismiss",
          {
            duration: 5000,
          }
        );
      });
  }
}
