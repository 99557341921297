import {uiState} from "../redux/custom-states/uiState/uiStateReducer";


export function getArdConfig() {
    return {
        effects: [],
        entityNameSpaces: [],
        defaultHost: '',
        defaultPath: '',
        entityAdapterMappings: {},
        customReducers: {
            'uiState': uiState
        }
    }
}
