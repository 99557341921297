import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  Client,
  Entity,
  User,
  UserRoles,
} from "@deliver-sense-librarian/data-schema";
import { Subject, combineLatest } from "rxjs";
import { Store } from "@ngrx/store";
import { AngularFirestore } from "@angular/fire/firestore";
import { takeUntil } from "rxjs/operators";
import { FirestoreUtilities } from "../../../../utilities/firestore-utilities";
import { AfterViewInit } from "@angular/core";

@Component({
  selector: "app-entities",
  templateUrl: "./entities.component.html",
  styleUrls: ["./entities.component.scss"],
})
export class EntitiesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<Entity>;
  public displayedColumns: string[] = ["name", "address"];
  private user: User;
  private destroy$ = new Subject();
  private initialized$ = new Subject();
  public client: Client;
  private entities: Entity[] = [];
  userEntities: any;

  constructor(private store: Store<any>, private afs: AngularFirestore) {}

  ngOnInit() {
    combineLatest([
      this.store.select((store) => store.uiState.authUser),
      this.store.select((store) => store.uiState.client),
      this.store.select((store) => store.uiState.entities),
    ])
      .pipe(takeUntil(this.initialized$))
      .subscribe(([user$, client$, entities$]) => {
        if (user$ && client$ && entities$?.length > 0) {
          this.user = user$;
          this.client = client$;
          this.userEntities = entities$;
          this.initialized$.next(true);
          this.initialized$.complete();
          this.getEntities();
        }
      });
  }
  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * Get team members
   * Get team member current client roles
   * Get team member entites and roles
   * Get team member departments and roles
   * @private
   */
  private async getEntities() {
    if (this.user.internalRole > 1) {
      await this.getAllClientEntities();
    } else {
      await this.getUserAccessibleEntities();
    }
  }
  async getAllClientEntities() {
    this.afs
      .collection("entities", (ref) =>
        ref.where("client", "==", this.client.id)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((query$) => {
        this.entities = FirestoreUtilities.mapToType(query$);
        this.tableData = new MatTableDataSource(this.entities);
      });
  }
  async getUserAccessibleEntities() {
    FirestoreUtilities.getUserAccessibleResourcesOfType(
      "entities",
      this.afs,
      this.userEntities,
      [UserRoles.admin, UserRoles.contributor, UserRoles.viewer]
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe((entities$) => {
        this.entities = entities$;
        if (this.entities && this.entities.length > 0) {
          this.entities = this.entities.filter(
            (entity) => !!entity && entity.client === this.client.id
          );
          this.tableData = new MatTableDataSource(this.entities);
          this.tableData.paginator = this.paginator;
          this.tableData.sort = this.sort;
        }
      });
  }
  isUserEntityAdmin(entityId) {
    if (this.user.internalRole > 1) {
      return true;
    }
    const entityRole = this.userEntities.find(
      (userEntity) => userEntity.resource === entityId
    );
    return entityRole && entityRole.role >= 3;
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
}
