import { Injectable, OnInit } from "@angular/core";
import { FirebaseAuthService } from "app/auth/services/firebase-auth.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { AngularFireAuth } from "@angular/fire/auth";
import { flatMap, map, take } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { UiState } from "../redux/custom-states/uiState/ui-state";
import { Client } from "@deliver-sense-librarian/data-schema";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";

@Injectable()
export class StripeService implements OnInit {
  uiState: UiState;

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private afs: AngularFirestore,
    private store: Store<any>,
    public auth: FirebaseAuthService,
    private afAuth: AngularFireAuth
  ) {
    this.store
      .select((store) => store.uiState)
      .subscribe((uiState$) => {
        if (uiState$.authUser && uiState$.client) {
          this.uiState = uiState$;
        }
      });
  }

  ngOnInit() {}

  private getToken(token): HttpHeaders {
    return new HttpHeaders().set("Authorization", `Bearer ${token}`);
  }

  public createStripePaymentMethod(paymentMethod) {
    return this.afAuth.idToken.pipe(
      take(1),
      map((token) => token),
      flatMap((token) => {
        const url = `${environment.apiUrl}paymentMethods`;
        const headerObj = { headers: this.getToken(token) };
        return this.http
          .post(
            url,
            {
              paymentMethodId: paymentMethod.id,
              clientId: this.uiState.client.id,
            },
            headerObj
          )
          .pipe(
            map((urlRes) => {
              return urlRes;
            })
          );
      })
    );
  }

  public getClientAutoPayMethod(client) {
    return this.afAuth.idToken.pipe(
      take(1),
      map((token) => token),
      flatMap((token) => {
        const url = `${environment.apiUrl}paymentMethods/${client.autoPayMethod}?clientId=${client.id}`;
        const headerObj = { headers: this.getToken(token) };
        return this.http.get(url, headerObj).pipe(
          map((urlRes) => {
            return urlRes;
          })
        );
      })
    );
  }

  public updateClientEmail(client: Client, newEmail: string) {
    return this.afAuth.idToken.pipe(
      take(1),
      map((token) => token),
      flatMap((token) => {
        const url = `${environment.apiUrl}customers/${client.billingId}`;
        const headerObj = { headers: this.getToken(token) };
        return this.http.patch(url, { email: newEmail }, headerObj).pipe(
          map((urlRes) => {
            return urlRes;
          })
        );
      })
    );
  }
  public getClientInvoiceCredits(client: Client) {
    return this.afAuth.idToken.pipe(
      take(1),
      map((token) => token),
      flatMap((token) => {
        const url = `${environment.apiUrl}clientInvoiceCredits?clientId=${client.id}`;
        const headerObj = { headers: this.getToken(token) };
        return this.http.get(url, headerObj).pipe(
          map((urlRes) => {
            return urlRes;
          })
        );
      })
    );
  }
  public getTaxRate(clientId, taxRateId) {
    return this.afAuth.idToken.pipe(
      take(1),
      map((token) => token),
      flatMap((token) => {
        const url = `${environment.apiUrl}taxRates/${taxRateId}?clientId=${clientId}`;
        const headerObj = { headers: this.getToken(token) };
        return this.http.get(url, headerObj).pipe(
          map((urlRes) => {
            return urlRes;
          })
        );
      })
    );
  }
}
