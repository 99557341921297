import {
  ThirdParty,
  Location,
  PriorPeriodAdjustmentTransaction,
  ThirdPartyReportFragmentVarianceAnalysisReport,
  TransactionAITypes,
  ThirdPartyReconciliationLocationData,
  ThirdPartyDeliveryIds,
} from "@deliver-sense-librarian/data-schema";
import { Workbook, Worksheet } from "exceljs";
import {
  ReconciliationDrillDownReportTypes,
  ReconciliationReportDatUtility,
} from "../reconciliation-report-data.utility";
import moment from "moment";

const headerRow = [
  "GL ACCOUNT",
  "DEBIT",
  "CREDIT",
  "REFCODE",
  "DOCUMENT",
  "DESCRIPTION",
  "",
  "",
  "",
  "BU",
  "ACCOUNT",
];
export async function compileBojanglesJEs(
  recSummaryWorkbook: Workbook,
  dataUtility: ReconciliationReportDatUtility
) {
  const thirdPartyReportVarianceAnalysisReports =
    await dataUtility.fetchReconciliationReportDrillDownFragments(
      ReconciliationDrillDownReportTypes.varianceAnalysisReports
    );
  const ppaTransactions = await dataUtility.fetchPriorPeriodAdjustments(
    dataUtility.locations
  );
  const cancelationsJeSheet = recSummaryWorkbook.addWorksheet(
    "DSP - Cancellations JE"
  );
  createHeaderSection(
    cancelationsJeSheet,
    dataUtility,
    "Delivery Cancellations"
  );
  //
  const adjustmentsSheet = recSummaryWorkbook.addWorksheet(
    "DSP - Adjustments JE"
  );
  createHeaderSection(adjustmentsSheet, dataUtility, "Delivery Adjustments");
  //
  const oloSalesVarJeSheet = recSummaryWorkbook.addWorksheet(
    "Olo - Sales Variance JE"
  );
  createHeaderSection(oloSalesVarJeSheet, dataUtility, "Olo Sales Variance");
  //
  const mfJeSheet = recSummaryWorkbook.addWorksheet(
    "Marketplace Facilitator JE"
  );
  createHeaderSection(
    mfJeSheet,
    dataUtility,
    "Delivery Marketplace Facilitator"
  );
  //
  const feesJeSheet = recSummaryWorkbook.addWorksheet("Commission JE");
  createHeaderSection(feesJeSheet, dataUtility, "Delivery Commission");

  compileCancellationsJe(
    cancelationsJeSheet,
    dataUtility,
    thirdPartyReportVarianceAnalysisReports,
    ppaTransactions
  );
  compileAdjustmentsJe(
    adjustmentsSheet,
    dataUtility,
    thirdPartyReportVarianceAnalysisReports,
    ppaTransactions
  );
  compileOloVarJe(
    oloSalesVarJeSheet,
    dataUtility,
    thirdPartyReportVarianceAnalysisReports
  );
  compileCommissionsJe(feesJeSheet, dataUtility);
  compileMFJe(mfJeSheet, dataUtility);
}
function createHeaderSection(
  sheet: Worksheet,
  dataUtility: ReconciliationReportDatUtility,
  sheetName: string
) {
  const titleRow = sheet.addRow([
    `BOJANGLES' RESTAURANTS, INC.`,
    "",
    "",
    "",
    "JE NO:",
    "",
    "",
    "",
    "",
    "SIGN-OFF",
    "Initials",
    "Date",
  ]);
  titleRow.font = { bold: true };
  const subtitleRow = sheet.addRow([
    "JOURNAL ENTRY",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Prepared",
  ]);
  subtitleRow.font = { bold: true };
  sheet.addRow(["", "", "", "", "", "", "", "", "", "Reviewed"]);
  sheet.addRow(["JE TYPE", "Regular", "", "", "", "", "", "", "", "Entered"]);
  sheet.getCell(`A4`).font = { bold: true };
  sheet.addRow(["APPLY PERIOD", dataUtility.endDateText]);
  sheet.addRow([]);
  sheet.addRow([
    "DESCRIPTION",
    `${sheetName} ${moment(dataUtility.endDateText, "MM/DD/YYYY")
      .subtract(1, "day")
      .format("MM/DD")}`,
  ]);
  //bold
  ["A4", "A5", "A7", "J3", "J4", "J5"].forEach(
    (cell) => (sheet.getCell(cell).font = { bold: true })
  );
  //
  ["J", "K", "L"].forEach((column) => {
    for (let i = 1; i < 5; i++) {
      const cell = `${column}${i}`;
      sheet.getCell(cell).border = {
        bottom: { style: "thin" },
        top: { style: "thin" },
        right: { style: "thin" },
        left: { style: "thin" },
      };
    }
  });
  //underline
  ["F1", "B4", "C4", "B5", "C5", "B7", "C7", "D7", "E7", "F7"].forEach(
    (cell) =>
      (sheet.getCell(cell).border = {
        bottom: { style: "thick" },
      })
  );
  sheet.addRow([]);
  sheet.addRow([]);
  sheet.getColumn(`H`).width = 1.25;
  sheet.getColumn(`I`).width = 1.25;
  sheet.getRow(6).height = 3;
  sheet.getRow(8).height = 2.25;
  sheet.getRow(9).height = 2.25;
}
function compileMFJe(
  mfSheet: Worksheet,
  dataUtility: ReconciliationReportDatUtility
) {
  mfSheet.addRow(headerRow);
  const totalMF = Math.abs(
    dataUtility.reportData.reduce((sum, report) => {
      return (sum += +report.marketFacilitatorTax
        ? +report.marketFacilitatorTax
        : 0);
    }, 0)
  );
  mfSheet.addRow([
    `80000215400`,
    totalMF,
    0,
    "",
    "",
    `marketplace facilitator tax`,
    "",
    "",
    "",
    80000,
    215400,
  ]);
  mfSheet.addRow([
    `80000115155`,
    0,
    totalMF,
    "",
    "",
    `marketplace facilitator tax`,
    "",
    "",
    "",
    80000,
    115155,
  ]);
}
function compileCancellationsJe(
  salesVarSheet: Worksheet,
  dataUtility: ReconciliationReportDatUtility,
  thirdPartyReportVarianceAnalysisReports: ThirdPartyReportFragmentVarianceAnalysisReport[],
  ppaTransactions: PriorPeriodAdjustmentTransaction[]
) {
  salesVarSheet.addRow(headerRow);
  let rowCount = 2;
  dataUtility.thirdParties
    .filter(
      (thirdParty) =>
        thirdParty.id !== "kSxn4c51aQNHV26iYDe4" &&
        thirdParty.id !== "fh5v5sPxpRteq4C7KPhh"
    )
    .forEach((thirdParty) => {
      let offsetTotals = {
        "Potential Pricing Issues": 0,
        "Transaction Missing in Source": 0,
        "Transaction In POS Not Paid Out": 0,
        Adjustment: 0,
        "Prior Period Adjustments": 0,
      };
      dataUtility.locations.forEach((location) => {
        let varAcct = 0; // 50426
        let refundAcct = 0; //50440
        const varAnalysis = thirdPartyReportVarianceAnalysisReports.find(
          (report) =>
            report.location === location.locationId &&
            report.thirdParty === thirdParty.id
        );
        /** Add Prior Period Transactions To */

        const ppaAdjustmentTransactionsAmount = getPPaTransactionValues(
          location,
          thirdParty,
          ppaTransactions,
          false
        );
        if (+ppaAdjustmentTransactionsAmount) {
          varAcct += -ppaAdjustmentTransactionsAmount; // Force neg to match var calc signage
          offsetTotals["Prior Period Adjustments"] +=
            -ppaAdjustmentTransactionsAmount;
        }
        /** Add by variance types */
        for (let type in TransactionAITypes) {
          if (
            type !== "potentialFraud" &&
            type !== "nonPosPayment" &&
            type !== "errorCharge" &&
            !!varAnalysis
          ) {
            const variance = +varAnalysis[TransactionAITypes[type]]
              ? +varAnalysis[TransactionAITypes[type]]
              : 0;
            if (+variance) {
              varAcct += +variance ? +variance : 0;
              //Push location variance type to corresponding location aggregate offset for variance type
              offsetTotals[TransactionAITypes[type]] += variance;
            }
          }
        }
        /** Entry lines for location third party */
        if (refundAcct !== 0) {
          salesVarSheet.addRow([
            +`${getLocationBU(location.locationId)}550440`,
            refundAcct > 0 ? refundAcct : 0,
            refundAcct < 0 ? Math.abs(refundAcct) : 0,
            "",
            "",
            `mobile delivery - ${thirdParty.name} Activity`,
            "",
            "",
            "",
            getLocationBU(location.locationId),
            550440,
          ]);
          rowCount++;
        }
        if (varAcct !== 0) {
          salesVarSheet.addRow([
            +`${getLocationBU(location.locationId)}550426`,
            varAcct > 0 ? varAcct : 0,
            varAcct < 0 ? Math.abs(varAcct) : 0,
            "",
            "",
            `mobile delivery - ${thirdParty.name} Activity`,
            "",
            "",
            "",
            getLocationBU(location.locationId),
            550426,
          ]);
          rowCount++;
        }
      });
      Object.keys(offsetTotals).forEach((entry) => {
        const entryAmount = offsetTotals[entry];
        if (entryAmount !== 0) {
          salesVarSheet.addRow([
            80000115155,
            entryAmount < 0 ? Math.abs(entryAmount) : 0,
            entryAmount > 0 ? entryAmount : 0,
            "",
            entry,
            `mobile delivery - ${thirdParty.name} Activity`,
            "",
            "",
            "",
            80000,
            115155,
          ]);
          rowCount++;
        }
      });
    });
}
function compileAdjustmentsJe(
  adjustmentsSheet: Worksheet,
  dataUtility: ReconciliationReportDatUtility,
  thirdPartyReportVarianceAnalysisReports: ThirdPartyReportFragmentVarianceAnalysisReport[],
  ppaTransactions: PriorPeriodAdjustmentTransaction[]
) {
  adjustmentsSheet.addRow(headerRow);
  let rowCount = 2;
  dataUtility.thirdParties
    .filter((thirdParty) => thirdParty.id !== "kSxn4c51aQNHV26iYDe4")
    .forEach((thirdParty) => {
      let offsetTotals = {
        "Error Charge": 0,
        "Prior Period Refunds": 0,
      };
      dataUtility.locations.forEach((location) => {
        let varAcct = 0; // 50426
        let refundAcct = 0; //50440
        const varAnalysis = thirdPartyReportVarianceAnalysisReports.find(
          (report) =>
            report.location === location.locationId &&
            report.thirdParty === thirdParty.id
        );
        /** Add Prior Period Transactions To */
        const ppaRefundTransactionsAmount = getPPaTransactionValues(
          location,
          thirdParty,
          ppaTransactions,
          true
        );
        if (+ppaRefundTransactionsAmount) {
          refundAcct += -ppaRefundTransactionsAmount; // Force neg to match var calc signage
          offsetTotals["Prior Period Refunds"] += -ppaRefundTransactionsAmount;
        }

        /** Add by variance types */ //@TODO can refactor for simplicity rushing cause... startup
        for (let type in TransactionAITypes) {
          if (type === "errorCharge" && !!varAnalysis) {
            const variance = +varAnalysis[TransactionAITypes[type]]
              ? +varAnalysis[TransactionAITypes[type]]
              : 0;
            if (+variance) {
              refundAcct += +variance ? +variance : 0;
              //Push location variance type to corresponding location aggregate offset for variance type
              offsetTotals[TransactionAITypes[type]] += variance;
            }
          }
        }
        /** Entry lines for location third party */
        if (refundAcct !== 0) {
          adjustmentsSheet.addRow([
            +`${getLocationBU(location.locationId)}550440`,
            refundAcct > 0 ? refundAcct : 0,
            refundAcct < 0 ? Math.abs(refundAcct) : 0,
            "",
            "",
            `mobile delivery - ${thirdParty.name} Activity`,
            "",
            "",
            "",
            getLocationBU(location.locationId),
            550440,
          ]);
          rowCount++;
        }
        if (varAcct !== 0) {
          adjustmentsSheet.addRow([
            +`${getLocationBU(location.locationId)}550426`,
            varAcct > 0 ? varAcct : 0,
            varAcct < 0 ? Math.abs(varAcct) : 0,
            "",
            "",
            `mobile delivery - ${thirdParty.name} Activity`,
            "",
            "",
            "",
            getLocationBU(location.locationId),
            550426,
          ]);
          rowCount++;
        }
      });
      Object.keys(offsetTotals).forEach((entry) => {
        const entryAmount = offsetTotals[entry];
        if (entryAmount !== 0) {
          adjustmentsSheet.addRow([
            80000115155,
            entryAmount < 0 ? Math.abs(entryAmount) : 0,
            entryAmount > 0 ? entryAmount : 0,
            "",
            entry,
            `mobile delivery - ${thirdParty.name} Activity`,
            "",
            "",
            "",
            80000,
            115155,
          ]);
          rowCount++;
        }
      });
    });
  //UGH adding ezCater fees to this JE for some insane reason
  dataUtility.thirdParties
    .filter((thirdParty) => thirdParty.id === ThirdPartyDeliveryIds.ezCater)
    .forEach((thirdParty) => {
      let thirdPartyOffset = 0;
      dataUtility.locations.forEach((location) => {
        const locationDspRec = dataUtility.reportData.find(
          (rec) =>
            rec.locationId === location.locationId &&
            rec.thirdParty === thirdParty.id
        );
        if (locationDspRec) {
          const feesTotal = calcFeesTotal(locationDspRec, thirdParty, true);
          if (+feesTotal !== 0) {
            thirdPartyOffset += feesTotal;
            adjustmentsSheet.addRow([
              +`${getLocationBU(location.locationId)}550425`,
              feesTotal < 0 ? Math.abs(feesTotal) : 0,
              feesTotal >= 0 ? feesTotal : 0,
              "",
              "",
              `mobile delivery - ${thirdParty.name} Activity`,
              "",
              "",
              "",
              getLocationBU(location.locationId),
              550425,
            ]);
            rowCount++;
          }
        }
      });
      if (thirdPartyOffset && +thirdPartyOffset !== 0)
        adjustmentsSheet.addRow([
          80000115155,
          thirdPartyOffset >= 0 ? thirdPartyOffset : 0,
          thirdPartyOffset < 0 ? Math.abs(thirdPartyOffset) : 0,
          "",
          "",
          `mobile delivery - ${thirdParty.name} Activity`,
          "",
          "",
          "",
          80000,
          115155,
        ]);
    });
}
function getPPaTransactionValues(
  location: Location,
  thirdParty: ThirdParty,
  ppaTransactions: PriorPeriodAdjustmentTransaction[],
  refund?: boolean
) {
  return ppaTransactions
    .filter((ppat) => {
      return (
        ppat.location === location.locationId &&
        ppat.account === thirdParty.id &&
        (refund ? ppat.isErrorCharge : ppat.isAdjustment)
      );
    })
    .reduce((sum: number, ppat) => {
      // determine the amount of the cutomer refund (DoorDash in transaction.saleCorrection, all other DSPS in transaction.sale)
      const amount =
        ppat.transaction.sale !== 0
          ? ppat.transaction.sale
          : ppat.transaction.saleCorrection !== 0
          ? ppat.transaction.saleCorrection
          : 0;
      return (sum += amount);
    }, 0);
}
function getLocationBU(locationId) {
  let locationBU = `8`;
  for (let i = 0; i < 4 - locationId.length; i++) {
    locationBU = `${locationBU}0`;
  }
  return +`${locationBU}${locationId}`;
}

async function compileOloVarJe(
  oloVarSheet: Worksheet,
  dataUtility: ReconciliationReportDatUtility,
  thirdPartyReportVarianceAnalysisReports: ThirdPartyReportFragmentVarianceAnalysisReport[]
) {
  oloVarSheet.addRow(headerRow);
  let rowCount = 2;
  dataUtility.locations.forEach((location) => {
    dataUtility.thirdParties
      .filter((thirdParty) => thirdParty.id === "fh5v5sPxpRteq4C7KPhh") // Only Olo-dispatch bojangles
      .forEach((thirdParty) => {
        const variance = calcSalVar(
          location,
          thirdParty,
          thirdPartyReportVarianceAnalysisReports
        );
        if (variance) {
          oloVarSheet.addRow([
            +`${getLocationBU(location.locationId)}550440`,
            variance < 0 ? Math.abs(variance) : 0,
            variance >= 0 ? variance : 0,
            "",
            "",
            "mobile delivery-mobile app activity",
            "",
            "",
            "",
            getLocationBU(location.locationId),
            550440,
          ]);
          rowCount++;
          oloVarSheet.addRow([
            +`${getLocationBU(location.locationId)}550500`,
            variance >= 0 ? variance : 0,
            variance < 0 ? Math.abs(variance) : 0,
            "",
            "",
            "mobile delivery-mobile app activity",
            "",
            "",
            "",
            getLocationBU(location.locationId),
            550500,
          ]);
        }
      });
  });
}
function calcSalVar(
  location: Location,
  dsp: ThirdParty,
  thirdPartyReportVarianceAnalysisReports: ThirdPartyReportFragmentVarianceAnalysisReport[]
) {
  const varAnalysis = thirdPartyReportVarianceAnalysisReports.find(
    (report) =>
      report.location === location.locationId && report.thirdParty === dsp.id
  );
  const salesVariance = varAnalysis.salesVarianceAmount;

  return +salesVariance.toFixed(2);
}
function compileCommissionsJe(
  feesSheet: Worksheet,
  dataUtility: ReconciliationReportDatUtility
) {
  feesSheet.addRow(headerRow);
  let rowCount = 2;
  dataUtility.thirdParties
    .filter(
      (thirdParty) =>
        thirdParty.id !== "kSxn4c51aQNHV26iYDe4" &&
        thirdParty.id !== "fh5v5sPxpRteq4C7KPhh"
    )
    .forEach((thirdParty) => {
      let thirdPartyOffset = 0;
      dataUtility.locations.forEach((location) => {
        const locationDspRec = dataUtility.reportData.find(
          (rec) =>
            rec.locationId === location.locationId &&
            rec.thirdParty === thirdParty.id
        );
        if (locationDspRec) {
          const feesTotal = calcFeesTotal(locationDspRec, thirdParty, false);
          if (+feesTotal !== 0) {
            thirdPartyOffset += feesTotal;
            feesSheet.addRow([
              +`${getLocationBU(location.locationId)}550425`,
              feesTotal < 0 ? Math.abs(feesTotal) : 0,
              feesTotal >= 0 ? feesTotal : 0,
              "",
              "",
              `${thirdParty.name} Commission Exp`,
              "",
              "",
              "",
              getLocationBU(location.locationId),
              550425,
            ]);
            rowCount++;
          }
        }
      });
      if (thirdPartyOffset !== 0) {
        feesSheet.addRow([
          80000115155,
          thirdPartyOffset >= 0 ? thirdPartyOffset : 0,
          thirdPartyOffset < 0 ? Math.abs(thirdPartyOffset) : 0,
          "",
          "",
          `${thirdParty.name} Commission Exp`,
          "",
          "",
          "",
          80000,
          115155,
        ]);
      }
    });
}
function calcFeesTotal(
  locationDspRec: ThirdPartyReconciliationLocationData,
  thirdParty: ThirdParty,
  isAdjustmentsSheet: boolean
) {
  if (thirdParty.id === ThirdPartyDeliveryIds.ezCater) {
    if (isAdjustmentsSheet) {
      return (
        locationDspRec.cateringFees +
        locationDspRec.thirdPartyMisc +
        locationDspRec.thirdPartyOtherCharges
      );
    } else {
      return locationDspRec.deliveryFees + locationDspRec.pickupFees;
    }
  } else {
    return (
      locationDspRec.deliveryFees +
      locationDspRec.pickupFees +
      locationDspRec.cateringFees +
      locationDspRec.thirdPartyMisc +
      locationDspRec.thirdPartyOtherCharges
    );
  }
}
