// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular.json`.

import { FirebaseConfig } from "./firebase.config";
import { currentVersion } from "./global.config";

export const apiBase = FirebaseConfig.getPrepConfiguration().apiBase;
// export const apiBase = 'http://localhost:5001/deliver-sense-app-pre-prod/us-central1';

export const environment = {
  appVersion: currentVersion,
  appBaseUrl: "https://app.prep.deliversense.com",
  production: false,
  signupEnabled: true,
  applicationControlCode: "22hZl0SMeJfAoKutM1St",
  apiUrl: `${apiBase}/api/`,
  firebaseConfig: FirebaseConfig.getPrepConfiguration(),
  applicationVersion: "0.0.2",
  stripePublicKey: "pk_test_nZUZzIVtLz5ZfAc9NCatHajP00twECQWOi",
  mg_api_key: "e352a02d4e508b8ac5dba4965267de51-bbbc8336-a29b08a6",
  mg_domain: "mg.deliversense.com",
};
