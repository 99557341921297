import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { downloadDataAsFile } from "app/shared/ds-constant";
import { Papa } from "ngx-papaparse";
import { ThirdPartyReportFragmentPayoutReport } from "@deliver-sense-librarian/data-schema";
import { AngularFirestore } from "@angular/fire/firestore";
import firebase from "firebase/app";
import { first } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { TransactionsDialogComponent } from "app/dialogs/transactions-dialog/transactions-dialog.component";
import * as _ from "lodash";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ReconciliationReportDatUtility } from "../../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../../utilities/reconciliation-report-filtering.utility";
import { FormControl } from "@angular/forms";
import { AfterViewInit } from "@angular/core";

@Component({
  selector: "app-payout-analysis-table",
  templateUrl: "./payout-analysis-table.component.html",
  styleUrls: ["./payout-analysis-table.component.scss"],
})
export class PayoutAnalysisTableComponent implements OnInit, AfterViewInit {
  @Input() data: ThirdPartyReportFragmentPayoutReport[];
  @Input() reportTitle: string;
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @Output() clearResults = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    "location",
    "thirdParty",
    "payoutDate",
    "transactions",
    "payout",
  ];
  public filter3pdsControl = new FormControl([]);
  public filterLocationsControl = new FormControl([]);
  constructor(
    private cdr: ChangeDetectorRef,
    private afs: AngularFirestore,
    private papa: Papa,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private loadingService: LoadingDialogService
  ) {}

  ngOnInit(): void {
    if (this.dataUtility.existingReport.includeBankInRec) {
      this.displayedColumns.push(...["bankAmount", "bankDetails"]);
    }
    this.tableData = new MatTableDataSource(this.data);
  }

  ngAfterViewInit(): void {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.filteringUtility.set3pdAndLocationFilterPredicate(
      this.tableData,
      this.filter3pdsControl,
      this.filterLocationsControl
    );
    this.cdr.detectChanges();
  }
  exportReport() {
    const data = Object.assign(
      this.tableData.data.map((data: ThirdPartyReportFragmentPayoutReport) => {
        const row = {
          Location: data.location,
          "Third Party": data["thirdPartyName"],
          "Payout Date": data.payoutDate,
          Transactions: data.transactionCount ? data.transactionCount : 0,
          "Total Payout": data.remittance,
        };
        if (this.dataUtility.existingReport.includeBankInRec) {
          row["Bank Amount"] = data["bankAmount"] ? data["bankAmount"] : "";
          row["Bank Details"] = data["bankDetails"] ? data["bankDetails"] : "";
        }
      })
    );
    const results = this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const fileName = `${this.reportTitle}-Payout-Analysis`;
    downloadDataAsFile(results, fileName, "csv");
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  public getTotalPayout() {
    return this.data.reduce((sum, current) => {
      return (sum += +current.remittance ? +current.remittance : 0);
    }, 0);
  }
  public getTotalBank() {
    return this.data.reduce((sum, current) => {
      return (sum += +current["bankAmount"] ? +current["bankAmount"] : 0);
    }, 0);
  }
  public async openTransactionViewer(
    report: ThirdPartyReportFragmentPayoutReport
  ) {
    const transactionIds = report.transactions as string[];
    if (transactionIds.length > 0) {
      this.loadingService.isLoading(true, "Finding transactions...");
      const transactionIdBatches = _.chunk(transactionIds, 10);
      try {
        const transactions = _.flatten(
          await Promise.all(
            transactionIdBatches.map((transactionIds) => {
              return this.afs
                .collection("thirdPartyTransactions", (ref) =>
                  ref.where(
                    firebase.firestore.FieldPath.documentId(),
                    "in",
                    transactionIds
                  )
                )
                .snapshotChanges()
                .pipe(first())
                .toPromise();
            })
          )
        );
        this.loadingService.isLoading(false);
        this.dialog.open(TransactionsDialogComponent, {
          panelClass: "invisible-panel-dialog",
          data: {
            transactions: FirestoreUtilities.mapToType(transactions),
          },
        });
      } catch (e) {
        this.loadingService.isLoading(false);
        this.snackBar.open(
          `Could not find transactions. Please refresh and try again`,
          "Dismiss",
          { duration: 5000 }
        );
      }
    }
  }
}
