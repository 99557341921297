import { Component, OnInit, OnDestroy } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  Client,
  PosSystem,
  ThirdParty,
  DataUploadEventGroup,
  User,
  Location,
  UserRoles,
  ClientTransactionPeriodCloseConfiguration,
  DataUploadGroupChecklistControl,
  CustomReport,
} from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { Subject, combineLatest, of } from "rxjs";
import { takeUntil, distinctUntilChanged, first } from "rxjs/operators";
import { FirestoreUtilities } from "../../../utilities/firestore-utilities";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as _ from "lodash";
import { ConfirmDialogComponent } from "app/dialogs/confirm-dialog/confirm-dialog.component";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import * as moment from "moment";
import { LoadingDialogService } from "../../../services/loading-dialog.service";
import { NewDataUploadDialogComponent } from "../../../dialogs/new-data-upload-dialog/new-data-upload-dialog.component";
import { UploadGroupChecklistDialogComponent } from "../../../dialogs/upload-group-checklist-dialog/upload-group-checklist-dialog.component";
import { ClientUploadChecklistItem } from "@deliver-sense-librarian/data-schema";

@Component({
  selector: "app-data-upload-events",
  templateUrl: "./data-upload-events.component.html",
  styleUrls: ["./data-upload-events.component.scss"],
})
export class DataUploadEventsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  public client: Client;
  public thirdParties = [];
  public posSystems = [];

  public selectedUploadGroupControl = new FormControl();
  public selectedUploadGroupId: string;

  public dataUploadEventGroups: DataUploadEventGroup[] = [];

  public newGroupNameControl = new FormControl();
  public editGroupNameControl = new FormControl();
  public creatingNewGroup = false;
  public editingGroup = false;
  public user: User;
  public uploadGroupChecklist: ClientUploadChecklistItem[];
  closePeriodForm: FormGroup;
  private initialized$ = new Subject();
  locations: Location[] = [];
  clientTransactionPeriodCloseConfiguration: ClientTransactionPeriodCloseConfiguration;
  editClosePeriodConfig: boolean;
  periodStart: Date;
  periodEnd: any;
  customReports: CustomReport[] = [];

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private store: Store<any>,
    private afs: AngularFirestore,
    private loadingService: LoadingDialogService
  ) {}

  ngOnInit(): void {
    this.listenForGroupChange();
    combineLatest([
      this.store
        .select((store) => store.uiState.client)
        .pipe(distinctUntilChanged((a, b) => a?.id === b?.id)),
      this.store
        .select((store) => store.uiState.authUser)
        .pipe(distinctUntilChanged((a, b) => a?.id === b?.id)),
      this.store
        .select((store) => store.uiState.clientPosSystems)
        .pipe(distinctUntilChanged((a, b) => a?.length === b?.length)),
      this.store
        .select((store) => store.uiState.clientThirdParties)
        .pipe(distinctUntilChanged((a, b) => a?.length === b?.length)),
      this.store.select((store) => store.uiState.locations),
    ])
      .pipe(takeUntil(this.initialized$), distinctUntilChanged())
      .subscribe(
        async ([
          client$,
          authUser$,
          clientPosSystems$,
          clientThirdParties$,
          clientLocations$,
        ]) => {
          if (
            client$ &&
            authUser$ &&
            clientPosSystems$?.length > 0 &&
            clientThirdParties$?.length > 0 &&
            clientLocations$?.length > 0
          ) {
            this.client = client$;
            this.user = authUser$;
            this.thirdParties = <ThirdParty[]>clientThirdParties$
              ? clientThirdParties$.map((client3pd) => client3pd.thirdParty)
              : [];
            this.posSystems = <PosSystem[]>clientPosSystems$
              ? clientPosSystems$.map((clientPos) => clientPos.posSystem)
              : [];
            await this.fetchLocations(clientLocations$);
            await this.fetchCustomReports();
            this.initialized$.next(true);
            this.initialized$.complete();
            this.fetchUploadGroups();
            this.fetchLatestPeriodConfigurations();
          }
        }
      );
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  async fetchCustomReports() {
    this.customReports = <CustomReport[]>(
      FirestoreUtilities.mapToType(
        await this.afs
          .collection("customReports")
          .snapshotChanges()
          .pipe(first())
          .toPromise()
      )
    );
  }
  fetchLatestPeriodConfigurations() {
    this.afs
      .collection("clientTransactionPeriodCloseConfigurations", (ref) =>
        ref.where("client", "==", this.client.id).orderBy("closeDate", "desc")
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((clientTransactionPeriodCloseConfigurations$) => {
        const clientTransactionPeriodCloseConfigurations =
          FirestoreUtilities.mapToType(
            clientTransactionPeriodCloseConfigurations$
          );
        this.clientTransactionPeriodCloseConfiguration =
          clientTransactionPeriodCloseConfigurations.length > 0
            ? clientTransactionPeriodCloseConfigurations[0]
            : null;
        if (this.clientTransactionPeriodCloseConfiguration) {
          this.periodStart = moment(
            this.clientTransactionPeriodCloseConfiguration.closeDate.toDate()
          )
            .add(1, "day")
            .toDate();
          this.periodEnd =
            this.clientTransactionPeriodCloseConfiguration.dateDataAvailable.toDate();
        }
        this.setupPeriodConfigForm();
      });
  }
  private setupPeriodConfigForm() {
    this.closePeriodForm = this.fb.group({
      startDate: new FormControl(
        this.clientTransactionPeriodCloseConfiguration.closeDate
          ? moment(
              this.clientTransactionPeriodCloseConfiguration.closeDate.toDate()
            )
              .add(1, "day")
              .startOf("day")
              .toDate()
          : null,
        Validators.required
      ),
      endDate: new FormControl(
        this.clientTransactionPeriodCloseConfiguration.dateDataAvailable
          ? this.clientTransactionPeriodCloseConfiguration.dateDataAvailable.toDate()
          : null,
        Validators.required
      ),
      active: new FormControl(
        !!this.clientTransactionPeriodCloseConfiguration.active
      ),
    });
  }
  cancelPeriodChange() {
    this.editClosePeriodConfig = false;
    this.setupPeriodConfigForm();
  }
  async savePeriod() {
    if (this.closePeriodForm.valid) {
      const formValues: { startDate: Date; endDate: Date; active: boolean } =
        this.closePeriodForm.value;
      const closeDate = moment(formValues.startDate)
        .subtract(1, "day")
        .startOf("day")
        .toDate();
      const updateDate = moment().toDate();
      if (this.clientTransactionPeriodCloseConfiguration) {
        await this.afs
          .doc(
            `clientTransactionPeriodCloseConfigurations/${this.clientTransactionPeriodCloseConfiguration.id}`
          )
          .update({
            closeDate: closeDate,
            dateDataAvailable: formValues.endDate,
            dateUpdated: updateDate,
            active: !!formValues.active,
          });
      } else {
        await this.afs
          .collection(`clientTransactionPeriodCloseConfigurations`)
          .add({
            client: this.client.id,
            active: !!formValues.active,
            createdBy: this.user.id,
            dateCreated: updateDate,
            dateUpdated: updateDate,
            closeDate: closeDate,
            dateDataAvailable: formValues.endDate,
          });
      }
      this.editClosePeriodConfig = false;
      this.snackBar.open(
        "Updated period configuration succsessfully!",
        "Dismiss",
        { duration: 5000 }
      );
    } else {
      this.snackBar.open(
        "Please set a close date and date loaded through date",
        "Dismiss",
        { duration: 5000 }
      );
    }
  }
  // Grouping Methods
  private fetchLocations(clientLocations) {
    if (this.user.internalRole > 0) {
      this.afs
        .collection("locations", (ref) =>
          ref.where("client", "==", this.client.id).where("active", "==", true)
        )
        .snapshotChanges()
        .pipe(takeUntil(this.destroy$))
        .subscribe((locationsQueryResults$) => {
          this.locations = <Location[]>(
            FirestoreUtilities.mapToType(locationsQueryResults$)
          );
        });
    } else {
      FirestoreUtilities.getUserAccessibleResourcesOfType(
        "locations",
        this.afs,
        clientLocations,
        [UserRoles.admin, UserRoles.contributor]
      ).subscribe((locations$) => {
        this.locations = locations$
          .filter((location) => !!location.active)
          .sort((a, b) => (a.locationId < b.locationId ? -1 : 1));
      });
    }
  }
  editSelectedGroup() {
    const groupId = this.selectedUploadGroupId;
    const group = this.dataUploadEventGroups.find(
      (group) => group.id === groupId
    );
    this.editGroupNameControl.patchValue(group.name);
    this.editGroupNameControl.updateValueAndValidity();
    this.editingGroup = true;
  }
  async updateGroupName() {
    if (this.editGroupNameControl.valid) {
      try {
        const newName = this.editGroupNameControl.value;
        const groupId = this.selectedUploadGroupId;
        await this.afs.doc(`dataUploadEventGroups/${groupId}`).update({
          name: newName,
        });
        this.snackBar.open("Group name updated successfully", "Dismiss", {
          duration: 5000,
        });
        this.editingGroup = false;
        this.editGroupNameControl.reset();
      } catch (e) {
        console.error(e);
        this.snackBar.open(
          "Oops... something went wrong. Please refresh and try again.",
          "Dismiss",
          { duration: 5000 }
        );
      }
    } else {
      this.snackBar.open("Please enter a name for the group", "Dismiss", {
        duration: 5000,
      });
    }
  }

  public cancelEditGroup() {
    this.editingGroup = false;
    this.editGroupNameControl.reset();
  }

  public cancelCreateGroup() {
    this.creatingNewGroup = false;
    this.newGroupNameControl.reset();
  }
  public isGroupOwner(groupId) {
    const group = this.dataUploadEventGroups.find(
      (group) => group.id === groupId
    );
    if (group) {
      return group.creator === this.user.id;
    }
    return false;
  }
  public async createUploadGroup() {
    if (this.newGroupNameControl.valid) {
      const uploadGroup = new DataUploadEventGroup();
      uploadGroup.name = this.newGroupNameControl.value;
      uploadGroup.client = this.client.id;
      uploadGroup.creator = this.user.id;
      const newGroup = await this.afs
        .collection("dataUploadEventGroups")
        .add(uploadGroup.toJSONObject());
      this.snackBar.open("New group created successfully", "Dismiss", {
        duration: 5000,
      });
      this.creatingNewGroup = false;
      this.newGroupNameControl.patchValue(newGroup.id);
      this.newGroupNameControl.updateValueAndValidity();
    } else {
      this.snackBar.open(
        "Please provide a name for the report group.",
        "Dismiss",
        { duration: 5000 }
      );
    }
  }
  public deleteGroup() {
    const groupToDelete = this.selectedUploadGroupId;
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Delete Group",
        message: `Are you sure you want to delete this report group? All reports in the group will be set to "Un-Grouped"`,
        action: "Yes, Delete",
      },
    });
    confirmDialog.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        const uploadGroupUpdateBatch = this.afs.firestore.batch();
        this.dataUploadEventGroups.forEach((uploadEvent) => {
          uploadGroupUpdateBatch.update(
            this.afs.doc(`dataUploadEvents/${uploadEvent.id}`).ref,
            { group: "ungrouped" }
          );
        });
        await uploadGroupUpdateBatch.commit();
        await this.afs.doc(`dataUploadEventGroups/${groupToDelete}`).delete();
        this.snackBar.open("Successfully deleted grouping", "Dismiss", {
          duration: 5000,
        });
        this.selectedUploadGroupControl.patchValue("ungrouped");
        this.selectedUploadGroupControl.updateValueAndValidity();
      }
    });
  }

  private fetchUploadGroups() {
    this.afs
      .collection("dataUploadEventGroups", (ref) =>
        ref.where("client", "==", this.client.id)
      )
      .snapshotChanges()
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((a, b) => a?.length === b?.length)
      )
      .subscribe((uploadGroups$) => {
        this.dataUploadEventGroups = <DataUploadEventGroup[]>(
          FirestoreUtilities.mapToType(uploadGroups$).sort((a, b) => {
            return moment(a.dateUpdated.toDate()).isBefore(
              moment(b.dateUpdated.toDate())
            )
              ? 1
              : -1;
          })
        );
        this.dataUploadEventGroups.push(
          new DataUploadEventGroup({ name: "Un-Grouped", id: "ungrouped" })
        );
        if (!this.selectedUploadGroupId) {
          this.selectedUploadGroupControl.patchValue(
            this.dataUploadEventGroups[0].id
          );
          this.selectedUploadGroupControl.updateValueAndValidity();
        }
      });
  }

  private listenForGroupChange() {
    this.selectedUploadGroupControl.valueChanges.subscribe(
      (selectedGroupId) => {
        if (this.selectedUploadGroupId !== selectedGroupId) {
          this.selectedUploadGroupId = null;
          setTimeout(() => {
            if (selectedGroupId) {
              this.selectedUploadGroupId = selectedGroupId;
              this.setUploadGroupChecklist();
            }
          });
        }
      }
    );
  }
  private async setUploadGroupChecklist() {
    const selectedUploadGroup = this.dataUploadEventGroups.find(
      (group) => group.id === this.selectedUploadGroupId
    );
    const uploadGroupChecklistItems = FirestoreUtilities.mapToType(
      await this.afs
        .collection("clientUploadChecklistItems", (ref) =>
          ref.where("client", "==", this.client.id)
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise()
    ) as ClientUploadChecklistItem[];
    uploadGroupChecklistItems.forEach((checklistItem) => {
      let log;
      if (selectedUploadGroup.uploadChecklist) {
        log = selectedUploadGroup.uploadChecklist.find((checklistItemLog) => {
          return checklistItemLog.checklistItem === checklistItem.id;
        });
      }
      checklistItem["log"] = log ? log : new DataUploadGroupChecklistControl();
    });
    this.uploadGroupChecklist = uploadGroupChecklistItems.filter(
      (item) => !item.parent
    );
    this.uploadGroupChecklist.forEach((checklistItem) => {
      const children = uploadGroupChecklistItems.filter(
        (item) => item.parent === checklistItem.id
      );
      checklistItem["children"] = children;
    });
  }
  isChecklistComplete() {
    let complete = true;
    if (this.uploadGroupChecklist) {
      for (const checklistItem of this.uploadGroupChecklist) {
        if (!checklistItem["log"]?.complete) {
          complete = false;
          break;
        }
        if (checklistItem["children"]) {
          checklistItem["children"].forEach((child) => {
            if (!child["log"]?.complete) {
              complete = false;
            }
          });
          if (!complete) {
            break;
          }
        }
      }
    }
    return complete;
  }
  openNewDataUploadEventDialog(multiple = false) {
    this.dialog.open(NewDataUploadDialogComponent, {
      panelClass: "invisible-panel-dialog",
      disableClose: true,
      data: {
        selectedGroup: this.selectedUploadGroupId,
        multiple,
      },
    });
  }
  showUploadGroupChecklist() {
    const checklistDialogRef = this.dialog.open(
      UploadGroupChecklistDialogComponent,
      {
        panelClass: "invisible-panel-dialog",
        data: {
          uploadChecklist: this.uploadGroupChecklist,
        },
      }
    );
    checklistDialogRef.afterClosed().subscribe(async (checklistItems) => {
      if (checklistItems) {
        const groupChecklistLogs = _.flatten(
          checklistItems.map((checklistItem) => {
            let parentItem = {
              complete: !!checklistItem.log.complete,
              checklistItem: checklistItem.id,
            };
            let childrenItems = checklistItem.children.map((childItem) => {
              return {
                complete: !!childItem.log.complete,
                checklistItem: childItem.id,
              };
            });
            return [parentItem, ...childrenItems];
          })
        );
        await this.afs
          .doc(`dataUploadEventGroups/${this.selectedUploadGroupId}`)
          .update({
            uploadChecklist: groupChecklistLogs,
            dateUpdated: moment().toDate(),
          });
        this.snackBar.open("Upload group checklist updated.", "Dismiss", {
          duration: 5000,
        });
      }
    });
  }
}
