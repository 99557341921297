import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as moment from "moment";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ReconciliationReportDatUtility } from "../../utilities/reconciliation-report-data.utility";
import { ThirdPartyDeliveryAnalyticsEngine } from "../../third-party-delivery-analytics-engine";

@Component({
  selector: "app-transaction-drill-down",
  templateUrl: "./transaction-drill-down.component.html",
  styleUrls: ["./transaction-drill-down.component.scss"],
})
export class TransactionDrillDownComponent implements OnInit, OnDestroy {
  @Input() dataUtility: ReconciliationReportDatUtility;
  analyticsEngine: ThirdPartyDeliveryAnalyticsEngine;
  public parametersForm: FormGroup;
  public transactionMatches;
  private allTransactions: { posTransactions: any[]; tpdTransactions: any[] };
  private destroy$ = new Subject();
  constructor(
    private afs: AngularFirestore,
    private store: Store<any>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private loadingService: LoadingDialogService
  ) {}

  ngOnInit() {
    this.analyticsEngine = new ThirdPartyDeliveryAnalyticsEngine();
    this.setupParametersForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  setupParametersForm() {
    this.parametersForm = this.fb.group({
      selectedLocation: new FormControl("", Validators.required),
      selectedThirdParty: new FormControl("", Validators.required),
      startDate: new FormControl(
        this.dataUtility.existingReport.startDate.toDate(),
        Validators.required
      ),
      endDate: new FormControl(
        this.dataUtility.existingReport.endDate.toDate(),
        Validators.required
      ),
      acceptableVariance: new FormControl(10, Validators.required),
    });
  }
  async runAnalysis() {
    if (this.parametersForm.valid) {
      this.loadingService.isLoading(true, "Matching transactions...");
      const locationId = this.parametersForm.get("selectedLocation").value;
      const thirdPartyId = this.parametersForm.get("selectedThirdParty").value;
      const thirdParty = this.dataUtility.thirdParties.find(
        (tp) => tp.id === thirdPartyId
      );
      const start = moment(this.parametersForm.get("startDate").value)
        .startOf("day")
        .toDate();
      const end = moment(this.parametersForm.get("endDate").value)
        .endOf("day")
        .toDate();
      const acceptableVariance = 1;

      this.analyticsEngine
        .fetchParameterizedReportData(
          locationId,
          thirdParty,
          start,
          end,
          this.dataUtility.client,
          this.afs
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe((result$) => {
          this.loadingService.isLoading(false);
          this.allTransactions = result$;
          const matchingAnalysis: { matches; distances } =
            this.analyticsEngine.createDistanceMatrix(this.allTransactions);
          this.transactionMatches = this.flattenTransactionMatches(
            matchingAnalysis
          ).sort((a, b) => {
            return +a["Match%"] > +b["Match%"] ? -1 : 1;
          });
        });
    } else {
      this.snackBar.open(
        "Please select a location, 3PD, start date, and end date",
        "Dismiss",
        {
          duration: 5000,
        }
      );
    }
  }

  private flattenTransactionMatches(matchingAnalysis: { matches; distances }) {
    return matchingAnalysis.matches
      .map((match) => {
        const posTransaction = this.getPosTransaction(match.posTransaction);
        const thirdPartyTransaction = this.getThirdPartyTransaction(
          match.thirdPartyTransaction
        );
        return {
          posTransaction: posTransaction ? posTransaction : null,
          "Match %": `${+this.analyticsEngine.normalizeDistance(
            match.match,
            matchingAnalysis.distances
          )}`,
          thirdPartyTransaction: thirdPartyTransaction
            ? thirdPartyTransaction
            : null,
        };
      })
      .sort((a, b) => {
        return a["Match %"] < b["Match %"] ? 1 : -1;
      });
  }

  getSelectedThirdParty() {
    const thirdPartyId = this.parametersForm.value.selectedThirdParty;
    const thirdParty = this.dataUtility.thirdParties.find(
      (_tp) => _tp.id === thirdPartyId
    );
    return thirdParty;
  }
  getSelectedLocation() {
    const locationId = this.parametersForm.value.selectedLocation;
    const location = this.dataUtility.locations.find(
      (_location) => _location.locationId === locationId
    );
    return location;
  }
  getPosTransaction(posTransactionId: string) {
    return this.allTransactions.posTransactions.find(
      (t) => t.id === posTransactionId
    );
  }

  getThirdPartyTransaction(thirdPartyTransactionId: string) {
    return this.allTransactions.tpdTransactions.find(
      (t) => t.id === thirdPartyTransactionId
    );
  }
}
