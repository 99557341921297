import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { UserView } from "@deliver-sense-librarian/data-schema";
import { AngularFireStorage } from "@angular/fire/storage";
import { takeUntil, map } from "rxjs/operators";
import { Subject, Observable } from "rxjs";
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
  selector: "app-avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
})
export class AvatarComponent implements OnInit, OnDestroy {
  @Input() userView: UserView;
  @Input() name: string;
  @Input() toolTip: string;
  user: UserView;
  destroy$ = new Subject();
  initials: string;
  pictureUrl: Observable<string>;
  constructor(
    private storage: AngularFireStorage,
    private afs: AngularFirestore
  ) {}

  ngOnInit() {
    if (this.userView) {
      // this.afs
      //   .doc(`userViews/${this.userView.id}`)
      //   .valueChanges()
      //   .pipe(takeUntil(this.destroy$))
      //   .subscribe((userView$: UserView) => {
      //     this.user = userView$;
      //     if (userView$.picturePath) {
      //       this.initials = null;
      //       this.setPicture();
      //     } else {
      //       this.pictureUrl = null;
      //       this.setInitials();
      //     }
      //   });
      this.setInitials();
    }
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  private async setPicture() {
    if (this.user && this.user.picturePath) {
      this.pictureUrl = this.storage
        .ref(this.user.picturePath)
        .getDownloadURL()
        .pipe(
          takeUntil(this.destroy$),
          map((downloadUrl) => {
            return downloadUrl;
          })
        );
    }
  }

  setInitials() {
    if (this.userView) {
      if (this.userView.firstName && this.userView.lastName) {
        const firstInitial = this.userView.firstName.charAt(0).toUpperCase();
        const lastIntial = this.userView.lastName.charAt(0).toUpperCase();
        this.initials = `${firstInitial}${lastIntial}`;
      } else {
        const firstInitial = this.userView.email.charAt(0).toUpperCase();
        this.initials = `${firstInitial}`;
      }
    } else if (this.name) {
      this.initials = this.name.charAt(0);
    }
  }
  getAvatarTooltip() {
    if (this.toolTip) {
      return this.toolTip;
    } else {
      return this.user ? `${this.user.firstName} ${this.user.lastName}` : "";
    }
  }

  getUserViewInitialColor() {
    const firstInitial = this.initials.charAt(0).toLowerCase();
    switch (firstInitial) {
      case "a":
        return "#E91E63";
      case "b":
        return "#3F51B5";
      case "c":
        return "#0D47A1";
      case "d":
        return "#00695C";
      case "e":
        return "#82B1FF";
      case "f":
        return "#B388FF";
      case "g":
        return "#FF8A80";
      case "h":
        return "#EA80FC";
      case "i":
        return "#7B1FA2";
      case "j":
        return "#338dff";
      case "k":
        return "#FB8C00";
      case "l":
        return "#9E9D24";
      case "m":
        return "#1B5E20";
      case "n":
        return "#0097A7";
      case "o":
        return "#26A69A";
      case "p":
        return "#8C9EFF";
      case "q":
        return "#FF6E40";
      case "r":
        return "#F06292";
      case "s":
        return "#D32F2F";
      case "t":
        return "#CE93D8";
      case "u":
        return "#FFCDD2";
      case "v":
        return "#26C6DA";
      case "w":
        return "#2E7D32";
      case "x":
        return "#C0CA33";
      case "y":
        return "#F57C00";
      case "z":
        return "#9E9E9E";
      default:
        return "#37474F";
    }
  }
}
