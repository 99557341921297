import { Component, Inject, OnInit } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ClientExemptionCertificateDocument } from "@deliver-sense-librarian/data-schema";
import { first } from "rxjs/operators";

@Component({
  selector: "app-exemption-certificate-viewer-dialog",
  templateUrl: "./exemption-certificate-viewer-dialog.component.html",
  styleUrls: ["./exemption-certificate-viewer-dialog.component.scss"],
})
export class ExemptionCertificateViewerDialogComponent implements OnInit {
  constructor(
    private storage: AngularFireStorage,
    public dialogRef: MatDialogRef<ExemptionCertificateViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      clientExemptionCertificateDocument: ClientExemptionCertificateDocument;
    }
  ) {}

  ngOnInit(): void {}
  async downloadFile() {
    const fullUrlPath = await this.storage
      .ref(this.data.clientExemptionCertificateDocument.filePath)
      .getDownloadURL()
      .pipe(first())
      .toPromise();
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.download = this.data.clientExemptionCertificateDocument.filePath;
    a.href = fullUrlPath;
    a.target = "_blank";
    a.click();
  }
  closeAndDelete() {
    this.dialogRef.close("remove");
  }
}
