import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  ThirdParty,
  TransactionI,
  ClientThirdParty,
  disputeReasons,
  disputeStatuses,
} from "@deliver-sense-librarian/data-schema";
import { Subject } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ErrorChargeTransaction } from "app/components/ds-modules/3pd-reconciliation/reconciliation-sub-reports/error-summary/error-summary-table/error-summary-table.component";

@Component({
  selector: "app-dispute-manager-dialog",
  templateUrl: "./dispute-manager-dialog.component.html",
  styleUrls: ["./dispute-manager-dialog.component.scss"],
})
export class DisputeManagerDialogComponent implements OnInit, AfterViewInit {
  public thirdParties: ThirdParty[];
  public keys: string[] = [];
  public destroy$ = new Subject();
  public clientThirdParties: ClientThirdParty[];
  descriptionToView: any;
  activePopover: any;
  disputeManagerForm: FormGroup;
  public disputeCountArr = [];
  public disputeReasons = disputeReasons;
  public isSaving = false;
  constructor(
    private fb: FormBuilder,
    private afs: AngularFirestore,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DisputeManagerDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { transaction: ErrorChargeTransaction }
  ) {
    for (let i = 0; i < 11; i++) {
      this.disputeCountArr.push(i);
    }
  }

  ngOnInit() {
    this.setupForm();
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  ngAfterViewInit() {}
  setupForm() {
    this.disputeManagerForm = this.fb.group({
      disputeReason: new FormControl(
        this.data.transaction?.disputeReason,
        Validators.required
      ),
      disputeCount: new FormControl(
        this.data.transaction?.disputeCount,
        Validators.required
      ),
      systemError: new FormControl(
        this.data.transaction.disputeReason === "No dispute - System error"
          ? true
          : false
      ),
    });
    this.disputeManagerForm
      .get("systemError")
      .valueChanges.subscribe((isError) => {
        if (isError) {
          this.disputeManagerForm
            .get("disputeReason")
            .patchValue("No dispute - System error");
          this.disputeManagerForm.get("disputeReason").updateValueAndValidity();
          this.disputeManagerForm.get("disputeCount").patchValue(0);
          this.disputeManagerForm.get("disputeCount").updateValueAndValidity();
        }
      });
  }

  async updateDispute() {
    if (this.disputeManagerForm.valid) {
      const formValues = this.disputeManagerForm.value;
      try {
        this.isSaving = true;
        await this.afs
          .doc(`thirdPartyTransactions/${this.data.transaction.id}`)
          .update({
            disputeReason: formValues.disputeReason
              ? formValues.disputeReason
              : null,
            disputeCount: formValues.disputeCount
              ? formValues.disputeCount
              : null,
            dateUpdated: moment().toDate(),
          });
        this.snackBar.open("Dispute log updated successfully", "Dismiss", {
          duration: 5000,
        });
        this.dialogRef.close(formValues);
      } catch (e) {
        this.snackBar.open(
          "Oops.. something went wrong. Please refresh and try again.",
          "Dismiss",
          { duration: 5000 }
        );
      }
    } else {
      this.snackBar.open(
        "A dispute reason and count are required.",
        "Dismiss",
        { duration: 5000 }
      );
    }
  }
  copySuccessAlert() {
    this.snackBar.open(`Reason copied to your clipboard`, "Dismiss", {
      duration: 4000,
    });
  }
  setOtherReason() {
    this.disputeManagerForm.get("disputeReason").patchValue("Other reason");
    this.disputeManagerForm.get("disputeReason").updateValueAndValidity();
    this.disputeManagerForm.get("disputeCount").patchValue(1);
    this.disputeManagerForm.get("disputeCount").updateValueAndValidity();
  }
}
