import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "lodash";
import { Papa } from "ngx-papaparse";
import {
  ThirdPartyReconciliationLocationData,
  ThirdPartyReportFragmentVarianceAnalysisReport,
  ThirdPartyReportNetCash,
  TransactionAITypes,
} from "@deliver-sense-librarian/data-schema";
import {
  ReconciliationDrillDownReportTypes,
  ReconciliationReportDatUtility,
} from "../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import { ReconciliationReportFilteringUtility } from "../../utilities/reconciliation-report-filtering.utility";
@Component({
  selector: "app-net-cash-report",
  templateUrl: "./net-cash-report.component.html",
  styleUrls: ["./net-cash-report.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NetCashReportComponent implements OnInit, AfterViewInit {
  @Input() exportUtility: ReconciliationReportExportUtility;
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  public netCashReports: ThirdPartyReportNetCash[] = [];
  public loadingData = false;
  thirdPartyReportVarianceAnalysisReports: any;
  constructor(
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private papa: Papa
  ) {}

  ngOnInit() {
    this.initializeTable();
  }
  ngAfterViewInit() {}
  private async initializeTable() {
    this.loadingData = true;
    this.thirdPartyReportVarianceAnalysisReports =
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.varianceAnalysisReports
      );
    this.compileThirdPrtyReportNetCashReports();
    this.loadingData = false;
  }
  private compileThirdPrtyReportNetCashReports() {
    this.netCashReports = this.dataUtility.reportData.map((locationReport) => {
      const varianceAnalysis =
        this.thirdPartyReportVarianceAnalysisReports.find(
          (vaReport: ThirdPartyReportFragmentVarianceAnalysisReport) => {
            return (
              vaReport.location === locationReport.locationId &&
              vaReport.thirdParty === locationReport.thirdParty
            );
          }
        );
      return this.dataUtility.runNetCashCalcs(locationReport, varianceAnalysis);
    });
  }

  getVariance(a: number, b: number) {
    return +(a - b).toFixed(2);
  }
}
