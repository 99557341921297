import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Client, ReportGroupTypes, ThirdPartyReportGroup } from '@deliver-sense-librarian/data-schema';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-tpd-report-dialog',
  templateUrl: './new-tpd-report-dialog.component.html',
  styleUrls: ['./new-tpd-report-dialog.component.scss']
})
export class NewTpdReportDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<NewTpdReportDialogComponent>,
    private router: Router,
              @Inject(MAT_DIALOG_DATA) public data: {groups: ThirdPartyReportGroup[], selectedGroup: string, type: ReportGroupTypes}) {
  }

  ngOnInit() {
  }

  public async closeDialog(id: string) {
      this.dialogRef.close(id);
  }

}
