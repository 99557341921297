import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  Client,
  Location,
  ThirdPartyPerformanceSummaryReport,
  ThirdPartyReport,
  ThirdPartyReportFragment,
  User,
  UserRoles,
} from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import * as Highcharts from "highcharts";
import more from "highcharts/highcharts-more";
import HC_exporting from "highcharts/modules/exporting";
HC_exporting(Highcharts);
more(Highcharts);
Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});
import _ from "lodash";
import { combineLatest, Subject } from "rxjs";
import { takeUntil, first, distinctUntilChanged } from "rxjs/operators";
import { FirestoreUtilities } from "../../../../utilities/firestore-utilities";
import { ThirdParty } from "@deliver-sense-librarian/data-schema";
import { FormControl, FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { Papa } from "ngx-papaparse";
declare const $: any;

@Component({
  selector: "app-third-party-performance-chart",
  templateUrl: "./third-party-performance-chart.component.html",
  styleUrls: ["./third-party-performance-chart.component.scss"],
})
export class ThirdPartyPerformanceChartComponent implements OnInit {
  locations: Location[];
  client: Client;
  initialized$ = new Subject();
  lastestCompleteReport: ThirdPartyReport;
  preformanceReports: ThirdPartyPerformanceSummaryReport[] = [];
  reportFragments: ThirdPartyReportFragment[] = [];

  Highcharts: typeof Highcharts = Highcharts;
  public chartOptions;
  self = this;
  showChart: boolean;
  noRatingsData: boolean;

  destroy$ = new Subject();
  thirdParties: ThirdParty[] = [];
  thirdPartySelectControl = new FormControl();
  user: User;
  uiState: {
    client: any;
    authUser: any;
    clientThirdParties: any;
    locations: any;
  };
  loadingParameters: boolean;
  selectedThirdParty: ThirdParty;

  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private papa: Papa,
    private afs: AngularFirestore,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    combineLatest([
      this.store
        .select((store) => store.uiState.client)
        .pipe(distinctUntilChanged((a, b) => a === b)),
      this.store
        .select((store) => store.uiState.authUser)
        .pipe(distinctUntilChanged((a, b) => a === b)),
      this.store
        .select((store) => store.uiState.clientThirdParties)
        .pipe(distinctUntilChanged((a, b) => a === b)),
      this.store
        .select((store) => store.uiState.locations)
        .pipe(distinctUntilChanged((a, b) => a === b)),
    ])
      .pipe(takeUntil(this.initialized$))
      .subscribe(([client, authUser, clientThirdParties, locations]) => {
        if (
          client &&
          authUser &&
          clientThirdParties?.length > 0 &&
          locations?.length > 0
        ) {
          this.uiState = { client, authUser, clientThirdParties, locations };
          this.thirdParties = clientThirdParties
            .filter((cdsp) => cdsp.active)
            .map((clientThirdParty) => clientThirdParty.thirdParty)
            .filter((tp) => !!tp);
          this.initialized$.next(true);
          this.initialized$.complete();
          this.initializeWidget();
        }
      });
  }
  private async initializeWidget() {
    await this.getLocations();
    this.fetchMostRecentReconciliation();
  }
  private async getLocations() {
    if (this.uiState.authUser.internalRole > 0) {
      this.locations = <Location[]>FirestoreUtilities.mapToType(
        await this.afs
          .collection("locations", (ref) =>
            ref
              .where("client", "==", this.uiState.client.id)
              .where("active", "==", true)
          )
          .snapshotChanges()
          .pipe(first())
          .toPromise()
      );
      this.loadingParameters = false;
    } else {
      this.locations = (
        await FirestoreUtilities.getUserAccessibleResourcesOfType(
          "locations",
          this.afs,
          this.uiState.locations,
          [UserRoles.admin, UserRoles.contributor, UserRoles.viewer]
        )
          .pipe(first())
          .toPromise()
      )
        .filter((location) => !!location.active)
        .sort((a, b) => (a.locationId < b.locationId ? -1 : 1));
      this.loadingParameters = false;
    }
  }

  private async fetchMostRecentReconciliation() {
    const lastestCompleteReport = <ThirdPartyReport[]>(
      FirestoreUtilities.mapToType(
        await this.afs
          .collection("thirdPartyReports", (ref) =>
            ref
              .where("client", "==", this.uiState.client.id)
              .where("complete", "==", true)
              .orderBy("endDate", "desc")
              .limit(1)
          )
          .snapshotChanges()
          .pipe(first())
          .toPromise()
      )
    );
    if (lastestCompleteReport.length > 0) {
      this.lastestCompleteReport = lastestCompleteReport[0];
      this.fetchReportFragments();
    }
  }
  private async fetchReportFragments() {
    this.reportFragments = <ThirdPartyReportFragment[]>(
      FirestoreUtilities.mapToType(
        await this.afs
          .collection("thirdPartyReportFragments", (ref) =>
            ref
              .where("thirdPartyReport", "==", this.lastestCompleteReport.id)
              .where("client", "==", this.uiState.client.id)
          )
          .snapshotChanges()
          .pipe(first())
          .toPromise()
      )
    );
    this.fetchReconciliationReportDrillDownFragments();
  }
  public async fetchReconciliationReportDrillDownFragments() {
    const fragmentRequests: Promise<any>[] = [];
    _.chunk(this.reportFragments, 10).forEach(
      (fragmentChunk: ThirdPartyReportFragment[]) => {
        fragmentRequests.push(
          this.afs
            .collection("thirdPartyPerformanceSummaryReports", (ref) =>
              ref
                .where(
                  "thirdPartyReportFragment",
                  "in",
                  fragmentChunk.map((fragment) => fragment.id)
                )
                .where("client", "==", this.uiState.client.id)
                .orderBy("grossSales", "desc")
            )
            .snapshotChanges()
            .pipe(first())
            .toPromise()
        );
      }
    );
    const resolvedRequests = _.flatten(await Promise.all(fragmentRequests));
    this.preformanceReports = FirestoreUtilities.mapToType(
      resolvedRequests
    ).filter((performanceReport) => {
      return !!this.locations.find(
        (location) => location.locationId === performanceReport.location
      );
    });
    this.setupChartData();
  }

  setupChartData() {
    this.showChart = false;
    this.noRatingsData = false;
    this.preformanceReports.forEach((performanceReport) => {
      performanceReport["thirdPartyName"] = this.thirdParties.find(
        (dsp) => dsp.id === performanceReport.thirdParty
      )?.name;
    });
    const performanceByThirdParty = _.groupBy(
      this.preformanceReports,
      "thirdPartyName"
    );
    const seriesData = [
      {
        name: `3PD Gross Sales`,
        data: Object.keys(performanceByThirdParty).map((dspId) => {
          return {
            y: +performanceByThirdParty[dspId]
              .reduce((sum, report) => {
                return (sum += report.grossSales);
              }, 0)
              .toFixed(2),
          };
        }),
      },
    ];
    const self = this;
    if (seriesData.length > 0) {
      setTimeout(() => {
        this.chartOptions = {
          chart: {
            type: "bar",

            events: {
              load: function () {
                $(".highcharts-scrollbar").show();
              },
            },
          },

          // legend: {
          //   layout: "vertical",
          //   align: "right",
          //   verticalAlign: "middle",
          // },

          title: {
            text: `Top 3PDs - Gross Sales`,
          },

          xAxis: {
            title: {
              text: "Third Party",
            },
            categories: Object.keys(performanceByThirdParty),
          },
          // legend: {
          //   layout: "vertical",
          //   align: "right",
          //   verticalAlign: "middle",
          // },
          yAxis: {
            title: {
              text: "Gross Sales",
            },
            labels: {
              format: "${value}",
            },
            formatter: function () {
              return Highcharts.numberFormat(this.value, 3);
            },
            lang: {
              thousandsSep: ",",
            },
          },
          tooltip: {
            useHTML: true,
            headerFormat: "<table>",
            pointFormat: `<tr><th colspan="2"><h3>{point.category}</h3></th></tr>
            <tr><th>Gross Sales:</th><td>$ {point.y}</td></tr>`,
            footerFormat: "</table>",
            followPointer: true,
          },
          credits: {
            enabled: true,
            text: "DeliverSense.com",
            href: "https://deliversense.com",
          },
          dataSorting: {
            enabled: true,
            sortKey: "value",
          },
          plotOptions: {
            bar: {
              dataLabels: {
                enabled: true,
                format: "${point.y:,.2f}",
              },
            },
          },
          series: seriesData,
        };
        this.showChart = true;
      }, 300);
    } else {
      this.noRatingsData = true;
    }
  }
  goToReport() {
    if (
      this.lastestCompleteReport.creator === this.uiState.authUser.id ||
      this.uiState.authUser.internalRole > 0
    ) {
      this.router.navigate(["app/3pd-reports", this.lastestCompleteReport.id]);
    } else {
      this.router.navigateByUrl(
        `app/3pd-reports/${this.lastestCompleteReport.id}?shared=true`
      );
    }
  }
}
