import { INITIAL_UI_STATE, UiState } from "./ui-state";
import { Action } from "@ngrx/store";
import {
  AUTHENTICATION_FAILED,
  AUTHENTICATION_SUCCESS,
  SET_ACCOUNT_CLIENT,
  UNAUTHENTICATE_USER,
  SetAccountClientAction,
  UnauthenticateUserAction,
  UserAuthenticationSuccessAction,
  UserAuthenticationFailedAction,
  SetAccountRolesAction,
  SET_ACCOUNT_ROLES,
  SetClientPosSystemsAction,
  SetClientThirdPartiesAction,
  SET_CLIENT_THIRD_PARTIES,
  SET_CLIENT_POS_SYSTEMS,
} from "./ui-state-actions";
import { environment } from "../../../../environments/environment";
import {
  TOGGLE_SHOW_DATA_UPLOAD,
  ToggleShowDataUploadAction,
} from "./ui-state-actions/application-state-actions";
import {
  SetClientEntitiesAction,
  SET_CLIENT_ENTITIES,
} from "./ui-state-actions/authentication-actions";
import {
  SetClientLocationObjectsAction,
  SET_CLIENT_LOCATION_OBJECTS,
  UNSET_SELECTED_CLIENT,
  UnsetSelectedClientAction,
  SET_AUTHORIZED_CLIENTS,
  SetAuthorizedClientsAction,
  SFA_SUCCESS,
  SfaSuccessAction,
  CLEAR_SFA,
  ClearSfaAction,
} from "./ui-state-actions/authentication-actions";

export function uiState(
  state: UiState = INITIAL_UI_STATE,
  action: Action
): UiState {
  switch (action.type) {
    /**
     * Authentication
     */
    case AUTHENTICATION_SUCCESS:
      return handleSetAuthenticatedUserAction(state, <any>action);
    case AUTHENTICATION_FAILED:
      return handleSetAuthenticationError(state, <any>action);
    case UNAUTHENTICATE_USER:
      return handleUnauthenticatedUserAction(state, <any>action);
    case SET_ACCOUNT_CLIENT:
      return handleSetAccountClientAction(state, <any>action);
    case SET_ACCOUNT_ROLES:
      return handleSetAccountRolesAction(state, <any>action);
    case SET_CLIENT_THIRD_PARTIES:
      return handleSetClientThirdPartiesAction(state, <any>action);
    case SET_CLIENT_POS_SYSTEMS:
      return handleSetClientPosSystemsAction(state, <any>action);
    case SET_CLIENT_LOCATION_OBJECTS:
      return handleSetClientLocationObjectsAction(state, <any>action);
    case SET_CLIENT_ENTITIES:
      return handleSetClientEntitiesAction(state, <any>action);
    case UNSET_SELECTED_CLIENT:
      return handleUnsetSelectedClient(state, <any>action);
    case SET_AUTHORIZED_CLIENTS:
      return handleSetAuthorizedClients(state, <any>action);
    case SFA_SUCCESS:
      return handleSfaSuccessAction(state, <any>action);
    case CLEAR_SFA:
      return handleClearSfaAction(state, <any>action);
    /**
     * Default
     */
    case TOGGLE_SHOW_DATA_UPLOAD:
      return handleToggleShowDataUploadAction(state, <any>action);
    default:
      return state;
  }
}

function handleSetAuthenticatedUserAction(
  state: UiState,
  action: UserAuthenticationSuccessAction
) {
  const newState = Object.assign({}, state);
  localStorage.setItem("user", JSON.stringify(action.payload));
  localStorage.setItem(
    "applicationVersion",
    JSON.stringify(environment.applicationVersion)
  );
  newState.authUser = action.payload;
  return newState;
}

function handleSetAccountClientAction(
  state: UiState,
  action: SetAccountClientAction
) {
  const newState = Object.assign({}, state);
  localStorage.setItem("client", JSON.stringify(action.payload));
  newState.client = action.payload;
  return newState;
}

function handleSetAuthenticationError(
  state: UiState,
  action: UserAuthenticationFailedAction
) {
  unsetAuthStorage();
  const newState = Object.assign({}, state);
  newState.authUser = undefined;
  newState.client = undefined;
  return newState;
}

function handleUnauthenticatedUserAction(
  state: UiState,
  action: UnauthenticateUserAction
) {
  unsetAuthStorage();
  const newState = Object.assign({}, state);
  newState.authUser = undefined;
  newState.client = undefined;
  newState.clientRole = undefined;
  newState.authorizedClients = [];
  newState.locations = [];
  newState.entities = [];
  newState.departments = [];
  newState.projects = [];
  newState.clientPosSystems = [];
  newState.clientThirdParties = [];
  newState.clientLocations = [];
  newState.sfaToken = undefined;
  return newState;
}

function handleSetAccountRolesAction(
  state: UiState,
  action: SetAccountRolesAction
) {
  const newState = Object.assign({}, state);
  newState.clientRole = action.payload.clientRole;
  newState.locations = action.payload.locations;
  newState.entities = action.payload.entities;
  newState.departments = action.payload.departments;
  newState.projects = action.payload.projects;
  return newState;
}
function handleSetClientPosSystemsAction(
  state: UiState,
  action: SetClientPosSystemsAction
) {
  const newState = Object.assign({}, state);
  newState.clientPosSystems = action.payload;
  return newState;
}
function handleSetClientThirdPartiesAction(
  state: UiState,
  action: SetClientThirdPartiesAction
) {
  const newState = Object.assign({}, state);
  newState.clientThirdParties = action.payload;
  return newState;
}

function handleSetClientLocationObjectsAction(
  state: UiState,
  action: SetClientLocationObjectsAction
) {
  const newState = Object.assign({}, state);
  newState.clientLocations = action.payload;
  return newState;
}
function handleSetClientEntitiesAction(
  state: UiState,
  action: SetClientEntitiesAction
) {
  const newState = Object.assign({}, state);
  newState.clientEntities = action.payload;
  return newState;
}
function handleSfaSuccessAction(state: UiState, action: SfaSuccessAction) {
  const newState = Object.assign({}, state);
  newState.sfaToken = action.payload.token;
  localStorage.setItem("sfaToken", action.payload.token);
  localStorage.setItem("client", JSON.stringify(action.payload.client));
  newState.client = action.payload.client.id;
  return newState;
}
function handleClearSfaAction(state: UiState, action: ClearSfaAction) {
  const newState = Object.assign({}, state);
  newState.sfaToken = null;
  localStorage.removeItem("sfaToken");
  return newState;
}
function handleUnsetSelectedClient(
  state: UiState,
  acton: UnsetSelectedClientAction
) {
  const newState = Object.assign({}, state);
  newState.clientRole = undefined;
  newState.locations = [];
  newState.client = undefined;
  newState.entities = [];
  newState.departments = [];
  newState.projects = [];
  localStorage.removeItem("client");
  return newState;
}
function handleSetAuthorizedClients(
  state: UiState,
  action: SetAuthorizedClientsAction
) {
  const newState = Object.assign({}, state);
  newState.authorizedClients = action.payload;
  return newState;
}

function handleToggleShowDataUploadAction(
  state: UiState,
  action: ToggleShowDataUploadAction
) {
  const newState = Object.assign({}, state);
  newState.showDataUpload = action.payload;
  return newState;
}
function unsetAuthStorage() {
  localStorage.removeItem("client");
  localStorage.removeItem("user");
  localStorage.removeItem("sfaToken");
}
