import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { downloadDataAsFile } from "app/shared/ds-constant";
import { Papa } from "ngx-papaparse";
import {
  ThirdPartyReportFragmentAdjustmentLog,
  ThirdPartyReportFragmentPayoutReport,
} from "@deliver-sense-librarian/data-schema";
import { AngularFirestore } from "@angular/fire/firestore";
import firebase from "firebase/app";
import { first } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { TransactionsDialogComponent } from "app/dialogs/transactions-dialog/transactions-dialog.component";
import * as _ from "lodash";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ReconciliationReportDatUtility } from "../../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../../utilities/reconciliation-report-filtering.utility";
import { FormControl } from "@angular/forms";
import { AfterViewInit } from "@angular/core";

@Component({
  selector: "app-adjustments-analysis-table",
  templateUrl: "./adjustments-analysis-table.component.html",
  styleUrls: ["./adjustments-analysis-table.component.scss"],
})
export class AdjustmentsAnalysisTableComponent
  implements OnInit, AfterViewInit
{
  @Input() data: ThirdPartyReportFragmentAdjustmentLog[];
  @Input() reportTitle: string;
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @Output() clearResults = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    "location",
    "thirdParty",
    "date",
    "payoutDate",
    "saleAdjustment",
    "taxAdjustment",
    "promoFee",
    "transactionId",
    "status",
    "transactionType",
    "description",
  ];
  public filter3pdsControl = new FormControl([]);
  public filterLocationsControl = new FormControl([]);
  constructor(
    private cdr: ChangeDetectorRef,
    private afs: AngularFirestore,
    private papa: Papa,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private loadingService: LoadingDialogService
  ) {}

  ngOnInit(): void {
    this.tableData = new MatTableDataSource(this.data);
  }

  ngAfterViewInit(): void {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.filteringUtility.set3pdAndLocationFilterPredicate(
      this.tableData,
      this.filter3pdsControl,
      this.filterLocationsControl
    );
    this.cdr.detectChanges();
  }
  exportReport() {
    const data = Object.assign(
      this.tableData.data.map((data: ThirdPartyReportFragmentAdjustmentLog) => {
        return {
          Location: data.location,
          "Third Party": data["thirdPartyName"],
          "Payout Date": data.payoutDate,
          Date: data.date,
          "Sale Adjustment": data.saleAdjustment,
          "Tax Adjustment": data.taxAdjustment,
          "Promo Fees": data.promoFee,
          "Transaction Id": data.transactionId,
          Status: data.status,
          "Transaction Type": data.transactionType,
          Description: data.description,
        };
      })
    );
    const results = this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const fileName = `${this.reportTitle}-Adjustments-Analysis`;
    downloadDataAsFile(results, fileName, "csv");
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  public getTotalSaleAdjustment() {
    return this.tableData.filteredData.reduce((sum, current) => {
      return (sum += +current.saleAdjustment ? +current.saleAdjustment : 0);
    }, 0);
  }
  public getTotalTaxAdjustment() {
    return this.tableData.filteredData.reduce((sum, current) => {
      return (sum += +current.taxAdjustment ? +current.taxAdjustment : 0);
    }, 0);
  }
  public getTotalPromoFee() {
    return this.tableData.filteredData.reduce(
      (sum, current: ThirdPartyReportFragmentAdjustmentLog) => {
        return (sum += +current.promoFee ? +current.promoFee : 0);
      },
      0
    );
  }

  public async openTransactionViewer(
    log: ThirdPartyReportFragmentAdjustmentLog
  ) {
    const transactionId = log.adjustmentTransaction as string[];

    this.loadingService.isLoading(true, "Finding transaction...");
    try {
      const transaction = FirestoreUtilities.objectToType(
        await this.afs
          .doc(`thirdPartyTransactions/${transactionId}`)
          .snapshotChanges()
          .pipe(first())
          .toPromise()
      );
      this.loadingService.isLoading(false);
      this.dialog.open(TransactionsDialogComponent, {
        panelClass: "invisible-panel-dialog",
        data: {
          transactions: [transaction],
        },
      });
    } catch (e) {
      this.loadingService.isLoading(false);
      this.snackBar.open(
        `Could not find transactions. Please refresh and try again`,
        "Dismiss",
        { duration: 5000 }
      );
    }
  }
}
