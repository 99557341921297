import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModule } from "app/material.module";
import { DsSharedModule } from "app/shared/ds-shared.module";
import { RouterModule } from "@angular/router";
import { DataUploadEventsComponent } from "./data-upload-events/data-upload-events.component";
import { DataUploadEventComponent } from "./data-upload-event/data-upload-event.component";
import { NewDataUploadComponent } from "./new-data-upload(deprecated)/new-data-upload.component";
import { UxModule } from "../ux/ux.module";
import { FileUploadModule } from "../file-upload/file-upload.module";
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from "ngx-perfect-scrollbar";
import { UploadErrorsTableComponent } from "./upload-errors-table/upload-errors-table.component";
import { PopoverModule } from "ngx-bootstrap/popover";
import { InvalidLocationsTableComponent } from "./data-upload-event/invalid-locations-table/invalid-locations-table.component";
import { TempPriorPeriodAdjustmentsTableComponent } from "./temp-prior-period-adjustments-table/temp-prior-period-adjustments-table.component";
import { PriorPeriodAdjustmentsModule } from "../ds-modules/prior-period-adjustments/prior-period-adjustments.module";
import { DataUploadEventsTableComponent } from "./data-upload-events/data-upload-events-table/data-upload-events-table.component";
import { TypeSearchModule } from "../type-search/type-search.module";
import { MultipleDataUploadComponent } from "./multiple-data-upload/multiple-data-upload.component";
import { DirectivesModule } from "app/directives/directives.module";
import { SampleConversionsTableComponent } from "./data-upload-event/sample-conversions-table/sample-conversions-table.component";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20,
};
@NgModule({
  declarations: [
    DataUploadEventsComponent,
    DataUploadEventComponent,
    NewDataUploadComponent,
    MultipleDataUploadComponent,
    UploadErrorsTableComponent,
    InvalidLocationsTableComponent,
    TempPriorPeriodAdjustmentsTableComponent,
    DataUploadEventsTableComponent,
    SampleConversionsTableComponent,
  ],
  exports: [
    NewDataUploadComponent,
    UploadErrorsTableComponent,
    MultipleDataUploadComponent,
    InvalidLocationsTableComponent,
    TempPriorPeriodAdjustmentsTableComponent,
    DataUploadEventsTableComponent,
    DataUploadEventComponent,
    SampleConversionsTableComponent,
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MaterialModule,
    CommonModule,
    PerfectScrollbarModule,
    DsSharedModule,
    RouterModule,
    UxModule,
    FileUploadModule,
    PopoverModule.forRoot(),
    PriorPeriodAdjustmentsModule,
    TypeSearchModule,
    DirectivesModule,
  ],
})
export class DataUploadModule {}
