import { Component, OnDestroy, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { takeUntil, first } from "rxjs/operators";
import { combineLatest, Subject } from "rxjs";
import {
  Client,
  Location,
  ThirdParty,
  UserRoles,
} from "@deliver-sense-librarian/data-schema";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { Store } from "@ngrx/store";
import { markFields } from "app/shared/ds-constant";
import { FormControl, Validators, FormBuilder } from "@angular/forms";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { UiState } from "app/redux/custom-states/uiState/ui-state";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormGroup } from "@angular/forms";
import { TransactionI } from "@deliver-sense-librarian/data-schema";
import { Clipboard } from "@angular/cdk/clipboard";
import moment from "moment";

class TransactionLookupForm {
  batchIdLookup: boolean;
  location: string;
  transactionSource: "3PD" | "POS";
  thirdParty: string;
  lookupByTransactionHistory: boolean;
  transactionId: string;
  startDate: Date;
  endDate: Date;
  batchId: string;
}
@Component({
  selector: "app-transaction-lookup",
  templateUrl: "./transaction-lookup.component.html",
  styleUrls: ["./transaction-lookup.component.scss"],
})
export class TransactionLookupComponent implements OnInit, OnDestroy {
  public locations: Location[];
  public transactionSearchForm: FormGroup;
  public transactions;
  public noTransactions = false;
  public thirdParties: ThirdParty[] = [];
  public transactionTypes = ["POS", "3PD"];
  private destroy$ = new Subject();
  private client: Client;
  private uiState$: UiState;

  constructor(
    private store: Store<any>,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private fb: FormBuilder,
    private clipboard: Clipboard
  ) {
    this.setupParametersForm();
  }

  ngOnInit() {
    this.store
      .select((store) => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((uiState$: UiState) => {
        if (
          uiState$.authUser &&
          uiState$.client &&
          uiState$.clientThirdParties
        ) {
          this.client = uiState$.client;
          this.uiState$ = uiState$;
          this.getLocations();
          this.getRelevantResources();
          this.thirdParties = this.uiState$.clientThirdParties
            .map((clientThirdParty) => {
              if (clientThirdParty.active) {
                return clientThirdParty.thirdParty;
              }
            })
            .filter((tpd) => !!tpd);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  private setupParametersForm() {
    this.transactionSearchForm = this.fb.group({
      batchIdLookup: new FormControl(false),
      //For lookup method parameters
      location: new FormControl("", Validators.required),
      transactionSource: new FormControl("3PD", Validators.required),
      thirdParty: new FormControl("", Validators.required),
      lookupByTransactionHistory: new FormControl(true),
      // for transaction history
      transactionId: new FormControl("", Validators.required),
      // for false transaction history
      startDate: new FormControl(""),
      endDate: new FormControl(""),

      // For lookup method Integration Batch Id
      batchId: new FormControl(""),
    });
    this.transactionSearchForm
      .get("batchIdLookup")
      .valueChanges.subscribe((batchIdLookup$) => {
        if (batchIdLookup$) {
          [
            "location",
            "transactionSource",
            "thirdParty",
            "transactionId",
            "startDate",
            "endDate",
          ].forEach((controlName) => {
            this.transactionSearchForm.get(controlName).setValidators(null);
            this.transactionSearchForm
              .get(controlName)
              .updateValueAndValidity();
          });
          this.transactionSearchForm
            .get("batchId")
            .setValidators(Validators.required);
          this.transactionSearchForm.get("batchId").updateValueAndValidity();
        } else {
          this.transactionSearchForm.get("batchId").setValidators(null);
          this.transactionSearchForm.get("batchId").updateValueAndValidity();
          this.transactionSearchForm
            .get("location")
            .setValidators(Validators.required);
          this.transactionSearchForm.get("location").updateValueAndValidity();
          this.updateTransactionSourceValidators();
          this.updateTransactionHistoryValidators();
        }
      });
    this.transactionSearchForm
      .get("transactionSource")
      .valueChanges.subscribe((transactionSource$) => {
        this.updateTransactionHistoryValidators();
      });
    this.transactionSearchForm
      .get("lookupByTransactionHistory")
      .valueChanges.subscribe((lookupByTransactionHistory$) => {
        this.updateTransactionHistoryValidators();
      });
  }
  private updateTransactionSourceValidators() {
    if (this.transactionSearchForm.get("transactionSource").value === "3PD") {
      this.transactionSearchForm
        .get("thirdParty")
        .setValidators(Validators.required);
      this.transactionSearchForm.get("thirdParty").updateValueAndValidity();
    } else {
      this.transactionSearchForm.get("thirdParty").setValidators(null);
      this.transactionSearchForm.get("thirdParty").updateValueAndValidity();
      this.transactionSearchForm
        .get("posSystem")
        .setValidators(Validators.required);
      this.transactionSearchForm.get("posSystem").updateValueAndValidity();
    }
  }
  private updateTransactionHistoryValidators() {
    if (!!this.transactionSearchForm.get("lookupByTransactionHistory").value) {
      this.transactionSearchForm
        .get("transactionId")
        .setValidators(Validators.required);
      this.transactionSearchForm.get("transactionId").updateValueAndValidity();
      this.transactionSearchForm.get("startDate").setValidators(null);
      this.transactionSearchForm.get("startDate").updateValueAndValidity();
      this.transactionSearchForm.get("endDate").setValidators(null);
      this.transactionSearchForm.get("endDate").updateValueAndValidity();
    } else {
      this.transactionSearchForm.get("transactionId").setValidators(null);
      this.transactionSearchForm.get("transactionId").updateValueAndValidity();
      this.transactionSearchForm
        .get("startDate")
        .setValidators(Validators.required);
      this.transactionSearchForm.get("startDate").updateValueAndValidity();
      this.transactionSearchForm
        .get("endDate")
        .setValidators(Validators.required);
      this.transactionSearchForm.get("endDate").updateValueAndValidity();
    }
  }
  private getLocations() {
    FirestoreUtilities.getUserAccessibleResourcesOfType(
      "locations",
      this.afs,
      this.uiState$.locations,
      [3]
    ).subscribe((locations$) => {
      this.locations = locations$.sort((a, b) =>
        a.locationId < b.locationId ? -1 : 1
      );
    });
  }
  private getRelevantResources() {
    combineLatest([
      FirestoreUtilities.getUserAccessibleResourcesOfType(
        "locations",
        this.afs,
        this.uiState$.locations,
        [UserRoles.admin, UserRoles.contributor]
      ),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([locations]) => {
        this.locations = locations;
      });
  }

  runSearch() {
    markFields(this.transactionSearchForm);
    if (this.transactionSearchForm.valid) {
      this.transactions = null;
      this.loadingService.isLoading(true, "Pulling transactions...");
      setTimeout(() => {
        if (this.transactionSearchForm.get("batchIdLookup").value) {
          this.searchByBatchId();
        } else {
          this.searchByParams();
        }
      }, 1000);
    }
  }
  async searchByBatchId() {
    const batchId = this.transactionSearchForm.get("batchId").value;
    try {
      const results$ = await this.afs
        .collection("posTransactions", (ref) =>
          ref
            .where("client", "==", this.client.id)
            .where("batchId", "==", batchId)
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise();

      this.transactions = FirestoreUtilities.mapToType(results$);
      if (this.transactions.length < 1) {
        this.noTransactions = true;
      }
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        "Oops... something went wrong! Please refresh and try again.",
        "Dismiss",
        { duration: 5000 }
      );
    } finally {
      this.loadingService.isLoading(false);
    }
  }
  async searchByParams() {
    const formValues: TransactionLookupForm = this.transactionSearchForm.value;
    const collectionName =
      formValues.transactionSource === "3PD"
        ? "thirdPartyTransactions"
        : "posTransactions";
    const accountField =
      formValues.transactionSource === "3PD" ? "thirdParty" : "account";
    try {
      let transactionQueryResults$;
      if (formValues.lookupByTransactionHistory) {
        transactionQueryResults$ = await this.afs
          .collection(collectionName, (ref) =>
            ref
              .where("location", "==", formValues.location)
              .where("client", "==", this.client.id)
              .where(accountField, "==", formValues.thirdParty)
              .where("transactionId", "==", formValues.transactionId)
          )
          .snapshotChanges()
          .pipe(first())
          .toPromise();
      } else {
        const start = moment(formValues.startDate)
          .startOf("day")
          .add(4, "hours")
          .utcOffset("-5:00")
          .toDate();
        const end = moment(formValues.endDate)
          .endOf("day")
          .utcOffset("-05:00")
          .toDate();
        transactionQueryResults$ = await this.afs
          .collection(collectionName, (ref) =>
            ref
              .where("location", "==", formValues.location)
              .where("client", "==", this.client.id)
              .where(accountField, "==", formValues.thirdParty)
              .where("date", ">=", start)
              .where("date", "<=", end)
          )
          .snapshotChanges()
          .pipe(first())
          .toPromise();
      }
      this.transactions = FirestoreUtilities.mapToType(
        transactionQueryResults$
      );
      if (this.transactions.length < 1) {
        this.noTransactions = true;
      }
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        "Oops... something went wrong! Please refresh and try again.",
        "Dismiss",
        { duration: 5000 }
      );
    } finally {
      this.loadingService.isLoading(false);
    }
  }
  copyTransactionId(transaction: TransactionI) {
    this.clipboard.copy(transaction.transactionId);
    this.snackBar.open("Transaction id copied to your clipboard.", "Dismiss", {
      duration: 5000,
    });
  }
}
