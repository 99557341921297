import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoginComponent } from "./login/login.component";
import { SignupComponent } from "./signup/signup.component";
import { PasswordresetComponent } from "./passwordreset/passwordreset.component";
import { DsSharedModule } from "../shared/ds-shared.module";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "../material.module";
import { ClientSelectorComponent } from "./client-selector/client-selector.component";
import { FlexModule } from "@angular/flex-layout";
import { TeamMemberSignupComponent } from "./team-member-signup/team-member-signup.component";
import { ClientsModule } from "../components/organization/clients/clients.module";
import { SfaComponent } from "./sfa/sfa.component";
import { SsoComponent } from "./sso/sso.component";
import { MatCardModule } from "@angular/material/card";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

@NgModule({
  declarations: [
    LoginComponent,
    SignupComponent,
    PasswordresetComponent,
    ClientSelectorComponent,
    TeamMemberSignupComponent,
    SfaComponent,
    SsoComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DsSharedModule,
    RouterModule,
    MaterialModule,
    MatCardModule,
    FormsModule,
    FlexModule,
    ClientsModule,
    PerfectScrollbarModule,
  ],
  providers: [],
})
export class AuthModule {}
