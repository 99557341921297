import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Location, Entity } from "@deliver-sense-librarian/data-schema";
import { take, takeUntil, first } from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { ReplaySubject, Subject } from "rxjs";
import { MatSelect } from "@angular/material/select";
import * as _ from "lodash";
import { AngularFirestore } from "@angular/fire/firestore";
import { FirestoreUtilities } from "../../../utilities/firestore-utilities";
import { Store } from "@ngrx/store";
import { uiState } from "../../../redux/custom-states/uiState/uiStateReducer";

@Component({
  selector: "app-location-search",
  templateUrl: "./location-search.component.html",
  styleUrls: ["./location-search.component.scss"],
})
export class LocationSearchComponent implements OnInit {
  @Input() locations: Location[];
  @Input() selectedLocations: FormControl;
  @Input() multiple = true;
  @ViewChild("multiSelect", { static: true }) multiSelect: MatSelect;
  public locationsFilterCtrl: FormControl = new FormControl();
  public filteredLocations: ReplaySubject<Location[]> = new ReplaySubject<
    Location[]
  >(1);
  private destroy$ = new Subject();
  entities: Entity[];
  constructor(private afs: AngularFirestore, private store: Store<any>) {}

  ngOnInit() {
    this.initializeComponent();
  }

  async initializeComponent() {
    this.entities = await this.store
      .select((store) => store.uiState.clientEntities)
      .pipe(first())
      .toPromise();
    this.mapEntityLocations();
    this.filteredLocations.next(this.locations.slice());
    this.locationsFilterCtrl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterLocations();
      });
  }
  async mapEntityLocations() {
    this.locations.forEach((location) => {
      const locationEntity = this.entities.find(
        (entity) => entity.id === location.entity
      );
      if (locationEntity) {
        location["entityName"] = locationEntity.name;
      }
    });
  }
  toggleSelectAll(selectAllValue: boolean) {
    this.filteredLocations
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe((val) => {
        if (selectAllValue) {
          this.selectedLocations.patchValue(
            val.map((location) => location.locationId)
          );
          this.selectedLocations.updateValueAndValidity();
        } else {
          this.selectedLocations.patchValue([]);
          this.selectedLocations.updateValueAndValidity();
        }
      });
  }

  filterLocations() {
    if (!this.locations) {
      return;
    }
    this.mapEntityLocations();
    // get the search keyword
    let search = this.locationsFilterCtrl.value;
    if (!search) {
      this.filteredLocations.next(this.locations.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredLocations.next(
      this.locations.filter((location) => {
        return (
          location.name?.toLowerCase().indexOf(search) > -1 ||
          location.locationId?.indexOf(search) > -1 ||
          location["entityName"]?.toLowerCase().indexOf(search) > -1
        );
      })
    );
  }

  getLocationName(locationId: string) {
    const location = this.locations.find((l) => l.locationId === locationId);
    return location ? location.name : "";
  }
}
