import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reconciliation-note-dialog',
  templateUrl: './reconciliation-note-dialog.component.html',
  styleUrls: ['./reconciliation-note-dialog.component.scss']
})
export class ReconciliationNoteDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ReconciliationNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
