import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TermsComponent } from "./terms/terms.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { DsSharedModule } from "app/shared/ds-shared.module";

@NgModule({
  imports: [CommonModule, FlexLayoutModule, DsSharedModule],
  declarations: [TermsComponent, PrivacyPolicyComponent],
  exports: [TermsComponent, PrivacyPolicyComponent],
})
export class TermsModule {}
