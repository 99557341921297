import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  ThirdPartyReportFragmentErrorChargeLog,
  ThirdPartyReportFragmentTransactionAnalysis,
  ThirdPartyTransaction,
} from "@deliver-sense-librarian/data-schema";
import _ from "lodash";
import { FormGroup } from "@angular/forms";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import { ReconciliationReportDatUtility } from "../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../utilities/reconciliation-report-filtering.utility";
import moment from "moment";
import { DrillDownSelection } from "../daily-drill-down/daily-drill-down.component";

@Component({
  selector: "app-error-summary",
  templateUrl: "./error-summary.component.html",
  styleUrls: ["./error-summary.component.scss"],
})
export class ErrorSummaryComponent implements OnInit, OnDestroy {
  @Input() exportUtility: ReconciliationReportExportUtility;
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @Input() preErrorChargeSelection: DrillDownSelection;

  public thirdPartyReportFragmentErrorChargeLogs: ThirdPartyReportFragmentErrorChargeLog[] =
    [];
  public thirdPartyErrorTransactionAnalysis: ThirdPartyReportFragmentTransactionAnalysis[] =
    [];
  transactions: ThirdPartyTransaction[];
  loadingErrorCharges = true;
  public parametersForm: FormGroup;
  public transactionData: any[];
  constructor(private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.fetchDataAndInitializeTable();
  }
  ngOnDestroy() {}

  async fetchDataAndInitializeTable() {
    try {
      this.transactions = await this.dataUtility.fetchErrorChargeTransactions();
      this.transactions = this.transactions.sort((a, b) => {
        return moment(a.date.toDate()).isSameOrBefore(moment(b.date.toDate()))
          ? -1
          : 1;
      });
      this.loadingErrorCharges = false;
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        "Oops.. something went wrong. Please refresh and try again.",
        "Dismiss",
        { duration: 5000 }
      );
    }
  }
}
