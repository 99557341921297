import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AppComponent } from "./app.component";
import { RouterModule } from "@angular/router";
import { ROUTES } from "./app.routing";
import { AdminLayoutModule } from "./views/admin-layout/admin-layout.module";
import { LoadingDialogComponent } from "./dialogs/loading-dialog/loading-dialog.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { HttpClientModule } from "@angular/common/http";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { HelpModule } from "./components/help/help.module";
import { ServicesModule } from "./services/services.module";
import { AuthModule } from "./auth/auth.module";
import { DashboardModule } from "./components/dashboard/dashboard.module";
import { DsSharedModule } from "./shared/ds-shared.module";
import { NotFoundModule } from "./components/not-found/not-found.module";
import { FirebaseAuthService } from "./auth/services/firebase-auth.service";
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";
import { ReconciliationReportsModule } from "./components/ds-modules/3pd-reconciliation/reconciliation-reports.module";
import {
  StoreDevtoolsModule,
  StoreDevtoolsOptions,
} from "@ngrx/store-devtools";
import { getArdConfig } from "./utilities/ard-config";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { OrganizationModule } from "./components/organization/organization.module";
import { DirectivesModule } from "./directives/directives.module";
import { UserProfileModule } from "./components/organization/user-profile/user-profile.module";
import { FileUploadModule } from "./components/file-upload/file-upload.module";
import { ExemptionCertificateManagementModule } from "./components/ds-modules/exemption-certificate-management/exemption-certificate-management.module";
import { GuardsModule } from "./guards/guards.module";
import { NotificationPanelModule } from "./components/structural/notification-panel/notification-panel.module";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PopoverModule } from "ngx-bootstrap/popover";
import { DataUploadModule } from "./components/data-upload/data-upload.module";
import { DialogsModule } from "./dialogs/dialogs.module";
import { TransactionLookupModule } from "./components/ds-modules/transaction-lookup/transaction-lookup.module";
import { TermsModule } from "./components/structural/terms/terms.module";
import { TrendAnalysisModule } from "./components/ds-modules/trend-analysis/trend-analysis.module";
import { ClientsModule } from "./components/organization/clients/clients.module";

import { environment } from "environments/environment";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { SharingCenterModule } from "./components/sharing-center/sharing-center.module";
import { JoyrideModule } from "ngx-joyride";
import { CookieService } from "ngx-cookie-service";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  // suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20,
};
@NgModule({
  declarations: [AppComponent],
  imports: [
    /**
     * FireStore & Firebase
     */
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule.enablePersistence(),
    AngularFireStorageModule,
    /**
     * REDUX
     */
    StoreModule.forRoot(getArdConfig().customReducers),
    EffectsModule.forRoot(getArdConfig().effects),
    StoreDevtoolsModule.instrument(<StoreDevtoolsOptions>{ maxAge: 25 }),
    /**
     * Angular Base Modules
     */
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES, { useHash: false }),
    FlexLayoutModule,
    /**
     * Views
     */
    AdminLayoutModule,
    /**
     * Components
     */
    ReconciliationReportsModule,
    OrganizationModule,
    DashboardModule,
    HelpModule,
    NotFoundModule,
    UserProfileModule,
    FileUploadModule,
    ExemptionCertificateManagementModule,
    TransactionLookupModule,
    NotificationPanelModule,
    DataUploadModule,
    TermsModule,
    TrendAnalysisModule,
    ClientsModule,
    SharingCenterModule,
    /**
     * Libraries
     */
    PerfectScrollbarModule,
    JoyrideModule.forRoot(),
    /**
     * Application Specific
     */
    AuthModule,
    DialogsModule,
    DirectivesModule,
    DsSharedModule,
    ServicesModule,
    GuardsModule,
    PopoverModule.forRoot(),
  ],
  providers: [
    Location,
    Title,
    CookieService,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    FirebaseAuthService,
    // { provide: USE_EMULATOR, useValue: ['localhost', 8080] },
  ],
  entryComponents: [LoadingDialogComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
