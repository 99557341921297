import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReconciliationReportComponent } from "./reconciliation-report/reconciliation-report.component";
import { ReportsComponent } from "./reconciliation-reports/reports.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModule } from "../../../material.module";
import { DsSharedModule } from "../../../shared/ds-shared.module";
import { ThirdPartyReportSettingsComponent } from "./reconciliation-report-settings/third-party-report-settings.component";
import { ReconciliationChartsComponent } from "./reconciliation-sub-reports/reconciliation-charts/reconciliation-charts.component";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTreeModule } from "@angular/material/tree";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatSliderModule } from "@angular/material/slider";
import { MatInputModule } from "@angular/material/input";
import { NewReportComponent } from "./new-reconciliation-report/new-tpd-report.component";
import { UxModule } from "../../ux/ux.module";
import { PopoverModule } from "ngx-bootstrap/popover";
import { ThirdPartyStatusConfigurationsComponent } from "./reconciliation-report-settings/third-party-status-configurations/third-party-status-configurations.component";
import { ThirdPartyReportThresholdsComponent } from "./reconciliation-report-settings/third-party-report-thresholds/third-party-report-thresholds.component";
import { ThirdPartyReportDefaultFieldsComponent } from "./reconciliation-report-settings/third-party-report-default-fields/third-party-report-default-fields.component";
import { ThirdPartyDefaultFieldsSelectorComponent } from "./reconciliation-report-settings/third-party-default-fields-selector/third-party-default-fields-selector.component";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { ThirdPartyStatusConfigurationsTableComponent } from "./reconciliation-report-settings/third-party-status-configurations/third-party-status-configurations-table/third-party-status-configurations-table.component";
import { HighchartsChartModule } from "highcharts-angular";
import { RouterModule } from "@angular/router";
import { PriorPeriodAdjustmentsModule } from "../prior-period-adjustments/prior-period-adjustments.module";
import { AnomalyAnalysisComponent } from "./reconciliation-sub-reports/anomaly-analysis/anomaly-analysis.component";
import { DailyDrillDownComponent } from "./reconciliation-sub-reports/daily-drill-down/daily-drill-down.component";
import { DataValidationReportComponent } from "./reconciliation-sub-reports/data-validation-report/data-validation-report.component";
import { DispatchSummaryReport } from "./reconciliation-sub-reports/dispatch-summary-report/dispatch-summary-report.component";
import { ErrorSummaryTableComponent } from "./reconciliation-sub-reports/error-summary/error-summary-table/error-summary-table.component";
import { ErrorSummaryComponent } from "./reconciliation-sub-reports/error-summary/error-summary.component";
import { FeeAnalysisComponent } from "./reconciliation-sub-reports/fee-analysis/fee-analysis.component";
import { PayoutAnalysisTableComponent } from "./reconciliation-sub-reports/payout-analysis/payout-analysis-table/payout-analysis-table.component";
import { PayoutAnalysisComponent } from "./reconciliation-sub-reports/payout-analysis/payout-analysis.component";
import { PotentialFraudReportComponent } from "./reconciliation-sub-reports/potential-fraud-report/potential-fraud-report.component";
import { ReconciliationtNoteComponent } from "./reconciliation-sub-reports/reconciliation-notes/reconciliation-note/reconciliation-note.component";
import { ReconciliationNotesComponent } from "./reconciliation-sub-reports/reconciliation-notes/reconciliation-notes.component";
import { ReconciliationSummaryReportComponent } from "./reconciliation-sub-reports/reconciliation-summary-report/reconciliation-summary-report.component";
import { StatusDrillDownTableComponent } from "./reconciliation-sub-reports/status-drill-down/status-drill-down-table/status-drill-down-table.component";
import { StatusDrillDownComponent } from "./reconciliation-sub-reports/status-drill-down/status-drill-down.component";
import { TaxRateAnalysisTableComponent } from "./reconciliation-sub-reports/tax-rate-analysis/tax-rate-analysis-table/tax-rate-analysis-table.component";
import { TaxRateAnalysisComponent } from "./reconciliation-sub-reports/tax-rate-analysis/tax-rate-analysis.component";
import { TaxSummaryReportComponent } from "./reconciliation-sub-reports/tax-summary-report/tax-summary-report.component";
import { TransactionAnalysisComponent } from "./reconciliation-sub-reports/transaction-analysis/transaction-analysis.component";
import { TransactionDrillDownTableComponent } from "./reconciliation-sub-reports/transaction-drill-down/transaction-match/transaction-drill-down-table/transaction-drill-down-table.component";
import { TransactionDrillDownComponent } from "./reconciliation-sub-reports/transaction-drill-down/transaction-drill-down.component";
import { VarianceAnalysisComponent } from "./reconciliation-sub-reports/variance-analysis/variance-analysis.component";
import { FeeDrillDownTableComponent } from "./reconciliation-sub-reports/fee-analysis/fee-drill-down-table/fee-drill-down-table.component";
import { DataValidationTableComponent } from "./reconciliation-sub-reports/data-validation-report/data-validation-table/data-validation-table.component";
import { PotentialFraudReportTableComponent } from "./reconciliation-sub-reports/potential-fraud-report/potential-fraud-report-table/potential-fraud-report-table.component";
import { ReconciliationTableComponent } from "./reconciliation-sub-reports/reconciliation-table/reconciliation-table/reconciliation-table.component";
import { ReconciliationComponent } from "./reconciliation-sub-reports/reconciliation-table/reconciliation.component";
import { TaxSummaryTableComponent } from "./reconciliation-sub-reports/tax-summary-report/tax-summary-table/tax-summary-table.component";
import { VarianceAnalysisTableComponent } from "./reconciliation-sub-reports/variance-analysis/variance-analysis-table/variance-analysis-table.component";
import { DispatchSummaryTableComponent } from "./reconciliation-sub-reports/dispatch-summary-report/dispatch-summary-table/dispatch-summary-table.component";
import { ReconciliationSummaryTableComponent } from "./reconciliation-sub-reports/reconciliation-summary-report/reconciliation-summary-table/reconciliation-summary-table.component";
import { TransactionAnalysisTableComponent } from "./reconciliation-sub-reports/transaction-analysis/transaction-analysis-table/transaction-analysis-table.component";
import { TypeSearchModule } from "../../type-search/type-search.module";
import { AllAnomaliesComponent } from "./reconciliation-sub-reports/anomaly-analysis/all-anomalies/all-anomalies.component";
import { CustomerRatingsComponent } from "./reconciliation-sub-reports/customer-ratings/customer-ratings.component";
import { ThirdPartyPerformanceSummaryComponent } from "./reconciliation-sub-reports/third-party-performance-summary/third-party-performance-summary-report.component";
import { ThirdPartyPerformanceSummaryTableComponent } from "./reconciliation-sub-reports/third-party-performance-summary/third-party-performance-summary-table/third-party-performance-summary-table.component";
import { NetCashReportComponent } from "./reconciliation-sub-reports/net-cash/net-cash-report.component";
import { NetCashTableComponent } from "./reconciliation-sub-reports/net-cash/net-cash-table/net-cash-table.component";
import { FeesSummaryTableComponent } from "./reconciliation-sub-reports/fees-summary-report/fees-summary-table/fees-summary-table.component";
import { FeesSummaryReportComponent } from "./reconciliation-sub-reports/fees-summary-report/fees-summary-report.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { AdjustmentsAnalysisComponent } from "./reconciliation-sub-reports/adjustments-analysis/adjustments-analysis.component";
import { AdjustmentsAnalysisTableComponent } from "./reconciliation-sub-reports/adjustments-analysis/adjustments-analysis-table/adjustments-analysis-table.component";
import { RevenueRecoverySummaryComponent } from "./reconciliation-sub-reports/revenue-recovery-summary/revenue-recovery-summary.component";
import { RevenueRecoverySummaryTableComponent } from "./reconciliation-sub-reports/revenue-recovery-summary/revenue-recovery-table/revenue-recovery-summary-table.component";
import { CanceledOrdersSummaryComponent } from "./reconciliation-sub-reports/canceled-orders-summary/canceled-orders-summary-report.component";
import { CanceledOrdersSummaryTableComponent } from "./reconciliation-sub-reports/canceled-orders-summary/canceled-orders-summary-table/canceled-orders-summary-table.component";
import { TransactionFlagsComponent } from "./reconciliation-sub-reports/transaction-flags/transaction-flags.component";
import { ThirdPartyEcdsConfigurationsComponent } from "./reconciliation-report-settings/third-party-ecds-configs/third-party-ecds-configs.component";
import { DepositReportTableComponent } from "./reconciliation-sub-reports/deposit-report/deposit-report-table/deposit-report-table.component";
import { DepositReportComponent } from "./reconciliation-sub-reports/deposit-report/deposit-report.component";
import { JoyrideModule } from "ngx-joyride";
import { AnalyticsSummaryBreakoutComponent } from "./reconciliation-sub-reports/analytics-summary-breakout/analytics-summary-breakout.component";
import { AnalyticsSummaryBreakoutTableComponent } from "./reconciliation-sub-reports/analytics-summary-breakout/analytics-summary-breakout-table/analytics-summary-breakout-table.component";
import { TransactionMatchComponent } from "./reconciliation-sub-reports/transaction-drill-down/transaction-match/transaction-match.component";
import { ClientPersistantReconciliationNotesComponent } from "./reconciliation-report-settings/client-persistant-reconciliation-notes/client-persistant-reconciliation-notes.component";
import { ClientPersistantReconciliationNoteComponent } from "./reconciliation-report-settings/client-persistant-reconciliation-notes/client-persistant-reconciliation-note/client-persistant-reconciliation-note.component";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};
@NgModule({
  imports: [
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MaterialModule,
    MatSelectModule,
    MatTableModule,
    MatTreeModule,
    MatButtonModule,
    MatCardModule,
    MatSliderModule,
    MatInputModule,
    CommonModule,
    PerfectScrollbarModule,
    DsSharedModule,
    RouterModule,
    TypeSearchModule,
    PriorPeriodAdjustmentsModule,
    UxModule,
    PopoverModule.forRoot(),
    HighchartsChartModule,
    ClipboardModule,
    JoyrideModule.forChild(),
  ],
  declarations: [
    // Setttings Components
    ThirdPartyReportSettingsComponent,
    ThirdPartyStatusConfigurationsTableComponent,
    ThirdPartyStatusConfigurationsComponent,
    ThirdPartyReportThresholdsComponent,
    ThirdPartyReportDefaultFieldsComponent,
    ThirdPartyDefaultFieldsSelectorComponent,
    ClientPersistantReconciliationNotesComponent,
    ClientPersistantReconciliationNoteComponent,
    // Top Level Reporting Components
    NewReportComponent,
    ReportsComponent,
    // Sub-reports
    AnomalyAnalysisComponent,
    CustomerRatingsComponent,
    DailyDrillDownComponent,
    DataValidationReportComponent,
    DispatchSummaryReport,
    ErrorSummaryComponent,
    FeeAnalysisComponent,
    PayoutAnalysisComponent,
    ReconciliationComponent,
    ReconciliationChartsComponent,
    ReconciliationSummaryReportComponent,
    ReconciliationtNoteComponent,
    ReconciliationNotesComponent,
    ReconciliationReportComponent,
    StatusDrillDownComponent,
    TransactionAnalysisComponent,
    TaxSummaryReportComponent,
    VarianceAnalysisComponent,
    PotentialFraudReportComponent,
    TransactionDrillDownComponent,
    TaxRateAnalysisComponent,
    ThirdPartyPerformanceSummaryComponent,
    FeesSummaryReportComponent,
    AdjustmentsAnalysisComponent,
    // Data Table Components
    DataValidationTableComponent,
    DispatchSummaryTableComponent,
    ErrorSummaryTableComponent,
    FeeDrillDownTableComponent,
    PayoutAnalysisTableComponent,
    PotentialFraudReportTableComponent,
    ReconciliationTableComponent,
    TaxSummaryTableComponent,
    StatusDrillDownTableComponent,
    TransactionAnalysisTableComponent,
    TransactionDrillDownTableComponent,
    TaxRateAnalysisTableComponent,
    VarianceAnalysisTableComponent,
    ReconciliationSummaryTableComponent,
    AllAnomaliesComponent,
    ThirdPartyPerformanceSummaryTableComponent,
    NetCashReportComponent,
    NetCashTableComponent,
    FeesSummaryTableComponent,
    AdjustmentsAnalysisTableComponent,
    RevenueRecoverySummaryComponent,
    RevenueRecoverySummaryTableComponent,
    CanceledOrdersSummaryComponent,
    CanceledOrdersSummaryTableComponent,
    TransactionFlagsComponent,
    ThirdPartyEcdsConfigurationsComponent,
    DepositReportTableComponent,
    DepositReportComponent,
    AnalyticsSummaryBreakoutComponent,
    AnalyticsSummaryBreakoutTableComponent,
    TransactionMatchComponent,
  ],
  exports: [
    // Setttings Components
    ThirdPartyReportSettingsComponent,
    ThirdPartyStatusConfigurationsTableComponent,
    ThirdPartyStatusConfigurationsComponent,
    ThirdPartyReportThresholdsComponent,
    ThirdPartyReportDefaultFieldsComponent,
    ThirdPartyDefaultFieldsSelectorComponent,
    ClientPersistantReconciliationNotesComponent,
    ClientPersistantReconciliationNoteComponent,
    // Top Level Reporting Components
    NewReportComponent,
    ReportsComponent,
    // Sub-reports
    AnomalyAnalysisComponent,
    CustomerRatingsComponent,
    DailyDrillDownComponent,
    DataValidationReportComponent,
    DispatchSummaryReport,
    ErrorSummaryComponent,
    FeeAnalysisComponent,
    PayoutAnalysisComponent,
    ReconciliationComponent,
    ReconciliationChartsComponent,
    ReconciliationSummaryReportComponent,
    ReconciliationtNoteComponent,
    ReconciliationNotesComponent,
    ReconciliationReportComponent,
    StatusDrillDownComponent,
    TransactionAnalysisComponent,
    TaxSummaryReportComponent,
    VarianceAnalysisComponent,
    PotentialFraudReportComponent,
    TransactionDrillDownComponent,
    TaxRateAnalysisComponent,
    ThirdPartyPerformanceSummaryComponent,
    FeesSummaryReportComponent,
    AdjustmentsAnalysisComponent,
    // Data Table Components
    AllAnomaliesComponent,
    DataValidationTableComponent,
    DispatchSummaryTableComponent,
    ErrorSummaryTableComponent,
    FeeDrillDownTableComponent,
    PayoutAnalysisTableComponent,
    PotentialFraudReportTableComponent,
    ReconciliationTableComponent,
    TaxSummaryTableComponent,
    StatusDrillDownTableComponent,
    TransactionAnalysisTableComponent,
    TransactionDrillDownTableComponent,
    TaxRateAnalysisTableComponent,
    VarianceAnalysisTableComponent,
    ReconciliationSummaryTableComponent,
    ThirdPartyPerformanceSummaryTableComponent,
    NetCashReportComponent,
    NetCashTableComponent,
    FeesSummaryTableComponent,
    AdjustmentsAnalysisTableComponent,
    RevenueRecoverySummaryComponent,
    RevenueRecoverySummaryTableComponent,
    CanceledOrdersSummaryComponent,
    CanceledOrdersSummaryTableComponent,
    TransactionFlagsComponent,
    ThirdPartyEcdsConfigurationsComponent,
    DepositReportTableComponent,
    DepositReportComponent,
    AnalyticsSummaryBreakoutComponent,
    AnalyticsSummaryBreakoutTableComponent,
    TransactionMatchComponent,
  ],

  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReconciliationReportsModule {}
