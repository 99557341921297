import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "lodash";
import { Papa } from "ngx-papaparse";
import {
  Client,
  User,
  ThirdPartyReportFragmentDispatchReport,
  ThirdPartyReportFragment,
} from "@deliver-sense-librarian/data-schema";
import { AngularFirestore } from "@angular/fire/firestore";
import { Subject } from "rxjs";
import { Store } from "@ngrx/store";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { first } from "rxjs/operators";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import { ReconciliationReportDatUtility } from "../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../utilities/reconciliation-report-filtering.utility";
@Component({
  selector: "app-dispatch-summary-report",
  templateUrl: "./dispatch-summary-report.component.html",
  styleUrls: ["./dispatch-summary-report.component.scss"],
})
export class DispatchSummaryReport implements OnInit, OnDestroy {
  @Input() exportUtility: ReconciliationReportExportUtility;
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  public dispatchReports: ThirdPartyReportFragmentDispatchReport[] = [];
  public loadingFragmentReports = false;
  private destroy$ = new Subject();
  constructor() {}

  ngOnInit() {
    this.fetchDispatchSummaryData();
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private async fetchDispatchSummaryData() {
    this.loadingFragmentReports = true;
    this.dispatchReports = (
      await this.dataUtility.fetchDispatchSummaryData()
    ).filter(
      (report) =>
        !!this.dataUtility.locations.find(
          (location) => location.locationId === report.location
        )
    );
    this.loadingFragmentReports = false;
  }
}
