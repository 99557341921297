import { Workbook } from "exceljs";
import {
  ReconciliationReportDatUtility,
  ReconciliationDrillDownReportTypes,
} from "./reconciliation-report-data.utility";
import { AngularFirestore } from "@angular/fire/firestore";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import {
  PriorPeriodAdjustmentTransaction,
  StandardJournalEntry,
  ThirdParty,
  ThirdPartyReportFragmentVarianceAnalysisReport,
  TransactionAITypes,
  Location,
  ClientJeHeaderConfiguration,
  ClientJeValueConfiguration,
  PayoutReportFragmentLog,
  ThirdPartyPayout,
  DataConverter,
  ThirdPartyTransaction,
  ThirdPartyDeliveryIds,
  ClientJeHeaderConfigurationGroup,
  ThirdPartyReconciliationLocationData,
} from "@deliver-sense-librarian/data-schema";
import moment from "moment";
import { first } from "rxjs/operators";
import _ from "lodash";

class JEFieldValues {
  constructor() {}
  public JOURNAL: any;
  public DATE: any;
  public DESCRIPTION: any;
  public ACCT: any;
  public DEPT: any;
  public LOCATION: any;
  public MEMO: any;
  public DEBIT: any;
  public CREDIT: any;
  public DETAIL1: any;
  public DETAIL2: any;
}
export class JournalEntryGenerator {
  private headerRow: string[];
  private availableJournalEntries: StandardJournalEntry[] = [];
  clientJeHeaderConfigurations: ClientJeHeaderConfiguration[] = [];
  clientJeValueConfigurations: ClientJeValueConfiguration[] = [];
  constructor(
    private dataUtility: ReconciliationReportDatUtility,
    private afs: AngularFirestore,
    private workbook: Workbook,
    private reportLocations: Location[],
    private selectedReports: string[],
    private jeHeaderSelection?: ClientJeHeaderConfigurationGroup
  ) {}
  private async initializeConfigurationData() {
    await Promise.all([
      this.fetchClientJeValueMappings(),
      this.fetchClientJeHeaderConfigurations(),
      this.fetchClientJeValueConfigurations(),
    ]);
    this.setJeHeaderRow();
  }

  async generateAndAttachJournalEntries() {
    await this.initializeConfigurationData();
    const jeExecutions = [];
    if (this.selectedReports.indexOf("Customer Refunds") > -1) {
      const customerRefundsJeSheet = this.workbook.addWorksheet(
        "Customer Refunds JE"
      );
      jeExecutions.push(
        this.compileCustomerRefundsJe(
          customerRefundsJeSheet,
          this.reportLocations
        )
      );
    }
    if (this.selectedReports.indexOf("Fees Aggregated") > -1) {
      const customerRefundsJeSheet =
        this.workbook.addWorksheet("Fees Aggregate JE");
      jeExecutions.push(
        this.compileFeeAggregateJe(customerRefundsJeSheet, this.reportLocations)
      );
    }
    if (this.selectedReports.indexOf("Fees Detailed") > -1) {
      const customerRefundsJeSheet =
        this.workbook.addWorksheet("Fees Detailed JE");
      jeExecutions.push(
        this.compileFeeDetailJe(customerRefundsJeSheet, this.reportLocations)
      );
    }
    if (this.selectedReports.indexOf("Sales Tax Adjustment") > -1) {
      const taxAdjJeSheet = this.workbook.addWorksheet(
        "Sales Tax Adjustment JE"
      );
      jeExecutions.push(
        this.compileTaxAdjJe(taxAdjJeSheet, this.reportLocations)
      );
    }

    if (this.selectedReports.indexOf("Sales Variance Aggregated") > -1) {
      const salesVarAggSheet = this.workbook.addWorksheet(
        "Sales Variance Aggregated JE"
      );
      jeExecutions.push(
        this.compileSaleVarAggregateJe(salesVarAggSheet, this.reportLocations)
      );
    }
    if (this.selectedReports.indexOf("Sales Variance Detailed") > -1) {
      const saleVarDetailSheet = this.workbook.addWorksheet(
        "Sales Variance Detailed JE"
      );
      jeExecutions.push(
        this.compileSaleVarDetailJe(saleVarDetailSheet, this.reportLocations)
      );
    }
    if (this.selectedReports.indexOf("Deposit Details") > -1) {
      const depositDetailSheet =
        this.workbook.addWorksheet("Deposit Details JE");
      jeExecutions.push(
        this.compileDepositDetailJe(depositDetailSheet, this.reportLocations)
      );
    }
    if (this.selectedReports.indexOf("Unreported Deposit Difference") > -1) {
      const unreportedDiffSheet = this.workbook.addWorksheet(
        "Unreported Deposit Diff JE"
      );
      jeExecutions.push(
        this.compileUnreportedDiffJe(unreportedDiffSheet, this.reportLocations)
      );
    }
    if (this.selectedReports.indexOf("Tips Payable") > -1) {
      const tipsPayableJeSheet = this.workbook.addWorksheet("Tips Payable JE");
      jeExecutions.push(
        this.complieTipsPayableJe(tipsPayableJeSheet, this.reportLocations)
      );
    }
    if (this.selectedReports.indexOf("Refund and Adjustment Tax") > -1) {
      const refundAdjTaxSheet = this.workbook.addWorksheet(
        "Refund and Adjustment Tax JE"
      );
      jeExecutions.push(
        this.compileRefundAdjTaxJe(refundAdjTaxSheet, this.reportLocations)
      );
    }
    if (this.selectedReports.indexOf("POS Markup") > -1) {
      const posMarkupSheet = this.workbook.addWorksheet("POS Markup JE");
      jeExecutions.push(
        this.compilePosMarkupJe(posMarkupSheet, this.reportLocations)
      );
    }
    if (this.selectedReports.indexOf("Other Revenue") > -1) {
      const otherRevenueSheet = this.workbook.addWorksheet("Other Revenue JE");
      jeExecutions.push(
        this.complieOtherRevenueJe(otherRevenueSheet, this.reportLocations)
      );
    }
    if (this.selectedReports.indexOf("Deposit Aggregated") > -1) {
      const depositAggregateSheet =
        this.workbook.addWorksheet("Deposit Aggregate");
      jeExecutions.push(
        this.compileDepositAggJe(depositAggregateSheet, this.reportLocations)
      );
    }
    return await Promise.all(jeExecutions);
  }
  async compilePosMarkupJe(posMarkupSheet, reportLocations) {
    posMarkupSheet.addRow(this.headerRow);
    let rowNumber = 2;
    reportLocations.forEach((location) => {
      this.dataUtility.thirdParties.forEach((thirdParty) => {
        const locationReportData = this.dataUtility.reportData.find(
          (data) =>
            data.locationId == location.locationId &&
            data.thirdParty === thirdParty.id
        );
        if (locationReportData) {
          [
            {
              name: "Sale Adjustment",
              amount: locationReportData.posSalesAdjustments,
            },
            {
              name: "Tax Adjustment",
              amount: locationReportData.posTaxAdjustments,
            },
          ].forEach((entry) => {
            const configurableOffsetAccountFieldValues =
              this.setStandardJeValueOverrides(
                "recordPosMarkup",
                true,
                location,
                thirdParty,
                entry.name,
                entry.amount
              );
            const configurablePrimaryAccountFieldValues =
              this.setStandardJeValueOverrides(
                "recordPosMarkup",
                false,
                location,
                thirdParty,
                entry.name,
                entry.amount
              );
            const offsetDebit = +this.computeEntryValue(
              configurableOffsetAccountFieldValues,
              entry.amount,
              true
            );
            const offsetCredit = +this.computeEntryValue(
              configurableOffsetAccountFieldValues,
              entry.amount,
              false
            );
            const primaryDebit = +this.computeEntryValue(
              configurablePrimaryAccountFieldValues,
              entry.amount,
              true
            );
            const primaryCredit = +this.computeEntryValue(
              configurablePrimaryAccountFieldValues,
              entry.amount,
              false
            );
            const isEntrySkipped =
              offsetCredit + offsetDebit + primaryCredit + primaryDebit === 0;
            if (!isEntrySkipped) {
              //OFFSET ROW
              posMarkupSheet.addRow(
                this.sortColumnValues([
                  configurableOffsetAccountFieldValues.JOURNAL
                    ? configurableOffsetAccountFieldValues.JOURNAL
                    : "", // JOURNAL
                  configurableOffsetAccountFieldValues.DATE
                    ? configurableOffsetAccountFieldValues.DATE
                    : this.dataUtility.endDateText, // DATE
                  configurableOffsetAccountFieldValues.DESCRIPTION
                    ? configurableOffsetAccountFieldValues.DESCRIPTION
                    : `POS MARKUP - ${this.dataUtility.existingReport.name}`, //DESCRIPTION
                  configurableOffsetAccountFieldValues.ACCT
                    ? configurableOffsetAccountFieldValues.ACCT
                    : `ex. 1000`, //ACCNT
                  configurableOffsetAccountFieldValues.DEPT
                    ? configurableOffsetAccountFieldValues.DEPT
                    : `ex. D1123`, //DEPT
                  configurableOffsetAccountFieldValues.LOCATION
                    ? configurableOffsetAccountFieldValues.LOCATION
                    : location.locationId, //LOCATION
                  configurableOffsetAccountFieldValues.MEMO
                    ? configurableOffsetAccountFieldValues.MEMO
                    : `${thirdParty.name} - ${entry.name}`, // MEMO
                  +offsetDebit >= 0 ? offsetDebit : 0,
                  +offsetCredit < 0 ? Math.abs(+offsetCredit) : 0,
                  configurableOffsetAccountFieldValues.DETAIL1
                    ? configurableOffsetAccountFieldValues.DETAIL1
                    : "",
                  configurableOffsetAccountFieldValues.DETAIL2
                    ? configurableOffsetAccountFieldValues.DETAIL2
                    : "",
                ])
              );
              rowNumber++;
              // NON OFFSET ROW
              posMarkupSheet.addRow(
                this.sortColumnValues([
                  configurablePrimaryAccountFieldValues.JOURNAL
                    ? configurablePrimaryAccountFieldValues.JOURNAL
                    : "", // JOURNAL
                  configurablePrimaryAccountFieldValues.DATE
                    ? configurablePrimaryAccountFieldValues.DATE
                    : this.dataUtility.endDateText, // DATE
                  configurablePrimaryAccountFieldValues.DESCRIPTION
                    ? configurablePrimaryAccountFieldValues.DESCRIPTION
                    : `POS MARKUP - ${this.dataUtility.existingReport.name}`, //DESCRIPTION
                  configurablePrimaryAccountFieldValues.ACCT
                    ? configurablePrimaryAccountFieldValues.ACCT
                    : `ex. 7000`, //ACCNT
                  configurablePrimaryAccountFieldValues.DEPT
                    ? configurablePrimaryAccountFieldValues.DEPT
                    : `ex. D1123`, //DEPT
                  configurablePrimaryAccountFieldValues.LOCATION
                    ? configurablePrimaryAccountFieldValues.LOCATION
                    : location.locationId, //LOCATION
                  configurablePrimaryAccountFieldValues.MEMO
                    ? configurablePrimaryAccountFieldValues.MEMO
                    : `${thirdParty.name} - ${entry.name}`, // MEMO

                  +primaryDebit < 0 ? Math.abs(+primaryDebit) : 0,
                  +primaryCredit >= 0 ? primaryCredit : 0,
                  configurablePrimaryAccountFieldValues.DETAIL1
                    ? configurablePrimaryAccountFieldValues.DETAIL1
                    : "",
                  configurablePrimaryAccountFieldValues.DETAIL2
                    ? configurablePrimaryAccountFieldValues.DETAIL2
                    : "",
                ])
              );
            }
          });
        }
      });
    });
  }
  async complieOtherRevenueJe(otherRevenueSheet, reportLocations) {
    otherRevenueSheet.addRow(this.headerRow);
    let rowNumber = 2;
    reportLocations.forEach((location) => {
      this.dataUtility.thirdParties.forEach((thirdParty) => {
        const locationReportData = this.dataUtility.reportData.find(
          (data) =>
            data.locationId == location.locationId &&
            data.thirdParty === thirdParty.id
        );
        if (locationReportData) {
          [
            {
              name: "Other Revenue",
              amount: locationReportData.thirdPartyOtherRevenue,
            },
          ].forEach((entry) => {
            const configurableOffsetAccountFieldValues =
              this.setStandardJeValueOverrides(
                "recordOtherRevenue",
                true,
                location,
                thirdParty,
                entry.name,
                entry.amount
              );
            const configurablePrimaryAccountFieldValues =
              this.setStandardJeValueOverrides(
                "recordOtherRevenue",
                false,
                location,
                thirdParty,
                entry.name,
                entry.amount
              );
            const offsetDebit = +this.computeEntryValue(
              configurableOffsetAccountFieldValues,
              entry.amount,
              true
            );
            const offsetCredit = +this.computeEntryValue(
              configurableOffsetAccountFieldValues,
              entry.amount,
              false
            );
            const primaryDebit = +this.computeEntryValue(
              configurablePrimaryAccountFieldValues,
              entry.amount,
              true
            );
            const primaryCredit = +this.computeEntryValue(
              configurablePrimaryAccountFieldValues,
              entry.amount,
              false
            );
            const isEntrySkipped =
              offsetCredit + offsetDebit + primaryCredit + primaryDebit === 0;
            if (!isEntrySkipped) {
              //OFFSET ROW
              otherRevenueSheet.addRow(
                this.sortColumnValues([
                  configurableOffsetAccountFieldValues.JOURNAL
                    ? configurableOffsetAccountFieldValues.JOURNAL
                    : "", // JOURNAL
                  configurableOffsetAccountFieldValues.DATE
                    ? configurableOffsetAccountFieldValues.DATE
                    : this.dataUtility.endDateText, // DATE
                  configurableOffsetAccountFieldValues.DESCRIPTION
                    ? configurableOffsetAccountFieldValues.DESCRIPTION
                    : `DSP TIPS PAYABLE - ${this.dataUtility.existingReport.name}`, //DESCRIPTION
                  configurableOffsetAccountFieldValues.ACCT
                    ? configurableOffsetAccountFieldValues.ACCT
                    : `ex. 1000`, //ACCNT
                  configurableOffsetAccountFieldValues.DEPT
                    ? configurableOffsetAccountFieldValues.DEPT
                    : `ex. D1123`, //DEPT
                  configurableOffsetAccountFieldValues.LOCATION
                    ? configurableOffsetAccountFieldValues.LOCATION
                    : location.locationId, //LOCATION
                  configurableOffsetAccountFieldValues.MEMO
                    ? configurableOffsetAccountFieldValues.MEMO
                    : `${thirdParty.name} - Tips Payable`, // MEMO
                  +offsetDebit < 0 ? Math.abs(+offsetDebit) : 0,
                  +offsetCredit >= 0 ? +offsetCredit : 0,
                  configurableOffsetAccountFieldValues.DETAIL1
                    ? configurableOffsetAccountFieldValues.DETAIL1
                    : "",
                  configurableOffsetAccountFieldValues.DETAIL2
                    ? configurableOffsetAccountFieldValues.DETAIL2
                    : "",
                ])
              );
              rowNumber++;
              // NON OFFSET ROW

              otherRevenueSheet.addRow(
                this.sortColumnValues([
                  configurablePrimaryAccountFieldValues.JOURNAL
                    ? configurablePrimaryAccountFieldValues.JOURNAL
                    : "", // JOURNAL
                  configurablePrimaryAccountFieldValues.DATE
                    ? configurablePrimaryAccountFieldValues.DATE
                    : this.dataUtility.endDateText, // DATE
                  configurablePrimaryAccountFieldValues.DESCRIPTION
                    ? configurablePrimaryAccountFieldValues.DESCRIPTION
                    : `DSP TIPS PAYABLE - ${this.dataUtility.existingReport.name}`, //DESCRIPTION
                  configurablePrimaryAccountFieldValues.ACCT
                    ? configurablePrimaryAccountFieldValues.ACCT
                    : `ex. 7000`, //ACCNT
                  configurablePrimaryAccountFieldValues.DEPT
                    ? configurablePrimaryAccountFieldValues.DEPT
                    : `ex. D1123`, //DEPT
                  configurablePrimaryAccountFieldValues.LOCATION
                    ? configurablePrimaryAccountFieldValues.LOCATION
                    : location.locationId, //LOCATION
                  configurablePrimaryAccountFieldValues.MEMO
                    ? configurablePrimaryAccountFieldValues.MEMO
                    : `${thirdParty.name} - ${entry.name}`, // MEMO
                  +primaryDebit >= 0 ? +primaryDebit : 0,
                  +primaryCredit < 0 ? Math.abs(+primaryCredit) : 0,
                  configurablePrimaryAccountFieldValues.DETAIL1
                    ? configurablePrimaryAccountFieldValues.DETAIL1
                    : "",
                  configurablePrimaryAccountFieldValues.DETAIL2
                    ? configurablePrimaryAccountFieldValues.DETAIL2
                    : "",
                ])
              );
            }
          });
        }
      });
    });
  }
  async compileCustomerRefundsJe(customerRefundsJeSheet, reportLocations) {
    customerRefundsJeSheet.addRow(this.headerRow);
    let rowNumber = 2;
    const analyticsSummaries =
      await this.dataUtility.compileAnalyticsBreakoutSummaries(reportLocations);
    const ppaTransactions = await this.dataUtility.fetchPriorPeriodAdjustments(
      this.dataUtility.locations
    );
    reportLocations.forEach((location) => {
      this.dataUtility.thirdParties.forEach((thirdParty) => {
        const analyticSummary = analyticsSummaries.find(
          (summary) =>
            summary.location === location.locationId &&
            summary.thirdParty === thirdParty.id
        );
        const matchingPpaRefundTransactions = ppaTransactions.filter((ppat) => {
          return (
            ppat.location === location.locationId &&
            ppat.account === thirdParty.id &&
            ppat.isErrorCharge
          );
        });
        const ppaRefundTransactionsAmount =
          -matchingPpaRefundTransactions.reduce((sum: number, ppat) => {
            // determine the amount of the cutomer refund (DoorDash in transaction.saleCorrection, all other DSPS in transaction.sale)
            const amount =
              ppat.transaction.sale !== 0
                ? ppat.transaction.sale
                : ppat.transaction.saleCorrection !== 0
                ? ppat.transaction.saleCorrection
                : 0;
            return (sum += amount);
          }, 0);
        const value =
          Math.abs(analyticSummary.errorCharges) +
          Math.abs(ppaRefundTransactionsAmount);
        const configurableOffsetAccountFieldValues =
          this.setStandardJeValueOverrides(
            "recordCustomerRefunds",
            true,
            location,
            thirdParty,
            null,
            value
          );
        const configurablePrimaryAccountFieldValues =
          this.setStandardJeValueOverrides(
            "recordCustomerRefunds",
            false,
            location,
            thirdParty,
            null,
            value
          );
        if (
          !this.isConfiguredDebitCreditZero(
            configurableOffsetAccountFieldValues,
            configurablePrimaryAccountFieldValues,
            value
          )
        ) {
          customerRefundsJeSheet.addRow(
            this.sortColumnValues([
              configurableOffsetAccountFieldValues.JOURNAL
                ? configurableOffsetAccountFieldValues.JOURNAL
                : "", // JOURNAL
              configurableOffsetAccountFieldValues.DATE
                ? configurableOffsetAccountFieldValues.DATE
                : this.dataUtility.endDateText, // DATE
              configurableOffsetAccountFieldValues.DESCRIPTION
                ? configurableOffsetAccountFieldValues.DESCRIPTION
                : `DSP CUSTOMER REFUNDS - ${this.dataUtility.existingReport.name}`,
              //DESCRIPTION
              configurableOffsetAccountFieldValues.ACCT
                ? configurableOffsetAccountFieldValues.ACCT
                : `ex. 1000`, //ACCNT
              configurableOffsetAccountFieldValues.DEPT
                ? configurableOffsetAccountFieldValues.DEPT
                : `ex. D1123`, //DEPT
              configurableOffsetAccountFieldValues.LOCATION
                ? configurableOffsetAccountFieldValues.LOCATION
                : location.locationId, //LOCATION
              configurableOffsetAccountFieldValues.MEMO
                ? configurableOffsetAccountFieldValues.MEMO
                : `${thirdParty.name} - Customer Refunds`, // MEMO
              configurableOffsetAccountFieldValues.DEBIT
                ? configurableOffsetAccountFieldValues.DEBIT
                : 0,
              configurableOffsetAccountFieldValues.CREDIT
                ? configurableOffsetAccountFieldValues.CREDIT
                : value,
              configurableOffsetAccountFieldValues.DETAIL1
                ? configurableOffsetAccountFieldValues.DETAIL1
                : "",
              configurableOffsetAccountFieldValues.DETAIL2
                ? configurableOffsetAccountFieldValues.DETAIL2
                : "",
            ])
          );
          rowNumber++;
          customerRefundsJeSheet.addRow(
            this.sortColumnValues([
              configurablePrimaryAccountFieldValues.JOURNAL
                ? configurablePrimaryAccountFieldValues.JOURNAL
                : "", // JOURNAL
              configurablePrimaryAccountFieldValues.DATE
                ? configurablePrimaryAccountFieldValues.DATE
                : this.dataUtility.endDateText, // DATE
              configurablePrimaryAccountFieldValues.DESCRIPTION
                ? configurablePrimaryAccountFieldValues.DESCRIPTION
                : `DSP CUSTOMER REFUNDS - ${this.dataUtility.existingReport.name}`,
              //DESCRIPTION
              configurablePrimaryAccountFieldValues.ACCT
                ? configurablePrimaryAccountFieldValues.ACCT
                : `ex. 7000`, //ACCNT
              configurablePrimaryAccountFieldValues.DEPT
                ? configurablePrimaryAccountFieldValues.DEPT
                : `ex. D1123`, //DEPT
              configurablePrimaryAccountFieldValues.LOCATION
                ? configurablePrimaryAccountFieldValues.LOCATION
                : location.locationId, //LOCATION
              configurablePrimaryAccountFieldValues.MEMO
                ? configurablePrimaryAccountFieldValues.MEMO
                : `${thirdParty.name} - Customer Refunds`, // MEMO
              configurablePrimaryAccountFieldValues.DEBIT
                ? configurablePrimaryAccountFieldValues.DEBIT
                : value,
              configurablePrimaryAccountFieldValues.CREDIT
                ? configurablePrimaryAccountFieldValues.CREDIT
                : 0,
              configurablePrimaryAccountFieldValues.DETAIL1
                ? configurablePrimaryAccountFieldValues.DETAIL1
                : "",
              configurablePrimaryAccountFieldValues.DETAIL2
                ? configurablePrimaryAccountFieldValues.DETAIL2
                : "",
            ])
          );
          rowNumber++;
        }
      });
    });
  }

  async compileFeeAggregateJe(feeAggregateJeSheet, reportLocations) {
    feeAggregateJeSheet.addRow(this.headerRow);
    let rowNumber = 2;
    reportLocations.forEach((location) => {
      this.dataUtility.thirdParties.forEach((thirdParty) => {
        const configurableOffsetAccountFieldValues =
          this.setStandardJeValueOverrides(
            "recordFeesAggregate",
            true,
            location,
            thirdParty
          );
        const configurablePrimaryAccountFieldValues =
          this.setStandardJeValueOverrides(
            "recordFeesAggregate",
            false,
            location,
            thirdParty
          );
        feeAggregateJeSheet.addRow(
          this.sortColumnValues([
            configurableOffsetAccountFieldValues.JOURNAL
              ? configurableOffsetAccountFieldValues.JOURNAL
              : "", // JOURNAL
            configurableOffsetAccountFieldValues.DATE
              ? configurableOffsetAccountFieldValues.DATE
              : this.dataUtility.endDateText, // DATE
            configurableOffsetAccountFieldValues.DESCRIPTION
              ? configurableOffsetAccountFieldValues.DESCRIPTION
              : `DSP COMMISSION FEES - ${this.dataUtility.existingReport.name}`,
            //DESCRIPTION
            configurableOffsetAccountFieldValues.ACCT
              ? configurableOffsetAccountFieldValues.ACCT
              : `ex. 1100`, //ACCNT
            configurableOffsetAccountFieldValues.DEPT
              ? configurableOffsetAccountFieldValues.DEPT
              : `ex. D1123`, //DEPT
            configurableOffsetAccountFieldValues.LOCATION
              ? configurableOffsetAccountFieldValues.LOCATION
              : location.locationId, //LOCATION
            configurableOffsetAccountFieldValues.MEMO
              ? configurableOffsetAccountFieldValues.MEMO
              : `${thirdParty.name} - Commission Expense`, // MEMO
            {
              id: "commissionAgg",
              formula: `IF(SUMIFS('Fees Summary'!H:H,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}") + SUMIFS('Fees Summary'!I:I,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}") + SUMIFS('Fees Summary'!J:J,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}") > 0, ABS(SUMIFS('Fees Summary'!H:H,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}") + SUMIFS('Fees Summary'!I:I,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}") + SUMIFS('Fees Summary'!J:J,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}")),0)`,
            },
            {
              id: "commissionAgg",
              formula: `IF(SUMIFS('Fees Summary'!H:H,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}") + SUMIFS('Fees Summary'!I:I,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}") + SUMIFS('Fees Summary'!J:J,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}") < 0, ABS(SUMIFS('Fees Summary'!H:H,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}") + SUMIFS('Fees Summary'!I:I,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}") + SUMIFS('Fees Summary'!J:J,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}")),0)`,
            },
            configurableOffsetAccountFieldValues.DETAIL1
              ? configurableOffsetAccountFieldValues.DETAIL1
              : "",
            configurableOffsetAccountFieldValues.DETAIL2
              ? configurableOffsetAccountFieldValues.DETAIL2
              : "",
          ])
        );
        rowNumber++;
        feeAggregateJeSheet.addRow(
          this.sortColumnValues([
            configurablePrimaryAccountFieldValues.JOURNAL
              ? configurablePrimaryAccountFieldValues.JOURNAL
              : "", // JOURNAL
            configurablePrimaryAccountFieldValues.DATE
              ? configurablePrimaryAccountFieldValues.DATE
              : this.dataUtility.endDateText, // DATE
            configurablePrimaryAccountFieldValues.DESCRIPTION
              ? configurablePrimaryAccountFieldValues.DESCRIPTION
              : `DSP COMMISSION FEES - ${this.dataUtility.existingReport.name}`,
            //DESCRIPTION
            configurablePrimaryAccountFieldValues.ACCT
              ? configurablePrimaryAccountFieldValues.ACCT
              : `ex. 7700`, //ACCNT
            configurablePrimaryAccountFieldValues.DEPT
              ? configurablePrimaryAccountFieldValues.DEPT
              : `ex. D1123`, //DEPT
            configurablePrimaryAccountFieldValues.LOCATION
              ? configurablePrimaryAccountFieldValues.LOCATION
              : location.locationId, //LOCATION
            configurablePrimaryAccountFieldValues.MEMO
              ? configurablePrimaryAccountFieldValues.MEMO
              : `${thirdParty.name} - Commission Expense`, // MEMO
            {
              id: "commissionAgg",
              formula: `IF(SUMIFS('Fees Summary'!H:H,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}") + SUMIFS('Fees Summary'!I:I,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}") + SUMIFS('Fees Summary'!J:J,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}") < 0, ABS(SUMIFS('Fees Summary'!H:H,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}") + SUMIFS('Fees Summary'!I:I,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}") + SUMIFS('Fees Summary'!J:J,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}")),0)`,
            },
            {
              id: "commissionAgg",
              formula: `IF(SUMIFS('Fees Summary'!H:H,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}") + SUMIFS('Fees Summary'!I:I,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}") + SUMIFS('Fees Summary'!J:J,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}") > 0, ABS(SUMIFS('Fees Summary'!H:H,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}") + SUMIFS('Fees Summary'!I:I,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}") + SUMIFS('Fees Summary'!J:J,'Fees Summary'!A:A,"${location.locationId}",'Fees Summary'!B:B,"${thirdParty.name}")),0)`,
            },
            configurablePrimaryAccountFieldValues.DETAIL1
              ? configurablePrimaryAccountFieldValues.DETAIL1
              : "",
            configurablePrimaryAccountFieldValues.DETAIL2
              ? configurablePrimaryAccountFieldValues.DETAIL2
              : "",
          ])
        );
      });
    });
  }
  async compileRefundAdjTaxJe(refundAdjTaxSheet, reportLocations) {
    const thirdPartyReportVarianceAnalysisReports =
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.varianceAnalysisReports
      );
    const ppaTransactions = await this.dataUtility.fetchPriorPeriodAdjustments(
      this.dataUtility.locations
    );
    refundAdjTaxSheet.addRow(this.headerRow);
    let rowNumber = 2;
    reportLocations.forEach((location: Location) => {
      this.dataUtility.thirdParties.forEach((thirdParty) => {
        const locationReportData = this.dataUtility.reportData.find(
          (data) =>
            data.locationId == location.locationId &&
            data.thirdParty === thirdParty.id
        );
        let locationTaxRate =
          location.stateTaxRate +
          location.countyTaxRate +
          location.cityTaxRate +
          location.specialTaxRate;
        if (locationReportData.mfTaxApplicable) {
          if (locationReportData.isPartialMfTax) {
            locationTaxRate =
              location.mfRateNotes?.length > 0
                ? location.mfRateNotes[0].rate
                : 0;
          } else {
            locationTaxRate = 0;
          }
        }
        if (locationTaxRate > 0) {
          const varianceAnalysis = thirdPartyReportVarianceAnalysisReports.find(
            (report) =>
              report.location === location.locationId &&
              report.thirdParty === thirdParty.id
          );
          const vaRefunds =
            thirdParty.id === ThirdPartyDeliveryIds["Uber Eats"] ||
            thirdParty.duplicateOf === ThirdPartyDeliveryIds["Uber Eats"]
              ? 0
              : varianceAnalysis.salesVarianceReasons.find(
                  (reason: any) =>
                    reason.type === TransactionAITypes.errorCharge
                ).sale;
          const vaAdjustments =
            thirdParty.id === ThirdPartyDeliveryIds["Uber Eats"] ||
            thirdParty.duplicateOf === ThirdPartyDeliveryIds["Uber Eats"]
              ? 0
              : varianceAnalysis.salesVarianceReasons.find(
                  (reason: any) => reason.type === TransactionAITypes.adjustment
                ).sale;
          const matchingPpaRefundTransactions = ppaTransactions.filter(
            (ppat) => {
              return (
                ppat.location === location.locationId &&
                ppat.account === thirdParty.id &&
                ppat.isErrorCharge
              );
            }
          );
          const ppaRefundAmount = -matchingPpaRefundTransactions.reduce(
            (sum: number, ppat) => {
              // determine the amount of the cutomer refund (DoorDash in transaction.saleCorrection, all other DSPS in transaction.sale)
              const amount =
                ppat.transaction.sale !== 0
                  ? ppat.transaction.sale
                  : ppat.transaction.saleCorrection !== 0
                  ? ppat.transaction.saleCorrection
                  : 0;
              return (sum += amount);
            },
            0
          );

          const matchingPpaAdjustmentTransactions = ppaTransactions.filter(
            (ppat) => {
              return (
                ppat.location === location.locationId &&
                ppat.account === thirdParty.id &&
                ppat.isAdjustment
              );
            }
          );
          const ppaAdjustemntAmount = -matchingPpaAdjustmentTransactions.reduce(
            (sum: number, ppat) => {
              // determine the amount of the cutomer refund (DoorDash in transaction.saleCorrection, all other DSPS in transaction.sale)
              const amount =
                ppat.transaction.sale !== 0
                  ? ppat.transaction.sale
                  : ppat.transaction.saleCorrection !== 0
                  ? ppat.transaction.saleCorrection
                  : 0;
              return (sum += amount);
            },
            0
          );

          if (locationReportData) {
            [
              {
                name: "Refunds Tax",
                amount: vaRefunds,
              },
              {
                name: "Adjustments Tax",
                amount: vaAdjustments,
              },
              { name: "Prior Periods Refund Tax", amount: ppaRefundAmount },
              {
                name: "Prior Periods Adjustment Tax",
                amount: ppaAdjustemntAmount,
              },
            ].forEach((entry) => {
              if (entry.amount) {
                const entryTax = +(
                  entry.amount -
                  entry.amount / (1 + locationTaxRate / 100)
                ).toFixed(2);
                const configurableOffsetAccountFieldValues =
                  this.setStandardJeValueOverrides(
                    "recordRefundAdjustmentTax",
                    true,
                    location,
                    thirdParty,
                    entry.name,
                    entryTax
                  );
                const configurablePrimaryAccountFieldValues =
                  this.setStandardJeValueOverrides(
                    "recordRefundAdjustmentTax",
                    false,
                    location,
                    thirdParty,
                    entry.name,
                    entryTax
                  );
                const offsetDebit = +this.computeEntryValue(
                  configurableOffsetAccountFieldValues,
                  entryTax,
                  true
                );
                const offsetCredit = +this.computeEntryValue(
                  configurableOffsetAccountFieldValues,
                  entryTax,
                  false
                );
                const primaryDebit = +this.computeEntryValue(
                  configurablePrimaryAccountFieldValues,
                  entryTax,
                  true
                );
                const primaryCredit = +this.computeEntryValue(
                  configurablePrimaryAccountFieldValues,
                  entryTax,
                  false
                );
                const isEntrySkipped =
                  offsetCredit + offsetDebit + primaryCredit + primaryDebit ===
                  0;
                if (!isEntrySkipped) {
                  //OFFSET ROW
                  refundAdjTaxSheet.addRow(
                    this.sortColumnValues([
                      configurableOffsetAccountFieldValues.JOURNAL
                        ? configurableOffsetAccountFieldValues.JOURNAL
                        : "", // JOURNAL
                      configurableOffsetAccountFieldValues.DATE
                        ? configurableOffsetAccountFieldValues.DATE
                        : this.dataUtility.endDateText, // DATE
                      configurableOffsetAccountFieldValues.DESCRIPTION
                        ? configurableOffsetAccountFieldValues.DESCRIPTION
                        : `REFUND ADJUSTMENTS TAX - ${this.dataUtility.existingReport.name}`, //DESCRIPTION
                      configurableOffsetAccountFieldValues.ACCT
                        ? configurableOffsetAccountFieldValues.ACCT
                        : `ex. 1000`, //ACCNT
                      configurableOffsetAccountFieldValues.DEPT
                        ? configurableOffsetAccountFieldValues.DEPT
                        : `ex. D1123`, //DEPT
                      configurableOffsetAccountFieldValues.LOCATION
                        ? configurableOffsetAccountFieldValues.LOCATION
                        : location.locationId, //LOCATION
                      configurableOffsetAccountFieldValues.MEMO
                        ? configurableOffsetAccountFieldValues.MEMO
                        : `${thirdParty.name} - ${entry.name}`, // MEMO
                      +offsetDebit < 0 ? Math.abs(+offsetDebit) : 0,
                      +offsetCredit >= 0 ? +offsetCredit : 0,
                      configurableOffsetAccountFieldValues.DETAIL1
                        ? configurableOffsetAccountFieldValues.DETAIL1
                        : "",
                      configurableOffsetAccountFieldValues.DETAIL2
                        ? configurableOffsetAccountFieldValues.DETAIL2
                        : "",
                    ])
                  );
                  rowNumber++;
                  // NON OFFSET ROW
                  refundAdjTaxSheet.addRow(
                    this.sortColumnValues([
                      configurablePrimaryAccountFieldValues.JOURNAL
                        ? configurablePrimaryAccountFieldValues.JOURNAL
                        : "", // JOURNAL
                      configurablePrimaryAccountFieldValues.DATE
                        ? configurablePrimaryAccountFieldValues.DATE
                        : this.dataUtility.endDateText, // DATE
                      configurablePrimaryAccountFieldValues.DESCRIPTION
                        ? configurablePrimaryAccountFieldValues.DESCRIPTION
                        : `REFUND ADJUSTMENTS TAX - ${this.dataUtility.existingReport.name}`, //DESCRIPTION
                      configurablePrimaryAccountFieldValues.ACCT
                        ? configurablePrimaryAccountFieldValues.ACCT
                        : `ex. 7000`, //ACCNT
                      configurablePrimaryAccountFieldValues.DEPT
                        ? configurablePrimaryAccountFieldValues.DEPT
                        : `ex. D1123`, //DEPT
                      configurablePrimaryAccountFieldValues.LOCATION
                        ? configurablePrimaryAccountFieldValues.LOCATION
                        : location.locationId, //LOCATION
                      configurablePrimaryAccountFieldValues.MEMO
                        ? configurablePrimaryAccountFieldValues.MEMO
                        : `${thirdParty.name} - ${entry.name}`, // MEMO
                      +primaryDebit >= 0 ? +primaryDebit : 0,
                      +primaryCredit < 0 ? Math.abs(+primaryCredit) : 0,
                      configurablePrimaryAccountFieldValues.DETAIL1
                        ? configurablePrimaryAccountFieldValues.DETAIL1
                        : "",
                      configurablePrimaryAccountFieldValues.DETAIL2
                        ? configurablePrimaryAccountFieldValues.DETAIL2
                        : "",
                    ])
                  );
                }
              }
            });
          }
        }
      });
    });
  }
  async complieTipsPayableJe(tipsPayableJeSheet, reportLocations) {
    tipsPayableJeSheet.addRow(this.headerRow);
    let rowNumber = 2;
    reportLocations.forEach((location) => {
      this.dataUtility.thirdParties.forEach((thirdParty) => {
        const locationReportData = this.dataUtility.reportData.find(
          (data) =>
            data.locationId == location.locationId &&
            data.thirdParty === thirdParty.id
        );
        if (locationReportData) {
          [
            {
              name: "Tips Payable",
              amount: locationReportData.thirdPartyTips,
            },
          ].forEach((entry) => {
            const configurableOffsetAccountFieldValues =
              this.setStandardJeValueOverrides(
                "recordTipsPayable",
                true,
                location,
                thirdParty,
                entry.name,
                entry.amount
              );
            const configurablePrimaryAccountFieldValues =
              this.setStandardJeValueOverrides(
                "recordTipsPayable",
                false,
                location,
                thirdParty,
                entry.name,
                entry.amount
              );
            const offsetDebit = +this.computeEntryValue(
              configurableOffsetAccountFieldValues,
              entry.amount,
              true
            );
            const offsetCredit = +this.computeEntryValue(
              configurableOffsetAccountFieldValues,
              entry.amount,
              false
            );
            const primaryDebit = +this.computeEntryValue(
              configurablePrimaryAccountFieldValues,
              entry.amount,
              true
            );
            const primaryCredit = +this.computeEntryValue(
              configurablePrimaryAccountFieldValues,
              entry.amount,
              false
            );
            const isEntrySkipped =
              offsetCredit + offsetDebit + primaryCredit + primaryDebit === 0;
            if (!isEntrySkipped) {
              //OFFSET ROW
              tipsPayableJeSheet.addRow(
                this.sortColumnValues([
                  configurableOffsetAccountFieldValues.JOURNAL
                    ? configurableOffsetAccountFieldValues.JOURNAL
                    : "", // JOURNAL
                  configurableOffsetAccountFieldValues.DATE
                    ? configurableOffsetAccountFieldValues.DATE
                    : this.dataUtility.endDateText, // DATE
                  configurableOffsetAccountFieldValues.DESCRIPTION
                    ? configurableOffsetAccountFieldValues.DESCRIPTION
                    : `DSP TIPS PAYABLE - ${this.dataUtility.existingReport.name}`, //DESCRIPTION
                  configurableOffsetAccountFieldValues.ACCT
                    ? configurableOffsetAccountFieldValues.ACCT
                    : `ex. 1000`, //ACCNT
                  configurableOffsetAccountFieldValues.DEPT
                    ? configurableOffsetAccountFieldValues.DEPT
                    : `ex. D1123`, //DEPT
                  configurableOffsetAccountFieldValues.LOCATION
                    ? configurableOffsetAccountFieldValues.LOCATION
                    : location.locationId, //LOCATION
                  configurableOffsetAccountFieldValues.MEMO
                    ? configurableOffsetAccountFieldValues.MEMO
                    : `${thirdParty.name} - Tips Payable`, // MEMO
                  +offsetDebit < 0 ? Math.abs(+offsetDebit) : 0,
                  +offsetCredit >= 0 ? +offsetCredit : 0,
                  configurableOffsetAccountFieldValues.DETAIL1
                    ? configurableOffsetAccountFieldValues.DETAIL1
                    : "",
                  configurableOffsetAccountFieldValues.DETAIL2
                    ? configurableOffsetAccountFieldValues.DETAIL2
                    : "",
                ])
              );
              rowNumber++;
              // NON OFFSET ROW

              tipsPayableJeSheet.addRow(
                this.sortColumnValues([
                  configurablePrimaryAccountFieldValues.JOURNAL
                    ? configurablePrimaryAccountFieldValues.JOURNAL
                    : "", // JOURNAL
                  configurablePrimaryAccountFieldValues.DATE
                    ? configurablePrimaryAccountFieldValues.DATE
                    : this.dataUtility.endDateText, // DATE
                  configurablePrimaryAccountFieldValues.DESCRIPTION
                    ? configurablePrimaryAccountFieldValues.DESCRIPTION
                    : `DSP TIPS PAYABLE - ${this.dataUtility.existingReport.name}`, //DESCRIPTION
                  configurablePrimaryAccountFieldValues.ACCT
                    ? configurablePrimaryAccountFieldValues.ACCT
                    : `ex. 7000`, //ACCNT
                  configurablePrimaryAccountFieldValues.DEPT
                    ? configurablePrimaryAccountFieldValues.DEPT
                    : `ex. D1123`, //DEPT
                  configurablePrimaryAccountFieldValues.LOCATION
                    ? configurablePrimaryAccountFieldValues.LOCATION
                    : location.locationId, //LOCATION
                  configurablePrimaryAccountFieldValues.MEMO
                    ? configurablePrimaryAccountFieldValues.MEMO
                    : `${thirdParty.name} - ${entry.name}`, // MEMO
                  +primaryDebit >= 0 ? +primaryDebit : 0,
                  +primaryCredit < 0 ? Math.abs(+primaryCredit) : 0,
                  configurablePrimaryAccountFieldValues.DETAIL1
                    ? configurablePrimaryAccountFieldValues.DETAIL1
                    : "",
                  configurablePrimaryAccountFieldValues.DETAIL2
                    ? configurablePrimaryAccountFieldValues.DETAIL2
                    : "",
                ])
              );
            }
          });
        }
      });
    });
  }
  async compileFeeDetailJe(feeDetailJeSheet, reportLocations) {
    feeDetailJeSheet.addRow(this.headerRow);
    let rowNumber = 2;
    reportLocations.forEach((location) => {
      this.dataUtility.thirdParties.forEach((thirdParty) => {
        [
          { type: "Delivery Expense", column: "C", field: "deliveryFees" },
          { type: "Pickup Expense", column: "D", field: "pickupFees" },
          { type: "Promo Expense", column: "E", field: "promoFees" },
          { type: "Catering Expense", column: "F", field: "cateringFees" },
          { type: "Custom Expense", column: "G", field: "customFees" },
          { type: "Misc Expense", column: "I", field: "thirdPartyMisc" },
          {
            type: "Credit Card Processing Expense",
            column: "J",
            field: "thirdPartyOtherCharges",
          },
        ].forEach((feeType) => {
          const locationReportData = this.dataUtility.reportData.find(
            (data) =>
              data.locationId == location.locationId &&
              data.thirdParty === thirdParty.id
          );
          if (locationReportData) {
            const feeValue = locationReportData[feeType.field];
            if (feeValue) {
              const configurableOffsetAccountFieldValues =
                this.setStandardJeValueOverrides(
                  "recordFeesDetail",
                  true,
                  location,
                  thirdParty,
                  feeType.type,
                  feeValue,
                  null,
                  locationReportData
                );
              const configurablePrimaryAccountFieldValues =
                this.setStandardJeValueOverrides(
                  "recordFeesDetail",
                  false,
                  location,
                  thirdParty,
                  feeType.type,
                  feeValue,
                  null,
                  locationReportData
                );
              const offsetDebit = +this.computeEntryValue(
                configurableOffsetAccountFieldValues,
                feeValue,
                true
              );
              const offsetCredit = +this.computeEntryValue(
                configurableOffsetAccountFieldValues,
                feeValue,
                false
              );
              const primaryDebit = +this.computeEntryValue(
                configurablePrimaryAccountFieldValues,
                feeValue,
                true
              );
              const primaryCredit = +this.computeEntryValue(
                configurablePrimaryAccountFieldValues,
                feeValue,
                false
              );
              const isEntrySkipped =
                offsetCredit + offsetDebit + primaryCredit + primaryDebit === 0;
              if (!isEntrySkipped) {
                //OFFSET ROW
                feeDetailJeSheet.addRow(
                  this.sortColumnValues([
                    configurableOffsetAccountFieldValues.JOURNAL
                      ? configurableOffsetAccountFieldValues.JOURNAL
                      : "", // JOURNAL
                    configurableOffsetAccountFieldValues.DATE
                      ? configurableOffsetAccountFieldValues.DATE
                      : this.dataUtility.endDateText, // DATE
                    configurableOffsetAccountFieldValues.DESCRIPTION
                      ? configurableOffsetAccountFieldValues.DESCRIPTION
                      : `DSP COMMISSION FEES - ${this.dataUtility.existingReport.name}`, //DESCRIPTION
                    configurableOffsetAccountFieldValues.ACCT
                      ? configurableOffsetAccountFieldValues.ACCT
                      : `ex. 1000`, //ACCNT
                    configurableOffsetAccountFieldValues.DEPT
                      ? configurableOffsetAccountFieldValues.DEPT
                      : `ex. D1123`, //DEPT
                    configurableOffsetAccountFieldValues.LOCATION
                      ? configurableOffsetAccountFieldValues.LOCATION
                      : location.locationId, //LOCATION
                    configurableOffsetAccountFieldValues.MEMO
                      ? configurableOffsetAccountFieldValues.MEMO
                      : `${thirdParty.name} - ${feeType.type}`, // MEMO
                    offsetDebit > 0 ? offsetDebit : 0,
                    offsetCredit < 0 ? Math.abs(offsetCredit) : 0,
                    configurableOffsetAccountFieldValues.DETAIL1
                      ? configurableOffsetAccountFieldValues.DETAIL1
                      : "",
                    configurableOffsetAccountFieldValues.DETAIL2
                      ? configurableOffsetAccountFieldValues.DETAIL2
                      : "",
                  ])
                );
                rowNumber++;
                // NON OFFSET ROW

                feeDetailJeSheet.addRow(
                  this.sortColumnValues([
                    configurablePrimaryAccountFieldValues.JOURNAL
                      ? configurablePrimaryAccountFieldValues.JOURNAL
                      : "", // JOURNAL
                    configurablePrimaryAccountFieldValues.DATE
                      ? configurablePrimaryAccountFieldValues.DATE
                      : this.dataUtility.endDateText, // DATE
                    configurablePrimaryAccountFieldValues.DESCRIPTION
                      ? configurablePrimaryAccountFieldValues.DESCRIPTION
                      : `DSP COMMISSION FEES - ${this.dataUtility.existingReport.name}`, //DESCRIPTION
                    configurablePrimaryAccountFieldValues.ACCT
                      ? configurablePrimaryAccountFieldValues.ACCT
                      : `ex. 7000`, //ACCNT
                    configurablePrimaryAccountFieldValues.DEPT
                      ? configurablePrimaryAccountFieldValues.DEPT
                      : `ex. D1123`, //DEPT
                    configurablePrimaryAccountFieldValues.LOCATION
                      ? configurablePrimaryAccountFieldValues.LOCATION
                      : location.locationId, //LOCATION
                    configurablePrimaryAccountFieldValues.MEMO
                      ? configurablePrimaryAccountFieldValues.MEMO
                      : `${thirdParty.name} - ${feeType.type}`, // MEMO
                    primaryDebit < 0 ? Math.abs(primaryDebit) : 0,
                    primaryCredit > 0 ? primaryCredit : 0,

                    configurablePrimaryAccountFieldValues.DETAIL1
                      ? configurablePrimaryAccountFieldValues.DETAIL1
                      : "",
                    configurablePrimaryAccountFieldValues.DETAIL2
                      ? configurablePrimaryAccountFieldValues.DETAIL2
                      : "",
                  ])
                );
              }
            }
          }
        });
      });
    });
  }
  async compileTaxAdjJe(taxAdjJeSheet, reportLocations) {
    taxAdjJeSheet.addRow(this.headerRow);
    let rowNumber = 2;
    reportLocations.forEach((location) => {
      this.dataUtility.thirdParties.forEach((thirdParty) => {
        const reconciliation = this.dataUtility.reportData.find(
          (report) =>
            report.locationId === location.locationId &&
            report.thirdParty === thirdParty.id
        );
        const suggestedTaxAdjustment = reconciliation?.suggestedTaxAdjustment
          ? +reconciliation.suggestedTaxAdjustment
          : 0;
        if (suggestedTaxAdjustment && suggestedTaxAdjustment !== 0) {
          const configurableOffsetAccountFieldValues =
            this.setStandardJeValueOverrides(
              "recordTaxAdjustment",
              true,
              location,
              thirdParty,
              null,
              suggestedTaxAdjustment
            );
          const configurablePrimaryAccountFieldValues =
            this.setStandardJeValueOverrides(
              "recordTaxAdjustment",
              false,
              location,
              thirdParty,
              null,
              suggestedTaxAdjustment
            );
          //OFFSET ACCOUNT:
          taxAdjJeSheet.addRow(
            this.sortColumnValues([
              configurableOffsetAccountFieldValues.JOURNAL
                ? configurableOffsetAccountFieldValues.JOURNAL
                : "", // JOURNAL
              configurableOffsetAccountFieldValues.DATE
                ? configurableOffsetAccountFieldValues.DATE
                : this.dataUtility.endDateText, // DATE
              configurableOffsetAccountFieldValues.DESCRIPTION
                ? configurableOffsetAccountFieldValues.DESCRIPTION
                : `SALES TAX LIABILITY - ${this.dataUtility.existingReport.name}`,
              //DESCRIPTION
              configurableOffsetAccountFieldValues.ACCT
                ? configurableOffsetAccountFieldValues.ACCT
                : `ex. 1000`, //ACCNT
              configurableOffsetAccountFieldValues.DEPT
                ? configurableOffsetAccountFieldValues.DEPT
                : `ex. D1123`, //DEPT
              configurableOffsetAccountFieldValues.LOCATION
                ? configurableOffsetAccountFieldValues.LOCATION
                : location.locationId, //LOCATION
              configurableOffsetAccountFieldValues.MEMO
                ? configurableOffsetAccountFieldValues.MEMO
                : `${thirdParty.name} - Tax Adjustment`, // MEMO
              suggestedTaxAdjustment < 0 ? Math.abs(suggestedTaxAdjustment) : 0,
              suggestedTaxAdjustment > 0 ? Math.abs(suggestedTaxAdjustment) : 0,
              // {
              //   id: "suggestedTaxAdustment",
              //   formula: `IF(SUMIFS('DS Report'!${recColumns["Suggested Tax Adjustment"]}:${recColumns["Suggested Tax Adjustment"]},'DS Report'!A:A,"${location.locationId}",'DS Report'!B:B,"${thirdParty.name}") < 0, ABS(SUMIFS('DS Report'!${recColumns["Suggested Tax Adjustment"]}:${recColumns["Suggested Tax Adjustment"]},'DS Report'!A:A,"${location.locationId}",'DS Report'!B:B,"${thirdParty.name}")), 0)`,
              // },
              // {
              //   id: "suggestedTaxAdustment",
              //   formula: `IF(SUMIFS('DS Report'!${recColumns["Suggested Tax Adjustment"]}:${recColumns["Suggested Tax Adjustment"]},'DS Report'!A:A,"${location.locationId}",'DS Report'!B:B,"${thirdParty.name}") > 0, ABS(SUMIFS('DS Report'!${recColumns["Suggested Tax Adjustment"]}:${recColumns["Suggested Tax Adjustment"]},'DS Report'!A:A,"${location.locationId}",'DS Report'!B:B,"${thirdParty.name}")), 0)`,
              // },
              configurableOffsetAccountFieldValues.DETAIL1
                ? configurableOffsetAccountFieldValues.DETAIL1
                : "",
              configurableOffsetAccountFieldValues.DETAIL2
                ? configurableOffsetAccountFieldValues.DETAIL2
                : "",
            ])
          );
          rowNumber++;
          //PRIMARY ACCOUNT
          taxAdjJeSheet.addRow(
            this.sortColumnValues([
              configurablePrimaryAccountFieldValues.JOURNAL
                ? configurablePrimaryAccountFieldValues.JOURNAL
                : "", // JOURNAL
              configurablePrimaryAccountFieldValues.DATE
                ? configurablePrimaryAccountFieldValues.DATE
                : this.dataUtility.endDateText, // DATE
              configurablePrimaryAccountFieldValues.DESCRIPTION
                ? configurablePrimaryAccountFieldValues.DESCRIPTION
                : `SALES TAX LIABILITY - ${this.dataUtility.existingReport.name}`,
              //DESCRIPTION
              configurablePrimaryAccountFieldValues.ACCT
                ? configurablePrimaryAccountFieldValues.ACCT
                : `ex. 2200`, //ACCNT
              configurablePrimaryAccountFieldValues.DEPT
                ? configurablePrimaryAccountFieldValues.DEPT
                : `ex. D1123`, //DEPT
              configurablePrimaryAccountFieldValues.LOCATION
                ? configurablePrimaryAccountFieldValues.LOCATION
                : location.locationId, //LOCATION
              configurablePrimaryAccountFieldValues.MEMO
                ? configurablePrimaryAccountFieldValues.MEMO
                : `${thirdParty.name} - Tax Adjustment`, // MEMO
              suggestedTaxAdjustment > 0 ? Math.abs(suggestedTaxAdjustment) : 0,
              suggestedTaxAdjustment < 0 ? Math.abs(suggestedTaxAdjustment) : 0,
              // {
              //   id: "suggestedTaxAdustment",
              //   formula: `IF(SUMIFS('DS Report'!${recColumns["Suggested Tax Adjustment"]}:${recColumns["Suggested Tax Adjustment"]},'DS Report'!A:A,"${location.locationId}",'DS Report'!B:B,"${thirdParty.name}") > 0, ABS(SUMIFS('DS Report'!${recColumns["Suggested Tax Adjustment"]}:${recColumns["Suggested Tax Adjustment"]},'DS Report'!A:A,"${location.locationId}",'DS Report'!B:B,"${thirdParty.name}")), 0)`,
              // },
              // {
              //   id: "suggestedTaxAdustment",
              //   formula: `IF(SUMIFS('DS Report'!${recColumns["Suggested Tax Adjustment"]}:${recColumns["Suggested Tax Adjustment"]},'DS Report'!A:A,"${location.locationId}",'DS Report'!B:B,"${thirdParty.name}") < 0, ABS(SUMIFS('DS Report'!${recColumns["Suggested Tax Adjustment"]}:${recColumns["Suggested Tax Adjustment"]},'DS Report'!A:A,"${location.locationId}",'DS Report'!B:B,"${thirdParty.name}")), 0)`,
              // },
              configurablePrimaryAccountFieldValues.DETAIL1
                ? configurablePrimaryAccountFieldValues.DETAIL1
                : "",
              configurablePrimaryAccountFieldValues.DETAIL2
                ? configurablePrimaryAccountFieldValues.DETAIL2
                : "",
            ])
          );
        }
      });
    });
  }
  calcSalVar(
    location: Location,
    dsp: ThirdParty,
    thirdPartyReportVarianceAnalysisReports: ThirdPartyReportFragmentVarianceAnalysisReport[],
    ppaTransactions: PriorPeriodAdjustmentTransaction[]
  ) {
    const varAnalysis = thirdPartyReportVarianceAnalysisReports.find(
      (report) =>
        report.location === location.locationId && report.thirdParty === dsp.id
    );
    const salesVariance = varAnalysis.salesVarianceAmount;
    const matchingPpaRefundTransactions = ppaTransactions.filter((ppat) => {
      return (
        ppat.location === location.locationId &&
        ppat.account === dsp.id &&
        ppat.isErrorCharge
      );
    });
    const ppaRefundTransactionsAmount = -matchingPpaRefundTransactions.reduce(
      (sum: number, ppat) => {
        // determine the amount of the cutomer refund (DoorDash in transaction.saleCorrection, all other DSPS in transaction.sale)
        const amount =
          ppat.transaction.sale !== 0
            ? ppat.transaction.sale
            : ppat.transaction.saleCorrection !== 0
            ? ppat.transaction.saleCorrection
            : 0;
        return (sum += amount);
      },
      0
    );
    const matchingPpaAdjustmentTransactions = ppaTransactions.filter((ppat) => {
      return (
        ppat.location === location.locationId &&
        ppat.account === dsp.id &&
        ppat.isAdjustment
      );
    });
    const ppaAdjustmentTransactionsAmount =
      -matchingPpaAdjustmentTransactions.reduce((sum: number, ppat) => {
        // determine the amount of the cutomer refund (DoorDash in transaction.saleCorrection, all other DSPS in transaction.sale)
        const amount =
          ppat.transaction.sale !== 0
            ? ppat.transaction.sale
            : ppat.transaction.saleCorrection !== 0
            ? ppat.transaction.saleCorrection
            : 0;
        return (sum += amount);
      }, 0);
    return +(
      salesVariance +
      ppaRefundTransactionsAmount +
      ppaAdjustmentTransactionsAmount
    ).toFixed(2);
  }
  async compileSaleVarAggregateJe(saleVarAgeSheet, reportLocations) {
    saleVarAgeSheet.addRow(this.headerRow);
    let rowNumber = 2;
    const thirdPartyReportVarianceAnalysisReports =
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.varianceAnalysisReports
      );
    const ppaTransactions = await this.dataUtility.fetchPriorPeriodAdjustments(
      this.dataUtility.locations
    );
    reportLocations.forEach((location) => {
      this.dataUtility.thirdParties.forEach((thirdParty) => {
        const variance = this.calcSalVar(
          location,
          thirdParty,
          thirdPartyReportVarianceAnalysisReports,
          ppaTransactions
        );
        if (variance !== 0) {
          const configurableOffsetAccountFieldValues =
            this.setStandardJeValueOverrides(
              "recordSalesVarianceAggregate",
              true,
              location,
              thirdParty,
              "Sales Variance",
              variance
            );
          const configurablePrimaryAccountFieldValues =
            this.setStandardJeValueOverrides(
              "recordSalesVarianceAggregate",
              false,
              location,
              thirdParty,
              "Sales Variance",
              variance
            );
          const offsetDebit = +this.computeEntryValue(
            configurableOffsetAccountFieldValues,
            variance,
            true
          ); //WILL DEFAULT TO VARIANCE
          const offsetCredit = +this.computeEntryValue(
            configurableOffsetAccountFieldValues,
            variance,
            false
          );
          const primaryDebit = +this.computeEntryValue(
            configurablePrimaryAccountFieldValues,
            variance,
            true
          ); //WILL DEFAULT TO VARIANCE
          const primaryCredit = +this.computeEntryValue(
            configurablePrimaryAccountFieldValues,
            variance,
            false
          ); //WILL DEFAULT TO VARIANCE
          //OFFSET ACCOUNT
          const isEntrySkipped =
            offsetCredit + offsetDebit + primaryCredit + primaryDebit === 0;
          if (!isEntrySkipped) {
            saleVarAgeSheet.addRow(
              this.sortColumnValues([
                configurableOffsetAccountFieldValues.JOURNAL
                  ? configurableOffsetAccountFieldValues.JOURNAL
                  : "", // JOURNAL
                configurableOffsetAccountFieldValues.DATE
                  ? configurableOffsetAccountFieldValues.DATE
                  : this.dataUtility.endDateText, // DATE
                configurableOffsetAccountFieldValues.DESCRIPTION
                  ? configurableOffsetAccountFieldValues.DESCRIPTION
                  : `DSP SALES VARIANCE - ${this.dataUtility.existingReport.name}`,
                //DESCRIPTION
                configurableOffsetAccountFieldValues.ACCT
                  ? configurableOffsetAccountFieldValues.ACCT
                  : `ex. 1000`, //ACCNT
                configurableOffsetAccountFieldValues.DEPT
                  ? configurableOffsetAccountFieldValues.DEPT
                  : `ex. D1123`, //DEPT
                configurableOffsetAccountFieldValues.LOCATION
                  ? configurableOffsetAccountFieldValues.LOCATION
                  : location.locationId, //LOCATION
                configurableOffsetAccountFieldValues.MEMO
                  ? configurableOffsetAccountFieldValues.MEMO
                  : `${thirdParty.name} - Sales Variance`,
                // MEMO
                +offsetDebit < 0 ? Math.abs(+offsetDebit) : 0,
                +offsetCredit >= 0 ? +offsetCredit : 0,
                configurableOffsetAccountFieldValues.DETAIL1
                  ? configurableOffsetAccountFieldValues.DETAIL1
                  : "",
                configurableOffsetAccountFieldValues.DETAIL2
                  ? configurableOffsetAccountFieldValues.DETAIL2
                  : "",
              ])
            );
            rowNumber++;
            saleVarAgeSheet.addRow(
              this.sortColumnValues([
                configurablePrimaryAccountFieldValues.JOURNAL
                  ? configurablePrimaryAccountFieldValues.JOURNAL
                  : "", // JOURNAL
                configurablePrimaryAccountFieldValues.DATE
                  ? configurablePrimaryAccountFieldValues.DATE
                  : this.dataUtility.endDateText, // DATE
                configurablePrimaryAccountFieldValues.DESCRIPTION
                  ? configurablePrimaryAccountFieldValues.DESCRIPTION
                  : `DSP SALES VARIANCE - ${this.dataUtility.existingReport.name}`,
                //DESCRIPTION
                configurablePrimaryAccountFieldValues.ACCT
                  ? configurablePrimaryAccountFieldValues.ACCT
                  : `ex. 7400`, //ACCNT
                configurablePrimaryAccountFieldValues.DEPT
                  ? configurablePrimaryAccountFieldValues.DEPT
                  : `ex. D1123`, //DEPT
                configurablePrimaryAccountFieldValues.LOCATION
                  ? configurablePrimaryAccountFieldValues.LOCATION
                  : location.locationId, //LOCATION
                configurablePrimaryAccountFieldValues.MEMO
                  ? configurablePrimaryAccountFieldValues.MEMO
                  : `${thirdParty.name} - Sales Variance`,
                // MEMO
                +primaryDebit >= 0 ? +primaryDebit : 0, // if the adjustment is positive, debit the 5000 series account
                +primaryCredit < 0 ? Math.abs(+primaryCredit) : 0, // if it is negative put in credit (absolute) for 5000
                configurablePrimaryAccountFieldValues.DETAIL1
                  ? configurablePrimaryAccountFieldValues.DETAIL1
                  : "",
                configurablePrimaryAccountFieldValues.DETAIL2
                  ? configurablePrimaryAccountFieldValues.DETAIL2
                  : "",
              ])
            );
          }
        }
      });
    });
  }
  async compileSaleVarDetailJe(saleVarDetailJeSheet, reportLocations) {
    saleVarDetailJeSheet.addRow(this.headerRow);
    let rowNumber = 2;
    const thirdPartyReportVarianceAnalysisReports =
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.varianceAnalysisReports
      );
    const ppaTransactions = await this.dataUtility.fetchPriorPeriodAdjustments(
      this.dataUtility.locations
    );
    reportLocations.forEach((location) => {
      this.dataUtility.thirdParties.forEach((thirdParty) => {
        const varAnalysis = thirdPartyReportVarianceAnalysisReports.find(
          (report) =>
            report.location === location.locationId &&
            report.thirdParty === thirdParty.id
        );
        const matchingPpaRefundTransactions = ppaTransactions.filter((ppat) => {
          return (
            ppat.location === location.locationId &&
            ppat.account === thirdParty.id &&
            ppat.isErrorCharge
          );
        });
        const ppaRefundTransactionsAmount =
          -matchingPpaRefundTransactions.reduce((sum: number, ppat) => {
            // determine the amount of the cutomer refund (DoorDash in transaction.saleCorrection, all other DSPS in transaction.sale)
            const amount =
              ppat.transaction.sale !== 0
                ? ppat.transaction.sale
                : ppat.transaction.saleCorrection !== 0
                ? ppat.transaction.saleCorrection
                : 0;
            return (sum += amount);
          }, 0);
        const matchingPpaAdjustmentTransactions = ppaTransactions.filter(
          (ppat) => {
            return (
              ppat.location === location.locationId &&
              ppat.account === thirdParty.id &&
              ppat.isAdjustment
            );
          }
        );
        const ppaAdjustmentTransactionsAmount =
          -matchingPpaAdjustmentTransactions.reduce((sum: number, ppat) => {
            // determine the amount of the cutomer refund (DoorDash in transaction.saleCorrection, all other DSPS in transaction.sale)
            const amount =
              ppat.transaction.sale !== 0
                ? ppat.transaction.sale
                : ppat.transaction.saleCorrection !== 0
                ? ppat.transaction.saleCorrection
                : 0;
            return (sum += amount);
          }, 0);
        for (let type in TransactionAITypes) {
          if (
            type !== "potentialFraud" &&
            type !== "nonPosPayment" &&
            !!varAnalysis
          ) {
            // Potential Fraud Indicators Excluded from VA Report
            const variance = +varAnalysis[TransactionAITypes[type]]
              ? +varAnalysis[TransactionAITypes[type]]
              : 0;
            if (variance !== 0) {
              const configurableOffsetAccountFieldValues =
                this.setStandardJeValueOverrides(
                  "recordSalesVarianceDetail",
                  true,
                  location,
                  thirdParty,
                  TransactionAITypes[type],
                  variance
                );
              const configurablePrimaryAccountFieldValues =
                this.setStandardJeValueOverrides(
                  "recordSalesVarianceDetail",
                  false,
                  location,
                  thirdParty,
                  TransactionAITypes[type],
                  variance
                );
              const offsetDebit = +this.computeEntryValue(
                configurableOffsetAccountFieldValues,
                variance,
                true
              ); //WILL DEFAULT TO VARIANCE
              const offsetCredit = +this.computeEntryValue(
                configurableOffsetAccountFieldValues,
                variance,
                false
              );
              const primaryDebit = +this.computeEntryValue(
                configurablePrimaryAccountFieldValues,
                variance,
                true
              ); //WILL DEFAULT TO VARIANCE
              const primaryCredit = +this.computeEntryValue(
                configurablePrimaryAccountFieldValues,
                variance,
                false
              ); //WILL DEFAULT TO VARIANCE
              //OFFSET ACCOUNT
              const isEntrySkipped =
                offsetCredit + offsetDebit + primaryCredit + primaryDebit === 0;
              if (!isEntrySkipped) {
                saleVarDetailJeSheet.addRow(
                  this.sortColumnValues([
                    configurableOffsetAccountFieldValues.JOURNAL
                      ? configurableOffsetAccountFieldValues.JOURNAL
                      : "", // JOURNAL
                    configurableOffsetAccountFieldValues.DATE
                      ? configurableOffsetAccountFieldValues.DATE
                      : this.dataUtility.endDateText, // DATE
                    configurableOffsetAccountFieldValues.DESCRIPTION
                      ? configurableOffsetAccountFieldValues.DESCRIPTION
                      : `DSP SALES VARIANCE - ${this.dataUtility.existingReport.name}`,
                    //DESCRIPTION
                    configurableOffsetAccountFieldValues.ACCT
                      ? configurableOffsetAccountFieldValues.ACCT
                      : `ex. 1000`, //ACCNT
                    configurableOffsetAccountFieldValues.DEPT
                      ? configurableOffsetAccountFieldValues.DEPT
                      : `ex. D1123`, //DEPT
                    configurableOffsetAccountFieldValues.LOCATION
                      ? configurableOffsetAccountFieldValues.LOCATION
                      : location.locationId, //LOCATION
                    configurableOffsetAccountFieldValues.MEMO
                      ? configurableOffsetAccountFieldValues.MEMO
                      : `${thirdParty.name} - ${TransactionAITypes[type]}`,
                    // MEMO
                    +offsetDebit < 0 ? Math.abs(+offsetDebit) : 0,
                    +offsetCredit >= 0 ? +offsetCredit : 0,
                    configurableOffsetAccountFieldValues.DETAIL1
                      ? configurableOffsetAccountFieldValues.DETAIL1
                      : "",
                    configurableOffsetAccountFieldValues.DETAIL2
                      ? configurableOffsetAccountFieldValues.DETAIL2
                      : "",
                  ])
                );
                rowNumber++;
                //PRIMARY ACCOUNT
                saleVarDetailJeSheet.addRow(
                  this.sortColumnValues([
                    configurablePrimaryAccountFieldValues.JOURNAL
                      ? configurablePrimaryAccountFieldValues.JOURNAL
                      : "", // JOURNAL
                    configurablePrimaryAccountFieldValues.DATE
                      ? configurablePrimaryAccountFieldValues.DATE
                      : this.dataUtility.endDateText, // DATE
                    configurablePrimaryAccountFieldValues.DESCRIPTION
                      ? configurablePrimaryAccountFieldValues.DESCRIPTION
                      : `DSP SALES VARIANCE - ${this.dataUtility.existingReport.name}`,
                    //DESCRIPTION
                    configurablePrimaryAccountFieldValues.ACCT
                      ? configurablePrimaryAccountFieldValues.ACCT
                      : `ex. 7400`, //ACCNT
                    configurablePrimaryAccountFieldValues.DEPT
                      ? configurablePrimaryAccountFieldValues.DEPT
                      : `ex. D1123`, //DEPT
                    configurablePrimaryAccountFieldValues.LOCATION
                      ? configurablePrimaryAccountFieldValues.LOCATION
                      : location.locationId, //LOCATION
                    configurablePrimaryAccountFieldValues.MEMO
                      ? configurablePrimaryAccountFieldValues.MEMO
                      : `${thirdParty.name} - ${TransactionAITypes[type]}`,
                    // MEMO
                    +primaryDebit >= 0 ? +primaryDebit : 0, // if the adjustment is positive, debit the 5000 series account
                    +primaryCredit < 0 ? Math.abs(+primaryCredit) : 0, // if it is negative put in credit (absolute) for 5000
                    configurablePrimaryAccountFieldValues.DETAIL1
                      ? configurablePrimaryAccountFieldValues.DETAIL1
                      : "",
                    configurablePrimaryAccountFieldValues.DETAIL2
                      ? configurablePrimaryAccountFieldValues.DETAIL2
                      : "",
                  ])
                );
              }
            }
          }
        }
        // Prior Period Adjustments
        [
          {
            name: "Prior Period Customer Refunds",
            amount: ppaRefundTransactionsAmount,
          },
          {
            name: "Prior Period Adjustments",
            amount: ppaAdjustmentTransactionsAmount,
          },
        ].forEach((ppaTType) => {
          if (ppaTType.amount !== 0) {
            const configurablePPATOffsetAccountFieldValues =
              this.setStandardJeValueOverrides(
                "recordSalesVarianceDetail",
                true,
                location,
                thirdParty,
                ppaTType.name,
                ppaTType.amount
              );
            const configurablePPATPrimaryAccountFieldValues =
              this.setStandardJeValueOverrides(
                "recordSalesVarianceDetail",
                false,
                location,
                thirdParty,
                ppaTType.name,
                ppaTType.amount
              );
            if (
              !this.isConfiguredDebitCreditZero(
                configurablePPATOffsetAccountFieldValues,
                configurablePPATPrimaryAccountFieldValues,
                ppaTType.amount
              )
            ) {
              saleVarDetailJeSheet.addRow(
                this.sortColumnValues([
                  configurablePPATOffsetAccountFieldValues.JOURNAL
                    ? configurablePPATOffsetAccountFieldValues.JOURNAL
                    : "", // JOURNAL
                  configurablePPATOffsetAccountFieldValues.DATE
                    ? configurablePPATOffsetAccountFieldValues.DATE
                    : this.dataUtility.endDateText, // DATE
                  configurablePPATOffsetAccountFieldValues.DESCRIPTION
                    ? configurablePPATOffsetAccountFieldValues.DESCRIPTION
                    : `DSP SALES VARIANCE - ${this.dataUtility.existingReport.name}`,
                  //DESCRIPTION
                  configurablePPATOffsetAccountFieldValues.ACCT
                    ? configurablePPATOffsetAccountFieldValues.ACCT
                    : `ex. 1000`, //ACCNT
                  configurablePPATOffsetAccountFieldValues.DEPT
                    ? configurablePPATOffsetAccountFieldValues.DEPT
                    : `ex. D1123`, //DEPT
                  configurablePPATOffsetAccountFieldValues.LOCATION
                    ? configurablePPATOffsetAccountFieldValues.LOCATION
                    : location.locationId, //LOCATION
                  configurablePPATOffsetAccountFieldValues.MEMO
                    ? configurablePPATOffsetAccountFieldValues.MEMO
                    : `${thirdParty.name} - Prior Period ${
                        ppaTType.name === "Prior Period Customer Refunds"
                          ? "Customer Refunds"
                          : "Adjustments"
                      }`,
                  // MEMO
                  ppaTType.amount < 0 ? Math.abs(ppaTType.amount) : 0, // if it is negative put in credit (absolute) for 5000
                  ppaTType.amount >= 0 ? ppaTType.amount : 0, // if the adjustment is positive, debit the 5000 series account
                  configurablePPATOffsetAccountFieldValues.DETAIL1
                    ? configurablePPATOffsetAccountFieldValues.DETAIL1
                    : "",
                  configurablePPATOffsetAccountFieldValues.DETAIL2
                    ? configurablePPATOffsetAccountFieldValues.DETAIL2
                    : "",
                ])
              );
              rowNumber++;
              //PRIMARY PPAT ACCOUNT

              saleVarDetailJeSheet.addRow(
                this.sortColumnValues([
                  configurablePPATPrimaryAccountFieldValues.JOURNAL
                    ? configurablePPATPrimaryAccountFieldValues.JOURNAL
                    : "", // JOURNAL
                  configurablePPATPrimaryAccountFieldValues.DATE
                    ? configurablePPATPrimaryAccountFieldValues.DATE
                    : this.dataUtility.endDateText, // DATE
                  configurablePPATPrimaryAccountFieldValues.DESCRIPTION
                    ? configurablePPATPrimaryAccountFieldValues.DESCRIPTION
                    : `DSP SALES VARIANCE - ${this.dataUtility.existingReport.name}`,
                  //DESCRIPTION
                  configurablePPATPrimaryAccountFieldValues.ACCT
                    ? configurablePPATPrimaryAccountFieldValues.ACCT
                    : `ex. 7000`, //ACCNT
                  configurablePPATPrimaryAccountFieldValues.DEPT
                    ? configurablePPATPrimaryAccountFieldValues.DEPT
                    : `ex. D1123`, //DEPT
                  configurablePPATPrimaryAccountFieldValues.LOCATION
                    ? configurablePPATPrimaryAccountFieldValues.LOCATION
                    : location.locationId, //LOCATION
                  configurablePPATPrimaryAccountFieldValues.MEMO
                    ? configurablePPATPrimaryAccountFieldValues.MEMO
                    : `${thirdParty.name} - Prior Period ${
                        ppaTType.name === "Prior Period Customer Refunds"
                          ? "Customer Refunds"
                          : "Adjustments"
                      }`,
                  // MEMO
                  ppaTType.amount >= 0 ? ppaTType.amount : 0,
                  ppaTType.amount < 0 ? Math.abs(ppaTType.amount) : 0,
                  configurablePPATPrimaryAccountFieldValues.DETAIL1
                    ? configurablePPATPrimaryAccountFieldValues.DETAIL1
                    : "",
                  configurablePPATPrimaryAccountFieldValues.DETAIL2
                    ? configurablePPATPrimaryAccountFieldValues.DETAIL2
                    : "",
                ])
              );
            }
          }
        });
      });
    });
  }

  async compileUnreportedDiffJe(depositDiffSheet, reportLocations) {
    depositDiffSheet.addRow(this.headerRow);
    let rowNumber = 2;
    const depositReports = <PayoutReportFragmentLog[]>(
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.depositReports
      )
    );
    reportLocations.forEach((location) => {
      this.dataUtility.thirdParties.forEach((thirdParty) => {
        const unreportedDifference = depositReports
          .filter(
            (report) =>
              report.location === location.locationId &&
              report.thirdParty === thirdParty.id
          )
          .reduce((sum, report) => {
            return (sum += +report.thirdPartyPayout?.unreportedDifference
              ? +report.thirdPartyPayout?.unreportedDifference
              : 0);
          }, 0);
        if (!!unreportedDifference && unreportedDifference !== 0) {
          const configurableOffsetAccountFieldValues =
            this.setStandardJeValueOverrides(
              "recordUnreportedDepositDifference",
              true,
              location,
              thirdParty,
              null,
              unreportedDifference
            );
          const configurablePrimaryAccountFieldValues =
            this.setStandardJeValueOverrides(
              "recordUnreportedDepositDifference",
              false,
              location,
              thirdParty,
              null,
              unreportedDifference
            );
          //OFFSET ACCOUNT
          depositDiffSheet.addRow(
            this.sortColumnValues([
              configurableOffsetAccountFieldValues.JOURNAL
                ? configurableOffsetAccountFieldValues.JOURNAL
                : "", // JOURNAL
              configurableOffsetAccountFieldValues.DATE
                ? configurableOffsetAccountFieldValues.DATE
                : this.dataUtility.endDateText, // DATE
              configurableOffsetAccountFieldValues.DESCRIPTION
                ? configurableOffsetAccountFieldValues.DESCRIPTION
                : `UNREPORTED DEPOSIT DIFFERENCE - ${this.dataUtility.existingReport.name}`,
              //DESCRIPTION
              configurableOffsetAccountFieldValues.ACCT
                ? configurableOffsetAccountFieldValues.ACCT
                : `ex. 1200`, //ACCNT
              configurableOffsetAccountFieldValues.DEPT
                ? configurableOffsetAccountFieldValues.DEPT
                : `ex. D1123`, //DEPT
              configurableOffsetAccountFieldValues.LOCATION
                ? configurableOffsetAccountFieldValues.LOCATION
                : location.locationId, //LOCATION
              configurableOffsetAccountFieldValues.MEMO
                ? configurableOffsetAccountFieldValues.MEMO
                : `${thirdParty.name} - Unreported Deposit Difference`, // MEMO
              unreportedDifference > 0 ? Math.abs(unreportedDifference) : 0,
              unreportedDifference < 0 ? Math.abs(unreportedDifference) : 0,
              // {
              //   id: "depositDifference",
              //   formula: `IF(SUMIFS('Deposits'!$Q:$Q,'Deposits'!A:A,"${location.locationId}",'Deposits'!B:B,"${thirdParty.name}") > 0, ABS(SUMIFS('Deposits'!$Q:$Q,'Deposits'!A:A,"${location.locationId}",'Deposits'!B:B,"${thirdParty.name}")), 0)`,
              // },
              // {
              //   id: "depositDifference",
              //   formula: `IF(SUMIFS('Deposits'!$Q:$Q,'Deposits'!A:A,"${location.locationId}",'Deposits'!B:B,"${thirdParty.name}") < 0, ABS(SUMIFS('Deposits'!$Q:$Q,'Deposits'!A:A,"${location.locationId}",'Deposits'!B:B,"${thirdParty.name}")), 0)`,
              // },

              configurableOffsetAccountFieldValues.DETAIL1
                ? configurablePrimaryAccountFieldValues.DETAIL1
                : "",
              configurableOffsetAccountFieldValues.DETAIL2
                ? configurablePrimaryAccountFieldValues.DETAIL2
                : "",
            ])
          );
          rowNumber++;
          //PRIMARY ACCOUNT
          depositDiffSheet.addRow(
            this.sortColumnValues([
              configurablePrimaryAccountFieldValues.JOURNAL
                ? configurablePrimaryAccountFieldValues.JOURNAL
                : "", // JOURNAL
              configurablePrimaryAccountFieldValues.DATE
                ? configurablePrimaryAccountFieldValues.DATE
                : this.dataUtility.endDateText, // DATE
              configurablePrimaryAccountFieldValues.DESCRIPTION
                ? configurablePrimaryAccountFieldValues.DESCRIPTION
                : `UNREPORTED DEPOSIT DIFFERENCE - ${this.dataUtility.existingReport.name}`,
              //DESCRIPTION
              configurablePrimaryAccountFieldValues.ACCT
                ? configurablePrimaryAccountFieldValues.ACCT
                : `ex. 7000`, //ACCNT
              configurablePrimaryAccountFieldValues.DEPT
                ? configurablePrimaryAccountFieldValues.DEPT
                : `ex. D1123`, //DEPT
              configurablePrimaryAccountFieldValues.LOCATION
                ? configurablePrimaryAccountFieldValues.LOCATION
                : location.locationId, //LOCATION
              configurablePrimaryAccountFieldValues.MEMO
                ? configurablePrimaryAccountFieldValues.MEMO
                : `${thirdParty.name} - Unreported Deposit Difference`, // MEMO

              unreportedDifference < 0 ? Math.abs(unreportedDifference) : 0,
              unreportedDifference > 0 ? Math.abs(unreportedDifference) : 0,
              // {
              //   id: "depositDifference",
              //   formula: `IF(SUMIFS('Deposits'!$Q:$Q,'Deposits'!A:A,"${location.locationId}",'Deposits'!B:B,"${thirdParty.name}") < 0, ABS(SUMIFS('Deposits'!$Q:$Q,'Deposits'!A:A,"${location.locationId}",'Deposits'!B:B,"${thirdParty.name}")), 0)`,
              // },
              // {
              //   id: "depositDifference",
              //   formula: `IF(SUMIFS('Deposits'!$Q:$Q,'Deposits'!A:A,"${location.locationId}",'Deposits'!B:B,"${thirdParty.name}") > 0, ABS(SUMIFS('Deposits'!$Q:$Q,'Deposits'!A:A,"${location.locationId}",'Deposits'!B:B,"${thirdParty.name}")), 0)`,
              // },
              configurablePrimaryAccountFieldValues.DETAIL1
                ? configurablePrimaryAccountFieldValues.DETAIL1
                : "",
              configurablePrimaryAccountFieldValues.DETAIL2
                ? configurablePrimaryAccountFieldValues.DETAIL2
                : "",
            ])
          );
        }
      });
    });
  }
  async compileDepositDetailJe(depositDetailSheet, reportLocations) {
    depositDetailSheet.addRow(this.headerRow);
    let rowNumber = 2;
    const payouts = <ThirdPartyPayout[]>(
      (
        await this.dataUtility.fetchReconciliationReportDrillDownFragments(
          ReconciliationDrillDownReportTypes.depositReports
        )
      ).map((log) => log.thirdPartyPayout)
    );
    // reportLocations.forEach((location) => {
    //   this.dataUtility.thirdParties.forEach((thirdParty) => {
    payouts.forEach((deposit) => {
      const thirdParty = this.dataUtility.thirdParties.find(
        (dsp) => dsp.id === deposit.thirdParty
      );
      const location = reportLocations.find(
        (location) => deposit.location === location.locationId
      );

      [
        { field: "sales", entry: "Sales" },
        { field: "saleCorrection", entry: "Sale Correction" },
        { field: "adjustments", entry: "Adjustments" },
        { field: "tip", entry: "Tip" },
        { field: "deliveryFees", entry: "Delivery Fees" },
        { field: "tax", entry: "Tax" },
        { field: "taxRemitted", entry: "Tax Remitted" },
        { field: "misc", entry: "Misc" },
        { field: "promoFees", entry: "Promo Fees" },
        { field: "backupWithholdingTax", entry: "Backup Withholding Tax" },
      ].forEach((line) => {
        const entryValue =
          line.field === "tax"
            ? deposit.tax + deposit.taxOnFees
            : line.field === "misc"
            ? deposit.misc + deposit.unreportedDifference //Unreported Difference In Misc
            : deposit[line.field];
        let exampleAccount = 4000;
        switch (line.field) {
          case "saleCorrection":
          case "sales":
            exampleAccount = 4000;
            break;
          case "adjustments":
            exampleAccount = 7000;
            break;
          case "tip":
            exampleAccount = 40000;
            break;
          case "taxRemitted":
          case "tax":
            exampleAccount = 2000;
            break;
          default:
            exampleAccount = 7000;
        }
        const configurablePrimaryAccountFieldValues =
          this.setStandardJeValueOverrides(
            "recordDepositJe",
            false,
            location,
            thirdParty,
            line.entry,
            entryValue,
            deposit
          );

        //DETAIL ENTRIES
        if (+entryValue && entryValue !== 0) {
          depositDetailSheet.addRow(
            this.sortColumnValues([
              configurablePrimaryAccountFieldValues.JOURNAL
                ? configurablePrimaryAccountFieldValues.JOURNAL
                : "", // JOURNAL
              configurablePrimaryAccountFieldValues.DATE
                ? configurablePrimaryAccountFieldValues.DATE
                : this.dataUtility.endDateText, // DATE
              configurablePrimaryAccountFieldValues.DESCRIPTION
                ? configurablePrimaryAccountFieldValues.DESCRIPTION
                : `DSP DEPOSIT DETAILS - ${this.dataUtility.existingReport.name}`, //DESCRIPTION
              configurablePrimaryAccountFieldValues.ACCT
                ? configurablePrimaryAccountFieldValues.ACCT
                : `ex. ${exampleAccount}`, //ACCNT
              configurablePrimaryAccountFieldValues.DEPT
                ? configurablePrimaryAccountFieldValues.DEPT
                : `ex. D1123`, //DEPT
              configurablePrimaryAccountFieldValues.LOCATION
                ? configurablePrimaryAccountFieldValues.LOCATION
                : location.locationId, //LOCATION
              configurablePrimaryAccountFieldValues.MEMO
                ? configurablePrimaryAccountFieldValues.MEMO
                : `${thirdParty.name} - ${line.entry}`, // MEMO
              entryValue < 0 ? Math.abs(entryValue) : 0,
              entryValue > 0 ? entryValue : 0,
              configurablePrimaryAccountFieldValues.DETAIL1
                ? configurablePrimaryAccountFieldValues.DETAIL1
                : "",
              configurablePrimaryAccountFieldValues.DETAIL2
                ? configurablePrimaryAccountFieldValues.DETAIL2
                : "",
            ])
          );
          rowNumber++;
        }
      });

      const configurableOffsetAccountFieldValues =
        this.setStandardJeValueOverrides(
          "recordDepositJe",
          true,
          location,
          thirdParty,
          "Deposit",
          deposit.payout,
          deposit
        );
      //DETAIL ENTRIES
      depositDetailSheet.addRow(
        this.sortColumnValues([
          configurableOffsetAccountFieldValues.JOURNAL
            ? configurableOffsetAccountFieldValues.JOURNAL
            : "", // JOURNAL
          configurableOffsetAccountFieldValues.DATE
            ? configurableOffsetAccountFieldValues.DATE
            : this.dataUtility.endDateText, // DATE
          configurableOffsetAccountFieldValues.DESCRIPTION
            ? configurableOffsetAccountFieldValues.DESCRIPTION
            : `DSP DEPOSIT DETAILS - ${this.dataUtility.existingReport.name}`, //DESCRIPTION
          configurableOffsetAccountFieldValues.ACCT
            ? configurableOffsetAccountFieldValues.ACCT
            : `ex. 1000`, //ACCNT
          configurableOffsetAccountFieldValues.DEPT
            ? configurableOffsetAccountFieldValues.DEPT
            : `ex. D1123`, //DEPT
          configurableOffsetAccountFieldValues.LOCATION
            ? configurableOffsetAccountFieldValues.LOCATION
            : location.locationId, //LOCATION
          configurableOffsetAccountFieldValues.MEMO
            ? configurableOffsetAccountFieldValues.MEMO
            : `${thirdParty.name} - DEPOSIT`, // MEMO
          deposit.payout,
          0,
          configurableOffsetAccountFieldValues.DETAIL1
            ? configurableOffsetAccountFieldValues.DETAIL1
            : "",
          configurableOffsetAccountFieldValues.DETAIL2
            ? configurableOffsetAccountFieldValues.DETAIL2
            : "",
        ])
      );
      rowNumber++;
    });
    // });
  }
  async compileDepositAggJe(depositAggSheet, reportLocations) {
    depositAggSheet.addRow(this.headerRow);
    let rowNumber = 2;
    const payouts = <ThirdPartyPayout[]>(
      (
        await this.dataUtility.fetchReconciliationReportDrillDownFragments(
          ReconciliationDrillDownReportTypes.depositReports
        )
      ).map((log) => log.thirdPartyPayout)
    );
    // reportLocations.forEach((location) => {
    //   this.dataUtility.thirdParties.forEach((thirdParty) => {
    payouts.forEach((deposit) => {
      const thirdParty = this.dataUtility.thirdParties.find(
        (dsp) => dsp.id === deposit.thirdParty
      );
      const location = reportLocations.find(
        (location) => deposit.location === location.locationId
      );

      [
        {
          name: "Deposit",
          amount: deposit.payout,
        },
      ].forEach((entry) => {
        const configurableOffsetAccountFieldValues =
          this.setStandardJeValueOverrides(
            "recordDepositAggregate",
            true,
            location,
            thirdParty,
            entry.name,
            entry.amount
          );
        const configurablePrimaryAccountFieldValues =
          this.setStandardJeValueOverrides(
            "recordDepositAggregate",
            false,
            location,
            thirdParty,
            entry.name,
            entry.amount
          );
        const offsetDebit = +this.computeEntryValue(
          configurableOffsetAccountFieldValues,
          entry.amount,
          true
        );
        const offsetCredit = +this.computeEntryValue(
          configurableOffsetAccountFieldValues,
          entry.amount,
          false
        );
        const primaryDebit = +this.computeEntryValue(
          configurablePrimaryAccountFieldValues,
          entry.amount,
          true
        );
        const primaryCredit = +this.computeEntryValue(
          configurablePrimaryAccountFieldValues,
          entry.amount,
          false
        );
        const isEntrySkipped =
          offsetCredit + offsetDebit + primaryCredit + primaryDebit === 0;
        if (!isEntrySkipped) {
          //OFFSET ROW
          depositAggSheet.addRow(
            this.sortColumnValues([
              configurableOffsetAccountFieldValues.JOURNAL
                ? configurableOffsetAccountFieldValues.JOURNAL
                : "", // JOURNAL
              configurableOffsetAccountFieldValues.DATE
                ? configurableOffsetAccountFieldValues.DATE
                : this.dataUtility.endDateText, // DATE
              configurableOffsetAccountFieldValues.DESCRIPTION
                ? configurableOffsetAccountFieldValues.DESCRIPTION
                : `DSP DEPOSIT- ${this.dataUtility.existingReport.name}`, //DESCRIPTION
              configurableOffsetAccountFieldValues.ACCT
                ? configurableOffsetAccountFieldValues.ACCT
                : `--`, //ACCNT
              configurableOffsetAccountFieldValues.DEPT
                ? configurableOffsetAccountFieldValues.DEPT
                : `--`, //DEPT
              configurableOffsetAccountFieldValues.LOCATION
                ? configurableOffsetAccountFieldValues.LOCATION
                : location.locationId, //LOCATION
              configurableOffsetAccountFieldValues.MEMO
                ? configurableOffsetAccountFieldValues.MEMO
                : `${thirdParty.name} - ${entry.name}`, // MEMO
              +offsetDebit < 0 ? Math.abs(+offsetDebit) : 0,
              +offsetCredit >= 0 ? +offsetCredit : 0,
              configurableOffsetAccountFieldValues.DETAIL1
                ? configurableOffsetAccountFieldValues.DETAIL1
                : "",
              configurableOffsetAccountFieldValues.DETAIL2
                ? configurableOffsetAccountFieldValues.DETAIL2
                : "",
            ])
          );
          rowNumber++;
          // NON OFFSET ROW

          depositAggSheet.addRow(
            this.sortColumnValues([
              configurablePrimaryAccountFieldValues.JOURNAL
                ? configurablePrimaryAccountFieldValues.JOURNAL
                : "", // JOURNAL
              configurablePrimaryAccountFieldValues.DATE
                ? configurablePrimaryAccountFieldValues.DATE
                : this.dataUtility.endDateText, // DATE
              configurablePrimaryAccountFieldValues.DESCRIPTION
                ? configurablePrimaryAccountFieldValues.DESCRIPTION
                : `DSP DEPOSIT- ${this.dataUtility.existingReport.name}`, //DESCRIPTION
              configurablePrimaryAccountFieldValues.ACCT
                ? configurablePrimaryAccountFieldValues.ACCT
                : `--`, //ACCNT
              configurablePrimaryAccountFieldValues.DEPT
                ? configurablePrimaryAccountFieldValues.DEPT
                : `--`, //DEPT
              configurablePrimaryAccountFieldValues.LOCATION
                ? configurablePrimaryAccountFieldValues.LOCATION
                : location.locationId, //LOCATION
              configurablePrimaryAccountFieldValues.MEMO
                ? configurablePrimaryAccountFieldValues.MEMO
                : `${thirdParty.name} - ${entry.name}`, // MEMO
              +primaryDebit >= 0 ? +primaryDebit : 0,
              +primaryCredit < 0 ? Math.abs(+primaryCredit) : 0,
              configurablePrimaryAccountFieldValues.DETAIL1
                ? configurablePrimaryAccountFieldValues.DETAIL1
                : "",
              configurablePrimaryAccountFieldValues.DETAIL2
                ? configurablePrimaryAccountFieldValues.DETAIL2
                : "",
            ])
          );
        }
      });
    });
  }
  /**CONFIGURATION INJECTION FUNCTIONS */
  private async fetchClientJeValueMappings() {
    this.availableJournalEntries = FirestoreUtilities.mapToType(
      await this.afs
        .collection("standardJournalEntries")
        .snapshotChanges()
        .pipe(first())
        .toPromise()
    );
  }
  private getFieldValue(
    fieldCode: string,
    journalEntry: StandardJournalEntry,
    thirdParty: ThirdParty,
    location: Location,
    entry: string,
    isOffset: boolean,
    value?: any,
    document?: ThirdPartyTransaction | ThirdPartyPayout,
    reconciliation?: ThirdPartyReconciliationLocationData
  ) {
    const state = location ? location.addressState : null;
    let fieldConfigs: ClientJeValueConfiguration[] =
      this.clientJeValueConfigurations.filter(
        (config) =>
          config.field === fieldCode &&
          !!config.isOffsetAccount === !!isOffset &&
          config.journalEntry === journalEntry.id
      );
    if (fieldConfigs?.length > 0) {
      let matchingConfig: ClientJeValueConfiguration;
      fieldConfigs.forEach((config) => {
        let intersectionScore = 0;
        if (location) {
          intersectionScore =
            config.location === location.locationId
              ? intersectionScore + 1
              : config.location && config.location !== location.locationId
              ? intersectionScore - 5
              : intersectionScore;
        }

        if (thirdParty) {
          intersectionScore =
            config.thirdParty === thirdParty.id
              ? intersectionScore + 1
              : config.thirdParty && config.thirdParty !== thirdParty.id
              ? intersectionScore - 5
              : intersectionScore;
        }
        if (state) {
          intersectionScore =
            config.state === state
              ? intersectionScore + 1
              : config.state && config.state !== state
              ? intersectionScore - 5
              : intersectionScore;
        }
        if (config.entity) {
          intersectionScore =
            config.entity === location.entity
              ? intersectionScore + 1
              : config.entity && config.entity !== location.entity
              ? intersectionScore - 5
              : intersectionScore;
        }
        if (entry) {
          intersectionScore =
            config.entry === entry ? intersectionScore + 1 : intersectionScore;
        }
        config["intersectionScore"] = intersectionScore;
      });
      fieldConfigs = fieldConfigs
        .filter((filedConfig) => filedConfig["intersectionScore"] >= 0)
        .sort((a, b) =>
          a["intersectionScore"] > b["intersectionScore"] ? -1 : 1
        );
      if (fieldConfigs.length > 0) {
        matchingConfig = fieldConfigs[0];
      } else {
        return null;
      }
      if (matchingConfig) {
        if (matchingConfig.exeFunction) {
          return this.executeFunction(
            location,
            thirdParty,
            matchingConfig.exeFunction,
            value,
            reconciliation
          );
        }
        const processedValue = this.processOverrideValue(
          matchingConfig.overrideValue,
          location,
          thirdParty,
          entry,
          document
        );
        return processedValue;
      } else {
        return null;
      }
    }
    return null;
  }
  public executeFunction(
    location: Location,
    thirdParty: ThirdParty,
    code: string,
    value,
    reconciliation?: ThirdPartyReconciliationLocationData
  ) {
    try {
      const dataConverter = new DataConverter();
      const f = new Function(
        "location",
        "thirdParty",
        "value",
        "dataConverter",
        "reconciliation",
        code
      );
      return f(
        location,
        thirdParty,
        value,
        dataConverter,
        reconciliation,
        moment
      );
    } catch (e: any) {
      throw new Error(
        `Data Converter CODE Error: ${code} \n ${JSON.stringify({
          value,
          location,
          thirdParty,
        })} \n ${e.message}`
      );
    }
  }
  processOverrideValue(value, location, thirdParty, entry?, document?: any) {
    let processedValue = value;
    if (processedValue || processedValue === 0) {
      processedValue = value
        .replace(`<reportName>`, this.dataUtility.existingReport.name)
        .replace("<startDate>", this.dataUtility.startDateText)
        .replace("<endDate>", this.dataUtility.endDateText)
        .replace("<thirdParty>", thirdParty.name)
        .replace("<locationId>", location.locationId)
        .replace("<locationName>", location.name)
        .replace("<locationAlias>", location.alias)
        .replace("<entry>", entry)
        .replace(
          "<payoutDate>",
          document?.payoutDate
            ? moment(document.payoutDate.toDate()).format("l")
            : ""
        )
        .replace(
          "<transactionDate>",
          document && document["date"]
            ? moment(document["date"].toDate()).format("l")
            : ""
        );
      if (processedValue.indexOf("<toNumber()>") > -1) {
        processedValue = +processedValue;
      }
      if (processedValue.indexOf("<removeSpaces()>") > -1) {
        processedValue = processedValue.replace(/\s/g, "");
        processedValue = processedValue.replace("<removeSpaces()>", "");
      }
    }
    return processedValue;
  }
  setStandardJeValueOverrides(
    reportId:
      | "recordCustomerRefunds"
      | "recordFeesAggregate"
      | "recordFeesDetail"
      | "recordSalesVarianceAggregate"
      | "recordSalesVarianceDetail"
      | "recordTaxAdjustment"
      | "recordUnreportedDepositDifference"
      | "recordDepositJe"
      | "recordTipsPayable"
      | "recordRefundAdjustmentTax"
      | "recordPosMarkup"
      | "recordOtherRevenue"
      | "recordDepositAggregate",
    isOffset: boolean,
    location: Location,
    thirdParty: ThirdParty,
    entry?: string,
    value?: any,
    document?: ThirdPartyTransaction | ThirdPartyPayout,
    reconciliation?: ThirdPartyReconciliationLocationData
  ) {
    const report = this.availableJournalEntries.find(
      (je) => je.id === reportId
    );

    const fieldValues = new JEFieldValues();
    fieldValues.JOURNAL = this.getFieldValue(
      "JOURNAL",
      report,
      thirdParty,
      location,
      entry,
      isOffset,
      value,
      document,
      reconciliation
    );
    fieldValues.DATE = this.getFieldValue(
      "DATE",
      report,
      thirdParty,
      location,
      entry,
      isOffset,
      value,
      document,
      reconciliation
    );

    fieldValues.DESCRIPTION = this.getFieldValue(
      "DESCRIPTION",
      report,
      thirdParty,
      location,
      entry,
      isOffset,
      value,
      document,
      reconciliation
    );
    fieldValues.ACCT = this.getFieldValue(
      "ACCT",
      report,
      thirdParty,
      location,
      entry,
      isOffset,
      value,
      document,
      reconciliation
    );
    fieldValues.DEPT = this.getFieldValue(
      "DEPT",
      report,
      thirdParty,
      location,
      entry,
      isOffset,
      value,
      document,
      reconciliation
    );
    fieldValues.MEMO = this.getFieldValue(
      "MEMO",
      report,
      thirdParty,
      location,
      entry,
      isOffset,
      value,
      document,
      reconciliation
    );
    fieldValues.LOCATION = this.getFieldValue(
      "LOCATION",
      report,
      thirdParty,
      location,
      entry,
      isOffset,
      value,
      document,
      reconciliation
    );

    fieldValues.DETAIL1 = this.getFieldValue(
      "DETAIL1",
      report,
      thirdParty,
      location,
      entry,
      isOffset,
      value,
      document,
      reconciliation
    );

    fieldValues.DETAIL2 = this.getFieldValue(
      "DETAIL2",
      report,
      thirdParty,
      location,
      entry,
      isOffset,
      value,
      document,
      reconciliation
    );
    fieldValues.CREDIT = this.getFieldValue(
      "CREDIT",
      report,
      thirdParty,
      location,
      entry,
      isOffset,
      value,
      document,
      reconciliation
    );

    fieldValues.DEBIT = this.getFieldValue(
      "DEBIT",
      report,
      thirdParty,
      location,
      entry,
      isOffset,
      value,
      document,
      reconciliation
    );
    return fieldValues;
  }
  async setJeHeaderRow() {
    this.headerRow = [
      "JOURNAL",
      "DATE",
      "DESCRIPTION",
      "ACCT",
      "DEPT",
      "LOCATION",
      "MEMO",
      "DEBIT",
      "CREDIT",
      "DETAIL1",
      "DETAIL2",
    ]
      .sort((a, b) => {
        const configAFound = this.clientJeHeaderConfigurations.find(
          (headerConfig) => headerConfig.field === a
        );
        const configBFound = this.clientJeHeaderConfigurations.find(
          (headerConfig) => headerConfig.field === b
        );
        return configAFound?.order > configBFound?.order
          ? 1
          : configAFound?.order < configBFound?.order
          ? -1
          : 0;
      })
      .map((defaultHeader) => {
        const configFound = this.clientJeHeaderConfigurations.find(
          (headerConfig) => headerConfig.field === defaultHeader
        );
        return configFound
          ? configFound.overrideValue === "NULL"
            ? ""
            : configFound.overrideValue
          : defaultHeader;
      });
  }
  public sortColumnValues(values: any[]) {
    let sortedArray = [];
    const defaultColumnOrder = [
      "JOURNAL",
      "DATE",
      "DESCRIPTION",
      "ACCT",
      "DEPT",
      "LOCATION",
      "MEMO",
      "DEBIT",
      "CREDIT",
      "DETAIL1",
      "DETAIL2",
    ];
    sortedArray = values
      .map((row, index) => {
        return { value: row, i: index };
      })
      .sort((a, b) => {
        const configAFound = this.clientJeHeaderConfigurations.find(
          (headerConfig) => headerConfig.field === defaultColumnOrder[a.i]
        );
        const configBFound = this.clientJeHeaderConfigurations.find(
          (headerConfig) => headerConfig.field === defaultColumnOrder[b.i]
        );
        return configAFound?.order > configBFound?.order
          ? 1
          : configAFound?.order < configBFound?.order
          ? -1
          : 0;
      });
    return sortedArray.map((row) => row.value);
  }
  public async fetchClientJeHeaderConfigurations() {
    const allClientJeHeaderConfigs = FirestoreUtilities.mapToType(
      await this.afs
        .collection("clientJeHeaderConfigurations", (ref) =>
          ref.where("client", "==", this.dataUtility.client.id)
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise()
    );
    if (this.jeHeaderSelection) {
      this.clientJeHeaderConfigurations = allClientJeHeaderConfigs.filter(
        (headerConfig) => headerConfig.group === this.jeHeaderSelection
      );
    } else {
      this.clientJeHeaderConfigurations = allClientJeHeaderConfigs.filter(
        (headerConfig) => !headerConfig.group
      );
    }
  }
  public async fetchClientJeValueConfigurations() {
    this.clientJeValueConfigurations = FirestoreUtilities.mapToType(
      await this.afs
        .collection("clientJeValueConfigurations", (ref) =>
          ref.where("client", "==", this.dataUtility.client.id)
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise()
    );
  }

  private computeEntryValue(configuration, initialValue, isDebit) {
    const configuredOverride = isDebit
      ? configuration.DEBIT
      : configuration.CREDIT;
    return !!configuredOverride || configuredOverride === 0
      ? configuredOverride
      : initialValue;
  }
  private isConfiguredDebitCreditZero(
    configurableOffsetAccountFieldValues,
    configurablePrimaryAccountFieldValues,
    value
  ) {
    const configurablePrimaryDebitValue =
      configurableOffsetAccountFieldValues.DEBIT
        ? configurableOffsetAccountFieldValues.DEBIT
        : value;
    const configurablePrimaryCreditValue =
      configurableOffsetAccountFieldValues.CREDIT
        ? configurableOffsetAccountFieldValues.CREDIT
        : 0;
    const configurableOffsetDebitValue =
      configurablePrimaryAccountFieldValues.DEBIT
        ? configurablePrimaryAccountFieldValues.DEBIT
        : value;
    const configurableOffsetCreditValue =
      configurablePrimaryAccountFieldValues.CREDIT
        ? configurablePrimaryAccountFieldValues.CREDIT
        : 0;
    return (
      +configurablePrimaryDebitValue +
        +configurablePrimaryCreditValue +
        +configurableOffsetDebitValue +
        +configurableOffsetCreditValue ===
      0
    );
  }

  isMatchFieldConflicting(
    matchingConfig: ClientJeValueConfiguration,
    thirdParty: ThirdParty,
    location: Location,
    entry
  ) {
    let conflicts = false;
    if (
      matchingConfig.thirdParty &&
      matchingConfig.thirdParty !== thirdParty.id
    ) {
      conflicts = true;
      return conflicts;
    }
    if (
      matchingConfig.location &&
      matchingConfig.location !== location.locationId
    ) {
      conflicts = true;
      return conflicts;
    }
    if (matchingConfig.entry && matchingConfig.entry !== entry) {
      conflicts = true;
      return conflicts;
    }
  }
}
