import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { LocationSearchComponent } from "./location-search/location-search.component";
import { DeliveryPartnerSearchComponent } from "./delivery-partner-search/delivery-partner-search.component";
import { MaterialModule } from "app/material.module";
import { VarianceReasonSearchComponent } from "./variance-reason-search/variance-reason-search.component";
import { ClientSearchComponent } from "./client-search/client-search.component";

@NgModule({
  declarations: [
    LocationSearchComponent,
    DeliveryPartnerSearchComponent,
    VarianceReasonSearchComponent,
    ClientSearchComponent,
  ],
  exports: [
    LocationSearchComponent,
    DeliveryPartnerSearchComponent,
    VarianceReasonSearchComponent,
    ClientSearchComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
  ],
})
export class TypeSearchModule {}
