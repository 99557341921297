import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.scss"],
})
export class PrivacyPolicyComponent implements OnInit {
  privacyPolicy: string;

  constructor(private afs: AngularFirestore) {}

  ngOnInit() {
    this.afs
      .doc("staticContents/0")
      .snapshotChanges()
      .subscribe((staticContents$) => {
        this.privacyPolicy =
          FirestoreUtilities.objectToType(staticContents$).privacyPolicy;
      });
  }
}
