import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  ThirdPartyReport,
  UserView,
  ReportShare,
  ReportGroupTypes,
  Client,
  User,
} from "@deliver-sense-librarian/data-schema";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { from, of, Subject } from "rxjs";
import { Store } from "@ngrx/store";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AngularFirestore } from "@angular/fire/firestore";
import { combineAll, takeUntil, map, catchError, first } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { ConfirmDialogComponent } from "app/dialogs/confirm-dialog/confirm-dialog.component";
import { NewTpdReportDialogComponent } from "app/dialogs/new-tpd-report-dialog/new-tpd-report-dialog.component";
import { UiState } from "app/redux/custom-states/uiState/ui-state";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";

@Component({
  selector: "app-shared-with-me-reports",
  templateUrl: "./shared-with-me-reports.component.html",
  styleUrls: ["./shared-with-me-reports.component.scss"],
})
export class SharedWithMeReportsComponent implements OnInit {
  @Input() selectedReportType: ReportGroupTypes;
  @Input() client: Client;
  @Input() user: User;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<ThirdPartyReport>;
  public displayedColumns: string[] = ["name", "creator", "date"];
  private destroy$ = new Subject();
  private creators: UserView[] = [];
  private reports: ThirdPartyReport[] = [];
  private reportTypeCollectionName:
    | "thirdPartyReports"
    | "errorChargeReports"
    | "trendAnalysisReports"
    | "payoutReports";
  reportTypeRoute: string;

  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.setReportTypeInfo();
    this.getReports();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  setReportTypeInfo() {
    switch (this.selectedReportType) {
      case ReportGroupTypes.errorCharge:
        this.reportTypeCollectionName = "errorChargeReports";
        this.reportTypeRoute = "error-charge-manager";
        break;
      case ReportGroupTypes.thirdPartyReconciliation:
        this.reportTypeCollectionName = "thirdPartyReports";
        this.reportTypeRoute = "3pd-reports";
        break;
      case ReportGroupTypes.trendAnalysis:
        this.reportTypeCollectionName = "trendAnalysisReports";
        this.reportTypeRoute = "trend-analysis";
        break;
      case ReportGroupTypes.payoutSummary:
        this.reportTypeCollectionName = "payoutReports";
        this.reportTypeRoute = "payout-reporting";
        break;
    }
  }
  public getCreatorInfo(doc$) {
    const creator = this.creators.find((user) => user.id === doc$.creator);
    return creator
      ? creator.email
        ? creator.email
        : `${creator.firstName} ${creator.lastName}`
      : "N/A";
  }

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  goToReport(report) {
    this.router.navigate([`/app/${this.reportTypeRoute}/${report.id}`], {
      queryParams: {
        shared: true,
      },
    });
  }
  private getReports() {
    this.afs
      .collection("reportShares", (ref) =>
        ref
          .where("client", "==", this.client.id)
          .where("toUser", "==", this.user.id)
          .where("type", "==", this.selectedReportType)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((sharedReportsQuery$) => {
        const reportShares = <ReportShare[]>(
          FirestoreUtilities.mapToType(sharedReportsQuery$)
        );
        if (reportShares.length > 0) {
          const reportRequests = reportShares.map((reportShare) => {
            return this.afs
              .doc(`${this.reportTypeCollectionName}/${reportShare.report}`)
              .snapshotChanges();
          });
          from(reportRequests)
            .pipe(combineAll(), first())
            .subscribe(
              (reportsQuery$) => {
                this.reports = <ThirdPartyReport[]>(
                  FirestoreUtilities.mergeToType(reportsQuery$).sort(
                    (a: ThirdPartyReport, b: ThirdPartyReport) => {
                      return moment(a.dateUpdated.toDate()).isSameOrBefore(
                        b.dateUpdated.toDate()
                      )
                        ? 1
                        : -1;
                    }
                  )
                );
                this.reports.forEach((report) => {
                  report.creator = this.getCreatorInfo(report);
                });
                this.tableData = new MatTableDataSource(this.reports);
                this.tableData.paginator = this.paginator;
                this.tableData.sort = this.sort;
                this.getReportCreators();
              },
              (e) => {
                `error ${this.reportTypeCollectionName}: ${e.message}`;
              }
            );
        }
      });
  }

  private getReportCreators() {
    const creatorRequests = this.reports.map((doc$: ThirdPartyReport) => {
      return this.afs.doc(`userViews/${doc$.creator}`).snapshotChanges();
    });
    from(creatorRequests)
      .pipe(combineAll(), takeUntil(this.destroy$))
      .subscribe((userViews$) => {
        this.creators = FirestoreUtilities.mergeToType(userViews$).filter(
          (item) => !!item
        );
      });
  }

  async deleteReport(row: ThirdPartyReport) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Delete",
        message: "Are you sure you want to delete this report?",
        action: "Yes, Delete",
      },
    });
    confirmDialog.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        // await this.afs.doc(`%/${row.id}`).delete();
        this.snackBar.open(
          "Report deleted has been disabled in sharing center.",
          "Dismiss",
          {
            duration: 5000,
          }
        );
      }
    });
  }
}
