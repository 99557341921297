import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "lodash";
import { Papa } from "ngx-papaparse";
import {
  ThirdPartyReconciliationLocationData,
  ThirdPartyReportTaxSummary,
} from "@deliver-sense-librarian/data-schema";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import { ReconciliationReportDatUtility } from "../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../utilities/reconciliation-report-filtering.utility";
@Component({
  selector: "app-tax-summary-report",
  templateUrl: "./tax-summary-report.component.html",
  styleUrls: ["./tax-summary-report.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TaxSummaryReportComponent implements OnInit, AfterViewInit {
  @Input() exportUtility: ReconciliationReportExportUtility;
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  public taxSummaries: ThirdPartyReportTaxSummary[] = [];
  public loadingData = false;
  constructor(
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private papa: Papa
  ) {}

  ngOnInit() {
    this.initializeTable();
  }
  ngAfterViewInit() {}
  private async initializeTable() {
    this.compileThirdPartyTaxSummary();
  }

  private compileThirdPartyTaxSummary() {
    this.taxSummaries = this.dataUtility.reportData.map((locationReport) => {
      return this.runTaxSummaryCalcs(locationReport);
    });
  }

  private runTaxSummaryCalcs(
    reconciliation: ThirdPartyReconciliationLocationData
  ) {
    const taxSummary = new ThirdPartyReportTaxSummary();
    const location = this.dataUtility.locations.find(
      (location) => location.locationId === reconciliation.locationId
    );
    if (location) {
      const mfRates = location.mfRateNotes;
      taxSummary.location = location.locationId;
      taxSummary.stateProvince = location.addressState;
      taxSummary.thirdParty = reconciliation.thirdParty;
      taxSummary["thirdPartyName"] = this.dataUtility.getThirdPartyName(
        reconciliation.thirdParty
      );
      taxSummary.partialMf = reconciliation.isPartialMfTax;
      taxSummary.compsDiscounts = reconciliation.campaignPromoFees;
      taxSummary.taxRate =
        location.stateTaxRate +
        location.countyTaxRate +
        location.cityTaxRate +
        location.specialTaxRate;
      taxSummary.thirdPartyAverageTaxRate = +(
        reconciliation.thirdPartyAverageTaxRate * 100
      ).toFixed(2);
      taxSummary.totalSales = reconciliation.thirdPartyNetSales;
      taxSummary.mfSales =
        reconciliation.marketFacilitatorTax !== 0
          ? reconciliation.thirdPartyNetSales
          : 0;
      taxSummary.totalTax = Math.abs(reconciliation.marketFacilitatorTax);
      taxSummary.taxableSales =
        taxSummary.totalSales === taxSummary.mfSales
          ? 0
          : taxSummary.totalSales -
            taxSummary.mfSales -
            taxSummary.compsDiscounts;
      taxSummary.estimatedTaxDue =
        taxSummary.taxableSales * (taxSummary.taxRate / 100);
      taxSummary.nonPartialPer3pd =
        reconciliation.marketFacilitatorTax >= 0
          ? reconciliation.clientTaxResponsibility
          : 0;
      taxSummary.variance =
        taxSummary.estimatedTaxDue - taxSummary.nonPartialPer3pd;
      taxSummary.localOnlyTax = reconciliation.isPartialMfTax
        ? taxSummary.mfSales - taxSummary.compsDiscounts
        : 0;
      taxSummary.clientTaxRate = mfRates?.length > 0 ? mfRates[0].rate : 0;
      taxSummary.clientResponsibility =
        taxSummary.localOnlyTax * (taxSummary.clientTaxRate / 100);
      taxSummary.partialPer3pd =
        reconciliation.marketFacilitatorTax !== 0
          ? reconciliation.clientTaxResponsibility
          : 0;
      taxSummary.partialVariance =
        taxSummary.clientResponsibility - taxSummary.partialPer3pd;
      taxSummary.notes =
        mfRates?.length > 0
          ? mfRates.reduce((txt, rate) => {
              if (!txt) {
                return `${rate.note} @ ${rate.rate}%`;
              } else {
                return `${txt}, ${rate.note} @ ${rate.rate}%`;
              }
            }, ``)
          : null;
      taxSummary.posNetTax = reconciliation.posNetTax;
      taxSummary.suggestedAdjustment = reconciliation.suggestedTaxAdjustment;
      return taxSummary;
    }
  }

  getVariance(a: number, b: number) {
    return a - b;
  }
}
