import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ReconciliationReportDatUtility } from "../../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../../utilities/reconciliation-report-filtering.utility";
import { FormControl } from "@angular/forms";
import { AfterViewInit, AfterViewChecked } from "@angular/core";
import { TransactionsDialogComponent } from "app/dialogs/transactions-dialog/transactions-dialog.component";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { AngularFirestore } from "@angular/fire/firestore";
import { LoadingDialogService } from "../../../../../../services/loading-dialog.service";
import { first, startWith } from "rxjs/operators";
import { combineLatest } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { getTimeFromMinutesFromMidnight } from "app/shared/ds-constant";
import _, { startsWith } from "lodash";
import { TransactionAITypes } from "@deliver-sense-librarian/data-schema";
import { TADrillDownSelection } from "../../daily-drill-down/daily-drill-down.component";

@Component({
  selector: "app-transaction-analysis-table",
  templateUrl: "./transaction-analysis-table.component.html",
  styleUrls: ["./transaction-analysis-table.component.scss"],
})
export class TransactionAnalysisTableComponent
  implements OnInit, AfterViewInit
{
  @Input() data: any[] = [];
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() preTASelection: TADrillDownSelection;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @Output() jumpToDailyDrillDown = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    "location",
    "thirdParty",
    "type",
    "source",
    "date",
    "time",
    "sale",
    "tax",
    "tip",
    "misc",
    "description",
    "status",
    "transactionType",
    "count",
    "posTransactionId",
    "outOfPeriod",
  ];

  public filter3pdsControl = new FormControl([]);
  public filterLocationsControl = new FormControl([]);
  public filterTypeControl = new FormControl([]);
  constructor(
    private dialog: MatDialog,
    private afs: AngularFirestore,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar
  ) {}
  ngOnInit(): void {
    this.tableData = new MatTableDataSource(this.data);
  }
  ngAfterViewInit(): void {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.setFilterPredicate(
      this.tableData,
      this.filter3pdsControl,
      this.filterLocationsControl,
      this.filterTypeControl
    );
    this.checkForPreSelection();
  }
  checkForPreSelection() {
    if (this.preTASelection) {
      this.filterLocationsControl.patchValue([this.preTASelection.locationId]);
      this.filter3pdsControl.patchValue([this.preTASelection.thirdPartyId]);
      this.filterTypeControl.patchValue([this.preTASelection.type]);
      this.filterLocationsControl.updateValueAndValidity();
      this.filter3pdsControl.updateValueAndValidity();
      this.filterTypeControl.updateValueAndValidity();
    }
  }
  getFormattedTime(time) {
    return getTimeFromMinutesFromMidnight(time);
  }
  public async openTransactionViewer(transactionId) {
    try {
      this.loadingService.isLoading(true, "Finding transaction...");
      const transaction = FirestoreUtilities.objectToType(
        await this.afs
          .doc(`thirdPartyTransactions/${transactionId}`)
          .snapshotChanges()
          .pipe(first())
          .toPromise()
      );
      this.loadingService.isLoading(false);
      this.dialog.open(TransactionsDialogComponent, {
        panelClass: "invisible-panel-dialog",
        data: {
          transactions: [transaction],
        },
      });
    } catch (e) {
      this.loadingService.isLoading(false);
      this.snackBar.open(
        `Could not find transactions. Please refresh and try again`,
        "Dismiss",
        { duration: 5000 }
      );
    }
  }

  /***Filter Predicates */
  public setFilterPredicate(
    tableData: MatTableDataSource<any>,
    filter3pdsControl,
    filterLocationsControl,
    filterTypeControl
  ) {
    tableData.filterPredicate = (data: any, filtersJson: string) => {
      const filterGroups = _.groupBy(JSON.parse(filtersJson), "id");
      let matchThirdPartyName = true;
      let matchLocationId = true;
      let matchType = true;
      if (filterGroups.thirdParty?.length > 0) {
        matchThirdPartyName = !!filterGroups.thirdParty.find((dspFilter) => {
          return (
            dspFilter.value === data.thirdParty ||
            dspFilter.name === data.thirdPartyName
          );
        });
      }
      if (filterGroups.locationId?.length > 0) {
        matchLocationId = !!filterGroups.locationId.find(
          (locationIdFilter) =>
            // Location will be locationId in rec table but location in all other reports
            locationIdFilter.value === data.locationId ||
            locationIdFilter.value === data.location
        );
      }
      if (filterGroups.type?.length > 0) {
        matchType = !!filterGroups.type.find(
          (typeFilter) => typeFilter.value === data.type
        );
      }

      const matchAll = matchThirdPartyName && matchLocationId && matchType;
      return matchAll;
    };
    this.setupFilterListeners(
      tableData,
      filter3pdsControl,
      filterLocationsControl,
      filterTypeControl
    );
  }
  private setupFilterListeners(
    tableData: MatTableDataSource<any>,
    filter3pdsControl: FormControl,
    filterLocationsControl: FormControl,
    filterTypeControl: FormControl
  ) {
    combineLatest([
      filter3pdsControl.valueChanges.pipe(startWith([])),
      filterLocationsControl.valueChanges.pipe(startWith([])),
      filterTypeControl.valueChanges.pipe(startWith([])),
    ]).subscribe(([selected3pds, selectedLocations, selectedTypes]) => {
      this.multiFilter(
        tableData,
        selected3pds,
        selectedLocations,
        selectedTypes
      );
    });
  }
  private multiFilter(
    tableData: MatTableDataSource<any>,
    selected3pds: string[],
    selectedLocations: string[],
    selectedTypes: string[]
  ) {
    let filters;
    let tpdFilters = [];
    let locationFilters = [];
    let typeFilters = [];
    if (selectedLocations.length > 0) {
      locationFilters = selectedLocations.map((locationId) => {
        return { id: "locationId", value: locationId };
      });
    }

    if (selected3pds.length > 0) {
      tpdFilters = selected3pds.map((dspId) => {
        const dsp = this.dataUtility.thirdParties.find(
          (dsp) => dsp.id === dspId
        );
        return { id: "thirdParty", value: dspId, name: dsp.name };
      });
    }
    if (selectedTypes.length > 0) {
      typeFilters = selectedTypes.map((type) => {
        return { id: "type", value: type };
      });
    }
    const filtersArray = [...tpdFilters, ...locationFilters, ...typeFilters];
    if (filtersArray.length > 0) {
      filters = JSON.stringify(filtersArray);
    } else {
      filters = "";
    }
    tableData.filter = filters;
    tableData.paginator.firstPage();
  }
}
