import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  Entity,
  OrganizationRole,
  UserView,
} from "@deliver-sense-librarian/data-schema";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatTableDataSource } from "@angular/material/table";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { UserRoles } from "@deliver-sense-librarian/data-schema";

@Component({
  selector: "app-entity-team-members",
  templateUrl: "./entity-team-members.component.html",
  styleUrls: ["./entity-team-members.component.scss"],
})
export class EntityTeamMembersComponent implements OnInit {
  @Input() entity: Entity;
  public entityTeamMembers: UserView[];
  private destroy$ = new Subject();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<UserView>;
  public displayedColumns: string[] = [
    "firstName",
    "lastName",
    "role",
    "email",
  ];
  constructor(private afs: AngularFirestore) {}

  ngOnInit(): void {
    this.getTeamMembers();
  }
  ngOnDestroy() {}
  private getTeamMembers() {
    this.afs
      .collection("userViews", (ref) =>
        ref.where("clients", "array-contains", this.entity.client)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (userViews$) => {
        const teamMembers = FirestoreUtilities.mapToType(userViews$);
        this.entityTeamMembers = await this.fetchTeamMemberClientRoles(
          teamMembers
        );
        this.tableData = new MatTableDataSource(this.entityTeamMembers);
        this.tableData.paginator = this.paginator;
        this.tableData.sort = this.sort;
      });
  }
  private async fetchTeamMemberClientRoles(teamMembers) {
    return (
      await Promise.all(
        teamMembers.map(async (teamMember) => {
          const orgRoles = FirestoreUtilities.mapToType(
            await this.afs
              .collection(
                `users/${teamMember.id}/clientRoles/${this.entity.client}/organizationRoles`
              )
              .snapshotChanges()
              .pipe(first())
              .toPromise()
          );

          const teamMemberEntityAccess = orgRoles.find(
            (orgRole) =>
              orgRole.type === "entity" && orgRole.resource === this.entity.id
          );
          if (teamMemberEntityAccess) {
            teamMember["entityRole"] = UserRoles[teamMemberEntityAccess.role];
            return teamMember;
          }
          return null;
        })
      )
    ).filter((teamMember) => !!teamMember);
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
}
