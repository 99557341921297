import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ClientUploadChecklistItem } from "@deliver-sense-librarian/data-schema";

@Component({
  selector: "app-upload-group-checklist-dialog",
  templateUrl: "./upload-group-checklist-dialog.component.html",
  styleUrls: ["./upload-group-checklist-dialog.component.scss"],
})
export class UploadGroupChecklistDialogComponent implements OnInit {
  public checklist: any[];
  constructor(
    public dialogRef: MatDialogRef<UploadGroupChecklistDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      uploadChecklist: ClientUploadChecklistItem[];
    }
  ) {
    this.checklist = Object.assign(data.uploadChecklist);
  }

  ngOnInit() {}

  saveChecklistProgress() {
    this.dialogRef.close(this.checklist);
  }
}
