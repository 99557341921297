import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  ClientWebhookDelivery,
  ClientWebhookSubscription,
  User,
} from "@deliver-sense-librarian/data-schema";
import { downloadDataAsFile } from "app/shared/ds-constant";
import { PopoverDirective } from "ngx-bootstrap/popover";
import { Papa } from "ngx-papaparse";

@Component({
  selector: "app-client-webhook-log",
  templateUrl: "./client-webhook-log.component.html",
  styleUrls: ["./client-webhook-log.component.scss"],
})
export class ClientWebhookLogComponent implements OnInit {
  @Input() webhookLogs: ClientWebhookDelivery[];
  @Output() clearLogs = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<ClientWebhookDelivery>;
  public displayedColumns: string[] = [
    "id",
    "date",
    "status",
    "response",
    "export",
  ];
  responseToView: any;
  activePopover: PopoverDirective;
  constructor(private papa: Papa) {}

  ngOnInit(): void {
    this.tableData = new MatTableDataSource(this.webhookLogs);
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
  }
  public setReponseToView(
    delivery: ClientWebhookDelivery,
    activePopover: PopoverDirective
  ) {
    this.responseToView = delivery.response;
    this.activePopover = activePopover;
  }
  back() {
    this.clearLogs.emit(true);
  }
  export(log: ClientWebhookDelivery) {
    if (log["data"]) {
      const data = log["data"]
        ? log["data"].journalEntries
          ? log["data"].journalEntries
          : log["data"].data
        : [];
      const results = this.papa.unparse(data, {
        quotes: false,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ",",
        header: true,
        newline: "\r\n",
        skipEmptyLines: false,
      });
      const fileName = `Webhook_data`;
      downloadDataAsFile(results, fileName, "csv");
    }
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
}
