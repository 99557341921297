import {
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  ThirdPartyTransaction,
  ThirdPartyReportFragmentErrorChargeLog,
} from "@deliver-sense-librarian/data-schema";
import {
  downloadDataAsFile,
  getTimeFromMinutesFrom,
} from "app/shared/ds-constant";
import { ReconciliationReportDatUtility } from "../../../utilities/reconciliation-report-data.utility";
import { TransactionHistoryDialogComponent } from "../../../../../../dialogs/transaction-history-dialog/transaction-history-dialog.component";
import { ReconciliationReportFilteringUtility } from "../../../utilities/reconciliation-report-filtering.utility";
import moment from "moment";
import { Papa } from "ngx-papaparse";
import _ from "lodash";
import { FormControl } from "@angular/forms";
export class ErrorChargeTransaction extends ThirdPartyTransaction {
  errorChargeLog: ThirdPartyReportFragmentErrorChargeLog;
  amount: number;
}
@Component({
  selector: "app-revenue-recovery-summary-table",
  templateUrl: "./revenue-recovery-summary-table.component.html",
  styleUrls: ["./revenue-recovery-summary-table.component.scss"],
})
export class RevenueRecoverySummaryTableComponent
  implements OnInit, AfterViewInit
{
  @Input() data: ThirdPartyTransaction[] = [];
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    "location",
    "thirdParty",
    "date",
    "payoutDate",
    "saleAdjustment",
    "taxAdjustment",
    "transactionId",
    "status",
    "transactionType",
    "description",
  ];

  public filter3pdsControl = new FormControl([]);
  public filterLocationsControl = new FormControl([]);
  constructor(
    private dialog: MatDialog,
    private afs: AngularFirestore,
    private snackBar: MatSnackBar,
    private papa: Papa
  ) {}

  ngOnInit(): void {
    this.tableData = new MatTableDataSource(this.data);
  }
  ngAfterViewInit(): void {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.filteringUtility.set3pdAndLocationFilterPredicate(
      this.tableData,
      this.filter3pdsControl,
      this.filterLocationsControl
    );
  }

  getFormattedTime(minutes) {
    return getTimeFromMinutesFrom(minutes);
  }
  public openTransactionHistory(transaction) {
    this.dialog.open(TransactionHistoryDialogComponent, {
      panelClass: "invisible-panel-dialog",
      data: {
        transaction,
      },
    });
  }

  exportReport() {
    const data = Object.assign(
      this.tableData.data.map((transaction: ThirdPartyTransaction) => {
        return {
          Location: transaction.location,
          "Third Party": transaction["thirdPartyName"],
          "Payout Date": transaction.payoutDate?.seconds
            ? moment(transaction.payoutDate.toDate()).format("l")
            : transaction.payoutDate,
          Date: transaction.date?.seconds
            ? moment(transaction.date.toDate()).format("l")
            : transaction.date,
          "Sale Adjustment": transaction["saleAdjustment"], // compiled value
          "Tax Adjustment": transaction.taxCorrection,
          "Promo Fees": transaction.promoFee,
          "Transaction Id": transaction.transactionId,
          Status: transaction.status,
          "Transaction Type": transaction.transactionType,
          Description: transaction.description,
        };
      })
    );
    const results = this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const fileName = `Revenue-recovered_${moment(
      this.dataUtility.existingReport.startDate.toDate()
    ).format("M.DD.YYYY")}-${moment(
      this.dataUtility.existingReport.endDate.toDate()
    ).format("M.DD.YYYY")}`;
    downloadDataAsFile(results, fileName, "csv");
  }
  public getTotalOfType(type) {
    return this.tableData.filteredData.reduce((sum, current) => {
      return (sum += +current[type] ? +current[type] : 0);
    }, 0);
  }
}
