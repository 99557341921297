import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FirestoreUtilities } from "../../../../utilities/firestore-utilities";
import { Router } from "@angular/router";
import {
  Location,
  Entity,
  Client,
  LocationThirdParty,
  ThirdParty,
  LocationThirdPartyItemRate,
  RateType,
  User,
  UserRoles,
  LocationThirdPartyMarketFacilitatorTaxRate,
} from "@deliver-sense-librarian/data-schema";
import { FormControl } from "@angular/forms";
import * as moment from "moment";
import { Store } from "@ngrx/store";
import { combineLatest, Subject } from "rxjs";
import { takeUntil, first, map } from "rxjs/operators";
import { LoadingDialogService } from "../../../../services/loading-dialog.service";
import * as crypto from "crypto-js";
import { UiState } from "../../../../redux/custom-states/uiState/ui-state";
import { AngularFireStorage } from "@angular/fire/storage";
import * as _ from "lodash";
import { AngularFireAuth } from "@angular/fire/auth";
import { environment } from "environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Papa } from "ngx-papaparse";

@Component({
  selector: "app-upload-locations",
  templateUrl: "./upload-locations.component.html",
  styleUrls: ["./upload-locations.component.scss"],
})
export class UploadLocationsComponent implements OnInit, OnDestroy {
  @Output() locationsUploaded = new EventEmitter();
  public user: User;
  public client: Client;
  public selectedEntity = new FormControl();
  public entities: Entity[];
  public locationUploadTemplateLocation;
  private thirdParties: ThirdParty[] = [];
  private rateTypes: RateType[] = [];
  private destroy$ = new Subject();
  private uiState: UiState;

  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private papa: Papa,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
    private http: HttpClient,
    private afAuth: AngularFireAuth
  ) {}

  ngOnInit() {
    this.store
      .select((store) => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((uiState$) => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$ as UiState;
          this.fetchResources();
          this.getLocationUploadSheetLocation();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  fetchResources() {
    combineLatest([
      FirestoreUtilities.getUserAccessibleResourcesOfType(
        "entities",
        this.afs,
        this.uiState.entities,
        [UserRoles.admin, UserRoles.contributor]
      ),
      this.afs.collection("thirdParties").snapshotChanges(),
      this.afs.collection("rateTypes").snapshotChanges(),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([entities$, thirdParties$, rateTypes$]) => {
        this.entities = entities$;
        this.thirdParties = FirestoreUtilities.mapToType(thirdParties$);
        this.rateTypes = FirestoreUtilities.mapToType(rateTypes$).sort(
          (a, b) => {
            return a < b ? -1 : 1;
          }
        );
      });
  }
  delay() {
    return new Promise(function (resolve, reject) {
      const timeoutId = setTimeout(function () {
        resolve(true);
      }, 2000);
    });
  }
  private async getToken(): Promise<HttpHeaders> {
    const token = await this.afAuth.idToken
      .pipe(
        first(),
        map((token$) => token$)
      )
      .toPromise();
    return new HttpHeaders().set("Authorization", `Bearer ${token}`);
  }
  async detectFiles(event) {
    if (event.target.files && event.target.files[0]) {
      const csvData = event.target.files[0];
      this.loadingService.isLoading(true, "Uploading locations");
      this.papa.parse(csvData, {
        header: true,
        worker: true,
        skipEmptyLines: true,
        complete: async (parsedData) => {
          const data = parsedData.data;
          if (this.isParsedDataValid(data)) {
            const locationData = this.serializeCSVToLocationData(data);
            const headerObj = await this.getToken();
            const url = `${environment.apiUrl}locations?client=${this.client.id}`;
            this.http
              .post(url, { locations: locationData }, { headers: headerObj })
              .subscribe(
                (locations$) => {
                  this.snackBar.open(
                    "Successfully saved locations ",
                    "Dismiss",
                    {
                      duration: 5000,
                    }
                  );
                  this.locationsUploaded.emit(true);
                  this.router.navigate(["/app/organization/locations"]);
                  this.loadingService.isLoading(false);
                },
                (e) => {
                  console.log(e.message);
                  this.snackBar.open("Error uploading locations", "Dismiss", {
                    duration: 5000,
                  });
                  this.loadingService.isLoading(false);
                }
              );
          } else {
            this.loadingService.isLoading(false);
            this.snackBar.open(
              "You must have an id for every location.",
              "Dismiss",
              {
                duration: 6000,
              }
            );
          }
        },
      });
    }
  }

  private createRolesAndLocationBatches(locationData: any) {
    const locationChunks = _.chunk(locationData, 450);
    return locationChunks.map((locations) => {
      const rolesBatch = this.afs.firestore.batch();
      const locationsBatch = this.afs.firestore.batch();
      locations.forEach((location: any) => {
        rolesBatch.set(
          this.afs.doc(
            `users/${this.user.id}/clientRoles/${this.client.id}/organizationRoles/${location.id}`
          ).ref,
          {
            resource: location.id,
            role: UserRoles.admin,
            type: "location",
            dateUpdated: moment().toDate(),
          }
        );
        locationsBatch.set(this.afs.doc(`locations/${location.id}`).ref, {
          locationId: location.locationId,
          name: location.name,
          entity: location.entity,
          client: location.client,
          primaryContact: location.primaryContact,
          addressLine1: location.addressLine1,
          addressLine2: location.addressLine2,
          addressCity: location.addressCity,
          addressState: location.addressState,
          addressPostalCode: location.addressPostalCode,
          cityTaxRate: location.cityTaxRate,
          countyTaxRate: location.countyTaxRate,
          stateTaxRate: location.stateTaxRate,
          specialTaxRate: location.specialTaxRate,
          thirdParties: location.thirdParties,
        });
      });
      return { rolesBatch, locationsBatch };
    });
  }

  serializeCSVToLocationData(data) {
    return data.map((location$) => {
      const newLocation = new Location();
      newLocation.locationId = location$["Location Number"];
      newLocation.id = this.getLocationClientHash(newLocation.locationId);
      newLocation.name = location$["Location Name"];
      newLocation.entity = this.selectedEntity.value.id;
      newLocation.client = this.client.id;
      newLocation.primaryContact = location$["Primary Contact Email"]
        ? location$["Primary Contact Email"]
        : null;
      newLocation.addressLine1 = location$["Address 1"]
        ? location$["Address 1"]
        : null;
      newLocation.addressLine2 = location$["Address 2"]
        ? location$["Address 2"]
        : null;
      newLocation.addressCity = location$["City"] ? location$["City"] : null;
      newLocation.addressState = location$["State"] ? location$["State"] : null;
      newLocation.addressPostalCode = location$["Zip"]
        ? location$["Zip"]
        : null;
      newLocation.cityTaxRate = +location$["City Tax Rate"]
        ? +location$["City Tax Rate"] * 100
        : 0;
      newLocation.countyTaxRate = +location$["County Tax Rate"]
        ? +location$["County Tax Rate"] * 100
        : 0;
      newLocation.stateTaxRate = +location$["State Tax Rate"]
        ? +location$["State Tax Rate"] * 100
        : 0;
      newLocation.specialTaxRate = location$["Special Tax Rate"]
        ? location$["Special Tax Rate"] * 100
        : 0;
      this.setLocationThirdPartyFeesAndRates(newLocation, location$);
      return newLocation;
    });
  }

  private setLocationThirdPartyFeesAndRates(
    newLocation: Location,
    uploadedLocation$
  ) {
    const locationThirdParties = [];
    this.thirdParties.forEach((thirdParty) => {
      const ldp = new LocationThirdParty();
      const ldpMfTaxRate = new LocationThirdPartyMarketFacilitatorTaxRate();
      const foodNaBevLDPRate = new LocationThirdPartyItemRate();
      const nonTaxableLDPRate = new LocationThirdPartyItemRate();
      const defaultEffectiveDate = moment().startOf("day").toDate();
      ldp.active = uploadedLocation$[thirdParty.name] === "TRUE";
      ldp.thirdParty = thirdParty.id;
      ldp.deliveryFee = uploadedLocation$[`${thirdParty.name} Fee`]
        ? uploadedLocation$[`${thirdParty.name} Fee`]
        : 0;
      ldp.mfApplicable = uploadedLocation$[`${thirdParty.name} MF Tax`] > 0;
      ldp["itemTaxRates"] = [];
      ldp["marketFacilitatorRates"] = [];
      if (uploadedLocation$[`${thirdParty.name} MF Tax`]) {
        ldpMfTaxRate.rate = uploadedLocation$[`${thirdParty.name} MF Tax`]
          ? uploadedLocation$[`${thirdParty.name} MF Tax`]
          : 0;
        ldpMfTaxRate.effectiveDate = uploadedLocation$[
          `${thirdParty.name} MF Tax Effective Date`
        ]
          ? moment(
              uploadedLocation$[`${thirdParty.name} MF Tax Effective Date`],
              "M/D/YYYY"
            )
              .startOf("day")
              .toDate()
          : defaultEffectiveDate;
        ldpMfTaxRate["thirdParty"] = thirdParty.id;
        ldp["marketFacilitatorRates"].push(ldpMfTaxRate);
      }
      foodNaBevLDPRate.effectiveDate = defaultEffectiveDate;
      foodNaBevLDPRate.rate = ldp.active
        ? this.getTotalTaxRate(uploadedLocation$)
        : 0;
      foodNaBevLDPRate.rateType = this.rateTypes.find(
        (rateType) => rateType.name === "Food & N/A Bev"
      ).id;
      foodNaBevLDPRate["thirdParty"] = thirdParty.id;
      nonTaxableLDPRate.effectiveDate = defaultEffectiveDate;
      nonTaxableLDPRate.rate = 0;
      nonTaxableLDPRate.rateType = this.rateTypes.find(
        (rateType) => rateType.name === "Non-Taxable"
      ).id;
      nonTaxableLDPRate["thirdParty"] = thirdParty.id;
      ldp["itemTaxRates"].push(nonTaxableLDPRate);
      ldp["itemTaxRates"].push(foodNaBevLDPRate);
      locationThirdParties.push(ldp);
    });
    newLocation["thirdParties"] = locationThirdParties;
  }

  private getTotalTaxRate(location$: Location) {
    const cityRate = +location$["City Tax Rate"]
      ? +location$["City Tax Rate"]
      : 0;
    const countyRate = +location$["County Tax Rate"]
      ? +location$["County Tax Rate"]
      : 0;
    const stateRate = +location$["State Tax Rate"]
      ? +location$["State Tax Rate"]
      : 0;
    const specialRate = +location$["Special Tax Rate"]
      ? +location$["Special Tax Rate"]
      : 0;
    return cityRate + countyRate + stateRate + specialRate;
  }

  private getLocationClientHash(locationId) {
    const digest = crypto.algo.MD5.create();
    digest.update(locationId);
    digest.update(this.client.id);
    const progressiveHash = digest.finalize();
    return progressiveHash.toString(crypto.enc.Hex);
  }

  private isParsedDataValid(data) {
    return true;
  }

  async getLocationUploadSheetLocation() {
    this.locationUploadTemplateLocation =
      await FirestoreUtilities.getDownloadUrlFromPath(
        this.storage,
        "data-sheets/deliver-sense-locations-upload-template.csv"
      );
  }
}
