import {
  recColumns,
  ThirdParty,
  Location,
} from "@deliver-sense-librarian/data-schema";
import { Workbook, Worksheet } from "exceljs";
import moment from "moment";
import { ReconciliationReportDatUtility } from "../reconciliation-report-data.utility";

const dspOptions = ["Uber", "GrubHub", "DoorDash", "EZ Cater"];
const vrOptions = [
  "Conviction Chicken",
  "Apps All Around",
  "Krispy Rice",
  "Kumi",
  "TGIF Catering",
  "Stonie Bowls",
  "Square Deal Pizza",
  "Wow Bao",
];

export function compileTGIFJournalEntries(
  recSummaryWorkbook: Workbook,
  dataUtility: ReconciliationReportDatUtility,
  skipChecks: boolean
) {
  const dsVarSheet = recSummaryWorkbook.addWorksheet("DSVar");
  const delExpSheet = recSummaryWorkbook.addWorksheet("DSDelExp");
  compileVarianceJournalEntrySheet(dsVarSheet, dataUtility);
  compileDeliveryExpenseSheet(delExpSheet, dataUtility);
  if (!skipChecks) {
    const jeCheckSheet = recSummaryWorkbook.addWorksheet("JE Check");
    compileJeCheckSheet(jeCheckSheet);
  }
  return [dsVarSheet, delExpSheet];
}
function compileJeCheckSheet(jeCheckSheet: Worksheet) {
  const headerRow = jeCheckSheet.addRow([
    "Sales Variance - Error Charges",
    "Sales Variance - Potential Pricing Issues",
    "Sales Variance - Transaction Missing In Source",
    "Sales Variance - Transaction in POS Not Paid Out",
    "Sales Variance - Other",
    "Tax Variance",
    "Delivery Fee Variance",
    "Promo Fees Total",

    "Uber Eats Delivery Expense",
    "Uber Eats Pickup Expense",
    "Door Dash Delivery Expense",
    "Door Dash Pickup Expense",
    "Grub Hub Delivery Expense",

    "Conviction Chicken DoorDash Delivery Expense",
    "Conviction Chicken Uber Delivery Expense",
    "Conviction Chicken GrubHub Delivery Expense",

    "Apps All Around Uber Delivery Expense",
    "Apps All Around DoorDash Delivery Expense",
    "Apps All Around GrubHub Delivery Expense",

    "Krispy Rice Grub Hub",
    "Krispy Rice Uber",
    "Krispy Rice DoorDash",

    "Olo Dispatch",

    "EZ Cater Delivery Expense",

    "Kumi Uber Delivery Expense",
    "Kumi DoorDash Delivery Expense",
    "Kumi GrubHub Delivery Expense",

    "TGIF Catering DoorDash Delivery Expense",

    "Stonie Bowls Uber Delivery Expense",
    "Stonie Bowls DoorDash Delivery Expense",

    "Square Deal Pizza Uber Delivery Expense",
    "Square Deal Pizza DoorDash Delivery Expense",
    "Square Deal Pizza GrubHub Delivery Expense",

    "Wow Bao Uber Delivery Expense",
    "Wow Bao DoorDash Delivery Expense",
    "Wow Bao GrubHub Delivery Expense",
  ]);
  const summaryRow = jeCheckSheet.addRow([
    { id: "summarySalVarErr", formula: `SUM('Variance Analysis'!F:F)` },
    { id: "summarySalVarPricing", formula: `SUM('Variance Analysis'!E:E)` },
    { id: "summaryTransMiss", formula: `SUM('Variance Analysis'!H:H)` },
    { id: "summaryNotPaid", formula: `SUM('Variance Analysis'!I:I)` },
    { id: "summarySaleVarOther", formula: `SUM('Variance Analysis'!G:G)` },
    {
      id: "summaryTaxVar",
      formula: `SUM('DS Report'!${recColumns["Tax Variance"]}:${recColumns["Tax Variance"]})-SUM('DS Report'!${recColumns["Market Facilitator Tax"]}:${recColumns["Market Facilitator Tax"]})`,
    },
    "", //summaryDelVar
    {
      id: "summaryPromoFee",
      formula: `-SUM('DS Report'!${recColumns["Promo Fees"]}:${recColumns["Promo Fees"]})`,
    },
    // TGIF
    {
      id: "summaryUeDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Uber Eats",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})`,
    },
    {
      id: "summaryUePuExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Uber Eats",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    {
      id: "summaryDdDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"DoorDash",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})`,
    },
    {
      id: "summaryDdPuExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"DoorDash",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    {
      id: "summaryGhDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"GrubHub",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})`,
    },
    // CONVICTION CHICKEN
    {
      id: "summaryCcDdDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Conviction Chicken DoorDash",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Conviction Chicken DoorDash",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    {
      id: "summaryCcUeDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Conviction Chicken Uber",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Conviction Chicken Uber",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    {
      id: "summaryCcGhDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Conviction Chicken GrubHub",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Conviction Chicken GrubHub",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    // APPS ALL AROUND
    {
      id: "summaryAaUeDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Apps All Around Uber",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Apps All Around Uber",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    {
      id: "summaryAaDdDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Apps All Around DoorDash",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Apps All Around DoorDash",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    {
      id: "summaryAaGhDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Apps All Around GrubHub",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Apps All Around GrubHub",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    // KRISPY RICE
    {
      id: "summaryKrGhDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Krispy Rice GrubHub",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Krispy Rice GrubHub",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    {
      id: "summaryKrUeDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Krispy Rice Uber",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Krispy Rice Uber",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    {
      id: "summaryKrDdDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Krispy Rice DoorDash",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Krispy Rice DoorDash",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    // OLO
    {
      id: "summaryOloDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Olo Dispatch",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Olo Dispatch",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    //EZ Cater
    {
      id: "summaryEzDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"ezCater",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"ezCater",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    // KUMI
    {
      id: "summaryKumiUeDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Kumi Uber",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Kumi Uber",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    {
      id: "summaryKumiDdDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Kumi DoorDash",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Kumi DoorDash",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    {
      id: "summaryKumiGhDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Kumi GrubHub",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Kumi GrubHub",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    // TGIF CATERING
    {
      id: "summaryCateringDdDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"TGIF Catering DoorDash",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"TGIF Catering DoorDash",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    // STONIE BOWLS
    {
      id: "summaryStonieUeDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Stonie Bowls Uber",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Stonie Bowls Uber",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    {
      id: "summaryStonieDdDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Stonie Bowls DoorDash",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Stonie Bowls DoorDash",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    //SQUARE DEAL PIZZA
    {
      id: "summarySquareDealPizzaUeDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Square Deal Pizza Uber",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Square Deal Pizza Uber",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    {
      id: "summarySquareDealPizzaDdDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Square Deal Pizza DoorDash",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Square Deal Pizza DoorDash",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    {
      id: "summarySquareDealPizzaGhDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Square Deal Pizza GrubHub",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Square Deal Pizza GrubHub",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    //Wow Bao PIZZA
    {
      id: "summaryWowBaoUeDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Wow Bao Uber",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Wow Bao Uber",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    {
      id: "summaryWowBaoDdDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Wow Bao DoorDash",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Wow Bao DoorDash",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
    {
      id: "summaryWowBaoGhDelExp",
      formula: `SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Wow Bao GrubHub",'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})+SUMIF('DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Wow Bao GrubHub",'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
    },
  ]);
  const jeRow = jeCheckSheet.addRow([
    { id: "jeSalVarErr", formula: `SUM(DSVar!D:D)` },
    { id: "jeSalVarPricing", formula: `SUM(DSVar!E:E)` },
    { id: "jeTransMiss", formula: `SUM(DSVar!F:F)` },
    { id: "jeNotPaid", formula: `SUM(DSVar!G:G)` },
    { id: "jeSaleVarOther", formula: `SUM(DSVar!H:H)` },
    { id: "jeTaxVar", formula: `SUM(DSVar!I:I)` },
    "", //summaryDelVar
    { id: "jePromoFee", formula: `SUM(DSVar!K:K)` },
    // TGIF
    { id: "jeUeDelExp", formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,I1)` },
    { id: "jeUePuExp", formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,J1)` },
    { id: "jeDdDelExp", formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,K1)` },
    { id: "jeDdPuExp", formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,L1)` },
    { id: "jeGhDelExp", formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,M1)` },
    // Conviction Chicken
    {
      id: "jeCcDdDelExp",
      formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,N1)`,
    },
    {
      id: "jeCcUeDelExp",
      formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,O1)`,
    },
    {
      id: "jeCcGhDelExp",
      formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,P1)`,
    },
    // Apps All Around
    {
      id: "jeAaaUeDelExp",
      formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,Q1)`,
    },
    {
      id: "jeAaaDdDelExp",
      formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,R1)`,
    },
    {
      id: "jeAaaGhDelExp",
      formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,S1)`,
    },
    // Krispy Rice
    {
      id: "jeKrUeDelExp",
      formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,T1)`,
    },
    {
      id: "jeKrDdDelExp",
      formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,U1)`,
    },
    {
      id: "jeKrGhDelExp",
      formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,V1)`,
    },
    // Olo
    {
      id: "jeOloDisDelExp",
      formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,W1)`,
    },
    // ez Cater
    { id: "jeEZDelExp", formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,X1)` },
    // Kumi
    {
      id: "jeKumiUeDelExp",
      formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,Y1)`,
    },
    {
      id: "jeKumiDdDelExp",
      formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,Z1)`,
    },
    {
      id: "jeKumiGhDelExp",
      formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,AA1)`,
    },
    // TGIF Catering
    {
      id: "jeCateringDdDelExp",
      formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,AB1)`,
    },
    // Stonie Bowls
    {
      id: "jeStonieUeDelExp",
      formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,AC1)`,
    },
    {
      id: "jeStonieDdDelExp",
      formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,AD1)`,
    },
    //Square deal pizz
    {
      id: "jeSqrDealPizzaUeDelExp",
      formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,AE1)`,
    },
    {
      id: "jeSqrDealPizzaDdDelExp",
      formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,AF1)`,
    },
    {
      id: "jeSqrDealPizzaGhDelExp",
      formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,AG1)`,
    },
    // Wow Bao
    {
      id: "jeWowBaoUeDelExp",
      formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,AH1)`,
    },
    {
      id: "jeWowBaoDdDelExp",
      formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,AI1)`,
    },
    {
      id: "jeWowBaoGhDelExp",
      formula: `SUMIFS(DSDelExp!$D:$D,DSDelExp!$C:$C,AJ1)`,
    },
  ]);

  const diffRow = jeCheckSheet.addRow([
    { id: "a", formula: `A2-A3` },
    { id: "b", formula: `B2-B3` },
    { id: "c", formula: `C2-C3` },
    { id: "d", formula: `D2-D3` },
    { id: "e", formula: `E2-E3` },
    { id: "f", formula: `F2-F3` },
    "", //summaryDelVar
    { id: "h", formula: `H2-H3` },
    { id: "i", formula: `I2-I3` },
    { id: "j", formula: `J2-J3` },
    { id: "k", formula: `K2-K3` },
    { id: "l", formula: `L2-L3` },
    { id: "m", formula: `M2-M3` },
    { id: "n", formula: `N2-N3` },
    { id: "o", formula: `O2-O3` },
    { id: "p", formula: `P2-P3` },
    { id: "q", formula: `Q2-Q3` },
    { id: "r", formula: `R2-R3` },
    { id: "s", formula: `S2-S3` },
    { id: "t", formula: `T2-T3` },
    { id: "u", formula: `U2-U3` },
    { id: "v", formula: `V2-V3` },
    { id: "w", formula: `W2-W3` },
    { id: "X", formula: `X2-X3` },
    { id: "y", formula: `Y2-Y3` },
    { id: "z", formula: `Z2-Z3` },
    { id: "aa", formula: `AA2-AA3` },
    { id: "ab", formula: `AB2-AB3` },
    { id: "ac", formula: `AC2-AC3` },
    { id: "ad", formula: `AD2-AD3` },
    //square deal pizza
    { id: "ae", formula: `AE2-AE3` },
    { id: "af", formula: `AF2-AF3` },
    { id: "ag", formula: `AG2-AG3` },
    // Wow Bao
    { id: "ah", formula: `AH2-AH3` },
    { id: "ai", formula: `AI2-AI3` },
    { id: "aj", formula: `AJ2-AJ3` },
  ]);
}
function compileDeliveryExpenseSheet(
  delExpSheet: Worksheet,
  dataUtility: ReconciliationReportDatUtility
) {
  const weekEndingDate = moment(
    dataUtility.existingReport.endDate.toDate()
  ).format("M/D/YYYY");
  const headerRow = delExpSheet.addRow([
    "Week Ending Date",
    "Store ID",
    "Delivery Partner Name",
    "Expense",
  ]);
  let rowCount = 2;
  const deliveryPartnerNames = [
    "Uber Eats Delivery Expense",
    "Uber Eats Pickup Expense",
    "Door Dash Delivery Expense",
    "Door Dash Pickup Expense",
    "Grub Hub Delivery Expense",

    "EZ Cater Delivery Expense",

    "Grab N Go Expense",

    "Conviction Chicken DoorDash Delivery Expense",
    "Conviction Chicken Uber Delivery Expense",
    "Conviction Chicken GrubHub Delivery Expense",

    "Apps All Around Uber Delivery Expense",
    "Apps All Around DoorDash Delivery Expense",
    "Apps All Around GrubHub Delivery Expense",

    "Krispy Rice Grub Hub",
    "Krispy Rice Uber",
    "Krispy Rice DoorDash",

    "Olo Dispatch",

    "Kumi Uber Delivery Expense",
    "Kumi DoorDash Delivery Expense",
    "Kumi GrubHub Delivery Expense",

    "Stonie Bowls DoorDash Delivery Expense",
    "Stonie Bowls Uber Delivery Expense",

    "Square Deal Pizza Uber Delivery Expense",
    "Square Deal Pizza DoorDash Delivery Expense",
    "Square Deal Pizza GrubHub Delivery Expense",

    "Wow Bao Uber Delivery Expense",
    "Wow Bao DoorDash Delivery Expense",
    "Wow Bao GrubHub Delivery Expense",
  ];

  dataUtility.locations.forEach((location) => {
    deliveryPartnerNames.forEach((deliveryPartnerName) => {
      delExpSheet.addRow([
        weekEndingDate,
        +location.locationId,
        deliveryPartnerName,
        getTGIFDelExpense(deliveryPartnerName, rowCount),
      ]);
      rowCount++;
    });
  });
}

function getTGIFDelExpense(deliveryPartnerName: string, rowCount: number) {
  switch (deliveryPartnerName) {
    // TGIF
    case "Uber Eats Delivery Expense":
      return {
        id: "ueDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Uber Eats")`,
      };
    case "Uber Eats Pickup Expense":
      return {
        id: "uePuExp",
        formula: `+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Uber Eats")`,
      };
    case "Door Dash Delivery Expense":
      return {
        id: "doorDashDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"DoorDash")`,
      };
    case "Door Dash Pickup Expense":
      return {
        id: "doorDashPuExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"DoorDash")`,
      };
    case "Grub Hub Delivery Expense":
      return {
        id: "grubHubDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"GrubHub")`,
      };
    case "EZ Cater Delivery Expense":
      return {
        id: "ezDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"ezCater")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"ezCater")`,
      };
    case "Grab N Go Expense":
      // {
      //   id: "gngDelExp",
      //   formula: formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Grab N Go")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Grab N Go")`,
      // }
      return "";

    // Conviction Chicken
    case "Conviction Chicken DoorDash Delivery Expense":
      return {
        id: "ccDDDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Conviction Chicken DoorDash")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Conviction Chicken DoorDash")`,
      };
    case "Conviction Chicken Uber Delivery Expense":
      return {
        id: "ccUeDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Conviction Chicken Uber")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Conviction Chicken Uber")`,
      };
    case "Conviction Chicken GrubHub Delivery Expense":
      return {
        id: "cCGhDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Conviction Chicken GrubHub")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Conviction Chicken GrubHub")`,
      };
    // Apps All Around
    case "Apps All Around Uber Delivery Expense":
      return {
        id: "aaaUeDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Apps All Around Uber")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Apps All Around Uber")`,
      };
    case "Apps All Around DoorDash Delivery Expense":
      return {
        id: "aaaDdDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Apps All Around DoorDash")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Apps All Around DoorDash")`,
      };
    case "Apps All Around GrubHub Delivery Expense":
      return {
        id: "aaaGhDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Apps All Around GrubHub")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Apps All Around GrubHub")`,
      };
    // Krispy Rice
    case "Krispy Rice Grub Hub":
      return {
        id: "krGhDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Krispy Rice GrubHub")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Krispy Rice GrubHub")`,
      };
    case "Krispy Rice Uber":
      return {
        id: "krUeDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Krispy Rice Uber")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Krispy Rice Uber")`,
      };
    case "Krispy Rice DoorDash":
      return {
        id: "krDdDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Krispy Rice DoorDash")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Krispy Rice DoorDash")`,
      };
    // Olo Dispatch
    case "Olo Dispatch":
      return {
        id: "oloDisDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Olo Dispatch")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Olo Dispatch")`,
      };
    // Kumi
    case "Kumi GrubHub Delivery Expense":
      return {
        id: "kumiGhDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Kumi GrubHub")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Kumi GrubHub")`,
      };
    case "Kumi Uber Delivery Expense":
      return {
        id: "kumiUeDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Kumi Uber")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Kumi Uber")`,
      };
    case "Kumi DoorDash Delivery Expense":
      return {
        id: "kumiDdDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Kumi DoorDash")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Kumi DoorDash")`,
      };
    // TGIF Catering
    case "TGIF Catering DoorDash Delivery Expense":
      return {
        id: "caterDdDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"TGIF Catering DoorDash")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"TGIF Catering DoorDash")`,
      };
    // Stonie Bowls
    case "Stonie Bowls Uber Delivery Expense":
      return {
        id: "stonieUeDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Stonie Bowls Uber")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Stonie Bowls Uber")`,
      };
    case "Stonie Bowls DoorDash Delivery Expense":
      return {
        id: "stonieDdDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Stonie Bowls DoorDash")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Stonie Bowls DoorDash")`,
      };
    // Square Deal Pizza
    case "Square Deal Pizza GrubHub Delivery Expense":
      return {
        id: "sqrDealPizzaGhDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Square Deal Pizza GrubHub")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Square Deal Pizza GrubHub")`,
      };
    case "Square Deal Pizza Uber Delivery Expense":
      return {
        id: "sqrDealPizzaUeDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Square Deal Pizza Uber")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Square Deal Pizza Uber")`,
      };
    case "Square Deal Pizza DoorDash Delivery Expense":
      return {
        id: "sqrDealPizzaDdDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Square Deal Pizza DoorDash")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Square Deal Pizza DoorDash")`,
      };
    // Wow Bao
    case "Wow Bao GrubHub Delivery Expense":
      return {
        id: "waoBaoGhDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Wow Bao GrubHub")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Wow Bao GrubHub")`,
      };
    case "Wow Bao Uber Delivery Expense":
      return {
        id: "waoBaoUeDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Wow Bao Uber")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Wow Bao Uber")`,
      };
    case "Wow Bao DoorDash Delivery Expense":
      return {
        id: "waoBaoDdDelExp",
        formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Wow Bao DoorDash")+SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},"Wow Bao DoorDash")`,
      };
  }
}
function compileVarianceJournalEntrySheet(
  dsVarSheet: Worksheet,
  dataUtility: ReconciliationReportDatUtility
) {
  const weekEndingDate = moment(
    dataUtility.existingReport.endDate.toDate()
  ).format("M/D/YYYY");
  const headerRow = dsVarSheet.addRow([
    "Week Ending Date",
    "Store ID",
    "Partner/Concept",
    "Sales Variance - Error Charges",
    "Sales Variance - Potential Pricing Issues",
    "Sales Variance - Transaction Missing In Source",
    "Sales Variance - Transaction in POS Not Paid Out",
    "Sales Variance - Other",
    "Tax Variance",
    "Delivery Fee Variance",
    "Promo Fees Total",
    "Dispatch Fee Variance",
    "Dispatch Tip Variance",
  ]);
  let rowCount = 2;
  dataUtility.locations.forEach((location) => {
    dataUtility.thirdParties.forEach((dsp) => {
      dsVarSheet.addRow([
        weekEndingDate,
        +location.locationId,
        dsp.name,
        {
          id: "saleVarErrChrg",
          formula: `SUMIFS('Variance Analysis'!$F:$F,'Variance Analysis'!$B:$B,$C${rowCount},'Variance Analysis'!$A:$A,$B${rowCount})`,
        },
        {
          id: "saleVarPricing",
          formula: `SUMIFS('Variance Analysis'!$E:$E,'Variance Analysis'!$B:$B,$C${rowCount},'Variance Analysis'!$A:$A,$B${rowCount})`,
        },
        {
          id: "saleVarTransMissing",
          formula: `SUMIFS('Variance Analysis'!$H:$H,'Variance Analysis'!$B:$B,$C${rowCount},'Variance Analysis'!$A:$A,$B${rowCount})`,
        },
        {
          id: "saleVarNotPaid",
          formula: `SUMIFS('Variance Analysis'!$I:$I,'Variance Analysis'!$B:$B,$C${rowCount},'Variance Analysis'!$A:$A,$B${rowCount})`,
        },
        {
          id: "saleVarOther",
          formula: `SUMIFS('Variance Analysis'!$J:$J,'Variance Analysis'!$B:$B,$C${rowCount},'Variance Analysis'!$A:$A,$B${rowCount})+SUMIFS('Variance Analysis'!$G:$G,'Variance Analysis'!$B:$B,$C${rowCount},'Variance Analysis'!$A:$A,$B${rowCount})`,
        },
        {
          id: "taxVar",
          formula: `SUMIFS('DS Report'!$${recColumns["Tax Variance"]}:$${recColumns["Tax Variance"]},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},$C${rowCount},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount})-SUMIFS('DS Report'!$${recColumns["Market Facilitator Tax"]}:$${recColumns["Market Facilitator Tax"]},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},$C${rowCount},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount})`,
        },
        "", // delivery fee variance
        {
          id: "promoFeeTotal",
          formula: `-SUMIFS('DS Report'!$${recColumns["Promo Fees"]}:$${recColumns["Promo Fees"]},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},$C${rowCount},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$B${rowCount})`,
        },
        "", // dispatch fee var
        "", // dispatch tip var
      ]);
      rowCount++;
    });
  });
}
