import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-new-data-upload-dialog",
  templateUrl: "./new-data-upload-dialog.component.html",
  styleUrls: ["./new-data-upload-dialog.component.scss"],
})
export class NewDataUploadDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<NewDataUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}
}
