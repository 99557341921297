import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FirebaseAuthService } from "../../../../auth/services/firebase-auth.service";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
  Client,
  ClientModule,
  DeliverSenseModule,
  User,
  usStates,
} from "@deliver-sense-librarian/data-schema";
import { FirestoreUtilities } from "../../../../utilities/firestore-utilities";
import { Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { Subject, combineLatest } from "rxjs";
import * as moment from "moment";
import { ConfirmDialogComponent } from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import { AngularFireStorage } from "@angular/fire/storage";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatCheckboxChange } from "@angular/material/checkbox";

@Component({
  selector: "app-create-client",
  templateUrl: "./client.component.html",
  styleUrls: ["./client.component.scss"],
})
export class ClientComponent implements OnInit, OnDestroy {
  @Input() client: Client = new Client();
  @Output() formSavedSuccess = new EventEmitter();
  clientForm: FormGroup;
  physicalSameAsShipping = new FormControl();
  states = usStates;
  storagePath: string;
  billingEmailInEdit = false;
  clientBillingEmailFormControl = new FormControl("", [
    Validators.required,
    Validators.email,
  ]);
  resttingClientLogo = false;
  public user: User;
  private destroy$ = new Subject();
  private clientModules: ClientModule[] = [];
  public availableModules: DeliverSenseModule[] = [];

  constructor(
    private auth: FirebaseAuthService,
    private store: Store<any>,
    private activedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private storage: AngularFireStorage,
    private afs: AngularFirestore
  ) {}

  ngOnInit() {
    combineLatest([
      this.store.select((store) => store.uiState.client),
      this.store.select((store) => store.uiState.authUser),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([client, authUser]) => {
        if (authUser && client) {
          this.user = authUser;
          this.client = client;
          this.getClientModules();
          this.getAvailableModules();
          this.setupClientForm();
          this.listenForClientChanges();
          this.storagePath = `clients/${this.client.id}/logo/`;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private listenForClientChanges() {
    this.afs
      .doc(`clients/${this.client.id}`)
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((client$) => {
        this.client = FirestoreUtilities.objectToType(client$);
        if (this.client.logoPath) {
          this.storage
            .ref(this.client.logoPath)
            .getDownloadURL()
            .subscribe((url) => {
              this.client.logo = url;
            });
        }
      });
  }

  private setupClientForm() {
    this.clientForm = this.fb.group({
      name: new FormControl(
        this.client.name ? this.client.name : "",
        Validators.required
      ),
      addressLine1: new FormControl(
        this.client.addressLine1 ? this.client.addressLine1 : "",
        Validators.required
      ),
      addressLine2: new FormControl(
        this.client.addressLine2 ? this.client.addressLine2 : ""
      ),
      addressCity: new FormControl(
        this.client.addressCity ? this.client.addressCity : "",
        Validators.required
      ),
      addressState: new FormControl(
        this.client.addressState ? this.client.addressState : "",
        Validators.required
      ),
      addressPostalCode: new FormControl(
        this.client.addressPostalCode ? this.client.addressPostalCode : "",
        Validators.required
      ),
      clientRecDeliveryMessageTemplate: new FormControl(
        this.client.clientRecDeliveryMessageTemplate
          ? this.client.clientRecDeliveryMessageTemplate
          : ""
      ),
      clientInvoiceRecipients: new FormArray([]),
      clientRecRecipients: new FormArray([]),
      locationIdString: new FormControl(this.client.locationIdString),
    });
    this.setupEmailArrays();

    this.physicalSameAsShipping.valueChanges.subscribe(
      (physicalSameAsShipping$) => {
        this.clientForm
          .get("billingAddressLine1")
          .setValidators(physicalSameAsShipping$ ? null : Validators.required);
        this.clientForm.get("billingAddressLine1").updateValueAndValidity();
        this.clientForm
          .get("billingAddressCity")
          .setValidators(physicalSameAsShipping$ ? null : Validators.required);
        this.clientForm.get("billingAddressCity").updateValueAndValidity();
        this.clientForm
          .get("billingAddressState")
          .setValidators(physicalSameAsShipping$ ? null : Validators.required);
        this.clientForm.get("billingAddressState").updateValueAndValidity();
        this.clientForm
          .get("billingAddressPostalCode")
          .setValidators(physicalSameAsShipping$ ? null : Validators.required);
        this.clientForm
          .get("billingAddressPostalCode")
          .updateValueAndValidity();
        this.clientForm.updateValueAndValidity();
      }
    );
  }
  private setupEmailArrays() {
    if (this.client.clientInvoiceRecipients) {
      const invoicingEmailsArray = this.clientForm.get(
        "clientInvoiceRecipients"
      ) as FormArray;
      this.client.clientInvoiceRecipients.forEach((emailAddress) => {
        invoicingEmailsArray.push(
          new FormGroup({
            email: new FormControl(emailAddress, [
              Validators.required,
              Validators.email,
            ]),
          })
        );
      });
    }
    if (this.client.clientRecRecipients) {
      const recEmailsArray = this.clientForm.get(
        "clientRecRecipients"
      ) as FormArray;
      this.client.clientRecRecipients.forEach((emailAddress) => {
        recEmailsArray.push(
          new FormGroup({
            email: new FormControl(emailAddress, [
              Validators.required,
              Validators.email,
            ]),
          })
        );
      });
    }
  }
  private removeInvoiceEmail(index) {
    const invoicingEmailsArray = this.clientForm.get(
      "clientInvoiceRecipients"
    ) as FormArray;
    invoicingEmailsArray.removeAt(index);
  }
  private addInvoiceEmail() {
    const invoicingEmailsArray = this.clientForm.get(
      "clientInvoiceRecipients"
    ) as FormArray;
    invoicingEmailsArray.push(
      new FormGroup({
        email: new FormControl("", [Validators.required, Validators.email]),
      })
    );
  }
  private removeRecEmail(index) {
    const recEmailsArray = this.clientForm.get(
      "clientRecRecipients"
    ) as FormArray;
    recEmailsArray.removeAt(index);
  }
  private addRecEmail() {
    const recEmailsArray = this.clientForm.get(
      "clientRecRecipients"
    ) as FormArray;
    recEmailsArray.push(
      new FormGroup({
        email: new FormControl("", [Validators.required, Validators.email]),
      })
    );
  }
  public save() {
    this.clientForm.updateValueAndValidity();
    if (this.clientForm.valid) {
      const _client = this.mapFormToClient();
      this.afs
        .doc(`clients/${this.client.id}`)
        .update(_client.toJSONObject())
        .then(() => {
          this.snackBar.open("Successfully updated the client", "Dismiss", {
            duration: 5000,
          });
          this.formSavedSuccess.emit(true);
        });
    } else {
      this.snackBar.open("Please fill out all required fields.", "Dismiss", {
        duration: 5000,
      });
    }
  }

  private mapFormToClient(): Client {
    const client = new Client();
    const form = this.clientForm.value;
    client.name = form.name;
    client.primaryContact = this.user.id;
    client.addressLine1 = form.addressLine1;
    client.addressLine2 = form.addressLine2;
    client.addressCity = form.addressCity;
    client.addressState = form.addressState;
    client.addressPostalCode = form.addressPostalCode;
    client.billingAddressLine1 = form.billingAddressLine1;
    client.billingAddressLine2 = form.billingAddressLine2;
    client.billingAddressCity = form.billingAddressCity;
    client.billingAddressState = form.billingAddressState;
    client.billingAddressPostalCode = form.billingAddressPostalCode;
    client.locationIdString = form.locationIdString;
    client.clientRecDeliveryMessageTemplate =
      form.clientRecDeliveryMessageTemplate
        ? form.clientRecDeliveryMessageTemplate
        : "";
    client.clientInvoiceRecipients = form.clientInvoiceRecipients.map(
      (control) => control.email
    );
    client.clientRecRecipients = form.clientRecRecipients.map(
      (control) => control.email
    );

    return client;
  }

  private getClientModules() {
    this.afs
      .collection("clientModules", (ref) =>
        ref.where("client", "==", this.client.id)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((clientModules$) => {
        this.clientModules = FirestoreUtilities.mapToType(clientModules$);
      });
  }

  private getAvailableModules() {
    this.afs
      .collection("deliverSenseModules")
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((dsModules$) => {
        this.availableModules = FirestoreUtilities.mapToType(dsModules$);
      });
  }

  isClientModuleActive(dsModule: DeliverSenseModule) {
    const clientMatchingModule = this.clientModules.find(
      (cm) => cm.module === dsModule.id
    );
    return clientMatchingModule ? clientMatchingModule.active : false;
  }

  nextBillDate() {
    return moment().startOf("month").add(1, "month").format("LL");
  }

  async logoUploaded($event) {
    if ($event.filePath) {
      if (this.client.logoPath) {
        try {
          await FirestoreUtilities.deleteStorageFile(
            this.client.logoPath,
            this.storage
          );
        } catch (e) {
          this.snackBar.open(
            "Oops... something went wrong. Please refresh the page and try again.",
            "Dismiss",
            {
              duration: 5000,
            }
          );
        }
      }
      await this.afs.doc(`clients/${this.client.id}`).update({
        logoPath: $event.filePath,
      });
      this.snackBar.open("Logo updated successfully!", "Dismiss", {
        duration: 5000,
      });
    }
  }

  getCurrentMonth() {
    return `${moment()
      .startOf("month")
      .format("M/D/YY")} through ${moment().format("M/D/YY")}`;
  }

  removeUri(uri: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Cancel",
        message: "Are you sure you want to discard your changes?",
        action: "Yes, cancel.",
      },
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        // @ts-ignore
        const index = this.client.redirectUris.indexOf(uri);
        const reducedRedirectUris = this.client.redirectUris.splice(index, 1);
        this.afs.doc(`clients/${this.client.id}`).update({
          redirectUris: reducedRedirectUris,
        });
      }
    });
  }

  async saveBillingEmailChange() {
    if (this.clientBillingEmailFormControl.valid) {
      await this.afs.doc(`clients/${this.client.id}`).update({
        billingEmail: this.clientBillingEmailFormControl.value,
      });
      this.snackBar.open("Billing email updated successfully!", "Dismiss", {
        duration: 5000,
      });
      this.cancelBillingEdit();
    } else {
      this.snackBar.open("Please enter a valid email.", "Dismiss", {
        duration: 5000,
      });
    }
  }
  cancelBillingEdit() {
    this.billingEmailInEdit = false;
    this.clientBillingEmailFormControl.reset();
    this.clientBillingEmailFormControl.patchValue(this.client.billingEmail);
    this.clientBillingEmailFormControl.updateValueAndValidity();
  }
}
