import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Papa } from "ngx-papaparse";
import { ThirdPartyReportFragmentTaxRateReport } from "@deliver-sense-librarian/data-schema";
import { AngularFirestore } from "@angular/fire/firestore";
import firebase from "firebase/app";
import { first } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { TransactionsDialogComponent } from "app/dialogs/transactions-dialog/transactions-dialog.component";
import * as _ from "lodash";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { ReconciliationReportDatUtility } from "../../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../../utilities/reconciliation-report-filtering.utility";
import { FormControl } from "@angular/forms";
import { AfterViewInit } from "@angular/core";

@Component({
  selector: "app-tax-rate-analysis-table",
  templateUrl: "./tax-rate-analysis-table.component.html",
  styleUrls: ["./tax-rate-analysis-table.component.scss"],
})
export class TaxRateAnalysisTableComponent implements OnInit, AfterViewInit {
  @Input() data: ThirdPartyReportFragmentTaxRateReport[];
  @Input() reportTitle: string;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @Input() dataUtility: ReconciliationReportDatUtility;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    "location",
    "thirdParty",
    "taxRate",
    "transactions",
    "netSales",
    "netTax",
  ];
  public filter3pdsControl = new FormControl([]);
  public filterLocationsControl = new FormControl([]);
  constructor(
    private cdr: ChangeDetectorRef,
    private afs: AngularFirestore,
    private papa: Papa,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private loadingService: LoadingDialogService
  ) {}
  ngAfterViewInit() {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.filteringUtility.set3pdAndLocationFilterPredicate(
      this.tableData,
      this.filter3pdsControl,
      this.filterLocationsControl
    );
    this.cdr.detectChanges();
  }
  ngOnInit(): void {
    this.tableData = new MatTableDataSource(this.data);
  }

  public async openTransactionViewer(
    report: ThirdPartyReportFragmentTaxRateReport
  ) {
    const transactionIds = report.transactions as string[];
    if (transactionIds.length > 0) {
      this.loadingService.isLoading(true, "Finding transactions...");
      const transactionIdBatches = _.chunk(transactionIds, 10);
      try {
        const transactions = _.flatten(
          await Promise.all(
            transactionIdBatches.map((transactionIds) => {
              return this.afs
                .collection("thirdPartyTransactions", (ref) =>
                  ref.where(
                    firebase.firestore.FieldPath.documentId(),
                    "in",
                    transactionIds
                  )
                )
                .snapshotChanges()
                .pipe(first())
                .toPromise();
            })
          )
        );
        this.loadingService.isLoading(false);
        this.dialog.open(TransactionsDialogComponent, {
          panelClass: "invisible-panel-dialog",
          data: {
            transactions: FirestoreUtilities.mapToType(transactions),
          },
        });
      } catch (e) {
        this.loadingService.isLoading(false);
        this.snackBar.open(
          `Could not find transactions. Please refresh and try again`,
          "Dismiss",
          { duration: 5000 }
        );
      }
    }
  }
}
