import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import firebase from "firebase/app";
import { SelectionModel } from "@angular/cdk/collections";

@Component({
  selector: "app-upload-errors-table",
  templateUrl: "./upload-errors-table.component.html",
  styleUrls: ["./upload-errors-table.component.scss"],
})
export class UploadErrorsTableComponent implements OnInit {
  @Input() errorType: string;
  @Input() errorData: any[] = [];
  @Input() rowsToRemove: number[] = [];
  @Input() duplicatesToOverride: number[] = [];
  @Output() removeRow = new EventEmitter();
  @Output() undoRemoveRow = new EventEmitter();
  @Output() overrideDuplicate = new EventEmitter();
  @Output() undoOverrideDuplicate = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public keys: string[] = [];
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [];
  public selection: SelectionModel<any[]>;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.displayedColumns = [/**'select',**/ ...Object.keys(this.errorData[0])];
    // Batch Row always first.
    //If table is for Missing Required Data make "Missing Data" column after batchRow
    this.displayedColumns.sort((a, b) => {
      return a === "Missing Data" ? -1 : a === "batchRow" ? -1 : 0;
    });
    this.displayedColumns.unshift("remove");
    if (this.errorType === "duplicates") {
      this.displayedColumns.unshift("duplicates");
    }
    this.selection = new SelectionModel<any>(
      true,
      this.errorData.filter((error) => !!error.remove)
    );
    this.tableData = new MatTableDataSource(this.errorData);
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.cdr.detectChanges();
  }
  //DUPLICATES
  markDuplicateToOverride(batchRow: number) {
    this.overrideDuplicate.emit(batchRow);
  }
  addBackDuplicate(batchRow: number) {
    this.undoOverrideDuplicate.emit(batchRow);
  }
  isRowMarkedForOverride(batchRow) {
    return this.duplicatesToOverride.indexOf(batchRow) !== -1;
  }
  //REMOVALS
  markRowToDelete(batchRow: number) {
    this.removeRow.emit(batchRow);
  }
  addBackRow(batchRow: number) {
    this.undoRemoveRow.emit(batchRow);
  }
  isRowMarkedForRemoval(batchRow) {
    return this.rowsToRemove.indexOf(batchRow) !== -1;
  }
  isDateField(data) {
    return data instanceof firebase.firestore.Timestamp;
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableData.data.length;
    return numSelected === numRows;
  }
  masterErrorsToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.tableData.data.forEach((row) => {
          this.selection.select(row);
        });
  }
}
