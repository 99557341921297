import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  Client3pdTransactionStatusConfiguration,
  ThirdParty,
  ThirdPartyTransactionStatus,
  Client,
} from "@deliver-sense-librarian/data-schema";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-third-party-status-configurations",
  templateUrl: "./third-party-status-configurations.component.html",
  styleUrls: ["./third-party-status-configurations.component.scss"],
})
export class ThirdPartyStatusConfigurationsComponent implements OnInit {
  @Input()
  client3pdTransactionStatusConfigurations: Client3pdTransactionStatusConfiguration[] =
    [];
  @Input() clientThirdParties: ThirdParty[] = [];
  @Input() thirdPartyTransactionStatuses: ThirdPartyTransactionStatus[] = [];
  @Input() client: Client;
  filteredStatuses: ThirdPartyTransactionStatus[];
  public selectThirdParty = new FormControl();
  public selectedThirdPartyId: any;
  constructor(private cdr: ChangeDetectorRef, private afs: AngularFirestore) {}

  ngOnInit(): void {
    this.clientThirdParties = this.clientThirdParties.filter(
      (dsp) => !dsp.duplicateOf
    );
    this.selectThirdParty.valueChanges.subscribe((selectedThirdPartyId$) => {
      if (selectedThirdPartyId$ !== this.selectedThirdPartyId) {
        this.selectedThirdPartyId = selectedThirdPartyId$;
        this.filteredStatuses = null;
        setTimeout(() => {
          this.filteredStatuses = this.thirdPartyTransactionStatuses.filter(
            (thirdPartyTransactionStatus) => {
              return (
                thirdPartyTransactionStatus.thirdParty ===
                this.selectedThirdPartyId
              );
            }
          );
        });
      }
    });
  }
}
