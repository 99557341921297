import { AdminLayoutComponent } from "./views/admin-layout/admin-layout.component";
import { Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { UnauthenticatedGuard } from "./guards/unauthenticated.guard";
import { LoginComponent } from "./auth/login/login.component";
import { PasswordresetComponent } from "./auth/passwordreset/passwordreset.component";
import { SignupComponent } from "./auth/signup/signup.component";
import { DashboardComponent } from "./components/dashboard/dashboard/dashboard.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { ReportsComponent } from "./components/ds-modules/3pd-reconciliation/reconciliation-reports/reports.component";
import { EntitiesComponent } from "./components/organization/entities/entities/entities.component";
import { LocationComponent } from "./components/organization/locations/location/location.component";
import { ClientComponent } from "./components/organization/clients/client/client.component";
import { ReconciliationReportComponent } from "./components/ds-modules/3pd-reconciliation/reconciliation-report/reconciliation-report.component";
import { UserProfileComponent } from "./components/organization/user-profile/user-profile.component";
import { TeamMembersComponent } from "./components/organization/team/team-members/team-members.component";
import { ExemptionCertificateManagementComponent } from "./components/ds-modules/exemption-certificate-management/exemption-certificate-management.component";
import { ClientSelectorComponent } from "./auth/client-selector/client-selector.component";
import { ClientSelectedGuard } from "./guards/client-selected.guard";
import { TeamMemberSignupComponent } from "./auth/team-member-signup/team-member-signup.component";
import { EntityComponent } from "./components/organization/entities/entity/entity.component";
import { LocationsComponent } from "./components/organization/locations/locations/locations.component";
import { UploadLocationsComponent } from "./components/organization/locations/upload-locations/upload-locations.component";
import { SsoComponent } from "./auth/sso/sso.component";
import { SfaComponent } from "./auth/sfa/sfa.component";
import { SecondFactorAuthenticationGuard } from "./guards/sfa.guard";
import { NoSfaGuard } from "./guards/no-sfa.guard";
import { ThirdPartyReportSettingsComponent } from "./components/ds-modules/3pd-reconciliation/reconciliation-report-settings/third-party-report-settings.component";
import { TransactionLookupComponent } from "./components/ds-modules/transaction-lookup/transaction-lookup.component";
import { TeamMemberComponent } from "./components/organization/team/team-member/team-member.component";
import { TrendAnalysisReportsComponent } from "./components/ds-modules/trend-analysis/trend-analysis-reports/trend-analysis-reports.component";
import { TrendAnalysisReportComponent } from "./components/ds-modules/trend-analysis/trend-analysis-report/trend-analysis-report.component";
import { DataUploadEventsComponent } from "./components/data-upload/data-upload-events/data-upload-events.component";
import { DataUploadEventComponent } from "./components/data-upload/data-upload-event/data-upload-event.component";
import { NewDataUploadComponent } from "./components/data-upload/new-data-upload(deprecated)/new-data-upload.component";
import { SharingCenterComponent } from "./components/sharing-center/sharing-center.component";

export const ROUTES: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "login",
    component: LoginComponent,
    // canActivate: [UnauthenticatedGuard],
  },
  { path: "invites/:invitationId", component: TeamMemberSignupComponent },
  // { path: 'terms', component: TermsComponent },
  // { path: 'privacy-policy', component: PrivacyPolicyComponent },
  {
    path: "sign-up",
    component: SignupComponent,
    canActivate: [UnauthenticatedGuard],
  },
  {
    path: "forgot",
    component: PasswordresetComponent,
    canActivate: [UnauthenticatedGuard],
  },
  {
    path: "client-selection",
    component: ClientSelectorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sso/:tokenId",
    component: SsoComponent,
    canActivate: [UnauthenticatedGuard],
  },
  {
    path: "sfa/:clientId",
    component: SfaComponent,
    canActivate: [AuthGuard, NoSfaGuard],
  },
  {
    path: "app",
    canActivate: [
      AuthGuard,
      ClientSelectedGuard,
      SecondFactorAuthenticationGuard,
    ],
    component: AdminLayoutComponent,
    children: [
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      { path: "dashboard", component: DashboardComponent },
      {
        path: "organization",
        children: [
          {
            path: "locations",
            children: [
              { path: "", component: LocationsComponent },
              { path: "upload", component: UploadLocationsComponent },
              { path: "new", component: LocationComponent },
              { path: ":id", component: LocationComponent },
            ],
          },
          {
            path: "entities",
            children: [
              { path: "", component: EntitiesComponent },
              { path: "new", component: EntityComponent },
              { path: ":id", component: EntityComponent },
            ],
          },
          {
            path: "team",
            children: [
              { path: "", component: TeamMembersComponent },
              { path: "new", component: TeamMemberComponent },
              { path: ":id", component: TeamMemberComponent },
            ],
          },
        ],
      },
      {
        path: "sharing-center",
        component: SharingCenterComponent,
      },
      {
        path: "data-uploads",
        children: [
          { path: "", component: DataUploadEventsComponent },
          { path: "new", component: NewDataUploadComponent },
          { path: ":id", component: DataUploadEventComponent },
        ],
      },
      {
        path: "client",
        component: ClientComponent,
      },
      { path: "transactions", component: TransactionLookupComponent },

      {
        path: "3pd-reports",
        children: [
          { path: "", redirectTo: "list", pathMatch: "full" },
          { path: "list", component: ReportsComponent },
          { path: "new", component: ReconciliationReportComponent },
          { path: "settings", component: ThirdPartyReportSettingsComponent },
          { path: ":id", component: ReconciliationReportComponent },
        ],
      },
      {
        path: "trend-analysis",
        children: [
          { path: "", redirectTo: "list", pathMatch: "full" },
          { path: "list", component: TrendAnalysisReportsComponent },
          { path: ":id", component: TrendAnalysisReportComponent },
        ],
      },
      {
        path: "exemption-certificates",
        component: ExemptionCertificateManagementComponent,
      },
      {
        path: "profile",
        component: UserProfileComponent,
      },
      {
        path: "**",
        component: NotFoundComponent,
      },
    ],
  },
];
