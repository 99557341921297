import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";

import { MatDialog } from "@angular/material/dialog";
import * as _ from "lodash";
import { Papa } from "ngx-papaparse";
import {
  UserView,
  ThirdPartyReportTransactionFlag,
} from "@deliver-sense-librarian/data-schema";
import { AngularFirestore } from "@angular/fire/firestore";
import { takeUntil, first } from "rxjs/operators";
import { Subject } from "rxjs";
import moment from "moment";
import { downloadDataAsFile } from "app/shared/ds-constant";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import { ReconciliationReportDatUtility } from "../../utilities/reconciliation-report-data.utility";
import { ConfirmDialogComponent } from "app/dialogs/confirm-dialog/confirm-dialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TransactionsDialogComponent } from "app/dialogs/transactions-dialog/transactions-dialog.component";
import { ThirdParty } from "@deliver-sense-librarian/data-schema";
import { FormControl } from "@angular/forms";
@Component({
  selector: "app-transaction-flags",
  templateUrl: "./transaction-flags.component.html",
  styleUrls: ["./transaction-flags.component.scss"],
})
export class TransactionFlagsComponent implements OnInit, OnDestroy {
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() exportUtility: ReconciliationReportExportUtility;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    "location",
    "source",
    "date",
    "sale",
    "note",
    "transactionId",
    "delete",
  ];
  public transactionFlags: ThirdPartyReportTransactionFlag[];
  private destroy$ = new Subject();
  activePopover: any;
  flagToEdit: ThirdPartyReportTransactionFlag;
  flagNoteControl = new FormControl();
  constructor(
    private cdr: ChangeDetectorRef,
    private afs: AngularFirestore,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private papa: Papa
  ) {}

  ngOnInit() {
    this.getTransactionFlags();
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private async getTransactionFlags() {
    this.afs
      .collection("thirdPartyReportTransactionFlags", (ref) =>
        ref
          .where("client", "==", this.dataUtility.client.id)
          .where("thirdPartyReport", "==", this.dataUtility.existingReport.id)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (flagsQuery$) => {
        const transactionFlags = <ThirdPartyReportTransactionFlag[]>(
          FirestoreUtilities.mapToType(flagsQuery$)
        );
        await this.setDataNames(transactionFlags);
        this.transactionFlags = transactionFlags;
        this.setTableData();
      });
  }

  private async setDataNames(
    transactionFlags: ThirdPartyReportTransactionFlag[]
  ) {
    // const creatorQuerys = transactionFlags.map((transactionFlag) => {
    //   return this.afs
    //     .doc(`userViews/${transactionFlag.creator}`)
    //     .snapshotChanges()
    //     .pipe(first())
    //     .toPromise();
    // });
    // const creatorUserViews = <UserView[]>(
    //   FirestoreUtilities.mergeToType(await Promise.all(creatorQuerys))
    // );
    // transactionFlags.forEach((transactionFlag) => {
    //   const creatorUserView = creatorUserViews.find(
    //     (userView) => userView.id === transactionFlag.creator
    //   );
    //   if (creatorUserView) {
    //     transactionFlag["creatorEmail"] = creatorUserView.email;
    //   }
    // });
    transactionFlags.forEach((transactionFlag) => {
      if (!transactionFlag.transaction.pos) {
        transactionFlag["thirdPartyName"] = this.dataUtility.getThirdPartyName(
          transactionFlag.transaction.thirdParty
        );
      }
    });
  }

  setTableData() {
    this.tableData = new MatTableDataSource(this.transactionFlags);
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.cdr.detectChanges();
  }

  /**
   * ACTIONS
   */

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  exportReport() {
    const data = this.transactionFlags.map((flag) => {
      return {
        Locations: flag.transaction.location,
        Source: flag["thirdPartyName"] ? flag["thirdPartyName"] : "POS",
        date: moment(flag.transaction.date.toDate()).format("l"),
        sale: flag.transaction.sale,
        transactionId: flag.transaction.transactionId,
        note: flag.note,
      };
    });
    const results = this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const fileName = `${this.dataUtility.existingReport.name}-Transaction-Flags`;
    downloadDataAsFile(results, fileName, "csv");
  }
  openTransactionViewer(transaction) {
    this.dialog.open(TransactionsDialogComponent, {
      panelClass: "invisible-panel-dialog",
      data: {
        transactions: [transaction],
      },
    });
  }

  setNoteToEdit(
    transactionFlag: ThirdPartyReportTransactionFlag,
    activePopover
  ) {
    this.flagToEdit = transactionFlag;
    this.activePopover = activePopover;
    this.flagNoteControl.patchValue(this.flagToEdit.note);
    this.flagNoteControl.updateValueAndValidity();
  }
  async saveNote() {
    if (this.flagNoteControl.valid) {
      await this.afs
        .doc(`thirdPartyReportTransactionFlags/${this.flagToEdit.id}`)
        .update({
          dateUpdated: moment().toDate(),
          note: this.flagNoteControl.value ? this.flagNoteControl.value : "",
        });
      this.snackBar.open("Transaction flag note has been updated.", "Dismiss", {
        duration: 5000,
      });
      this.activePopover.hide();
      this.activePopover = null;
      this.flagToEdit = null;
      this.flagNoteControl.reset();
      this.flagNoteControl.updateValueAndValidity();
    }
  }
  async deleteFlag(transactionFlag: ThirdPartyReportTransactionFlag) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Delete Flag",
        message: `Are you sure you want delete this transaction flag?`,
        action: "Yes, Delete.",
      },
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs
          .doc(`thirdPartyReportTransactionFlags/${transactionFlag.id}`)
          .delete();
        this.snackBar.open("Flag deleted successfully!", "Dismiss", {
          duration: 5000,
        });
      }
    });
  }
}
