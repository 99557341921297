import { AngularFirestore } from "@angular/fire/firestore";
import { Injectable } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { tap, finalize, takeUntil, catchError } from "rxjs/operators";
import { LoadingDialogService } from "./loading-dialog.service";
import { Subject } from "rxjs";
import { reject } from "lodash";
export class UploadResult {
  filePath: string;
  fileSize: number;
  fileName: string;
  downloadUrl?: string;
}
@Injectable({
  providedIn: "root",
})
export class UploadDocumentService {
  constructor(
    private storage: AngularFireStorage,
    private afs: AngularFirestore
  ) {}

  uploadSingle(
    file,
    path,
    progress?: { progress: number },
    destroy$?: Subject<any>
  ): Promise<UploadResult> {
    return new Promise((resolve, reject) => {
      const ref = this.storage.ref(`${path}`);
      const task = this.storage.upload(path, file);
      const uploadResult: UploadResult = new UploadResult();
      const snapshot = task
        .snapshotChanges()
        .pipe(
          tap((info) => {
            if (progress) {
              console.log(info);
              const percentComplete = info.bytesTransferred / info.totalBytes;
              progress.progress = percentComplete * 100;
            }
          }),
          takeUntil(destroy$),
          // The file's download URL
          finalize(async () => {
            const downloadUrl = await ref.getDownloadURL().toPromise();
            uploadResult.downloadUrl = downloadUrl;
            resolve(uploadResult);
          })
        )
        .subscribe(
          (result) => {
            if (result["bytesTransferred"] === result["totalBytes"]) {
              uploadResult.filePath = result.ref.fullPath;
              uploadResult.fileSize = result.totalBytes;
              // this.loadingService.isLoading(false);
            }
          },
          (e) => {
            reject(e);
          },
          () => {
            // this.loadingService.isLoading(false);
          }
        );
    });
  }
}
