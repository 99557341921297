import { Component, OnInit, Inject } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import * as moment from "moment";
import { UploadDocumentService } from "../../services/upload-document.service";

@Component({
  selector: "app-upload-document-dialog",
  templateUrl: "./upload-document-dialog.component.html",
  styleUrls: ["./upload-document-dialog.component.scss"],
})
export class UploadDocumentDialogComponent implements OnInit {
  constructor(
    private afs: AngularFirestore,
    private dialog: MatDialog,
    private uploadDocumentService: UploadDocumentService,
    public dialogRef: MatDialogRef<UploadDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { storagePath: string }
  ) {}

  ngOnInit() {}
  closeDialog(fileStorageData?) {
    this.dialogRef.close(fileStorageData);
  }
}
