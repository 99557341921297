import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { TransactionAITypes } from "@deliver-sense-librarian/data-schema";
import { take, takeUntil } from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { ReplaySubject, Subject } from "rxjs";
import { MatSelect } from "@angular/material/select";
import * as _ from "lodash";
import { AngularFirestore } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-variance-reason-search",
  templateUrl: "./variance-reason-search.component.html",
  styleUrls: ["./variance-reason-search.component.scss"],
})
export class VarianceReasonSearchComponent implements OnInit {
  @Input() multiple = true;
  @Input() selectedTypes: FormControl;
  @ViewChild("multiSelect", { static: true }) multiSelect: MatSelect;
  public typeFilterCtrl: FormControl = new FormControl();
  public filteredTypes: ReplaySubject<string[]> = new ReplaySubject<string[]>(
    1
  );
  private destroy$ = new Subject();
  varianceReasonTypes = [
    "Adjustment",
    "Transaction Missing in Source",
    "Transaction In POS Not Paid Out",
  ];
  constructor(private afs: AngularFirestore, private store: Store<any>) {}

  ngOnInit() {
    this.initializeComponent();
  }

  async initializeComponent() {
    this.filteredTypes.next(this.varianceReasonTypes.slice());
    this.typeFilterCtrl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterTypes();
      });
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredTypes
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe((val) => {
        if (selectAllValue) {
          this.selectedTypes.patchValue(val.map((type) => type));
          this.selectedTypes.updateValueAndValidity();
        } else {
          this.selectedTypes.patchValue([]);
          this.selectedTypes.updateValueAndValidity();
        }
      });
  }

  filterTypes() {
    if (!this.varianceReasonTypes) {
      return;
    }
    let search = this.typeFilterCtrl.value;
    if (!search) {
      this.filteredTypes.next(this.varianceReasonTypes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredTypes.next(
      this.varianceReasonTypes.filter((type) => {
        return type.toLowerCase().indexOf(search) > -1;
      })
    );
  }
}
