import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as moment from "moment";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ReconciliationReportDatUtility } from "../../../utilities/reconciliation-report-data.utility";
import { ThirdPartyDeliveryAnalyticsEngine } from "../../../third-party-delivery-analytics-engine";
import { ThirdParty, Location } from "@deliver-sense-librarian/data-schema";

@Component({
  selector: "app-transaction-match",
  templateUrl: "./transaction-match.component.html",
  styleUrls: ["./transaction-match.component.scss"],
})
export class TransactionMatchComponent implements OnInit, OnDestroy {
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() location: Location;
  @Input() thirdParty: ThirdParty;
  @Output() reset = new EventEmitter();

  public analyticsEngine = new ThirdPartyDeliveryAnalyticsEngine();
  public parametersForm: FormGroup;
  public transactionMatches;
  private allTransactions: { posTransactions: any[]; tpdTransactions: any[] };
  private destroy$ = new Subject();
  constructor(
    private afs: AngularFirestore,
    private store: Store<any>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private loadingService: LoadingDialogService
  ) {}

  ngOnInit() {
    this.runAnalysis();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  async runAnalysis() {
    this.loadingService.isLoading(true, "Matching transactions...");
    const start = moment(
      this.startDate
        ? this.startDate
        : this.dataUtility.existingReport.startDate.toDate()
    )
      .startOf("day")
      .toDate();
    const end = moment(
      this.endDate
        ? this.endDate
        : this.dataUtility.existingReport.endDate.toDate()
    )
      .endOf("day")
      .toDate();
    const acceptableVariance = 1;

    this.analyticsEngine
      .fetchParameterizedReportData(
        this.location.locationId,
        this.thirdParty,
        start,
        end,
        this.dataUtility.client,
        this.afs
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((result$) => {
        this.loadingService.isLoading(false);
        this.allTransactions = result$;
        const matchingAnalysis: { matches; distances } =
          this.analyticsEngine.createDistanceMatrix(this.allTransactions);
        this.transactionMatches = this.flattenTransactionMatches(
          matchingAnalysis
        ).sort((a, b) => {
          return +a["Match%"] > +b["Match%"] ? -1 : 1;
        });
      });
  }

  private flattenTransactionMatches(matchingAnalysis: { matches; distances }) {
    return matchingAnalysis.matches
      .map((match) => {
        const posTransaction = this.getPosTransaction(match.posTransaction);
        const thirdPartyTransaction = this.getThirdPartyTransaction(
          match.thirdPartyTransaction
        );
        return {
          posTransaction: posTransaction ? posTransaction : null,
          "Match %": `${+this.analyticsEngine.normalizeDistance(
            match.match,
            matchingAnalysis.distances
          )}`,
          thirdPartyTransaction: thirdPartyTransaction
            ? thirdPartyTransaction
            : null,
        };
      })
      .sort((a, b) => {
        return a["Match %"] < b["Match %"] ? 1 : -1;
      });
  }

  getSelectedThirdParty() {
    const thirdPartyId = this.parametersForm.value.selectedThirdParty;
    const thirdParty = this.dataUtility.thirdParties.find(
      (_tp) => _tp.id === thirdPartyId
    );
    return thirdParty;
  }
  getSelectedLocation() {
    const locationId = this.parametersForm.value.selectedLocation;
    const location = this.dataUtility.locations.find(
      (_location) => _location.locationId === locationId
    );
    return location;
  }
  getPosTransaction(posTransactionId: string) {
    return this.allTransactions.posTransactions.find(
      (t) => t.id === posTransactionId
    );
  }

  getThirdPartyTransaction(thirdPartyTransactionId: string) {
    return this.allTransactions.tpdTransactions.find(
      (t) => t.id === thirdPartyTransactionId
    );
  }
}
