import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import {
  ThirdPartyReportNote,
  ThirdParty,
  User,
  Client,
  Location,
} from "@deliver-sense-librarian/data-schema";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as moment from "moment";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "app/dialogs/confirm-dialog/confirm-dialog.component";
import _ from "lodash";

@Component({
  selector: "app-client-persistant-reconciliation-note",
  templateUrl: "./client-persistant-reconciliation-note.component.html",
  styleUrls: ["./client-persistant-reconciliation-note.component.scss"],
})
export class ClientPersistantReconciliationNoteComponent implements OnInit {
  @Input() thirdPartyReportNote: ThirdPartyReportNote;
  @Input() locations: Location[] = [];
  @Input() thirdParties: ThirdParty[] = [];
  @Input() creator: User;
  @Input() client: Client;
  @Output() complete = new EventEmitter();
  reportNoteForm: FormGroup;

  constructor(
    private afs: AngularFirestore,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.setupReportNoteForm();
  }

  private setupReportNoteForm() {
    this.reportNoteForm = this.fb.group({
      locations: new FormControl(
        this.thirdPartyReportNote.locations
          ? this.thirdPartyReportNote.locations
          : [],
        Validators.minLength(1)
      ),
      thirdParties: new FormControl(
        this.thirdPartyReportNote.thirdParties
          ? this.thirdPartyReportNote.thirdParties
          : [],
        Validators.minLength(1)
      ),

      message: new FormControl(
        this.thirdPartyReportNote.message
          ? this.thirdPartyReportNote.message
          : "",
        Validators.required
      ),
    });
  }
  async save() {
    if (this.reportNoteForm.valid) {
      const formValues = this.reportNoteForm.value;
      if (this.thirdPartyReportNote.id) {
        await this.updateNote(formValues);
      } else {
        this.createNote(formValues);
      }
      this.complete.emit(true);
    } else {
      this.snackbar.open("Please fill out all required fields", "Dismiss", {
        duration: 5000,
      });
    }
  }
  async updateNote(formValues) {
    await this.afs
      .doc(`thirdPartyReportNotes/${this.thirdPartyReportNote.id}`)
      .update({
        locations: formValues.locations,
        thirdParties: formValues.thirdParties,
        dates: formValues.dates ? formValues.dates : null,
        message: formValues.message,
        dateUpdated: moment().toDate(),
      });
    this.snackbar.open(
      "Successfully updated reconciliation report note!",
      "Dismiss",
      {
        duration: 5000,
      }
    );
  }
  async createNote(formValues) {
    const locations = this.locations.filter((location) =>
      formValues.locations.find(
        (locationId) => locationId === location.locationId
      )
    );
    const entityLocations = _.groupBy(locations, "entity");
    await Promise.all(
      Object.keys(entityLocations).map((entityId) => {
        const newNote = new ThirdPartyReportNote();
        newNote.creator = this.creator.id;
        newNote.client = this.client.id;
        newNote.locations = entityLocations[entityId].map(
          (location) => location.locationId
        );
        newNote.thirdParties = formValues.thirdParties;
        newNote.dates = formValues.dates;
        newNote.message = formValues.message;
        newNote.isPersistant = true;
        return this.afs
          .collection("thirdPartyReportNotes")
          .add(newNote.toJSONObject());
      })
    );
    this.snackbar.open(
      "Persistant report note created successfully!",
      "Dismiss",
      {
        duration: 5000,
      }
    );
  }
  async deleteNote() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Delete Reconciliation Note",
        message: `Are you sure you want delete this note?`,
        action: "Yes, Delete.",
      },
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs
          .doc(`thirdPartyReportNotes/${this.thirdPartyReportNote.id}`)
          .delete();
        this.snackbar.open("Report note deleted successfully!", "Dismiss", {
          duration: 5000,
        });
        this.complete.emit(true);
      }
    });
  }
}
