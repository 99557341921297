import { MaterialModule } from "../material.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TermsAndServicesDialogComponent } from "./terms-and-services-dialog/terms-and-services-dialog.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SupportDialogComponent } from "./support-dialog/support-dialog.component";
import { PrivacyPolicyDialogComponent } from "./policy-policy-dialog/privacy-policy-dialog.component";
import { PolicyDialogComponent } from "./policy-dialog/policy-dialog.component";
import { HelpModule } from "../components/help/help.module";
import { ReasonPromptDialogComponent } from "./reason-prompt-dialog/reason-prompt-dialog.component";
import { ConfirmLeaveDialogComponent } from "./unsaved-changes/confirm-leave-dialog.component";
import { DsSharedModule } from "../shared/ds-shared.module";
import { ClientsModule } from "../components/organization/clients/clients.module";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { LoadingDialogComponent } from "./loading-dialog/loading-dialog.component";
import { OrganizationModule } from "../components/organization/organization.module";
import { LocationUploadDialogComponent } from "./location-upload-dialog/location-upload-dialog.component";
import { InformationDialogComponent } from "./information-dialog/information-dialog.component";
import { EditFieldDialogComponent } from "./edit-field-dialog/edit-field-dialog.component";
import { TpdReportFieldSelectionDialogComponent } from "./tpd-report-field-selection-dialog/tpd-report-field-selection-dialog.component";
import { UploadDocumentDialogComponent } from "./upload-document-dialog/upload-document-dialog.component";
import { InformationDialogTableComponent } from "./information-dialog/information-dialog-table/information-dialog-table.component";
import { ModuleInformationDialogComponent } from "./module-information-dialog/module-information-dialog.component";
import { NewTpdReportDialogComponent } from "./new-tpd-report-dialog/new-tpd-report-dialog.component";
import { ReconciliationReportsModule } from "app/components/ds-modules/3pd-reconciliation/reconciliation-reports.module";
import { TransactionsDialogComponent } from "./transactions-dialog/transactions-dialog.component";
import { TermsModule } from "../components/structural/terms/terms.module";
import { FileUploadModule } from "../components/file-upload/file-upload.module";
import { FormsContainerDialogComponent } from "./forms-container-dialog/forms-container-dialog.component";
import { ExemptionCertificateManagementModule } from "../components/ds-modules/exemption-certificate-management/exemption-certificate-management.module";
import { ExemptionCertificatesListDialogComponent } from "./exemption-certificates-list/exemption-certificates-list.component";
import { ExemptionCertificateViewerDialogComponent } from "./exemption-certificate-viewer-dialog/exemption-certificate-viewer-dialog.component";
import { TeamMemberSelectorDialogComponent } from "./team-member-selector-dialog/team-member-selector-dialog.component";
import { AvatarModule } from "../components/avatar/avatar.module";
import { PopoverModule } from "ngx-bootstrap/popover";
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from "ngx-perfect-scrollbar";
import { ReconciliationNoteDialogComponent } from "./reconciliation-note-dialog/reconciliation-note-dialog.component";
import { TransactionHistoryDialogComponent } from "./transaction-history-dialog/transaction-history-dialog.component";
import { EditRecReportInfoDialogComponent } from "./edit-rec-report-info-dialog/edit-rec-report-info-dialog.component";
import { TransactionsTableModule } from "../components/transactions-table/transactions-table.module";
import { DataUploadViewDialogComponent } from "./data-upload-view-dialog/data-upload-view-dialog.component";
import { DataUploadModule } from "../components/data-upload/data-upload.module";
import { UxModule } from "../components/ux/ux.module";
import { NewVersionDialogComponent } from "./new-version-dialog.component/new-version-dialog.component";
import { CustomerRatingsDialogComponent } from "./customer-ratings-dialog/customer-ratings-dialog.component";
import { CustomerRatingsTableModule } from "app/components/customer-ratings-table/customer-ratings-table.module";
import { EntitySelectorDialogComponent } from "./entity-selector-dialog/entity-selector-dialog.component";
import { NewDataUploadDialogComponent } from "./new-data-upload-dialog/new-data-upload-dialog.component";
import { UploadExpectedFieldsDialogComponent } from "./upload-expected-fields/upload-expected-fields-dialog.component";
import { UploadControlsChecklistDialogComponent } from "./upload-controls-checklist-dialog/upload-controls-checklist-dialog.component";
import { UploadGroupChecklistDialogComponent } from "./upload-group-checklist-dialog/upload-group-checklist-dialog.component";
import { DisputeManagerDialogComponent } from "./dispute-manager-dialog/dispute-manager-dialog.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { RecReportViewDialogDialogComponent } from "./rec-report-view-dialog/rec-report-view-dialog.component";
import { PayoutTableModule } from "../components/payout-table/payouts-table.module";
import { PayoutsDialogComponent } from "./payouts-dialog/payouts-dialog.component";
import { TransactionsMatchDialogComponent } from "./transaction-match-dialog/transactions-match-dialog.component";
import { CanceledOrderDisputeManagerDialogComponent } from "./canceled-order-dispute-manager-dialog/canceled-order-dispute-manager-dialog.component";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  // suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20,
};
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DsSharedModule,
    FlexLayoutModule,
    HelpModule,
    ClientsModule,
    OrganizationModule,
    ReconciliationReportsModule,
    MaterialModule,
    TermsModule,
    FileUploadModule,
    ExemptionCertificateManagementModule,
    AvatarModule,
    PopoverModule.forRoot(),
    PerfectScrollbarModule,
    TransactionsTableModule,
    DataUploadModule,
    UxModule,
    CustomerRatingsTableModule,
    ClipboardModule,
    PayoutTableModule,
  ],
  declarations: [
    PrivacyPolicyDialogComponent,
    TermsAndServicesDialogComponent,
    SupportDialogComponent,
    PolicyDialogComponent,
    ReasonPromptDialogComponent,
    ConfirmLeaveDialogComponent,
    ConfirmDialogComponent,
    LoadingDialogComponent,
    LocationUploadDialogComponent,
    InformationDialogComponent,
    EditFieldDialogComponent,
    TpdReportFieldSelectionDialogComponent,
    UploadDocumentDialogComponent,
    InformationDialogTableComponent,
    ModuleInformationDialogComponent,
    NewTpdReportDialogComponent,
    TransactionsDialogComponent,
    FormsContainerDialogComponent,
    ExemptionCertificatesListDialogComponent,
    ExemptionCertificateViewerDialogComponent,
    TeamMemberSelectorDialogComponent,
    ReconciliationNoteDialogComponent,
    TransactionHistoryDialogComponent,
    EditRecReportInfoDialogComponent,
    DataUploadViewDialogComponent,
    NewVersionDialogComponent,
    CustomerRatingsDialogComponent,
    EntitySelectorDialogComponent,
    NewDataUploadDialogComponent,
    UploadExpectedFieldsDialogComponent,
    UploadControlsChecklistDialogComponent,
    UploadGroupChecklistDialogComponent,
    DisputeManagerDialogComponent,
    RecReportViewDialogDialogComponent,
    PayoutsDialogComponent,
    TransactionsMatchDialogComponent,
    CanceledOrderDisputeManagerDialogComponent,
  ],
  entryComponents: [
    PrivacyPolicyDialogComponent,
    TermsAndServicesDialogComponent,
    SupportDialogComponent,
    PolicyDialogComponent,
    ReasonPromptDialogComponent,
    ConfirmLeaveDialogComponent,
    ConfirmDialogComponent,
    LoadingDialogComponent,
    LocationUploadDialogComponent,
    InformationDialogComponent,
    EditFieldDialogComponent,
    TpdReportFieldSelectionDialogComponent,
    UploadDocumentDialogComponent,
    ModuleInformationDialogComponent,
    NewTpdReportDialogComponent,
    TransactionsDialogComponent,
    FormsContainerDialogComponent,
    ExemptionCertificatesListDialogComponent,
    ExemptionCertificateViewerDialogComponent,
    TeamMemberSelectorDialogComponent,
    ReconciliationNoteDialogComponent,
    TransactionHistoryDialogComponent,
    EditRecReportInfoDialogComponent,
    DataUploadViewDialogComponent,
    NewVersionDialogComponent,
    CustomerRatingsDialogComponent,
    EntitySelectorDialogComponent,
    NewDataUploadDialogComponent,
    UploadExpectedFieldsDialogComponent,
    UploadControlsChecklistDialogComponent,
    UploadGroupChecklistDialogComponent,
    DisputeManagerDialogComponent,
    RecReportViewDialogDialogComponent,
    PayoutsDialogComponent,
    TransactionsMatchDialogComponent,
    CanceledOrderDisputeManagerDialogComponent,
  ],
  exports: [],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
})
export class DialogsModule {}
