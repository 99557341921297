import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import {
  ThirdParty,
  TransactionI,
  ClientThirdParty,
  ThirdPartyReportTransactionFlag,
  ThirdPartyReport,
} from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { TransactionHistoryDialogComponent } from "../transaction-history-dialog/transaction-history-dialog.component";
import { SanitizeHtmlPipe } from "../../shared/pipes/sanitize-html.pipe";
import { AngularFirestore } from "@angular/fire/firestore";
import { FirestoreUtilities } from "../../utilities/firestore-utilities";
import { ReconciliationReportDatUtility } from "app/components/ds-modules/3pd-reconciliation/utilities/reconciliation-report-data.utility";

@Component({
  selector: "app-transactions-match-dialog",
  templateUrl: "./transactions-match-dialog.component.html",
  styleUrls: ["./transactions-match-dialog.component.scss"],
})
export class TransactionsMatchDialogComponent implements OnInit {
  public destroy$ = new Subject();
  loadingTransactionFlags = true;

  constructor(
    public dialogRef: MatDialogRef<TransactionsMatchDialogComponent>,
    public afs: AngularFirestore,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      location: Location;
      thirdParty: ThirdParty;
      dataUtility: ReconciliationReportDatUtility;
      startDate: any;
      endDate: any;
    }
  ) {}

  ngOnInit() {}
}
