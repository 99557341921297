import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AngularFirestore } from "@angular/fire/firestore";
import { ActivatedRoute, Router } from "@angular/router";
import {
  first,
  takeUntil,
  distinctUntilChanged,
  catchError,
} from "rxjs/operators";
import { Subject, combineLatest, lastValueFrom } from "rxjs";
import {
  UserRoles,
  Location,
  ThirdParty,
  ThirdPartyReport,
  Client3pdConfiguration,
  ThirdPartyReconciliationLocationData,
  Client3pdTransactionStatusConfiguration,
  ThirdPartyTransactionStatus,
  ThirdPartyReportStatuses,
  ThirdPartyReportFragment,
  ThirdPartyReportFragmentCategoryReport,
  ThirdPartyReportFragmentDailyDrillDownReport,
  ThirdPartyReportFragmentVarianceAnalysisReport,
  ThirdPartyReportFragmentTransactionAnalysis,
  ThirdPartyReportFragmentFeeReport,
  ReportShare,
  ReportGroup,
  ReportGroupTypes,
  reconciliationTableFields,
  ThirdPartyReportFragmentErrorChargeLog,
  ThirdPartyReportFragmentDataLog,
  ThirdPartyReportTypes,
  DsModules,
  ClientModule,
  DsModuleAddons,
  ClientCustomReport,
  StandardJournalEntry,
  ThirdPartyReportNote,
  ThirdPartyReportVersion,
  ClientJeHeaderConfigurationGroup,
} from "@deliver-sense-librarian/data-schema";
import * as moment from "moment-timezone";
// moment.tz.setDefault("America/Phoenix");
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
  FormBuilder,
  FormGroup,
} from "@angular/forms";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { ThirdPartyDeliveryAnalyticsEngine } from "../third-party-delivery-analytics-engine";
import * as _ from "lodash";
import { MatExpansionPanel } from "@angular/material/expansion";
import { MatDialog } from "@angular/material/dialog";
import { TeamMemberSelectorDialogComponent } from "app/dialogs/team-member-selector-dialog/team-member-selector-dialog.component";
import { Papa } from "ngx-papaparse";
import { ThirdPartyDeliveryIds } from "@deliver-sense-librarian/data-schema";
import { ReconciliationReportDatUtility } from "../utilities/reconciliation-report-data.utility";
import { ReconciliationReportExportUtility } from "../utilities/reconciliation-report-export.utility";
import { EditRecReportInfoDialogComponent } from "../../../../dialogs/edit-rec-report-info-dialog/edit-rec-report-info-dialog.component";
import { ReconciliationReportFilteringUtility } from "../utilities/reconciliation-report-filtering.utility";
import { ConfirmDialogComponent } from "app/dialogs/confirm-dialog/confirm-dialog.component";
import { HttpClient } from "@angular/common/http";
import { UploadDocumentService } from "../../../../services/upload-document.service";
import { EntitySelectorDialogComponent } from "app/dialogs/entity-selector-dialog/entity-selector-dialog.component";
import { RecReportViewDialogDialogComponent } from "../../../../dialogs/rec-report-view-dialog/rec-report-view-dialog.component";
import {
  DrillDownSelection,
  TADrillDownSelection,
} from "../reconciliation-sub-reports/daily-drill-down/daily-drill-down.component";
import { EditFieldDialogComponent } from "app/dialogs/edit-field-dialog/edit-field-dialog.component";

@Component({
  selector: "app-create-report",
  templateUrl: `./reconciliation-report.component.html`,
  styleUrls: ["./reconciliation-report.component.scss"],
})
export class ReconciliationReportComponent implements OnInit, OnDestroy {
  @ViewChild(ElementRef, { static: true }) reportHeaderCard: ElementRef;
  @ViewChild(MatExpansionPanel, { static: true })
  parameterPanel: MatExpansionPanel;
  public loadingParameters = true;
  public reportName = new FormControl("", Validators.required);
  public parametersForm: FormGroup;
  public reportData: ThirdPartyReconciliationLocationData[];
  public thirdParties: ThirdParty[] = [];
  public locations: Location[] = [];
  public reportAvailable: boolean;
  public analyticsEngine: ThirdPartyDeliveryAnalyticsEngine;
  public existingReport: ThirdPartyReport;
  public editingName = false;
  public editingGroup = false;
  public client3pdConfiguration: Client3pdConfiguration;
  public client3pdTransactionStatusConfigurations: Client3pdTransactionStatusConfiguration[] =
    [];
  public taxRateErrorTotal = 0;
  public salesErrorTotal = 0;
  public taxErrorTotal = 0;
  public remittanceErrorTotal = 0;
  public defaultSelectedFields = [
    // "Location Id",
    // "3PD",
    // "State/Province",
    "Sales Variance",
    "Tax Variance",
    "Suggested Tax Adjustment",
    "Total Fees",
    "Remittance Variance",
  ];
  public possibleFields = reconciliationTableFields;
  private allThirdPartiesSelected: boolean;
  private thirdPartyReportId: string;
  public uiState: any;
  private destroy$ = new Subject();
  public selectedReport = new FormControl("Analytics Breakout Summary");

  public availableReports = [];
  public reconciliationReports = [
    { value: "Summary" },
    { value: "Net Cash" },
    { value: "Reconciliation" },
    { value: "Tax Summary Report" },
    { value: "Daily Drill Down" },
    { value: "Variance Analysis" },
    { value: "Transaction Analysis" },
    { value: "POS Misuse/Fraud" },
    { value: "Variance Charts" },
    { value: "Transaction Matching" },
  ];
  public dspAnalyticsReports = [
    { value: "Analytics Breakout Summary" },
    { value: "3PD Performance Summary" },
    { value: "Fees Summary" },
    { value: "Error Charges" },
    { value: "Adjustments" },
    { value: "Payout Analysis" },
    { value: "Status Analysis" },
    { value: "Fees Analysis" },
    { value: "Tax Rate Analysis" },
    { value: "Revenue Recovered" },
    { value: "Canceled Orders" },
    { value: "Prior Period Adjustments" },
  ];
  public dispatchToGoReports = [{ value: "Dispatch Summary" }];
  public customerRatingsReports = [{ value: "Customer Ratings" }];
  public dataReviewReports = [
    { value: "Reconciliation Notes" },
    { value: "Flagged Transactions" },
    { value: "Data Analytics" },
    { value: "Data Anomalies" },
  ];
  public depositReports = [{ value: "Deposit Report" }];
  /**Export Elements */
  public reportExportTypes = [];
  public reportExportSelectionControl = new FormControl([]);
  public skipCheckFigures = new FormControl(true);
  public showExportSelection = false;
  totalErrors = 0;
  thirdPartyTransactionStatuses: ThirdPartyTransactionStatus[];
  clientThirdPartyReportingModule: ClientModule;
  reportRunningInBackground = false;
  reportFragments: ThirdPartyReportFragment[] = [];
  completeFragments: ThirdPartyReportFragment[] = [];
  loadingReportFragments = true;
  errorFragments: ThirdPartyReportFragment[] = [];
  errorReconcilingData = false;
  thirdPartyReportFragmentDayDrillDownReports: ThirdPartyReportFragmentDailyDrillDownReport[] =
    [];
  thirdPartyReportFragmentCategoryReports: ThirdPartyReportFragmentCategoryReport[] =
    [];
  thirdPartyReportVarianceAnalysisReports: ThirdPartyReportFragmentVarianceAnalysisReport[] =
    [];
  thirdPartyReportFragmentTransactionAnalysis: ThirdPartyReportFragmentTransactionAnalysis[] =
    [];
  thirdPartyReportFragmentFeeReports: ThirdPartyReportFragmentFeeReport[] = [];
  thirdPartyErrorTransactionAnalysis: ThirdPartyReportFragmentTransactionAnalysis[] =
    [];
  thirdPartyPotentialFraudIndicators: ThirdPartyReportFragmentTransactionAnalysis[] =
    [];
  thirdPartyReportFragmentErrorChargeLogs: ThirdPartyReportFragmentErrorChargeLog[] =
    [];
  thirdPartyReportFragmentDataLogs: ThirdPartyReportFragmentDataLog[] = [];
  sharedViewMode: boolean;
  shareModeLocationIds: string[] = [];
  reportShares: ReportShare[] = [];
  public reportGroups: ReportGroup[] = [];
  public selectedThirdPartyReportGroup = new FormControl();
  loadingFragmentReports: boolean;
  activePopover: any;
  initialized$ = new Subject();
  exportUtility: ReconciliationReportExportUtility;
  dataUtility: ReconciliationReportDatUtility;
  downloadOnLoad: boolean;
  initialDownloadTriggered = false;
  filteringUtility: ReconciliationReportFilteringUtility;
  showReportSelector = false;
  preDrillDownSelection: DrillDownSelection;
  preTASelection: TADrillDownSelection;
  preErrorChargeSelection: DrillDownSelection;
  clientCustomReports: ClientCustomReport[] = [];
  standardJournalEntries: StandardJournalEntry[] = [];
  clientJeHeaderConfigurationGroups: ClientJeHeaderConfigurationGroup[] = [];
  exportJeHeaderSelection = new FormControl();
  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private papa: Papa,
    private afs: AngularFirestore,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private uploadDocumentService: UploadDocumentService
  ) {
    this.availableReports = [
      ...this.reconciliationReports.map((report) => report.value),
      ...this.dspAnalyticsReports.map((report) => report.value),
      ...this.dispatchToGoReports.map((report) => report.value),
      ...this.customerRatingsReports.map((report) => report.value),
      ...this.dataReviewReports.map((report) => report.value),
      ...this.depositReports.map((report) => report.value),
    ];
  }

  ngOnInit() {
    combineLatest([
      this.store
        .select((store) => store.uiState.client)
        .pipe(distinctUntilChanged((a, b) => a === b)),
      this.store
        .select((store) => store.uiState.authUser)
        .pipe(distinctUntilChanged((a, b) => a === b)),
      this.store
        .select((store) => store.uiState.clientThirdParties)
        .pipe(distinctUntilChanged((a, b) => a === b)),
      this.store
        .select((store) => store.uiState.locations)
        .pipe(distinctUntilChanged((a, b) => a === b)),
    ])
      .pipe(takeUntil(this.initialized$))
      .subscribe(([client, authUser, clientThirdParties, locations]) => {
        if (
          client &&
          authUser &&
          clientThirdParties?.length > 0 &&
          locations?.length > 0
        ) {
          this.uiState = { client, authUser, clientThirdParties, locations };
          this.initializeReport();
          this.initialized$.next(true);
          this.initialized$.complete();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private listenForFormControlChanges() {
    this.skipCheckFigures.valueChanges.subscribe((value) => {
      if (!value) {
        const selectedExportReports = this.reportExportSelectionControl.value;
        if (
          selectedExportReports.indexOf(
            ThirdPartyReportTypes.errorChargeSummary
          ) === -1
        ) {
          selectedExportReports.push(ThirdPartyReportTypes.errorChargeSummary);
          this.reportExportSelectionControl.patchValue(selectedExportReports);
          this.reportExportSelectionControl.updateValueAndValidity();
        }
      }
    });
  }
  private async initializeReport() {
    await this.fetchReportingResources();
    this.getLocations();
    this.listenForFormControlChanges();
    this.thirdParties = this.uiState.clientThirdParties
      .filter((cdsp) => cdsp.active && !cdsp.thirdParty.recHidden)
      .map((clientThirdParty) => clientThirdParty.thirdParty)
      .filter((tp) => !!tp);
    const queryParams = await this.activatedRoute.queryParams
      .pipe(first())
      .toPromise();
    this.sharedViewMode = queryParams["shared"] === "true";
    this.downloadOnLoad = queryParams["downloadOnLoad"] === "true";
    this.activatedRoute.params.subscribe((params$) => {
      this.thirdPartyReportId = params$["id"];
      if (this.thirdPartyReportId) {
        this.getExistingReport();
      }
      if (!this.sharedViewMode) {
        this.getExistingReportShares();
      } else {
        this.checkForShareAccess();
      }
    });
    this.getReportGroups();
  }
  private getReportGroups() {
    this.afs
      .collection("reportGroups", (ref) =>
        ref
          .where("client", "==", this.uiState.client.id)
          .where("creator", "==", this.uiState.authUser.id)
          .where("type", "==", ReportGroupTypes.thirdPartyReconciliation)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((reportGroups$) => {
        this.reportGroups = <ReportGroup[]>(
          FirestoreUtilities.mapToType(reportGroups$)
        );
        this.reportGroups.push(
          new ReportGroup({ name: "Un-Grouped", id: "ungrouped" })
        );
      });
  }
  private getLocations() {
    if (this.uiState.authUser.internalRole > 0) {
      this.afs
        .collection("locations", (ref) =>
          ref
            .where("client", "==", this.uiState.client.id)
            .where("active", "==", true)
        )
        .snapshotChanges()
        .pipe(takeUntil(this.destroy$))
        .subscribe((locationsQueryResults$) => {
          this.locations = <Location[]>(
            FirestoreUtilities.mapToType(locationsQueryResults$)
          );
          this.loadingParameters = false;
        });
    } else {
      FirestoreUtilities.getUserAccessibleResourcesOfType(
        "locations",
        this.afs,
        this.uiState.locations,
        [UserRoles.admin, UserRoles.contributor, UserRoles.viewer]
      ).subscribe((locations$) => {
        this.locations = locations$
          .filter((location) => !!location.active)
          .sort((a, b) => (a.locationId < b.locationId ? -1 : 1));
        this.loadingParameters = false;
      });
    }
  }

  private async fetchReportingResources() {
    const [
      client3pdConfigQuery$,
      client3pdTransactionStatusConfigQuery$,
      thirdPartyTransactionStatusesQuery$,
      clientJeHeaderConfigurationGroupsQuery$,
    ] = await Promise.all([
      this.afs
        .collection("client3pdConfigurations", (ref) =>
          ref.where("client", "==", this.uiState.client.id)
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise(),
      this.afs
        .collection("client3pdTransactionStatusConfigurations", (ref) =>
          ref.where("client", "==", this.uiState.client.id)
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise(),
      this.afs
        .collection("thirdPartyTransactionStatuses")
        .snapshotChanges()
        .pipe(first())
        .toPromise(),
      lastValueFrom(
        this.afs
          .collection("clientJeHeaderConfigurationGroups", (ref) =>
            ref.where("client", "==", this.uiState.client.id)
          )
          .snapshotChanges()
          .pipe(first())
      ),
    ]);
    this.client3pdConfiguration = FirestoreUtilities.mapToType(
      client3pdConfigQuery$
    )[0] as Client3pdConfiguration;
    this.client3pdTransactionStatusConfigurations =
      FirestoreUtilities.mapToType(
        client3pdTransactionStatusConfigQuery$
      ) as Client3pdTransactionStatusConfiguration[];
    this.thirdPartyTransactionStatuses = FirestoreUtilities.mapToType(
      thirdPartyTransactionStatusesQuery$
    ) as ThirdPartyTransactionStatus[];
    this.clientJeHeaderConfigurationGroups = FirestoreUtilities.mapToType(
      clientJeHeaderConfigurationGroupsQuery$
    ) as ClientJeHeaderConfigurationGroup[];
    const [
      clientModuleQuery$,
      clientCustomReportsQuery$,
      standardJournalEntriesQuery$,
    ] = await Promise.all([
      lastValueFrom(
        this.afs
          .collection("clientModules", (ref) =>
            ref
              .where("module", "==", DsModules.ThirdPartyDeliveryReporting)
              .where("client", "==", this.uiState.client.id)
          )
          .snapshotChanges()
          .pipe(first())
      ),
      lastValueFrom(
        this.afs
          .collection("clientCustomReports", (ref) =>
            ref.where("client", "==", this.uiState.client.id)
          )
          .snapshotChanges()
          .pipe(first())
      ),
      lastValueFrom(
        this.afs
          .collection("standardJournalEntries")
          .snapshotChanges()
          .pipe(first())
      ),
    ]);
    this.clientThirdPartyReportingModule = FirestoreUtilities.mapToType(
      clientModuleQuery$
    )[0] as ClientModule;
    this.clientCustomReports = FirestoreUtilities.mapToType(
      clientCustomReportsQuery$
    ).sort((a, b) => {
      return a.name.charAt(0) > b.name.charAt(0) ? 1 : -1;
    }) as ClientCustomReport[];
    this.standardJournalEntries = <StandardJournalEntry[]>(
      FirestoreUtilities.mapToType(standardJournalEntriesQuery$)
    );
  }
  private checkForShareAccess() {
    // if (!this.existingReport.clientShared) {
    this.afs
      .collection(`reportShares`, (ref) =>
        ref
          .where("report", "==", this.thirdPartyReportId)
          .where("toUser", "==", this.uiState.authUser.id)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((reportSharesQuery$) => {
        this.reportShares = <ReportShare[]>(
          FirestoreUtilities.mapToType(reportSharesQuery$)
        );
        if (
          !this.reportShares.find(
            (share) => share.toUser === this.uiState.authUser.id
          )
        ) {
          this.router.navigate(["/app/3pd-reports"]);
          this.snackBar.open(
            "This report has not been shared with you.",
            "Dismiss",
            {
              duration: 5000,
            }
          );
        }
      });
    // }
  }
  private getExistingReportShares() {
    this.afs
      .collection(`reportShares`, (ref) =>
        ref
          .where("report", "==", this.thirdPartyReportId)
          .where("fromUser", "==", this.uiState.authUser.id)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((reportSharesQuery$) => {
        this.reportShares = <ReportShare[]>(
          FirestoreUtilities.mapToType(reportSharesQuery$)
        );
      });
  }
  public setReportToView(reportName) {
    this.selectedReport.patchValue(reportName);
    this.selectedReport.updateValueAndValidity();
  }
  private async listenForGroupChange() {
    this.selectedThirdPartyReportGroup.valueChanges.subscribe(
      async (selectedGroupId) => {
        if (selectedGroupId && this.existingReport.group !== selectedGroupId) {
          try {
            await this.afs
              .doc(`thirdPartyReports/${this.thirdPartyReportId}`)
              .update({
                group: selectedGroupId,
              });
            this.existingReport.group = selectedGroupId;
            this.snackBar.open(
              "Updated group setting successfully!",
              "Dismiss",
              { duration: 5000 }
            );
            this.editingGroup = false;
          } catch (e) {
            console.error(e.message);
            this.snackBar.open(
              "Oops... something went wrong. Please refresh and try again",
              "Dismiss",
              { duration: 5000 }
            );
          }
        }
      }
    );
  }
  private getExistingReport() {
    this.afs
      .doc(`thirdPartyReports/${this.thirdPartyReportId}`)
      .snapshotChanges()
      .pipe(
        first(),
        catchError((e) => {
          console.error(e);
          this.snackBar.open(
            "Oops... something went wrong loading your report. Please refresh to try again.",
            "Dismiss",
            {
              duration: 5000,
            }
          );
          return e;
        })
      )
      .subscribe((report$) => {
        this.existingReport = <ThirdPartyReport>(
          FirestoreUtilities.objectToType(report$)
        );
        this.setupAvailableReportGroups();
        if (
          this.existingReport.creator !== this.uiState.authUser.id &&
          !this.sharedViewMode
        ) {
          this.router.navigate(["../app"]);
          this.snackBar.open(
            "You do not have edit access to this report",
            "Dismiss",
            { duration: 5000 }
          );
        }
        if (this.existingReport) {
          this.reportName.patchValue(this.existingReport.name);
          this.reportName.updateValueAndValidity();
          if (this.existingReport) {
            if (this.existingReport.reportData) {
              this.reportData = this.existingReport.reportData;
              this.reportAvailable = true;
            }
            this.selectedThirdPartyReportGroup.patchValue(
              this.existingReport.group
            );
            this.selectedThirdPartyReportGroup.updateValueAndValidity();
            this.listenForGroupChange();
            this.setDefaultFields();
            this.setupParametersForm();
            this.getReportFragments();
          }
        }
      });
  }
  private setAvailableStandardJournalEntries() {
    let availabeJEs = [];
    this.standardJournalEntries
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .forEach((entry) => {
        if (
          entry.id === "recordSalesVarianceAggregate" ||
          entry.id === "recordSalesVarianceDetail"
        ) {
          if (this.existingReport.includePosInRec) {
            availabeJEs.push({ name: entry.name, value: entry.name });
          }
        } else {
          availabeJEs.push({ name: entry.name, value: entry.name });
        }
      });
    this.reportExportTypes.push({
      groupName: "Standard Journal Entries",
      reports: availabeJEs,
    });
  }
  public isReportRequired(value) {
    return (
      value === ThirdPartyReportTypes.dsReport ||
      value === ThirdPartyReportTypes.varianceAnalysis ||
      value === ThirdPartyReportTypes.transactionAnalysis ||
      value === ThirdPartyReportTypes.reconciliationSummary
    );
  }
  private setupAvailableReportGroups() {
    this.showReportSelector = false;
    setTimeout(() => {
      // 3PD Analytics Reports
      this.reportExportTypes = [];
      if (this.existingReport.includePosInRec) {
        this.reportExportTypes.push({
          groupName: "3PD Reconciliation",
          reports: [
            {
              name: "Reconciliation Summary",
              value: ThirdPartyReportTypes.reconciliationSummary,
            },
            {
              name: "Reconciliation Summary Breakout",
              value: ThirdPartyReportTypes.reconciliationSummaryBreakout,
            },
            {
              name: "Reconciliation Data Report",
              value: ThirdPartyReportTypes.dsReport,
            },
            {
              name: "Variance Analysis",
              value: ThirdPartyReportTypes.varianceAnalysis,
            },
            {
              name: "Transaction Analysis",
              value: ThirdPartyReportTypes.transactionAnalysis,
            },
            { name: "Tax Summary", value: ThirdPartyReportTypes.taxSummary },
            {
              name: "Net Cash",
              value: ThirdPartyReportTypes.netCash,
            },
            {
              name: "Potential Fraud Analysis",
              value: ThirdPartyReportTypes.potentialFraudAnalysis,
            },
            {
              name: "Reconciliation Notes",
              value: ThirdPartyReportTypes.reconciliationNotes,
            },
            {
              name: "Tip Variance",
              value: ThirdPartyReportTypes.tipVariance,
            },
          ],
        });
      } else {
        // If Analytics Only - Only Tax Summary and Net Cash
        this.reportExportTypes.push({
          groupName: "3PD Reconciliation",
          reports: [
            {
              name: "Reconciliation Summary",
              value: ThirdPartyReportTypes.reconciliationSummary,
            },
            {
              name: "Reconciliation Data Report",
              value: ThirdPartyReportTypes.dsReport,
            },
            { name: "Tax Summary", value: ThirdPartyReportTypes.taxSummary },
            {
              name: "Net Cash",
              value: ThirdPartyReportTypes.netCash,
            },
          ],
        });
      }
      this.reportExportTypes.push({
        groupName: "3PD Analytics",
        reports: [
          {
            name: "Third Party Analytics Breakout",
            value: ThirdPartyReportTypes.thirdPartyAnalyticsBreakoutSummary,
          },
          {
            name: "Third Party Analytics Breakout Aggregated",
            value:
              ThirdPartyReportTypes.thirdPartyAnalyticsBreakoutSummaryAggregated,
          },
          {
            name: "Third Party Performance Summary",
            value: ThirdPartyReportTypes.thirdPartyPerformance,
          },
          {
            name: "Fees Summary",
            value: ThirdPartyReportTypes.feesSummary,
          },
          {
            name: "Error Charge Summary",
            value: ThirdPartyReportTypes.errorChargeSummary,
          },
          {
            name: "Adjustments Analysis",
            value: ThirdPartyReportTypes.adjustmentsAnalysis,
          },
          { name: "Fee Analysis", value: ThirdPartyReportTypes.feeAnalysis },
          {
            name: "Canceled Orders",
            value: ThirdPartyReportTypes.canceledOrders,
          },
          {
            name: "Revenue Recovered",
            value: ThirdPartyReportTypes.revenueRecovered,
          },
          {
            name: "Tax Rate Analysis",
            value: ThirdPartyReportTypes.taxRateAnalysis,
          },
          {
            name: "Status Drill Down",
            value: ThirdPartyReportTypes.statusDrillDown,
          },
          {
            name: "Payout Analysis",
            value: ThirdPartyReportTypes.payoutAnalysis,
          },
        ],
      });
      this.reportExportTypes.push({
        groupName: "Deposits",
        reports: [
          {
            name: "Deposits Report",
            value: ThirdPartyReportTypes.depositReport,
          },
        ],
      });
      if (this.isDispatchInSelection()) {
        this.reportExportTypes.push({
          groupName: "Dispatch & To-Go",
          reports: [
            {
              name: "Dispatch Summary",
              value: ThirdPartyReportTypes.dispatchSummary,
            },
          ],
        });
      }
      if (this.isCustomerRatingsAvailable()) {
        this.reportExportTypes.push({
          groupName: "Customer Ratings",
          reports: [
            {
              name: "Customer Ratings",
              value: ThirdPartyReportTypes.customerRatings,
            },
          ],
        });
      }
      //STANDARD JES
      this.setAvailableStandardJournalEntries();

      if (this.clientCustomReports?.length > 0) {
        const customReports = this.clientCustomReports.map((customReport) => {
          return { name: customReport.name, value: customReport.name };
        });
        this.reportExportTypes.push({
          groupName: "Custom Reports",
          reports: customReports,
        });
      }
      // SETTING DEFAULT EXPORT SELECTION
      this.reportExportSelectionControl.reset();
      if (this.existingReport.defaultJeHeaderGroup) {
        this.exportJeHeaderSelection.patchValue(
          this.existingReport.defaultJeHeaderGroup
        );
      }
      const defaultExportSelections = [];
      if (this.existingReport.defaultExports?.length > 0) {
        defaultExportSelections.push(...this.existingReport.defaultExports);
      } else if (this.client3pdConfiguration.defaultExportReports?.length > 0) {
        defaultExportSelections.push(
          ...this.client3pdConfiguration.defaultExportReports
        );
      } else {
        if (this.existingReport.includePosInRec) {
          defaultExportSelections.push(
            ...[
              ThirdPartyReportTypes.reconciliationSummary,
              ThirdPartyReportTypes.dsReport,
              ThirdPartyReportTypes.varianceAnalysis,
              ThirdPartyReportTypes.transactionAnalysis,
              ThirdPartyReportTypes.taxSummary,
              ThirdPartyReportTypes.netCash,
              ThirdPartyReportTypes.potentialFraudAnalysis,
              ThirdPartyReportTypes.reconciliationNotes,
              ThirdPartyReportTypes.thirdPartyPerformance,
              ThirdPartyReportTypes.errorChargeSummary,
              ThirdPartyReportTypes.feesSummary,
              ThirdPartyReportTypes.feeAnalysis,
              ThirdPartyReportTypes.adjustmentsAnalysis,
              ThirdPartyReportTypes.taxRateAnalysis,
            ]
          );
        } else {
          defaultExportSelections.push(
            ...[
              ThirdPartyReportTypes.reconciliationSummary,
              ThirdPartyReportTypes.dsReport,
              ThirdPartyReportTypes.netCash,
              ThirdPartyReportTypes.taxSummary,
              ThirdPartyReportTypes.thirdPartyAnalyticsBreakoutSummary,
              ThirdPartyReportTypes.thirdPartyPerformance,
              ThirdPartyReportTypes.errorChargeSummary,
              ThirdPartyReportTypes.feesSummary,
              ThirdPartyReportTypes.feeAnalysis,
              ThirdPartyReportTypes.adjustmentsAnalysis,
              ThirdPartyReportTypes.canceledOrders,
              ThirdPartyReportTypes.revenueRecovered,
              ThirdPartyReportTypes.taxRateAnalysis,
            ]
          );
        }
        if (
          !!this.clientThirdPartyReportingModule?.addons?.find(
            (addon) =>
              addon.moduleAddon ===
              DsModuleAddons["Error Charge Dispute Service"]
          )?.active
        ) {
          defaultExportSelections.push(ThirdPartyReportTypes.revenueRecovered);
        }
        if (this.isDispatchInSelection()) {
          defaultExportSelections.push(ThirdPartyReportTypes.dispatchSummary);
        }
      }

      this.reportExportSelectionControl.patchValue(defaultExportSelections);
      this.reportExportSelectionControl.updateValueAndValidity();
      // SETTING DEFAULT GROUP TO VIEW
      this.selectedReport.patchValue(
        this.existingReport?.includePosInRec
          ? "Summary"
          : "Analytics Breakout Summary"
      );

      this.selectedReport.updateValueAndValidity();
      this.showReportSelector = true;
    });
  }
  private getReportFragments() {
    this.errorReconcilingData = false;
    this.afs
      .collection("thirdPartyReportFragments", (ref) =>
        ref
          .where("thirdPartyReport", "==", this.existingReport.id)
          .where("client", "==", this.uiState.client.id)
      )
      .snapshotChanges()
      .pipe(
        takeUntil(this.destroy$),
        catchError((e) => {
          console.error(
            `FireStore - 'thirdPartyReportFragments': ${e.message}`
          );
          return e;
        })
      )
      .subscribe((result$) => {
        this.reportFragments = <ThirdPartyReportFragment[]>(
          FirestoreUtilities.mapToType(result$)
        );
        this.completeFragments = this.reportFragments.filter(
          (fragment) => fragment.status === "done"
        );
        this.errorFragments = this.reportFragments.filter(
          (fragment) => fragment.status == "error"
        );
        this.loadingReportFragments = false;
        if (this.errorFragments.length > 0) {
          // ERRORS IN RECONCILIATION RUN
          this.errorReconcilingData = true;
        } else if (
          this.completeFragments.length > 0 &&
          this.completeFragments.length === this.reportFragments.length
        ) {
          // SUCCESSFUL RECONCILIATION RUN
          const fullReconciliation = _.flatten(
            this.completeFragments.map((fragment) => fragment["reconciliation"])
          );
          this.reportData = fullReconciliation.sort((a, b) =>
            parseInt(a.locationId) > parseInt(b.locationId) ? 1 : -1
          );
          if (!this.uiState.authUser.internalRole && this.sharedViewMode) {
            this.reportData = this.reportData.filter(
              (data: ThirdPartyReconciliationLocationData) => {
                return !!this.locations.find(
                  (location) => location.locationId === data.locationId
                );
              }
            );
          }
          this.shareModeLocationIds = this.locations
            .filter((location) => {
              return !!this.reportData.find(
                (report) => report.locationId === location.locationId
              );
            })
            .map((location) => location.locationId);
          this.dataUtility = new ReconciliationReportDatUtility(
            this.afs,
            this.uiState.authUser,
            this.getReportLocations(),
            this.getReportThirdParties(),
            this.uiState.client,
            this.existingReport,
            this.client3pdConfiguration,
            this.clientThirdPartyReportingModule,
            this.reportFragments,
            this.reportData,
            this.destroy$
          );
          this.exportUtility = new ReconciliationReportExportUtility(
            this.papa,
            this.afs,
            this.dataUtility,
            this.uploadDocumentService
          );
          this.filteringUtility = new ReconciliationReportFilteringUtility(
            this.dataUtility
          );
          if (this.downloadOnLoad && !this.initialDownloadTriggered) {
            this.initialDownloadTriggered = true;
            this.loadingService.isLoading(true, "Preparing report data...");
            setTimeout(() => {
              // allow a couple seconds for subscriptions to finish *probably not necessary*
              this.loadingService.isLoading(false);
              this.downloadRecSummary();
            }, 2000);
          }
        }
      });
  }

  async runReport() {
    this.reportData = null;
    setTimeout(async () => {
      if (this.parametersForm.valid) {
        this.loadingService.isLoading(true, "Creating report job...");
        const newParameters = this.parametersForm.value;
        if (!this.existingReport.lastRun) {
          // only on the first run
          await this.setPersistantNotes(newParameters);
        }
        try {
          if (this.reportFragments.length > 0) {
            await this.clearExistingFragments();
          }

          await this.saveReport();
          const selectedLocationIds = newParameters.selectedLocations;
          const selectedThirdPartyIds =
            newParameters.selectedThirdParties.filter((id) => id !== 0); // filter out 0 to account for multi select
          const start = moment(newParameters.startDate).format("l");
          const end = moment(newParameters.endDate).format("l");
          const locationsChunks = _.chunk(selectedLocationIds, 10);
          await Promise.all(
            locationsChunks.map(async (locationsChunk: string[]) => {
              await Promise.all(
                selectedThirdPartyIds.map(async (thirdPartyId) => {
                  const newFragment = new ThirdPartyReportFragment();
                  newFragment.client = this.uiState.client.id;
                  newFragment.endDate = end;
                  newFragment.startDate = start;
                  newFragment.locations = locationsChunk;
                  newFragment.thirdParty = thirdPartyId;
                  newFragment.thirdPartyReport = this.existingReport.id;
                  newFragment.includeDispatchInRec =
                    !!newParameters.includeDispatchInRec;
                  newFragment.includePosInRec = !!newParameters.includePosInRec;
                  newFragment.includeRatingsInRec =
                    !!newParameters.includeRatingsInRec;
                  return this.afs
                    .collection("thirdPartyReportFragments")
                    .add(newFragment.toJSONObject());
                })
              );
            })
          );
          this.loadingService.isLoading(false);
          this.reportRunningInBackground = true;
        } catch (e) {
          console.error(
            `Create Report Fragments - 'thirdPartyReportFragments', ${e.message}`
          );
          this.snackBar.open(
            "Error Creating Report. Please refresh the page and try again.",
            "Dismiss",
            { duration: 5000 }
          );
          this.loadingService.isLoading(false);
        }
      } else {
        this.snackBar.open(
          "Please select all required parameters before running the report.",
          "Dismiss",
          { duration: 5000 }
        );
      }
    });
  }
  private async clearExistingFragments() {
    const fragmentChunks = _.chunk(this.reportFragments, 500);
    await Promise.all(
      fragmentChunks.map((fragmentChunk) => {
        const deleteBatch = this.afs.firestore.batch();
        fragmentChunk.forEach((fragment) => {
          deleteBatch.delete(
            this.afs.doc(`thirdPartyReportFragments/${fragment.id}`).ref
          );
        });
        return deleteBatch.commit();
      })
    );
  }
  private async setPersistantNotes(newParameters) {
    const clientPersistantNotes = FirestoreUtilities.mapToType(
      await lastValueFrom(
        this.afs
          .collection("thirdPartyReportNotes", (ref) =>
            ref
              .where("client", "==", this.existingReport.client)
              .where("isPersistant", "==", true)
          )
          .snapshotChanges()
          .pipe(first())
      )
    );
    if (clientPersistantNotes?.length > 0) {
      await Promise.all(
        clientPersistantNotes.map((persistantNote: ThirdPartyReportNote) => {
          const reportNote = new ThirdPartyReportNote();
          reportNote.thirdPartyReport = this.existingReport.id;
          reportNote.client = this.existingReport.client;
          reportNote.message = persistantNote.message;
          reportNote.creator = persistantNote.creator;
          reportNote.thirdParties = persistantNote.thirdParties;
          reportNote.locations = persistantNote.locations;
          reportNote["fromPersistance"] = true;
          return this.afs
            .collection("thirdPartyReportNotes")
            .add(reportNote.toJSONObject());
        })
      );
    }
  }

  public isUserAccessRestricted() {
    return (
      this.existingReport.creator === this.uiState?.authUser.id ||
      this.uiState.clientRole < 2
    );
  }

  public getReportProgressPercentage() {
    if (this.completeFragments.length > 0) {
      return +(
        (this.completeFragments.length / this.reportFragments.length) *
        100
      ).toFixed(0);
    }
    return 0;
  }
  public reportIncludesOlo() {
    return (
      this.existingReport.thirdParties &&
      !!this.existingReport.thirdParties.find(
        (dspId) => dspId === ThirdPartyDeliveryIds["Olo Dispatch"]
      )
    );
  }
  public isPosReconciliationAvailable() {
    if (this.clientThirdPartyReportingModule?.addons) {
      return this.clientThirdPartyReportingModule.addons.find(
        (addon) => addon.moduleAddon === DsModuleAddons["POS Reconciliation"]
      )?.active;
    }
    return false;
  }

  public isBankReconciliationAvailable() {
    if (this.clientThirdPartyReportingModule?.addons) {
      return this.clientThirdPartyReportingModule.addons.find(
        (addon) => addon.moduleAddon === DsModuleAddons["Bank Reconciliation"]
      )?.active;
    }
    return false;
  }
  public isCustomerRatingsAvailable() {
    if (this.clientThirdPartyReportingModule?.addons) {
      return this.clientThirdPartyReportingModule.addons.find(
        (addon) => addon.moduleAddon === DsModuleAddons["Customer Ratings"]
      )?.active;
    }
    return false;
  }
  public selectAllThirdParties() {
    if (!this.allThirdPartiesSelected) {
      this.allThirdPartiesSelected = true;
      this.parametersForm
        .get("selectedThirdParties")
        .patchValue([0, ...this.thirdParties.map((tp) => tp.id)]);
    } else {
      this.allThirdPartiesSelected = false;
      this.parametersForm.get("selectedThirdParties").patchValue([]);
    }
    this.parametersForm.get("selectedThirdParties").updateValueAndValidity();
  }

  async saveReport() {
    await this.updateReport();
    await this.updateReportGroup();
  }
  async setDefaultReports() {
    await this.afs.doc(`thirdPartyReports/${this.existingReport.id}`).update({
      defaultExports: this.reportExportSelectionControl.value,
      defaultJeHeaderGroup: this.exportJeHeaderSelection.value,
      dateUpdated: moment().toDate(),
    });
    this.snackBar.open("Export selection saved successfully!", "Dismiss", {
      duration: 500,
    });
  }
  // private updateLocalExistingReport(formValues, dateTimeCapture) {
  //   this.existingReport.locations = formValues.selectedLocations;
  //   this.existingReport.thirdParties = formValues.selectedThirdParties;
  //   this.existingReport.startDate = moment(formValues.startDate).toDate();
  //   this.existingReport.endDate = moment(formValues.endDate).toDate();
  //   this.existingReport.includeDispatchInRec =
  //     !!formValues.includeDispatchInRec;
  //   this.existingReport.includePosInRec = !!formValues.includePosInRec;
  //   this.existingReport.includeRatingsInRec = !!formValues.includeRatingsInRec;
  //   this.setupAvailableReportGroups();
  // }
  private async updateReportGroup() {
    if (this.existingReport.group !== "ungrouped") {
      this.afs.doc(`reportGroups/${this.existingReport.group}`).update({
        dateUpdated: moment().toDate(),
      });
    }
  }

  private async updateReport() {
    if (this.parametersForm.valid) {
      const formValues = this.parametersForm.value;
      const dateTimeCapture = moment().toDate();
      await this.afs.doc(`thirdPartyReports/${this.existingReport.id}`).update({
        locations: formValues.selectedLocations,
        thirdParties: formValues.selectedThirdParties,
        startDate: moment(formValues.startDate)
          .startOf("day")
          .add(4, "hours")
          .toDate(),
        endDate: moment(formValues.endDate).endOf("day").toDate(),
        creatorTimezone: moment().format("Z"),
        status: ThirdPartyReportStatuses.running,
        lastRun: dateTimeCapture,
        dateUpdated: dateTimeCapture,
        includeDispatchInRec: !!formValues.includeDispatchInRec,
        includePosInRec: !!formValues.includePosInRec,
        includeRatingsInRec: !!formValues.includeRatingsInRec,
        includeBankInRec: !!formValues.includeBankInRec,
      });
      this.getExistingReport();
      this.snackBar.open("Report updated successfully.", "Dismiss", {
        duration: 5000,
      });
    } else {
      this.snackBar.open("Please complete all required report parameters.");
    }
  }
  async saveReportName() {
    if (this.reportName.valid) {
      await this.afs.doc(`thirdPartyReports/${this.existingReport.id}`).update({
        name: this.reportName.value,
      });
      this.snackBar.open("Report name updated successfully.", "Dismiss", {
        duration: 5000,
      });
    } else {
      this.snackBar.open("Please provide a name for the report.", "Dismiss", {
        duration: 5000,
      });
    }
  }

  public getThirdPartyName(thirdPartyId: string) {
    if (thirdPartyId) {
      const tp = this.thirdParties.find(
        (_tp) => !!_tp && _tp.id === thirdPartyId
      );
      return tp ? tp.name : "";
    }
    return "";
  }

  public getLocationName(locationId: string) {
    const location = this.locations.find((l) => l.locationId === locationId);
    return location ? location.name : "";
  }

  getReportGroupName() {
    const reportGroup = this.reportGroups.find(
      (group) => group.id === this.existingReport.group
    );
    return reportGroup ? `${reportGroup.name}` : "Un-Grouped";
  }

  private setupParametersForm() {
    const currentOffset = moment().format("Z");
    const endDateText = moment(this.existingReport.endDate.toDate())
      .utc()
      .utcOffset(
        this.existingReport.creatorTimezone
          ? this.existingReport.creatorTimezone
          : currentOffset
      )
      .format("l");
    const startDateText = moment(this.existingReport.startDate.toDate())
      .utc()
      .utcOffset(
        this.existingReport.creatorTimezone
          ? this.existingReport.creatorTimezone
          : currentOffset
      )
      .format("l");
    this.parametersForm = this.fb.group({
      selectedThirdParties: new FormControl(
        this.existingReport.thirdParties
          ? this.existingReport.thirdParties
          : [],
        Validators.required
      ),
      selectedLocations: new FormControl(
        this.existingReport.locations ? this.existingReport.locations : [],
        Validators.required
      ),
      startDate: new FormControl(
        moment(startDateText, "l").startOf("day").toDate(),
        Validators.required
      ),
      endDate: new FormControl(
        moment(endDateText, "l").endOf("day").toDate(),
        Validators.required
      ),
      includeDispatchInRec: new FormControl(
        this.existingReport.lastRun
          ? this.existingReport.includeDispatchInRec
          : true
      ),
      includePosInRec: new FormControl(this.existingReport?.includePosInRec),
      includeRatingsInRec: new FormControl(
        this.existingReport?.includeRatingsInRec
      ),
      includeBankInRec: new FormControl(this.existingReport?.includeBankInRec),
    });
  }

  private setDefaultFields() {
    if (
      !!this.existingReport.selectedFields &&
      this.existingReport.selectedFields.length > 0
    ) {
      this.defaultSelectedFields = this.existingReport.selectedFields;
    } else if (
      this.client3pdConfiguration &&
      this.client3pdConfiguration.defaultFields
    ) {
      this.defaultSelectedFields = this.client3pdConfiguration.defaultFields;
    }
  }
  isDispatchInSelection() {
    if (this.existingReport?.thirdParties) {
      const selectedThirdParties = this.existingReport.thirdParties;
      return !!selectedThirdParties.find(
        (dspId) => dspId === ThirdPartyDeliveryIds["Olo Dispatch"]
      );
    } else if (
      this.parametersForm.value.selectedThirdParties.find(
        (dspId) => dspId === ThirdPartyDeliveryIds["Olo Dispatch"]
      )
    )
      return true;
  }
  getReportLocations() {
    return this.locations
      .filter((location) => {
        return this.parametersForm
          .get("selectedLocations")
          .value.find((locationId) => locationId === location.locationId);
      })
      .sort((a, b) => (+a < +b ? -1 : 1));
  }

  getReportThirdParties() {
    return this.thirdParties.filter((tp) =>
      this.parametersForm
        .get("selectedThirdParties")
        .value.find((tpid) => tpid === tp.id)
    );
  }
  getReportMinDate() {
    return moment(this.parametersForm.get("startDate").value).toDate();
  }

  getReportMaxDate() {
    return moment(this.parametersForm.get("endDate").value).toDate();
  }

  getMaxEndDate() {
    if (this.parametersForm.get("startDate").valid) {
      return moment(this.parametersForm.get("startDate").value)
        .add(2, "month")
        .toDate();
    }
  }

  dateMinimum(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }
      const controlDate = moment(this.parametersForm.get("startDate").value);
      if (!controlDate.isValid()) {
        return null;
      }
      const validationDate = moment(date);
      return controlDate.isSameOrBefore(validationDate)
        ? null
        : {
            "date-minimum": {
              "date-minimum": validationDate.toDate(),
              actual: controlDate.toDate(),
            },
          };
    };
  }

  //Flattening functions
  getVariance(a: number, b: number, decimals = 2) {
    return +(a - b).toFixed(decimals);
  }
  isReportOutOfDate() {
    let outOfDate = false;
    const lastRunMoment = moment(this.existingReport.lastRun.toDate());
    if (
      this.existingReport.lastRun &&
      this.client3pdConfiguration &&
      this.client3pdConfiguration.dateUpdated
    ) {
      outOfDate = lastRunMoment.isBefore(
        this.client3pdConfiguration.dateUpdated.toDate()
      );
      if (!!outOfDate) {
        return outOfDate;
      }
    }
    if (
      this.existingReport.lastRun &&
      this.client3pdTransactionStatusConfigurations
    ) {
      for (let statusConfig of this.client3pdTransactionStatusConfigurations) {
        if (statusConfig && statusConfig.dateUpdated) {
          outOfDate = lastRunMoment.isBefore(
            moment(statusConfig.dateUpdated.toDate())
          );
          if (!!outOfDate) {
            break;
          }
        }
      }
      return outOfDate;
    }
  }
  openReportEditDialog() {
    const dialogRef = this.dialog.open(EditRecReportInfoDialogComponent, {
      data: {
        report: this.existingReport,
        reportGroups: this.reportGroups,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.existingReport.name = result.name;
        this.existingReport.group = result.group;
        this.dataUtility.existingReport = this.existingReport;
      }
    });
  }

  openShareReportDialog() {
    const excludedTeamMembers = this.reportShares.map(
      (reportShare) => reportShare.toUser
    );
    excludedTeamMembers.push(this.uiState.authUser.id);
    excludedTeamMembers.push(this.existingReport.creator);
    const dialogRef = this.dialog.open(TeamMemberSelectorDialogComponent, {
      panelClass: "invisible-panel-dialog",
      data: {
        client: this.uiState.client,
        report: this.existingReport,
        filterInternalUsers: this.uiState.authUser.internalRole ? false : true,
        excludedTeamMembers,
      },
    });
    dialogRef.afterClosed().subscribe(async (teamMemberIds) => {
      if (teamMemberIds) {
        await Promise.all(
          teamMemberIds.map((teamMemberId) => {
            const reportShare = new ReportShare();
            reportShare.client = this.uiState.client.id;
            reportShare.fromUser = this.uiState.authUser.id;
            reportShare.toUser = teamMemberId;
            reportShare.report = this.existingReport.id;
            reportShare.type = ReportGroupTypes.thirdPartyReconciliation;
            return this.afs
              .collection("reportShares")
              .add(reportShare.toJSONObject());
          })
        );
        this.snackBar.open(
          `${this.existingReport.name} shared with ${
            teamMemberIds.length
          } team member${teamMemberIds.length > 1 ? "s" : ""} successfully `,
          "Dismiss",
          { duration: 5000 }
        );
      }
    });
  }

  async downloadRecSummary(byEntity = false, sendEmails = false) {
    try {
      const progress = { progress: 0 };
      this.loadingService.isLoading(
        true,
        `Compiling report file ${sendEmails ? "and generating emails" : ""}...`,
        !byEntity ? progress : null
      );
      if (byEntity) {
        const entities = (
          await this.store
            .select((store) => store.uiState.clientEntities)
            .pipe(first())
            .toPromise()
        ).filter(
          (entity) =>
            !!this.getReportLocations().find(
              (location) => location.entity === entity.id
            )
        );
        const entitySelectionDialogRef = this.dialog.open(
          EntitySelectorDialogComponent,
          {
            panelClass: "invisible-panel-dialog",
            data: {
              entities,
            },
          }
        );
        const entitySelection = await entitySelectionDialogRef
          .afterClosed()
          .pipe(first())
          .toPromise();
        if (entitySelection && entitySelection.length > 0) {
          await this.exportUtility.compileAndDownloadReconciliationSummaryByEntity(
            entitySelection,
            this.reportExportSelectionControl.value,
            this.skipCheckFigures.value,
            progress,
            sendEmails,
            this.exportJeHeaderSelection.value
          );
        }
      } else {
        await this.exportUtility.compileAndDownloadReconciliationSummary(
          this.reportExportSelectionControl.value,
          false,
          this.skipCheckFigures.value,
          progress,
          null,
          sendEmails,
          this.exportJeHeaderSelection.value
        );
      }
      this.snackBar.open(
        `The reconciliation summary has been downloaded successfully ${
          sendEmails ? "and emails sent" : ""
        }!`,
        "Dismiss",
        { duration: 500 }
      );
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingService.isLoading(false);
    }
  }
  toggleReportCompletion(toggle: boolean) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Reconciliation Completion",
        message: `Are you sure you want change the completion status of this reconciliation?`,
        action: `Yes, ${!toggle ? "Undo" : ""} Complete`,
      },
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs
          .doc(`thirdPartyReports/${this.existingReport.id}`)
          .update({
            complete: toggle,
            dateUpdated: moment().toDate(),
          });
        this.existingReport["complete"] = toggle;
        if (!!toggle) {
          this.createNewReportVersion();
        }
        this.snackBar.open(
          `Successfully updated completion status of reconciliation - ${this.existingReport.name}`,
          "Dismiss",
          { duration: 5000 }
        );
      }
    });
  }
  toggleIntegrationTrigger(toggle: boolean) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Trigger Integration",
        message: `Please confirm trigger of the integration delivery.`,
        action: `Yes, ${!toggle ? "Reset" : "Trigger"} `,
      },
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        const date = moment().toDate();
        const updateDoc = {
          integrationTriggered: toggle,
          integrationTriggerDate: !!toggle ? date : null,
          dateUpdated: date,
        };
        await this.afs
          .doc(`thirdPartyReports/${this.existingReport.id}`)
          .update(updateDoc);
        this.existingReport.integrationTriggered = toggle;
        this.existingReport.integrationTriggerDate = !!toggle ? date : null;
        this.snackBar.open(
          `Successfully triggered integration delivery - ${this.existingReport.name}`,
          "Dismiss",
          { duration: 5000 }
        );
      }
    });
  }
  jumpToDailyDrillDown(selection: DrillDownSelection) {
    this.preDrillDownSelection = selection;
    this.dialog.open(RecReportViewDialogDialogComponent, {
      data: {
        reportType: "dailyDrillDown",
        preSelection: selection,
        dataUtility: this.dataUtility,
        exportUtility: this.exportUtility,
        filteringUtility: this.filteringUtility,
        thirdPartyReport: this.existingReport,
      },
    });
    // this.selectedReport.patchValue("Daily Drill Down");
    // this.selectedReport.updateValueAndValidity();
  }
  jumpToErrorCharges(selection: DrillDownSelection) {
    this.dialog.open(RecReportViewDialogDialogComponent, {
      data: {
        reportType: "errorChargeSummary",
        preSelection: selection,
        dataUtility: this.dataUtility,
        exportUtility: this.exportUtility,
        filteringUtility: this.filteringUtility,
        thirdPartyReport: this.existingReport,
      },
    });
    // this.preErrorChargeSelection = selection;
    // this.selectedReport.patchValue("Error Charges");
    // this.selectedReport.updateValueAndValidity();
  }
  jumpToTransactionAnalysis(selection: TADrillDownSelection) {
    this.dialog.open(RecReportViewDialogDialogComponent, {
      data: {
        reportType: "transactionAnalysis",
        preSelection: selection,
        dataUtility: this.dataUtility,
        exportUtility: this.exportUtility,
        filteringUtility: this.filteringUtility,
        thirdPartyReport: this.existingReport,
      },
    });
    // this.preTASelection = selection;
    // this.selectedReport.patchValue("Transaction Analysis");
    // this.selectedReport.updateValueAndValidity();
  }
  private async createNewReportVersion(
    byEntity?: boolean,
    sendEmails?: boolean
  ) {
    let versionName = `V${
      this.existingReport.versions?.length > 0
        ? this.existingReport.versions.length + 1
        : 1
    }`;
    const dialogRef = this.dialog.open(EditFieldDialogComponent, {
      data: {
        type: "input",
        label: "Version Name",
        value: versionName,
      },
    });
    dialogRef.afterClosed().subscribe(async (versionName) => {
      if (versionName) {
        const progress = { progress: 0 };
        this.loadingService.isLoading(
          true,
          `Compiling report file ${
            sendEmails ? "and generating emails" : ""
          }...`,
          !byEntity ? progress : null
        );
        const reportBlob =
          await this.exportUtility.compileAndDownloadReconciliationSummary(
            this.reportExportSelectionControl.value,
            true,
            this.skipCheckFigures.value,
            progress,
            null,
            false
            // sendEmails
          );
        const storagePath = `clients/${this.uiState.client.id}/3pd/reports`;
        const fileName = `${this.dataUtility.client.name}_REC_SUMMARY_${this.dataUtility.existingReport.name}-${versionName}.xlsx`;
        const filePath = `${storagePath}/${fileName}`;
        try {
          const uploadResult = await this.uploadDocumentService.uploadSingle(
            reportBlob,
            filePath,
            null,
            this.destroy$
          );
          const reportVersions = this.existingReport.versions
            ? Object.assign(this.existingReport.versions)
            : [];
          const newReportVersion = new ThirdPartyReportVersion();
          newReportVersion.filePath = uploadResult.downloadUrl;
          newReportVersion.versionName = versionName;
          reportVersions.push(newReportVersion.toJSONObject());
          await this.afs
            .doc(`thirdPartyReports/${this.existingReport.id}`)
            .update({
              versions: reportVersions,
            });
          await this.exportUtility.downloadFile(reportBlob, fileName);
          this.snackBar.open("New version created successfully!", "Dismiss", {
            duration: 5000,
          });
        } catch (e) {
          this.snackBar.open("Oops... something went wrong!", "Dismiss", {
            duration: 5000,
          });
        } finally {
          this.loadingService.isLoading(false);
        }
      }
    });
  }
  downloadLatestVersion() {
    const url = this.existingReport.versions.sort((a, b) =>
      a.dateCreated.seconds > b.dateCreated.seconds ? -1 : 1
    )[0].filePath;
    const downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", url);
    downloadAnchorNode.setAttribute("download", url);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }
}
