import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "lodash";
import { Papa } from "ngx-papaparse";
import {
  ThirdPartyReconciliationLocationData,
  ThirdPartyReportSummary,
  ThirdPartyReportFragmentVarianceAnalysisReport,
  TransactionAITypes,
  ThirdPartyPerformanceSummaryReport,
  ThirdPartyAnalyticsSummary,
} from "@deliver-sense-librarian/data-schema";
import { FormControl } from "@angular/forms";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import {
  ReconciliationDrillDownReportTypes,
  ReconciliationReportDatUtility,
} from "../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../utilities/reconciliation-report-filtering.utility";
@Component({
  selector: "app-analytics-summary-breakout",
  templateUrl: "./analytics-summary-breakout.component.html",
  styleUrls: ["./analytics-summary-breakout.component.scss"],
})
export class AnalyticsSummaryBreakoutComponent
  implements OnInit, AfterViewInit
{
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() exportUtility: ReconciliationReportExportUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public thirdPartyReportVarianceAnalysisReports: ThirdPartyReportFragmentVarianceAnalysisReport[];
  public filterLocationControl = new FormControl([]);
  public filterDspControl = new FormControl([]);
  public reportLocationSummaries: ThirdPartyReportSummary[];
  public loadingSummaryData: boolean;
  public thirdPartyPerformanceSummaryReports: ThirdPartyPerformanceSummaryReport[];
  analyticsSummaries: ThirdPartyAnalyticsSummary[] = [];
  constructor(
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private papa: Papa
  ) {}

  ngOnInit() {
    this.initializeTable();
  }
  ngAfterViewInit() {}
  private async initializeTable() {
    this.loadingSummaryData = true;
    this.analyticsSummaries =
      await this.dataUtility.compileAnalyticsBreakoutSummaries();
    this.loadingSummaryData = false;
  }
}
