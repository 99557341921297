import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { ThirdPartyReportFragmentTaxRateReport } from "@deliver-sense-librarian/data-schema";
import _ from "lodash";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import {
  ReconciliationDrillDownReportTypes,
  ReconciliationReportDatUtility,
} from "../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../utilities/reconciliation-report-filtering.utility";

@Component({
  selector: "app-tax-rate-analysis",
  templateUrl: "./tax-rate-analysis.component.html",
  styleUrls: ["./tax-rate-analysis.component.scss"],
})
export class TaxRateAnalysisComponent implements OnInit {
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() exportUtility: ReconciliationReportExportUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;

  thirdPartyReportFragmentTaxRateReports: ThirdPartyReportFragmentTaxRateReport[] =
    [];
  loadingTaxRateReports;
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.loadTaxRateAnalysisReports();
  }

  private async loadTaxRateAnalysisReports() {
    this.loadingTaxRateReports = true;

    this.thirdPartyReportFragmentTaxRateReports =
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.taxRateAnalysisReports
      );
    this.thirdPartyReportFragmentTaxRateReports.forEach((report) => {
      report["thirdPartyName"] = this.dataUtility.getThirdPartyName(
        report.thirdParty
      );
    });
    this.loadingTaxRateReports = false;
  }
}
