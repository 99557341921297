import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "lodash";
import { Papa } from "ngx-papaparse";
import {
  ThirdPartyPerformanceSummaryReport,
  ThirdPartyReportCanceledOrderDisputeLog,
  ThirdPartyReportFragmentTransactionAnalysis,
  ThirdPartyTransaction,
  TransactionAITypes,
} from "@deliver-sense-librarian/data-schema";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import {
  ReconciliationDrillDownReportTypes,
  ReconciliationReportDatUtility,
} from "../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../utilities/reconciliation-report-filtering.utility";
export class CanceledTransaction extends ThirdPartyTransaction {
  posOrderId: string;
  minutesToCancel: number;
  disputeEligible: boolean;
  disputeLog: ThirdPartyReportCanceledOrderDisputeLog;
}
@Component({
  selector: "app-canceled-orders-summary-report",
  templateUrl: "./canceled-orders-summary-report.component.html",
  styleUrls: ["./canceled-orders-summary-report.component.scss"],
})
export class CanceledOrdersSummaryComponent implements OnInit, AfterViewInit {
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() exportUtility: ReconciliationReportExportUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public thirdPartyPerformanceSummaryReports: ThirdPartyPerformanceSummaryReport[] =
    [];
  public canceledTransactions: CanceledTransaction[] = [];
  public loadingData: boolean;
  transactionAnalysisReports: ThirdPartyReportFragmentTransactionAnalysis[];
  canceledOrderDisputes: ThirdPartyReportCanceledOrderDisputeLog[];
  constructor(
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private papa: Papa
  ) {}

  ngOnInit() {
    this.initializeTable();
  }
  ngAfterViewInit() {}
  private async initializeTable() {
    this.loadingData = true;
    this.thirdPartyPerformanceSummaryReports = Object.assign(
      //NEW Instance so as not to modify utility doc
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.thirdPartyPerformanceSummaryReport
      )
    );
    this.canceledOrderDisputes =
      await this.dataUtility.fetchCanceledOrderDisputeLogs();
    this.transactionAnalysisReports = (
      await this.dataUtility.compileDailyDrillDownBreakoutReports(
        "transactionAnalysis"
      )
    ).filter((ta) => {
      return ta.type === TransactionAITypes.transactionNotPaidOut;
    });
    this.canceledTransactions = <CanceledTransaction[]>_.flatten(
      this.thirdPartyPerformanceSummaryReports.map((report) => {
        return report.canceledOrdersLog?.length > 0
          ? report.canceledOrdersLog
          : [];
      })
    );
    this.canceledTransactions.forEach((canceledTransaction) => {
      canceledTransaction["thirdPartyName"] =
        this.dataUtility.getThirdPartyName(canceledTransaction.thirdParty);
      const matchingInPOS = this.transactionAnalysisReports.find(
        (ta) => ta.thirdPartyTransactionId === canceledTransaction.transactionId
      );
      canceledTransaction.disputeLog = this.canceledOrderDisputes.find(
        (disputeLog) => disputeLog.transaction === canceledTransaction.id
      );
      if (matchingInPOS) {
        canceledTransaction.posOrderId = matchingInPOS.posTransactionId;
      }
      canceledTransaction.minutesToCancel =
        this.getMinutesToCancel(canceledTransaction);
      if (
        canceledTransaction.posOrderId &&
        canceledTransaction.minutesToCancel &&
        canceledTransaction.sale >= 10
      ) {
        canceledTransaction.disputeEligible = true;
      }
    });
    this.loadingData = false;
  }
  getMinutesToCancel(canceledTransaction) {
    const metaData = canceledTransaction.metadata;
    if (
      metaData?.length > 0 &&
      metaData.toLowerCase().indexOf("minutes to cancel") > -1
    ) {
      return Number(metaData.replace(/[^0-9\.]+/g, ""));
    }
    return null;
  }
}
