import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  Client,
  Client3pdTransactionStatusConfiguration,
  ThirdPartyTransactionStatus,
} from "@deliver-sense-librarian/data-schema";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatSnackBar } from "@angular/material/snack-bar";
import moment from "moment";

@Component({
  selector: "app-third-party-status-configurations-table",
  templateUrl: "./third-party-status-configurations-table.component.html",
  styleUrls: ["./third-party-status-configurations-table.component.scss"],
})
export class ThirdPartyStatusConfigurationsTableComponent implements OnInit {
  @Input()
  client3pdTransactionStatusConfigurations: Client3pdTransactionStatusConfiguration[] =
    [];
  @Input() filteredStatuses: ThirdPartyTransactionStatus[] = [];
  @Input() client: Client;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public updatingStatus = false;
  public displayedColumns: string[] = [
    "status",
    "includeInRec",
    "includeInTotals",
  ];
  constructor(
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore
  ) {}

  ngOnInit(): void {
    this.tableData = new MatTableDataSource(this.filteredStatuses);
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.cdr.detectChanges();
  }

  public getStatusText(statusId) {
    const status = this.filteredStatuses.find(
      (status) => status.id === statusId
    );
    return status ? status.status : "";
  }
  public clientIncludeStatusInRec(
    thirdPartyTransactionStatus: ThirdPartyTransactionStatus
  ) {
    const client3pdTransactionStatusConfiguration =
      this.client3pdTransactionStatusConfigurations.find(
        (config) => config.status === thirdPartyTransactionStatus.id
      );
    if (client3pdTransactionStatusConfiguration) {
      return client3pdTransactionStatusConfiguration.inPos;
    } else {
      return true;
    }
  }

  public clientIncludeStatusInTotals(
    thirdPartyTransactionStatus: ThirdPartyTransactionStatus
  ) {
    const client3pdTransactionStatusConfiguration =
      this.client3pdTransactionStatusConfigurations.find(
        (config) => config.status === thirdPartyTransactionStatus.id
      );
    if (client3pdTransactionStatusConfiguration) {
      return !!client3pdTransactionStatusConfiguration.inTotals;
    } else {
      return true;
    }
  }

  async updateClientThirdPartyStatusConfig(
    $event: MatCheckboxChange,
    row: ThirdPartyTransactionStatus,
    isTotalsConfig = false
  ) {
    this.updatingStatus = true;
    try {
      const clientThirdPartyStatus =
        this.client3pdTransactionStatusConfigurations.find(
          (client3pdStatusConfig) => client3pdStatusConfig.status === row.id
        );
      if (clientThirdPartyStatus) {
        const updateValue = isTotalsConfig
          ? { inTotals: $event.checked }
          : { inPos: $event.checked };
        await this.afs
          .doc(
            `client3pdTransactionStatusConfigurations/${clientThirdPartyStatus.id}`
          )
          .update({ ...updateValue, dateUpdated: moment().toDate() });
      } else {
        const client3pdStatusConfig =
          new Client3pdTransactionStatusConfiguration();
        client3pdStatusConfig.client = this.client.id;
        client3pdStatusConfig.thirdParty = row.thirdParty;
        client3pdStatusConfig.status = row.id;
        if (isTotalsConfig) {
          client3pdStatusConfig.inTotals = $event.checked;
        } else {
          client3pdStatusConfig.inPos = $event.checked;
        }
        await this.afs
          .collection(`client3pdTransactionStatusConfigurations`)
          .add(client3pdStatusConfig.toJSONObject());
      }
      this.snackBar.open(
        `Updated status configuration successfully`,
        "Dismiss",
        { duration: 5000 }
      );
      this.updatingStatus = false;
    } catch (e) {
      console.error(e.message);
      this.snackBar.open(
        `Oops... something when wrong. Please refresh and try again.`,
        "Dismiss",
        { duration: 5000 }
      );
      this.updatingStatus = false;
    }
  }
}
