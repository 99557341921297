import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewChild,
} from "@angular/core";
import {
  Client,
  ClientInvoice,
  DeliverSenseModule,
  ClientModule,
} from "@deliver-sense-librarian/data-schema";
import { FormControl, Validators } from "@angular/forms";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { from, Subject } from "rxjs";
import { combineAll, takeUntil, map } from "rxjs/operators";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { AngularFirestore } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";
import * as moment from "moment";
import * as _ from "lodash";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-client-invoices",
  templateUrl: "./client-invoices.component.html",
  styleUrls: ["./client-invoices.component.scss"],
})
export class ClientInvoicesComponent implements OnInit {
  @Input() client: Client;
  @Input() dsModules: DeliverSenseModule[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    "period",
    "status",
    "created",
    "due",
    "subtotal",
    "tax",
    "discount",
    "amountDue",
    "amountPaid",
    "amountRemaining",
    "payNow",
    "download",
  ];
  public startDate = new FormControl(null, Validators.required);
  public endDate = new FormControl(null, [Validators.required]);
  public invoices: ClientInvoice[] = [];
  public editinvoice: ClientInvoice = null;
  private destroy$ = new Subject();
  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.store
      .select((store) => store.uiState)
      .subscribe((uiState$) => {
        if (uiState$.authUser && uiState$.client) {
          this.client = uiState$.client;
          this.getInvoices();
        }
      });
  }
  getInvoices() {
    this.invoices = null;
    this.afs
      .collection("clientInvoices", (ref) =>
        ref
          .where("client", "==", this.client.id)
          .orderBy("invoicedDate", "desc")
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((invoices$) => {
        this.invoices = FirestoreUtilities.mapToType(invoices$);
        this.tableData = new MatTableDataSource(this.invoices);
        this.tableData.paginator = this.paginator;
        this.tableData.sort = this.sort;
        this.cdr.detectChanges();
      });
  }
  getDeliverSenseModule(clientModule: ClientModule) {
    if (clientModule) {
      const module = this.dsModules.find(
        (dsModule) => dsModule.id === clientModule.module
      );
      return module ? module.name : "";
    }
    return "";
  }
  getMaxEndDate() {
    if (this.startDate.valid) {
      return moment(this.startDate.value).add(3, "months").toDate();
    }
  }
  payNow(clientInvoice: ClientInvoice) {
    window.open(clientInvoice.stripeInvoice.hosted_invoice_url, "_blank");
  }
  getFormattedDate(dateUnix) {
    return dateUnix ? moment.unix(dateUnix).format("M/D/YYYY") : "";
  }
}
