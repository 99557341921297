import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Client } from "@deliver-sense-librarian/data-schema";
import { take, takeUntil, first } from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { ReplaySubject, Subject } from "rxjs";
import { MatSelect } from "@angular/material/select";
import * as _ from "lodash";
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
  selector: "app-client-search",
  templateUrl: "./client-search.component.html",
  styleUrls: ["./client-search.component.scss"],
})
export class ClientSearchComponent implements OnInit {
  @Input() clients: Client[];
  @Input() selectedClient: FormControl;
  @Input() multiple = true;
  @ViewChild("multiSelect", { static: true }) multiSelect: MatSelect;
  public clientsFilterCtrl: FormControl = new FormControl();
  public filteredClients: ReplaySubject<Client[]> = new ReplaySubject<Client[]>(
    1
  );
  private destroy$ = new Subject();
  constructor(private afs: AngularFirestore) {}

  ngOnInit() {
    this.initializeComponent();
  }

  async initializeComponent() {
    this.filteredClients.next(this.clients.slice());
    this.clientsFilterCtrl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterClients();
      });
    this.filteredClients.next(this.clients.slice());
    this.clientsFilterCtrl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterClients();
      });
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredClients
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe((val) => {
        if (selectAllValue) {
          this.selectedClient.patchValue(
            val.map((thirdParty) => thirdParty.id)
          );
          this.selectedClient.updateValueAndValidity();
        } else {
          this.selectedClient.patchValue([]);
          this.selectedClient.updateValueAndValidity();
        }
      });
  }

  filterClients() {
    if (!this.clients) {
      return;
    }
    // get the search keyword
    let search = this.clientsFilterCtrl.value;
    if (!search) {
      this.filteredClients.next(this.clients.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredClients.next(
      this.clients.filter(
        (thirdParty) =>
          thirdParty.name.toLowerCase().indexOf(search) > -1 ||
          thirdParty.id.indexOf(search) > -1
      )
    );
  }

  getClientName(thirdPartyId: string) {
    const thirdParty = this.clients?.find((dsp) => dsp.id === thirdPartyId);
    return thirdParty ? thirdParty.name : "";
  }
}
