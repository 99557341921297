import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AdminLayoutComponent } from "./admin-layout.component";
import { MaterialModule } from "../../material.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { FlexLayoutModule } from "@angular/flex-layout";
import { DsSharedModule } from "../../shared/ds-shared.module";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { HeaderModule } from "../../components/structural/header/header.module";
import { SidebarModule } from "../../components/structural/sidebar/sidebar.module";
import { AccordionModule } from "../../components/structural/menu-accordion/accordion.module";
import { NotificationPanelModule } from "../../components/structural/notification-panel/notification-panel.module";
import { DataUploadModule } from "../../components/data-upload/data-upload.module";

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    DsSharedModule,
    PerfectScrollbarModule,
    HeaderModule,
    SidebarModule,
    AccordionModule,
    NotificationPanelModule,
    DataUploadModule,
  ],
  declarations: [AdminLayoutComponent],
  exports: [AdminLayoutComponent],
  providers: [],
})
export class AdminLayoutModule {}
