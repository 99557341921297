import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { downloadDataAsFile } from "app/shared/ds-constant";
import moment from "moment";
import { Papa } from "ngx-papaparse";
import { ThirdPartyCategoryReport } from "@deliver-sense-librarian/data-schema";
import { AngularFirestore } from "@angular/fire/firestore";
import firebase from "firebase/app";
import { first } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { TransactionsDialogComponent } from "app/dialogs/transactions-dialog/transactions-dialog.component";
import * as _ from "lodash";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { FormControl } from "@angular/forms";
import { ReconciliationReportDatUtility } from "../../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../../utilities/reconciliation-report-filtering.utility";
import { AfterViewInit } from "@angular/core";

@Component({
  selector: "app-status-drill-down-table",
  templateUrl: "./status-drill-down-table.component.html",
  styleUrls: ["./status-drill-down-table.component.scss"],
})
export class StatusDrillDownTableComponent implements OnInit, AfterViewInit {
  @Input() data: ThirdPartyCategoryReport[];
  @Input() reportTitle: string;
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;

  @Output() clearResults = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    "location",
    "thirdParty",
    "categoryName",
    "transactions",
    "netSales",
    "tip",
    "netTax",
    "mfTax",
    "deliveryFee",
    "promoFee",
    "pickupFee",
    "cateringFee",
    "totalRemitted",
  ];
  public filter3pdsControl = new FormControl([]);
  public filterLocationsControl = new FormControl([]);
  constructor(
    private cdr: ChangeDetectorRef,
    private afs: AngularFirestore,
    private papa: Papa,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private loadingService: LoadingDialogService
  ) {}

  ngOnInit(): void {
    this.tableData = new MatTableDataSource(this.data);
  }
  ngAfterViewInit(): void {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.filteringUtility.set3pdAndLocationFilterPredicate(
      this.tableData,
      this.filter3pdsControl,
      this.filterLocationsControl
    );
    this.cdr.detectChanges();
  }
  exportReport() {
    const data = Object.assign(
      this.tableData.data.map((data) => {
        delete data.transactions;
        return {
          location: data.location,
          categoryName: data.categoryName,
          netSales: data.netSales,
          tip: data.tip,
          netTax: data.netTax,
          taxRemitted: data.taxRemitted,
          deliveryFee: data.deliveryFee,
          promoFee: data.promoFee,
          pickupFee: data.pickupFee,
          cateringFee: data.cateringFee,
          totalRemitted: data.totalRemitted,
        };
      })
    );
    const results = this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const fileName = `${this.reportTitle}_Status-Drill-Down_${moment().format(
      "M/D/YY-HH:mm:ss"
    )}`;
    downloadDataAsFile(results, fileName, "csv");
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  public async openTransactionViewer(report: ThirdPartyCategoryReport) {
    const transactionIds = report.transactions as string[];
    if (transactionIds.length > 0) {
      this.loadingService.isLoading(true, "Finding transactions...");
      const transactionIdBatches = _.chunk(transactionIds, 10);
      try {
        const transactions = _.flatten(
          await Promise.all(
            transactionIdBatches.map((transactionIds) => {
              return this.afs
                .collection("thirdPartyTransactions", (ref) =>
                  ref.where(
                    //@ts-ignore
                    firebase.firestore.FieldPath.documentId(),
                    "in",
                    transactionIds
                  )
                )
                .snapshotChanges()
                .pipe(first())
                .toPromise();
            })
          )
        );
        this.loadingService.isLoading(false);
        this.dialog.open(TransactionsDialogComponent, {
          panelClass: "invisible-panel-dialog",
          data: {
            transactions: FirestoreUtilities.mapToType(transactions),
          },
        });
      } catch (e) {
        this.loadingService.isLoading(false);
        this.snackBar.open(
          `Could not find transactions. Please refresh and try again`,
          "Dismiss",
          { duration: 5000 }
        );
      }
    }
  }
}
