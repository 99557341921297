import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import {
  ThirdParty,
  UserRoles,
  Location,
  ThirdPartyReport,
  ThirdPartyReportFragment,
  ThirdPartyTransaction,
  PosTransaction,
  ThirdPartyLocationDayDrillDown,
  ThirdPartyReportFragmentDailyDrillDownReport,
} from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { UiState } from "app/redux/custom-states/uiState/ui-state";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import * as _ from "lodash";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);
import { Subject, combineLatest } from "rxjs";
import { takeUntil, distinctUntilChanged, first } from "rxjs/operators";

export class TrendAnalysisParameters {
  selectedThirdParty: string;
  selectedLocation: string;
  trendType:
    | "Sales Variance"
    | "Tax Variance"
    | "Error Charges (Count)"
    | "Error Charges (Amount)";
  startDate: Date;
  endDate: Date;
}

@Component({
  selector: "app-trend-analysis",
  templateUrl: "./trend-analysis.component.html",
  styleUrls: ["./trend-analysis.component.scss"],
})
export class TrendAnalysisComponent implements OnInit {
  private destroy$ = new Subject();
  public uiState: UiState;
  public trendAnalysisSelectionControl = new FormControl();
  public trendAnalysisTypes = [
    "Sales Variance",
    "Tax Variance",
    "Tax Rate Variance",
    "Remittance Variance",
    "Error Charges (Count)",
    "Error Charges (Amount)",
  ];
  analysisData: any;
  selectedTrendType: any;
  public parametersForm: FormGroup;
  thirdParties: ThirdParty[];
  locations: Location[];

  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private activatedRoute: ActivatedRoute
  ) {
    this.setupParametersForm();
  }

  ngOnInit() {
    this.store
      .select((store) => store.uiState)
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((a: UiState, b: UiState) => {
          const aComp = {
            client: a.client,
            user: a.authUser,
            thirdParties: a.clientThirdParties,
            locations: a.clientLocations,
          };
          const bComp = {
            client: b.client,
            user: b.authUser,
            thirdParties: b.clientThirdParties,
            locations: b.clientLocations,
          };
          return JSON.stringify(aComp) === JSON.stringify(bComp);
        })
      )
      .subscribe((uiState$: UiState) => {
        if (
          uiState$.authUser &&
          uiState$.client &&
          uiState$.clientThirdParties?.length > 0 &&
          uiState$.clientLocations
        ) {
          this.uiState = uiState$;
          this.thirdParties = this.uiState.clientThirdParties
            .map((clientThirdParty) => clientThirdParty.thirdParty)
            .filter((tp) => !!tp);
          this.getRelatedResources();
        }
      });
  }
  private getRelatedResources() {
    FirestoreUtilities.getUserAccessibleResourcesOfType(
      "locations",
      this.afs,
      this.uiState.locations,
      [UserRoles.admin, UserRoles.contributor]
    ).subscribe((locations$) => {
      this.locations = locations$
        .filter((location) => !!location.active)
        .sort((a, b) => (a.locationId < b.locationId ? -1 : 1));
    });
  }
  private setupParametersForm() {
    this.parametersForm = this.fb.group({
      selectedThirdParty: new FormControl("", Validators.required),
      selectedLocation: new FormControl("", Validators.required),
      trendType: new FormControl("", Validators.required),
      startDate: new FormControl(new Date("2021-04-01"), Validators.required),
      endDate: new FormControl(new Date("2021-07-01"), Validators.required),
    });
  }
  getReportMinDate() {
    return moment(this.parametersForm.get("startDate").value).toDate();
  }

  getReportMaxDate() {
    return moment(this.parametersForm.get("endDate").value).toDate();
  }

  getMaxEndDate() {
    if (this.parametersForm.get("startDate").valid) {
      const maxOfRange = moment(this.parametersForm.get("startDate").value).add(
        5,
        "month"
      );
      return moment().startOf("day").isBefore(maxOfRange)
        ? moment().startOf("day").toDate()
        : maxOfRange.toDate();
    }
  }

  dateMinimum(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }
      const controlDate = moment(this.parametersForm.get("startDate").value);
      if (!controlDate.isValid()) {
        return null;
      }
      const validationDate = moment(date);
      return controlDate.isSameOrBefore(validationDate)
        ? null
        : {
            "date-minimum": {
              "date-minimum": validationDate.toDate(),
              actual: controlDate.toDate(),
            },
          };
    };
  }
  getThirdPartyName(thirdPartyId: string) {
    if (thirdPartyId) {
      const tp = this.thirdParties.find(
        (_tp) => !!_tp && _tp.id === thirdPartyId
      );
      return tp ? tp.name : "";
    }
    return "";
  }

  getLocationName(locationId: string) {
    const location = this.locations.find((l) => l.locationId === locationId);
    return location ? location.name : "";
  }
  runReport() {
    if (this.parametersForm.valid) {
      this.analysisData = null;
      setTimeout(() => {
        const formValues: TrendAnalysisParameters = this.parametersForm.value;
        this.getTrendData(formValues);
      });
    } else {
      this.snackBar.open(
        "Please select all required parameters before running the report.",
        "Dismiss",
        { duration: 5000 }
      );
    }
  }
  private async getTrendData(parameters: TrendAnalysisParameters) {
    if (this.parametersForm.valid) {
      this.loadingService.isLoading(true, "Running analysis...");
      const clientId = this.uiState.client.id;
      const thirdPartyId = parameters.selectedThirdParty;
      const [
        client3pdConfiguration$,
        client3pdTransactionStatusConfigurations$,
        thirdPartyStatuses$,
      ] = await Promise.all([
        this.afs
          .collection("client3pdConfigurations", (ref) =>
            ref.where("client", "==", clientId)
          )
          .snapshotChanges()
          .pipe(first())
          .toPromise(),
        this.afs
          .collection("client3pdTransactionStatusConfigurations", (ref) =>
            ref
              .where("client", "==", clientId)
              .where("thirdParty", "==", thirdPartyId)
          )
          .snapshotChanges()
          .pipe(first())
          .toPromise(),
        this.afs
          .collection("thirdPartyTransactionStatuses", (ref) =>
            ref.where("thirdParty", "==", thirdPartyId)
          )
          .snapshotChanges()
          .pipe(first())
          .toPromise(),
      ]);
      const start = moment(parameters.startDate).toDate();
      const end = moment(parameters.endDate).toDate();
      const client3pdConfigurations = FirestoreUtilities.mapToType(
        client3pdConfiguration$
      );
      const client3pdTransactionStatusConfigurations =
        FirestoreUtilities.mapToType(client3pdTransactionStatusConfigurations$);
      const thirdPartyTransactionStatuses =
        FirestoreUtilities.mapToType(thirdPartyStatuses$);
      // this.afs.collection('thirdPartyReportFragments', ref => ref
      //   .where('client', '==', this.uiState.client.id)
      //   .where('startDate', ">=", start)
      //   .where('startDate', "<=", end)
      //   .where('thirdParty', '==', parameters.selectedThirdParty)
      //   .where('locations', 'array-contains', parameters.selectedLocation)
      // )
      combineLatest([
        this.afs
          .collection("posTransactions", (ref) =>
            ref
              .where("client", "==", this.uiState.client.id)
              .where("date", ">=", start)
              .where("date", "<=", end)
              .where("location", "==", parameters.selectedLocation)
              .where("account", "==", parameters.selectedThirdParty)
          )
          .snapshotChanges(),
        this.afs
          .collection("thirdPartyTransactions", (ref) =>
            ref
              .where("client", "==", this.uiState.client.id)
              .where("date", ">=", start)
              .where("date", "<=", end)
              .where("location", "==", parameters.selectedLocation)
              .where("thirdParty", "==", parameters.selectedThirdParty)
          )
          .snapshotChanges(),
      ])
        .pipe(first())
        .subscribe(
          async ([posTransactionsResult, thirdPartyTransactionsResult]) => {
            this.loadingService.isLoading(false);
            const allTransactions: { posTransactions; tpdTransactions } = {
              tpdTransactions: FirestoreUtilities.mapToType(
                thirdPartyTransactionsResult
              ),
              posTransactions: FirestoreUtilities.mapToType(
                posTransactionsResult
              ),
            };
            const dailyDrillDowns =
              await this.createLocationDailyDrillDownReports(
                allTransactions,
                parameters.selectedThirdParty,
                parameters.selectedLocation,
                start,
                end,
                thirdPartyTransactionStatuses,
                client3pdTransactionStatusConfigurations
              );
            // const thirdPartyReportFragments = FirestoreUtilities.mapToType(thirdPartyReportFragmentsQueryResults);
            switch (parameters.trendType) {
              case "Sales Variance":
                this.analysisData = this.getChartData(
                  dailyDrillDowns,
                  "daySalesVariance",
                  "Sales Variance By Day"
                );
                break;
              case "Tax Variance":
                this.analysisData = this.getChartData(
                  dailyDrillDowns,
                  "dayTaxVariance",
                  "Sales Variance By Day"
                );
                break;
              case "Error Charges (Count)":
                this.analysisData = this.getChartData(
                  dailyDrillDowns,
                  "errorChargeCount",
                  "Error Charges Count By Day"
                );
                break;
              case "Error Charges (Amount)":
                this.analysisData = this.getChartData(
                  dailyDrillDowns,
                  "errorChargeTotal",
                  "Error Charges Amount By Day"
                );
                break;
            }
          },
          (e) => {
            console.error(e);
            this.loadingService.isLoading(false);
            this.snackBar.open(
              "Oops.. something went wrong. Please refresh and try again or contact support",
              "Dismiss",
              { duration: 5000 }
            );
          }
        );
    }
  }
  private getChartData(
    dailyDrillDowns: ThirdPartyLocationDayDrillDown[],
    field: string,
    label: string
  ) {
    const variancePoints: { date: string; dataPoint: number }[] =
      dailyDrillDowns.map((dayDrillDown: ThirdPartyLocationDayDrillDown) => {
        return {
          date: moment(dayDrillDown.date).format("MM/DD/YY"),
          dataPoint: dayDrillDown[field],
        };
      });

    const chartData = {
      data: variancePoints.map((variancePoint) => variancePoint.dataPoint),
      label,
    };
    const chartLabels = variancePoints.map((variancePoint) => {
      return variancePoint.date;
    });
    return {
      chartData,
      chartLabels,
    };
  }

  private createLocationDailyDrillDownReports(
    allTransactions: {
      posTransactions: PosTransaction[];
      tpdTransactions: ThirdPartyTransaction[];
    },
    thirdPartyId: string,
    locationId: string,
    startDate,
    endDate,
    thirdPartyTransactionStatuses,
    client3pdTransactionStatusConfigurations
  ): ThirdPartyLocationDayDrillDown[] {
    const range = moment.range(startDate, endDate);
    const dates = Array.from(range.by("days"));
    return dates
      .map((date) => {
        const startOfDay = moment(date).startOf("day");
        const endOfDay = moment(date).endOf("day");
        const tpdTransactions = allTransactions.tpdTransactions
          .filter((transaction) => transaction.location === locationId)
          .filter((transaction) =>
            this.isInDay(transaction.date.toDate(), startOfDay, endOfDay)
          );
        const posTransactions = allTransactions.posTransactions
          .filter((transaction) => transaction.location === locationId)
          .filter((transaction) =>
            this.isInDay(transaction.date.toDate(), startOfDay, endOfDay)
          );
        const statusFilteredTpdTransactions =
          this.filterTpdTransactionsByClientConfigs(
            thirdPartyTransactionStatuses,
            client3pdTransactionStatusConfigurations,
            tpdTransactions
          );
        const tpdTransactionsFilteredOut = _.xorBy(
          tpdTransactions,
          statusFilteredTpdTransactions,
          "id"
        );
        const adjustedStatusFilteredTpdTransactions =
          statusFilteredTpdTransactions.filter((transaction) => {
            return transaction.sale + transaction.saleCorrection !== 0;
          });
        const thirdParty = this.thirdParties.find(
          (tp) => tp.id === thirdPartyId
        );
        const errorChargeTransactions =
          adjustedStatusFilteredTpdTransactions.filter((transaction) => {
            const errorChargeStatusField = this.isTransactionErrorCharge(
              transaction,
              thirdParty
            );
            if (errorChargeStatusField) {
              transaction["errorChargeStatusField"] = errorChargeStatusField;
              return transaction;
            }
            return false;
          });
        let errorChargeSum = 0;
        if (errorChargeTransactions && errorChargeTransactions.length > 0) {
          errorChargeSum = errorChargeTransactions.reduce(
            (sum, transaction: ThirdPartyTransaction) => {
              let amount;
              switch (thirdParty.code) {
                case "dd":
                case "tgif-cc":
                  amount =
                    (+transaction.saleCorrection
                      ? +transaction.saleCorrection
                      : 0) * -1;
                  break;
                default:
                  amount = (+transaction.sale ? +transaction.sale : 0) * -1;
              }
              return (sum += amount);
            },
            0
          );
        }
        const tpdDaySales = statusFilteredTpdTransactions.reduce(
          (sum, transaction: ThirdPartyTransaction) => {
            return (sum +=
              transaction.sale +
              (transaction.saleCorrection ? transaction.saleCorrection : 0));
          },
          0
        );
        const tpdDayTax = statusFilteredTpdTransactions.reduce(
          (sum, transaction: ThirdPartyTransaction) => {
            return (sum +=
              transaction.tax +
              (transaction.taxCorrection ? transaction.taxCorrection : 0));
          },
          0
        );
        const posDaySales = posTransactions.reduce(
          (sum, transaction: PosTransaction) => {
            return (sum +=
              transaction.sale +
              (transaction.saleCorrection ? transaction.saleCorrection : 0));
          },
          0
        );
        const posDayTax = posTransactions.reduce(
          (sum, transaction: PosTransaction) => {
            return (sum +=
              transaction.tax +
              (transaction.taxCorrection ? transaction.taxCorrection : 0));
          },
          0
        );
        const excludedThirdPartySales = tpdTransactionsFilteredOut.reduce(
          (sum, transaction: ThirdPartyTransaction) => {
            return (sum +=
              transaction.sale +
              (transaction.saleCorrection ? transaction.saleCorrection : 0));
          },
          0
        );
        const excludedThirdPartyTax = tpdTransactionsFilteredOut.reduce(
          (sum, transaction: ThirdPartyTransaction) => {
            return (sum +=
              transaction.tax +
              (transaction.taxCorrection ? transaction.taxCorrection : 0));
          },
          0
        );
        const thirdPartyLocationDayDrillDown =
          new ThirdPartyLocationDayDrillDown();
        thirdPartyLocationDayDrillDown.date = moment(date)
          .startOf("day")
          .toDate();
        thirdPartyLocationDayDrillDown.location = locationId;
        thirdPartyLocationDayDrillDown.thirdParty = thirdPartyId;
        thirdPartyLocationDayDrillDown.thirdPartySales =
          +tpdDaySales.toFixed(2);
        thirdPartyLocationDayDrillDown.thirdPartyTax = +tpdDayTax.toFixed(2);
        thirdPartyLocationDayDrillDown.posSales = +posDaySales.toFixed(2);
        thirdPartyLocationDayDrillDown.posTax = +posDayTax.toFixed(2);
        thirdPartyLocationDayDrillDown.daySalesVariance = +(
          thirdPartyLocationDayDrillDown.posSales -
          thirdPartyLocationDayDrillDown.thirdPartySales
        ).toFixed(2);
        thirdPartyLocationDayDrillDown.dayTaxVariance = +(
          thirdPartyLocationDayDrillDown.posTax -
          thirdPartyLocationDayDrillDown.thirdPartyTax
        ).toFixed(2);
        thirdPartyLocationDayDrillDown.excludedThirdPartySales =
          +excludedThirdPartySales.toFixed(2);
        thirdPartyLocationDayDrillDown.excludedThirdPartyTax =
          +excludedThirdPartyTax.toFixed(2);
        thirdPartyLocationDayDrillDown.thirdPartyTransactionCount =
          statusFilteredTpdTransactions.length;
        thirdPartyLocationDayDrillDown.thirdPartyStatusFilteredTransactions =
          tpdTransactionsFilteredOut.map((transaction) => transaction.id);
        thirdPartyLocationDayDrillDown.posTransactionCount =
          posTransactions.length;
        thirdPartyLocationDayDrillDown["errorChargeCount"] =
          errorChargeTransactions.length;
        thirdPartyLocationDayDrillDown["errorChargeTotal"] = errorChargeSum;
        return thirdPartyLocationDayDrillDown;
      })
      .sort(
        (
          a: ThirdPartyLocationDayDrillDown,
          b: ThirdPartyLocationDayDrillDown
        ) => {
          return moment(a.date).isBefore(moment(b.date)) ? -1 : 1;
        }
      );
  }

  private isTransactionErrorCharge(
    transaction: ThirdPartyTransaction,
    thirdParty: ThirdParty
  ) {
    let isErrorChargeField = "";
    if (thirdParty.errorChargeCodes && thirdParty.errorChargeCodes.length > 0) {
      for (const errorChargeCode of thirdParty.errorChargeCodes) {
        if (transaction[errorChargeCode.field] === errorChargeCode.code) {
          isErrorChargeField = errorChargeCode.field;
          break;
        }
      }
      return isErrorChargeField;
    }
    return isErrorChargeField;
  }
  private isInDay(date: Date, startOfDay, endOfDay) {
    return (
      moment(date).isSameOrAfter(startOfDay) &&
      moment(date).isSameOrBefore(endOfDay)
    );
  }
  private filterTpdTransactionsByClientConfigs(
    thirdPartyTransactionStatuses,
    client3pdTransactionStatusConfigurations,
    tpdTransactions
  ) {
    return tpdTransactions.filter((tpdTransaction: ThirdPartyTransaction) => {
      // get the global third party transaction status that is equal to the text value in status of the transaction
      const thirdPartyTransactionStatus = thirdPartyTransactionStatuses.find(
        (tpts) =>
          tpts.status === tpdTransaction.status &&
          tpts.thirdParty === tpdTransaction.thirdParty
      );
      if (thirdPartyTransactionStatus) {
        // if a global transaction status is found check if the client has a setting for the transaction status
        const matching3pdTransactionsConfiguration =
          client3pdTransactionStatusConfigurations.find(
            (config) =>
              config.thirdParty === tpdTransaction.thirdParty &&
              config.status === thirdPartyTransactionStatus.id
          );
        if (matching3pdTransactionsConfiguration) {
          // if a matching client transaction status configuration is found return the boolean value of "inPos" to filter
          return matching3pdTransactionsConfiguration.inPos === false
            ? false
            : true;
        }
      }
      // if both status levels are not found return the transaction to be included in the reconciliation
      return true;
    });
  }
}
