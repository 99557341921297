import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import {
  ReportGroup,
  ReportGroupTypes,
  TrendAnalysisReport,
  TrendAnalysisFragment,
  UserView,
} from "@deliver-sense-librarian/data-schema";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { from, of, Subject } from "rxjs";
import { Store } from "@ngrx/store";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AngularFirestore } from "@angular/fire/firestore";
import { combineAll, takeUntil, map, catchError } from "rxjs/operators";
import { FirestoreUtilities } from "../../../../utilities/firestore-utilities";
import { ConfirmDialogComponent } from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import { NewTpdReportDialogComponent } from "../../../../dialogs/new-tpd-report-dialog/new-tpd-report-dialog.component";
import { Router, ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { UiState } from "../../../../redux/custom-states/uiState/ui-state";
import { FormControl } from "@angular/forms";
import { LoadingDialogService } from "../../../../services/loading-dialog.service";

@Component({
  selector: "app-trend-analysis-reports",
  templateUrl: "./trend-analysis-reports.component.html",
  styleUrls: ["./trend-analysis-reports.component.scss"],
})
export class TrendAnalysisReportsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<TrendAnalysisReport>;
  public displayedColumns: string[] = [
    "name",
    "creator",
    "startDate",
    "endDate",
    "dateUpdated",
    "status",
    "delete",
  ];
  public selectedReportGroup = new FormControl();
  public reports: TrendAnalysisReport[] = [];
  public reportGroups: ReportGroup[] = [];
  public newGroupNameControl = new FormControl();
  public editGroupNameControl = new FormControl();
  public creatingNewGroup = false;
  public editingGroup = false;
  private destroy$ = new Subject();
  private creators: UserView[] = [];
  private uiState$: UiState;
  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private loadingService: LoadingDialogService,
    private afs: AngularFirestore,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.store
      .select((store) => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((uiState$) => {
        if (uiState$.authUser && uiState$.client) {
          this.uiState$ = uiState$;
          this.getReportGroups();
          this.listenForGroupChange();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public getCreatorInfo(doc$) {
    const creator = this.creators.find((user) => user.id === doc$.creator);
    return creator
      ? creator.email
        ? creator.email
        : `${creator.firstName} ${creator.lastName}`
      : "N/A";
  }

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  editSelectedGroup() {
    const groupId = this.selectedReportGroup.value;
    const group = this.reportGroups.find((group) => group.id === groupId);
    this.editGroupNameControl.patchValue(group.name);
    this.editGroupNameControl.updateValueAndValidity();
    this.editingGroup = true;
  }
  async updateGroupName() {
    if (this.editGroupNameControl.valid) {
      try {
        const newName = this.editGroupNameControl.value;
        const groupId = this.selectedReportGroup.value;
        await this.afs.doc(`reportGroups/${groupId}`).update({
          name: newName,
        });
        this.snackBar.open("Group name updated successfully", "Dismiss", {
          duration: 5000,
        });
        this.editingGroup = false;
        this.editGroupNameControl.reset();
      } catch (e) {
        console.error(e);
        this.snackBar.open(
          "Oops... something went wrong. Please refresh and try again.",
          "Dismiss",
          { duration: 5000 }
        );
      }
    } else {
      this.snackBar.open("Please enter a name for the group", "Dismiss", {
        duration: 5000,
      });
    }
  }

  public cancelEditGroup() {
    this.editingGroup = false;
    this.editGroupNameControl.reset();
  }

  public cancelCreateGroup() {
    this.creatingNewGroup = false;
    this.newGroupNameControl.reset();
  }
  public async createReportGroup() {
    if (this.newGroupNameControl.valid) {
      const reportGroup = new ReportGroup();
      reportGroup.name = this.newGroupNameControl.value;
      reportGroup.client = this.uiState$.client.id;
      reportGroup.creator = this.uiState$.authUser.id;
      reportGroup.type = ReportGroupTypes.trendAnalysis;
      const newGroup = await this.afs
        .collection("reportGroups")
        .add(reportGroup.toJSONObject());
      this.snackBar.open("New group created successfully", "Dismiss", {
        duration: 5000,
      });
      this.creatingNewGroup = false;
      this.newGroupNameControl.patchValue(newGroup.id);
      this.newGroupNameControl.updateValueAndValidity();
    } else {
      this.snackBar.open(
        "Please provide a name for the report group.",
        "Dismiss",
        { duration: 5000 }
      );
    }
  }
  public deleteGroup() {
    const trendAnalysisGroupToDelete = this.selectedReportGroup.value;
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Delete Group",
        message: `Are you sure you want to delete this report group? All reports in the group will be set to "Un-Grouped"`,
        action: "Yes, Delete",
      },
    });
    confirmDialog.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        const reportGroupUpdateBatch = this.afs.firestore.batch();
        this.reports.forEach((report) => {
          reportGroupUpdateBatch.update(
            this.afs.doc(`trendAnalysisReports/${report.id}`).ref,
            { group: "ungrouped" }
          );
        });
        await reportGroupUpdateBatch.commit();
        await this.afs
          .doc(`reportGroups/${trendAnalysisGroupToDelete}`)
          .delete();
        this.snackBar.open("Successfully deleted grouping", "Dismiss", {
          duration: 5000,
        });
        this.selectedReportGroup.patchValue("ungrouped");
        this.selectedReportGroup.updateValueAndValidity();
      }
    });
  }

  private getReportGroups() {
    this.afs
      .collection("reportGroups", (ref) =>
        ref
          .where("client", "==", this.uiState$.client.id)
          .where("creator", "==", this.uiState$.authUser.id)
          .where("type", "==", ReportGroupTypes.trendAnalysis)
          .orderBy("dateUpdated", "desc")
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((reportGroups$) => {
        this.reportGroups = <ReportGroup[]>(
          FirestoreUtilities.mapToType(reportGroups$)
        );
        this.reportGroups.push(
          new ReportGroup({
            name: "Un-Grouped",
            id: "ungrouped",
            type: ReportGroupTypes.trendAnalysis,
          })
        );
        this.selectedReportGroup.patchValue(this.reportGroups[0].id);
        this.selectedReportGroup.updateValueAndValidity();
      });
  }
  private listenForGroupChange() {
    this.selectedReportGroup.valueChanges.subscribe((selectedGroupId) => {
      this.reports = [];
      setTimeout(() => {
        if (selectedGroupId) {
          this.getGroupReports(selectedGroupId);
        }
      });
    });
  }
  private getGroupReports(groupId) {
    this.loadingService.isLoading(true, `Loading reports...`);
    this.afs
      .collection("trendAnalysisReports", (ref) =>
        ref
          .where("client", "==", this.uiState$.client.id)
          .where("group", "==", groupId)
          .where("creator", "==", this.uiState$.authUser.id)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (reports$) => {
          this.loadingService.isLoading(false);
          this.reports = <TrendAnalysisReport[]>FirestoreUtilities.mapToType(
            reports$
          ).sort((a: TrendAnalysisReport, b: TrendAnalysisReport) => {
            return moment(a.dateUpdated.toDate()).isSameOrBefore(
              b.dateUpdated.toDate()
            )
              ? 1
              : -1;
          });
          this.getReportFragments().subscribe((complete$) => {
            this.tableData = new MatTableDataSource(this.reports);
            this.tableData.paginator = this.paginator;
            this.tableData.sort = this.sort;
            this.getReportCreators();
          });
        },
        (e) => {
          this.snackBar.open(
            "Oops... something went wrong. Please refresh and try again",
            "Dismiss",
            { duration: 5000 }
          );
          console.error(e.message);
          this.loadingService.isLoading(false);
        }
      );
  }
  private getReportFragments() {
    const fragmentRequests = this.reports.map((report) => {
      return this.afs
        .collection("trendAnalysisFragments", (ref) =>
          ref
            .where("trendAnalysisReport", "==", report.id)
            .where("client", "==", this.uiState$.client.id)
        )
        .snapshotChanges();
    });
    return from(fragmentRequests).pipe(
      combineAll(),
      takeUntil(this.destroy$),
      map((result$) => {
        const reportFragments = <TrendAnalysisFragment[]>(
          FirestoreUtilities.mergeCollectionToType(result$)
        );
        this.reports.forEach((report) => {
          report["completedFragments"] = reportFragments.filter(
            (fragment) =>
              fragment.trendAnalysisReport === report.id &&
              fragment.status === "done"
          );
          report["incompleteFragments"] = reportFragments.filter(
            (fragment) =>
              fragment.trendAnalysisReport === report.id &&
              fragment.status !== "done"
          );
          report["totalFragments"] = reportFragments.filter(
            (fragment) => fragment.trendAnalysisReport === report.id
          );
          report["errorFragments"] = reportFragments.filter(
            (fragment) =>
              fragment.trendAnalysisReport === report.id &&
              fragment.status == "error"
          );
        });
        return;
      }),
      catchError((e) => {
        console.error(`FireStore - 'trendAnalysisFragments': ${e.message}`);
        return of(e);
      })
    );
  }

  public getCompletePercentage(report) {
    const completedFragments = report["completedFragments"]?.length
      ? report["completedFragments"].length
      : 0;
    const incompleteFragments = report["incompleteFragments"]?.length
      ? report["incompleteFragments"].length
      : 0;
    if (incompleteFragments > 0) {
      return +(
        (completedFragments / report["totalFragments"].length) *
        100
      ).toFixed(2);
    } else if (completedFragments === 0) {
      return 0;
    } else {
      return 100;
    }
  }

  private getReportCreators() {
    const creatorRequests = this.reports.map((doc$: TrendAnalysisReport) => {
      return this.afs.doc(`userViews/${doc$.creator}`).snapshotChanges();
    });
    from(creatorRequests)
      .pipe(combineAll(), takeUntil(this.destroy$))
      .subscribe((userViews$) => {
        this.creators = FirestoreUtilities.mergeToType(userViews$).filter(
          (item) => !!item
        );
      });
  }

  async deleteReport(row: TrendAnalysisReport) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Delete",
        message: "Are you sure you want to delete this report?",
        action: "Yes, Delete",
      },
    });
    confirmDialog.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`trendAnalysisReports/${row.id}`).delete();
        this.snackBar.open("Report deleted successfully!", "Dismiss", {
          duration: 5000,
        });
      }
    });
  }

  createReport() {
    const dialogRef = this.dialog.open(NewTpdReportDialogComponent, {
      panelClass: "invisible-panel-dialog",
      data: {
        selectedGroup: this.selectedReportGroup.value,
        groups: this.reportGroups,
        type: ReportGroupTypes.trendAnalysis,
      },
    });
    dialogRef.afterClosed().subscribe((newId$) => {
      this.router.navigate(["../", newId$], { relativeTo: this.route });
    });
  }
}
