import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import {
  PosSystem,
  ThirdParty,
  Client,
} from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { Papa } from "ngx-papaparse";
import { Subject } from "rxjs";

@Component({
  selector: "app-temp-prior-period-adjustments-table",
  templateUrl: "./temp-prior-period-adjustments-table.component.html",
  styleUrls: ["./temp-prior-period-adjustments-table.component.scss"],
})
export class TempPriorPeriodAdjustmentsTableComponent implements OnInit {
  @Input() tempPriorPeriodAdjustments: {
    batchRow: number;
    date: any;
    location: string;
    account: string;
    sale: number;
    tax: number;
  }[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    "batchRow",
    "date",
    "location",
    "sale",
    "tax",
    "action",
  ];
  configurationToView: any;
  activePopover: any;
  private destroy$ = new Subject();
  public client: Client;
  public loadingPriorPeriodAdjustments = false;
  thirdParties: ThirdParty[] = [];
  posSystems: PosSystem[] = [];
  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private papa: Papa,
    private cdr: ChangeDetectorRef,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.tableData = new MatTableDataSource(this.tempPriorPeriodAdjustments);
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.cdr.detectChanges();
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
}
