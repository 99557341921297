import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { downloadDataAsFile } from "app/shared/ds-constant";
import moment from "moment";
import { Papa } from "ngx-papaparse";
import {
  ThirdParty,
  ThirdPartyCategoryReport,
} from "@deliver-sense-librarian/data-schema";
import { AngularFirestore } from "@angular/fire/firestore";
import firebase from "firebase/app";
import { first } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { TransactionsDialogComponent } from "app/dialogs/transactions-dialog/transactions-dialog.component";
import * as _ from "lodash";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormControl } from "@angular/forms";
import { ReconciliationReportDatUtility } from "../../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../../utilities/reconciliation-report-filtering.utility";
import { AfterViewInit } from "@angular/core";

@Component({
  selector: "app-fee-drill-down-table",
  templateUrl: "./fee-drill-down-table.component.html",
  styleUrls: ["./fee-drill-down-table.component.scss"],
})
export class FeeDrillDownTableComponent implements OnInit, AfterViewInit {
  @Input() data: ThirdPartyCategoryReport[];
  @Input() reportTitle: string;
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;

  @Output() clearResults = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    "location",
    "thirdParty",
    "feeType",
    "feeAmount",
    "transactions",
    "netSales",
    "netTax",
    "totalFees",
  ];
  public filter3pdsControl = new FormControl([]);
  public filterLocationsControl = new FormControl([]);
  constructor(
    private cdr: ChangeDetectorRef,
    private afs: AngularFirestore,
    private papa: Papa,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private loadingService: LoadingDialogService
  ) {}

  ngOnInit(): void {
    this.tableData = new MatTableDataSource(this.data);
  }

  ngAfterViewInit(): void {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.filteringUtility.set3pdAndLocationFilterPredicate(
      this.tableData,
      this.filter3pdsControl,
      this.filterLocationsControl
    );
    this.cdr.detectChanges();
  }
  exportReport() {
    const data = Object.assign(
      this.tableData.data.map((data) => {
        return {
          Location: data.location,
          "Third Party": data["thirdPartyName"],
          "Fee Type": data.feeType,
          "Fee Amount %": data.feeAmount > 0 ? `${data.feeAmount * 100}%` : 0,
          Transactions: data.transactions ? data.transactions.length : 0,
          "Net Sales": data.netSales,
          "Net Tax": data.netTax,
          "Total Fees": data.totalFees,
        };
      })
    );
    const results = this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const fileName = `${this.reportTitle}-Fee-Drill-Down`;
    downloadDataAsFile(results, fileName, "csv");
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  public async openTransactionViewer(report: ThirdPartyCategoryReport) {
    const transactionIds = report.transactions as string[];
    if (transactionIds.length > 0) {
      this.loadingService.isLoading(true, "Finding transactions...");
      const transactionIdBatches = _.chunk(transactionIds, 10);
      try {
        const transactions = _.flatten(
          await Promise.all(
            transactionIdBatches.map((transactionIds) => {
              return this.afs
                .collection("thirdPartyTransactions", (ref) =>
                  ref.where(
                    firebase.firestore.FieldPath.documentId(),
                    "in",
                    transactionIds
                  )
                )
                .snapshotChanges()
                .pipe(first())
                .toPromise();
            })
          )
        );
        this.loadingService.isLoading(false);
        this.dialog.open(TransactionsDialogComponent, {
          panelClass: "invisible-panel-dialog",
          data: {
            transactions: FirestoreUtilities.mapToType(transactions),
          },
        });
      } catch (e) {
        this.loadingService.isLoading(false);
        this.snackBar.open(
          `Could not find transactions. Please refresh and try again`,
          "Dismiss",
          { duration: 5000 }
        );
      }
    }
  }
}
