import { Workbook } from "exceljs";
import {
  ReconciliationDrillDownReportTypes,
  ReconciliationReportDatUtility,
} from "../reconciliation-report-data.utility";
import moment from "moment";
import {
  ThirdPartyReconciliationLocationData,
  ThirdPartyReportFragmentVarianceAnalysisReport,
  TransactionAITypes,
  recColumns,
} from "@deliver-sense-librarian/data-schema";
export async function compileDistrictTacoJournalEntries(
  recSummaryWorkbook: Workbook,
  dataUtility: ReconciliationReportDatUtility
) {
  const varianceAnalysisReports: ThirdPartyReportFragmentVarianceAnalysisReport[] =
    await dataUtility.fetchReconciliationReportDrillDownFragments(
      ReconciliationDrillDownReportTypes.varianceAnalysisReports
    );
  const dsReport: ThirdPartyReconciliationLocationData[] =
    dataUtility.reportData;
  const entryDate = dataUtility.endDateText;
  dataUtility.thirdParties.forEach((thirdParty) => {
    const deliveryPartnerName = thirdParty.name;
    const dspImportSheet = recSummaryWorkbook.addWorksheet(
      `${deliveryPartnerName} Import`
    );
    const headerRow = dspImportSheet.addRow([
      "JENumber",
      "Date",
      "Reversal Date",
      "JEComment",
      "JELocation",
      "Account",
      "Debit",
      "Credit",
      "DetailLocation",
      "DetailComment",
    ]);
    let rowCount = 2;
    let jeNumber =
      `${deliveryPartnerName}Adjust${dataUtility.existingReport.name}`.replace(
        /\s+/g,
        ""
      );
    //Delivery Service Fee

    dataUtility.existingReport.locations
      .sort((a, b) => (a > b ? 1 : -1))
      .forEach((location) => {
        dspImportSheet.addRow([
          jeNumber,
          entryDate,
          ``,
          `${deliveryPartnerName} AR Adjustment`,
          `${location}`,
          deliveryPartnerAccounts[deliveryPartnerName].deliveryServiceFee,
          {
            id: "deliveryServicFeeAdj",
            formula: `ABS(SUMIFS('Fees Summary'!H:H,'Fees Summary'!A:A,${location},'Fees Summary'!B:B,"${deliveryPartnerName}") + SUMIFS('Fees Summary'!I:I,'Fees Summary'!A:A,${location},'Fees Summary'!B:B,"${deliveryPartnerName}") + SUMIFS('Fees Summary'!J:J,'Fees Summary'!A:A,${location},'Fees Summary'!B:B,"${deliveryPartnerName}"))`,
          },
          0,
          `${location}`,
          `Delivery Service Fee - ${deliveryPartnerName}`,
        ]);
        rowCount++;
      });
    // Delivery Refund
    dspImportSheet.addRow([]);
    rowCount++;
    dataUtility.existingReport.locations.forEach((location) => {
      dspImportSheet.addRow([
        jeNumber,
        entryDate,
        ``,
        `${deliveryPartnerName} AR Adjustment`,
        `${location}`,
        deliveryPartnerAccounts[deliveryPartnerName].salesReturns,
        {
          id: "deliveryRefund",
          formula: `SUMIFS('Error Charges'!G:G,'Error Charges'!A:$A,${location},'Error Charges'!B:B,"${deliveryPartnerName}")`,
        },
        0,
        `${location}`,
        `Sales - Returns & Allowances - EOP - ${deliveryPartnerName}`,
      ]);
      rowCount++;
    });
    //Marketplace Fac Tax WH
    dspImportSheet.addRow([]);
    rowCount++;
    dataUtility.existingReport.locations.forEach((location) => {
      dspImportSheet.addRow([
        jeNumber,
        entryDate,
        ``,
        `${deliveryPartnerName} AR Adjustment`,
        `${location}`,
        deliveryPartnerAccounts[deliveryPartnerName].mfTax,
        {
          id: "mfTax",
          formula: `ABS(SUMIFS('DS Report'!${recColumns["Market Facilitator Tax"]}:${recColumns["Market Facilitator Tax"]},'DS Report'!A:A,${location},'DS Report'!B:B,"${deliveryPartnerName}"))`,
        },
        0,
        `${location}`,
        `Marketplace Fac Tax WH - ${deliveryPartnerName}`,
      ]);
      rowCount++;
    });
    //Item Sales - OverShort Delivery - Other Item
    dspImportSheet.addRow([]);
    rowCount++;

    dataUtility.existingReport.locations.forEach((location) => {
      const vaReport = varianceAnalysisReports.find(
        (vaReport) =>
          vaReport.location === location &&
          thirdParty.id === vaReport.thirdParty
      );
      const errorCharges = vaReport.salesVarianceReasons.find(
        (reason) => reason.type === TransactionAITypes.errorCharge
      ).sale;
      const canceledOrders = vaReport.salesVarianceReasons.find(
        (reason) => reason.type === TransactionAITypes.transactionNotPaidOut
      ).sale;
      const remainingVariance =
        vaReport.salesVarianceAmount - (errorCharges + canceledOrders);

      const locationRec = dsReport.find(
        (report) =>
          report.locationId === location && report.thirdParty === thirdParty.id
      );
      const taxVariance = locationRec.posNetTax - locationRec.thirdPartyNetTax;
      const overShort = +(remainingVariance + taxVariance).toFixed(2);
      dspImportSheet.addRow([
        jeNumber,
        entryDate,
        ``,
        `${deliveryPartnerName} AR Adjustment`,
        `${location}`,
        deliveryPartnerAccounts[deliveryPartnerName].overShort,
        overShort > 0 ? Math.abs(overShort) : 0,
        overShort < 0 ? Math.abs(overShort) : 0,
        `${location}`,
        `Over/short - Delivery ${deliveryPartnerName}`,
      ]);
      rowCount++;
    });
    //Delivery Service Recievable Adj
    dspImportSheet.addRow([]);
    dataUtility.existingReport.locations.forEach((location) => {
      dspImportSheet.addRow([
        jeNumber,
        entryDate,
        ``,
        `${deliveryPartnerName} AR Adjustment`,
        `${location}`,
        deliveryPartnerAccounts[deliveryPartnerName].recievabledAdj,
        0,
        {
          id: "deliveryServiceRecievableAdj",
          formula: `SUMIFS($G$2:$G$${rowCount},$E$2:$E$${rowCount},${location})-SUMIFS($H$2:$H$${rowCount},E2:E${rowCount},${location})`,
        },
        `${location}`,
        `Delivery Service Receivable Adj - ${deliveryPartnerName}`,
      ]);
    });
  });
}
class DistricTacoAccounts {
  recievabledAdj: number;
  deliveryServiceFee: number;
  salesReturns: number;
  mfTax: number;
  overShort: number;
}
const deliveryPartnerAccounts = {
  DoorDash: <DistricTacoAccounts>{
    recievabledAdj: 1103040,
    deliveryServiceFee: 7150040,
    salesReturns: 3059440,
    mfTax: 2400094,
    overShort: 7745040,
  },
  GrubHub: <DistricTacoAccounts>{
    recievabledAdj: 1103050,
    deliveryServiceFee: 7150050,
    salesReturns: 3059450,
    mfTax: 2400096,
    overShort: 7745050,
  },
  "Uber Eats": <DistricTacoAccounts>{
    recievabledAdj: 1103090,
    deliveryServiceFee: 7150090,
    salesReturns: 3059490,
    mfTax: 2400099,
    overShort: 7745090,
  },
};
