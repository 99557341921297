import { ActivatedRoute, Router } from "@angular/router";
import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormBuilder, FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { first, takeUntil } from "rxjs/operators";
import { FirestoreUtilities } from "../../../utilities/firestore-utilities";
import {
  Client,
  DataUploadErrorTypes,
  DataUploadEvent,
  DataUploadEventFragment,
  DataUploadEventStatuses,
  ThirdParty,
  DataUploadFragmentErrors,
  DataUploadFragmentWarnings,
  Location,
  PriorPeriodAdjustmentTransaction,
  DuplicateTransaction,
  DataUploadEventGroup,
  ClientInvalidDataLog,
  DataUploadEventTypes,
  CustomReport,
  DataSchema,
  DataUploadEventProgressCounter,
} from "@deliver-sense-librarian/data-schema";
import * as _ from "lodash";
import { ConfirmDialogComponent } from "app/dialogs/confirm-dialog/confirm-dialog.component";
import { Subject } from "rxjs";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import moment from "moment";
import bluebird from "bluebird";
import { Papa } from "ngx-papaparse";
import { downloadDataAsFile } from "app/shared/ds-constant";
import { DataUploadEventWithCounters } from "../data-upload-events/data-upload-events-table/data-upload-events-table.component";

@Component({
  selector: "app-data-upload-event",
  templateUrl: "./data-upload-event.component.html",
  styleUrls: ["./data-upload-event.component.scss"],
})
export class DataUploadEventComponent implements OnInit, OnDestroy {
  @Input() dataUploadEvent: DataUploadEventWithCounters;
  @Input() locations: Location[] = [];
  @Input() client: Client;
  @Input() thirdParties: ThirdParty[];
  @Input() posSystems = [];
  @Output() newProcessStarted = new EventEmitter();
  statuses = {
    cleaning: DataUploadEventStatuses.cleaning,
    complete: DataUploadEventStatuses.complete,
    committing: DataUploadEventStatuses.committing,
    errors: DataUploadEventStatuses.errors,
    validated: DataUploadEventStatuses.validated,
    running: DataUploadEventStatuses.running,
    clearingBatch: DataUploadEventStatuses.clearingBatch,
    transactionsRemoved: DataUploadEventStatuses.transactionsRemoved,
  };
  public config: PerfectScrollbarConfigInterface = {};
  dataUploadErrorTypes = DataUploadErrorTypes;
  rowsToRemove = [];
  duplicatesToOverride = [];
  locationsToRemove = [];
  duplicates: [];
  invalidLocations: [];
  errorGroups: any;
  errorGroupKeys: string[] = [
    "duplicates",
    "invalidData",
    "missingFields",
    "missingData",
  ];
  private destroy$ = new Subject();
  public showNewUploadSection: boolean;
  dataUploadEventFragments: DataUploadEventFragment[] = [];
  completeFragments: DataUploadEventFragment[] = [];
  errorFragments: DataUploadEventFragment[] = [];
  validatedFragments: DataUploadEventFragment[] = [];
  clearedFragments: DataUploadEventFragment[] = [];

  completedCounters: DataUploadEventProgressCounter[] = [];
  errorCounters: DataUploadEventProgressCounter[] = [];
  validatedCounters: DataUploadEventProgressCounter[] = [];
  clearedCounters: DataUploadEventProgressCounter[] = [];

  uploadErrors: DataUploadFragmentErrors;
  uploadStatus: DataUploadEventStatuses;
  fileSize: number;
  serverErrors: any[];
  uploadWarnings: DataUploadFragmentWarnings;
  locationsInUpload: { locationId: string; name: string; rows: number }[] = [];
  tempPriorPeriodAdjustments: any[];
  priorPeriodTransactions: PriorPeriodAdjustmentTransaction[] = [];
  duplicateTransactions: DuplicateTransaction[] = [];
  public dataUploadEventGroups: DataUploadEventGroup[] = [];
  public selectedUploadGroup = new FormControl();
  editingGroup = false;

  editNotesFormControl = new FormControl();
  editingNotes = false;

  locationErrors = [];
  duplicateErrors = [];
  missingDataErrors = [];
  missingFields = [];
  invalidDataLogs: ClientInvalidDataLog[] = [];
  locationFilterControl = new FormControl();
  filteredLocations: any;

  sampleConversions: any[] = [];
  warningsApproved = new FormControl(false);
  dataUploadEventProgressCounters: DataUploadEventProgressCounter[];
  constructor(
    private papa: Papa,
    private dialog: MatDialog,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore
  ) {}

  ngOnInit(): void {
    this.initializeUpload();
    this.fetchUploadGroups();
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  uploadRunning() {
    if ([1, 4, 5, 7].indexOf(this.dataUploadEvent.status) > -1) {
      return true;
    } else if (this.dataUploadEvent.status === DataUploadEventStatuses.errors) {
      const uploadProgress = +this.getProgress(
        this.dataUploadEventProgressCounters
      );
      return uploadProgress !== 100;
    }
    return false;
  }

  private async fetchInvalidDataLogs() {
    let accountType;
    let account;
    switch (this.dataUploadEvent.type) {
      case DataUploadEventTypes["3PD"]:
        accountType = "thirdParty";
        account = this.dataUploadEvent.account;
        break;
      case DataUploadEventTypes.POS:
        accountType = "posSystem";
        account = this.dataUploadEvent.account;
        break;
      case DataUploadEventTypes["Custom Report"]:
        accountType = "customReport";
        account = this.dataUploadEvent.customReport;
        break;
    }
    this.invalidDataLogs = <ClientInvalidDataLog[]>FirestoreUtilities.mapToType(
      await this.afs
        .collection("clientInvalidDataLogs", (ref) =>
          ref
            .where("client", "==", this.client.id)
            .where(accountType, "==", account)
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise()
    );
  }
  private fetchUploadGroups() {
    this.afs
      .collection("dataUploadEventGroups", (ref) =>
        ref.where("client", "==", this.client.id)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((uploadGroups$) => {
        this.dataUploadEventGroups = <DataUploadEventGroup[]>(
          FirestoreUtilities.mapToType(uploadGroups$).sort((a, b) => {
            return a.name.charAt(0) < b.name.charAt(0) ? -1 : 1;
          })
        );
        this.dataUploadEventGroups.push(
          new DataUploadEventGroup({ name: "Un-Grouped", id: "ungrouped" })
        );
      });
  }
  getProgress(counters: DataUploadEventProgressCounter[]) {
    // const counters = dataUploadEvent.dataUploadEventProgressCounters;
    if (this.dataUploadEventProgressCounters?.length > 0) {
      const progress = this.dataUploadEventProgressCounters.reduce(
        (progressObj, counter) => {
          progressObj.fragments += +counter.fragmentCount
            ? +counter.fragmentCount
            : 0;
          progressObj.commited += +counter.committedFragments
            ? +counter.committedFragments
            : 0;
          progressObj.errors += +counter.errorFragments
            ? +counter.errorFragments
            : 0;
          progressObj.validated += +counter.validatedFragments
            ? +counter.validatedFragments
            : 0;
          progressObj.cleared += +counter.clearedFragments
            ? +counter.clearedFragments
            : 0;
          return progressObj;
        },
        {
          fragments: 0,
          errors: 0,
          validated: 0,
          commited: 0,
          cleared: 0,
        }
      );
      return +(
        (_.sum([
          progress.errors,
          progress.validated,
          progress.commited,
          progress.cleared,
        ]) /
          progress.fragments) *
        100
      ).toFixed(2);
    }
  }
  private async initializeUpload() {
    if (this.dataUploadEvent) {
      this.duplicatesToOverride = this.dataUploadEvent.duplicatesToOverride
        ? this.dataUploadEvent.duplicatesToOverride
        : [];
      this.rowsToRemove = this.dataUploadEvent.rowsToRemove
        ? this.dataUploadEvent.rowsToRemove
        : [];
      this.locationsToRemove = this.dataUploadEvent.locationsToRemove
        ? this.dataUploadEvent.locationsToRemove
        : [];
      this.editNotesFormControl.patchValue(this.dataUploadEvent.notes);
      this.editNotesFormControl.updateValueAndValidity();
      await this.fetchInvalidDataLogs();
      this.fetchUploadFragments();
      this.fetchUploadCounters();
      this.listenForGroupChange();
    }
  }
  private aggregateTempPriorPeriodAdjustments() {
    this.tempPriorPeriodAdjustments = _.flatten(
      this.dataUploadEventFragments
        .filter(
          (dataUploadEventFragment) =>
            dataUploadEventFragment.priorPeriodTransactions?.length > 0
        )
        .map((dataUploadEventFragment) => {
          return dataUploadEventFragment.priorPeriodTransactions.map(
            (pptLog: any) => {
              return pptLog;
            }
          );
        })
    );
  }
  private fetchUploadCounters() {
    this.afs
      .collection("dataUploadEventProgressCounters", (ref) =>
        ref.where("dataUploadEvent", "==", this.dataUploadEvent.id)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (countersQuery$) => {
        this.dataUploadEventProgressCounters = FirestoreUtilities.mapToType(
          countersQuery$
        ) as DataUploadEventProgressCounter[];
        this.validatedCounters = this.dataUploadEventProgressCounters.filter(
          (counter) => counter.status === DataUploadEventStatuses.validated
        );
        this.errorCounters = this.dataUploadEventProgressCounters.filter(
          (counter) => counter.status === DataUploadEventStatuses.errors
        );
        this.completedCounters = this.dataUploadEventProgressCounters.filter(
          (counter) => counter.status === DataUploadEventStatuses.complete
        );
        this.clearedCounters = this.dataUploadEventProgressCounters.filter(
          (counter) =>
            counter.status === DataUploadEventStatuses.transactionsRemoved
        );
      });
  }
  private fetchUploadFragments() {
    this.afs
      .collection("dataUploadEventFragments", (ref) =>
        ref
          .where("dataUploadEvent", "==", this.dataUploadEvent.id)
          .where("client", "==", this.client.id)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (fragmentQueryResults) => {
        this.dataUploadEventFragments = <DataUploadEventFragment[]>(
          FirestoreUtilities.mapToType(fragmentQueryResults)
        );
        this.validatedFragments = this.dataUploadEventFragments.filter(
          (fragment) => fragment.status === DataUploadEventStatuses.validated
        );
        this.errorFragments = this.dataUploadEventFragments.filter(
          (fragment) => fragment.status === DataUploadEventStatuses.errors
        );
        this.completeFragments = this.dataUploadEventFragments.filter(
          (fragment) => fragment.status === DataUploadEventStatuses.complete
        );
        this.clearedFragments = this.dataUploadEventFragments.filter(
          (fragment) =>
            fragment.status === DataUploadEventStatuses.transactionsRemoved
        );
        if (
          [
            DataUploadEventStatuses.running,
            DataUploadEventStatuses.cleaning,
            DataUploadEventStatuses.committing,
            DataUploadEventStatuses.transactionsRemoved,
          ].indexOf(this.dataUploadEvent && this.dataUploadEvent.status) === -1
        ) {
          // if (this.errorFragments.length > 0) {
          this.aggregateErrors();
          // }
        }
        this.aggregateWarnings();
        this.uploadStatus = this.dataUploadEvent.status;
        this.fileSize = this.dataUploadEventFragments.reduce(
          (sum, fragment) => {
            return (sum += +fragment.fileSize ? +fragment.fileSize : 0);
          },
          0
        );
        this.determineLocationsInUpload();
        this.aggregateTempPriorPeriodAdjustments();
        this.compileConversionSamples();
      });
  }
  private determineLocationsInUpload() {
    this.locationsInUpload = this.locations
      .map((location) => {
        const locationFragment = this.dataUploadEventFragments.find(
          (dataUploadEventFragment) =>
            dataUploadEventFragment.location === location.locationId
        );
        if (locationFragment && locationFragment.rowCount > 0) {
          return {
            locationId: location.locationId,
            name: location.name,
            rows: locationFragment.rowCount,
          };
        } else {
          return {
            locationId: location.locationId,
            name: location.name,
            rows: 0,
          };
        }
      })
      .filter((location) => location.rows > 0);
    this.assignLocationsInUploadCopy();
  }
  private compileConversionSamples() {
    this.sampleConversions = this.dataUploadEventFragments
      .map((fragment) => {
        return fragment.sampleConversion;
      })
      .filter((conversion) => !!conversion);
    this.sampleConversions.forEach((conversion) => {
      Object.keys(conversion).forEach((key) => {
        if (!!conversion[key]?.seconds) {
          conversion[key] = moment(conversion[key].toDate()).format("l");
        }
      });
    });
  }
  private aggregateWarnings() {
    const warnings = new DataUploadFragmentWarnings();
    warnings.accountMappingErrors = [];
    warnings.missingFields = [];
    this.dataUploadEventFragments.forEach(
      (dataUploadFragment: DataUploadEventFragment) => {
        if (dataUploadFragment.warnings?.accountMappingErrors?.length > 0) {
          const fragmentMappingErrors = <string[]>(
            _.uniq(
              dataUploadFragment.warnings?.accountMappingErrors.map(
                (error) => error.account
              )
            )
          );
          warnings.accountMappingErrors = [
            ...warnings.accountMappingErrors,
            ...fragmentMappingErrors,
          ];
        }
        if (dataUploadFragment.warnings?.missingFields?.length > 0) {
          warnings.missingFields = [
            ...warnings.missingFields,
            ...dataUploadFragment.warnings?.missingFields,
          ];
        }
      }
    );
    warnings.accountMappingErrors = _.uniq(warnings.accountMappingErrors);
    warnings.missingFields = _.uniq(warnings.missingFields);
    this.uploadWarnings = warnings;
  }
  private aggregateErrors() {
    const errors = new DataUploadFragmentErrors();
    errors.invalidData = [];
    errors.missingData = [];
    // errors.missingFields = [];
    errors.duplicates = [];
    const serverErrors = [];

    this.errorFragments.forEach((errorFragment: DataUploadEventFragment) => {
      if (errorFragment.serverErrors) {
        serverErrors.push(errorFragment.serverErrors);
      }
      if (errorFragment.errors?.invalidData?.length > 0) {
        this.locationErrors = _.uniqBy(
          [
            ...this.locationErrors,
            ...errorFragment.errors.invalidData.map(
              (invalidLocationLog: {
                location: string;
                transactions: number;
              }) => {
                const knownInvalidLocation = this.invalidDataLogs.find(
                  (log) => log.identifier === invalidLocationLog.location
                );
                // auto select knownInvalidLocations and set marker if known
                return {
                  remove: false,
                  value: invalidLocationLog.location,
                  transactions: invalidLocationLog.transactions,
                  knownInvalidLocation: !!knownInvalidLocation,
                };
              }
            ),
          ],
          "value"
        );
      }
      if (errorFragment.errors?.missingData?.length > 0) {
        this.missingDataErrors = _.uniq([
          ...this.missingDataErrors,
          ...errorFragment.errors.missingData,
        ]);
      }
      // if (errorFragment.errors?.missingFields?.length > 0) {
      //   this.missingFields = _.uniq([
      //     ...this.missingFields,
      //     ...errorFragment.errors.missingFields,
      //   ]);
      // }
      if (errorFragment.errors?.duplicates?.length > 0) {
        this.duplicateErrors = _.uniq([
          ...this.duplicateErrors,
          ...errorFragment.errors.duplicates,
        ]);
      }
    });
    // if (this.dataUploadEvent.duplicatesToOverride) {
    //   this.duplicatesToOverride = [...this.duplicatesToOverride, this.dataUploadEvent.duplicatesToOverride.map(location => {
    //     return {}
    //   })]
    // }
    //MERGE ON VALIDATED
    if (this.dataUploadEvent.locationErrors) {
      this.locationErrors = _.uniqBy(
        [
          ...this.locationErrors,
          ...this.dataUploadEvent.locationErrors.map((locationError) => {
            const knownInvalidLocation = this.invalidDataLogs.find(
              (log) => log.identifier === locationError.value
            );
            const removeLocation = this.dataUploadEvent.locationsToRemove.find(
              (locationId) => locationId === locationError.value
            );
            return {
              remove: removeLocation,
              value: locationError.value,
              transactions: locationError.transactions,
              knownInvalidLocation: !!knownInvalidLocation,
            };
          }),
        ],
        "value"
      );
    }
    this.uploadErrors = errors;
    this.serverErrors = _.uniq(serverErrors);
  }

  locationMarkedToRemove(locationId) {
    return !!this.locationsToRemove.find((lId) => lId === locationId);
  }
  setLocationToRemove(locationId) {
    this.locationsToRemove.push(locationId);
    this.locationsToRemove = _.uniq(this.locationsToRemove);
  }
  setLocationsToRemove(locationSelection: { remove: boolean; value: any }[]) {
    this.locationsToRemove = locationSelection.map(
      (selectedLocation) => selectedLocation.value
    );
  }
  undoSetLocationToRemove(locationId) {
    this.locationsToRemove.splice(
      this.locationsToRemove.indexOf(locationId),
      1
    );
  }

  getAccountName() {
    let account;
    if (this.dataUploadEvent.type === "POS") {
      account = this.posSystems.find(
        (posSystem) => posSystem.id === this.dataUploadEvent.account
      );
    } else {
      account = this.thirdParties.find(
        (thirdParty) => thirdParty.id === this.dataUploadEvent.account
      );
    }
    return account ? account.name : "";
  }

  getUploadProgress() {
    return this.dataUploadEvent.status < 8
      ? (
          ((this.clearedCounters?.length +
            this.completedCounters?.length +
            this.errorCounters?.length +
            this.validatedCounters?.length) /
            this.dataUploadEvent.progressCounters) *
          100
        ).toFixed(0)
      : // (
        //     ((this.clearedFragments?.length +
        //       this.completeFragments?.length +
        //       this.errorFragments?.length +
        //       this.validatedFragments?.length) /
        //       this.dataUploadEvent.fragments) *
        //     100
        //   ).toFixed(0)
        100;
  }

  async saveNotes() {
    const updatedNotes = this.editNotesFormControl.value
      ? this.editNotesFormControl.value
      : "";
    await this.afs.doc(`dataUploadEvents/${this.dataUploadEvent.id}`).update({
      notes: updatedNotes,
    });
    this.editingNotes = false;
    this.dataUploadEvent.notes = updatedNotes;
    this.snackBar.open("Notes updated successfully!", "Dismiss", {
      duration: 5000,
    });
  }
  getUploadStatus() {
    switch (this.uploadStatus) {
      case DataUploadEventStatuses.cleaning:
        return "Cleaning";
      case DataUploadEventStatuses.committing:
        return "Committing";
      case DataUploadEventStatuses.complete:
        return "Complete";
      case DataUploadEventStatuses.errors:
        return "Errors";
      case DataUploadEventStatuses.running:
        return "Running";
      case DataUploadEventStatuses.validated:
        return "Validated";
      case DataUploadEventStatuses.clearingBatch:
        return "Clearning Transactions";
      case DataUploadEventStatuses.transactionsRemoved:
        return "Complete - Transactions Cleared";
    }
  }
  isRowMarkedForRemoval(batchRow) {
    return this.rowsToRemove.indexOf(batchRow) !== -1;
  }
  setRowsToDelete(updatedRowsToDelete: number[]) {
    updatedRowsToDelete.forEach((rowNumber) => {
      if (this.rowsToRemove.indexOf(rowNumber)) {
      }
    });
    this.rowsToRemove = updatedRowsToDelete;
  }

  addRemoveRow(row) {
    if (this.rowsToRemove.indexOf(row) < 0) {
      this.rowsToRemove.push(row);
    }
  }
  undoRemoveRow(row) {
    if (this.rowsToRemove.indexOf(row) >= 0) {
      this.rowsToRemove.splice(this.rowsToRemove.indexOf(row), 1);
    }
  }
  overrideDuplicate(row) {
    if (this.duplicatesToOverride.indexOf(row) < 0) {
      this.duplicatesToOverride.push(row);
    }
  }
  undoOverrideDuplicate(row) {
    if (this.duplicatesToOverride.indexOf(row) >= 0) {
      this.duplicatesToOverride.splice(
        this.duplicatesToOverride.indexOf(row),
        1
      );
    }
  }
  getMissingFieldsList() {
    return this.uploadErrors.missingFields;
  }

  async retryValidation() {
    this.loadingService.isLoading(true, "Cleaning data...");
    await this.nextUploadStep(DataUploadEventStatuses.cleaning);
    this.newProcessStarted.emit(true);
    this.loadingService.isLoading(false);
  }

  async commitUpload() {
    this.loadingService.isLoading(true, "Committing data...");
    await this.nextUploadStep(DataUploadEventStatuses.committing);
    this.newProcessStarted.emit(true);
    this.loadingService.isLoading(false);
  }
  async nextUploadStep(status: DataUploadEventStatuses) {
    const fragmentUpdateBatch = this.afs.firestore.batch();
    this.dataUploadEventFragments.forEach((fragment) => {
      fragmentUpdateBatch.update(
        this.afs.doc(`dataUploadEventFragments/${fragment.id}`).ref,
        {
          status: status,
        }
      );
    });
    this.serverErrors = null;
    const counterUpdateBatch = this.afs.firestore.batch();
    this.dataUploadEventProgressCounters.forEach((counter) => {
      counterUpdateBatch.update(
        this.afs.doc(`dataUploadEventProgressCounters/${counter.id}`).ref,
        {
          validatedFragments: 0,
          errorFragments: 0,
          committedFragments: 0,
          clearedFragments: 0,
          status: status,
        }
      );
    });
    await counterUpdateBatch.commit();
    await Promise.all([
      fragmentUpdateBatch.commit(),
      this.afs.doc(`dataUploadEvents/${this.dataUploadEvent.id}`).update({
        validatedFragments: 0,
        errorFragments: 0,
        committedFragments: 0,
        clearedFragments: 0,
        validatedCounters: 0,
        errorCounters: 0,
        committedCounters: 0,
        clearedCounters: 0,
        warnings:
          status === DataUploadEventStatuses.committing && this.uploadWarnings
            ? this.uploadWarnings.toJSONObject()
            : null,
        rowsToRemove: this.rowsToRemove,
        duplicatesToOverride: this.duplicatesToOverride,
        locationsToRemove: this.locationsToRemove,
        locationErrors: this.locationErrors,
        status: status,
      }),
    ]);
    if (status === DataUploadEventStatuses.committing) {
      await this.setNewInvalidLocationLogs();
    }
    await this.updateGroup(this.dataUploadEvent.group);
    return;
  }

  private async setNewInvalidLocationLogs() {
    const newInvalidLocations = this.locationsToRemove.filter((location) => {
      return (
        +location &&
        !this.invalidDataLogs.find(
          (invalidDataLog) => invalidDataLog.identifier === location
        )
      );
    });
    if (newInvalidLocations.length > 0) {
      return await Promise.all(
        newInvalidLocations.map((newInvalidLocation) => {
          const invalidDataLog = new ClientInvalidDataLog();
          invalidDataLog.active = true;
          invalidDataLog.client = this.client.id;
          invalidDataLog.identifier = newInvalidLocation;
          if (this.dataUploadEvent.type === DataUploadEventTypes["3PD"]) {
            invalidDataLog.thirdParty = `${this.dataUploadEvent.account}`;
          } else if (this.dataUploadEvent.type === DataUploadEventTypes.POS) {
            invalidDataLog.posSystem = `${this.dataUploadEvent.account}`;
          } else if (
            this.dataUploadEvent.type === DataUploadEventTypes["Custom Report"]
          ) {
            invalidDataLog.customReport = `${this.dataUploadEvent.customReport}`;
          }
          return this.afs
            .collection("clientInvalidDataLogs")
            .add(invalidDataLog.toJSONObject());
        })
      );
    }
  }

  getTabLabel(key) {
    switch (key) {
      case "missingFields":
        return "Missing Fields";
      case "invalidData":
        return "Invalid Locations";
      case "missingData":
        return "Missing Data";
      case "duplicates":
        return "Duplicates";
    }
  }
  delete() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Delete",
        message: "Are you sure you want delete this data upload log?.",
        action: "Yes, Delete.",
      },
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        const groupId = this.dataUploadEvent.group;
        await this.afs
          .doc(`dataUploadEvents/${this.dataUploadEvent.id}`)
          .delete();
        await this.updateGroup(groupId);
        this.newProcessStarted.emit(true);
        this.snackBar.open("Data upload log deleted successfully.", "Dismiss", {
          duration: 5000,
        });
      }
    });
  }
  deleteTransactions() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Delete Transactions",
        message: `Are you sure you want delete all ${this.dataUploadEvent.rowCount} transactions associated with this data upload log?.`,
        action: "Yes, Delete.",
      },
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        this.loadingService.isLoading(true, "Creating transaction removal job");
        const deleteBatch = this.afs.firestore.batch();
        const dateUpdated = moment().toDate();
        this.dataUploadEventFragments.forEach((fragment) => {
          deleteBatch.update(
            this.afs.doc(`dataUploadEventFragments/${fragment.id}`).ref,
            {
              status: DataUploadEventStatuses.clearingBatch,
              dateUpdated: dateUpdated,
            }
          );
        });
        const counterDeleteBatch = this.afs.firestore.batch();
        this.dataUploadEventProgressCounters.forEach((counter) => {
          counterDeleteBatch.update(
            this.afs.doc(`dataUploadEventProgressCounters/${counter.id}`).ref,
            {
              validatedFragments: 0,
              errorFragments: 0,
              committedFragments: 0,
              clearedFragments: 0,

              status: DataUploadEventStatuses.clearingBatch,
            }
          );
        });
        await counterDeleteBatch.commit();
        await Promise.all([
          deleteBatch.commit(),
          await this.afs
            .doc(`dataUploadEvents/${this.dataUploadEvent.id}`)
            .update({
              warnings: this.uploadWarnings
                ? this.uploadWarnings.toJSONObject()
                : null,
              validatedFragments: 0,
              errorFragments: 0,
              committedFragments: 0,
              clearedFragments: 0,
              validatedCounters: 0,
              errorCounters: 0,
              committedCounters: 0,
              clearedCounters: 0,
              status: DataUploadEventStatuses.clearingBatch,
            }),
        ]);
        await this.updateGroup(this.dataUploadEvent.group);
        this.loadingService.isLoading(false);
        this.snackBar.open("Delete transactions job started.", "Dismiss", {
          duration: 5000,
        });
      }
    });
  }

  private async updateGroup(groupId) {
    if (groupId !== "ungrouped") {
      await this.afs
        .doc(`dataUploadEventGroups/${groupId}`)
        .update({ dateUpdated: moment().toDate() });
    }
  }
  private async listenForGroupChange() {
    this.selectedUploadGroup.valueChanges.subscribe(async (selectedGroupId) => {
      if (selectedGroupId && this.dataUploadEvent.group !== selectedGroupId) {
        try {
          await this.afs
            .doc(`dataUploadEvents/${this.dataUploadEvent.id}`)
            .update({
              group: selectedGroupId,
            });
          this.snackBar.open("Updated group setting successfully!", "Dismiss", {
            duration: 5000,
          });
          this.editingGroup = false;
        } catch (e) {
          console.error(e.message);
          this.snackBar.open(
            "Oops... something went wrong. Please refresh and try again",
            "Dismiss",
            { duration: 5000 }
          );
        }
      }
    });
  }

  getUploadGroupName() {
    const reportGroup = this.dataUploadEventGroups.find(
      (group) => group.id === this.dataUploadEvent.group
    );
    return reportGroup ? `${reportGroup.name}` : "Un-Grouped";
  }

  filterLocations(value) {
    if (!value) {
      this.assignLocationsInUploadCopy();
    }
    this.filteredLocations = Object.assign([], this.locationsInUpload).filter(
      (location: Location) =>
        location.locationId.indexOf(value.toLowerCase()) > -1 ||
        location.name.toLowerCase().indexOf(value.toLowerCase()) > -1
    );
  }
  assignLocationsInUploadCopy() {
    this.filteredLocations = Object.assign([], this.locationsInUpload);
  }
  async downloadBatchTransactions() {
    this.loadingService.isLoading(
      true,
      "Fetching and downloading converted data..."
    );
    try {
      let collection;
      if (this.dataUploadEvent.type === DataUploadEventTypes["Custom Report"]) {
        const customReport = <CustomReport>(
          FirestoreUtilities.objectToType(
            await this.afs
              .doc(`customReports/${this.dataUploadEvent.customReport}`)
              .snapshotChanges()
              .pipe(first())
              .toPromise()
          )
        );
        const dataSchema = <DataSchema>(
          FirestoreUtilities.objectToType(
            await this.afs
              .doc(`customReports/${customReport.dataSchema}`)
              .snapshotChanges()
              .pipe(first())
              .toPromise()
          )
        );
        collection = dataSchema.collection;
      } else {
        collection =
          this.dataUploadEvent.type === DataUploadEventTypes.POS
            ? "posTransactions"
            : "thirdPartyTransactions";
      }
      const transactionsRequest = this.dataUploadEventFragments.map(
        (fragment) => {
          return this.afs
            .collection(collection, (ref) =>
              ref.where("dataUploadEventFragment", "==", fragment.id)
            )
            .snapshotChanges()
            .pipe(first())
            .toPromise();
        }
      );
      const transactionQueryResults = _.flatten(
        await bluebird.mapSeries(transactionsRequest, async (request) => {
          return await request;
        })
      );
      const data = FirestoreUtilities.mergeToType(transactionQueryResults);
      const headers = data.map((row) => Object.keys(row));

      const displayedColumns = _.uniq(_.flatten(headers));
      data.forEach((row) => {
        displayedColumns.forEach((header: string) => {
          if (row[header]?.seconds) {
            row[header] = moment(row[header].toDate()).format("l");
          }
        });
      });
      const results = this.papa.unparse(data, {
        quotes: false,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ",",
        header: true,
        newline: "\r\n",
        skipEmptyLines: false,
      });
      downloadDataAsFile(
        results,
        `${this.dataUploadEvent.fileName}-converted-data`,
        "csv"
      );
    } catch (e) {
      this.snackBar.open("Error fetching and downloading data.", "Dismiss", {
        duration: 5000,
      });
      console.error(e.message);
    } finally {
      this.loadingService.isLoading(false);
    }
  }
}
