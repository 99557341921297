import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";

import { MatDialog } from "@angular/material/dialog";
import * as _ from "lodash";
import { Papa } from "ngx-papaparse";
import {
  ThirdPartyReportNote,
  Client,
  User,
  UserView,
  ThirdParty,
} from "@deliver-sense-librarian/data-schema";
import { AngularFirestore } from "@angular/fire/firestore";
import { takeUntil, first } from "rxjs/operators";
import { Subject, lastValueFrom } from "rxjs";
import { Store } from "@ngrx/store";
import moment from "moment";
import { ReconciliationNoteDialogComponent } from "app/dialogs/reconciliation-note-dialog/reconciliation-note-dialog.component";
import { downloadDataAsFile } from "app/shared/ds-constant";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import { ReconciliationReportDatUtility } from "../../utilities/reconciliation-report-data.utility";
import { ConfirmDialogComponent } from "app/dialogs/confirm-dialog/confirm-dialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";
@Component({
  selector: "app-client-persistant-reconciliation-notes",
  templateUrl: "./client-persistant-reconciliation-notes.component.html",
  styleUrls: ["./client-persistant-reconciliation-notes.component.scss"],
})
export class ClientPersistantReconciliationNotesComponent
  implements OnInit, OnDestroy
{
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() client: Client;
  @Input() thirdParties: ThirdParty[];
  @Input() locations: Location[];
  @Input() user: User[];
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    "locations",
    "thirdParties",
    "creator",
    "message",
    "edit",
    "delete",
  ];
  public persistantThirdPartyReportNotes: ThirdPartyReportNote[];
  private destroy$ = new Subject();

  constructor(
    private cdr: ChangeDetectorRef,
    private afs: AngularFirestore,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private papa: Papa
  ) {}

  ngOnInit() {
    this.getReportNotes();
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private async getReportNotes() {
    this.afs
      .collection("thirdPartyReportNotes", (ref) =>
        ref
          .where("client", "==", this.client.id)
          .where("isPersistant", "==", true)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (queryResults$) => {
        const thirdPartyReportNotes = <ThirdPartyReportNote[]>(
          FirestoreUtilities.mapToType(queryResults$)
        );
        await this.setDataNames(thirdPartyReportNotes);
        this.persistantThirdPartyReportNotes = [...thirdPartyReportNotes];
        this.setTableData();
      });
  }
  private async setDataNames(thirdPartyReportNotes) {
    const creatorQuerys = thirdPartyReportNotes.map((thirdPartyReportNote) => {
      return this.afs
        .doc(`userViews/${thirdPartyReportNote.creator}`)
        .snapshotChanges()
        .pipe(first())
        .toPromise();
    });
    const creatorUserViews = <UserView[]>(
      FirestoreUtilities.mergeToType(await Promise.all(creatorQuerys))
    );
    thirdPartyReportNotes.forEach((thirdPartyReportNote) => {
      const creatorUserView = creatorUserViews.find(
        (userView) => userView.id === thirdPartyReportNote.creator
      );
      if (creatorUserView) {
        thirdPartyReportNote["creatorEmail"] = creatorUserView.email;
      }
    });
    thirdPartyReportNotes.forEach((thirdPartyReportNote) => {
      //@TODO wtf
      //@ts-ignore
      thirdPartyReportNote["thirdPartyNames"] =
        thirdPartyReportNote.thirdParties.reduce((txt, thirdPartyId) => {
          const thirdPartyName = this.thirdParties.find(
            (dsp) => dsp.id === thirdPartyId
          )?.name;
          if (!txt) {
            return `${thirdPartyName}`;
          } else {
            return `${txt}, ${thirdPartyName}`;
          }
        }, ``);
    });
  }

  setTableData() {
    this.tableData = new MatTableDataSource(
      this.persistantThirdPartyReportNotes
    );
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.cdr.detectChanges();
  }
  public upsertNote(reportNote?: ThirdPartyReportNote) {
    let note;
    if (reportNote) {
      note = this.persistantThirdPartyReportNotes.find(
        (reportNoteOriginal) => reportNoteOriginal.id === reportNote.id
      );
    } else {
      note = new ThirdPartyReportNote();
    }
    this.dialog.open(ReconciliationNoteDialogComponent, {
      panelClass: "invisible-panel-dialog",
      data: {
        thirdPartyReportNote: note,
        locations: this.locations,
        thirdParties: this.thirdParties,
        client: this.client,
        creator: this.user,
        persistant: true,
      },
    });
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  exportReport() {
    const data = this.persistantThirdPartyReportNotes.map((note) => {
      //@ts-ignore
      const thirdPartiesText = note.thirdParties.reduce((txt, dspId) => {
        if (txt) {
          return `${txt}, ${
            this.thirdParties.find((dsp) => dsp.id === dspId)?.name
          }`;
        } else {
          return this.thirdParties.find((dsp) => dsp.id === dspId)?.name;
        }
      }, "");
      return {
        Locations: note.locations,
        "3PDs": thirdPartiesText,
        Dates: note.dates
          ? note.dates.map((date) => `${moment(date.toDate()).format("l")} `)
          : note.startDate
          ? moment(note.startDate.toDate()).format("M/D/YYYY")
          : "",
        Creator: note.creator,
        Message: note.message,
      };
    });
    const results = this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const fileName = `${this.client.name}-Persistant-Reconciliation-Notes`;
    downloadDataAsFile(results, fileName, "csv");
  }
  async deleteNote(note: ThirdPartyReportNote) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Delete Persistant Reconciliation Note",
        message: `Are you sure you want delete this note?`,
        action: "Yes, Delete.",
      },
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`thirdPartyReportNotes/${note.id}`).delete();
        this.snackBar.open("Persistant note deleted successfully!", "Dismiss", {
          duration: 5000,
        });
      }
    });
  }
}
