import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-upload-expected-fields-dialog",
  templateUrl: "./upload-expected-fields-dialog.component.html",
  styleUrls: ["./upload-expected-fields-dialog.component.scss"],
})
export class UploadExpectedFieldsDialogComponent implements OnInit {
  fields: { fieldName: string; required: boolean }[];
  constructor(
    public dialogRef: MatDialogRef<UploadExpectedFieldsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { expectedFields: string[]; requiredFields: string[] }
  ) {
    this.fields = data.expectedFields.map((expectedField) => {
      const isRequired = !!data.requiredFields.find(
        (field) => field === expectedField
      );
      return { fieldName: expectedField, required: isRequired };
    });
  }

  ngOnInit() {}
}
