import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-upload-controls-checklist-dialog",
  templateUrl: "./upload-controls-checklist-dialog.component.html",
  styleUrls: ["./upload-controls-checklist-dialog.component.scss"],
})
export class UploadControlsChecklistDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<UploadControlsChecklistDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      formGroup: FormGroup;
    }
  ) {}

  ngOnInit() {}

  saveChecklistProgress() {
    this.dialogRef.close();
  }
}
