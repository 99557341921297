import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-terms-and-services-dialog",
  templateUrl: "./terms-and-services-dialog.component.html",
  styleUrls: ["./terms-and-services-dialog.component.scss"],
})
export class TermsAndServicesDialogComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
