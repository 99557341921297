import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoadingDotsComponent } from "./loading-dots/loading-dots.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MaterialModule } from "app/material.module";
import { DsSharedModule } from "app/shared/ds-shared.module";

@NgModule({
  declarations: [LoadingDotsComponent],
  exports: [LoadingDotsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DsSharedModule,
    FlexLayoutModule,
    MaterialModule,
  ],
})
export class UxModule {}
