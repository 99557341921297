import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormControl } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSelectionListChange } from "@angular/material/list";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  ClientExemptionCertificateDocument,
  usStates,
} from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { UiState } from "app/redux/custom-states/uiState/ui-state";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import {} from "app/shared/ds-constant";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ExemptionCertificateViewerDialogComponent } from "../exemption-certificate-viewer-dialog/exemption-certificate-viewer-dialog.component";
import {
  FormsContainerDialogComponent,
  ApplicationFormNames,
} from "../forms-container-dialog/forms-container-dialog.component";

@Component({
  selector: "app-exemption-certificates-list",
  templateUrl: "./exemption-certificates-list.component.html",
  styleUrls: ["./exemption-certificates-list.component.scss"],
})
export class ExemptionCertificatesListDialogComponent implements OnInit {
  uiState: UiState;
  clientExemptionCertificateDocuments: ClientExemptionCertificateDocument[] =
    [];
  filteredCertificates: ClientExemptionCertificateDocument[] = [];
  statesList = usStates;
  stateFilterControl = new FormControl("");
  titleFilterControl = new FormControl("");
  private destroy$ = new Subject();
  skipTitleFilter = false;
  skipStateFilter = false;
  selectedCertificate: any;
  newlyAddedCertificateId: any;
  constructor(
    public dialogRef: MatDialogRef<ExemptionCertificatesListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<any>,
    private dialog: MatDialog,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.data.client) {
      this.fetchClientExemptionCertificates();
    } else {
    }
  }

  fetchClientExemptionCertificates() {
    this.afs
      .collection("clientExemptionCertificateDocuments", (ref) =>
        ref
          .where("client", "==", this.data.client.id)
          .orderBy("dateCreated", "desc")
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (queryResults$) => {
        this.clientExemptionCertificateDocuments = <
          ClientExemptionCertificateDocument[]
        >FirestoreUtilities.mapToType(queryResults$);
        this.assignCopy();
        if (this.newlyAddedCertificateId) {
          this.selectedCertificate =
            this.clientExemptionCertificateDocuments.find(
              (certificate) => certificate.id === this.newlyAddedCertificateId
            );
        }
        this.listenForFilters();
      });
  }
  private listenForFilters() {
    this.stateFilterControl.valueChanges.subscribe((stateSelection) => {
      if (!this.skipStateFilter) {
        this.filterByState(stateSelection);
      } else {
        this.skipStateFilter = false;
      }
    });
    this.titleFilterControl.valueChanges.subscribe((titleInput) => {
      if (!this.skipTitleFilter) {
        this.filterByTitle(titleInput);
      } else {
        this.skipTitleFilter = false;
      }
    });
  }
  assignCopy() {
    this.filteredCertificates = Object.assign(
      [],
      this.clientExemptionCertificateDocuments
    );
  }
  filterByTitle(value) {
    this.skipStateFilter = true;
    this.stateFilterControl.reset();
    if (!value) {
      this.assignCopy();
    }
    this.filteredCertificates = Object.assign(
      [],
      this.clientExemptionCertificateDocuments
    ).filter(
      (item) => item.title.toLowerCase().indexOf(value.toLowerCase()) > -1
    );
  }
  createNewExemptionCertificateDocument() {
    const documentUploadDialog = this.dialog.open(
      FormsContainerDialogComponent,
      {
        panelClass: "invisible-panel-dialog",
        data: {
          formName: ApplicationFormNames.clientExemptionCertificateDocument,
        },
      }
    );
    documentUploadDialog.afterClosed().subscribe((certificateId) => {
      if (certificateId) {
        this.newlyAddedCertificateId = certificateId;
      }
    });
  }
  async viewCertificateDetails(
    certificate: ClientExemptionCertificateDocument
  ) {
    this.dialog.open(ExemptionCertificateViewerDialogComponent, {
      panelClass: "invisible-panel-dialog",
      data: {
        clientExemptionCertificateDocument: certificate,
      },
    });
  }

  filterByState($event: MatSelectChange) {
    this.skipTitleFilter = true;
    this.titleFilterControl.reset();
    if (!$event.value) {
      this.assignCopy();
    }
    this.filteredCertificates = Object.assign(
      [],
      this.clientExemptionCertificateDocuments
    ).filter((item) =>
      item.jurisdictions.find((jurisdiction) => {
        return jurisdiction.indexOf($event.value) > -1;
      })
    );
  }
  setSelectedCertificate($event: MatSelectionListChange) {
    this.selectedCertificate = $event.option.value;
  }
  closeAndAttach() {
    this.dialogRef.close(this.selectedCertificate);
  }
}
