import { MatTableDataSource } from "@angular/material/table";
import _ from "lodash";
import { combineLatest, zip } from "rxjs";
import { FormControl } from "@angular/forms";
import { ReconciliationReportDatUtility } from "./reconciliation-report-data.utility";
import { startWith } from "rxjs/operators";
export class ReconciliationReportFilteringUtility {
  constructor(private dataUtility: ReconciliationReportDatUtility) {}
  public set3pdAndLocationFilterPredicate(
    tableData: MatTableDataSource<any>,
    filter3pdsControl,
    filterLocationsControl
  ) {
    tableData.filterPredicate = (data: any, filtersJson: string) => {
      const filterGroups = _.groupBy(JSON.parse(filtersJson), "id");
      let matchThirdPartyName = true;
      let matchLocationId = true;
      if (filterGroups.thirdParty?.length > 0) {
        matchThirdPartyName = !!filterGroups.thirdParty.find((dspFilter) => {
          return (
            dspFilter.value === data.thirdParty ||
            dspFilter.name === data.thirdPartyName
          );
        });
      }
      if (filterGroups.locationId?.length > 0) {
        matchLocationId = !!filterGroups.locationId.find(
          (locationIdFilter) =>
            // Location will be locationId in rec table but location in all other reports
            locationIdFilter.value === data.locationId ||
            locationIdFilter.value === data.location
        );
      }

      const matchAll = matchThirdPartyName && matchLocationId;
      return matchAll;
    };
    this.setupFilterListeners(
      tableData,
      filter3pdsControl,
      filterLocationsControl
    );
  }
  private setupFilterListeners(
    tableData: MatTableDataSource<any>,
    filter3pdsControl: FormControl,
    filterLocationsControl: FormControl
  ) {
    combineLatest([
      filter3pdsControl.valueChanges.pipe(startWith([])),
      filterLocationsControl.valueChanges.pipe(startWith([])),
    ]).subscribe(([selected3pds, selectedLocations]) => {
      this.multiFilter(tableData, selected3pds, selectedLocations);
    });
  }
  private multiFilter(
    tableData: MatTableDataSource<any>,
    selected3pds: string[],
    selectedLocations: string[]
  ) {
    let filters;
    let tpdFilters = [];
    let locationFilters = [];
    if (selectedLocations.length > 0) {
      locationFilters = selectedLocations.map((locationId) => {
        return { id: "locationId", value: locationId };
      });
    }

    if (selected3pds.length > 0) {
      tpdFilters = selected3pds.map((dspId) => {
        const dsp = this.dataUtility.thirdParties.find(
          (dsp) => dsp.id === dspId
        );
        return { id: "thirdParty", value: dspId, name: dsp.name };
      });
    }
    const filtersArray = [...tpdFilters, ...locationFilters];
    if (filtersArray.length > 0) {
      filters = JSON.stringify(filtersArray);
    } else {
      filters = "";
    }
    tableData.filter = filters;
    tableData.paginator.firstPage();
  }
}
