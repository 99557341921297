import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModule } from "app/material.module";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { SharingCenterComponent } from "./sharing-center.component";
import { SharedByMeReportsComponent } from "./shared-by-me-reports/shared-by-me-reports.component";
import { SharedWithMeReportsComponent } from "./shared-with-me-reports/shared-with-me-reports.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
  ],
  declarations: [
    SharingCenterComponent,
    SharedByMeReportsComponent,
    SharedWithMeReportsComponent,
  ],
  exports: [SharedByMeReportsComponent, SharedWithMeReportsComponent],
})
export class SharingCenterModule {}
