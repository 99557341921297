
import { Directive, HostBinding, HostListener, Input, OnChanges } from "@angular/core";

@Directive({
    selector: '[appWindowHeightMatcher]'
})
export class WindowHeightMatcherDirective implements OnChanges {


    @Input('heightCompensation') heightCompensation: number;
    @Input('height') height = 'height';
    @HostBinding(`style.height.px`)
    //@ts-ignore
    adjustedWindowHeight: number = window.innerHeight - this.heightCompensation ? this.heightCompensation : 0;

    @HostListener('window:resize', ['$event'])
    private onResize(event) {
        this.adjustedWindowHeight = event.target.innerHeight - this.heightCompensation;
    }

    ngOnChanges(): void {
        if (this.heightCompensation) {
            this.adjustedWindowHeight = window.innerHeight - this.heightCompensation;
        }
    }

}
