import {
  Component,
  Inject,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  AfterViewChecked,
  AfterViewInit,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-error-dialog",
  templateUrl: "./information-dialog.component.html",
  styleUrls: ["./information-dialog.component.scss"],
})
export class InformationDialogComponent implements OnInit {
  type = "error";
  keys: string[];
  retry: boolean;
  rowsToRemove = [];
  constructor(
    public dialogRef: MatDialogRef<InformationDialogComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.type) {
      this.type = data.type;
    }
    this.retry = !!data.retry;
  }

  ngOnInit() {
    this.keys = this.getKeys();
  }

  getKeys() {
    if (this.data && this.data.tableData && this.data.tableData.length > 0) {
      return Object.keys(this.data.tableData[0]);
    }
    return [];
  }
  closeAndRetry() {
    this.dialogRef.close({ retry: true, rowsToRemove: this.rowsToRemove });
  }
  setRowsToDelete(updatedRowsToDelete: number[]) {
    this.rowsToRemove = updatedRowsToDelete;
  }
}
