import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ReportGroupTypes } from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-sharing-center",
  templateUrl: "./sharing-center.component.html",
  styleUrls: ["./sharing-center.component.scss"],
})
export class SharingCenterComponent implements OnInit, OnDestroy {
  public reportTypeControl = new FormControl();
  public selectedReportType: ReportGroupTypes;
  public reportTypes = [
    { name: "3PD Reports", value: ReportGroupTypes.thirdPartyReconciliation },
    { name: "Error Charge Reports", value: ReportGroupTypes.errorCharge },
    { name: "Trend Analysis", value: ReportGroupTypes.trendAnalysis },
    { name: "Payout Reports", value: ReportGroupTypes.payoutSummary },
  ];
  public uiState: any;
  private destroy$ = new Subject();
  constructor(private store: Store<any>) {}

  ngOnInit(): void {
    this.store
      .select((store) => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((uiState$) => {
        if (uiState$.authUser && uiState$.client) {
          this.uiState = uiState$;
          this.reportTypeControl.valueChanges.subscribe((value$) => {
            this.selectedReportType = null;
            setTimeout(() => {
              this.selectedReportType = value$;
            });
          });
          this.reportTypeControl.patchValue(
            ReportGroupTypes.thirdPartyReconciliation
          );
          this.reportTypeControl.updateValueAndValidity();
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
