import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";
import * as _ from "lodash";
import { PayoutReportFragmentLog } from "@deliver-sense-librarian/data-schema";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import {
  ReconciliationDrillDownReportTypes,
  ReconciliationReportDatUtility,
} from "../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../utilities/reconciliation-report-filtering.utility";
@Component({
  selector: "app-deposit-report",
  templateUrl: "./deposit-report.component.html",
  styleUrls: ["./deposit-report.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DepositReportComponent implements OnInit, AfterViewInit {
  @Input() exportUtility: ReconciliationReportExportUtility;
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  public depositReports: PayoutReportFragmentLog[] = [];
  public loadingData = true;
  constructor() {}

  ngOnInit() {
    this.initializeTable();
  }
  ngAfterViewInit() {}
  private async initializeTable() {
    this.compileDepositReports();
  }

  private async compileDepositReports() {
    this.depositReports =
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.depositReports
      );
    this.loadingData = false;
  }
}
