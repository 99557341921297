import { FirebaseAuthService } from "../auth/services/firebase-auth.service";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRoute,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { first } from "rxjs/operators";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user_data = JSON.parse(localStorage.getItem("user"));
    const queryParams = next.queryParams;
    const url = state.url;
    if (user_data) {
      return true;
    } else {
      const reportTypePath = url.includes("3pd-reports")
        ? "3pd-reports"
        : url.includes("payout-reporting")
        ? "payout-reporting"
        : url.includes("trend-analysis")
        ? "trend-analysis"
        : url.includes("error-charge-manager")
        ? "error-charge-manager"
        : null;
      if (reportTypePath && queryParams.shared) {
        const segments = url.split("/");
        const reportid = segments[3].split("?")[0];
        localStorage.setItem("redirectTo", reportTypePath);
        localStorage.setItem("reportId", reportid);
        localStorage.setItem("shared", queryParams.shared);
        localStorage.setItem("downloadOnLoad", queryParams.downloadOnLoad);
      }
      this.router.navigate(["/login"]);
      return false;
    }
  }
}
