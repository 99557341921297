import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum ApplicationFormNames {
  'clientExemptionCertificateDocument',
}

@Component({
  selector: 'app-forms-container-dialog',
  templateUrl: './forms-container-dialog.component.html',
  styleUrls: ['./forms-container-dialog.component.scss']
})
export class FormsContainerDialogComponent implements OnInit {
  public get applicationFormNames(): typeof ApplicationFormNames {
    return ApplicationFormNames;
  };
  constructor(public dialogRef: MatDialogRef<FormsContainerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { formName: ApplicationFormNames, inputData: any }) {
  }
  ngOnInit(): void {
  }

  close(event$) {
    this.dialogRef.close(event$)
  }

}
