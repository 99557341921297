import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import {
  Client,
  Client3pdConfiguration,
} from "@deliver-sense-librarian/data-schema";
import { FirebaseAuthService } from "app/auth/services/firebase-auth.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { takeUntil } from "rxjs/operators";
import { Subject, from } from "rxjs";
import { FirestoreUtilities } from "../../../../../utilities/firestore-utilities";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import moment from "moment";

@Component({
  selector: "app-third-party-ecds-configs",
  templateUrl: "./third-party-ecds-configs.component.html",
  styleUrls: ["./third-party-ecds-configs.component.scss"],
})
export class ThirdPartyEcdsConfigurationsComponent
  implements OnInit, OnDestroy
{
  @Input() client: Client;
  client3pdConfigurations: Client3pdConfiguration;
  destroy$ = new Subject();
  ecdsSettingsForm: any;
  constructor(
    private auth: FirebaseAuthService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.initializeComponent();
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  private async initializeComponent() {
    await this.fetchClient3PDConfigurations();
  }
  private async fetchClient3PDConfigurations() {
    this.afs
      .collection("client3pdConfigurations", (ref) =>
        ref.where("client", "==", this.client.id)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((queryResults$) => {
        if (queryResults$.length > 0) {
          this.client3pdConfigurations = <Client3pdConfiguration>(
            FirestoreUtilities.mapToType(queryResults$)[0]
          );
          this.setupForm();
        }
      });
  }
  private setupForm() {
    this.ecdsSettingsForm = this.fb.group({
      ecdsRefundFloor: new FormControl(
        this.client3pdConfigurations.ecdsRefundFloor
          ? this.client3pdConfigurations.ecdsRefundFloor
          : 5,
        [Validators.min(0), Validators.max(10)]
      ),
      ecdsCountRatio: new FormControl(
        this.client3pdConfigurations.ecdsCountRatio
          ? this.client3pdConfigurations.ecdsCountRatio * 100
          : 75,
        [Validators.min(0.5), Validators.max(100)]
      ),
      ecdsOrderRatio: new FormControl(
        this.client3pdConfigurations.ecdsOrderRatio
          ? this.client3pdConfigurations.ecdsOrderRatio * 100
          : 25,
        [Validators.min(0.5), Validators.max(100)]
      ),
      ecdsRefundItemTags: new FormArray([]),
      clientRefundRate: new FormControl(
        this.client3pdConfigurations.clientRefundRate
      ),
      clientCancelationRate: new FormControl(
        this.client3pdConfigurations.clientCancelationRate
      ),
    });
    if (this.client3pdConfigurations.ecdsRefundItemTags?.length > 0) {
      this.setupRefundTagArray();
    } else {
      this.addTag();
    }
  }
  public addTag() {
    const tagFormArray = this.ecdsSettingsForm.get(
      "ecdsRefundItemTags"
    ) as FormArray;
    tagFormArray.push(
      new FormGroup({
        tag: new FormControl("", Validators.required),
      })
    );
  }

  public removeTag(index) {
    const tagFormArray = this.ecdsSettingsForm.get(
      "ecdsRefundItemTags"
    ) as FormArray;
    tagFormArray.removeAt(index);
  }
  private setupRefundTagArray() {
    const tagFormArray = this.ecdsSettingsForm.get(
      "ecdsRefundItemTags"
    ) as FormArray;
    this.client3pdConfigurations.ecdsRefundItemTags.forEach((tag) => {
      tagFormArray.push(
        new FormGroup({
          tag: new FormControl(tag, Validators.required),
        })
      );
    });
  }
  async save() {
    if (this.ecdsSettingsForm.valid) {
      const formValues: {
        ecdsRefundFloor: number;
        ecdsCountRatio: number;
        ecdsOrderRatio: number;
        ecdsRefundItemTags: { tag: string }[];
        clientRefundRate: number;
        clientCancelationRate: number;
      } = this.ecdsSettingsForm.value;
      await this.afs
        .doc(`client3pdConfigurations/${this.client3pdConfigurations.id}`)
        .update({
          ecdsRefundFloor: formValues.ecdsRefundFloor
            ? formValues.ecdsRefundFloor
            : 5,
          ecdsCountRatio: formValues.ecdsCountRatio
            ? formValues.ecdsCountRatio / 100
            : 0.75,
          ecdsOrderRatio: formValues.ecdsOrderRatio
            ? formValues.ecdsOrderRatio / 100
            : 0.25,
          ecdsRefundItemTags:
            formValues.ecdsRefundItemTags?.length > 0
              ? formValues.ecdsRefundItemTags.map((entry) => entry.tag)
              : [],
          clientRefundRate: formValues.clientRefundRate
            ? formValues.clientRefundRate
            : 0,
          clientCancelationRate: formValues.clientCancelationRate
            ? formValues.clientCancelationRate
            : 0,
          dateUpdated: moment().toDate(),
        });
      this.snackBar.open(
        "ECDS Configurations Updated Succesfully!",
        "Dismiss",
        { duration: 5000 }
      );
    }
  }
  formatLabel(value: number) {
    return `${value}%`;
  }
}
