import { SfaSuccessAction } from "./../../redux/custom-states/uiState/ui-state-actions/authentication-actions";
import { User } from "@deliver-sense-librarian/data-schema";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { FirebaseAuthService } from "../services/firebase-auth.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subject } from "rxjs";
import { FormControl } from "@angular/forms";
import { Store } from "@ngrx/store";
import { takeUntil, first } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import { AngularFireStorage } from "@angular/fire/storage";
import { AngularFirestore } from "@angular/fire/firestore";
import { FirestoreUtilities } from "../../utilities/firestore-utilities";

@Component({
  selector: "app-sfa",
  templateUrl: "./sfa.component.html",
  styleUrls: ["./sfa.component.scss"],
})
export class SfaComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  public authCode = new FormControl();
  private clientId: string;
  user: User;
  constructor(
    private authService: FirebaseAuthService,
    private store: Store<any>,
    private dialog: MatDialog,
    private afs: AngularFirestore,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      if (params["clientId"]) {
        this.clientId = params["clientId"];
        this.authService.requestSfa(this.clientId).then(() => {
          this.snackBar.open(
            "Check your email for the authentication code",
            "Dismiss",
            {
              duration: 5000,
            }
          );
        });
      } else {
        this.router.navigateByUrl("/client-selection");
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  async requestNewSFA() {
    await this.authService.requestNewSfa(this.clientId);
    this.snackBar.open(
      "Check your email for the authentication code",
      "Dismiss",
      {
        duration: 5000,
      }
    );
  }

  async verifyAuth() {
    if (this.authCode.value.length === 6) {
      this.authCode.disable();
      this.afs
        .doc(`clients/${this.clientId}`)
        .snapshotChanges()
        .pipe(first())
        .subscribe(async (client$) => {
          const client = FirestoreUtilities.objectToType(client$);
          try {
            const sfaResponse = await this.authService.verifySfa(
              this.authCode.value,
              client
            );
            if (sfaResponse.error) {
              const errorMessage = sfaResponse.error.message
                ? sfaResponse.error.message
                : "Code entered is invalid.";
              this.snackBar.open(errorMessage, "Dismiss", { duration: 5000 });
              this.authCode.enable();
            } else {
              this.store.dispatch(
                new SfaSuccessAction({ token: sfaResponse, client })
              );
              const uiState$ = await this.store
                .select((store) => store.uiState)
                .pipe(first())
                .toPromise();
              await this.authService.setSelectedClient(
                this.clientId,
                uiState$.authUser
              );
              this.router.navigateByUrl("/app");
            }
          } catch (e) {
            console.log(e.message);
            this.authCode.enable();
            this.snackBar.open("Code entered is invalid.", "Dismiss", {
              duration: 5000,
            });
          }
        });
    }
  }
  backToClientSelection() {
    this.router.navigateByUrl("/client-selection");
  }
}
