import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModule } from "app/material.module";
import { DsSharedModule } from "app/shared/ds-shared.module";
import { RouterModule } from "@angular/router";
import { UxModule } from "../../ux/ux.module";
import { FileUploadModule } from "../../file-upload/file-upload.module";
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from "ngx-perfect-scrollbar";
import { PopoverModule } from "ngx-bootstrap/popover";
import { PriorPeriodAdjustmentsComponent } from "./prior-period-adjustments.component";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20,
};
@NgModule({
  declarations: [PriorPeriodAdjustmentsComponent],
  exports: [PriorPeriodAdjustmentsComponent],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MaterialModule,
    CommonModule,
    PerfectScrollbarModule,
    DsSharedModule,
    RouterModule,
    UxModule,
    FileUploadModule,
    PopoverModule.forRoot(),
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
})
export class PriorPeriodAdjustmentsModule {}
