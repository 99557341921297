import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Papa } from "ngx-papaparse";
import * as moment from "moment";
import {
  ThirdParty,
  TransactionI,
  ClientThirdParty,
} from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-customer-ratings-dialog",
  templateUrl: "./customer-ratings-dialog.component.html",
  styleUrls: ["./customer-ratings-dialog.component.scss"],
})
export class CustomerRatingsDialogComponent implements OnInit {
  public thirdParties: ThirdParty[];
  public destroy$ = new Subject();
  public tableData: MatTableDataSource<TransactionI>;
  public clientThirdParties: ClientThirdParty[];

  constructor(
    private dialog: MatDialog,
    private store: Store<any>,
    public dialogRef: MatDialogRef<CustomerRatingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { ratings: any[] }
  ) {}

  ngOnInit() {
    this.store
      .select((store) => store.uiState.clientThirdParties)
      .pipe(takeUntil(this.destroy$))
      .subscribe((clientThirdParties$: ClientThirdParty[]) => {
        if (clientThirdParties$ && clientThirdParties$.length > 0) {
          this.thirdParties = <ThirdParty[]>(
            clientThirdParties$.map(
              (clientThirdParty) => clientThirdParty.thirdParty
            )
          );
        }
      });
  }
}
