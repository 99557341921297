import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  ThirdPartyReport,
  UserView,
  Client,
} from "@deliver-sense-librarian/data-schema";
import { UiState } from "app/redux/custom-states/uiState/ui-state";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";
export class TeamMemberSelectorDialogData {
  excludedTeamMembers: string[];
  client: Client;
  report: ThirdPartyReport;
  filterInternalUsers: boolean;
}

@Component({
  selector: "app-team-member-selector-dialog",
  templateUrl: "./team-member-selector-dialog.component.html",
  styleUrls: ["./team-member-selector-dialog.component.scss"],
})
export class TeamMemberSelectorDialogComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns: string[] = ["select", "name", "email"];
  tableData: MatTableDataSource<any>;
  uiState: UiState;
  teamMembers: UserView[] = [];
  selection = new SelectionModel<UserView>(true, []);

  private destroy$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<TeamMemberSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TeamMemberSelectorDialogData,
    private afs: AngularFirestore,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.data.client) {
      this.fetchTeamMembers();
    } else {
    }
  }
  ngAfterViewInit() {}

  fetchTeamMembers() {
    this.afs
      .collection("userViews", (ref) =>
        ref.where("clients", "array-contains", this.data.client.id)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (queryResults$) => {
        this.teamMembers = <UserView[]>FirestoreUtilities.mapToType(
          queryResults$
        )
          .filter(
            (userView) =>
              !this.data.excludedTeamMembers.find(
                (alreadySharedUserId) => alreadySharedUserId === userView.id
              )
          )
          .filter((userView) => !userView.internalRole);

        this.tableData = new MatTableDataSource(this.teamMembers);
        this.tableData.paginator = this.paginator;
        this.tableData.sort = this.sort;
        this.cdr.detectChanges();
      });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableData.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.tableData.data.forEach((row) => {
          this.selection.select(row);
        });
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  closeAndShare() {
    this.dialogRef.close(
      this.selection.selected.map((userView) => userView.id)
    );
  }
}
