import {
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  ClientExemptionCertificateDocument,
  usStates,
} from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { UiState } from "app/redux/custom-states/uiState/ui-state";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { Subject } from "rxjs";
import {
  FormBuilder,
  FormControl,
  Validators,
  FormGroup,
} from "@angular/forms";
import { takeUntil, distinctUntilChanged } from "rxjs/operators";
import { UploadDocumentService } from "app/services/upload-document.service";
import { FirestoreUtilities } from "../../../../utilities/firestore-utilities";
import moment from "moment";

@Component({
  selector: "app-client-exemption-certificate-document",
  templateUrl: "./client-exemption-certificate-document.component.html",
  styleUrls: ["./client-exemption-certificate-document.component.scss"],
})
export class ClientExemptionCertificateDocumentComponent
  implements OnInit, OnDestroy
{
  @Input() certificateDocument: ClientExemptionCertificateDocument;
  @Output() completed = new EventEmitter();
  uiState: UiState;
  statesList = usStates;
  clientTaxExemptionDocuments: ClientExemptionCertificateDocument[];
  fileToUpload: any;
  certificateDocumentForm: FormGroup;
  fileName: string;
  file: any;
  storagePath: string;
  replacingFile = false;
  private progress = { progress: 0 };
  private destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private store: Store<any>,
    private uploadDocumentService: UploadDocumentService,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.store
      .select((store) => store.uiState)
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((a: UiState, b: UiState) => {
          const aComp = {
            client: a.client,
            user: a.authUser,
            thirdParties: a.clientThirdParties,
            locations: a.clientLocations,
          };
          const bComp = {
            client: b.client,
            user: b.authUser,
            thirdParties: b.clientThirdParties,
            locations: b.clientLocations,
          };
          return JSON.stringify(aComp) === JSON.stringify(bComp);
        })
      )
      .subscribe((uiState$) => {
        if (uiState$.authUser && uiState$.client) {
          this.uiState = uiState$;
          this.storagePath = `clients/${this.uiState.client.id}/exemption-certificate-documents/`;
          this.setupForm();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private setupForm() {
    if (this.certificateDocument) {
      this.fileName = this.certificateDocument.fileName;
    }
    this.certificateDocumentForm = this.fb.group({
      title: new FormControl(
        this.certificateDocument ? this.certificateDocument.title : "",
        Validators.required
      ),
      client: new FormControl(this.uiState.client.id),
      jurisdictions: new FormControl(
        this.certificateDocument ? this.certificateDocument.jurisdictions : "",
        Validators.required
      ),
      notes: new FormControl(
        this.certificateDocument ? this.certificateDocument.notes : ""
      ),
      fileName: new FormControl(
        this.certificateDocument ? this.certificateDocument.fileName : ""
      ),
    });
  }

  async save() {
    if (this.certificateDocumentForm.valid) {
      const formValues = this.certificateDocumentForm.value;
      try {
        if (this.certificateDocument) {
          this.updateCertificateDocument(formValues);
        } else {
          this.createCertificateDocument(formValues);
        }
      } catch (e) {
        this.snackBar.open(
          "Oops... something went wrong with the upload. Please refresh and try agin"
        );
      }
    }
  }
  async updateCertificateDocument(formValues) {
    const updateData = new ClientExemptionCertificateDocument(formValues);
    try {
      if (this.file) {
        const uploadResult = await this.uploadFilesToStorage(
          this.file,
          this.progress
        );
        updateData.fileName = uploadResult.fileName;
        updateData.filePath = uploadResult.filePath;
        updateData.fileSize = uploadResult.fileSize;
        await FirestoreUtilities.deleteStorageFile(
          this.certificateDocument.filePath,
          this.storage
        );
      }
      await this.afs
        .doc(
          `clientExemptionCertificateDocuments/${this.certificateDocument.id}`
        )
        .update(updateData.toJSONObject());
      this.snackBar.open(
        "Successfully updated certificate document!",
        "Dismiss",
        { duration: 5000 }
      );
      this.completed.emit(true);
    } catch (e) {
      this.snackBar.open("Oops... something went wrong!", "Dismiss", {
        duration: 5000,
      });
    }
  }
  async createCertificateDocument(formValues) {
    const updateData = new ClientExemptionCertificateDocument(formValues);
    const uploadResult = await this.uploadFilesToStorage(
      this.file,
      this.progress
    );
    updateData.fileName = uploadResult.fileName;
    updateData.filePath = uploadResult.filePath;
    updateData.fileSize = uploadResult.fileSize;
    updateData.createdBy = this.uiState.authUser.id;
    const newId = this.afs.createId();
    await this.completed.emit(newId);
    await this.afs
      .doc(`clientExemptionCertificateDocuments/${newId}`)
      .set(updateData.toJSONObject());
    this.snackBar.open(
      "Successfully updated certificate document!",
      "Dismiss",
      { duration: 5000 }
    );
  }
  setFileInformation(file) {
    this.replacingFile = false;
    this.file = file;
    this.fileName = file.name;
  }
  cancel() {
    this.completed.emit(true);
  }
  replaceFile() {
    this.replacingFile = true;
  }
  private async uploadFilesToStorage(file, progress?: { progress }) {
    this.loadingService.isLoading(true, "Uploading File", this.progress);
    try {
      const name = file.name;
      const lastDot = name.lastIndexOf(".");
      const ext = name.substring(lastDot + 1);
      const fileName = name + `-${moment().format("YYYY-MM-DD")}.${ext}`;
      const filePath = this.storagePath + "/" + this.fileName;
      const uploadResult = await this.uploadDocumentService.uploadSingle(
        file,
        filePath,
        null,
        this.destroy$
      );
      uploadResult.fileName = `${file.name}`;
      this.loadingService.isLoading(false);
      return uploadResult;
    } catch (e) {
      console.error(e);
      this.loadingService.isLoading(false);
      throw new Error(e.message);
    }
  }
}
