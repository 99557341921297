import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  ClientWebhookSubscription,
  WebhookSubscriptionStatuses,
  WebhookEvent,
  Client,
  User,
  ClientWebhookDelivery,
  WebhookEventCategory,
} from "@deliver-sense-librarian/data-schema";
import { ConfirmDialogComponent } from "app/dialogs/confirm-dialog/confirm-dialog.component";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import _ from "lodash";
import { Subject, combineLatest } from "rxjs";
import { takeUntil, first } from "rxjs/operators";
import { LoadingDialogService } from "../../../../services/loading-dialog.service";

@Component({
  selector: "app-client-webhooks",
  templateUrl: "./client-webhooks.component.html",
  styleUrls: ["./client-webhooks.component.scss"],
})
export class ClientWebhooksComponent implements OnInit {
  @Input() client: Client;
  @Input() user: User;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<ClientWebhookSubscription>;
  public displayedColumns: string[] = [
    "event",
    "endpoint",
    "status",
    "inTest",
    "edit",
    "delete",
    "logs",
  ];
  webhookSubscriptionForm: FormGroup;
  subscriptionInEdit: ClientWebhookSubscription;
  clientWebhookSubscriptions: ClientWebhookSubscription[] = [];
  subscriptionStatusOptions = [
    { name: "Inactive", value: WebhookSubscriptionStatuses.Inactive },
    { name: "Active", value: WebhookSubscriptionStatuses.Active },
  ];
  private destroy$ = new Subject();
  webhookEvents: WebhookEvent[];
  eventGroups = [];
  webhookLogs: ClientWebhookDelivery[];
  constructor(
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private loadingService: LoadingDialogService
  ) {}

  ngOnInit(): void {
    this.fetchClientWebhookSubscriptionData();
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private fetchClientWebhookSubscriptionData() {
    combineLatest([
      this.afs
        .collection("clientWebhookSubscriptions", (ref) =>
          ref.where("client", "==", this.client.id)
        )
        .snapshotChanges(),
      this.afs
        .collection("webhookEvents", (ref) =>
          ref.where("client", "in", ["all", this.client.id])
        )
        .snapshotChanges(),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([clientWebhooks$, webhookEvents$]) => {
        this.clientWebhookSubscriptions = <ClientWebhookSubscription[]>(
          FirestoreUtilities.mapToType(clientWebhooks$)
        );
        this.webhookEvents = <WebhookEvent[]>(
          FirestoreUtilities.mapToType(webhookEvents$)
        );
        this.setClientWebhookSubscriptionEventNames();
        this.setupWebhookEventGroups();
        this.tableData = new MatTableDataSource(
          this.clientWebhookSubscriptions
        );
        this.tableData.paginator = this.paginator;
        this.tableData.sort = this.sort;
      });
  }
  setClientWebhookSubscriptionEventNames() {
    this.clientWebhookSubscriptions.forEach((subscription) => {
      subscription["eventText"] = this.webhookEvents.find(
        (event) => event.id === subscription.event
      )?.name;
    });
  }
  setupWebhookEventGroups() {
    this.eventGroups = [];
    const eventCategoryGroups = _.groupBy(this.webhookEvents, "category");
    Object.keys(eventCategoryGroups).forEach((categoryName) => {
      this.eventGroups.push({
        name: categoryName,
        events: _.sortBy(
          eventCategoryGroups[categoryName].map((event) => {
            return event;
          }),
          "name"
        ),
      });
    });
  }
  /**
   * WEBHOOK FUNCTIONS
   */
  upsertWebhookSubscription(webhookSubscription?: ClientWebhookSubscription) {
    this.subscriptionInEdit = webhookSubscription ? webhookSubscription : null;
    this.setupWebhookSubscriptionForm();
  }
  private setupWebhookSubscriptionForm() {
    this.webhookSubscriptionForm = this.fb.group({
      event: new FormControl(
        this.subscriptionInEdit?.event,
        Validators.required
      ),
      endpoint: new FormControl(this.subscriptionInEdit?.endpoint, [
        Validators.required,
        Validators.pattern(
          /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/
        ),
      ]),
      status: new FormControl(
        this.subscriptionInEdit
          ? this.subscriptionInEdit.status
          : WebhookSubscriptionStatuses.Inactive
      ),
      inTest: new FormControl(!!this.subscriptionInEdit?.inTest),
    });
  }
  public async saveWebhookSubscription() {
    if (this.webhookSubscriptionForm.valid) {
      const formValues = this.webhookSubscriptionForm.value;
      const event = this.webhookEvents.find(
        (event) => event.id === formValues.event
      );
      if (this.subscriptionInEdit) {
        await this.updateWebhookSubscription(formValues, event);
      } else {
        await this.createNewWebhookSubscription(formValues, event);
      }
      this.closeWebhookSubscriptionForm();
    } else {
      this.snackBar.open("Please fill out all webhook fields.", "Dismiss", {
        duration: 5000,
      });
    }
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  async updateWebhookSubscription(formValues, event: WebhookEvent) {
    await this.afs
      .doc(`clientWebhookSubscriptions/${this.subscriptionInEdit.id}`)
      .update({
        event: formValues.event,
        endpoint: formValues.endpoint,
        status: formValues.status,
        inTest: !!formValues.inTest,
        isIntegration: event.category === WebhookEventCategory.Integration,
      });
    this.snackBar.open("Successfully updated webhook subscription", "Dismiss", {
      duration: 5000,
    });
  }
  async createNewWebhookSubscription(formValues, event: WebhookEvent) {
    await this.afs.collection(`clientWebhookSubscriptions`).add({
      client: this.client.id,
      event: formValues.event,
      endpoint: formValues.endpoint,
      status: formValues.status,
      inTest: !!formValues.inTest,
      isIntegration: event.category === WebhookEventCategory.Integration,
    });
    this.snackBar.open("Successfully created webhook subscription", "Dismiss", {
      duration: 5000,
    });
  }
  public closeWebhookSubscriptionForm() {
    this.webhookSubscriptionForm = null;
    this.subscriptionInEdit = null;
  }
  public async deleteClientWebhookSubscription(
    webhookSubscription: ClientWebhookSubscription
  ) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Delete",
        message: "Are you sure you want delete this data upload log?.",
        action: "Yes, Delete.",
      },
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs
          .doc(`clientWebhookSubscriptions/${webhookSubscription.id}`)
          .delete();
        this.snackBar.open(
          "Successfully deleted webhook subscription",
          "Dismiss",
          {
            duration: 5000,
          }
        );
      }
    });
  }
  async fetchLogs(webhookSubscription: ClientWebhookSubscription) {
    this.webhookLogs = null;
    setTimeout(async () => {
      this.loadingService.isLoading(
        true,
        "Fetching subscription delivery logs"
      );
      try {
        this.webhookLogs = <ClientWebhookDelivery[]>(
          FirestoreUtilities.mapToType(
            await this.afs
              .collection("clientWebhookDeliveries", (ref) =>
                ref
                  .where(
                    "clientWebhookSubscription",
                    "==",
                    webhookSubscription.id
                  )
                  .orderBy("responseDate", "desc")
              )
              .snapshotChanges()
              .pipe(first())
              .toPromise()
          )
        );
        this.loadingService.isLoading(false);
      } catch (e) {
        console.error(e);
        this.snackBar.open(
          "Oops... something went wrong. Please refresh and try again.",
          "Dismiss",
          { duration: 5000 }
        );
        this.loadingService.isLoading(false);
      }
    });
  }
}
