import { Component, OnInit, OnDestroy, Input, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  PriorPeriodAdjustmentTransaction,
  ThirdPartyReportFragmentAdjustmentLog,
  ThirdPartyReportFragmentErrorChargeLog,
  ThirdPartyReportFragmentTransactionAnalysis,
  ThirdPartyTransaction,
} from "@deliver-sense-librarian/data-schema";
import _ from "lodash";
import { FormGroup } from "@angular/forms";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import {
  ReconciliationDrillDownReportTypes,
  ReconciliationReportDatUtility,
} from "../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../utilities/reconciliation-report-filtering.utility";
import moment from "moment";
import { DrillDownSelection } from "../daily-drill-down/daily-drill-down.component";
import { MatPaginator } from "@angular/material/paginator";

@Component({
  selector: "app-revenue-recovery-summary",
  templateUrl: "./revenue-recovery-summary.component.html",
  styleUrls: ["./revenue-recovery-summary.component.scss"],
})
export class RevenueRecoverySummaryComponent implements OnInit, OnDestroy {
  @Input() exportUtility: ReconciliationReportExportUtility;
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @Input() preErrorChargeSelection: DrillDownSelection;
  private thirdPartyReportFragmentAdjustmentLogs: ThirdPartyReportFragmentAdjustmentLog[] =
    [];
  private ppaTransactionsRefundAdjustments: PriorPeriodAdjustmentTransaction[] =
    [];
  adjustmentTransactions: ThirdPartyTransaction[];
  loadingData = true;
  public parametersForm: FormGroup;
  public transactionData: any[];
  reveueRecoverAdjustments: any[] = [];
  constructor(private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.fetchDataAndInitializeTable();
  }
  ngOnDestroy() {}

  async fetchDataAndInitializeTable() {
    try {
      this.ppaTransactionsRefundAdjustments = (
        await this.dataUtility.fetchPriorPeriodAdjustments()
      )
        .filter((ppat) => ppat.isAdjustment)
        .map((ppa) => {
          ppa.transaction.saleAdjustment = ppa.transaction.sale
            ? ppa.transaction.sale
            : ppa.transaction.saleCorrection
            ? ppa.transaction.saleCorrection
            : 0;
          ppa.transaction["thirdPartyName"] =
            this.dataUtility.getThirdPartyName(ppa.account);
          return ppa.transaction;
        });

      this.adjustmentTransactions = (
        await this.dataUtility.fetchReconciliationReportDrillDownFragments(
          ReconciliationDrillDownReportTypes.adjustmentLogs
        )
      ).filter((transaction) => {
        return (
          transaction.saleAdjustment >= 0 &&
          ((transaction.status === "Miscellaneous Payment" &&
            transaction.description === "Restaurant refunds") || //DD
            transaction.transactionType === "Account Adjustment" || //Gh
            transaction.transactionType === "ADJUSTMENT") //DD
        );
      });

      this.adjustmentTransactions.forEach((report) => {
        report["thirdPartyName"] = this.dataUtility.getThirdPartyName(
          report.thirdParty
        );
      });
      this.adjustmentTransactions = this.adjustmentTransactions.sort((a, b) => {
        return moment(a.payoutDate, "M/DD/YYYY").isBefore(
          moment(b.payoutDate, "M/DD/YYYY")
        )
          ? -1
          : 1;
      });
      this.mergeAdjustmentSources();
      this.loadingData = false;
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        "Oops.. something went wrong. Please refresh and try again.",
        "Dismiss",
        { duration: 5000 }
      );
    }
  }
  private mergeAdjustmentSources() {
    this.reveueRecoverAdjustments = [
      ...this.ppaTransactionsRefundAdjustments,
      ...this.adjustmentTransactions,
    ];
  }
}
