import { SelectionModel } from "@angular/cdk/collections";
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import {
  PriorPeriodAdjustmentTransaction,
  ThirdParty,
  PosSystem,
  Location,
  Client,
  User,
  DataUploadEvent,
  PriorPeriodAdjustmentTypes,
} from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { ConfirmDialogComponent } from "app/dialogs/confirm-dialog/confirm-dialog.component";
import { TransactionsDialogComponent } from "app/dialogs/transactions-dialog/transactions-dialog.component";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { downloadDataAsFile } from "app/shared/ds-constant";
import moment from "moment";
import { Papa } from "ngx-papaparse";
import { Subject, combineLatest, from } from "rxjs";
import { combineAll, first, takeUntil } from "rxjs/operators";
import { FirestoreUtilities } from "../../../utilities/firestore-utilities";
import { PopoverDirective } from "ngx-bootstrap/popover";
import { ReconciliationReportExportUtility } from "../3pd-reconciliation/utilities/reconciliation-report-export.utility";

@Component({
  selector: "app-prior-period-adjustments",
  templateUrl: "./prior-period-adjustments.component.html",
  styleUrls: ["./prior-period-adjustments.component.scss"],
})
export class PriorPeriodAdjustmentsComponent implements OnInit {
  @Input() inReconciliationReport = false;
  @Input() dataUploadEvent: DataUploadEvent;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() locations: Location[] = [];
  @Input() thirdParties: ThirdParty[] = [];
  @Input() reportTitle: string;
  @Input() posSystems: PosSystem[] = [];
  @Input() exportUtility: ReconciliationReportExportUtility;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    "uploadPeriodEnd",
    "location",
    "source",
    "account",
    "type",
    "transactionDate",
    "transactionPayoutDate",
    "transaction",
    "status",
    "transactionType",
    "isAdjustment",
    "isErrorCharge",
    "matchingExistingTransaction",
    "changes",
    "applied",
    "apply",
    "delete",
  ];
  public changesTableColumns = ["field", "previousValue", "currentValue"];
  changesToView: any;
  activePopover: any;
  selection = new SelectionModel<PriorPeriodAdjustmentTransaction>(true, []);

  private destroy$ = new Subject();
  public client: Client;
  public priorPeriodAdjustmentTransactions: PriorPeriodAdjustmentTransaction[];
  public loadingPriorPeriodAdjustments = false;
  public user: User;

  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private papa: Papa,
    private cdr: ChangeDetectorRef,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.store.select((store) => store.uiState.clientPosSystems),
      this.store.select((store) => store.uiState.client),
      this.store.select((store) => store.uiState.authUser),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([clientPosSystems$, client$, authUser$]) => {
        if (clientPosSystems$ && clientPosSystems$.length > 0) {
          this.posSystems = clientPosSystems$.map(
            (clientPosSystem) => clientPosSystem.posSystem
          );
          this.client = client$;
          this.user = authUser$;
          this.fetchPriorPeriodAdjustments();
        }
      });
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  public getChangeTypeText(changeTypeNumber) {
    switch (changeTypeNumber) {
      case PriorPeriodAdjustmentTypes.noChange:
        return "No Change";
      case PriorPeriodAdjustmentTypes.newEntry:
        return "New Entry";
      case PriorPeriodAdjustmentTypes.changes:
        return "Multiple Changes";
      case PriorPeriodAdjustmentTypes.payoutDateOnly:
        return "Payout Date Only";
    }
  }
  public async fetchPriorPeriodAdjustments() {
    this.loadingPriorPeriodAdjustments = true;
    let ppaTQuery;
    if (this.dataUploadEvent) {
      ppaTQuery = this.afs
        .collection("priorPeriodAdjustmentTransactions", (ref) =>
          ref
            .where("client", "==", this.client.id)
            .where("dataUploadEvent", "==", this.dataUploadEvent.id)
        )
        .snapshotChanges()
        .pipe(takeUntil(this.destroy$));
    } else {
      ppaTQuery = this.afs
        .collection("priorPeriodAdjustmentTransactions", (ref) =>
          ref
            .where("uploadPeriodEnd", ">=", this.startDate)
            .where("uploadPeriodEnd", "<=", this.endDate)
            .where("client", "==", this.client.id)
            .orderBy("uploadPeriodEnd", "desc")
        )
        .snapshotChanges()
        .pipe(takeUntil(this.destroy$));
    }
    ppaTQuery.subscribe((priorPeriodAdjustmentsQueryResults$) => {
      this.priorPeriodAdjustmentTransactions = FirestoreUtilities.mapToType(
        priorPeriodAdjustmentsQueryResults$
      );
      if (!this.dataUploadEvent) {
        this.priorPeriodAdjustmentTransactions =
          this.priorPeriodAdjustmentTransactions
            .filter((priorPeriodAdjustment) => {
              if (priorPeriodAdjustment.transaction) {
                return this.locations.find(
                  (location) =>
                    location.locationId ===
                    priorPeriodAdjustment.transaction.location
                );
              }
              return false;
            })
            .filter(
              (transaction) =>
                !!this.locations.find(
                  (location) => location.locationId === transaction.location
                )
            );
      }
      // .filter((ppaT) => {
      //   return ppaT.isAdjustment || ppaT.isErrorCharge;
      // });
      this.loadingPriorPeriodAdjustments = false;
      this.flattenPpaTransactionData();
      this.tableData = new MatTableDataSource(
        this.priorPeriodAdjustmentTransactions
      );
      this.tableData.paginator = this.paginator;
      this.tableData.sort = this.sort;
      this.cdr.detectChanges();
    });
  }
  flattenPpaTransactionData() {
    return this.priorPeriodAdjustmentTransactions.forEach((ppaTransaction) => {
      if (ppaTransaction.type === "3PD") {
        const dsp = this.thirdParties.find(
          (dsp) => dsp.id === ppaTransaction.account
        );
        ppaTransaction["accountName"] = dsp ? dsp.name : "";
      }
      ppaTransaction["changeTypeText"] = this.getChangeTypeText(
        ppaTransaction.changeType
      );
    });
  }
  exportReport() {
    const data = Object.assign(
      this.tableData.data.map((data: PriorPeriodAdjustmentTransaction) => {
        return {
          Location: data.location,
          "Upload Period End": moment(data.uploadPeriodEnd.toDate()).format(
            "l"
          ),
          Source: data.type,
          ["Change Type"]: data.changeType,
          Account: data["accountName"],
          "Transaction Date": moment(data.transactionDate.toDate()).format("l"),
          "Transaction Payout Date": data.transactionPayoutDate
            ? moment(data.transactionPayoutDate.toDate()).format("l")
            : "",
          Sale: data.transaction.sale,
          "Sale Adjustment": data.transaction.saleCorrection,
          Tax: data.transaction.tax,
          "Tax Adjustment": data.transaction.taxCorrection,
          Status: data.transaction.status,
          "Transaction Type": data.transaction.transactionType,
          Description: data.transaction.description,
          Details: data.transaction.metaData,
          "Transaction ID": data.transaction.transactionId,
          "Error Charge": data.isErrorCharge,
          Adjustment: data.isAdjustment,
          Applied: data.applied,
          "Matching Transaction": data.matchingExistingTransactions?.length,
        };
      })
    );
    const results = this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const fileName = `${this.reportTitle}_Prior-Period-Adjustments`;
    downloadDataAsFile(results, fileName, "csv");
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  public async openTransactionViewer(
    ppat: PriorPeriodAdjustmentTransaction,
    showMatches = false
  ) {
    let transactions;
    if (showMatches) {
      const collectionName =
        ppat.type === "3PD" ? "thirdPartyTransactions" : "posTransactions";
      transactions = FirestoreUtilities.mergeToType(
        await from(
          ppat.matchingExistingTransactions.map((id) =>
            this.afs.doc(`${collectionName}/${id}`).snapshotChanges()
          )
        )
          .pipe(combineAll(), first())
          .toPromise()
      );
    } else {
      transactions = [ppat.transaction];
    }
    this.dialog.open(TransactionsDialogComponent, {
      panelClass: "invisible-panel-dialog",
      data: {
        transactions: transactions,
      },
    });
  }
  public setChangesToView(
    ppat: PriorPeriodAdjustmentTransaction,
    activePopover: PopoverDirective
  ) {
    this.changesToView = ppat.changes;
    this.activePopover = activePopover;
  }
  getAccountName(
    priorPeriodAdjustmentTransaction: PriorPeriodAdjustmentTransaction
  ) {
    let account;
    if (priorPeriodAdjustmentTransaction.type === "POS") {
      account = this.posSystems.find(
        (posSystem) => posSystem.id === priorPeriodAdjustmentTransaction.account
      );
    } else {
      account = this.thirdParties.find(
        (thirdParty) =>
          thirdParty.id === priorPeriodAdjustmentTransaction.account
      );
    }
    return account ? account.name : "";
  }

  deleteAdjustment(priorPeriodAdjustmentTransactionId: string) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Delete Adjustment",
        message: `Are you sure you want to delete this prior period adjustment? This cannot be undone, and any applied changes cannot reset.`,
        action: "Yes, Delete",
      },
    });
    confirmDialog.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs
          .doc(
            `priorPeriodAdjustmentTransactions/${priorPeriodAdjustmentTransactionId}`
          )
          .delete();
        this.snackBar.open(
          `Successfully deleted prior period adjustment.`,
          `Dismiss`,
          { duration: 5000 }
        );
      }
    });
  }
  async applyChanges(
    priorPeriodAdjustmentTransaction: PriorPeriodAdjustmentTransaction
  ) {
    const changeObj = priorPeriodAdjustmentTransaction.changes.map(
      (changeLog) => {
        const change = {};
        change[changeLog.field] = change[changeLog.currentValue];
      }
    );
    await this.setChanges(priorPeriodAdjustmentTransaction, changeObj, true);
    this.snackBar.open("Successfully applied changes", "Dismiss", {
      duration: 5000,
    });
  }
  async undoChanges(
    priorPeriodAdjustmentTransaction: PriorPeriodAdjustmentTransaction
  ) {
    const changeObj = priorPeriodAdjustmentTransaction.changes.map(
      (changeLog) => {
        const change = {};
        change[changeLog.field] = change[changeLog.previousValue];
      }
    );
    await this.setChanges(priorPeriodAdjustmentTransaction, changeObj, false);
    this.snackBar.open("Undo changes was successful", "Dismiss", {
      duration: 5000,
    });
  }
  async setChanges(
    priorPeriodAdjustmentTransaction: PriorPeriodAdjustmentTransaction,
    changeObj,
    applied: boolean
  ) {
    const collection =
      priorPeriodAdjustmentTransaction.type === "3PD"
        ? "thirdPartyTrasactions"
        : "posTransactions";
    await this.afs
      .doc(
        `${collection}/${priorPeriodAdjustmentTransaction.matchingExistingTransactions[0]}`
      )
      .update(changeObj);
    await this.afs
      .doc(
        `priorPeriodAdjustmentTrasactions/${priorPeriodAdjustmentTransaction.id}`
      )
      .update({
        applied,
        dateUpdated: moment().toDate(),
      });
  }
  async pushMultiple() {
    const ppaTransactionsToPush = this.selection.selected.filter((data) => {
      return (
        data.applied && data.changeType === PriorPeriodAdjustmentTypes.newEntry
      );
    });
    try {
      this.loadingService.isLoading(true, "Pushing transactions...");
      await Promise.all(
        ppaTransactionsToPush.map((data) => this.pushTransaction(data))
      );
      this.loadingService.isLoading(false);
      this.snackBar.open(
        "Successfully pushed unapplied transactions from selection",
        "Dismiss",
        { duration: 5000 }
      );
      this.selection.clear();
    } catch (e) {
      console.error(e.message);
      this.loadingService.isLoading(false);
      this.selection.clear();
      this.snackBar.open(
        `Oops... something went wrong. Please refresh and try again.`,
        "Dismiss",
        { duration: 5000 }
      );
    }
  }
  async pullMultiple() {
    const ppaTransactionsToPush = this.selection.selected.filter((data) => {
      return (
        data.applied && data.changeType === PriorPeriodAdjustmentTypes.newEntry
      );
    });
    try {
      this.loadingService.isLoading(true, "Pulling transactions...");
      await Promise.all(
        ppaTransactionsToPush.map((data) => this.pullTransaction(data))
      );
      this.loadingService.isLoading(false);
      this.selection.clear();
      this.snackBar.open(
        "Successfully pulled previously applied transactions from selection",
        "Dismiss",
        { duration: 5000 }
      );
    } catch (e) {
      console.error(e.message);
      this.loadingService.isLoading(false);
      this.selection.clear();
      this.snackBar.open(
        `Oops... something went wrong. Please refresh and try again.`,
        "Dismiss",
        { duration: 5000 }
      );
    }
  }

  async pushTransaction(
    priorPeriodAdjustmentTransaction: PriorPeriodAdjustmentTransaction
  ) {
    const transactionType =
      priorPeriodAdjustmentTransaction.type === "3PD"
        ? "thirdPartyTransactions"
        : "posTransactions";
    const transactionId = priorPeriodAdjustmentTransaction.transaction.id;
    const transactionData = Object.assign(
      priorPeriodAdjustmentTransaction.transaction
    );
    await this.afs
      .doc(`${transactionType}/${transactionId}`)
      .set(transactionData);
    await this.afs
      .doc(
        `priorPeriodAdjustmentTransactions/${priorPeriodAdjustmentTransaction.id}`
      )
      .update({
        applied: true,
        dateUpdated: moment().toDate(),
      });
  }

  async pullTransaction(
    priorPeriodAdjustmentTransaction: PriorPeriodAdjustmentTransaction
  ) {
    const transactionType =
      priorPeriodAdjustmentTransaction.type === "3PD"
        ? "thirdPartyTransactions"
        : "posTransactions";
    const transactionId = priorPeriodAdjustmentTransaction.transaction.id;

    await this.afs.doc(`${transactionType}/${transactionId}`).delete();
    await this.afs
      .doc(
        `priorPeriodAdjustmentTransactions/${priorPeriodAdjustmentTransaction.id}`
      )
      .update({
        applied: false,
        dateUpdated: moment().toDate(),
      });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableData.data.length;
    return numSelected === numRows;
  }

  masterLocationsToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.tableData.data.forEach((row) => {
          this.selection.select(row);
        });
  }
}
