import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-data-upload-view-dialog",
  templateUrl: "./data-upload-view-dialog.component.html",
  styleUrls: ["./data-upload-view-dialog.component.scss"],
})
export class DataUploadViewDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DataUploadViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}
}
