import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class NotificationsService {
  constructor(private snackBar: MatSnackBar) {}

  showNotification(message: string, duration = 5000, action = "Dismiss") {
    return this.snackBar.open(message, action, { duration: duration });
  }
}
