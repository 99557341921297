import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ClientComponent } from "./client/client.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModule } from "../../../material.module";
import { FileUploadModule } from "../../file-upload/file-upload.module";
import { ClientDayChargesComponent } from "./client-day-charges/client-day-charges.component";
import { ClientBillingComponent } from "./client-billing/client-billing.component";
import { ClientInvoicesComponent } from "./client-invoices/client-invoices.component";
import { StripeCardModule } from "app/components/stripe-card/stripe-card.module";
import { ClientCreditBalanceComponent } from "./client-credit-balance/client-credit-balance.component";
import { ClientModulesComponent } from "./client-modules/client-modules.component";
import { ClientPaymentDetailsComponent } from "./client-payment-details/client-payment-details.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { UxModule } from "app/components/ux/ux.module";
import { ClientDeveloperComponent } from "./client-developer/client-developer.component";
import { ClientWebhookLogComponent } from "./client-webhook-log/client-webhook-log.component";
import { ClientWebhooksComponent } from "./client-webhooks/client-webhooks.component";
import { PopoverModule } from "ngx-bootstrap/popover";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { EditorModule } from "primeng/editor";

@NgModule({
  declarations: [
    ClientComponent,
    ClientDayChargesComponent,
    ClientBillingComponent,
    ClientInvoicesComponent,
    ClientCreditBalanceComponent,
    ClientModulesComponent,
    ClientPaymentDetailsComponent,
    ClientDeveloperComponent,
    ClientWebhookLogComponent,
    ClientWebhooksComponent,
  ],
  exports: [
    ClientComponent,
    ClientDayChargesComponent,
    ClientBillingComponent,
    ClientInvoicesComponent,
    ClientCreditBalanceComponent,
    ClientPaymentDetailsComponent,
    ClientModulesComponent,
    ClientWebhookLogComponent,
    ClientWebhooksComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    MaterialModule,
    FileUploadModule,
    StripeCardModule,
    ClipboardModule,
    UxModule,
    PopoverModule.forRoot(),
    PerfectScrollbarModule,
    EditorModule,
  ],
})
export class ClientsModule {}
