import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import * as _ from "lodash";
import { ThirdPartyReportFragmentDataLog } from "@deliver-sense-librarian/data-schema";
import { Subject } from "rxjs";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import {
  ReconciliationDrillDownReportTypes,
  ReconciliationReportDatUtility,
} from "../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../utilities/reconciliation-report-filtering.utility";
import { FormControl } from "@angular/forms";
@Component({
  selector: "app-data-validation-report",
  templateUrl: "./data-validation-report.component.html",
  styleUrls: ["./data-validation-report.component.scss"],
})
export class DataValidationReportComponent implements OnInit, OnDestroy {
  @Input() exportUtility: ReconciliationReportExportUtility;
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public totalPosTransactions: number;
  public total3pdTransactions: number;
  public filter3pdsControl = new FormControl([]);
  public filterLocationsControl = new FormControl([]);
  public tableData: {
    location: string;
    thirdParty: string;
    thirdPartyName: string;
    posTransactions: number;
    thirdPartyTransactions: number;
  }[] = [];

  private destroy$ = new Subject();
  thirdPartyReportFragmentDataLogs: ThirdPartyReportFragmentDataLog[] = [];
  loadingDataAnalytics = true;
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.compileTableData();
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private async compileTableData() {
    this.thirdPartyReportFragmentDataLogs =
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.dataLogs
      );
    this.loadingDataAnalytics = false;
    this.totalPosTransactions = this.thirdPartyReportFragmentDataLogs.reduce(
      (sum, dataLog) => {
        return (sum += +dataLog.posTransactionCount
          ? +dataLog.posTransactionCount
          : 0);
      },
      0
    );
    this.total3pdTransactions = this.thirdPartyReportFragmentDataLogs.reduce(
      (sum, dataLog) => {
        return (sum += +dataLog.thirdPartyTransactionCount
          ? +dataLog.thirdPartyTransactionCount
          : 0);
      },
      0
    );

    const logsByLocation = _.groupBy(
      this.thirdPartyReportFragmentDataLogs,
      "location"
    );
    Object.keys(logsByLocation).forEach((locationId) => {
      const locationLogsBy3pd = _.groupBy(
        logsByLocation[locationId],
        "thirdParty"
      );
      Object.keys(locationLogsBy3pd).forEach((dspId) => {
        this.tableData.push({
          location: locationId,
          thirdParty: dspId,
          thirdPartyName: this.dataUtility.thirdParties.find(
            (dsp) => dsp.id === dspId
          ).name,
          thirdPartyTransactions: locationLogsBy3pd[dspId].reduce(
            (sum, log) => {
              return (sum += +log.thirdPartyTransactionCount
                ? +log.thirdPartyTransactionCount
                : 0);
            },
            0
          ),
          posTransactions: locationLogsBy3pd[dspId].reduce((sum, log) => {
            return (sum += +log.posTransactionCount
              ? +log.posTransactionCount
              : 0);
          }, 0),
        });
      });
      _.sortBy(this.tableData, ["posTransactions", "thirdPartyTransactions"]);
    });
  }
}
