import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FirebaseAuthService } from "../auth/services/firebase-auth.service";
import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { map, first, switchMap, catchError } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { environment } from "environments/environment";
import { of } from "rxjs";
import { ClearSfaAction } from "../redux/custom-states/uiState/ui-state-actions/authentication-actions";

@Injectable()
export class SecondFactorAuthenticationGuard implements CanActivate {
  constructor(
    private afAuth: AngularFireAuth,
    private authService: FirebaseAuthService,
    private router: Router,
    private http: HttpClient,
    private store: Store<any>
  ) {}
  private revokeSfaAndClient(sfaToken) {}
  canActivate() {
    const sfaToken = localStorage.getItem("sfaToken");
    const client_data = JSON.parse(localStorage.getItem("client"));
    // should already have client check from clientSelectedGuard no need to check for client;
    if (client_data && client_data.sfaRequired) {
      if (sfaToken) {
        return this.afAuth.idToken.pipe(
          map((token) => {
            const headerToken = new HttpHeaders().set(
              "Authorization",
              `Bearer ${token}${sfaToken ? `,Bearer ${sfaToken}` : ""}`
            );
            return { headers: headerToken };
          }),
          switchMap((header) => {
            const url = `${environment.apiUrl}sfa-check/${client_data.id}`;
            return this.http.get(url, header).pipe(
              map((isValid) => {
                if (isValid) {
                  return true;
                } else {
                  this.store.dispatch(new ClearSfaAction(sfaToken));
                  this.router.navigate(["client-selection"]);
                  return false;
                }
              }),
              catchError((e) => {
                this.store.dispatch(new ClearSfaAction(sfaToken));
                this.router.navigate(["client-selection"]);
                return of(false);
              })
            );
          })
        );
      } else {
        this.router.navigateByUrl(`sfa/${client_data.id}`);
        return of(false);
      }
    } else {
      return of(true);
    }
  }
}
