import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { UserRoles } from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { UiState } from "app/redux/custom-states/uiState/ui-state";
import { Subject } from "rxjs";
import { takeUntil, distinctUntilChanged } from "rxjs/operators";
@Directive({
  selector: "[appUserAuthorized]",
})
export class UserAuthorizedDirective implements OnInit, OnDestroy {
  @Input() allowedAccessLevel: UserRoles = 2;
  private destroy$ = new Subject();
  private uiState: UiState;
  constructor(private store: Store<any>, private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.elementRef.nativeElement.style.display = "none";
    this.store
      .select((store) => store.uiState)
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((a: UiState, b: UiState) => {
          const aComp = { client: a.clientRole, user: a.authUser };
          const bComp = { client: b.clientRole, user: b.authUser };
          return JSON.stringify(aComp) === JSON.stringify(bComp);
        })
      )
      .subscribe((uiState$: UiState) => {
        this.uiState = uiState$;
        if (
          this.uiState.authUser?.internalRole > 0 ||
          (this.uiState.clientRole && this.authorized())
        ) {
          this.elementRef.nativeElement.style.display = "block";
        }
      });
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  private authorized() {
    return this.uiState.clientRole >= this.allowedAccessLevel;
  }
}
