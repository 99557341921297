import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { getTimeFromMinutesFrom } from "app/shared/ds-constant";
import { ReconciliationReportDatUtility } from "../../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../../utilities/reconciliation-report-filtering.utility";
import { FormControl } from "@angular/forms";
import { AfterViewInit } from "@angular/core";

@Component({
  selector: "app-potential-fraud-report-table",
  templateUrl: "./potential-fraud-report-table.component.html",
  styleUrls: ["./potential-fraud-report-table.component.scss"],
})
export class PotentialFraudReportTableComponent
  implements OnInit, AfterViewInit
{
  @Input() data: any[] = [];
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    "location",
    "thirdParty",
    "date",
    "posTransactionId",
    "sale",
    "tax",
    "tip",
  ];

  public filter3pdsControl = new FormControl([]);
  public filterLocationsControl = new FormControl([]);
  constructor(private dialog: MatDialog) {}
  ngOnInit(): void {
    this.tableData = new MatTableDataSource(this.data);
  }
  ngAfterViewInit(): void {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.filteringUtility.set3pdAndLocationFilterPredicate(
      this.tableData,
      this.filter3pdsControl,
      this.filterLocationsControl
    );
  }
  getFormattedTime(minutes) {
    return getTimeFromMinutesFrom(minutes);
  }
}
