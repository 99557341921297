import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ElementRef,
} from "@angular/core";
import {
  Client,
  ClientInvoice,
  User,
} from "@deliver-sense-librarian/data-schema";
import { UiState } from "app/redux/custom-states/uiState/ui-state";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AngularFirestore } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import {} from "../../../../shared/ds-constant";
import * as moment from "moment";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-billing-recent-invoices",
  templateUrl: "./billing-recent-invoices.component.html",
  styleUrls: ["./billing-recent-invoices.component.scss"],
})
export class BillingRecentInvoicesComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public user: User;
  public client: Client;
  public uiState: UiState;
  private destroy$ = new Subject();
  clientInvoices: ClientInvoice[];
  loadingWidgetData = false;
  constructor(
    private store: Store<any>,
    private afs: AngularFirestore,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.store
      .select((store) => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((uiState$) => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$;
          this.getRecentInvoices();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  ngAfterViewInit() {}
  getRecentInvoices() {
    this.loadingWidgetData = true;
    this.afs
      .collection("clientInvoices", (ref) =>
        ref
          .where("client", "==", this.client.id)
          .orderBy("dateUpdated", "desc")
          .limit(5)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (clientInvoices$) => {
          this.clientInvoices = <ClientInvoice[]>(
            FirestoreUtilities.mapToType(clientInvoices$)
          );
          this.loadingWidgetData = false;
        },
        () => {
          this.loadingWidgetData = false;
        }
      );
  }
  payNow(clientInvoice: ClientInvoice) {
    window.open(clientInvoice.stripeInvoice.hosted_invoice_url, "_blank");
  }
  getFormattedDate(unix) {
    return unix ? moment.unix(unix).format("M/D/YYYY") : "";
  }
}
