import * as JSZip from "jszip";
import {
  Location,
  reconciliationTableColumnMappings,
  ThirdPartyReconciliationLocationData,
  ThirdPartyReportFragmentDailyDrillDownReport,
  ThirdPartyReportFragmentTransactionAnalysis,
  ThirdPartyReportFragmentVarianceAnalysisReport,
  TransactionAITypes,
  Entity,
  ThirdPartyReportNote,
  UserView,
  ThirdPartyReportFragmentDispatchReport,
  recColumns,
  ThirdPartyReportTypes,
  ReportDelivery,
  ThirdPartyReportFragmentAdjustmentLog,
  ThirdPartyReportTransactionFlag,
  PayoutReportFragmentLog,
  ThirdPartyRating,
  ThirdPartyDeliveryIds,
  ClientJeHeaderConfigurationGroup,
} from "@deliver-sense-librarian/data-schema";
import {
  getTimeFromMinutesFrom,
  getTimeFromMinutesFromMidnight,
} from "app/shared/ds-constant";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import * as _ from "lodash";
import moment from "moment";
import { Papa } from "ngx-papaparse";
import { AngularFirestore } from "@angular/fire/firestore";
import { first } from "rxjs/operators";
import { Workbook, Worksheet } from "exceljs";
import * as fs from "file-saver";
import {
  ThirdPartyCategoryReport,
  ThirdPartyPerformanceSummaryReport,
} from "@deliver-sense-librarian/data-schema";
import {
  ThirdPartyReportFragmentTaxRateReport,
  ThirdPartyReportFragmentPayoutReport,
} from "@deliver-sense-librarian/data-schema";
import {
  ReconciliationDrillDownReportTypes,
  ReconciliationReportDatUtility,
} from "./reconciliation-report-data.utility";
import { compileTGIFJournalEntries } from "./client-custom-reports.ts/tgif-je";
import { UploadDocumentService } from "../../../../services/upload-document.service";
import { compileYTCJournalEntries } from "./client-custom-reports.ts/tgif-ytc-je";
import { ThirdPartyTransaction } from "@deliver-sense-librarian/data-schema";
import { compileDistrictTacoJournalEntries } from "./client-custom-reports.ts/district-taco";
import { data } from "jquery";
import { JournalEntryGenerator } from "./reconciliation-je-generator";
import { compileBojanglesJEs } from "./client-custom-reports.ts/bojangles";
import { lastValueFrom } from "rxjs";
import { compileVirturantCustomReports } from "./client-custom-reports.ts/virturant";

export class ReconciliationReportExportUtility {
  constructor(
    private papa: Papa,
    private afs: AngularFirestore,
    private dataUtility: ReconciliationReportDatUtility,
    private uploadDocumentService: UploadDocumentService
  ) {}

  public createReconciilationReport() {
    const filteredData = this.flattenReconciliationReportData().map((row) => {
      const filteredFieldsObject = {};
      this.dataUtility.client3pdConfiguration.defaultFields.forEach(
        (fieldName) => {
          filteredFieldsObject[fieldName] = row[fieldName];
        }
      );
      return filteredFieldsObject;
    });
    const csv = this.jsonToCsv(filteredData);
    return {
      fileName: `${this.dataUtility.existingReport.name}-Reconciliation`,
      data: csv,
    };
  }
  public flattenReconciliationReportData() {
    return this.dataUtility.reportData.map(
      (row: ThirdPartyReconciliationLocationData) => {
        const location = this.dataUtility.locations.find(
          (_l) => _l.locationId === row.locationId
        );
        const exportLocationMap = {};
        Object.keys(reconciliationTableColumnMappings).forEach((key) => {
          exportLocationMap[key] = row[reconciliationTableColumnMappings[key]];
        });
        return exportLocationMap;
      }
    );
  }
  public mapDailyReportErrorCharges(dailyReports) {
    dailyReports.forEach((dailyReport) => {
      const errorChargeTransactions = dailyReport.analysis
        .map((analysis) => {
          return analysis.type === "Error Charge" ? analysis : null;
        })
        .filter((analysis) => !!analysis);
      dailyReport["errorChargeTransactions"] = errorChargeTransactions;
      dailyReport["errorCharges"] = +errorChargeTransactions
        .reduce((sum, analysis) => {
          return (sum += analysis.sale ? analysis.sale : 0);
        }, 0)
        .toFixed(2);
      // pull up adjustments to daily reports
      const adjustmentTransactions = dailyReport.analysis
        .map((analysis) => {
          return analysis.type === "Adjustment" ? analysis : null;
        })
        .filter((analysis) => !!analysis);
      dailyReport["adjustmentTransactions"] = adjustmentTransactions;
      dailyReport["adjustments"] = +adjustmentTransactions
        .reduce((sum, analysis) => {
          return (sum += analysis.sale ? analysis.sale : 0);
        }, 0)
        .toFixed(2);
    });
  }
  createDayDrillDownReport(
    thirdPartyReportFragmentDayDrillDownReports: ThirdPartyReportFragmentDailyDrillDownReport[]
  ) {
    const dailyReports = _.flatten(
      thirdPartyReportFragmentDayDrillDownReports.map(
        (dayReport) => dayReport.report
      )
    ).sort((curr, next) => {
      return moment(curr.date.toDate()).isSameOrBefore(next.date.toDate())
        ? -1
        : 1;
    });
    this.mapDailyReportErrorCharges(dailyReports);

    const data = Object.assign(
      dailyReports.map((data) => {
        return {
          Date: data.date
            ? moment(data.date.toDate()).format("MM/DD/YYYY")
            : "",
          Location: data.location,
          "3PD": this.dataUtility.getThirdPartyName(data.thirdParty),
          "Pos Transactions": data.posTransactionCount,
          "3PD Transactions": data.thirdPartyTransactionCount,
          "Excluded 3PD Transactions":
            data.thirdPartyStatusFilteredTransactions?.length,
          "Error Charge Transactions": data.errorChargeTransactions?.length,
          "Adjustment Transactions": data.adjustmentTransaction?.length,
          "POS Sales": data.posSales,
          "3PD Sales": data.thirdPartySales,
          "Sales Variance": data.daySalesVariance,
          "3PD Excluded Sales": data.excludedThirdPartySales,
          "Error Charges": data.errorCharges,
          Adjustments: data.adjustments,
          "POS Tax": data.posTax,
          "3PD Tax": data.thirdPartyTax,
          "Tax Variance": data.dayTaxVariance,
          "3PD Excluded Tax": data.excludedThirdPartyTax,
        };
      })
    );
    const csv = this.jsonToCsv(data);
    return {
      fileName: `${this.dataUtility.existingReport.name}-All_Daily-Drill-Down`,
      data: csv,
    };
  }

  getReasonsCsv(reasonsArr) {
    return _.join(reasonsArr, " \n");
  }
  getAnomaliesCsv(anomalies: any) {
    const anomaliesText = anomalies.map((anomaly) => {
      return anomaly.date
        ? `${moment(anomaly.date.toDate()).format("MM/DD/YYYY")} - ${
            anomaly.type
          }: 3PD ${anomaly.thirdPartyTransactionsCount}, POS ${
            anomaly.posTransactionCount
          }${anomaly.description ? ` (${anomaly.description})` : ""}`
        : "";
    });
    return _.join(anomaliesText, "\n");
  }

  getFormattedTime(minutes) {
    return getTimeFromMinutesFrom(minutes);
  }

  /**
   * HELPER FUNCTIONS
   **/
  jsonToCsv(data) {
    return this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
  }
  async zipDownload(
    files: { fileName: string; data: any }[],
    reportName: string
  ) {
    let zip = new JSZip();
    // Fill CSV variable
    files.forEach((fileDefinition) => {
      zip.file(`${fileDefinition.fileName}.csv`, fileDefinition.data);
    });
    const zipContent = await zip.generateAsync({
      type: "base64",
    });
    const link = document.createElement("a");
    link.href = "data:application/zip;base64," + zipContent;
    link.download = `${reportName}-Reconciliation-Reports`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  async sendRecSummaryEmails(blob: Blob, fileName: string, entity?: Entity) {
    const reportDelivery = new ReportDelivery();
    reportDelivery.client = this.dataUtility.client.id;
    reportDelivery.entity = entity ? entity.id : null;
    reportDelivery.recipients = entity
      ? entity.recRecipients
      : this.dataUtility.client.clientRecRecipients;
    reportDelivery.report = this.dataUtility.existingReport.id;
    reportDelivery.fileName = fileName;
    const uploadResult = await this.uploadDocumentService.uploadSingle(
      blob,
      `clients/${this.dataUtility.client.id}/3pd/reportDeliveries/${fileName}`,
      null,
      this.dataUtility.destroy
    );
    reportDelivery.filePath = uploadResult.filePath;
    this.afs.collection("reportDeliveries").add(reportDelivery.toJSONObject());
  }
  /**ENTITY COMPILATION */
  async compileAndDownloadReconciliationSummaryByEntity(
    entities: Entity[],
    selectedReports: ThirdPartyReportTypes[],
    skipChecks: boolean,
    progress: { progress: number },
    sendEmails?: boolean,
    jeHeaderSelection?: ClientJeHeaderConfigurationGroup
  ) {
    const locationsByEntity = _.groupBy(this.dataUtility.locations, "entity");
    const summaryBlobs = (
      await Promise.all(
        Object.keys(locationsByEntity).map(async (entityId) => {
          const entityLocations = locationsByEntity[entityId];
          const entity = entities.find((entity) => entity.id === entityId);
          if (entity) {
            const summaryXml =
              await this.compileAndDownloadReconciliationSummary(
                selectedReports,
                true,
                skipChecks,
                progress,
                entityLocations,
                false,
                jeHeaderSelection
              );
            return {
              blob: summaryXml,
              fileName: `${this.dataUtility.client.name}-${entity.name}_REC_SUMMARY_${this.dataUtility.existingReport.name}.xlsx`,
              entity: entity,
            };
          }
        })
      )
    ).filter((summaryBlob) => !!summaryBlob);
    if (sendEmails) {
      await Promise.all(
        summaryBlobs.map((summaryBlob) =>
          this.sendRecSummaryEmails(
            summaryBlob.blob,
            summaryBlob.fileName,
            summaryBlob.entity
          )
        )
      );
    }
    let zip = new JSZip();
    summaryBlobs.forEach((fileDefinition) => {
      zip.file(fileDefinition.fileName, fileDefinition.blob);
    });
    const zipContent = await zip.generateAsync({
      type: "base64",
    });
    const link = document.createElement("a");
    link.href = "data:application/zip;base64," + zipContent;
    link.download = `${this.dataUtility.existingReport.name}-Reconciliation-Summary(by-entity)`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return;
  }

  async compileAndDownloadReconciliationSummary(
    selectedReports: any[],
    returnBlob?: boolean,
    skipChecks?: boolean,
    progress: { progress: number } = { progress: 0 },
    locations?: Location[],
    sendEmails = false,
    jeHeaderSelection?: ClientJeHeaderConfigurationGroup
  ) {
    const progressStep = 95 / selectedReports.length;
    const reportLocations = locations ? locations : this.dataUtility.locations;
    const recSummaryWorkbook = new Workbook();
    // SUMMARY SHEET
    if (
      selectedReports.indexOf(ThirdPartyReportTypes.reconciliationSummary) > -1
    ) {
      const summarySheet = recSummaryWorkbook.addWorksheet("Summary");
      await this.compileSummarySheet(summarySheet, reportLocations);
      progress.progress += progressStep;
    }
    if (
      selectedReports.indexOf(
        ThirdPartyReportTypes.reconciliationSummaryBreakout
      ) > -1
    ) {
      const summarySheet = recSummaryWorkbook.addWorksheet("SummaryDetail");
      await this.compileSummaryBreakoutSheet(summarySheet, reportLocations);
      progress.progress += progressStep;
    }
    /**
     * Analytics Breakout Summaries
     */
    if (
      selectedReports.indexOf(
        ThirdPartyReportTypes.thirdPartyAnalyticsBreakoutSummary
      ) > -1
    ) {
      const analyticsSummarySheet = recSummaryWorkbook.addWorksheet(
        "Analytics Breakout Summary"
      );
      await this.compileAnalyticsBreakoutSummarySheet(
        analyticsSummarySheet,
        reportLocations
      );
      progress.progress += progressStep;
    }
    // if (
    //   selectedReports.indexOf(
    //     ThirdPartyReportTypes.thirdPartyAnalyticsBreakoutSummaryAggregated
    //   ) > -1
    // ) {
    //   const analyticsSummarySheet = recSummaryWorkbook.addWorksheet(
    //     "Analytics Breakout Summary - Aggregated"
    //   );
    //   await this.compileAnalyticsBreakoutAggregatedSummarySheet(
    //     analyticsSummarySheet,
    //     reportLocations
    //   );
    //   progress.progress += progressStep;
    // }
    /**
     * Performance Summary Sheet
     */
    if (
      selectedReports.indexOf(ThirdPartyReportTypes.thirdPartyPerformance) > -1
    ) {
      const thirdPartyPeformanceSheet =
        recSummaryWorkbook.addWorksheet("Order Accuracy");
      await this.compile3pdPerformanceSheet(
        thirdPartyPeformanceSheet,
        reportLocations
      );
      progress.progress += progressStep;
    }
    //VARIANCE ANALYSIS SHEET
    if (
      this.dataUtility.existingReport.includePosInRec &&
      selectedReports.indexOf(ThirdPartyReportTypes.varianceAnalysis) > -1
    ) {
      const varianceAnalysisSheet =
        recSummaryWorkbook.addWorksheet("Variance Analysis");
      await this.compileVarianceAnalysisSheet(
        varianceAnalysisSheet,
        reportLocations
      );
      progress.progress += progressStep;
    }
    if (
      this.dataUtility.existingReport.includePosInRec &&
      selectedReports.indexOf(ThirdPartyReportTypes.transactionAnalysis) > -1
    ) {
      const transactionAnalysisSheet = recSummaryWorkbook.addWorksheet(
        "Transaction Analysis"
      );
      await this.compileTransactionAnalysisSheet(
        transactionAnalysisSheet,
        reportLocations
      );
      progress.progress += progressStep;
    }
    //ERROR CHARGE SHEET
    if (
      selectedReports.indexOf(ThirdPartyReportTypes.errorChargeSummary) > -1
    ) {
      const errorChargeSheet = recSummaryWorkbook.addWorksheet("Error Charges");
      await this.compileErrorChargeSheet(errorChargeSheet, reportLocations);
      progress.progress += progressStep;
    }

    if (selectedReports.indexOf(ThirdPartyReportTypes.dispatchSummary) > -1) {
      const dispatchSummarySheet =
        recSummaryWorkbook.addWorksheet("Dispatch Summary");
      await this.compileDispatchSummaryReport(
        dispatchSummarySheet,
        reportLocations
      );
      progress.progress += progressStep;
    }

    /**
     * Adjustments Summary Sheet
     */
    if (
      selectedReports.indexOf(ThirdPartyReportTypes.adjustmentsAnalysis) > -1
    ) {
      const adjustmentsSummarySheet = recSummaryWorkbook.addWorksheet(
        "Adjustments Summary"
      );
      await this.compileAdjustmentSummarySheet(
        adjustmentsSummarySheet,
        reportLocations
      );
      progress.progress += progressStep;
    }
    /**
     * Revenue Recovery
     */
    if (selectedReports.indexOf(ThirdPartyReportTypes.revenueRecovered) > -1) {
      const revenueRecoveredSheet =
        recSummaryWorkbook.addWorksheet("Revenue Recovered");
      await this.compileRevenueRecoveredSheet(
        revenueRecoveredSheet,
        reportLocations
      );
      progress.progress += progressStep;
    }
    /**
     * Net Cash Sheet
     */
    if (selectedReports.indexOf(ThirdPartyReportTypes.netCash) > -1) {
      const netCashSheet = recSummaryWorkbook.addWorksheet("Net Cash");
      await this.compileNetCashReport(netCashSheet, reportLocations);
      progress.progress += progressStep;
    }
    /**
     * Fee Summary Sheet
     */
    if (selectedReports.indexOf(ThirdPartyReportTypes.feesSummary) > -1) {
      const feeSummarySheet = recSummaryWorkbook.addWorksheet("Fees Summary");
      await this.compileFeeSummarySheet(feeSummarySheet, reportLocations);
      progress.progress += progressStep;
    }
    /**
     * Fee Analysis Sheet
     */
    if (selectedReports.indexOf(ThirdPartyReportTypes.feeAnalysis) > -1) {
      const feeAnalysisSheet = recSummaryWorkbook.addWorksheet("Fee Analysis");
      await this.compileFeeAnalysisSheet(feeAnalysisSheet, reportLocations);
      progress.progress += progressStep;
    }
    /**
     * TAX SUMMARY
     */
    if (selectedReports.indexOf(ThirdPartyReportTypes.taxSummary) > -1) {
      const taxReportSheet = recSummaryWorkbook.addWorksheet("Tax Report");
      await this.compileTaxSummarySheet(taxReportSheet, reportLocations);
      progress.progress += progressStep;
    }
    /**
     * Tax Rate Analysis Sheet
     */
    if (selectedReports.indexOf(ThirdPartyReportTypes.taxRateAnalysis) > -1) {
      const taxRateAnalysisSheet =
        recSummaryWorkbook.addWorksheet("Tax Rate Analysis");
      await this.compileTaxRateAnalysisSheet(
        taxRateAnalysisSheet,
        reportLocations
      );
      progress.progress += progressStep;
    }
    /**
     * Payout Analysis Sheet
     */
    if (selectedReports.indexOf(ThirdPartyReportTypes.payoutAnalysis) > -1) {
      const payoutAnalysisSheet =
        recSummaryWorkbook.addWorksheet("Payout Analysis");
      await this.compilePayoutAnalysisSheet(
        payoutAnalysisSheet,
        reportLocations
      );
      progress.progress += progressStep;
    }
    /**
     * Status Analysis Sheet
     */
    if (selectedReports.indexOf(ThirdPartyReportTypes.statusDrillDown) > -1) {
      const statusAnalysisSheet =
        recSummaryWorkbook.addWorksheet("Status Analysis");
      await this.compileStatusAnalysisSheet(
        statusAnalysisSheet,
        reportLocations
      );
      progress.progress += progressStep;
    }
    /**
     * Potential Fraud Sheet
     */
    if (
      selectedReports.indexOf(ThirdPartyReportTypes.potentialFraudAnalysis) > -1
    ) {
      const fraudDetectionSheet = recSummaryWorkbook.addWorksheet("POS Misuse");
      await this.compileFraudDetectionSheet(
        fraudDetectionSheet,
        reportLocations
      );
      progress.progress += progressStep;
    }
    /**TIP Variance */
    if (selectedReports.indexOf(ThirdPartyReportTypes.tipVariance) > -1) {
      const tipVarianceSheet = recSummaryWorkbook.addWorksheet("Tip Variance");
      await this.compileTipVarianceSheet(tipVarianceSheet, reportLocations);
    }
    /**
     * Revenue Recovery
     */
    if (selectedReports.indexOf(ThirdPartyReportTypes.canceledOrders) > -1) {
      const canceledOrdersSheet = recSummaryWorkbook.addWorksheet(
        "Canceled Orders Summary"
      );
      await this.compileCanceledOrdersSheet(
        canceledOrdersSheet,
        reportLocations
      );
      progress.progress += progressStep;
    }
    /**
     * Report Notes Sheet
     */
    if (
      selectedReports.indexOf(ThirdPartyReportTypes.reconciliationNotes) > -1
    ) {
      const reportNotesSheet = recSummaryWorkbook.addWorksheet("Review Notes");
      await this.compileReportNotesSheet(reportNotesSheet, reportLocations);
      progress.progress += progressStep;
    }
    /**Deposit Reports */
    if (selectedReports.indexOf(ThirdPartyReportTypes.depositReport) > -1) {
      const depositsSheet = recSummaryWorkbook.addWorksheet("Deposits");
      await this.compileDepositsSheet(depositsSheet, reportLocations);
      progress.progress += progressStep;
    }
    /** Customer Ratings */
    if (selectedReports.indexOf(ThirdPartyReportTypes.customerRatings) > -1) {
      const customerRatingsSheet =
        recSummaryWorkbook.addWorksheet("Customer Ratings");
      await this.compileCustomerRatingsSheet(
        customerRatingsSheet,
        reportLocations
      );
      progress.progress += progressStep;
    }
    /**
     * REPORT DATA SHEET
     */
    const reportDataSheet = recSummaryWorkbook.addWorksheet("DS Report");
    this.compileReportDataSheet(reportDataSheet, reportLocations);
    /**REC CHECK */
    if (!skipChecks) {
      const recCheckSheet = recSummaryWorkbook.addWorksheet("Rec Check");
      await this.compileRecCheckSheet(recCheckSheet, reportLocations);
    }

    /**PriorPeriod Adjustments */
    const priorPeriodAdjustmentsSheet = recSummaryWorkbook.addWorksheet(
      "Prior Period Adjustments"
    );
    await this.compilePriorPeriodAdjustmentsSheet(
      priorPeriodAdjustmentsSheet,
      reportLocations
    );

    progress.progress += progressStep;
    await this.checkForStandardJeExports(
      recSummaryWorkbook,
      selectedReports,
      reportLocations,
      jeHeaderSelection
    );
    if (this.dataUtility.client.id === "Rm1VGHGxqIDN8ysE09X9") {
      //TGIF CORP
      if (selectedReports.indexOf("TGIF Corporate JE's") > -1) {
        const [dsVarSheet, delExpSheet] = compileTGIFJournalEntries(
          recSummaryWorkbook,
          this.dataUtility,
          skipChecks
        );
        this.autoFitColumns(delExpSheet);
        this.autoFitColumns(dsVarSheet);
      }
      //YTC TGIF
      if (selectedReports.indexOf("YTC TGIF JE's") > -1) {
        const [
          adjustmentsCFRSheet,
          adjustmentsORISheet,
          comissionFeesCFR,
          comissionFeesORI,
        ] = await compileYTCJournalEntries(
          recSummaryWorkbook,
          this.dataUtility
        );
        this.autoFitColumns(adjustmentsCFRSheet);
        this.autoFitColumns(adjustmentsORISheet);
        this.autoFitColumns(comissionFeesCFR);
        this.autoFitColumns(comissionFeesORI);
      }
    } else if (this.dataUtility.client.id === "m5PVBX8JTlE7yQleGCrt") {
      if (selectedReports.indexOf("DT Custom Journal Entries") > -1) {
        await compileDistrictTacoJournalEntries(
          recSummaryWorkbook,
          this.dataUtility
        );
      }
    } else if (this.dataUtility.client.id === "jueFVFVSeayJ6bCajgl6") {
      if (selectedReports.indexOf("OpCo Custom Journal Entries") > -1) {
        await compileBojanglesJEs(recSummaryWorkbook, this.dataUtility);
      }
    } else if (this.dataUtility.client.id === "GiqlIArMa7ZEJ3EG80z4") {
      await compileVirturantCustomReports(recSummaryWorkbook, this.dataUtility);
    }

    /***Compile File */

    const data = await recSummaryWorkbook.xlsx.writeBuffer();
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    if (sendEmails) {
      await this.sendRecSummaryEmails(
        blob,
        `${this.dataUtility.client.name}_REC_SUMMARY_${this.dataUtility.existingReport.name}.xlsx`,
        null
      );
    }
    if (returnBlob) {
      return blob;
    } else {
      fs.saveAs(
        blob,
        `${this.dataUtility.client.name}_REC_SUMMARY_${this.dataUtility.existingReport.name}.xlsx`
      );
    }
  }
  public downloadFile(blob, name) {
    return fs.saveAs(blob, name);
  }
  compileReconciliationSummarySheet(summarySheet, locations: Location[]) {
    const salesHeaderColor = "add8e6";
    const taxHeaderColor = "fed8b1";
    const mfHeaderColor = "90ee90";
    const feeHeaderColor = "CC99FF";
    const salesColumns = ["B", "C", "D", "E", "F", "G"];
    const taxColumns = ["H", "I", "J", "K"];
    const mfColumns = ["L", "M", "N"];
    const feeColumns = ["O", "P", "Q", "R", "S", "T", "U"];
    const locationIds = locations
      .map((location) =>
        this.dataUtility.client.locationIdString
          ? location.locationId
          : +location.locationId
      )
      .sort((a, b) => (a < b ? -1 : 1));
    const clientNameRow = summarySheet.addRow([this.dataUtility.client.name]);
    clientNameRow.font = { bold: true };
    const recTitleRow = summarySheet.addRow([
      `${this.dataUtility.existingReport.name} - 3PD Reconciliation Summary`,
    ]);
    recTitleRow.font = { bold: true };
    const dspNames = this.dataUtility.filteredThirdParties.reduce(
      (txt, dsp) => {
        if (!txt) {
          return `${dsp.name}`;
        } else {
          return `${txt}, ${dsp.name}`;
        }
      },
      ``
    );
    summarySheet.addRow(["3PDs", dspNames]);
    summarySheet.addRow([
      "Date Range",
      `${this.dataUtility.startDateText}-${this.dataUtility.endDateText}`,
    ]);
    summarySheet.addRow([]);
    const sectionsRow = summarySheet.addRow([
      "",
      "SALES",
      "",
      "",
      "",
      "",
      "",
      "TAX",
      "",
      "",
      "",
      "MF IMPACT",
      "",
      "",
      "FEES",
      "",
      "",
      "",
      "",
    ]);
    sectionsRow.eachCell((cell, number) => {
      const column = this.numberToLetters(number - 1);
      if (salesColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: salesHeaderColor },
          bgColor: { argb: salesHeaderColor },
        };
      } else if (taxColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: taxHeaderColor },
          bgColor: { argb: taxHeaderColor },
        };
      } else if (mfColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: mfHeaderColor },
          bgColor: { argb: mfHeaderColor },
        };
      } else if (feeColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: feeHeaderColor },
          bgColor: { argb: feeHeaderColor },
        };
      }
    });
    summarySheet.mergeCells("B6:G6");
    summarySheet.getCell("B6").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    summarySheet.mergeCells("H6:K6");
    summarySheet.getCell("H6").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    summarySheet.mergeCells("L6:N6");
    summarySheet.getCell("L6").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    summarySheet.mergeCells("O6:U6");
    summarySheet.getCell("O6").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    const headersRow = summarySheet.addRow([
      "Location",
      "POS Sales",
      "3PD Sales",
      "Variance",
      "Error Charges",
      "Cancelled Transaction",
      "Remaining Variance",
      "POS Tax",
      "3PD Tax",
      "Variance",
      "Rate Error?",
      "MF Sales",
      "MF Tax",
      "Partial Collection?",
      "Delivery Fees",
      "Pickup Fees",
      "Catering Fees",
      "Mktg/Promo Fees",
      "Total Fees",
      "Average Fee %",
      "Campaign Promo Fees",
    ]);
    headersRow.eachCell((cell, number) => {
      const column = this.numberToLetters(number - 1);
      if (salesColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: salesHeaderColor },
          bgColor: { argb: salesHeaderColor },
        };
      } else if (taxColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: taxHeaderColor },
          bgColor: { argb: taxHeaderColor },
        };
      } else if (mfColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: mfHeaderColor },
          bgColor: { argb: mfHeaderColor },
        };
      } else if (feeColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: feeHeaderColor },
          bgColor: { argb: feeHeaderColor },
        };
      }
    });
    let rowCount = 8;
    locationIds.forEach((locationId) => {
      summarySheet.addRow([
        this.dataUtility.client.locationIdString ? locationId : +locationId,
        {
          id: "posSales",
          formula: `SUMIF('DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount},'DS Report'!$${recColumns["POS Net Sales"]}:$${recColumns["POS Net Sales"]})`,
        },
        {
          id: "3pdSales",
          formula: `SUMIF('DS Report'!${recColumns["Location Id"]}:${recColumns["Location Id"]},Summary!$A${rowCount},'DS Report'!${recColumns["3PD Net Sales"]}:$${recColumns["3PD Net Sales"]})`,
        },
        { id: "salesVariance", formula: `B${rowCount}-C${rowCount}` },
        {
          id: "errorCharges",
          formula: `SUMIF('Variance Analysis'!$A:$A,Summary!$A${rowCount},'Variance Analysis'!$F:$F)`,
        },
        {
          id: "cancelledTransactions",
          formula: `SUMIF('Variance Analysis'!$A:$A,Summary!$A${rowCount},'Variance Analysis'!$I:$I)`,
        },
        {
          id: "remainingVariance",
          formula: `D${rowCount}-SUM(E${rowCount}:F${rowCount})`,
        },
        {
          id: "posTax",
          formula: `SUMIF('DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount},'DS Report'!$${recColumns["POS Net Tax"]}:$${recColumns["POS Net Tax"]})`,
        },
        {
          id: "3pdTax",
          formula: `SUMIF('DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount},'DS Report'!$${recColumns["3PD Net Tax"]}:$${recColumns["3PD Net Tax"]})`,
        },
        { id: "taxVariance", formula: `H${rowCount}-I${rowCount}` },
        {
          id: "rateError",
          formula: `IF(COUNTIFS('DS Report'!$${recColumns["Tax Rate Errors"]}:$${recColumns["Tax Rate Errors"]},"TRUE",'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount})>0,"YES","NO")`,
        },
        {
          id: "mfSales",
          formula: `SUMIFS('DS Report'!$${recColumns["3PD Net Sales"]}:$${recColumns["3PD Net Sales"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount},'DS Report'!$${recColumns["Market Facilitator Tax"]}:$${recColumns["Market Facilitator Tax"]},"<0")`,
        },
        {
          id: "mfTax",
          formula: `-SUMIF('DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount},'DS Report'!$${recColumns["Market Facilitator Tax"]}:$${recColumns["Market Facilitator Tax"]})`,
        },
        {
          id: "partialCollection",
          formula: `IF(COUNTIFS('DS Report'!$${recColumns["Partial Mf Tax"]}:$${recColumns["Partial Mf Tax"]},"TRUE",'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount})>0,"YES","NO")`,
        },
        {
          id: "deliveryFees",
          formula: `SUMIF('DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount},'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})`,
        },
        {
          id: "pickupFees",
          formula: `SUMIF('DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount},'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
        },
        {
          id: "cateringFees",
          formula: `SUMIF('DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount},'DS Report'!$${recColumns["Catering Fees"]}:$${recColumns["Catering Fees"]})`,
        },
        {
          id: "promoFees",
          formula: `SUMIF('DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount},'DS Report'!$${recColumns["Promo Fees"]}:$${recColumns["Promo Fees"]})`,
        },
        { id: "totalFees", formula: `SUM(O${rowCount}:R${rowCount})` },
        {
          id: "averageFee",
          formula: `IFERROR(AVERAGEIFS('DS Report'!$${recColumns["Effective Total Fee Rate"]}:$${recColumns["Effective Total Fee Rate"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount},'DS Report'!$${recColumns["Total Fees"]}:$${recColumns["Total Fees"]},"<0"), 0)`,
        },
        {
          id: "campaignPromoFees",
          formula: `SUMIF('DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount},'DS Report'!$${recColumns["Campaign Promo Fees"]}:$${recColumns["Campaign Promo Fees"]})`,
        },
      ]);
      rowCount += 1;
    });
    /**CHECK FIGURE ROWS */
    //Totals
    summarySheet.addRow([
      "Total",
      { id: "totalPosSale", formula: `SUM(B8:B${rowCount - 1})` },
      { id: "total3pdSale", formula: `SUM(C8:C${rowCount - 1})` },
      { id: "totalSaleVar", formula: `SUM(D8:D${rowCount - 1})` },
      { id: "totalErrorChrg", formula: `SUM(E8:E${rowCount - 1})` },
      { id: "totalCancelledTrans", formula: `SUM(F8:F${rowCount - 1})` },
      { id: "totalRemainingVar", formula: `SUM(G8:G${rowCount - 1})` },
      { id: "totalPosTax", formula: `SUM(H8:H${rowCount - 1})` },
      { id: "total3pdTax", formula: `SUM(I8:I${rowCount - 1})` },
      { id: "totalTaxVar", formula: `SUM(J8:J${rowCount - 1})` },
      "",
      { id: "totalMFSale", formula: `SUM(L8:L${rowCount - 1})` },
      { id: "totalMFTax", formula: `SUM(M8:M${rowCount - 1})` },
      "",
      { id: "totalDelivery", formula: `SUM(O8:O${rowCount - 1})` },
      { id: "totalPickup", formula: `SUM(P8:P${rowCount - 1})` },
      { id: "totalCatering", formula: `SUM(Q8:Q${rowCount - 1})` },
      { id: "totalPromo", formula: `SUM(R8:R${rowCount - 1})` },
      { id: "totalFee", formula: `SUM(S8:S${rowCount - 1})` },
      "",
      { id: "totalCampaign", formula: `SUM(U8:U${rowCount - 1})` },
    ]);
    //Checks
    summarySheet.addRow([
      "Check",
      {
        id: "checkPosSale",
        formula: `SUM('DS Report'!${recColumns["POS Net Sales"]}:${recColumns["POS Net Sales"]})-B${rowCount}`,
      },
      {
        id: "check3pdSale",
        formula: `SUM('DS Report'!${recColumns["3PD Net Sales"]}:${recColumns["3PD Net Sales"]})-C${rowCount}`,
      },
      { id: "checkVar", formula: `SUM('Variance Analysis'!D:D)-D${rowCount}` },
      {
        id: "checkErrorChrg",
        formula: `SUM('Variance Analysis'!F:F)-E${rowCount}`,
      },
      {
        id: "checkCancelledTrans",
        formula: `SUM('Variance Analysis'!I:I)-F${rowCount}`,
      },
      {
        id: "checkRemainingVar",
        formula: `SUM('Variance Analysis'!E:E,'Variance Analysis'!G:G,'Variance Analysis'!H:H)-G${rowCount}`,
      },
      {
        id: "checkPosTax",
        formula: `SUM('DS Report'!${recColumns["POS Net Tax"]}:${recColumns["POS Net Tax"]})-H${rowCount}`,
      },
      {
        id: "check3pdTax",
        formula: `SUM('DS Report'!${recColumns["3PD Net Tax"]}:${recColumns["3PD Net Tax"]})-I${rowCount}`,
      },
      {
        id: "checkTaxVar",
        formula: `SUM('DS Report'!${recColumns["Tax Variance"]}:${recColumns["Tax Variance"]})-J${rowCount}`,
      },
      "", // rate error
      "", // no check MF Sale
      "", // no check MF Tax
      "", // no check partial MF
      {
        id: "checkDeliveryFee",
        formula: `SUM('DS Report'!${recColumns["Delivery Fees"]}:${recColumns["Delivery Fees"]})-O${rowCount}`,
      },
      {
        id: "checkPickupFee",
        formula: `SUM('DS Report'!${recColumns["Pickup Fees"]}:${recColumns["Pickup Fees"]})-P${rowCount}`,
      },
      {
        id: "checkCateringFee",
        formula: `SUM('DS Report'!${recColumns["Catering Fees"]}:${recColumns["Catering Fees"]})-Q${rowCount}`,
      },
      {
        id: "checkPromoFee",
        formula: `SUM('DS Report'!${recColumns["Promo Fees"]}:${recColumns["Promo Fees"]})-R${rowCount}`,
      },
      {
        id: "checkTotalFee",
        formula: `SUM('DS Report'!${recColumns["Total Fees"]}:${recColumns["Total Fees"]})-S${rowCount}`,
      },
      "",
      {
        id: "checkCampaignFee",
        formula: `SUM('DS Report'!${recColumns["Campaign Promo Fees"]}:${recColumns["Campaign Promo Fees"]})-U${rowCount}`,
      },
    ]);
    summarySheet.addConditionalFormatting({
      ref: "D8:D1000",
      rules: [
        {
          type: "cellIs",
          operator: "greaterThan",
          formulae: [
            this.dataUtility.client3pdConfiguration.salesVarianceThreshold
              ? this.dataUtility.client3pdConfiguration.salesVarianceThreshold
              : 10,
          ],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FFD0D0" },
            },
            font: { color: { argb: "C42323" } },
          },
          priority: null,
        },
      ],
    });
    summarySheet.addConditionalFormatting({
      ref: "D8:D1000",
      rules: [
        {
          type: "cellIs",
          operator: "lessThan",
          formulae: [
            this.dataUtility.client3pdConfiguration.salesVarianceThreshold
              ? this.dataUtility.client3pdConfiguration.salesVarianceThreshold *
                -1
              : -10,
          ],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FFD0D0" },
            },
            font: { color: { argb: "C42323" } },
          },
          priority: null,
        },
      ],
    });
    summarySheet.addConditionalFormatting({
      ref: "J8:J1000",
      rules: [
        {
          type: "cellIs",
          operator: "greaterThan",
          formulae: [
            this.dataUtility.client3pdConfiguration.taxVarianceThreshold
              ? this.dataUtility.client3pdConfiguration.taxVarianceThreshold
              : 10,
          ],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FFD0D0" },
            },
            font: { color: { argb: "C42323" } },
          },
          priority: null,
        },
      ],
    });
    summarySheet.addConditionalFormatting({
      ref: "J8:J1000",
      rules: [
        {
          type: "cellIs",
          operator: "lessThan",
          formulae: [
            this.dataUtility.client3pdConfiguration.taxVarianceThreshold
              ? this.dataUtility.client3pdConfiguration.taxVarianceThreshold *
                -1
              : -10,
          ],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FFD0D0" },
            },
            font: { color: { argb: "C42323" } },
          },
          priority: null,
        },
      ],
    });
    summarySheet.addConditionalFormatting({
      ref: "K8:K1000",
      rules: [
        {
          type: "containsText",
          operator: "containsText",
          text: "YES",
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FFD0D0" },
            },
            font: { color: { argb: "C42323" } },
          },
          priority: null,
        },
      ],
    });
    summarySheet.addConditionalFormatting({
      ref: "N8:N1000",
      rules: [
        {
          type: "containsText",
          operator: "containsText",
          text: "YES",
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FFD0D0" },
            },
            font: { color: { argb: "C42323" } },
          },
          priority: null,
        },
      ],
    });
    // summarySheet.mergeCells('A1:B1');
    // summarySheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    // summarySheet.mergeCells('A2:B2');
    // summarySheet.getCell('A2').alignment = { vertical: 'middle', horizontal: 'center' };
    this.autoFitColumns(summarySheet);
    [
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "L",
      "M",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "U",
    ].forEach((column) => {
      this.formatAllCellsInColumn(summarySheet, column, "accounting");
    });
    this.formatAllCellsInColumn(summarySheet, "T", "percent");
    summarySheet.getCell("B3").numFmt = "@";
  }
  compileReconciliationSummaryBreakoutSheet(
    summarySheet,
    locations: Location[]
  ) {
    const salesHeaderColor = "add8e6";
    const taxHeaderColor = "fed8b1";
    const mfHeaderColor = "90ee90";
    const feeHeaderColor = "CC99FF";
    const salesColumns = ["B", "C", "D", "E", "F", "G", "H"];
    const taxColumns = ["I", "J", "K"];
    const mfColumns = ["L", "M", "N", "O"];
    const feeColumns = ["P", "Q", "R", "S", "T", "U", "V"];
    const locationIds = locations
      .map((location) =>
        this.dataUtility.client.locationIdString
          ? location.locationId
          : +location.locationId
      )
      .sort((a, b) => (a < b ? -1 : 1));
    const clientNameRow = summarySheet.addRow([this.dataUtility.client.name]);
    clientNameRow.font = { bold: true };
    const recTitleRow = summarySheet.addRow([
      `${this.dataUtility.existingReport.name} - 3PD Reconciliation Summary Detail`,
    ]);
    recTitleRow.font = { bold: true };
    const dspNames = this.dataUtility.filteredThirdParties.reduce(
      (txt, dsp) => {
        if (!txt) {
          return `${dsp.name}`;
        } else {
          return `${txt}, ${dsp.name}`;
        }
      },
      ``
    );
    summarySheet.addRow(["3PDs", dspNames]);
    summarySheet.addRow([
      "Date Range",
      `${this.dataUtility.startDateText}-${this.dataUtility.endDateText}`,
    ]);
    summarySheet.addRow([]);
    const sectionsRow = summarySheet.addRow([
      "",
      "SALES",
      "",
      "",
      "",
      "",
      "",
      "TAX",
      "",
      "",
      "",
      "MF IMPACT",
      "",
      "",
      "FEES",
      "",
      "",
      "",
      "",
    ]);
    sectionsRow.eachCell((cell, number) => {
      const column = this.numberToLetters(number - 1);
      if (salesColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: salesHeaderColor },
          bgColor: { argb: salesHeaderColor },
        };
      } else if (taxColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: taxHeaderColor },
          bgColor: { argb: taxHeaderColor },
        };
      } else if (mfColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: mfHeaderColor },
          bgColor: { argb: mfHeaderColor },
        };
      } else if (feeColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: feeHeaderColor },
          bgColor: { argb: feeHeaderColor },
        };
      }
    });
    summarySheet.mergeCells("C6:H6");
    summarySheet.getCell("C6").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    summarySheet.mergeCells("I6:L6");
    summarySheet.getCell("I6").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    summarySheet.mergeCells("M6:O6");
    summarySheet.getCell("M6").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    summarySheet.mergeCells("P6:V6");
    summarySheet.getCell("P6").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    const headersRow = summarySheet.addRow([
      "Location",
      "Third Party",
      "POS Sales",
      "3PD Sales",
      "Variance",
      "Error Charges",
      "Cancelled Transaction",
      "Remaining Variance",
      "POS Tax",
      "3PD Tax",
      "Variance",
      "Rate Error?",
      "MF Sales",
      "MF Tax",
      "Partial Collection?",
      "Delivery Fees",
      "Pickup Fees",
      "Catering Fees",
      "Mktg/Promo Fees",
      "Total Fees",
      "Average Fee %",
      "Campaign Promo Fees",
    ]);
    headersRow.eachCell((cell, number) => {
      const column = this.numberToLetters(number - 1);
      if (salesColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: salesHeaderColor },
          bgColor: { argb: salesHeaderColor },
        };
      } else if (taxColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: taxHeaderColor },
          bgColor: { argb: taxHeaderColor },
        };
      } else if (mfColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: mfHeaderColor },
          bgColor: { argb: mfHeaderColor },
        };
      } else if (feeColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: feeHeaderColor },
          bgColor: { argb: feeHeaderColor },
        };
      }
    });
    let rowCount = 8;
    locationIds.forEach((locationId) => {
      this.dataUtility.thirdParties.forEach((thirdParty) => {
        summarySheet.addRow([
          this.dataUtility.client.locationIdString ? locationId : +locationId,
          thirdParty.name,
          {
            id: "posSales",
            formula: `SUMIFS('DS Report'!$${recColumns["POS Net Sales"]}:$${recColumns["POS Net Sales"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount})`,
          },
          {
            id: "3pdSales",
            formula: `SUMIFS('DS Report'!$${recColumns["3PD Net Sales"]}:$${recColumns["3PD Net Sales"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount})`,
          },
          { id: "salesVariance", formula: `C${rowCount}-D${rowCount}` },
          {
            id: "errorCharges",
            formula: `SUMIFS('Error Charges'!$G:$G, 'Error Charges'!$A:$A,SummaryDetail!$A${rowCount},'Error Charges'!$B:$B,SummaryDetail!B${rowCount})`,
          },
          {
            id: "cancelledTransactions",
            formula: this.dataUtility.existingReport.includePosInRec
              ? `SUMIFS('Variance Analysis'!$I:$I, 'Variance Analysis'!$A:$A,SummaryDetail!$A${rowCount},'Variance Analysis'!$B:$B,SummaryDetail!B${rowCount})`
              : `SUMIFS('Canceled Orders Summary'!$D:$D, 'Canceled Orders Summary'!$A:$A,SummaryDetail!$A${rowCount},'Canceled Orders Summary'!$B:$B,SummaryDetail!B${rowCount})`,
          },
          {
            id: "remainingVariance",
            formula: `E${rowCount}-SUM(F${rowCount}:G${rowCount})`,
          },
          {
            id: "posTax",
            formula: `SUMIFS('DS Report'!$${recColumns["POS Net Tax"]}:$${recColumns["POS Net Tax"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount})`,
          },
          {
            id: "3pdTax",
            formula: `SUMIFS('DS Report'!$${recColumns["3PD Net Tax"]}:$${recColumns["3PD Net Tax"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount})`,
          },
          { id: "taxVariance", formula: `I${rowCount}-J${rowCount}` },
          {
            id: "rateError",
            formula: `IF(COUNTIFS('DS Report'!$${recColumns["Tax Rate Errors"]}:$${recColumns["Tax Rate Errors"]},"TRUE",'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount})>0,"YES","NO")`,
          },
          {
            id: "mfSales",
            formula: `SUMIFS('DS Report'!$${recColumns["3PD Net Sales"]}:$${recColumns["3PD Net Sales"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount},'DS Report'!$${recColumns["Market Facilitator Tax"]}:$${recColumns["Market Facilitator Tax"]},"<0")`,
          },
          {
            id: "mfTax",
            formula: `-SUMIFS('DS Report'!$${recColumns["Market Facilitator Tax"]}:$${recColumns["Market Facilitator Tax"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount})`,
          },
          {
            id: "partialCollection",
            formula: `IF(COUNTIFS('DS Report'!$${recColumns["Partial Mf Tax"]}:$${recColumns["Partial Mf Tax"]},"TRUE",'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount})>0,"YES","NO")`,
          },
          {
            id: "deliveryFees",
            formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount})`,
          },
          {
            id: "pickupFees",
            formula: `SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount})`,
          },
          {
            id: "cateringFees",
            formula: `SUMIFS('DS Report'!$${recColumns["Catering Fees"]}:$${recColumns["Catering Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount})`,
          },
          {
            id: "promoFees",
            formula: `SUMIFS('DS Report'!$${recColumns["Promo Fees"]}:$${recColumns["Promo Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount})`,
          },
          { id: "totalFees", formula: `SUM(P${rowCount}:S${rowCount})` },
          {
            id: "averageFee",
            formula: `IFERROR(AVERAGEIFS('DS Report'!$${recColumns["Effective Total Fee Rate"]}:$${recColumns["Effective Total Fee Rate"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount},'DS Report'!$${recColumns["Total Fees"]}:$${recColumns["Total Fees"]},"<0"), 0)`,
          },
          {
            id: "campaignPromoFees",
            formula: `SUMIFS('DS Report'!$${recColumns["Campaign Promo Fees"]}:$${recColumns["Campaign Promo Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount})`,
          },
        ]);
        rowCount += 1;
      });
    });
    /**CHECK FIGURE ROWS */
    //Totals
    summarySheet.addRow([
      "Total",
      "",
      { id: "totalPosSale", formula: `SUM(C8:C${rowCount - 1})` },
      { id: "total3pdSale", formula: `SUM(D8:D${rowCount - 1})` },
      { id: "totalSaleVar", formula: `SUM(E8:E${rowCount - 1})` },
      { id: "totalErrorChrg", formula: `SUM(F8:F${rowCount - 1})` },
      { id: "totalCancelledTrans", formula: `SUM(G8:G${rowCount - 1})` },
      { id: "totalRemainingVar", formula: `SUM(H8:H${rowCount - 1})` },
      { id: "totalPosTax", formula: `SUM(I8:I${rowCount - 1})` },
      { id: "total3pdTax", formula: `SUM(J8:J${rowCount - 1})` },
      { id: "totalTaxVar", formula: `SUM(K8:K${rowCount - 1})` },
      "",
      { id: "totalMFSale", formula: `SUM(M8:M${rowCount - 1})` },
      { id: "totalMFTax", formula: `SUM(N8:N${rowCount - 1})` },
      "",
      { id: "totalDelivery", formula: `SUM(P8:P${rowCount - 1})` },
      { id: "totalPickup", formula: `SUM(Q8:Q${rowCount - 1})` },
      { id: "totalCatering", formula: `SUM(R8:R${rowCount - 1})` },
      { id: "totalPromo", formula: `SUM(S8:S${rowCount - 1})` },
      { id: "totalFee", formula: `SUM(T8:T${rowCount - 1})` },
      "",
      { id: "totalCampaign", formula: `SUM(V8:V${rowCount - 1})` },
    ]);
    //Checks
    if (this.dataUtility.existingReport.includePosInRec) {
      summarySheet.addRow([
        "Check",
        "",
        {
          id: "checkPosSale",
          formula: `SUM('DS Report'!${recColumns["POS Net Sales"]}:${recColumns["POS Net Sales"]})-C${rowCount}`,
        },
        {
          id: "check3pdSale",
          formula: `SUM('DS Report'!${recColumns["3PD Net Sales"]}:${recColumns["3PD Net Sales"]})-D${rowCount}`,
        },
        {
          id: "checkVar",
          formula: `SUM('Variance Analysis'!D:D)-E${rowCount}`,
        },
        {
          id: "checkErrorChrg",
          formula: `SUM('Variance Analysis'!F:F)-F${rowCount}`,
        },
        {
          id: "checkCancelledTrans",
          formula: `SUM('Variance Analysis'!I:I)-G${rowCount}`,
        },
        {
          id: "checkRemainingVar",
          formula: `SUM('Variance Analysis'!E:E,'Variance Analysis'!G:G,'Variance Analysis'!H:H)-H${rowCount}`,
        },
        {
          id: "checkPosTax",
          formula: `SUM('DS Report'!${recColumns["POS Net Tax"]}:${recColumns["POS Net Tax"]})-I${rowCount}`,
        },
        {
          id: "check3pdTax",
          formula: `SUM('DS Report'!${recColumns["3PD Net Tax"]}:${recColumns["3PD Net Tax"]})-J${rowCount}`,
        },
        {
          id: "checkTaxVar",
          formula: `SUM('DS Report'!${recColumns["Tax Variance"]}:${recColumns["Tax Variance"]})-K${rowCount}`,
        },
        "", // rate error
        "", // no check MF Sale
        "", // no check MF Tax
        "", // no check partial MF
        {
          id: "checkDeliveryFee",
          formula: `SUM('DS Report'!${recColumns["Delivery Fees"]}:${recColumns["Delivery Fees"]})-P${rowCount}`,
        },
        {
          id: "checkPickupFee",
          formula: `SUM('DS Report'!${recColumns["Pickup Fees"]}:${recColumns["Pickup Fees"]})-Q${rowCount}`,
        },
        {
          id: "checkCateringFee",
          formula: `SUM('DS Report'!${recColumns["Catering Fees"]}:${recColumns["Catering Fees"]})-R${rowCount}`,
        },
        {
          id: "checkPromoFee",
          formula: `SUM('DS Report'!${recColumns["Promo Fees"]}:${recColumns["Promo Fees"]})-S${rowCount}`,
        },
        {
          id: "checkTotalFee",
          formula: `SUM('DS Report'!${recColumns["Total Fees"]}:${recColumns["Total Fees"]})-T${rowCount}`,
        },
        "",
        {
          id: "checkCampaignFee",
          formula: `SUM('DS Report'!${recColumns["Campaign Promo Fees"]}:${recColumns["Campaign Promo Fees"]})-V${rowCount}`,
        },
      ]);
    }
    summarySheet.addConditionalFormatting({
      ref: "E8:E1000",
      rules: [
        {
          type: "cellIs",
          operator: "greaterThan",
          formulae: [
            this.dataUtility.client3pdConfiguration.salesVarianceThreshold
              ? this.dataUtility.client3pdConfiguration.salesVarianceThreshold
              : 10,
          ],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FFD0D0" },
            },
            font: { color: { argb: "C42323" } },
          },
          priority: null,
        },
      ],
    });
    summarySheet.addConditionalFormatting({
      ref: "E8:E1000",
      rules: [
        {
          type: "cellIs",
          operator: "lessThan",
          formulae: [
            this.dataUtility.client3pdConfiguration.salesVarianceThreshold
              ? this.dataUtility.client3pdConfiguration.salesVarianceThreshold *
                -1
              : -10,
          ],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FFD0D0" },
            },
            font: { color: { argb: "C42323" } },
          },
          priority: null,
        },
      ],
    });
    summarySheet.addConditionalFormatting({
      ref: "K8:K1000",
      rules: [
        {
          type: "cellIs",
          operator: "greaterThan",
          formulae: [
            this.dataUtility.client3pdConfiguration.taxVarianceThreshold
              ? this.dataUtility.client3pdConfiguration.taxVarianceThreshold
              : 10,
          ],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FFD0D0" },
            },
            font: { color: { argb: "C42323" } },
          },
          priority: null,
        },
      ],
    });
    summarySheet.addConditionalFormatting({
      ref: "K8:K1000",
      rules: [
        {
          type: "cellIs",
          operator: "lessThan",
          formulae: [
            this.dataUtility.client3pdConfiguration.taxVarianceThreshold
              ? this.dataUtility.client3pdConfiguration.taxVarianceThreshold *
                -1
              : -10,
          ],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FFD0D0" },
            },
            font: { color: { argb: "C42323" } },
          },
          priority: null,
        },
      ],
    });
    summarySheet.addConditionalFormatting({
      ref: "M8:M1000",
      rules: [
        {
          type: "containsText",
          operator: "containsText",
          text: "YES",
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FFD0D0" },
            },
            font: { color: { argb: "C42323" } },
          },
          priority: null,
        },
      ],
    });
    summarySheet.addConditionalFormatting({
      ref: "O8:O1000",
      rules: [
        {
          type: "containsText",
          operator: "containsText",
          text: "YES",
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FFD0D0" },
            },
            font: { color: { argb: "C42323" } },
          },
          priority: null,
        },
      ],
    });

    // summarySheet.mergeCells('A1:B1');
    // summarySheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    // summarySheet.mergeCells('A2:B2');
    // summarySheet.getCell('A2').alignment = { vertical: 'middle', horizontal: 'center' };
    this.autoFitColumns(summarySheet);
    [
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "M",
      "N",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "V",
    ].forEach((column) => {
      this.formatAllCellsInColumn(summarySheet, column, "accounting");
    });
    this.formatAllCellsInColumn(summarySheet, "U", "percent");
    summarySheet.getCell("B3").numFmt = "@";
  }
  async compileAnalyticsBreakoutSummarySheet(
    sheet: Worksheet,
    locations: Location[]
  ) {
    const headerRow = sheet.addRow([
      "Location",
      "Location City-State",
      "Third Party",
      "3PD Gross Sales",
      "Error Charges",
      "3PD Sale Adjustments",
      "3PD Net Sales",
      "Campaign Promo Fees",
      "Taxable Sales",
      "Canceled Orders",
      "3PD Tax Collected",
      "3PD Avg Tax Rate",
      "Restaurant Responsible For Tax",
      "Partial Tax Responsibility",
      "Tax Remitted To Restaurant",
      "Backup Withholding Tax",
      "Delivery Fees",
      "Pickup Fees",
      "Promo Fees",
      "Catering Fees",
      "Total Fees",
      "Misc Fees",
      "Credit Card Processing Fees",
    ]);
    const analyticsSummaries =
      await this.dataUtility.compileAnalyticsBreakoutSummaries(locations);
    analyticsSummaries.forEach((summary) => {
      sheet.addRow([
        summary.location,
        summary["locationCityState"],
        summary["thirdPartyName"],
        summary.dspGrossSales,
        summary.errorCharges,
        summary.adjustments,
        summary.netSales,
        summary.campaignPromoFees,
        summary.taxableSales,
        summary.canceledOrders,
        summary.dspTax,
        summary.dspEffectiveTaxRate,
        summary.mfTaxApplicable ? "NO" : "YES",
        summary.partialMfTaxApplicable ? "YES" : "NO",
        summary.taxRemittedToRestaurant,
        summary.backupWithholdingTax,
        summary.deliveryFees,
        summary.pickupFees,
        summary.promoFees,
        summary.cateringFees,
        summary.totalFees,
        summary.miscFees,
        summary.creditCardProcessingFees,
      ]);
    });
    this.autoFitColumns(sheet);
    [
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
    ].forEach((column) => {
      this.formatAllCellsInColumn(sheet, column, "accounting");
    });
  }
  //

  compileAnalyticsSummarySheet(summarySheet, locations: Location[]) {
    const salesHeaderColor = "add8e6";
    const taxHeaderColor = "fed8b1";
    const mfHeaderColor = "90ee90";
    const feeHeaderColor = "CC99FF";
    const salesColumns = ["B", "C", "D"];
    const taxColumns = ["E"];
    const mfColumns = ["F", "G", "H"];
    const feeColumns = ["I", "J", "K", "L", "M", "N", "O"];
    const locationIds = locations
      .map((location) =>
        this.dataUtility.client.locationIdString
          ? location.locationId
          : +location.locationId
      )
      .sort((a, b) => (a < b ? -1 : 1));
    const clientNameRow = summarySheet.addRow([this.dataUtility.client.name]);
    clientNameRow.font = { bold: true };
    const recTitleRow = summarySheet.addRow([
      `${this.dataUtility.existingReport.name} - 3PD Financial Transactions Summary`,
    ]);
    recTitleRow.font = { bold: true };
    const dspNames = this.dataUtility.filteredThirdParties.reduce(
      (txt, dsp) => {
        if (!txt) {
          return `${dsp.name}`;
        } else {
          return `${txt}, ${dsp.name}`;
        }
      },
      ``
    );
    summarySheet.addRow(["3PDs", dspNames]);
    summarySheet.addRow([
      "Date Range",
      `${this.dataUtility.startDateText}-${this.dataUtility.endDateText}`,
    ]);
    summarySheet.addRow([]);
    const sectionsRow = summarySheet.addRow([
      "",
      "SALES",
      "",
      "",
      "TAX",
      "MF IMPACT",
      "",
      "",
      "FEES",
      "",
      "",
      "",
      "",
      "",
    ]);
    sectionsRow.eachCell((cell, number) => {
      const column = this.numberToLetters(number - 1);
      if (salesColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: salesHeaderColor },
          bgColor: { argb: salesHeaderColor },
        };
      } else if (taxColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: taxHeaderColor },
          bgColor: { argb: taxHeaderColor },
        };
      } else if (mfColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: mfHeaderColor },
          bgColor: { argb: mfHeaderColor },
        };
      } else if (feeColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: feeHeaderColor },
          bgColor: { argb: feeHeaderColor },
        };
      }
    });
    summarySheet.mergeCells("B6:D6");
    summarySheet.getCell("B6").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    summarySheet.mergeCells("E6:E6");
    summarySheet.getCell("E6").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    summarySheet.mergeCells("F6:H6");
    summarySheet.getCell("F6").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    summarySheet.mergeCells("I6:O6");
    summarySheet.getCell("I6").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    const headersRow = summarySheet.addRow([
      "Location",
      "3PD Sales",
      "Error Charges",
      "All Canceled Orders",
      "3PD Tax",
      "MF Sales",
      "MF Tax",
      "Partial Collection?",
      "Delivery Fees",
      "Pickup Fees",
      "Catering Fees",
      "Mktg/Promo Fees",
      "Total Fees",
      "Average Fee %",
      "Campaign Promo Fees",
    ]);
    headersRow.eachCell((cell, number) => {
      const column = this.numberToLetters(number - 1);
      if (salesColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: salesHeaderColor },
          bgColor: { argb: salesHeaderColor },
        };
      } else if (taxColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: taxHeaderColor },
          bgColor: { argb: taxHeaderColor },
        };
      } else if (mfColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: mfHeaderColor },
          bgColor: { argb: mfHeaderColor },
        };
      } else if (feeColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: feeHeaderColor },
          bgColor: { argb: feeHeaderColor },
        };
      }
    });
    let rowCount = 8;
    locationIds.forEach((locationId) => {
      summarySheet.addRow([
        this.dataUtility.client.locationIdString ? locationId : +locationId,
        {
          id: "3pdSales",
          formula: `SUMIF('DS Report'!${recColumns["Location Id"]}:${recColumns["Location Id"]},Summary!$A${rowCount},'DS Report'!${recColumns["3PD Net Sales"]}:$${recColumns["3PD Net Sales"]})`,
        },
        {
          id: "errorCharges",
          formula: `SUMIF('Error Charges'!$A:$A,Summary!$A${rowCount},'Error Charges'!$G:$G)`,
        },
        {
          id: "canceledOrders",
          formula: `SUMIF('Canceled Orders Summary'!$A:$A,Summary!$A${rowCount},'Canceled Orders Summary'!$D:$D) + SUMIF('Canceled Orders Summary'!$A:$A,Summary!$A${rowCount},'Canceled Orders Summary'!$E:$E)`,
        },
        {
          id: "3pdTax",
          formula: `SUMIF('DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount},'DS Report'!$${recColumns["3PD Net Tax"]}:$${recColumns["3PD Net Tax"]})`,
        },
        {
          id: "mfSales",
          formula: `SUMIFS('DS Report'!$${recColumns["3PD Net Sales"]}:$${recColumns["3PD Net Sales"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount},'DS Report'!$${recColumns["Market Facilitator Tax"]}:$${recColumns["Market Facilitator Tax"]},"<0")`,
        },
        {
          id: "mfTax",
          formula: `-SUMIF('DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount},'DS Report'!$${recColumns["Market Facilitator Tax"]}:$${recColumns["Market Facilitator Tax"]})`,
        },
        {
          id: "partialCollection",
          formula: `IF(COUNTIFS('DS Report'!$${recColumns["Partial Mf Tax"]}:$${recColumns["Partial Mf Tax"]},"TRUE",'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount})>0,"YES","NO")`,
        },
        {
          id: "deliveryFees",
          formula: `SUMIF('DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount},'DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]})`,
        },
        {
          id: "pickupFees",
          formula: `SUMIF('DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount},'DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]})`,
        },
        {
          id: "cateringFees",
          formula: `SUMIF('DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount},'DS Report'!$${recColumns["Catering Fees"]}:$${recColumns["Catering Fees"]})`,
        },
        {
          id: "promoFees",
          formula: `SUMIF('DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount},'DS Report'!$${recColumns["Promo Fees"]}:$${recColumns["Promo Fees"]})`,
        },
        { id: "totalFees", formula: `SUM(H${rowCount}:L${rowCount})` },
        {
          id: "averageFee",
          formula: `IFERROR(AVERAGEIFS('DS Report'!$${recColumns["Effective Total Fee Rate"]}:$${recColumns["Effective Total Fee Rate"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount},'DS Report'!$${recColumns["Total Fees"]}:$${recColumns["Total Fees"]},"<0"), 0)`,
        },
        {
          id: "campaignPromoFees",
          formula: `SUMIF('DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},Summary!$A${rowCount},'DS Report'!$${recColumns["Campaign Promo Fees"]}:$${recColumns["Campaign Promo Fees"]})`,
        },
      ]);
      rowCount += 1;
    });
    /**CHECK FIGURE ROWS */
    //Totals
    summarySheet.addRow([
      "Total",
      { id: "total3pdSale", formula: `SUM(B8:B${rowCount - 1})` },
      { id: "totalErrorChrg", formula: `SUM(C8:C${rowCount - 1})` },
      { id: "totalErrorChrg", formula: `SUM(D8:D${rowCount - 1})` },
      { id: "total3pdTax", formula: `SUM(E8:E${rowCount - 1})` },
      { id: "totalMFSale", formula: `SUM(F8:F${rowCount - 1})` },
      { id: "totalMFTax", formula: `SUM(G8:G${rowCount - 1})` },
      "",
      { id: "totalDelivery", formula: `SUM(I8:I${rowCount - 1})` },
      { id: "totalPickup", formula: `SUM(J8:J${rowCount - 1})` },
      { id: "totalCatering", formula: `SUM(K8:K${rowCount - 1})` },
      { id: "totalPromo", formula: `SUM(L8:L${rowCount - 1})` },
      { id: "totalFee", formula: `SUM(M8:M${rowCount - 1})` },
      "",
      { id: "totalCampaign", formula: `SUM(O8:O${rowCount - 1})` },
    ]);
    //Checks
    summarySheet.addRow([
      "Check",
      {
        id: "check3pdSale",
        formula: `SUM('DS Report'!${recColumns["3PD Net Sales"]}:${recColumns["3PD Net Sales"]})-B${rowCount}`,
      },
      {
        id: "checkErrorChrg",
        formula: `SUM('Error Charges'!G:G)-C${rowCount}`,
      },
      {
        id: "checkAllCanceled",
        formula: `SUM('Order Accuracy'!L:L)-D${rowCount}`,
      }, // All Cancled
      "", // no check MF Sale
      "", // no check MF Tax
      "", // no check partial MF
      "",
      {
        id: "checkDeliveryFee",
        formula: `SUM('DS Report'!${recColumns["Delivery Fees"]}:${recColumns["Delivery Fees"]})-I${rowCount}`,
      },
      {
        id: "checkPickupFee",
        formula: `SUM('DS Report'!${recColumns["Pickup Fees"]}:${recColumns["Pickup Fees"]})-J${rowCount}`,
      },
      {
        id: "checkCateringFee",
        formula: `SUM('DS Report'!${recColumns["Catering Fees"]}:${recColumns["Catering Fees"]})-K${rowCount}`,
      },
      {
        id: "checkPromoFee",
        formula: `SUM('DS Report'!${recColumns["Promo Fees"]}:${recColumns["Promo Fees"]})-L${rowCount}`,
      },
      {
        id: "checkTotalFee",
        formula: `SUM('DS Report'!${recColumns["Total Fees"]}:${recColumns["Total Fees"]})-M${rowCount}`,
      },
      "",
      {
        id: "checkTotalFee",
        formula: `SUM('DS Report'!${recColumns["Campaign Promo Fees"]}:${recColumns["Campaign Promo Fees"]})-O${rowCount}`,
      },
    ]);
    this.autoFitColumns(summarySheet);
    ["B", "C", "D", "E", "F", "G", "I", "J", "L", "M", "O"].forEach(
      (column) => {
        this.formatAllCellsInColumn(summarySheet, column, "accounting");
      }
    );
    this.formatAllCellsInColumn(summarySheet, "N", "percent");
    summarySheet.getCell("B3").numFmt = "@";
  }
  compileSummarySheet(summarySheet: Worksheet, locations: Location[]) {
    if (this.dataUtility.existingReport.includePosInRec) {
      this.compileReconciliationSummarySheet(summarySheet, locations);
    } else {
      this.compileAnalyticsSummarySheet(summarySheet, locations);
    }
  }
  compileSummaryBreakoutSheet(summarySheet: Worksheet, locations: Location[]) {
    if (this.dataUtility.existingReport.includePosInRec) {
      this.compileReconciliationSummaryBreakoutSheet(summarySheet, locations);
    } else {
      this.compileAnalyticsSummaryBreakoutSheet(summarySheet, locations);
    }
  }
  compileAnalyticsSummaryBreakoutSheet(summarySheet, locations: Location[]) {
    const salesHeaderColor = "add8e6";
    const taxHeaderColor = "fed8b1";
    const mfHeaderColor = "90ee90";
    const feeHeaderColor = "CC99FF";
    const salesColumns = ["C", "D", "E"];
    const taxColumns = ["F"];
    const mfColumns = ["G", "H", "I"];
    const feeColumns = ["J", "K", "L", "M", "N", "O", "P"];
    const locationIds = locations
      .map((location) =>
        this.dataUtility.client.locationIdString
          ? location.locationId
          : +location.locationId
      )
      .sort((a, b) => (a < b ? -1 : 1));
    const clientNameRow = summarySheet.addRow([this.dataUtility.client.name]);
    clientNameRow.font = { bold: true };
    const recTitleRow = summarySheet.addRow([
      `${this.dataUtility.existingReport.name} - 3PD Reconciliation Summary Detail`,
    ]);
    recTitleRow.font = { bold: true };
    const dspNames = this.dataUtility.filteredThirdParties.reduce(
      (txt, dsp) => {
        if (!txt) {
          return `${dsp.name}`;
        } else {
          return `${txt}, ${dsp.name}`;
        }
      },
      ``
    );
    summarySheet.addRow(["3PDs", dspNames]);
    summarySheet.addRow([
      "Date Range",
      `${this.dataUtility.startDateText}-${this.dataUtility.endDateText}`,
    ]);
    summarySheet.addRow([]);
    const sectionsRow = summarySheet.addRow([
      "",
      "",
      "SALES",
      "",
      "",
      "TAX",
      "MF IMPACT",
      "",
      "",
      "FEES",
      "",
      "",
      "",
      "",
    ]);
    sectionsRow.eachCell((cell, number) => {
      const column = this.numberToLetters(number - 1);
      if (salesColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: salesHeaderColor },
          bgColor: { argb: salesHeaderColor },
        };
      } else if (taxColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: taxHeaderColor },
          bgColor: { argb: taxHeaderColor },
        };
      } else if (mfColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: mfHeaderColor },
          bgColor: { argb: mfHeaderColor },
        };
      } else if (feeColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: feeHeaderColor },
          bgColor: { argb: feeHeaderColor },
        };
      }
    });
    summarySheet.mergeCells("C6:E6");
    summarySheet.getCell("C6").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    summarySheet.mergeCells("G6:I6");
    summarySheet.getCell("G6").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    summarySheet.mergeCells("J6:P6");
    summarySheet.getCell("J6").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    const headersRow = summarySheet.addRow([
      "Location",
      "Third Party",
      "3PD Net Sales",
      "Error Charges",
      "All Cancelled Transactions",
      "3PD Tax",
      "MF Sales",
      "MF Tax",
      "Partial Collection?",
      "Delivery Fees",
      "Pickup Fees",
      "Catering Fees",
      "Mktg/Promo Fees",
      "Total Fees",
      "Average Fee %",
      "Campaign Promo Fees",
    ]);
    headersRow.eachCell((cell, number) => {
      const column = this.numberToLetters(number - 1);
      if (salesColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: salesHeaderColor },
          bgColor: { argb: salesHeaderColor },
        };
      } else if (taxColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: taxHeaderColor },
          bgColor: { argb: taxHeaderColor },
        };
      } else if (mfColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: mfHeaderColor },
          bgColor: { argb: mfHeaderColor },
        };
      } else if (feeColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: feeHeaderColor },
          bgColor: { argb: feeHeaderColor },
        };
      }
    });
    let rowCount = 8;
    locationIds.forEach((locationId) => {
      this.dataUtility.thirdParties.forEach((thirdParty) => {
        summarySheet.addRow([
          this.dataUtility.client.locationIdString ? locationId : +locationId,
          thirdParty.name,

          {
            id: "3pdSales",
            formula: `SUMIFS('DS Report'!$${recColumns["3PD Net Sales"]}:$${recColumns["3PD Net Sales"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount})`,
          },
          {
            id: "errorCharges",
            formula: `SUMIFS('Error Charges'!$G:$G, 'Error Charges'!$A:$A,SummaryDetail!$A${rowCount},'Error Charges'!$B:$B,SummaryDetail!B${rowCount})`,
          },
          {
            id: "cancelledTransactions",
            formula: `SUMIFS('Canceled Orders Summary'!$D:$D, 'Canceled Orders Summary'!$A:$A,SummaryDetail!$A${rowCount},'Canceled Orders Summary'!$B:$B,SummaryDetail!B${rowCount})`,
          },

          {
            id: "3pdTax",
            formula: `SUMIFS('DS Report'!$${recColumns["3PD Net Tax"]}:$${recColumns["3PD Net Tax"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount})`,
          },

          {
            id: "mfSales",
            formula: `SUMIFS('DS Report'!$${recColumns["3PD Net Sales"]}:$${recColumns["3PD Net Sales"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount},'DS Report'!$${recColumns["Market Facilitator Tax"]}:$${recColumns["Market Facilitator Tax"]},"<0")`,
          },
          {
            id: "mfTax",
            formula: `-SUMIFS('DS Report'!$${recColumns["Market Facilitator Tax"]}:$${recColumns["Market Facilitator Tax"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount})`,
          },
          {
            id: "partialCollection",
            formula: `IF(COUNTIFS('DS Report'!$${recColumns["Partial Mf Tax"]}:$${recColumns["Partial Mf Tax"]},"TRUE",'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount})>0,"YES","NO")`,
          },
          {
            id: "deliveryFees",
            formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount})`,
          },
          {
            id: "pickupFees",
            formula: `SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount})`,
          },
          {
            id: "cateringFees",
            formula: `SUMIFS('DS Report'!$${recColumns["Catering Fees"]}:$${recColumns["Catering Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount})`,
          },
          {
            id: "promoFees",
            formula: `SUMIFS('DS Report'!$${recColumns["Promo Fees"]}:$${recColumns["Promo Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount})`,
          },
          { id: "totalFees", formula: `SUM(J${rowCount}:M${rowCount})` },
          {
            id: "averageFee",
            formula: `IFERROR(AVERAGEIFS('DS Report'!$${recColumns["Effective Total Fee Rate"]}:$${recColumns["Effective Total Fee Rate"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount},'DS Report'!$${recColumns["Total Fees"]}:$${recColumns["Total Fees"]},"<0"), 0)`,
          },
          {
            id: "campaignPromoFees",
            formula: `SUMIFS('DS Report'!$${recColumns["Campaign Promo Fees"]}:$${recColumns["Campaign Promo Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},SummaryDetail!$A${rowCount},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},SummaryDetail!$B${rowCount})`,
          },
        ]);
        rowCount += 1;
      });
    });
    /**CHECK FIGURE ROWS */
    //Totals
    summarySheet.addRow([
      "Total",
      "",
      { id: "total3pdSale", formula: `SUM(C8:C${rowCount - 1})` },
      { id: "totalErrorChrg", formula: `SUM(D8:D${rowCount - 1})` },
      { id: "totalCancelledTrans", formula: `SUM(E8:E${rowCount - 1})` },
      { id: "total3pdTax", formula: `SUM(F8:F${rowCount - 1})` },
      { id: "totalMFSale", formula: `SUM(G8:G${rowCount - 1})` },
      { id: "totalMFTax", formula: `SUM(H8:H${rowCount - 1})` },
      "",
      { id: "totalDelivery", formula: `SUM(J8:J${rowCount - 1})` },
      { id: "totalPickup", formula: `SUM(K8:K${rowCount - 1})` },
      { id: "totalCatering", formula: `SUM(L8:L${rowCount - 1})` },
      { id: "totalPromo", formula: `SUM(M8:M${rowCount - 1})` },
      { id: "totalFee", formula: `SUM(N8:N${rowCount - 1})` },
      "",
      { id: "totalCampaign", formula: `SUM(P8:P${rowCount - 1})` },
    ]);
    summarySheet.addConditionalFormatting({
      ref: "I8:I1000",
      rules: [
        {
          type: "containsText",
          operator: "containsText",
          text: "YES",
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FFD0D0" },
            },
            font: { color: { argb: "C42323" } },
          },
          priority: null,
        },
      ],
    });

    this.autoFitColumns(summarySheet);
    ["C", "D", "E", "F", "G", "H", "J", "K", "L", "M", "N", "P"].forEach(
      (column) => {
        this.formatAllCellsInColumn(summarySheet, column, "accounting");
      }
    );
    this.formatAllCellsInColumn(summarySheet, "O", "percent");
    summarySheet.getCell("B3").numFmt = "@";
  }
  async compileVarianceAnalysisSheet(
    varianceAnalysisSheet: Worksheet,
    locations: Location[]
  ) {
    const headerRow = varianceAnalysisSheet.addRow([
      "Location",
      "Third Party",
      "Sales Variance",
      "Sales Variance Amount",
      "Potential Pricing Issues*",
      "Error Charge",
      "Adjustment",
      "Transaction Missing In Source",
      "Transaction In POS not Paid Out",
      "Tax Variance",
      "Tax Variance Amount",
      "Tax Variance Reasons",
      "Anomalies",
      "Notes",
    ]);
    const thirdPartyReportVarianceAnalysisReports =
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.varianceAnalysisReports,
        locations
      );
    const anomalyValidations = await this.dataUtility.fetchAnomalyValidations();
    thirdPartyReportVarianceAnalysisReports.forEach(
      (report: ThirdPartyReportFragmentVarianceAnalysisReport) => {
        if (report.salesVarianceReasons) {
          // Potential Fraud Indicators Excluded from VA Report
          let reasonSum = 0;
          report.salesVarianceReasons
            .filter(
              (reason) => reason.type !== TransactionAITypes.potentialFraud
            )
            .forEach((reason) => {
              report[reason.type] = +reason.sale;
              reasonSum += +reason.sale ? +reason.sale : 0;
            });
          report["reasonSum"] = reasonSum;
        }
        const existingValidation = anomalyValidations.find((validation) => {
          return (
            validation.location === report.location &&
            validation.thirdParty === report.thirdParty
          );
        });
        if (existingValidation) {
          report["validation"] = existingValidation;
        }
      }
    );
    let rowCount = 1;
    thirdPartyReportVarianceAnalysisReports.forEach(
      (report: ThirdPartyReportFragmentVarianceAnalysisReport) => {
        const varianceReportRow = [
          this.dataUtility.client.locationIdString
            ? report.location
            : +report.location,
          this.dataUtility.getThirdPartyName(report.thirdParty),
          !!report.salesVariance &&
          (report.salesVarianceAmount > 0 || report.salesVarianceAmount < 0)
            ? "YES"
            : "NO",
          report.salesVarianceAmount ? +report.salesVarianceAmount : 0,
        ];
        for (let type in TransactionAITypes) {
          if (type !== "potentialFraud" && type !== "nonPosPayment") {
            // Potential Fraud Indicators Excluded from VA Report
            const varianceResonAmount = +report[TransactionAITypes[type]]
              ? +report[TransactionAITypes[type]]
              : "";
            varianceReportRow.push(varianceResonAmount);
          }
        }
        varianceReportRow.push(`${!!report.taxVariance ? "YES" : "NO"}`);
        varianceReportRow.push(
          report.taxVarianceAmount ? +report.taxVarianceAmount : 0
        );
        varianceReportRow.push(this.getReasonsCsv(report.taxVarianceReasons));
        varianceReportRow.push(this.getAnomaliesCsv(report.anomalies));
        varianceReportRow.push(
          report["validation"]
            ? `${report["validation"].validated ? "VALIDATED," : ""} ${
                report["validation"].message ? report["validation"].message : ""
              }`
            : ""
        );
        varianceAnalysisSheet.addRow(varianceReportRow);
        if (
          rowCount > 1 &&
          report.salesVarianceAmount.toFixed(2) !==
            report["reasonSum"].toFixed(2)
        ) {
          varianceAnalysisSheet.getCell(`D${rowCount}`).style = {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FFD0D0" },
              fgColor: { argb: "FFD0D0" },
            },
            font: { color: { argb: "C42323" } },
          };
        }
        varianceAnalysisSheet.getCell(`E${rowCount}`).alignment = {
          vertical: "top",
          horizontal: "left",
          wrapText: true,
        };
        varianceAnalysisSheet.getCell(`L${rowCount}`).alignment = {
          vertical: "top",
          horizontal: "left",
          wrapText: true,
        };
        varianceAnalysisSheet.getCell(`M${rowCount}`).alignment = {
          vertical: "top",
          horizontal: "left",
          wrapText: true,
        };
        varianceAnalysisSheet.getCell(`N${rowCount}`).alignment = {
          vertical: "top",
          horizontal: "left",
          wrapText: true,
        };
        rowCount++;
      }
    );
    ["D", "E", "F", "G", "H", "I", "K"].forEach((column) => {
      this.formatAllCellsInColumn(varianceAnalysisSheet, column, "accounting");
    });
    const salesVarianceColumns = ["C", "D"];
    const salesVarianceReasonsColumns = ["E", "F", "G", "H", "I"];
    const taxVarianceColumns = ["J", "K"];
    const taxVarianceReasonColumns = ["L"];
    const salesVarianceHeaderColor = "4b86d8";
    const salesVarianceReasonHeaderColor = "86ade4";
    const taxVarianceHeaderColor = "e67447";
    const taxVarianceReasonHeaderColor = "eea081";
    headerRow.eachCell((cell, number) => {
      const column = this.numberToLetters(number - 1);
      if (salesVarianceColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: salesVarianceHeaderColor },
          bgColor: { argb: salesVarianceHeaderColor },
        };
      } else if (salesVarianceReasonsColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: salesVarianceReasonHeaderColor },
          bgColor: { argb: salesVarianceReasonHeaderColor },
        };
      } else if (taxVarianceColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: taxVarianceHeaderColor },
          bgColor: { argb: taxVarianceHeaderColor },
        };
      } else if (taxVarianceReasonColumns.indexOf(column) !== -1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: taxVarianceReasonHeaderColor },
          bgColor: { argb: taxVarianceReasonHeaderColor },
        };
      }
      cell.alignment = { vertical: "top", horizontal: "left", wrapText: true };
    });
    headerRow.height = 45;
    this.autoFitColumns(varianceAnalysisSheet);
    varianceAnalysisSheet.getColumn(`L`).width = 45;
    varianceAnalysisSheet.getColumn(`M`).width = 45;
    varianceAnalysisSheet.getColumn(`N`).width = 45;
  }
  async compileTransactionAnalysisSheet(
    transactionAnalysisSheet: Worksheet,
    locations: Location[]
  ) {
    const headerRow = transactionAnalysisSheet.addRow([
      "Location",
      "Third Party",
      "Date",
      "Time",
      "Sales",
      "Tax",
      "Tip",
      "Misc",
      "Description",
      "Status",
      "Transaction Type",
      "Type",
      "Source",
      "Count",
      "3PD Transaction Id",
      "POS Transaction Id",
      "Out of Period",
    ]);
    headerRow.font = { bold: true };
    headerRow.border = {
      bottom: { style: "thin" },
    };

    const transansactionAnalysisReports =
      await this.dataUtility.compileDailyDrillDownBreakoutReports(
        "transactionAnalysis",
        locations
      );
    transansactionAnalysisReports.forEach((report) => {
      const thirdParty = this.dataUtility.filteredThirdParties.find(
        (dsp) => dsp.id === report.thirdParty
      );
      if (thirdParty) {
        transactionAnalysisSheet.addRow([
          this.dataUtility.client.locationIdString
            ? report.location
            : +report.location,
          `${thirdParty.name}`,
          `${
            report.date ? moment(report.date.toDate()).format("M/D/YYYY") : ""
          }`,
          `${report.time ? getTimeFromMinutesFromMidnight(report.time) : ""}`,
          report.sale,
          report.tax,
          report.tip,
          report.misc,
          report.description,
          report.status,
          report.transactionType,
          report.type,
          report.transactionSource,
          report.count,
          report.type === TransactionAITypes.transactionNotPaidOut
            ? report.thirdPartyTransactionId
            : null,
          report.type === TransactionAITypes.transactionNotPaidOut
            ? report.posTransactionId
            : null,
          report.outOfPeriod ? "TRUE" : "FALSE",
        ]);
      }
    });
    ["D", "E", "F", "G"].forEach((column) => {
      this.formatAllCellsInColumn(
        transactionAnalysisSheet,
        column,
        "accounting"
      );
    });
  }

  compileReportDataSheet(reportDataSheet: Worksheet, locations: Location[]) {
    reportDataSheet.addRow(
      Object.keys(reconciliationTableColumnMappings).map((key) => {
        return key;
      })
    );
    this.dataUtility.reportData
      .filter(
        (row: ThirdPartyReconciliationLocationData) =>
          !!locations.find((location) => location.locationId === row.locationId)
      )
      .map((row: ThirdPartyReconciliationLocationData) => {
        const exportLocationMap = [];
        Object.keys(reconciliationTableColumnMappings).forEach((key) => {
          if (key === "Location Id") {
            exportLocationMap.push(
              this.dataUtility.client.locationIdString
                ? row[reconciliationTableColumnMappings[key]]
                : +row[reconciliationTableColumnMappings[key]]
            );
          } else {
            exportLocationMap.push(row[reconciliationTableColumnMappings[key]]);
          }
        });
        reportDataSheet.addRow(exportLocationMap);
      });
    this.autoFitColumns(reportDataSheet);
  }

  async compileErrorChargeSheet(
    errorChargeSheet: Worksheet,
    locations: Location[]
  ) {
    const row = [
      "Location",
      "Third Party",
      "Date",
      "Time",
      "Status",
      "Transaction Type",
      "Refund Amount",
      "Order Amount",
      "Description",
      "Details",
      "Issue Count",
      "Transaction Id",
      "POS Transaction Id",
      "Workflow Id",
      "Parent Workflow Id",
      "Dispute Eligible",
      "Suggested Items to Dispute",
    ];
    const headerRow = errorChargeSheet.addRow(row);
    headerRow.font = { bold: true };
    headerRow.border = {
      bottom: { style: "thin" },
    };
    // Load precursor data
    await this.dataUtility.compileDailyDrillDownBreakoutReports(
      "errorTransactionAnalysis"
    );
    await this.dataUtility.fetchReconciliationReportDrillDownFragments(
      ReconciliationDrillDownReportTypes.errorChargeLogs
    );
    //
    const errorChargeTransactions =
      await this.dataUtility.fetchErrorChargeTransactions(locations);
    errorChargeTransactions.forEach((errorTransaction) => {
      const row = [
        this.dataUtility.client.locationIdString
          ? errorTransaction.location
          : +errorTransaction.location,
        `${
          this.dataUtility.filteredThirdParties.find(
            (dsp) => dsp.id === errorTransaction.thirdParty
          )?.name
        }`,
        `${
          errorTransaction.date
            ? moment(errorTransaction.date.toDate()).format("M/D/YYYY")
            : ""
        }`,
        this.getFormattedTime(errorTransaction.time),
        errorTransaction.status,
        errorTransaction.transactionType,
        errorTransaction["amount"],
        errorTransaction["errorChargeLog"]?.parentThirdPartyTransaction?.sale,
        `${errorTransaction.description ? errorTransaction.description : ""}`,
        `${errorTransaction.metadata ? errorTransaction.metadata : ""}`,
        errorTransaction["errorChargeLog"] &&
        errorTransaction["errorChargeLog"].issueCount
          ? +errorTransaction["errorChargeLog"].issueCount
          : "",
        errorTransaction.transactionId,
        `${
          errorTransaction["errorChargeLog"] &&
          errorTransaction["errorChargeLog"].posTransaction
            ? errorTransaction["errorChargeLog"].posTransaction.transactionId
            : ""
        }`,
        errorTransaction.vendorWorkflowId,
        errorTransaction["errorChargeLog"]?.parentThirdPartyTransaction
          ?.vendorWorkflowId,

        errorTransaction["errorChargeLog"]?.eligibleForDispute,
        errorTransaction["errorChargeLog"] &&
        errorTransaction["errorChargeLog"].itemsToDispute?.length > 0
          ? errorTransaction["errorChargeLog"].itemsToDispute.toString()
          : "",
      ];
      errorChargeSheet.addRow(row);
    });
  }

  async compileFeeAnalysisSheet(
    feeAnalysisSheet: Worksheet,
    locations: Location[]
  ) {
    const headerRow = feeAnalysisSheet.addRow([
      "Location",
      "Third Party",
      "Fee Type",
      "Fee Amount %",
      "Transactions",
      "Net Sales",
      "Net Tax",
      "Total Fees",
    ]);
    headerRow.font = { bold: true };
    headerRow.border = {
      bottom: { style: "thin" },
    };
    const feeAnalysisReports =
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.feeReports,
        locations
      );
    feeAnalysisReports.forEach((report) => {
      feeAnalysisSheet.addRow([
        this.dataUtility.client.locationIdString
          ? report.location
          : +report.location,
        `${
          this.dataUtility.filteredThirdParties.find(
            (dsp) => dsp.id === report.thirdParty
          )?.name
        }`,
        report.feeType,
        report.feeAmount > 0 ? +report.feeAmount.toFixed(2) : 0,
        report.transactions ? report.transactions.length : 0,
        report.netSales,
        report.netTax,
        report.totalFees,
      ]);
    });
    feeAnalysisSheet.getCell("J2").value = "Average Fee";
    feeAnalysisSheet.getCell("J3").value = "Fee Type";
    feeAnalysisSheet.getCell("J3").border = {
      bottom: { style: "thin" },
    };
    feeAnalysisSheet.getCell("J4").value = "delivery";
    feeAnalysisSheet.getCell("J5").value = "pickup";
    feeAnalysisSheet.getCell("J6").value = "no fees";
    feeAnalysisSheet.getCell("J7").value = "promo";
    let columnNumber = 10;
    this.dataUtility.filteredThirdParties.forEach((thirdParty) => {
      const column = this.numberToLetters(columnNumber);
      feeAnalysisSheet.getCell(`${column}3`).value = thirdParty.name;
      feeAnalysisSheet.getCell(`${column}3`).border = {
        bottom: { style: "thin" },
      };
      columnNumber++;
    });
    const lastColumnInAverageTable = this.numberToLetters(columnNumber - 1);
    columnNumber = 10;
    for (let i = 4; i < 8; i++) {
      this.dataUtility.filteredThirdParties.forEach((thirdParty) => {
        const column = this.numberToLetters(columnNumber);
        //@ts-ignore
        feeAnalysisSheet.getCell(`${column}${i}`).value = {
          formula: `IF(COUNTIFS($C:$C,$J${i},$B:$B,${this.numberToLetters(
            columnNumber
          )}$3) > 0,AVERAGEIFS($D:$D,$C:$C,$J${i},$B:$B,${this.numberToLetters(
            columnNumber
          )}$3),0)`,
        };
        feeAnalysisSheet.getCell(`${column}${i}`).numFmt = "0.00%";
        feeAnalysisSheet.getCell(`${column}${i}`).border = {
          right: { style: "thick" },
        };
        columnNumber++;
      });
      columnNumber = 10;
    }
    this.formatAllCellsInColumn(feeAnalysisSheet, "D", "percent");
    this.formatAllCellsInColumn(feeAnalysisSheet, "F", "accounting");
    this.formatAllCellsInColumn(feeAnalysisSheet, "G", "accounting");
    this.formatAllCellsInColumn(feeAnalysisSheet, "H", "accounting");

    feeAnalysisSheet.mergeCells(`J2:${lastColumnInAverageTable}2`);
    feeAnalysisSheet.getCell("J2").font = { bold: true };
    feeAnalysisSheet.getCell("J2").border = {
      bottom: { style: "thick" },
    };
    this.autoFitColumns(feeAnalysisSheet);
  }
  async compileTaxRateAnalysisSheet(
    taxRateAnalysisSheet: Worksheet,
    locations: Location[]
  ) {
    const headerRow = taxRateAnalysisSheet.addRow([
      "Location",
      "Third Party",
      "Tax Rate",
      "Transactions",
      "Net Sales",
      "Net Tax",
    ]);
    headerRow.font = { bold: true };
    headerRow.border = {
      bottom: { style: "thin" },
    };
    const thirdPartyReportFragmentTaxRateReports =
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.taxRateAnalysisReports,
        locations
      );
    thirdPartyReportFragmentTaxRateReports.forEach(
      (report: ThirdPartyReportFragmentTaxRateReport) => {
        taxRateAnalysisSheet.addRow([
          this.dataUtility.client.locationIdString
            ? report.location
            : +report.location,
          `${
            this.dataUtility.filteredThirdParties.find(
              (dsp) => dsp.id === report.thirdParty
            )?.name
          }`,
          report.taxRate,
          report.transactionCount ? report.transactionCount : 0,
          report.netSales,
          report.netTax,
        ]);
      }
    );

    this.formatAllCellsInColumn(taxRateAnalysisSheet, "C", "percent");
    this.formatAllCellsInColumn(taxRateAnalysisSheet, "D", "accounting");
    this.formatAllCellsInColumn(taxRateAnalysisSheet, "E", "accounting");
    this.autoFitColumns(taxRateAnalysisSheet);
  }
  async compileDepositsSheet(depositsSheet, locations: Location[]) {
    const sections = [
      "Deposit Info",
      "",
      "",
      "",
      "",
      "",
      "Payout Detail",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ];
    const header = [
      "Location",
      "Third Party",
      "Deposit Date",
      "Deposit ID",
      "Deposit Date Range",
      "Total Payout",
      "Sales",
      "Sale Correction",
      "Tip",
      "Adjustments",
      "Delivery Fees",
      "Tax",
      "Tax Remitted",
      "Misc",
      "Promo Fees",
      "Tax On Fees",
      "Unreported Difference",
    ];
    if (this.dataUtility.isPayoutReconciliationActive()) {
      sections.push(...["Comparison To Related Reconciliation", "", "", ""]);
      header.push(
        ...[
          "Payout Per Reconciliation",
          "Prior Period Adjustments Payout",
          "Variance",
          "Canceled Unpaid Per Rec",
        ]
      );
    }
    depositsSheet.addRow(sections);
    depositsSheet.mergeCells("A1:F1"); // DEPOSIT INFO MERGE
    depositsSheet.getCell("A1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    depositsSheet.mergeCells("G1:Q1"); // PAYOUT MAKUP MERGE
    depositsSheet.getCell("G1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    if (this.dataUtility.isPayoutReconciliationActive()) {
      depositsSheet.mergeCells("R1:U1");
      depositsSheet.getCell("R1").alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    }
    depositsSheet.addRow(header);
    const depositReports: PayoutReportFragmentLog[] =
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.depositReports,
        locations
      );
    depositReports.forEach((report) => {
      const transactionStartDate = report.thirdPartyPayout.transactionsStartDate
        ? moment(report.thirdPartyPayout.transactionsStartDate.toDate()).format(
            "l"
          )
        : "";
      const transactionEndDate = report.thirdPartyPayout.transactionsEndDate
        ? moment(report.thirdPartyPayout.transactionsEndDate.toDate()).format(
            "l"
          )
        : "";
      const dateRange =
        transactionEndDate && transactionStartDate
          ? `${transactionStartDate} - ${transactionEndDate}`
          : "";
      const row = [
        report.location,
        report["thirdPartyName"],
        moment(report.thirdPartyPayout.payoutDate.toDate()).format("l"),
        report.thirdPartyPayout.payoutId,
        dateRange,
        report.thirdPartyPayout.payout,
        report.thirdPartyPayout.sales,
        report.thirdPartyPayout.saleCorrection,
        report.thirdPartyPayout.tip,
        report.thirdPartyPayout.adjustments,
        report.thirdPartyPayout.deliveryFees,
        report.thirdPartyPayout.tax,
        report.thirdPartyPayout.taxRemitted,
        report.thirdPartyPayout.misc,
        report.thirdPartyPayout.promoFees,
        report.thirdPartyPayout.taxOnFees,
        report.thirdPartyPayout.unreportedDifference,
      ];
      if (this.dataUtility.isPayoutReconciliationActive()) {
        row.push(
          ...[
            report.reconciliationTransactionsRemittance,
            report.reconciliationPpaTransactionsRemittance,
            report.reconciliationPayoutVariance,
            report.canceledUnpaidAmount,
          ]
        );
      }
      depositsSheet.addRow(row);
    });
    [
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
    ].forEach((column) =>
      this.formatAllCellsInColumn(depositsSheet, column, "accounting")
    );
  }
  async compile3pdPerformanceSheet(performanceSheet, locations: Location[]) {
    performanceSheet.addRow([
      "Location",
      "Third Party",
      "Gross 3PD Sales",
      "Net 3PD Sales",
      "Transactions",
      "Error Charges",
      "Error Charge Count Rate",
      "Error Charge Amount",
      "Error Charge Amount Rate",
      "Canceled Orders",
      "Canceled Orders Count Rate",
      "Canceled Orders Amount",
      "Canceled Orders Amount Rate",
    ]);
    const thirdPartyPerformanceReports: ThirdPartyPerformanceSummaryReport[] =
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.thirdPartyPerformanceSummaryReport,
        locations
      );

    let rowCount = 2;
    thirdPartyPerformanceReports.forEach((report) => {
      performanceSheet.addRow([
        this.dataUtility.client.locationIdString
          ? report.location
          : +report.location,
        report["thirdPartyName"],
        report.grossSales,
        report.netSales,
        report.transactionCount,
        report.errorChargeCount,
        report.errorChargeCountRate,
        report.errorChargeAmount,
        report.errorChargeAmountRate,
        report.canceledOrderCount,
        report.canceledOrderCountRate,
        report.canceledOrderAmount,
        report.canceledOrderAmountRate,
      ]);
      this.formatCellsInRow(
        performanceSheet,
        ["G", "I", "K", "M"],
        rowCount,
        "percent"
      );
      rowCount++;
    });
    this.autoFitColumns(performanceSheet);
  }
  async compileCustomerRatingsSheet(
    customerRatingsSheet,
    locations: Location[]
  ) {
    const customerRatings = await this.dataUtility.fetchThirdPartyRatings(
      locations
    );
    const seriesData = this.dataUtility.getCustomerRatingsSeriesData(
      locations.map((location) => location.locationId),
      customerRatings
    );
    /**Summary Table */

    customerRatingsSheet.addRow(["Average Customer Ratings"]);
    customerRatingsSheet.mergeCells(`A1:D1`);
    customerRatingsSheet.getCell("A1").font = { bold: true };
    customerRatingsSheet.getCell("A1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    customerRatingsSheet.getCell("A1").border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    customerRatingsSheet.addRow([
      "Location",
      "Third Party",
      "Average Rating",
      "Ratings Count",
    ]);
    locations.forEach((location) => {
      Object.keys(seriesData.thirdPartyGroups).map((thirdPartyId) => {
        const avgRating =
          seriesData.data[location.locationId] &&
          seriesData.data[location.locationId][thirdPartyId]
            ? seriesData.data[location.locationId][thirdPartyId]
            : 0;
        customerRatingsSheet.addRow([
          location.locationId,
          this.dataUtility.getThirdPartyName(thirdPartyId),
          avgRating,
          this.dataUtility.getRatingsCount(location.locationId, thirdPartyId),
        ]);
      });
    });
    customerRatingsSheet.addRow([]);
    /**Details Table */
    const detailsHeaderRow = customerRatingsSheet.addRow([
      "Location",
      "Third Party",
      "Date",
      "Rating",
      "Description",
      "Tags",
      "Rating Type",
      "Transaction Id",
    ]);
    detailsHeaderRow.font = { bold: true };
    detailsHeaderRow.border = {
      bottom: { style: "thin" },
    };
    customerRatings
      .sort((a, b) => (a.thirdParty > b.thirdParty ? -1 : 1))
      .forEach((rating: ThirdPartyRating) => {
        customerRatingsSheet.addRow([
          rating.location,
          this.dataUtility.getThirdPartyName(rating.thirdParty),
          moment(rating.date.toDate()).format("l"),
          rating.rating,
          rating.description,
          rating.tags?.toString(),
          rating.ratingType,
          rating["transactionId"],
        ]);
      });
    this.autoFitColumns(customerRatingsSheet);
  }
  async compileStatusAnalysisSheet(statusAnalysisSheet, locations: Location[]) {
    const headerRow = statusAnalysisSheet.addRow([
      "Location",
      "Third Party",
      "Status",
      "Sales",
      "Tip",
      "Tax",
      "Tax Remitted",
      "Delivery Fees",
      "Promo Fees",
      "Pickup Fees",
      "Catering Fees",
      "Total Remitted",
    ]);
    headerRow.font = { bold: true };
    headerRow.border = {
      bottom: { style: "thin" },
    };

    const categoryReports: ThirdPartyCategoryReport[] =
      await this.dataUtility.compileCategoryReports("status", locations);
    categoryReports.forEach((report) => {
      statusAnalysisSheet.addRow([
        report["location"],
        report["thirdPartyName"],
        report.categoryName,
        report.netSales,
        report.tip,
        report.netTax,
        report.taxRemitted,
        report.deliveryFee,
        report.promoFee,
        report.pickupFee,
        report.cateringFee,
        report.totalRemitted,
      ]);
    });
    ["D", "E", "F", "G", "H", "I", "J", "K", "L"].forEach((column) =>
      this.formatAllCellsInColumn(statusAnalysisSheet, column, "accounting")
    );
    this.autoFitColumns(statusAnalysisSheet);
  }
  async compilePayoutAnalysisSheet(
    payoutAnalysisSheet: Worksheet,
    locations: Location[]
  ) {
    const rowColumns = [
      "Location",
      "Third Party",
      "Payout Date",
      "Transactions",
      "Total Payout",
    ];
    //SETUP DATA
    let bankTransactions = [];
    if (this.dataUtility.existingReport.includeBankInRec) {
      rowColumns.push(...["Bank Amount", "Bank Details"]);
      bankTransactions =
        await this.dataUtility.fetchAndAssignBankTransactions();
    }
    const thirdPartyReportFragmentPayoutReports =
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.payoutAnalysisReports,
        locations
      );
    if (bankTransactions.length > 0) {
      bankTransactions.forEach((bankTransaction) => {
        const payoutReport = new ThirdPartyReportFragmentPayoutReport();
        payoutReport.thirdParty = bankTransaction.thirdParty;
        payoutReport["bankAmount"] = bankTransaction.amount;
        payoutReport["bankDetails"] = bankTransaction.details;
        payoutReport.payoutDate = moment(bankTransaction.date.toDate()).format(
          "M/DD/YYYY"
        );
        payoutReport.location = bankTransaction.source;
        thirdPartyReportFragmentPayoutReports.push(payoutReport);
      });
    }
    //CREATE TAB
    const headerRow = payoutAnalysisSheet.addRow(rowColumns);
    headerRow.font = { bold: true };
    headerRow.border = {
      bottom: { style: "thin" },
    };
    thirdPartyReportFragmentPayoutReports
      .sort((a, b) => {
        return moment(a.payoutDate, "M/DD/YYYY").isBefore(
          moment(b.payoutDate, "M/DD/YYYY")
        )
          ? -1
          : 1;
      })
      .forEach((report: ThirdPartyReportFragmentPayoutReport) => {
        const newRow = [
          this.dataUtility.client.locationIdString
            ? report.location
            : +report.location,
          `${
            this.dataUtility.filteredThirdParties.find(
              (dsp) => dsp.id === report.thirdParty
            )?.name
          }`,
          report.payoutDate,
          report.transactionCount ? report.transactionCount : 0,
          report.remittance,
        ];
        if (this.dataUtility.existingReport.includeBankInRec) {
          newRow.push(
            ...[
              report["bankAmount"] ? report["bankAmount"] : "",
              report["bankDetails"] ? report["bankDetails"] : "",
            ]
          );
        }
        payoutAnalysisSheet.addRow(newRow);
      });
    this.formatAllCellsInColumn(payoutAnalysisSheet, "E", "accounting");
    this.autoFitColumns(payoutAnalysisSheet);
  }
  async compileAdjustmentSummarySheet(
    adjustmentSummarySheet: Worksheet,
    locations: Location[]
  ) {
    const rowColumns = [
      "Location",
      "Third Party",
      "Payout Date",
      "Date",
      "Sale Adjustment",
      "Tax Adjustment",
      "Promo Fee",
      "Transaction Id",
      "Status",
      "Transaction Type",
    ];
    //SETUP DATA

    const thirdPartyReportFragmentAdjustmentLogs: ThirdPartyReportFragmentAdjustmentLog[] =
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.adjustmentLogs,
        locations
      );

    const headerRow = adjustmentSummarySheet.addRow(rowColumns);
    headerRow.font = { bold: true };
    headerRow.border = {
      bottom: { style: "thin" },
    };
    thirdPartyReportFragmentAdjustmentLogs
      .sort((a, b) => {
        return moment(a.payoutDate, "M/DD/YYYY").isBefore(
          moment(b.payoutDate, "M/DD/YYYY")
        )
          ? -1
          : 1;
      })
      .forEach((log) => {
        const newRow = [
          +log.location ? +log.location : log.location,
          `${
            this.dataUtility.filteredThirdParties.find(
              (dsp) => dsp.id === log.thirdParty
            )?.name
          }`,
          log.payoutDate,
          log.date,
          log.saleAdjustment ? log.saleAdjustment : 0,
          log.taxAdjustment ? log.taxAdjustment : 0,
          log.promoFee ? log.promoFee : 0,
          log.transactionId,
          log.status,
          log.transactionType,
          log.description,
        ];

        adjustmentSummarySheet.addRow(newRow);
      });
    this.formatAllCellsInColumn(adjustmentSummarySheet, "D", "accounting");
    this.formatAllCellsInColumn(adjustmentSummarySheet, "E", "accounting");
    this.autoFitColumns(adjustmentSummarySheet);
  }
  async compileRevenueRecoveredSheet(
    revenueRecoveredSheet: Worksheet,
    locations: Location[]
  ) {
    const rowColumns = [
      "Location",
      "Third Party",
      "Payout Date",
      "Date",
      "Sale Adjustment",
      "Tax Adjustment",
      "Promo Fee",
      "Transaction Id",
      "Status",
      "Transaction Type",
      "Description",
    ];
    //SETUP DATA

    const revenueRecoveryAdjustments: ThirdPartyTransaction[] =
      await this.dataUtility.fetchRevenueRecoveredAdjustments(locations);

    const headerRow = revenueRecoveredSheet.addRow(rowColumns);
    headerRow.font = { bold: true };
    headerRow.border = {
      bottom: { style: "thin" },
    };
    revenueRecoveryAdjustments.forEach((transaction) => {
      const newRow = [
        +transaction.location ? +transaction.location : transaction.location,
        `${
          this.dataUtility.filteredThirdParties.find(
            (dsp) => dsp.id === transaction.thirdParty
          )?.name
        }`,
        transaction.payoutDate?.seconds
          ? moment(transaction.payoutDate.toDate()).format("l")
          : transaction.payoutDate,
        transaction.date?.seconds
          ? moment(transaction.date.toDate()).format("l")
          : transaction.date,
        transaction["saleAdjustment"] ? transaction["saleAdjustment"] : 0,
        transaction.taxCorrection ? transaction.taxCorrection : 0,
        transaction.promoFee ? transaction.promoFee : 0,
        transaction.transactionId,
        transaction.status,
        transaction.transactionType,
        transaction.description,
      ];

      revenueRecoveredSheet.addRow(newRow);
    });
    ["E", "F", "G"].forEach((columnLetter) => {
      this.formatAllCellsInColumn(
        revenueRecoveredSheet,
        columnLetter,
        "accounting"
      );
    });
    this.autoFitColumns(revenueRecoveredSheet);
  }
  async compileCanceledOrdersSheet(
    canceledOrdersSheet: Worksheet,
    locations: Location[]
  ) {
    const rowColumns = [
      "Location",
      "Third Party",
      "Date",
      "Sale",
      "Sale Adjustment",
      "Tax",
      "Tax Adjustment",
      "Delivery Fee",
      "Pickup Fee",
      "Promo Fee",
      "Transaction Id",
      "Status",
      "Transaction Type",
      "Description",
      "Details",
      "POS Order Id Reciept",
    ];
    //SETUP DATA

    const canceledOrders: ThirdPartyTransaction[] =
      await this.dataUtility.fetchCanceledOrders(locations);
    const transactionAnalysisReports = (
      await this.dataUtility.compileDailyDrillDownBreakoutReports(
        "transactionAnalysis"
      )
    ).filter((ta) => {
      return ta.type === TransactionAITypes.transactionNotPaidOut;
    });
    const headerRow = canceledOrdersSheet.addRow(rowColumns);
    headerRow.font = { bold: true };
    headerRow.border = {
      bottom: { style: "thin" },
    };
    canceledOrders.forEach((transaction) => {
      const matchingInPOS = transactionAnalysisReports.find(
        (ta) => ta.thirdPartyTransactionId === transaction.transactionId
      );
      if (matchingInPOS) {
        transaction["posOrderId"] = matchingInPOS.posTransactionId;
      }

      const newRow = [
        +transaction.location ? +transaction.location : transaction.location,
        `${
          this.dataUtility.filteredThirdParties.find(
            (dsp) => dsp.id === transaction.thirdParty
          )?.name
        }`,
        moment(transaction.date.toDate()).format("l"),
        transaction.sale,
        transaction.saleCorrection ? transaction.saleCorrection : 0,
        transaction.tax,
        transaction.taxCorrection ? transaction.taxCorrection : 0,
        transaction.deliveryFeeTotal ? transaction.deliveryFeeTotal : 0,
        transaction.pickupFeeTotal ? transaction.pickupFeeTotal : 0,
        transaction.promoFee ? transaction.promoFee : 0,
        transaction.transactionId,
        transaction.status,
        transaction.transactionType,
        transaction.description,
        transaction.metadata,
        data["posOrderId"],
      ];

      canceledOrdersSheet.addRow(newRow);
    });
    ["D", "E", "F", "G", "H", "I", "J"].forEach((columnLetter) => {
      this.formatAllCellsInColumn(
        canceledOrdersSheet,
        columnLetter,
        "accounting"
      );
    });
    this.autoFitColumns(canceledOrdersSheet);
  }
  async compileFraudDetectionSheet(
    fraudDetectionSheet: Worksheet,
    locations: Location[]
  ) {
    const headerRow = fraudDetectionSheet.addRow([
      "Location",
      "Third Party",
      "Date",
      "POS Transaction Id",
      "Sales",
      "Tax",
      "Tip",
    ]);
    headerRow.font = { bold: true };
    headerRow.border = {
      bottom: { style: "thin" },
    };
    const thirdPartyPotentialFraudIndicators =
      await this.dataUtility.compileDailyDrillDownBreakoutReports(
        "fraudIndicators",
        locations
      );
    thirdPartyPotentialFraudIndicators.forEach(
      (report: ThirdPartyReportFragmentTransactionAnalysis) => {
        fraudDetectionSheet.addRow([
          this.dataUtility.client.locationIdString
            ? report.location
            : +report.location,
          `${
            this.dataUtility.filteredThirdParties.find(
              (dsp) => dsp.id === report.thirdParty
            )?.name
          }`,
          moment(report.date.toDate()).format("l"),
          report.posTransactionId,
          report.sale,
          report.tax,
          report.tip,
        ]);
      }
    );
    this.formatAllCellsInColumn(fraudDetectionSheet, "E", "accounting");
    this.formatAllCellsInColumn(fraudDetectionSheet, "F", "accounting");
    this.formatAllCellsInColumn(fraudDetectionSheet, "G", "accounting");
    this.autoFitColumns(fraudDetectionSheet);
  }
  compileNetCashReport(netCashSheet: Worksheet, locations: Location[]) {
    const posHeaderColor = "99CC00";
    const dspHeaderColor = "FF8080";

    const posColumns = ["C", "D", "E", "F"];
    const dspColumns = ["G", "H", "I", "J", "K", "L", "M"];

    const headers = ["Location", "3PD"];

    if (this.dataUtility.existingReport.includePosInRec) {
      headers.push(
        ...["POS Sales", "POS Tax", "POS Adjustments", "POS Total Cash"]
      );
    }
    headers.push(
      ...[
        "3PD Gross Sales",
        "3PD Tips",
        "Error Charges",
        "3PD Tax To Client",
        "Fees (Delivery/Pickup/Catering)",
        "Promo Fees",
        "3PD Adjustments",
        "3PD Total Cash",
      ]
    );
    if (this.dataUtility.existingReport.includePosInRec) {
      headers.push(...["Cash Variance"]);
    }

    const headerRow = netCashSheet.addRow(headers);
    if (this.dataUtility.existingReport.includePosInRec) {
      headerRow.eachCell((cell, number) => {
        const column = this.numberToLetters(number - 1);
        if (posColumns.indexOf(column) !== -1) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: posHeaderColor },
            bgColor: { argb: posHeaderColor },
          };
        } else if (dspColumns.indexOf(column) !== -1) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: dspHeaderColor },
            bgColor: { argb: dspHeaderColor },
          };
        }
      });
    }
    let rowNumber = 2;
    this.dataUtility.filteredThirdParties.forEach((thirdParty) => {
      locations.forEach((location) => {
        const newRow: any[] = [
          this.dataUtility.client.locationIdString
            ? location.locationId
            : +location.locationId,
          thirdParty.name,
        ];
        if (this.dataUtility.existingReport.includePosInRec) {
          newRow.push(
            ...[
              {
                id: "posSales",
                formula: `SUMIFS('DS Report'!${recColumns["POS Net Sales"]}:${recColumns["POS Net Sales"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},'Net Cash'!$A${rowNumber},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},'Net Cash'!$B${rowNumber})`,
              },
              {
                id: "posTax",
                formula: `SUMIFS('DS Report'!${recColumns["POS Net Tax"]}:${recColumns["POS Net Tax"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},'Net Cash'!$A${rowNumber},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},'Net Cash'!$B${rowNumber})`,
              },
              {
                id: "posAdj",
                formula: `SUMIFS('DS Report'!${recColumns["POS Other Charges"]}:${recColumns["POS Other Charges"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},'Net Cash'!$A${rowNumber},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},'Net Cash'!$B${rowNumber})+SUMIFS('DS Report'!${recColumns["POS Other Revenue"]}:${recColumns["POS Other Revenue"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},'Net Cash'!$A${rowNumber},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},'Net Cash'!$B${rowNumber})`,
              },
              {
                id: "totalCashPos",
                formula: `SUM(C${rowNumber}:E${rowNumber})`,
              },
            ]
          );
        }
        newRow.push(
          ...[
            {
              id: "dspGrossSales",
              formula: `SUMIFS('DS Report'!${recColumns["3PD Gross Sales"]}:${recColumns["3PD Gross Sales"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},'Net Cash'!$A${rowNumber},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},'Net Cash'!$B${rowNumber})`,
            },
            {
              id: "dspTips",
              formula: `SUMIFS('DS Report'!${recColumns["3PD Tips"]}:${recColumns["3PD Tips"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},'Net Cash'!$A${rowNumber},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},'Net Cash'!$B${rowNumber})`,
            },
            {
              id: "errorCharges",
              formula: `-SUMIFS('Error Charges'!G:G,'Error Charges'!A:A,'Net Cash'!A${rowNumber},'Error Charges'!B:B,'Net Cash'!B${rowNumber})`,
            },
            {
              id: "taxToRestaurant",
              formula: `SUMIFS('DS Report'!${recColumns["3PD Net Tax"]}:${recColumns["3PD Net Tax"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},'Net Cash'!$A${rowNumber},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},'Net Cash'!$B${rowNumber})+SUMIFS('DS Report'!${recColumns["Market Facilitator Tax"]}:${recColumns["Market Facilitator Tax"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},'Net Cash'!$A${rowNumber},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},'Net Cash'!$B${rowNumber})`,
            },
            {
              id: "nonPromoFees",
              formula: `SUMIFS('DS Report'!${recColumns["Delivery Fees"]}:${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},'Net Cash'!$A${rowNumber},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},'Net Cash'!$B${rowNumber})+SUMIFS('DS Report'!${recColumns["Pickup Fees"]}:${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},'Net Cash'!$A${rowNumber},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},'Net Cash'!$B${rowNumber})+SUMIFS('DS Report'!${recColumns["Catering Fees"]}:${recColumns["Catering Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},'Net Cash'!$A${rowNumber},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},'Net Cash'!$B${rowNumber})`,
            },
            {
              id: "promoFees",
              formula: `SUMIFS('DS Report'!${recColumns["Promo Fees"]}:${recColumns["Promo Fees"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},'Net Cash'!$A${rowNumber},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},'Net Cash'!$B${rowNumber})`,
            },
            {
              id: "dspAdj",
              formula: `SUMIFS('DS Report'!${recColumns["3PD Other Charges"]}:${recColumns["3PD Other Charges"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},'Net Cash'!$A${rowNumber},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},'Net Cash'!$B${rowNumber})+SUMIFS('DS Report'!${recColumns["3PD Other Revenue"]}:${recColumns["3PD Other Revenue"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},'Net Cash'!$A${rowNumber},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},'Net Cash'!$B${rowNumber})+SUMIFS('DS Report'!${recColumns["3PD Misc"]}:${recColumns["3PD Misc"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},'Net Cash'!$A${rowNumber},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},'Net Cash'!$B${rowNumber})+SUMIFS('DS Report'!${recColumns["Backup Withholding Tax"]}:${recColumns["Backup Withholding Tax"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},'Net Cash'!$A${rowNumber},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},'Net Cash'!$B${rowNumber})`,
            },
          ]
        );
        if (this.dataUtility.existingReport.includePosInRec) {
          newRow.push(
            ...[
              {
                id: "totalCashDsp",
                formula: `SUM(G${rowNumber}:M${rowNumber})`,
              },
              {
                id: "cashVariance",
                formula: `F${rowNumber}-N${rowNumber}`,
              },
            ]
          );
        } else {
          newRow.push(
            ...[
              {
                id: "totalCashDsp",
                formula: `SUM(C${rowNumber}:H${rowNumber})`,
              },
            ]
          );
        }
        netCashSheet.addRow(newRow);
        rowNumber += 1;
      });
    });
    this.autoFitColumns(netCashSheet);
    ["C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N"].forEach(
      (column) => {
        this.formatAllCellsInColumn(netCashSheet, column, "accounting");
      }
    );
  }
  compileTaxSummarySheet(taxReportSheet: Worksheet, locations: Location[]) {
    taxReportSheet.addRow([
      ...this.getEmptyCells(8),
      "3PD MF Remitted",
      ...this.getEmptyCells(1),
      "Non-MF Collection/Remittance",
      ...this.getEmptyCells(3),
      "Partial MF Collection/Remittance - LOCAL Additional",
      ...this.getEmptyCells(5),
      "POS Tax Impacts",
    ]);
    const headerRow = taxReportSheet.addRow([
      "Location",
      "StateProvince",
      "3PD",
      "Partial",
      "POS Tax Rate",
      "3PD Average Tax Rate",
      "Total Sales",
      "Comps and Discounts",
      "MF Sales",
      "3pd Remitted Tax",
      "Taxable Sales",
      "Est. Tax Due",
      "3PD Remitted ",
      "Variance",
      "Local Only Taxable",
      "Client Local Rate",
      "Client Responsible",
      "Per 3PD",
      "Variance",
      "Notes",
      "POS Recorded Tax",
      "Suggested Tax Adjustment",
    ]);
    let rowCount = 3;
    this.dataUtility.filteredThirdParties.forEach((thirdParty) => {
      locations.forEach((location) => {
        const rateNotes = `${
          location.mfRateNotes && location.mfRateNotes.length > 0
            ? `${location.mfRateNotes[0].note} @ ${
                location.mfRateNotes[0].rate
              }% ${
                location.mfRateNotes[0].effectiveDate
                  ? " As Of " +
                    moment(
                      location.mfRateNotes[0].effectiveDate.toDate()
                    ).format("M/D/YYYY")
                  : ""
              }`
            : ""
        }`;
        const reportDataRow = this.dataUtility.reportData.find((row) => {
          return (
            row.locationId === location.locationId &&
            row.thirdParty === thirdParty.id
          );
        });
        const isPartial =
          reportDataRow && reportDataRow.isPartialMfTax ? "YES" : "NO";
        // if (isPartial === 'YES') {
        const taxSummaryRow = taxReportSheet.addRow([
          this.dataUtility.client.locationIdString
            ? location.locationId
            : +location.locationId,
          location.addressState,
          thirdParty.name,
          isPartial,
          {
            id: "taxRate",
            formula: `VLOOKUP(A${rowCount},'DS Report'!${recColumns["Location Id"]}:${recColumns["Location Tax Rate"]},MATCH("Location Tax Rate",'DS Report'!$1:$1,0),FALSE)`,
          },
          {
            id: "dspAvgTaxRate",
            formula: `SUMIFS('DS Report'!${recColumns["3PD Average Tax Rate"]}:${recColumns["3PD Average Tax Rate"]},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},C${rowCount},'DS Report'!${recColumns["Location Id"]}:${recColumns["Location Id"]},A${rowCount})`,
          },
          {
            id: "totalSales",
            formula: `SUMIFS('DS Report'!${recColumns["3PD Net Sales"]}:${recColumns["3PD Net Sales"]},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},'Tax Report'!C${rowCount},'DS Report'!${recColumns["Location Id"]}:${recColumns["Location Id"]},'Tax Report'!A${rowCount})`,
          },
          {
            id: "compsDiscounts",
            formula: `SUMIFS('DS Report'!${recColumns["Campaign Promo Fees"]}:${recColumns["Campaign Promo Fees"]},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},C${rowCount},'DS Report'!${recColumns["Location Id"]}:${recColumns["Location Id"]},A${rowCount})`,
          },
          {
            id: "mfSales",
            formula: `SUMIFS('DS Report'!${recColumns["3PD Net Sales"]}:${recColumns["3PD Net Sales"]},'DS Report'!${recColumns["Location Id"]}:${recColumns["Location Id"]},A${rowCount},'DS Report'!${recColumns["Market Facilitator Tax"]}:${recColumns["Market Facilitator Tax"]},"<0",'DS Report'!${recColumns["3PD"]}:${recColumns["3PD"]},C${rowCount})`,
          },
          {
            id: "totalTax",
            formula: `ABS(SUMIFS('DS Report'!${recColumns["Market Facilitator Tax"]}:${recColumns["Market Facilitator Tax"]},'DS Report'!${recColumns["Location Id"]}:${recColumns["Location Id"]},A${rowCount},'DS Report'!${recColumns["3PD"]}:${recColumns["3PD"]},C${rowCount}))`,
          },
          {
            id: "taxableSales",
            formula: `IF(G${rowCount} = I${rowCount},0,G${rowCount}-H${rowCount}-I${rowCount})`,
          },
          { id: "estTaxDue", formula: `K${rowCount}*E${rowCount}` },
          {
            id: "fullPer3pd",
            formula: `SUMIFS('DS Report'!$${recColumns["Client Tax Responsibility"]}:$${recColumns["Client Tax Responsibility"]},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},$A${rowCount},'DS Report'!${recColumns["3PD"]}:${recColumns["3PD"]},C${rowCount},'DS Report'!$${recColumns["Market Facilitator Tax"]}:$${recColumns["Market Facilitator Tax"]},">=0")`,
          },
          { id: "variance", formula: `L${rowCount}-M${rowCount}` },
          {
            id: "localOnlyTaxable",
            formula: `IF(D${rowCount}="YES",I${rowCount}-H${rowCount},0)`,
          },
          location.mfRateNotes && location.mfRateNotes.length > 0
            ? location.mfRateNotes[0].rate / 100
            : 0,
          {
            id: "clientResponsibile",
            formula: `O${rowCount}*(P${rowCount})`,
          },
          {
            id: "partialPer3PD",
            formula: `SUMIFS('DS Report'!${recColumns["Client Tax Responsibility"]}:${recColumns["Client Tax Responsibility"]},'DS Report'!${recColumns["Location Id"]}:${recColumns["Location Id"]},A${rowCount},'DS Report'!${recColumns["3PD"]}:${recColumns["3PD"]},C${rowCount},'DS Report'!${recColumns["Market Facilitator Tax"]}:${recColumns["Market Facilitator Tax"]},"<0")`,
          },
          { id: "partialVariance", formula: `Q${rowCount}-R${rowCount}` },
          rateNotes,

          {
            id: "posRecordedTax",
            formula: `SUMIFS('DS Report'!${recColumns["POS Net Tax"]}:${recColumns["POS Net Tax"]},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},C${rowCount},'DS Report'!${recColumns["Location Id"]}:${recColumns["Location Id"]},A${rowCount})`,
          },
          {
            id: "suggestedTaxAdjustment",
            formula: `SUMIFS('DS Report'!${recColumns["Suggested Tax Adjustment"]}:${recColumns["Suggested Tax Adjustment"]},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},C${rowCount},'DS Report'!${recColumns["Location Id"]}:${recColumns["Location Id"]},A${rowCount})`,
          },
        ]);
        this.formatCellsInRow(
          taxReportSheet,
          ["E", "F", "P"],
          rowCount,
          "percent"
        );
        this.formatCellsInRow(
          taxReportSheet,
          ["G", "H", "I", "J", "K", "L", "M", "N", "O", "Q", "R", "S"],
          rowCount,
          "accounting"
        );
        rowCount++;
        // }
      });
    });
    taxReportSheet.mergeCells("I1:J1");
    taxReportSheet.getCell("I1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    taxReportSheet.getCell("I1").border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    taxReportSheet.mergeCells("K1:N1");
    taxReportSheet.getCell("K1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    taxReportSheet.getCell("K1").border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    taxReportSheet.mergeCells("O1:T1");
    taxReportSheet.getCell("O1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    taxReportSheet.getCell("O1").border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    taxReportSheet.mergeCells("U1:V1");
    taxReportSheet.getCell("U1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    taxReportSheet.getCell("U1").border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  }
  compileTipVarianceSheet(tipVarianceSheet: Worksheet, locations: Location[]) {
    tipVarianceSheet.addRow([
      "Location",
      "3PD",
      "POS Tips",
      "Third Party Tips",
      "Variance",
    ]);
    let rowCount = 2;
    this.dataUtility.filteredThirdParties.forEach((thirdParty) => {
      locations.forEach((location) => {
        const reportDataRow = this.dataUtility.reportData.find((row) => {
          return (
            row.locationId === location.locationId &&
            row.thirdParty === thirdParty.id
          );
        });
        if (reportDataRow.posTips !== 0 || reportDataRow.thirdPartyTips !== 0) {
          tipVarianceSheet.addRow([
            this.dataUtility.client.locationIdString
              ? location.locationId
              : +location.locationId,
            thirdParty.name,
            {
              id: "posTips",
              formula: `SUMIFS('DS Report'!$${recColumns["POS Tips"]}:$${recColumns["POS Tips"]},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},B${rowCount},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},A${rowCount})`,
            },
            {
              id: "dspTips",
              formula: `SUMIFS('DS Report'!$${recColumns["3PD Tips"]}:$${recColumns["3PD Tips"]},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},B${rowCount},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},A${rowCount})`,
            },
            {
              id: "variance",
              formula: `C${rowCount} - D${rowCount}`,
            },
          ]);
          this.formatCellsInRow(tipVarianceSheet, ["H"], rowCount, "percent");
          this.formatCellsInRow(
            tipVarianceSheet,
            ["C", "D", "E"],
            rowCount,
            "accounting"
          );
          rowCount++;
        }
      });
    });
  }
  compileFeeSummarySheet(feeSummarySheet: Worksheet, locations: Location[]) {
    const headerRow = feeSummarySheet.addRow([
      "Location",
      "3PD",
      "Delivery Fees",
      "Pickup Fees",
      "Promo Fees",
      "Catering Fees",
      "Custom Fees",
      "Total Fees",
      "3PD Misc",
      "Other Charges",
    ]);
    let rowCount = 2;
    this.dataUtility.filteredThirdParties.forEach((thirdParty) => {
      locations.forEach((location) => {
        const reportDataRow = this.dataUtility.reportData.find((row) => {
          return (
            row.locationId === location.locationId &&
            row.thirdParty === thirdParty.id
          );
        });
        feeSummarySheet.addRow([
          this.dataUtility.client.locationIdString
            ? location.locationId
            : +location.locationId,
          thirdParty.name,
          {
            id: "deliveryFees",
            formula: `SUMIFS('DS Report'!$${recColumns["Delivery Fees"]}:$${recColumns["Delivery Fees"]},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},B${rowCount},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},A${rowCount})`,
          },
          {
            id: "pickupFees",
            formula: `SUMIFS('DS Report'!$${recColumns["Pickup Fees"]}:$${recColumns["Pickup Fees"]},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},B${rowCount},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},A${rowCount})`,
          },
          {
            id: "promoFees",
            formula: `SUMIFS('DS Report'!$${recColumns["Promo Fees"]}:$${recColumns["Promo Fees"]},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},B${rowCount},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},A${rowCount})`,
          },

          {
            id: "cateringFees",
            formula: `SUMIFS('DS Report'!$${recColumns["Catering Fees"]}:$${recColumns["Catering Fees"]},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},B${rowCount},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},A${rowCount})`,
          },

          {
            id: "customFees",
            formula: `SUMIFS('DS Report'!$${recColumns["Custom Fees"]}:$${recColumns["Custom Fees"]},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},B${rowCount},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},A${rowCount})`,
          },
          { id: "totalFees", formula: `SUM(C${rowCount}:G${rowCount})` },

          {
            id: "misc",
            formula: `SUMIFS('DS Report'!$${recColumns["3PD Misc"]}:$${recColumns["3PD Misc"]},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},B${rowCount},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},A${rowCount})`,
          },

          {
            id: "otherCharges",
            formula: `SUMIFS('DS Report'!$${recColumns["3PD Other Charges"]}:$${recColumns["3PD Other Charges"]},'DS Report'!$${recColumns["3PD"]}:$${recColumns["3PD"]},B${rowCount},'DS Report'!$${recColumns["Location Id"]}:$${recColumns["Location Id"]},A${rowCount})`,
          },
        ]);
        this.formatCellsInRow(feeSummarySheet, ["H"], rowCount, "percent");
        this.formatCellsInRow(
          feeSummarySheet,
          ["C", "D", "E", "F", "G", "H", "I", "J"],
          rowCount,
          "accounting"
        );
        rowCount++;
        // }
      });
    });
  }
  compileRecCheckSheet(recCheckSheet: Worksheet, locations: Location[]) {
    const headerRow = recCheckSheet.addRow([
      "",
      "Summary",
      "V. Analysis",
      "",
      "V.Analysis",
      "Error Charge",
      "",
      "V.Analysis",
      "T.Analysis",
      "",
      "V.Analysis",
      "T.Analysis",
      "",
      "V.Analysis",
      "T.Analysis",
      "",
      "DS Report",
      "T.Analysis",
      "",
    ]);
    const tableHeaderRow = recCheckSheet.addRow([
      "Location Id",
      "Sales Variance",
      "Sales Variance",
      "Difference",
      "Error Charge",
      "Error Charge",
      "Difference",
      "Transaction Missing In Source",
      "Transaction Missing In Source",
      "Difference",
      "Transaction In POS Not Paid Out",
      "Transaction In POS Not Paid Out",
      "Difference",
      "Adjustment",
      "Adjustment",
      "Difference",
      "Misc",
      "Misc",
      "Difference",
    ]);
    tableHeaderRow.border = {
      bottom: { style: "thin" },
    };
    let rowCount = 3;
    locations.forEach((location) => {
      recCheckSheet.addRow([
        this.dataUtility.client.locationIdString
          ? location.locationId
          : +location.locationId,
        {
          id: "summarySaleVar",
          formula: `VLOOKUP($A${rowCount},Summary!$A:$G,4,FALSE)`,
        },
        {
          id: "vASaleVar",
          formula: `SUMIF('Variance Analysis'!$A:$A,'REC Check'!$A${rowCount},'Variance Analysis'!D:D)`,
        },
        { id: "saleVarDiff", formula: `B${rowCount}-C${rowCount}` },
        {
          id: "vAErrChrg",
          formula: `SUMIF('Variance Analysis'!$A:$A,'REC Check'!$A${rowCount},'Variance Analysis'!F:F)`,
        },
        {
          id: "errChrg",
          formula: `SUMIF('Error Charges'!A:A,'REC Check'!A${rowCount},'Error Charges'!G:G)`,
        },
        { id: "errChrgDiff", formula: `E${rowCount}-F${rowCount}` },
        {
          id: "vATransMissing",
          formula: `SUMIF('Variance Analysis'!$A:$A,'REC Check'!$A${rowCount},'Variance Analysis'!H:H)`,
        },
        {
          id: "tATransMissing",
          formula: `SUMIFS('Transaction Analysis'!$E:$E,'Transaction Analysis'!$A:$A,'REC Check'!$A${rowCount},'Transaction Analysis'!$L:$L,'REC Check'!I$2)`,
        },
        { id: "transMissingDiff", formula: `H${rowCount}-I${rowCount}` },
        {
          id: "vANotPaid",
          formula: `SUMIF('Variance Analysis'!$A:$A,'REC Check'!$A${rowCount},'Variance Analysis'!I:I)`,
        },
        {
          id: "tANotPaid",
          formula: `SUMIFS('Transaction Analysis'!$E:$E,'Transaction Analysis'!$A:$A,'REC Check'!$A${rowCount},'Transaction Analysis'!$L:$L,'REC Check'!L$2)`,
        },
        { id: "notPaidDiff", formula: `K${rowCount}-L${rowCount}` },
        {
          id: "vAAdjustments",
          formula: `SUMIF('Variance Analysis'!$A:$A,'REC Check'!$A${rowCount},'Variance Analysis'!G:G)`,
        },
        {
          id: "tAAdjustments",
          formula: `SUMIFS('Transaction Analysis'!$E:$E,'Transaction Analysis'!$A:$A,'REC Check'!$A${rowCount},'Transaction Analysis'!$L:$L,'REC Check'!O$2)`,
        },
        { id: "adjustmentsDiff", formula: `N${rowCount}-O${rowCount}` },
        //MISC Payments
        {
          id: "dsReportMisc",
          formula: `SUMIF('DS Report'!A:A,'Rec Check'!$A${rowCount},'DS Report'!${recColumns["3PD Misc"]}:${recColumns["3PD Misc"]})`,
        },
        {
          id: "tAMisc",
          formula: `=SUMIF('Transaction Analysis'!A:A,'Rec Check'!$A${rowCount},'Transaction Analysis'!H:H)`,
        },
        { id: "miscDiff", formula: `Q${rowCount}-R${rowCount}` },
      ]);
      rowCount++;
    });
    // recCheckSheet.addConditionalFormatting({
    //   ref: "F2:F1000",
    //   rules: [
    //     {
    //       type: "cellIs",
    //       operator: "lessThan",
    //       formulae: [
    //         this.dataUtility.client3pdConfiguration.taxVarianceThreshold
    //           ? this.dataUtility.client3pdConfiguration.taxVarianceThreshold *
    //             -1
    //           : -10,
    //       ],
    //       style: {
    //         fill: {
    //           type: "pattern",
    //           pattern: "solid",
    //           bgColor: { argb: "FFD0D0" },
    //         },
    //         font: { color: { argb: "C42323" } },
    //       },
    //       priority: null,
    //     },
    //   ],
    // });
    this.autoFitColumns(recCheckSheet);
  }

  // Utility Functions
  async compileReportNotesSheet(
    reportNotesSheet: Worksheet,
    locations: Location[]
  ) {
    const titleRow = reportNotesSheet.addRow([
      "Review Comments",
      "",
      "",
      "",
      "",
      "",
      "",
      "Transaction Flags",
    ]);
    reportNotesSheet.mergeCells("A1:E1");
    reportNotesSheet.getCell("A1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    reportNotesSheet.getCell("A1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "add8e6" },
      bgColor: { argb: "add8e6" },
    };
    reportNotesSheet.mergeCells("H1:P1");
    reportNotesSheet.getCell("H1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    reportNotesSheet.getCell("H1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "fed8b1" },
      bgColor: { argb: "fed8b1" },
    };
    const headerRow = reportNotesSheet.addRow([
      "Locations",
      "3PDs",
      "Dates",
      "Creator",
      "Message",
      "",
      "",
      "Location",
      "Source",
      "Date",
      "Sale",
      "Tax",
      "TransactionId",
      "Status",
      "Transaction Type",
      "Notes",
    ]);
    // const clientPersistantNotes = FirestoreUtilities.mapToType(
    //   await lastValueFrom(
    //     this.afs
    //       .collection("thirdPartyReportNotes", (ref) =>
    //         ref
    //           .where("client", "==", this.dataUtility.client.id)
    //           .where("isPersistant", "==", true)
    //       )
    //       .snapshotChanges()
    //       .pipe(first())
    //   )
    // );
    const reportNotes = [
      // ...clientPersistantNotes,
      ...(<ThirdPartyReportNote[]>FirestoreUtilities.mapToType(
        await this.afs
          .collection("thirdPartyReportNotes", (ref) =>
            ref
              .where("client", "==", this.dataUtility.client.id)
              .where(
                "thirdPartyReport",
                "==",
                this.dataUtility.existingReport.id
              )
          )
          .snapshotChanges()
          .pipe(first())
          .toPromise()
      )),
    ]
      .filter((note: ThirdPartyReportNote) => {
        // filter for persistant notes not relevant
        let locationsFound = false;
        let thirdPartiesFound = false;
        note.thirdParties.forEach((thirdPartyId) => {
          if (
            this.dataUtility.existingReport.thirdParties.indexOf(thirdPartyId) >
            -1
          ) {
            thirdPartiesFound = true;
          }
        });
        note.locations.forEach((locationId) => {
          if (
            this.dataUtility.existingReport.locations.indexOf(locationId) > -1
          ) {
            locationsFound = true;
          }
        });

        return locationsFound && thirdPartiesFound;
      })
      .filter((note) => {
        // filter for entity breakout
        if (!note.locations || note.locations.length === 0) {
          return true;
        }
        const inLocationsCount = note.locations.filter((noteLocation) => {
          return !!locations.find((l) => l.locationId === noteLocation);
        });
        return inLocationsCount.length > 0;
      });

    const transactionFlags = <ThirdPartyReportTransactionFlag[]>(
      FirestoreUtilities.mapToType(
        await this.afs
          .collection("thirdPartyReportTransactionFlags", (ref) =>
            ref
              .where("client", "==", this.dataUtility.client.id)
              .where(
                "thirdPartyReport",
                "==",
                this.dataUtility.existingReport.id
              )
          )
          .snapshotChanges()
          .pipe(first())
          .toPromise()
      ).filter((flag) => {
        return !!locations.find(
          (location) => location.locationId === flag.transaction.location
        );
      })
    );
    const longestListCount =
      transactionFlags.length > reportNotes.length
        ? transactionFlags.length
        : reportNotes.length;
    if (reportNotes.length > 0) {
      await this.setNoteCreatorEmails(reportNotes);
    }
    let rowCount = 3;
    transactionFlags.forEach((transactionFlag) => {
      transactionFlag["source"] = transactionFlag.transaction.posSystem
        ? "POS"
        : this.dataUtility.getThirdPartyName(
            transactionFlag.transaction.thirdParty
          );
    });
    reportNotes.forEach((reportNote) => {
      const reportThirdParties = reportNote.thirdParties;
      //@ts-ignore
      reportNote.dspNames = reportThirdParties.reduce((txt, thirdPartyId) => {
        const matchingDsp = this.dataUtility.thirdParties.find(
          (dsp) => dsp.id === thirdPartyId
        );
        if (matchingDsp) {
          if (txt) {
            return `${txt}, ${matchingDsp.name}`;
          } else {
            return matchingDsp.name;
          }
        }
        return txt;
      }, "");
      //@ts-ignore
      reportNote.locationsText = reportNote.locations.reduce(
        (txt, locationId) => {
          if (txt) {
            return `${txt}, ${locationId}`;
          } else {
            return `${locationId}`;
          }
        },
        ""
      );
    });
    for (let i = 0; i < longestListCount; i++) {
      const reportNote = reportNotes[i];
      const transactionFlag = transactionFlags[i];
      const row = reportNotesSheet.addRow([
        reportNote ? reportNote["locationsText"] : "",
        reportNote ? reportNote["dspNames"] : "",
        reportNote
          ? reportNote.dates
            ? reportNote.dates.map(
                (date) => `${moment(date.toDate()).format("l")} `
              )
            : reportNote.startDate
            ? moment(reportNote.startDate.toDate()).format("M/D/YYYY")
            : ""
          : "",
        reportNote ? `${reportNote["creatorEmail"]}` : "",
        reportNote ? `${reportNote.message}` : "",
        "",
        "",
        transactionFlag ? transactionFlag.transaction.location : "",
        transactionFlag ? transactionFlag["source"] : "",
        transactionFlag
          ? moment(transactionFlag.transaction.date.toDate()).format("l")
          : "",
        transactionFlag ? transactionFlag.transaction.sale : 0,
        transactionFlag ? transactionFlag.transaction.tax : 0,
        transactionFlag ? transactionFlag.transaction.transactionId : "",
        transactionFlag ? transactionFlag.transaction.status : "",
        transactionFlag ? transactionFlag.transaction.transactionType : "",
        transactionFlag ? transactionFlag.note : "",
      ]);
      row.alignment = { vertical: "top", horizontal: "left" };
      reportNotesSheet.getCell(`E${rowCount}`).alignment = {
        vertical: "top",
        horizontal: "left",
        wrapText: true,
      };
      reportNotesSheet.getCell(`P${rowCount}`).alignment = {
        vertical: "top",
        horizontal: "left",
        wrapText: true,
      };
      rowCount++;
    }
    reportNotesSheet.getColumn(`E`).width = 35;
    reportNotesSheet.getColumn(`P`).width = 35;
    this.autoFitColumns(reportNotesSheet);
  }

  async compileDispatchSummaryReport(
    dispatchSummarySheet: Worksheet,
    locations: Location[]
  ) {
    const dispatchReports = (
      await this.dataUtility.fetchDispatchSummaryData()
    ).filter(
      (report) =>
        !!locations.find((location) => location.locationId == report.location)
    );
    const headerRow = dispatchSummarySheet.addRow([
      "Location",
      "Third Party",
      "Status",
      "Sales",
      "Tax",
      "Tip",
      "Delivery Charge",
      "Custom Fees",
      "Promo Fees",
      "Transaction Count",
    ]);
    dispatchReports.forEach(
      (report: ThirdPartyReportFragmentDispatchReport) => {
        dispatchSummarySheet.addRow([
          this.dataUtility.client.locationIdString
            ? report.location
            : +report.location,
          `${
            this.dataUtility.thirdParties.find(
              (dsp) => dsp.id === report.thirdParty
            )?.name
          }`,
          report.status,
          report.sales,
          report.tax,
          report.tip,
          report.deliveryCharge,
          report.customFees,
          report.promoFees,
          report.transactions ? report.transactions : "",
        ]);
      }
    );
    ["D", "E", "F", "G", "H", "I"].forEach((column) => {
      this.formatAllCellsInColumn(dispatchSummarySheet, column, "accounting");
    });
  }

  private async compilePriorPeriodAdjustmentsSheet(
    ppaSheet: Worksheet,
    locations: Location[]
  ) {
    const headerRow = ppaSheet.addRow([
      "Location",
      "Upload Period End Date",
      "Source",
      "Account",
      "Transaction Date",
      "Payout Date",
      "Sale",
      "Sale Adjustment",
      "Tax",
      "Tax Adjustment",
      "Tax Remitted",
      "Status",
      "Transaction Type",
      "Description",
      "Details",
      "Transaction Id",
      "Error Charge",
      "Adjustment",
    ]);
    headerRow.font = { bold: true };
    headerRow.border = {
      bottom: { style: "thin" },
    };
    const ppaTransactions = await this.dataUtility.fetchPriorPeriodAdjustments(
      locations
    );
    ppaTransactions.forEach((ppaT) => {
      ppaSheet.addRow([
        ppaT.location,
        moment(ppaT.uploadPeriodEnd.toDate()).format("l"),
        ppaT.type,
        this.dataUtility.getThirdPartyName(ppaT.account),
        moment(ppaT.transactionDate.toDate()).format("l"),
        ppaT.transaction.payoutDate
          ? moment(ppaT.transaction.payoutDate.toDate()).format("l")
          : "",
        ppaT.transaction.sale,
        ppaT.transaction.saleCorrection,
        ppaT.transaction.tax,
        ppaT.transaction.taxCorrection,
        ppaT.transaction.taxRemitted,
        ppaT.transaction.status,
        ppaT.transaction.transactionType,
        ppaT.transaction.description ? ppaT.transaction.description : "",
        ppaT.transaction.metaData,
        ppaT.transaction.transactionId,
        ppaT.isErrorCharge,
        ppaT.isAdjustment,
      ]);
    });
  }
  /**STANDARD JOURNAL ENTRIES */

  async checkForStandardJeExports(
    workbook: Workbook,
    selectedReports: any[],
    reportLocations,
    jeHeaderSelection?: ClientJeHeaderConfigurationGroup
  ) {
    if (
      selectedReports.indexOf("Customer Refunds") > -1 ||
      selectedReports.indexOf("Fees Aggregated") > -1 ||
      selectedReports.indexOf("Fees Detailed") > -1 ||
      selectedReports.indexOf("Sales Tax Adjustment") > -1 ||
      selectedReports.indexOf("Sales Variance Aggregated") > -1 ||
      selectedReports.indexOf("Sales Variance Detailed") > -1 ||
      selectedReports.indexOf("Deposit Details") > -1 ||
      selectedReports.indexOf("Unreported Deposit Difference") > -1 ||
      selectedReports.indexOf("Tips Payable") > -1 ||
      selectedReports.indexOf("Refund and Adjustment Tax") > -1 ||
      selectedReports.indexOf("POS Markup") > -1 ||
      selectedReports.indexOf("Other Revenue") > -1 ||
      selectedReports.indexOf("Deposit Aggregated") > -1
    ) {
      const jeGenerator = new JournalEntryGenerator(
        this.dataUtility,
        this.afs,
        workbook,
        reportLocations,
        selectedReports,
        jeHeaderSelection
      );
      await jeGenerator.generateAndAttachJournalEntries();
    }
  }

  //**END STANDARD JES */
  /**HELPER FUNCTIONS */
  private autoFitColumns(sheet: Worksheet) {
    sheet.columns.forEach(function (column, i) {
      column.width = 15;
    });
  }
  private formatCellsInRow(
    sheet: Worksheet,
    columns: string[],
    rowCount: number,
    type: "percent" | "accounting"
  ) {
    let formatCode;
    switch (type) {
      case "accounting":
        formatCode = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        break;
      case "percent":
        formatCode = "0.00%";
        break;
    }
    columns.forEach((column) => {
      sheet.getCell(`${column}${rowCount}`).numFmt = formatCode;
    });
  }

  private async setNoteCreatorEmails(thirdPartyReportNotes) {
    const creatorQuerys = thirdPartyReportNotes.map((thirdPartyReportNote) => {
      return this.afs
        .doc(`userViews/${thirdPartyReportNote.creator}`)
        .snapshotChanges()
        .pipe(first())
        .toPromise();
    });
    const creatorUserViews = <UserView[]>(
      FirestoreUtilities.mergeToType(await Promise.all(creatorQuerys))
    );
    thirdPartyReportNotes.forEach((thirdPartyReportNote) => {
      const creatorUserView = creatorUserViews.find(
        (userView) => userView.id === thirdPartyReportNote.creator
      );
      if (creatorUserView) {
        thirdPartyReportNote["creatorEmail"] = creatorUserView.email;
      }
    });
  }
  private numberToLetters(num) {
    let letters = "";
    while (num >= 0) {
      letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[num % 26] + letters;
      num = Math.floor(num / 26) - 1;
    }
    return letters;
  }
  private getEmptyCells(number) {
    const emptyCellArray = [];
    for (let i = 1; i <= number; i++) {
      emptyCellArray.push("");
    }
    return emptyCellArray;
  }
  private formatAllCellsInColumn(
    sheet: Worksheet,
    column: string,
    type: "percent" | "accounting"
  ) {
    let formatCode;
    switch (type) {
      case "accounting":
        formatCode = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        break;
      case "percent":
        formatCode = "0.00%";
        break;
    }
    sheet.columns.forEach((c, i) => {
      const columnAlpha = this.numberToLetters(i);
      if (columnAlpha === column) {
        c["eachCell"]({ includeEmpty: true }, (cell) => {
          cell.numFmt = formatCode;
        });
      }
    });
  }
}

//async compileAnalyticsBreakoutAggregatedSummarySheet(
//   sheet: Worksheet,
//   locations: Location[]
// ) {
//   const headerRow = sheet.addRow([
//     "Location",
//     "Location City-State",
//     "3PD Gross Sales",
//     "Error Charges",
//     "3PD Sale Adjustments",
//     "3PD Net Sales",
//     "Campaign Promo Fees",
//     "Taxable Sales",
//     "Canceled Orders",
//     "3PD Tax Collected",
//     "Tax Remitted To Restaurant",
//     "Backup Withholding Tax",
//     "Delivery Fees",
//     "Pickup Fees",
//     "Promo Fees",
//     "Catering Fees",
//     "Total Fees",
//     "Misc Fees",
//     "Credit Card Processing Fees",
//   ]);
//   const analyticsSummaries =
//     await this.dataUtility.compileAnalyticsBreakoutSummaries(locations);
//   const analyticsSummaryGroups = _.groupBy(analyticsSummaries, "location");
//   Object.keys(analyticsSummaryGroups).forEach((key) => {
//     const summaries = analyticsSummaryGroups[key];
//     sheet.addRow([
//       key,
//       summaries[0]["locationCityState"],
//       summaries.reduce((sum, row) => {
//         return (sum += +row.dspGrossSales ? +row.dspGrossSales : 0);
//       }, 0),

//       summaries.reduce((sum, row) => {
//         return (sum += +row.errorCharges ? +row.errorCharges : 0);
//       }, 0),
//       summaries.reduce((sum, row) => {
//         return (sum += +row.adjustments ? +row.adjustments : 0);
//       }, 0),

//       summaries.reduce((sum, row) => {
//         return (sum += +row.netSales ? +row.netSales : 0);
//       }, 0),
//       summaries.reduce((sum, row) => {
//         return (sum += +row.campaignPromoFees ? +row.campaignPromoFees : 0);
//       }, 0),
//       summaries.reduce((sum, row) => {
//         return (sum += +row.taxableSales ? +row.taxableSales : 0);
//       }, 0),
//       summaries.reduce((sum, row) => {
//         return (sum += +row.canceledOrders ? +row.canceledOrders : 0);
//       }, 0),
//       summaries.reduce((sum, row) => {
//         return (sum += +row.dspTax ? +row.dspTax : 0);
//       }, 0),

//       summaries.reduce((sum, row) => {
//         return (sum += +row.taxRemittedToRestaurant
//           ? +row.taxRemittedToRestaurant
//           : 0);
//       }, 0),
//       summaries.reduce((sum, row) => {
//         return (sum += +row.backupWithholdingTax
//           ? +row.backupWithholdingTax
//           : 0);
//       }, 0),
//       summaries.reduce((sum, row) => {
//         return (sum += +row.deliveryFees ? +row.deliveryFees : 0);
//       }, 0),
//       summaries.reduce((sum, row) => {
//         return (sum += +row.pickupFees ? +row.pickupFees : 0);
//       }, 0),
//       summaries.reduce((sum, row) => {
//         return (sum += +row.promoFees ? +row.promoFees : 0);
//       }, 0),
//       summaries.reduce((sum, row) => {
//         return (sum += +row.cateringFees ? +row.cateringFees : 0);
//       }, 0),

//       summaries.reduce((sum, row) => {
//         return (sum += +row.totalFees ? +row.totalFees : 0);
//       }, 0),
//       summaries.reduce((sum, row) => {
//         return (sum += +row.miscFees ? +row.miscFees : 0);
//       }, 0),
//       summaries.reduce((sum, row) => {
//         return (sum += +row.creditCardProcessingFees
//           ? +row.creditCardProcessingFees
//           : 0);
//       }, 0),
//     ]);
//   });

//   this.autoFitColumns(sheet);
//   [
//     "C",
//     "D",
//     "E",
//     "F",
//     "G",
//     "H",
//     "I",
//     "J",
//     "K",
//     "L",
//     "M",
//     "O",
//     "P",
//     "Q",
//   ].forEach((column) => {
//     this.formatAllCellsInColumn(sheet, column, "accounting");
//   });
// }
