import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { combineAll, first, map, takeUntil } from "rxjs/operators";

import { ActivatedRoute, Router } from "@angular/router";
import { FirebaseAuthService } from "../services/firebase-auth.service";
import { Client, User, UserRoles } from "@deliver-sense-librarian/data-schema";
import { AngularFirestore } from "@angular/fire/firestore";
import { firstValueFrom, from, lastValueFrom, of, Subject } from "rxjs";
import { FirestoreUtilities } from "../../utilities/firestore-utilities";
import { Store } from "@ngrx/store";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AngularFireStorage } from "@angular/fire/storage";
import _ from "lodash";

@Component({
  selector: "app-login",
  templateUrl: "./client-selector.component.html",
  styleUrls: ["./client-selector.component.scss"],
})
export class ClientSelectorComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  public clients: Client[] = [];
  public noClients = false;
  public user: User;
  public clientRoles: any[] = [];
  private redirectInstructions: {
    redirectTo: string;
    id?: string;
    shared?: boolean;
    downloadOnLoad?: boolean;
  };
  private destroy$ = new Subject();
  authorizedClients: { id: string; resource: string; role: number }[] = [];

  constructor(
    private auth: FirebaseAuthService,
    private store: Store<any>,
    private storage: AngularFireStorage,
    private router: Router,
    private afs: AngularFirestore,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.store
      .select((state) => state.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((uiState$) => {
        if (uiState$.authUser) {
          if (uiState$.client) {
            this.router.navigateByUrl("/app");
          } else {
            this.user = uiState$.authUser;
            this.getUserClients();
          }
          this.checkForRedirectInstructions();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngAfterViewInit() {}
  private async checkForRedirectInstructions() {
    const redirectTo = localStorage.getItem("redirectTo");
    if (redirectTo) {
      this.redirectInstructions = {
        redirectTo: redirectTo,
        id: localStorage.getItem("reportId"),
        shared: localStorage.getItem("shared") == "true",
        downloadOnLoad: localStorage.getItem("downloadOnLoad") == "true",
      };
    }
  }
  private async getUserClients() {
    this.store
      .select((state) => state.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (uiState$) => {
        this.authorizedClients = uiState$.authorizedClients;
        this.clients = (
          await Promise.all(
            this.authorizedClients.map((clientRole) => {
              return lastValueFrom(
                this.afs
                  .doc(`clients/${clientRole.id}`)
                  .snapshotChanges()
                  .pipe(first())
              );
            })
          )
        )
          .map((request) => FirestoreUtilities.objectToType(request))
          .sort((a, b) => (a.name.charAt(0) > b.name.charAt(0) ? 1 : -1));
      });
    // this.afs
    //   .collection(`users/${this.user.id}/clientRoles`)
    //   .snapshotChanges()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((clientRoles$) => {
    //     this.clientRoles = FirestoreUtilities.mapToType(clientRoles$);
    //     if (this.clientRoles && clientRoles$.length > 0) {
    //       const clientRequest = this.clientRoles.map((clientRole) => {
    //         return this.afs
    //           .doc(`clients/${clientRole.resource}`)
    //           .snapshotChanges();
    //       });
    //       from(clientRequest)
    //         .pipe(combineAll(), takeUntil(this.destroy$))
    //         .subscribe((clients$) => {
    //           this.clients = FirestoreUtilities.mergeToType(clients$).filter(
    //             (client) => !!client && client.active
    //           );
    //           if (this.clients.length < 1) {
    //             this.noClients = true;
    //           } else {
    //             const clientLogos = this.clients.map((client) => {
    //               if (client.logoPath) {
    //                 return this.storage
    //                   .ref(client.logoPath)
    //                   .getDownloadURL()
    //                   .pipe(
    //                     map((url) => {
    //                       client.logo = url;
    //                     })
    //                   );
    //               } else {
    //                 return of();
    //               }
    //             });
    //             from(clientLogos)
    //               .pipe(takeUntil(this.destroy$), combineAll())
    //               .subscribe((clientLogoMappings$) => {});
    //           }
    //         });
    //     } else {
    //       this.noClients = true;
    //     }
    //   });
  }

  public async setClient(client: Client) {
    if (client.sfaRequired) {
      await this.router.navigateByUrl(`/sfa/${client.id}`);
    } else {
      await this.auth.setSelectedClient(client.id, this.user.id);
      if (this.redirectInstructions) {
        const fullPath = `app/${this.redirectInstructions.redirectTo}${
          this.redirectInstructions.id ? `/${this.redirectInstructions.id}` : ""
        }`;
        await this.router.navigate([fullPath], {
          queryParams: {
            redirectTo: this.redirectInstructions.redirectTo,
            id: this.redirectInstructions.id,
            shared: !!this.redirectInstructions.shared,
            downloadOnLoad: !!this.redirectInstructions.downloadOnLoad,
          },
        });
      } else {
        await this.router.navigate(["/app"]);
      }
    }
  }

  async signout() {
    await this.auth.signOut();
    this.router.navigate([""]);
  }

  getUserClientRole(client: Client) {
    if (this.user) {
      const clientRole = this.clientRoles.find(
        (_cr) => _cr.resource === client.id
      );
      if (clientRole) {
        switch (clientRole.role) {
          case UserRoles.admin:
            return "admin";
          case UserRoles.contributor:
            return "contributor";
          case UserRoles.viewer:
            return "viewer";
        }
      }
    }
  }
}
