import { SelectionModel } from "@angular/cdk/collections";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  Input,
  ViewChild,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatOption } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ClientInvalidDataLog } from "@deliver-sense-librarian/data-schema";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { Papa } from "ngx-papaparse";
import * as _ from "lodash";
import { AfterViewInit } from "@angular/core";
@Component({
  selector: "app-sample-conversion-table",
  templateUrl: "./sample-conversions-table.component.html",
  styleUrls: ["./sample-conversions-table.component.scss"],
})
export class SampleConversionsTableComponent implements OnInit, AfterViewInit {
  @Input() sampleConversions: any[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private afs: AngularFirestore,
    private papa: Papa,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private loadingService: LoadingDialogService
  ) {}
  ngOnInit(): void {
    if (this.sampleConversions) {
      const headers = this.sampleConversions.map((conversion) =>
        Object.keys(conversion)
      );
      this.displayedColumns = _.uniq(_.flatten(headers));
      this.displayedColumns = this.displayedColumns.sort((a, b) => {
        return a === "location" || a === "date"
          ? -1
          : a.charAt(0) < b.charAt(0)
          ? -1
          : 1;
      });
      this.setTableData();
    }
  }
  ngAfterViewInit(): void {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.cdr.detectChanges();
  }
  setTableData() {
    this.tableData = new MatTableDataSource(this.sampleConversions);
  }

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
}
