import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";

import {
  ThirdPartyReport,
  ThirdPartyReportFragmentDailyDrillDownReport,
} from "@deliver-sense-librarian/data-schema";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment";
import { Papa } from "ngx-papaparse";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import _ from "lodash";
import { first } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { TransactionsDialogComponent } from "app/dialogs/transactions-dialog/transactions-dialog.component";
import firebase from "firebase/app";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { downloadDataAsFile } from "app/shared/ds-constant";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import { TransactionAITypes } from "@deliver-sense-librarian/data-schema";
import {
  ReconciliationDrillDownReportTypes,
  ReconciliationReportDatUtility,
} from "../../utilities/reconciliation-report-data.utility";
import { TransactionsMatchDialogComponent } from "app/dialogs/transaction-match-dialog/transactions-match-dialog.component";

export class DrillDownSelection {
  thirdPartyId: string;
  locationId: string;
}
export class TADrillDownSelection extends DrillDownSelection {
  type: TransactionAITypes;
}
@Component({
  selector: "app-daily-drill-down",
  templateUrl: "./daily-drill-down.component.html",
  styleUrls: ["./daily-drill-down.component.scss"],
})
export class DailyDrillDownComponent implements OnInit, OnDestroy {
  @Input() thirdPartyReport: ThirdPartyReport;
  @Input() preSelection: DrillDownSelection;
  @Input() exportUtility: ReconciliationReportExportUtility;
  @Input() dataUtility: ReconciliationReportDatUtility;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public thirdPartyReportFragmentDayDrillDownReports: ThirdPartyReportFragmentDailyDrillDownReport[] =
    [];
  public parametersForm: FormGroup;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    "date",
    "tpdTransactions",
    "errorChargeTransactions",
    "adjustmentTransactions",
    "tpdSales",
    "errorCharges",
    "adjustments",
    "excludedTpdSales",
    "excludedTpdTransactions",
    "tpdTax",
    "excludedTpdTax",
  ];
  public selectedDrillDownThirdParty = new FormControl("", Validators.required);
  public selectedDrillDownLocation = new FormControl("", Validators.required);
  public selectedDate = new FormControl("", Validators.required);
  destroy$ = new Subject();
  excludedTransactionIds: any[];
  loadingData = false;
  constructor(
    private afs: AngularFirestore,
    private cdr: ChangeDetectorRef,
    private papa: Papa,
    private store: Store<any>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private loadingService: LoadingDialogService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.setupAvailableColumns();
    this.initializeData();
  }

  ngOnDestroy(): void {}

  ngAfterViewChecked(): void {}
  private async initializeData() {
    this.loadingData = true;
    this.thirdPartyReportFragmentDayDrillDownReports =
      await this.dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.dailyDrillDowns
      );
    this.loadingData = false;
    this.setupParametersForm();
    this.checkForPreSelection();
  }
  checkForPreSelection() {
    if (this.preSelection) {
      this.parametersForm
        .get("selectedLocation")
        .patchValue(this.preSelection.locationId);
      this.parametersForm
        .get("selectedThirdParty")
        .patchValue(this.preSelection.thirdPartyId);
      this.runAnalysis();
    }
  }
  setupAvailableColumns() {
    if (this.thirdPartyReport.includePosInRec) {
      this.displayedColumns.splice(1, 0, "posTransactions");
      this.displayedColumns.splice(5, 0, "posSales");
      this.displayedColumns.splice(7, 0, "salesVariance");
      this.displayedColumns.splice(8, 0, "pricingIssues");
      this.displayedColumns.splice(11, 0, "missingInPos");
      this.displayedColumns.splice(12, 0, "missingIn3pd");
      this.displayedColumns.splice(13, 0, "transactionNotPaidOut");
      this.displayedColumns.splice(16, 0, "posTax");
      this.displayedColumns.splice(18, 0, "taxVariance");
    }
  }
  setupParametersForm() {
    this.parametersForm = this.fb.group({
      selectedLocation: new FormControl("", Validators.required),
      selectedThirdParty: new FormControl("", Validators.required),
    });
  }
  runAnalysis() {
    if (this.parametersForm.valid) {
      this.fetchParameterizedReportData();
    } else {
      this.snackBar.open("Please select all required parameters", "Dismiss", {
        duration: 5000,
      });
    }
  }
  private getDailyDrillDownExportRow(data) {
    if (this.thirdPartyReport.includePosInRec) {
      return {
        Date: data.date ? moment(data.date.toDate()).format("MM/DD/YYYY") : "",
        Location: data.location,
        "3PD": this.dataUtility.getThirdPartyName(data.thirdParty),
        "Pos Transactions": data.posTransactionCount,
        "3PD Transactions": data.thirdPartyTransactionCount,
        "Excluded 3PD Transactions":
          data.thirdPartyStatusFilteredTransactions?.length,
        "Error Charge Transactions": data.errorChargeTransactions?.length,
        "Adjustment Transactions": data.adjustmentTransactions?.length,
        "POS Sales": data.posSales,
        "3PD Sales": data.thirdPartySales,
        "Sales Variance": data.daySalesVariance,
        "3PD Excluded Sales": data.excludedThirdPartySales,
        "Error Charges": data.errorCharges,
        Adjustments: data.adjustments,
        "POS Tax": data.posTax,
        "3PD Tax": data.thirdPartyTax,
        "Tax Variance": data.dayTaxVariance,
        "3PD Excluded Tax": data.excludedThirdPartyTax,
      };
    } else {
      return {
        Date: data.date ? moment(data.date.toDate()).format("MM/DD/YYYY") : "",
        Location: data.location,
        "3PD": this.getSelectedThirdPartyName(),
        "3PD Transactions": data.thirdPartyTransactionCount,
        "Excluded 3PD Transactions":
          data.thirdPartyStatusFilteredTransactions?.length,
        "Error Charge Transactions": data.errorChargeTransactions?.length,
        "Adjustment Transactions": data.adjustmentTransaction?.length,
        "3PD Sales": data.thirdPartySales,
        "3PD Excluded Sales": data.excludedThirdPartySales,
        "Error Charges": data.errorCharges,
        Adjustments: data.adjustments,
        "3PD Tax": data.thirdPartyTax,
        "3PD Excluded Tax": data.excludedThirdPartyTax,
      };
    }
  }
  exportReport() {
    const data = Object.assign(
      this.tableData.data.map((data) => {
        return this.getDailyDrillDownExportRow(data);
      })
    );
    const results = this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const fileName = `${this.dataUtility.existingReport.name}_Daily-Drill-Down`;
    downloadDataAsFile(results, fileName, "csv");
  }
  exportAllReport() {
    const dailyReports = _.flatten(
      this.thirdPartyReportFragmentDayDrillDownReports.map(
        (dayReport) => dayReport.report
      )
    ).sort((curr, next) => {
      return moment(curr.date.toDate()).isSameOrBefore(next.date.toDate())
        ? -1
        : 1;
    });
    this.mapDailyReportErrorCharges(dailyReports);

    const data = Object.assign(
      dailyReports.map((data) => {
        return this.getDailyDrillDownExportRow(data);
      })
    );
    const results = this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const fileName = `${this.dataUtility.existingReport.name}_FULL_Daily-Drill-Down`;
    downloadDataAsFile(results, fileName, "csv");
  }
  private async fetchParameterizedReportData() {
    const locationId = this.parametersForm.get("selectedLocation").value;
    const thirdPartyId = this.parametersForm.get("selectedThirdParty").value;
    this.loadingService.isLoading(true, `Loading report...`);
    const filteredDayReports =
      this.thirdPartyReportFragmentDayDrillDownReports.filter(
        (dayDrillDownReport) => {
          return (
            dayDrillDownReport.location === locationId &&
            dayDrillDownReport.thirdParty === thirdPartyId
          );
        }
      );
    const dailyReports = _.flatten(
      filteredDayReports.map((dayReport) => dayReport.report)
    ).sort((curr, next) => {
      return moment(curr.date.toDate()).isSameOrBefore(next.date.toDate())
        ? -1
        : 1;
    });
    // pull up error charges to daily reports
    this.mapDailyReportErrorCharges(dailyReports);
    this.mapDailyReportAnalysisTypes(dailyReports);
    this.excludedTransactionIds = _.flatten(
      dailyReports.map(
        (dailyReport) => dailyReport.thirdPartyStatusFilteredTransactions
      )
    );
    this.tableData = new MatTableDataSource(dailyReports);
    this.tableData.sort = this.sort;
    this.cdr.detectChanges();
    this.loadingService.isLoading(false);
  }

  private mapDailyReportErrorCharges(dailyReports) {
    dailyReports.forEach((dailyReport) => {
      const errorChargeTransactions = dailyReport.analysis
        .map((analysis) => {
          return analysis.type === "Error Charge" ? analysis : null;
        })
        .filter((analysis) => !!analysis);
      dailyReport["errorChargeTransactions"] = errorChargeTransactions;
      dailyReport["errorCharges"] = +errorChargeTransactions
        .reduce((sum, analysis) => {
          return (sum += analysis.sale ? analysis.sale : 0);
        }, 0)
        .toFixed(2);
      // pull up adjustments to daily reports
      const adjustmentTransactions = dailyReport.analysis
        .map((analysis) => {
          return analysis.type === "Adjustment" ? analysis : null;
        })
        .filter((analysis) => !!analysis);
      dailyReport["adjustmentTransactions"] = adjustmentTransactions;
      dailyReport["adjustments"] = +adjustmentTransactions
        .reduce((sum, analysis) => {
          return (sum += analysis.sale ? analysis.sale : 0);
        }, 0)
        .toFixed(2);
    });
  }
  private mapDailyReportAnalysisTypes(dailyReports) {
    dailyReports.forEach((dailyReport) => {
      dailyReport.transactionNotPaidOut = 0;
      dailyReport.pricingIssues = 0;
      dailyReport.missingInPos = 0;
      dailyReport.missingIn3pd = 0;
      if (dailyReport.analysis.length > 0) {
        dailyReport.analysis.forEach((analysis) => {
          if (analysis.type === TransactionAITypes.transactionNotPaidOut) {
            dailyReport.transactionNotPaidOut += +analysis.sale;
          } else if (analysis.type === TransactionAITypes.pricingIssues) {
            dailyReport.pricingIssues += +analysis.sale;
          } else if (
            analysis.type === TransactionAITypes.transactionMissing &&
            analysis.transactionSource === "POS"
          ) {
            dailyReport.missingInPos += +analysis.sale;
          } else if (
            analysis.type === TransactionAITypes.transactionMissing &&
            analysis.transactionSource === "3PD"
          ) {
            dailyReport.missingIn3pd += +analysis.sale;
          }
        });
      }
    });
  }
  // @TOD refactor down
  getTotalOfColumn(column) {
    return +this.tableData.data
      .reduce((prev, row) => {
        return (prev += +row[column] ? +row[column] : 0);
      }, 0)
      .toFixed(2);
  }
  getTotalCount(column) {
    return this.tableData.data.reduce((prev, row) => {
      return (prev += +row[column].length ? +row[column].length : 0);
    }, 0);
  }
  getTotalPosTransactionsCount() {
    return this.tableData.data.reduce((prev, row) => {
      return (prev += +row.posTransactionCount ? +row.posTransactionCount : 0);
    }, 0);
  }
  getTotal3pdTransactionsCount() {
    return this.tableData.data.reduce((prev, row) => {
      return (prev += +row.thirdPartyTransactionCount
        ? +row.thirdPartyTransactionCount
        : 0);
    }, 0);
  }
  getTotal3pdFilteredTransactionsCount() {
    return this.tableData.data.reduce((prev, row) => {
      return (prev += +row.thirdPartyStatusFilteredTransactions.length
        ? +row.thirdPartyStatusFilteredTransactions.length
        : 0);
    }, 0);
  }
  getTotalErrorChargeCount() {
    return this.tableData.data.reduce((prev, row) => {
      return (prev += +row.errorChargeTransactions.length
        ? +row.errorChargeTransactions.length
        : 0);
    }, 0);
  }
  getTotalAdjustmentCount() {
    return this.tableData.data.reduce((prev, row) => {
      return (prev += +row.adjustmentTransactions.length
        ? +row.adjustmentTransactions.length
        : 0);
    }, 0);
  }
  public async openLocationDayTransactionsViewer(date, isPos = false) {
    try {
      this.loadingService.isLoading(true, "Finding transactions...");
      const collectionName = isPos
        ? "posTransactions"
        : "thirdPartyTransactions";
      const startDate = moment(date).startOf("day").toDate();
      const endDate = moment(date).endOf("day").toDate();
      const locationId = this.parametersForm.get("selectedLocation").value;
      const thirdPartyId = this.parametersForm.get("selectedThirdParty").value;
      const transactionsQuery$ = await this.afs
        .collection(collectionName, (ref) =>
          ref
            .where("client", "==", this.dataUtility.client.id)
            .where("location", "==", locationId)
            .where(isPos ? "account" : "thirdParty", "==", thirdPartyId)
            .where("date", ">=", startDate)
            .where("date", "<=", endDate)
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise();

      const transactions = FirestoreUtilities.mapToType(
        transactionsQuery$
      ).filter(
        (transaction) =>
          !this.excludedTransactionIds.find((id) => id === transaction.id) &&
          !this.dataUtility.isTransactionErrorCharge(
            transaction,
            this.dataUtility.thirdParties.find((dsp) => dsp.id === thirdPartyId)
          ) &&
          !this.dataUtility.isTransactionAdjustment(
            transaction,
            this.dataUtility.thirdParties.find((dsp) => dsp.id === thirdPartyId)
          )
      );
      this.loadingService.isLoading(false);
      this.dialog.open(TransactionsDialogComponent, {
        panelClass: "invisible-panel-dialog",
        data: {
          transactions,
          thirdPartyReport: this.thirdPartyReport,
        },
      });
    } catch (e) {
      console.error(e);
      this.loadingService.isLoading(false);
      this.snackBar.open(
        `Could not find transactions. Please refresh and try again`,
        "Dismiss",
        { duration: 5000 }
      );
    }
  }
  public async showAllDayTransactions(date) {
    try {
      this.loadingService.isLoading(true, "Finding transactions...");
      const startDate = moment(date).startOf("day").toDate();
      const endDate = moment(date).endOf("day").toDate();
      const locationId = this.parametersForm.get("selectedLocation").value;
      const thirdPartyId = this.parametersForm.get("selectedThirdParty").value;
      const [thirdPartyTransactions, posTransactions] = await Promise.all([
        FirestoreUtilities.mapToType(
          await this.afs
            .collection("thirdPartyTransactions", (ref) =>
              ref
                .where("client", "==", this.dataUtility.client.id)
                .where("location", "==", locationId)
                .where("thirdParty", "==", thirdPartyId)
                .where("date", ">=", startDate)
                .where("date", "<=", endDate)
            )
            .snapshotChanges()
            .pipe(first())
            .toPromise()
        ).filter(
          (transaction) =>
            !this.excludedTransactionIds.find((id) => id === transaction.id)
        ),
        FirestoreUtilities.mapToType(
          await this.afs
            .collection("posTransactions", (ref) =>
              ref
                .where("client", "==", this.dataUtility.client.id)
                .where("location", "==", locationId)
                .where("account", "==", thirdPartyId)
                .where("date", ">=", startDate)
                .where("date", "<=", endDate)
            )
            .snapshotChanges()
            .pipe(first())
            .toPromise()
        ).filter(
          (transaction) =>
            !this.excludedTransactionIds.find((id) => id === transaction.id)
        ),
      ]);
      this.loadingService.isLoading(false);
      this.dialog.open(TransactionsDialogComponent, {
        panelClass: "invisible-panel-dialog",
        data: {
          transactions: [...posTransactions, ...thirdPartyTransactions].sort(
            (a, b) => (a.sale > b.sale ? -1 : 1)
          ),
          thirdPartyReport: this.thirdPartyReport,
        },
      });
    } catch (e) {
      this.loadingService.isLoading(false);
      this.snackBar.open(
        `Could not find transactions. Please refresh and try again`,
        "Dismiss",
        { duration: 5000 }
      );
    }
  }
  public getIndicatorTransactionIds(analysis) {
    return analysis
      .map((indicator) => indicator.transaction)
      .filter((id) => !!id);
  }
  public async openLocationTransactionsViewer(transactionIds) {
    if (transactionIds.length > 0) {
      this.loadingService.isLoading(true, "Finding transactions...");
      const transactionIdBatches = _.chunk(transactionIds, 10);
      try {
        const transactions = _.flatten(
          await Promise.all(
            transactionIdBatches.map((transactionIds) => {
              return this.afs
                .collection("thirdPartyTransactions", (ref) =>
                  ref.where(
                    firebase.firestore.FieldPath.documentId(),
                    "in",
                    transactionIds
                  )
                )
                .snapshotChanges()
                .pipe(first())
                .toPromise();
            })
          )
        );
        this.loadingService.isLoading(false);
        this.dialog.open(TransactionsDialogComponent, {
          panelClass: "invisible-panel-dialog",
          data: {
            transactions: FirestoreUtilities.mapToType(transactions),
            thirdPartyReport: this.thirdPartyReport,
          },
        });
      } catch (e) {
        this.loadingService.isLoading(false);
        this.snackBar.open(
          `Could not find transactions. Please refresh and try again`,
          "Dismiss",
          { duration: 5000 }
        );
      }
    }
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  getSelectedLocationName() {
    const selectedLocation = this.parametersForm.get("selectedLocation").value;
    if (selectedLocation) {
      const location = this.dataUtility.locations.find(
        (_l) => _l.locationId === selectedLocation
      );
      return `${location.locationId} - ${location.name}`;
    }
    return "";
  }

  getSelectedThirdPartyName() {
    const selectedThirdParty =
      this.parametersForm.get("selectedThirdParty").value;
    if (selectedThirdParty) {
      const tpd = this.dataUtility.thirdParties.find(
        (_tp) => _tp.id === selectedThirdParty
      );
      return `${tpd.name}`;
    }
    return "";
  }
  clearResults() {
    this.tableData = null;
  }
  showDayTransactionMatch(day: Date) {
    const thirdParty = this.dataUtility.thirdParties.find(
      (_tp) => _tp.id === this.parametersForm.get("selectedThirdParty").value
    );
    const location = this.dataUtility.locations.find(
      (_l) =>
        _l.locationId === this.parametersForm.get("selectedLocation").value
    );
    this.dialog.open(TransactionsMatchDialogComponent, {
      panelClass: "invisible-panel-dialog",
      data: {
        thirdParty,
        location,
        dataUtility: this.dataUtility,
        startDate: day,
        endDate: day,
      },
    });
  }
}
