import { Component, OnInit, Input } from "@angular/core";
import * as Highcharts from "highcharts";
import more from "highcharts/highcharts-more";
import HC_exporting from "highcharts/modules/exporting";
HC_exporting(Highcharts);
more(Highcharts);
import * as _ from "lodash";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ReconciliationReportDatUtility } from "../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import { ThirdPartyRating } from "@deliver-sense-librarian/data-schema";
import moment from "moment";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { CustomerRatingsDialogComponent } from "app/dialogs/customer-ratings-dialog/customer-ratings-dialog.component";
import { Papa } from "ngx-papaparse";
import { downloadDataAsFile } from "app/shared/ds-constant";
declare const $: any;

@Component({
  selector: "app-customer-ratings",
  templateUrl: "./customer-ratings.component.html",
  styleUrls: ["./customer-ratings.component.scss"],
})
export class CustomerRatingsComponent implements OnInit {
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() exportUtility: ReconciliationReportExportUtility;
  selectedLocations = new FormControl([]);

  Highcharts: typeof Highcharts = Highcharts;
  showChart = false;
  customerRatings: ThirdPartyRating[];
  label = "";
  self = this;
  public chartOptions;
  loadingData: boolean;
  noRatingsData: boolean;
  seriesData: {
    locations: string[];
    columnData: Highcharts.SeriesOptionsType[];
  };

  constructor(
    private snackBar: MatSnackBar,
    private papa: Papa,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.initializeData();
    this.listenForLocationFilter();
  }
  private async initializeData() {
    this.loadingData = true;
    this.customerRatings = await this.dataUtility.fetchThirdPartyRatings();
    this.setupChartData();
    this.loadingData = false;
  }
  private listenForLocationFilter() {
    this.selectedLocations.valueChanges.subscribe((locations) => {
      this.filterChartLocations();
    });
  }
  reset() {
    this.showChart = false;
    this.selectedLocations.reset();
  }
  export() {
    const data = Object.assign(
      this.seriesData.columnData.map((point: any) => {
        return point.data.map((dataPoint) => {
          return {
            Location: dataPoint.location,
            "Third Party": point.name,
            "Number of Ratings": dataPoint.ratingsCount,
            "Avg. Rating": dataPoint.y,
          };
        });
      })
    );
    const results = this.papa.unparse(_.flatten(data), {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const fileName = `${this.dataUtility.existingReport.name}_Customer-Ratings`;
    downloadDataAsFile(results, fileName, "csv");
  }
  setupChartData() {
    this.showChart = false;
    this.noRatingsData = false;
    this.seriesData = this.getSeriesData();
    const self = this;
    if (this.seriesData.columnData.length > 0) {
      setTimeout(() => {
        this.chartOptions = {
          chart: {
            type: "column",

            events: {
              load: function () {
                $(".highcharts-scrollbar").show();
              },
            },
          },

          legend: {
            layout: "vertical",
            align: "right",
            verticalAlign: "middle",
          },

          title: {
            text: `${this.dataUtility.existingReport.name} - Customer Ratings`,
          },

          xAxis: {
            title: {
              text: "Location",
            },
            categories: this.seriesData.locations,
          },

          yAxis: {
            title: {
              text: "Average Rating",
            },
            labels: {
              format: "{value}",
            },
          },
          tooltip: {
            useHTML: true,
            headerFormat: "<table>",
            pointFormat: `<tr><th colspan="2"><h3>{point.category}</h3></th></tr>
            <tr><th>Average Ratings:</th><td>{point.y}</td></tr>
            <tr><th>Number of Ratings:</th><td>{point.ratingsCount}</td></tr>`,
            footerFormat: "</table>",
            followPointer: true,
          },
          credits: {
            enabled: true,
            text: "DeliverSense.com",
            href: "https://deliversense.com",
          },
          dataSorting: {
            enabled: true,
            sortKey: "value",
          },
          plotOptions: {
            series: {
              keys: ["y", "value"],
              cursor: "pointer",
              point: {
                events: {
                  click: function () {
                    self.openRatingsTable(this);
                  },
                },
              },
            },
          },
          series: this.seriesData.columnData,
        };
        this.showChart = true;
      }, 300);
    } else {
      this.noRatingsData = true;
    }
  }

  openRatingsTable(point) {
    const filteredRatings = this.customerRatings.filter(
      (rating) =>
        rating.location === point.location &&
        rating.thirdParty === point.thirdParty
    );
    this.dialog.open(CustomerRatingsDialogComponent, {
      panelClass: "invisible-panel-dialog",
      data: {
        ratings: filteredRatings,
      },
    });
  }
  filterChartLocations() {
    this.showChart = false;
    setTimeout(() => {
      const seriesData = this.getSeriesData(
        this.selectedLocations.value?.length > 0
          ? this.selectedLocations.value
          : null
      );

      this.chartOptions.xAxis = {
        title: {
          text: "Location",
        },
        categories: seriesData.locations,
      };
      (this.chartOptions.series = seriesData.columnData),
        (this.showChart = true);
    });
  }

  getSeriesData(locationIds?: string[]) {
    const seriesData = this.dataUtility.getCustomerRatingsSeriesData(
      locationIds,
      this.customerRatings
    );
    // = {};
    // const locationSelectionRatings =
    //   locationIds?.length > 0
    //     ? this.customerRatings.filter((rating) => {
    //         return !!locationIds.find((l) => l === rating.location);
    //       })
    //     : this.customerRatings;
    // const thirdPartyGroups = _.groupBy(locationSelectionRatings, "thirdParty");
    // const allDataByLocation = _.groupBy(locationSelectionRatings, "location");
    // Object.keys(allDataByLocation).forEach(
    //   (locationId) => (seriesData[locationId] = {})
    // );
    // _.forEach(allDataByLocation, (value, locationId) => {
    //   const locationRatingsByThirdParty = _.groupBy(
    //     allDataByLocation[locationId],
    //     "thirdParty"
    //   );
    //   _.forEach(locationRatingsByThirdParty, (value, dspId) => {
    //     const locationThirdPartyRatings = locationRatingsByThirdParty[dspId];
    //     seriesData[locationId][dspId] = +_.mean(
    //       locationThirdPartyRatings.map((rating) => rating.rating)
    //     ).toFixed(2);
    //   });
    // });
    return {
      locations: Object.keys(seriesData.allDataByLocation).map((location) => {
        return `${location} - ${
          this.dataUtility.locations.find((l) => l.locationId === location).name
        }`;
      }),
      columnData: <Highcharts.SeriesOptionsType[]>Object.keys(
        seriesData.thirdPartyGroups
      ).map((thirdPartyId) => {
        return {
          color: this.dataUtility
            .getThirdPartyName(thirdPartyId)
            .includes("Uber")
            ? "#05c569"
            : this.dataUtility
                .getThirdPartyName(thirdPartyId)
                .includes("DoorDash")
            ? "#ff0000"
            : "#333333",
          name: this.dataUtility.getThirdPartyName(thirdPartyId),
          data: Object.keys(seriesData.allDataByLocation).map((locationId) => {
            return {
              y: seriesData.data[locationId][thirdPartyId]
                ? seriesData.data[locationId][thirdPartyId]
                : 0,
              location: locationId,
              thirdParty: thirdPartyId,
              ratingsCount: this.dataUtility.getRatingsCount(
                locationId,
                thirdPartyId
              ),
            };
          }),
        };
      }),
    };
  }
}
