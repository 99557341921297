import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import {
  ThirdParty,
  TransactionI,
  ClientThirdParty,
  ThirdPartyPayout,
} from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TransactionHistoryDialogComponent } from "../transaction-history-dialog/transaction-history-dialog.component";

@Component({
  selector: "app-payouts-dialog",
  templateUrl: "./payouts-dialog.component.html",
  styleUrls: ["./payouts-dialog.component.scss"],
})
export class PayoutsDialogComponent implements OnInit {
  public thirdParties: ThirdParty[];
  public destroy$ = new Subject();
  public tableData: MatTableDataSource<TransactionI>;
  public clientThirdParties: ClientThirdParty[];

  constructor(
    private dialog: MatDialog,
    private store: Store<any>,
    public dialogRef: MatDialogRef<PayoutsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { thirdPartyPayouts: ThirdPartyPayout[] }
  ) {}

  ngOnInit() {
    this.store
      .select((store) => store.uiState.clientThirdParties)
      .pipe(takeUntil(this.destroy$))
      .subscribe((clientThirdParties$: ClientThirdParty[]) => {
        if (clientThirdParties$ && clientThirdParties$.length > 0) {
          this.thirdParties = <ThirdParty[]>(
            clientThirdParties$.map(
              (clientThirdParty) => clientThirdParty.thirdParty
            )
          );
        }
      });
  }

  openTransactionHistory(transaction) {
    this.dialog.open(TransactionHistoryDialogComponent, {
      panelClass: "invisible-panel-dialog",
      data: {
        transaction,
      },
    });
  }
}
