import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  TransactionAITypes,
  ThirdPartyReportAnomalyValidation,
  ThirdPartyReportFragmentAnomaly,
  ThirdPartyReportFragmentVarianceAnalysisReport,
  ThirdPartyReportNote,
} from "@deliver-sense-librarian/data-schema";
import * as moment from "moment";
import { ReconciliationReportDatUtility } from "../../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../../utilities/reconciliation-report-filtering.utility";
import { FormControl } from "@angular/forms";
import { AfterViewInit } from "@angular/core";
import _ from "lodash";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PopoverDirective } from "ngx-bootstrap/popover";
import { JoyrideService } from "ngx-joyride";
import { ReconciliationNoteDialogComponent } from "app/dialogs/reconciliation-note-dialog/reconciliation-note-dialog.component";

@Component({
  selector: "app-variance-analysis-table",
  templateUrl: "./variance-analysis-table.component.html",
  styleUrls: ["./variance-analysis-table.component.scss"],
})
export class VarianceAnalysisTableComponent implements OnInit, AfterViewInit {
  @Input() data: any[] = [];
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @Output() jumpToDailyDrillDown = new EventEmitter();
  @Output() jumpToErrorCharges = new EventEmitter();
  @Output() jumpToTransactionAnalysis = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [];
  public saleVarianceTypes = [];
  public anomalyToView;
  public anomalyNoteToView;
  public filter3pdsControl = new FormControl([]);
  public filterLocationsControl = new FormControl([]);

  validationToEdit: ThirdPartyReportAnomalyValidation;
  activePopover: PopoverDirective;
  anomalyValidationControl = new FormControl("");
  vaReportInEdit: any;

  constructor(
    private dialog: MatDialog,
    private afs: AngularFirestore,
    private snackBar: MatSnackBar,
    public readonly joyrideService: JoyrideService
  ) {
    this.compileDisplayedColumns();
  }
  ngOnInit(): void {
    this.tableData = new MatTableDataSource(this.data);
  }
  private compileDisplayedColumns() {
    const columns = [
      "location",
      "thirdParty",
      "salesVariance",
      "salesVarianceAmount",
    ];
    for (let type in TransactionAITypes) {
      if (
        TransactionAITypes[type] !== "Potential Fraud/ POS Misuse" &&
        TransactionAITypes[type] !== "Non-POS related payment"
      ) {
        columns.push(TransactionAITypes[type]);
        this.saleVarianceTypes.push(TransactionAITypes[type]);
      }
    }
    columns.push(
      ...[
        "taxVariance",
        "taxVarianceAmount",
        "taxVarianceReasons",
        "anomalies",
        "anomalyNotes",
      ]
    );
    this.displayedColumns = columns;
  }
  ngAfterViewInit(): void {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.filteringUtility.set3pdAndLocationFilterPredicate(
      this.tableData,
      this.filter3pdsControl,
      this.filterLocationsControl
    );
  }

  getReasonsHtml(reasonsArr) {
    if (reasonsArr) {
      return reasonsArr.reduce((prev, reason) => {
        return `${prev} <li>${reason}</li>`;
      }, "");
    }
  }
  getValidationText(validation: ThirdPartyReportAnomalyValidation) {
    return validation
      ? `${validation.validated ? "VALIDATED," : ""} ${
          validation.message ? validation.message : ""
        }`
      : "";
  }
  getReasonsCsv(reasonsArr) {
    return _.join(reasonsArr, "\n");
  }
  getAnomaliesCsv(anomalies: any) {
    const anomaliesText = anomalies.map((anomaly) => {
      return anomaly.date
        ? `${moment(anomaly.date.toDate()).format("MM/DD/YYYY")} - ${
            anomaly.type
          }: 3PD ${anomaly.thirdPartyTransactionsCount}, POS ${
            anomaly.posTransactionCount
          }`
        : "";
    });
    return _.join(anomaliesText, "\n");
  }
  getAnomaliesText(anomalies: ThirdPartyReportFragmentAnomaly[]) {
    if (anomalies?.length > 0) {
      return anomalies.reduce((prev, anomaly) => {
        return `${prev} <li>${
          anomaly.date ? moment(anomaly.date.toDate()).format("MM/DD/YYYY") : ""
        } - ${anomaly.type}: 3PD ${anomaly.thirdPartyTransactionsCount}, POS ${
          anomaly.posTransactionCount
        }</li>`;
      }, "");
    }
  }

  async updateAnomalyVerification(
    event: MatCheckboxChange,
    vaReport: ThirdPartyReportFragmentVarianceAnalysisReport
  ) {
    try {
      if (vaReport["validation"]) {
        await this.afs
          .doc(
            `thirdPartyReportAnomalyValidations/${vaReport["validation"].id}`
          )
          .update({
            validated: event.checked ? true : false,
          });
        vaReport["validation"].validated = event.checked;
      } else {
        const validation = this.createNewAnomalyValidation(vaReport);
        validation.validated = event.checked;
        const query = await this.afs
          .collection("thirdPartyReportAnomalyValidations")
          .add(validation.toJSONObject());
        validation.id = query.id;
        vaReport["validation"] = validation;
      }
      this.snackBar.open("Anomaly verification updated.", "Dismiss", {
        duration: 5000,
      });
    } catch (e) {
      this.snackBar.open(
        "Oops... something went wrong. Please refresh and try again",
        "Dismiss",
        { duration: 5000 }
      );
    }
  }
  private createNewAnomalyValidation(
    anomaly: ThirdPartyReportFragmentVarianceAnalysisReport
  ) {
    const thirdPartyReportAnomalyValidation =
      new ThirdPartyReportAnomalyValidation();
    thirdPartyReportAnomalyValidation.location = anomaly.location;
    thirdPartyReportAnomalyValidation.thirdParty = anomaly.thirdParty;
    thirdPartyReportAnomalyValidation.validatedBy = this.dataUtility.user.id;
    thirdPartyReportAnomalyValidation.thirdPartyReport =
      this.dataUtility.existingReport.id;
    thirdPartyReportAnomalyValidation.client = this.dataUtility.client.id;
    thirdPartyReportAnomalyValidation.validated = false;
    return thirdPartyReportAnomalyValidation;
  }
  /**Setting Anomaly Reasons */
  setAnomalyValidationToEdit(vaReport, activePopover) {
    this.vaReportInEdit = vaReport;
    this.validationToEdit = vaReport.validation
      ? vaReport.validation
      : this.createNewAnomalyValidation(vaReport);
    this.activePopover = activePopover;
    this.anomalyValidationControl.patchValue(this.validationToEdit.message);
    this.anomalyValidationControl.updateValueAndValidity();
  }

  async saveAnomalyReason() {
    if (this.anomalyValidationControl.valid) {
      if (this.validationToEdit.id) {
        await this.updateValidationReason();
      } else {
        await this.createValidationReason();
      }
      this.activePopover.hide();
      this.activePopover = null;
      this.vaReportInEdit.validation = this.validationToEdit;
      this.validationToEdit = null;
      this.vaReportInEdit = null;
      this.anomalyNoteToView = null;
      this.anomalyValidationControl.reset();
      this.anomalyValidationControl.updateValueAndValidity();
    }
  }
  async updateValidationReason() {
    const message = this.anomalyValidationControl.value
      ? this.anomalyValidationControl.value
      : null;
    await this.afs
      .doc(`thirdPartyReportAnomalyValidations/${this.validationToEdit.id}`)
      .update({ message: message, dateUpdated: moment().toDate() });
    this.validationToEdit.message = message;
  }
  async createValidationReason() {
    this.validationToEdit.message = this.anomalyValidationControl.value;
    await this.afs
      .collection("thirdPartyReportAnomalyValidations")
      .add(this.validationToEdit.toJSONObject());
  }
  public getTotalOfType(type) {
    return this.tableData.filteredData.reduce((sum, current) => {
      return (sum += +current[type] ? +current[type] : 0);
    }, 0);
  }
  public getVarianceTypeTooltip(salesVariaceType: TransactionAITypes) {
    switch (salesVariaceType) {
      case TransactionAITypes.transactionMissing:
        return `These are orders that are not canceled but are missing in either the POS or  the delivery partner data.`;
      case TransactionAITypes.errorCharge:
        return `Also called customer refunds, these charges reduce the amount paid out on orders due to customer complaints.`;
      case TransactionAITypes.pricingIssues:
        return `If the <i>Variance Intelligence Algorithm</i> cannont determine the full <b>Sales Variance Amount</b> in terms of canceled order in the POS, missing transactions, adjustments, or customer refunds, the remaining difference will be allocated to Pricing Issues, and is typically related to item pricing differences between the POS and the delivery partner marketplace.`;
      case TransactionAITypes.adjustment:
        return `Order adjustments per the delivery partner that either reduce or increase the  total order payout i.e., credits for successful customer refund disputes.`;
      case TransactionAITypes.transactionNotPaidOut:
        return `These are canceled orders that will not be paid out by the delivery partner  but have been matched to an existing transaction in the POS.`;
    }
  }
  // public upsertNote(reportNote?: ThirdPartyReportNote) {
  //   let note;
  //   if (reportNote) {
  //     note = this.thirdPartyReportNotes.find(
  //       (reportNoteOriginal) => reportNoteOriginal.id === reportNote.id
  //     );
  //   } else {
  //     note = new ThirdPartyReportNote();
  //   }
  //   this.dialog.open(ReconciliationNoteDialogComponent, {
  //     panelClass: "invisible-panel-dialog",
  //     data: {
  //       thirdPartyReportNote: note,
  //       locations: this.dataUtility.locations,
  //       thirdParties: this.dataUtility.thirdParties,
  //       client: this.dataUtility.client,
  //       creator: this.dataUtility.user,
  //       thirdPartyReportId: this.dataUtility.existingReport.id,
  //     },
  //   });
  // }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  /**
   * Joyride tour
   */
  startTour() {
    this.joyrideService.startTour({
      steps: [
        "overview",
        "salesVariance",
        "salesVarianceAmount",
        TransactionAITypes.transactionNotPaidOut,
        TransactionAITypes.transactionMissing,
        TransactionAITypes.adjustment,
        TransactionAITypes.errorCharge,
        TransactionAITypes.pricingIssues,
        "taxVariance",
        "taxVarianceAmount",
        "taxVarianceReasons",
        "anomalies",
        "anomalyNotes",
      ],
    });
  }
}
