import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EntitiesComponent } from "./entities/entities/entities.component";
import { EntityComponent } from "./entities/entity/entity.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModule } from "../../material.module";
import { TeamMembersComponent } from "./team/team-members/team-members.component";
import { LocationsComponent } from "./locations/locations/locations.component";
import { LocationComponent } from "./locations/location/location.component";
import { UploadLocationsComponent } from "./locations/upload-locations/upload-locations.component";
import { FileUploadModule } from "../file-upload/file-upload.module";
import { DsSharedModule } from "../../shared/ds-shared.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { PopoverModule } from "ngx-bootstrap/popover";
import { DirectivesModule } from "../../directives/directives.module";
import { TeamMemberComponent } from "./team/team-member/team-member.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { EntityThirdPartiesComponent } from "./entities/entity-third-parties/entity-third-parties.component";
import { EntityTeamMembersComponent } from "./entities/entity-team-members/entity-team-members.component";
import { LocationsTableComponent } from "./locations/locations-table/locations-table.component";

@NgModule({
  declarations: [
    EntitiesComponent,
    EntityComponent,
    TeamMembersComponent,
    TeamMemberComponent,
    LocationsComponent,
    LocationComponent,
    UploadLocationsComponent,
    EntityThirdPartiesComponent,
    EntityTeamMembersComponent,
    LocationsTableComponent,
  ],
  exports: [
    EntityComponent,
    LocationComponent,
    LocationsTableComponent,
    UploadLocationsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FileUploadModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    MaterialModule,
    DsSharedModule,
    FontAwesomeModule,
    DirectivesModule,
    PopoverModule.forRoot(),
    ClipboardModule,
    PerfectScrollbarModule,
  ],
})
export class OrganizationModule {}
