import { Directive, HostListener, Input } from '@angular/core';
import { HelpService } from "../services/help.service";

@Directive({
    selector: '[appHelp]'
})
export class HelpDirective {
    @Input(
        'tip'
    ) tip: string;

    constructor(private helpService: HelpService) {
    }

    @HostListener('click', ['$event']) onClick(event: MouseEvent) {
        this.helpService.setTip(this.tip)
    }

}
