import { Component, Input, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  Client,
  User,
  UserSettings,
} from "@deliver-sense-librarian/data-schema";
import { LoadingDialogService } from "app/services/loading-dialog.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { FirestoreUtilities } from "../../../../utilities/firestore-utilities";

@Component({
  selector: "app-user-settings",
  templateUrl: "./user-settings.component.html",
  styleUrls: ["./user-settings.component.scss"],
})
export class UserSettingsComponent implements OnInit {
  @Input() user: User;
  @Input() client: Client;
  userSettings: UserSettings;
  userSettingsFormGroup: FormGroup;
  private destroy$ = new Subject();
  userSettingsDoc: import("@angular/fire/firestore").AngularFirestoreDocument<unknown>;
  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private loadingService: LoadingDialogService,
    private afs: AngularFirestore
  ) {}

  ngOnInit(): void {
    this.userSettingsDoc = this.afs.doc(`userSettings/${this.user.id}`);
    this.initializeData();
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  private async initializeData() {
    this.userSettingsDoc
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userSettingQueryResults$) => {
        this.userSettings = FirestoreUtilities.objectToType(
          userSettingQueryResults$
        );
        this.setupUserSettingsForm();
      });
  }
  setupUserSettingsForm() {
    this.userSettingsFormGroup = this.fb.group({
      emailOnReportShare: new FormControl(this.userSettings.emailOnReportShare),
    });
  }
  async save() {
    const formValues: { emailOnReportShare: boolean } =
      this.userSettingsFormGroup.value;
    await this.userSettingsDoc.update({
      emailOnReportShare: formValues.emailOnReportShare,
    });
    this.snackBar.open(
      "Notification settings updated successfully!",
      "Dismiss",
      { duration: 5000 }
    );
  }
}
