import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "lodash";
import { Papa } from "ngx-papaparse";
import {
  reconciliationTableFields,
  ThirdPartyReconciliationLocationData,
} from "@deliver-sense-librarian/data-schema";
import { FormControl } from "@angular/forms";
import { TpdReportFieldSelectionDialogComponent } from "app/dialogs/tpd-report-field-selection-dialog/tpd-report-field-selection-dialog.component";
import { ReconciliationReportDatUtility } from "../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
@Component({
  selector: "app-reconciliation",
  templateUrl: "./reconciliation.component.html",
  styleUrls: ["./reconciliation.component.scss"],
})
export class ReconciliationComponent implements OnInit, AfterViewInit {
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() exportUtility: ReconciliationReportExportUtility;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public onlyShowErrors = false;
  public dataFlattened = false;
  public possibleFields = reconciliationTableFields;
  public filterErrorsControl = new FormControl([]);
  public filter3pdsControl = new FormControl([]);
  public filterLocationControl = new FormControl([]);
  public fixedFields = ["Location Id", "3PD"];
  @Input() selectedFields = [
    ...this.fixedFields,
    "State/Province",
    "Sales Variance",
    "Tax Variance",
    "Suggested Tax Adjustment",
    "Total Fees",
    "Remittance Variance",
  ];
  constructor(
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private papa: Papa
  ) {}

  ngOnInit() {
    this.initializeTable();
  }
  ngAfterViewInit() {}
  private async initializeTable() {
    this.setInitialFieldSelection();
    this.dataFlattened = true;
    this.tableData = new MatTableDataSource(this.dataUtility.reportData);
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;

    this.tableData.filterPredicate = (
      data: ThirdPartyReconciliationLocationData,
      filtersJson: string
    ) => {
      const matchFilter = [];
      const filterGroups = _.groupBy(JSON.parse(filtersJson), "id");
      let matchThirdPartyName = true;
      let matchLocationId = true;
      if (filterGroups.thirdPartyName?.length > 0) {
        matchThirdPartyName = !!filterGroups.thirdPartyName.find(
          (dspNameFilter) => dspNameFilter.value === data.thirdPartyName
        );
      }
      if (filterGroups.locationId?.length > 0) {
        matchLocationId = !!filterGroups.locationId.find(
          (locationIdFilter) => locationIdFilter.value === data.locationId
        );
      }
      const matchAll = matchThirdPartyName && matchLocationId;
      return matchAll;
    };
    this.cdr.detectChanges();
    this.setupFilterListeners();
  }

  private setInitialFieldSelection() {
    if (
      this.dataUtility.client3pdConfiguration &&
      this.dataUtility.client3pdConfiguration.defaultFields &&
      this.dataUtility.client3pdConfiguration.defaultFields.length > 0
    ) {
      this.selectedFields =
        this.dataUtility.client3pdConfiguration.defaultFields;
    }
  }

  //@TODO remove?
  getVariance(a: number, b: number) {
    return +(a - b).toFixed(2);
  }

  public applyFilter(filterValue: string) {
    const tableFilters = [
      // { id: 'locationId', value: filterValue },
      // { id: 'locationName', value: filterValue },
      { id: "stateProvince", value: filterValue },
      { id: "posGrossSales", value: filterValue },
      { id: "posSalesAdjustments", value: filterValue },
      { id: "posNetSales", value: filterValue },
      { id: "thirdPartyGrossSales", value: filterValue },
      { id: "thirdPartySalesAdjustments", value: filterValue },
      { id: "thirdPartyNetSales", value: filterValue },
      { id: "salesVariance", value: filterValue },
      { id: "posTips", value: filterValue },
      { id: "thirdPartyTips", value: filterValue },
      { id: "posOtherCharges", value: filterValue },
      { id: "posOtherRevenue", value: filterValue },
      { id: "thirdPartyOtherCharges", value: filterValue },
      { id: "thirdPartyOtherRevenue", value: filterValue },
      { id: "posGrossTax", value: filterValue },
      { id: "posTaxAdjustments", value: filterValue },
      { id: "posNetTax", value: filterValue },
      { id: "thirdPartyGrossTax", value: filterValue },
      { id: "thirdPartyTaxAdjustments", value: filterValue },
      { id: "thirdPartyNetTax", value: filterValue },
      { id: "taxVariance", value: filterValue },
      { id: "locationTaxRate", value: filterValue },
      { id: "thirdPartyEffectiveTaxRate", value: filterValue },
      { id: "taxRateVariance", value: filterValue },
      { id: "marketFacilitatorTax", value: filterValue },
      { id: "effectiveMfRate", value: filterValue },
      { id: "clientTaxResponsibility", value: filterValue },
      { id: "effectiveClientTaxResponsibilityRate", value: filterValue },
      { id: "suggestedTaxAdjustment", value: filterValue },
      { id: "totalFees", value: filterValue },
      { id: "deliveryFees", value: filterValue },
      { id: "effectiveDeliveryFeeRate", value: filterValue },
      { id: "promoFees", value: filterValue },
      { id: "expectedRemittance", value: filterValue },
      { id: "actualRemittance", value: filterValue },
      { id: "remittanceVariance", value: filterValue },
      { id: "campaignPromoFees", value: filterValue },
    ];
    this.tableData.filter = JSON.stringify(tableFilters);
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  private setupFilterListeners() {
    this.filter3pdsControl.valueChanges.subscribe((selected3pds) => {
      const selectedErrorTypes = this.filterErrorsControl.value
        ? this.filterErrorsControl.value
        : [];
      const selectedLocations = this.filterLocationControl.value
        ? this.filterLocationControl.value
        : [];
      this.multiFilter(selected3pds, selectedLocations);
    });

    this.filterLocationControl.valueChanges.subscribe((selectedLocations) => {
      const selected3pds = this.filter3pdsControl.value
        ? this.filter3pdsControl.value
        : [];
      const selectedErrorTypes = this.filterErrorsControl.value
        ? this.filterErrorsControl.value
        : [];
      this.multiFilter(selected3pds, selectedLocations);
    });
  }
  private multiFilter(selected3pds, selectedLocations) {
    let filters;
    let tpdFilters = [];
    let locationFilters = [];
    if (selectedLocations.length > 0) {
      locationFilters = selectedLocations.map((locationId) => {
        return { id: "locationId", value: locationId };
      });
    }

    if (selected3pds.length > 0) {
      tpdFilters = selected3pds.map((dspId) => {
        const dsp = this.dataUtility.thirdParties.find(
          (dsp) => dsp.id === dspId
        );
        return { id: "thirdPartyName", value: dsp.name };
      });
    }
    const filtersArray = [...tpdFilters, ...locationFilters];
    if (filtersArray.length > 0) {
      filters = JSON.stringify(filtersArray);
    } else {
      filters = "";
    }
    this.tableData.filter = filters;
    this.tableData.paginator.firstPage();
  }

  getRoundedRate(rate) {
    return (+rate ? +rate * 100 : 0).toFixed(2);
  }
  selectFields() {
    const fieldSelectDialogRef = this.dialog.open(
      TpdReportFieldSelectionDialogComponent,
      {
        panelClass: "invisible-panel-dialog",
        data: {
          selectedFields: this.selectedFields,
        },
      }
    );
    fieldSelectDialogRef.afterClosed().subscribe((updatedFieldSelection$) => {
      if (updatedFieldSelection$) {
        const updateFieldSelection = [
          ...this.fixedFields,
          ...updatedFieldSelection$
            .map((field) => field.item)
            .filter((fieldName) => this.possibleFields.indexOf(fieldName) > -1),
        ];
        this.selectedFields = _.sortBy(updateFieldSelection, (item) => {
          return this.possibleFields.indexOf(item);
        });
      }
    });
  }

  getFunctionDefinition(
    row: ThirdPartyReconciliationLocationData,
    functionName: string
  ) {
    const location = this.dataUtility.locations.find(
      (l) => l.locationId === row.locationId
    );
    if (location && this.dataUtility.reportData) {
      switch (functionName) {
        case "salesVariance":
          return `
        <h4>Sales Variance Calculation</h4>
        <div class="function-def">
          POS Net Sales - 3PD Net Sales = Sales Variance
        </div>
        <div class="function-def">
          (${row.posNetSales} - ${row.thirdPartyNetSales} = ${row.salesVariance})
        </div>
        `;
          break;
        case "taxVariance":
          return `
        <h4>Tax Variance Calculation</h4>
        <div class="function-def">
          POS Net Tax - 3PD Net Tax = Tax_Variance
        </div>
        <div class="function-def">
          (${row.posNetTax} - ${row.thirdPartyNetTax} = ${row.taxVariance})
        </div>
        `;
          break;
        case "locationTaxRate":
          return `
        <h4>Location Tax Rate Calculation</h4>
        <div class="function-def">
          State_Tax_Rate + County_Tax_Rate + City_Tax_Rate + Special_Tax_Rate = Location_Tax_Rate
        </div>
        <div class="function-def">
          (${location.stateTaxRate}% + ${location.countyTaxRate}% + ${
            location.cityTaxRate
          }% + ${location.specialTaxRate}% = ${row.locationTaxRate * 100}%)
        </div>
        `;
        case "thirdPartyEffectiveTaxRate":
          return `
        <h4>Third Party Effective Tax Rate Calculation</h4>
        <div class="function-def">
          3PD Net Tax ÷ 3PD Net Sales = 3PD Effective Tax Rate
        </div>
        <div class="function-def">
        (${row.thirdPartyNetTax} ÷ ${row.thirdPartyNetSales} = ${row.thirdPartyEffectiveTaxRate})
        </div>
        `;
        case "effectiveMfTaxRate":
          return `
        <h4>Effective Market Facilitator Tax Rate Calculation</h4>
        <div class="function-def">
          3PD Market Facilitator Tax ÷ 3PD Net Sales = Effective MF Tax Rate
        </div>
        <div class="function-def">
        (${row.marketFacilitatorTax} ÷ ${row.thirdPartyNetSales} = ${row.effectiveMfRate})
        </div>
        `;
        case "clientTaxResponsibility":
          return `
        <h4>Client Tax Responsibility Calculation</h4>
        <div class="function-def">
          3PD Net Tax + Market Facilitator Tax + Tax On Fees= Client Tax Responsibility
        </div>
        <div class="function-def">
          (${row.thirdPartyNetTax} + ${row.marketFacilitatorTax} + ${row.taxOnFees}= ${row.clientTaxResponsibility})
        </div>
        `;
        case "clientTaxResponsibilityRate":
          return `
        <h4>Client Tax Responsibility Rate Calculation</h4>
        <div class="function-def">
          Client Tax Responsibility ÷ 3PD Net Sales = Client Tax Responsibility Rate
        </div>
        <div class="function-def">
        (${row.clientTaxResponsibility} ÷ ${row.thirdPartyNetSales} = ${row.effectiveClientTaxResponsibilityRate})
        </div>
        `;
        case "suggestedTaxAdjustment":
          return `
        <h4>Suggested Tax Adjustment</h4>
        <div class="function-def">
          POS Net Tax - Client Tax Responsibility = Suggested Tax Adjustment
        </div>
        <div class="function-def">
          (${row.posNetTax} - ${row.clientTaxResponsibility} = ${row.suggestedTaxAdjustment})
        </div>
        `;
        case "expectedRemittance":
          const locationReport = this.dataUtility.reportData.find(
            (lrd) => lrd.reportId === row.reportId
          );
          return `
        <h4>Expected Remittance Calculation</h4>
        <div class="function-def">
          POS Net Sales + POS Net Tax + POS Tips + POS Other Revenue - (Total Fees + 3PD MF Tax + Backup Withholding Tax + 3PD Other Charges) = Expected Remittance
        </div>
        <div class="function-def">
          ${row.posNetSales} + ${row.posNetTax} + ${row.posTips} + ${
            row.posOtherRevenue
          } - (${Math.abs(row.totalFees)} + ${Math.abs(
            row.marketFacilitatorTax
          )} + ${row.backupWithholdingTax} + ${Math.abs(
            row.thirdPartyOtherCharges
          )}) = ${row.expectedRemittance}
        </div>
        `;
        case "deliverFeeRate":
          return `
        <h4>Effective Delivery Fee Rate Calculation</h4>
        <div class="function-def">
          Delivery Fees Total ÷ 3PD Net Sales = Effective Delivery Fee Rate
        </div>
        <div class="function-def">
        (${row.deliveryFees} ÷ ${row.thirdPartyNetSales} = ${row.effectiveDeliveryFeeRate})
        </div>
        `;
        case "remittanceVariance":
          return `
        <h4>Remittance Variance Calculation</h4>
        <div class="function-def">
        Expected Remittance - Actual Remittance = Remittance Variance
        </div>
        <div class="function-def">
          (${row.expectedRemittance} - ${row.actualRemittance} = ${row.remittanceVariance})
        </div>
        `;
      }
    }
  }
}
