import {
  AfterViewInit,
  Component,
  Inject,
  Injectable,
  OnInit,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";
import { FlatTreeControl } from "@angular/cdk/tree";
import { BehaviorSubject } from "rxjs";
import { SelectionModel } from "@angular/cdk/collections";
import { MatCheckboxChange } from "@angular/material/checkbox";
import {
  feeFields,
  locationFields,
  markerFields,
  remittanceFields,
  salesFields,
  taxAdjustmentFields,
  taxFields,
  tipsAndOtherFields,
} from "@deliver-sense-librarian/data-schema";

export class FieldItemNode {
  children: FieldItemNode[];
  item: string;
}

export class FieldItemFlatNode {
  item: string;
  level: number;
  expandable: boolean;
}
function getPreDefinedFieldGroupValues(group: string[]) {
  const obj = {};
  group.forEach((field) => {
    if (field !== "Location Id" && field !== "3PD") {
      obj[field] = null;
    }
  });
  return obj;
}
const TREE_DATA = {
  Location: getPreDefinedFieldGroupValues(locationFields),
  Sales: getPreDefinedFieldGroupValues(salesFields),
  "Tips and Other Charges": getPreDefinedFieldGroupValues(tipsAndOtherFields),
  Tax: getPreDefinedFieldGroupValues(taxFields),
  "Tax Adjustments": getPreDefinedFieldGroupValues(taxAdjustmentFields),
  Fees: getPreDefinedFieldGroupValues(feeFields),
  Remittance: getPreDefinedFieldGroupValues(remittanceFields),
  Markers: getPreDefinedFieldGroupValues(markerFields),
};

@Injectable()
export class ReportFieldsDatabase {
  dataChange = new BehaviorSubject<FieldItemNode[]>([]);

  get data(): FieldItemNode[] {
    return this.dataChange.value;
  }

  constructor() {
    this.initialize();
  }

  initialize() {
    // Build the tree nodes from Json object. The result is a list of `FieldItemNode` with nested
    //     file node as children.
    const data = this.buildFileTree(TREE_DATA, 0);

    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `FieldItemNode`.
   */
  buildFileTree(obj: { [key: string]: any }, level: number): FieldItemNode[] {
    return Object.keys(obj).reduce<FieldItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new FieldItemNode();
      node.item = key;

      if (value != null) {
        if (typeof value === "object") {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  /** Add an item to to-do list */
  insertItem(parent: FieldItemNode, name: string) {
    if (parent.children) {
      parent.children.push({ item: name } as FieldItemNode);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: FieldItemNode, name: string) {
    node.item = name;
    this.dataChange.next(this.data);
  }
}

@Component({
  selector: "app-tpd-report-field-selection-dialog",
  templateUrl: "./tpd-report-field-selection-dialog.component.html",
  styleUrls: ["./tpd-report-field-selection-dialog.component.scss"],
  providers: [ReportFieldsDatabase],
})
export class TpdReportFieldSelectionDialogComponent implements AfterViewInit {
  dynamicFields = false;

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<FieldItemFlatNode, FieldItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<FieldItemNode, FieldItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: FieldItemFlatNode | null = null;

  /** The new item's name */
  newItemName = "";

  treeControl: FlatTreeControl<FieldItemFlatNode>;

  treeFlattener: MatTreeFlattener<FieldItemNode, FieldItemFlatNode>;

  dataSource: MatTreeFlatDataSource<FieldItemNode, FieldItemFlatNode>;

  /** The selection for checklist */
  fieldlistSelection = new SelectionModel<FieldItemFlatNode>(
    true /* multiple */
  );
  preSelectedFields = [];

  ngAfterViewInit() {
    this.preSelectedFields.forEach((selectedField) => {
      const matchingNode = this.treeControl.dataNodes.find(
        (node) => node.item === selectedField
      );
      if (matchingNode) {
        this.todoItemSelectionToggle(matchingNode);
        if (this.getParentNode(matchingNode)) {
          this.treeControl.expand(this.getParentNode(matchingNode));
        } else {
          this.treeControl.expand(matchingNode);
        }
      }
    });
  }

  constructor(
    private database: ReportFieldsDatabase,
    public dialogRef: MatDialogRef<TpdReportFieldSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.preSelectedFields = dialogData.selectedFields;
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<FieldItemFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
    database.dataChange.subscribe((data) => {
      this.dataSource.data = data;
    });
  }

  getLevel = (node: FieldItemFlatNode) => node.level;

  isExpandable = (node: FieldItemFlatNode) => node.expandable;

  getChildren = (node: FieldItemNode): FieldItemNode[] => node.children;

  hasChild = (_: number, _nodeData: FieldItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: FieldItemFlatNode) =>
    _nodeData.item === "";

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: FieldItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item
        ? existingNode
        : new FieldItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: FieldItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) =>
      this.fieldlistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: FieldItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.fieldlistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: FieldItemFlatNode): void {
    this.fieldlistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.fieldlistSelection.isSelected(node)
      ? this.fieldlistSelection.select(...descendants)
      : this.fieldlistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every((child) => this.fieldlistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: FieldItemFlatNode): void {
    this.fieldlistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: FieldItemFlatNode): void {
    let parent: FieldItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: FieldItemFlatNode): void {
    const nodeSelected = this.fieldlistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) =>
      this.fieldlistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.fieldlistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.fieldlistSelection.select(node);
    }
  }

  /* Get the parent node of a node */

  getParentNode(node: FieldItemFlatNode): FieldItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Select the category so we can insert the new item. */
  addNewItem(node: FieldItemFlatNode) {
    const parentNode = this.flatNodeMap.get(node);
    this.database.insertItem(parentNode!, "");
    this.treeControl.expand(node);
  }

  /** Save the node to database */
  saveNode(node: FieldItemFlatNode, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node);
    this.database.updateItem(nestedNode!, itemValue);
  }

  apply(selected) {
    this.dialogRef.close(selected);
  }

  toggleSelectAll($event: MatCheckboxChange) {
    if ($event.checked) {
      this.treeControl.dataNodes.forEach((node) => {
        this.fieldlistSelection.select(node);
      });
    } else {
      this.treeControl.dataNodes.forEach((node) => {
        this.fieldlistSelection.deselect(node);
      });
    }
  }
}
