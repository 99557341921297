import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  reconciliationTableFields,
  ThirdPartyReconciliationLocationData,
  reconciliationTableColumnMappings,
} from "@deliver-sense-librarian/data-schema";
import { ReconciliationReportDatUtility } from "../../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../../utilities/reconciliation-report-filtering.utility";
import { FormControl } from "@angular/forms";
import { AfterViewInit } from "@angular/core";

@Component({
  selector: "app-reconciliation-table",
  templateUrl: "./reconciliation-table.component.html",
  styleUrls: ["./reconciliation-table.component.scss"],
})
export class ReconciliationTableComponent implements OnInit, AfterViewInit {
  @Input() data: any[] = [];
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = reconciliationTableFields;
  public filter3pdsControl = new FormControl([]);
  public filterLocationsControl = new FormControl([]);
  constructor(private dialog: MatDialog, private cdr: ChangeDetectorRef) {}
  ngOnInit(): void {
    this.tableData = new MatTableDataSource(this.data);
  }
  ngAfterViewInit(): void {
    // this.tableData.paginator = this.paginator;
    // // this.tableData.sortingDataAccessor = (row, columnName) => {
    // //   return row[reconciliationTableColumnMappings[columnName]];
    // // };
    // this.tableData.sort = this.sort;
    // this.filteringUtility.set3pdAndLocationFilterPredicate(
    //   this.tableData,
    //   this.filter3pdsControl,
    //   this.filterLocationsControl
    // );
  }

  getFunctionDefinition(
    row: ThirdPartyReconciliationLocationData,
    functionName: string
  ) {
    const location = this.dataUtility.locations.find(
      (l) => l.locationId === row.locationId
    );
    if (location && this.dataUtility.reportData) {
      switch (functionName) {
        case "salesVariance":
          return `
        <h4>Sales Variance Calculation</h4>
        <div class="function-def">
          POS Net Sales - 3PD Net Sales = Sales Variance
        </div>
        <div class="function-def">
          (${row.posNetSales} - ${row.thirdPartyNetSales} = ${row.salesVariance})
        </div>
        `;
          break;
        case "taxVariance":
          return `
        <h4>Tax Variance Calculation</h4>
        <div class="function-def">
          POS Net Tax - 3PD Net Tax = Tax_Variance
        </div>
        <div class="function-def">
          (${row.posNetTax} - ${row.thirdPartyNetTax} = ${row.taxVariance})
        </div>
        `;
          break;
        case "locationTaxRate":
          return `
        <h4>Location Tax Rate Calculation</h4>
        <div class="function-def">
          State_Tax_Rate + County_Tax_Rate + City_Tax_Rate + Special_Tax_Rate = Location_Tax_Rate
        </div>
        <div class="function-def">
          (${location.stateTaxRate}% + ${location.countyTaxRate}% + ${
            location.cityTaxRate
          }% + ${location.specialTaxRate}% = ${row.locationTaxRate * 100}%)
        </div>
        `;
        case "thirdPartyEffectiveTaxRate":
          return `
        <h4>Third Party Effective Tax Rate Calculation</h4>
        <div class="function-def">
          3PD Net Tax ÷ 3PD Net Sales = 3PD Effective Tax Rate
        </div>
        <div class="function-def">
        (${row.thirdPartyNetTax} ÷ ${row.thirdPartyNetSales} = ${row.thirdPartyEffectiveTaxRate})
        </div>
        `;
        case "effectiveMfTaxRate":
          return `
        <h4>Effective Market Facilitator Tax Rate Calculation</h4>
        <div class="function-def">
          3PD Market Facilitator Tax ÷ 3PD Net Sales = Effective MF Tax Rate
        </div>
        <div class="function-def">
        (${row.marketFacilitatorTax} ÷ ${row.thirdPartyNetSales} = ${row.effectiveMfRate})
        </div>
        `;
        case "clientTaxResponsibility":
          return `
        <h4>Client Tax Responsibility Calculation</h4>
        <div class="function-def">
          3PD Net Tax + Market Facilitator Tax + Tax On Fees= Client Tax Responsibility
        </div>
        <div class="function-def">
          (${row.thirdPartyNetTax} + ${row.marketFacilitatorTax} + ${row.taxOnFees}= ${row.clientTaxResponsibility})
        </div>
        `;
        case "clientTaxResponsibilityRate":
          return `
        <h4>Client Tax Responsibility Rate Calculation</h4>
        <div class="function-def">
          Client Tax Responsibility ÷ 3PD Net Sales = Client Tax Responsibility Rate
        </div>
        <div class="function-def">
        (${row.clientTaxResponsibility} ÷ ${row.thirdPartyNetSales} = ${row.effectiveClientTaxResponsibilityRate})
        </div>
        `;
        case "suggestedTaxAdjustment":
          return `
        <h4>Suggested Tax Adjustment</h4>
        <div class="function-def">
          POS Net Tax - Client Tax Responsibility = Suggested Tax Adjustment
        </div>
        <div class="function-def">
          (${row.posNetTax} - ${row.clientTaxResponsibility} = ${row.suggestedTaxAdjustment})
        </div>
        `;
        case "expectedRemittance":
          const locationReport = this.dataUtility.reportData.find(
            (lrd) => lrd.reportId === row.reportId
          );
          return `
        <h4>Expected Remittance Calculation</h4>
        <div class="function-def">
          POS Net Sales + POS Net Tax + POS Tips + POS Other Revenue - (Total Fees + 3PD MF Tax + Backup Withholding Tax + 3PD Other Charges) = Expected Remittance
        </div>
        <div class="function-def">
          ${row.posNetSales} + ${row.posNetTax} + ${row.posTips} + ${
            row.posOtherRevenue
          } - (${Math.abs(row.totalFees)} + ${Math.abs(
            row.marketFacilitatorTax
          )} + ${row.backupWithholdingTax} + ${Math.abs(
            row.thirdPartyOtherCharges
          )}) = ${row.expectedRemittance}
        </div>
        `;
        case "deliverFeeRate":
          return `
        <h4>Effective Delivery Fee Rate Calculation</h4>
        <div class="function-def">
          Delivery Fees Total ÷ 3PD Net Sales = Effective Delivery Fee Rate
        </div>
        <div class="function-def">
        (${row.deliveryFees} ÷ ${row.thirdPartyNetSales} = ${row.effectiveDeliveryFeeRate})
        </div>
        `;
        case "remittanceVariance":
          return `
        <h4>Remittance Variance Calculation</h4>
        <div class="function-def">
        Expected Remittance - Actual Remittance = Remittance Variance
        </div>
        <div class="function-def">
          (${row.expectedRemittance} - ${row.actualRemittance} = ${row.remittanceVariance})
        </div>
        `;
      }
    }
  }
}
