import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TrendAnalysisComponent } from "./trend-analysis.component";
import { TrendChartComponent } from "./trend-chart/trend-chart.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { UxModule } from "app/components/ux/ux.module";
import { MaterialModule } from "app/material.module";
import { DsSharedModule } from "app/shared/ds-shared.module";
import { PopoverModule } from "ngx-bootstrap/popover";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { TrendAnalysisReportsComponent } from "./trend-analysis-reports/trend-analysis-reports.component";
import { TrendAnalysisReportComponent } from "./trend-analysis-report/trend-analysis-report.component";
import { HighchartsChartModule } from "highcharts-angular";
import { TypeSearchModule } from "../../type-search/type-search.module";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    TrendAnalysisComponent,
    TrendChartComponent,
    TrendAnalysisReportsComponent,
    TrendAnalysisReportComponent,
  ],
  exports: [TrendChartComponent],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MaterialModule,
    CommonModule,
    PerfectScrollbarModule,
    DsSharedModule,
    RouterModule,
    TypeSearchModule,
    UxModule,
    PopoverModule.forRoot(),
    HighchartsChartModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
})
export class TrendAnalysisModule {}
