import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModule } from "../material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DsSharedModule } from "../shared/ds-shared.module";
import { HelpDirective } from "./help.directive";
import { ScrollListenerDirective } from "./scroll-listener.directive";
import { UserAuthorizedDirective } from "./user-authorized";
import { DndDirective } from "./dnd.directive";

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    DsSharedModule,
    FlexLayoutModule,
  ],
  declarations: [
    HelpDirective,
    ScrollListenerDirective,
    UserAuthorizedDirective,
    DndDirective,
  ],
  exports: [
    HelpDirective,
    ScrollListenerDirective,
    UserAuthorizedDirective,
    DndDirective,
  ],
})
export class DirectivesModule {}
