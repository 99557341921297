import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  downloadDataAsFile,
  getTimeFromMinutesFromMidnight,
} from "app/shared/ds-constant";
import { Papa } from "ngx-papaparse";
import * as moment from "moment";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  ThirdParty,
  TransactionI,
  ClientThirdParty,
  ThirdPartyTransaction,
} from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { Subject, lastValueFrom } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { LoadingDialogService } from "../../services/loading-dialog.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ThirdPartyDeliveryIds } from "@deliver-sense-librarian/data-schema";

@Component({
  selector: "app-transaction-history-dialog",
  templateUrl: "./transaction-history-dialog.component.html",
  styleUrls: ["./transaction-history-dialog.component.scss"],
})
export class TransactionHistoryDialogComponent
  implements OnInit, AfterViewInit
{
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public thirdParties: ThirdParty[];
  public keys: string[] = [];
  public destroy$ = new Subject();
  public tableData: MatTableDataSource<TransactionI>;
  public clientThirdParties: ClientThirdParty[];
  public displayedColumns: string[] = [
    "date",
    "time",
    "location",
    "source",
    "status",
    "transactionType",
    "thirdParty",
    "description",
    "sale",
    "saleCorrection",
    "tax",
    "taxCorrection",
    "tip",
    "otherCharges",
    "taxRemitted",
    "promoFee",
    "deliveryFee",
    "pickupFeeTotal",
    "cateringFee",
    "transactionId",
    "orderId",
    "workflowId",
  ];

  descriptionToView: any;
  activePopover: any;
  transactions: ThirdPartyTransaction[];
  loadingHistory = false;
  constructor(
    private papa: Papa,
    private afs: AngularFirestore,
    private store: Store<any>,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<TransactionHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { transaction: ThirdPartyTransaction }
  ) {
    this.tableData = new MatTableDataSource([data.transaction]);
  }

  ngOnInit() {
    this.store
      .select((store) => store.uiState.clientThirdParties)
      .pipe(takeUntil(this.destroy$))
      .subscribe((clientThirdParties$: ClientThirdParty[]) => {
        if (clientThirdParties$ && clientThirdParties$.length > 0) {
          this.thirdParties = <ThirdParty[]>(
            clientThirdParties$.map(
              (clientThirdParty) => clientThirdParty.thirdParty
            )
          );
          this.fetchTransactionHistory();
        }
      });
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  ngAfterViewInit() {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
  }
  private async fetchTransactionHistory() {
    const transaction = this.data.transaction;
    const transactionThirdParty = FirestoreUtilities.objectToType(
      await lastValueFrom(
        this.afs
          .doc(`thirdParties/${this.data.transaction.thirdParty}`)
          .snapshotChanges()
          .pipe(first())
      )
    );
    this.loadingHistory = true;
    this.afs
      .collection("thirdPartyTransactions", (ref) =>
        ref
          .where(
            "transactionId",
            "==",
            transactionThirdParty &&
              (transactionThirdParty.id ===
                ThirdPartyDeliveryIds["Uber Eats"] ||
                transactionThirdParty.duplicateOf ===
                  ThirdPartyDeliveryIds["Uber Eats"])
              ? `#${transaction.transactionId}`
              : transaction.transactionId
          )
          .where("client", "==", transaction.client)
          .where("location", "==", transaction.location)
          .where("thirdParty", "==", transaction.thirdParty)
      )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (transactionHistoryResults$) => {
          this.transactions = [
            ...FirestoreUtilities.mapToType(transactionHistoryResults$).sort(
              (a, b) =>
                moment(a.date.toDate()).isSameOrBefore(
                  moment(b.date.toDate()) ? 1 : -1
                )
            ),
          ];
          this.flattenTransactionData();
          this.tableData = new MatTableDataSource(this.transactions);
          this.loadingHistory = false;
        },
        (e) => {
          this.dialogRef.close();
          this.loadingHistory = false;
          this.snackBar.open("Error loading transaction history", "Dismiss", {
            duration: 5000,
          });
        }
      );
  }
  private flattenTransactionData() {
    this.transactions.forEach((transaction) => {
      transaction["dateText"] = moment(transaction.date.toDate()).format("l");
      transaction["source"] = transaction["account"] ? "POS" : "3PD";
      transaction["timeText"] = this.getTimeFormatted(transaction.time);
    });
  }
  public getTimeFormatted(time) {
    let processedTime = time;
    let dateTime = time && time["seconds"] ? time.toDate() : null;
    if (dateTime) {
      const hours = moment(dateTime).hour();
      const minutes = moment(dateTime).minutes();
      return `${hours}:${minutes}`;
    }
    return getTimeFromMinutesFromMidnight(processedTime);
  }
  exportReport() {
    const data = this.tableData.data.map((row) => {
      return {
        date: moment(row.date.toDate()).format("MM/DD/YYYY"),
        location: row.location,
        type: row["account"] ? "POS" : "3PD",
        thirdParty: this.getThirdPartyName(row),
        time: row.time ? this.getTimeFormatted(row.time) : "",
        status: row.status,
        transactionType: row.transactionType,
        sale: row.sale,
        saleCorrection: row.saleCorrection,
        tax: row.tax,
        taxCorrection: row.taxCorrection,
        tip: row.tip,
        otherCharges: row.otherCharges,
        taxRemitted: row["taxRemitted"],
        promoFee: row["promoFee"] ? row["promoFee"] : 0,
        deliveryFee: row["deliveryFeeTotal"] ? row["deliveryFeeTotal"] : 0,
        pickupFeeTotal: row["pickupFeeTotal"] ? row["pickupFeeTotal"] : 0,
        cateringFee: row["cateringFee"] ? row["cateringFee"] : 0,
        remittance: row["totalRemitted"] ? row["totalRemitted"] : 0,
        description: row["description"] ? row["description"] : "",
        transactionId: row["transactionId"] ? row["transactionId"] : "",
        orderId: row["orderId"] ? row["orderId"] : "",
      };
    });
    const results = this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const location = data[0] ? data[0].location : "";
    const fileName = `${
      data[0] && data[0]["type"]
    }-${location}-Transactions_History`;
    downloadDataAsFile(results, fileName, "csv");
  }
  public getThirdPartyName(transaction) {
    const thirdPartyId = transaction.account
      ? transaction.account
      : transaction.thirdParty;
    const thirdParty = this.thirdParties.find(
      (thirdParty) => thirdParty.id === thirdPartyId
    );
    return thirdParty ? `${thirdParty.name}` : "";
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  setNotesToView(description, activePopover) {
    // this.descriptionToView = null;
    // this.activePopover = null;
    // setTimeout(() => {
    this.descriptionToView = description;
    this.activePopover = activePopover;
    // })
  }
}
