import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  ThirdParty,
  TransactionI,
  ClientThirdParty,
  disputeReasons,
  disputeStatuses,
  ThirdPartyTransaction,
  Location,
  Client,
  ThirdPartyReportCanceledOrderDisputeLog,
  ThirdPartyReport,
} from "@deliver-sense-librarian/data-schema";
import { Subject } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
import { first } from "rxjs/operators";
import { CanceledTransaction } from "app/components/ds-modules/3pd-reconciliation/reconciliation-sub-reports/canceled-orders-summary/canceled-orders-summary-report.component";
import {
  getCanceledOrderDisputeReason,
  getLocationAddress,
} from "../../shared/ds-constant";

@Component({
  selector: "app-canceled-order-dispute-manager-dialog",
  templateUrl: "./canceled-order-dispute-manager-dialog.component.html",
  styleUrls: ["./canceled-order-dispute-manager-dialog.component.scss"],
})
export class CanceledOrderDisputeManagerDialogComponent
  implements OnInit, AfterViewInit
{
  public thirdParties: ThirdParty[];
  public keys: string[] = [];
  public destroy$ = new Subject();
  descriptionToView: any;
  activePopover: any;
  disputeManagerForm: FormGroup;
  public disputeCountArr = [];
  public disputeReasons = disputeReasons;
  public isSaving = false;
  location: Location;
  constructor(
    private fb: FormBuilder,
    private afs: AngularFirestore,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CanceledOrderDisputeManagerDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      transaction: CanceledTransaction;
      client: Client;
      report: ThirdPartyReport;
    }
  ) {}

  ngOnInit() {
    this.initialize();
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  ngAfterViewInit() {}
  async initialize() {
    this.location = <Location>FirestoreUtilities.mapToType(
      await this.afs
        .collection("locations", (ref) =>
          ref
            .where("locationId", "==", this.data.transaction.location)
            .where("client", "==", this.data.transaction.client)
        )
        .snapshotChanges()
        .pipe(first())
        .toPromise()
    )[0];
  }
  copySuccessAlert() {
    this.snackBar.open(`Text copied to your clipboard`, "Dismiss", {
      duration: 4000,
    });
  }
  async saveDisputed() {
    this.isSaving = true;
    let disputeLog;
    if (!this.data.transaction.disputeLog) {
      disputeLog = new ThirdPartyReportCanceledOrderDisputeLog();
      disputeLog.transaction = this.data.transaction.id;
      disputeLog.client = this.data.client.id;
      disputeLog.thirdPartyReport = this.data.report.id;
      (disputeLog.canceledOrderDisputeReason = getCanceledOrderDisputeReason(
        this.data.transaction
      )),
        (disputeLog.canceledOrderDisputed = true);
      const persistedDoc = await this.afs
        .collection("thirdPartyReportCanceledOrderDisputeLogs")
        .add(disputeLog.toJSONObject());
      disputeLog.id = persistedDoc.id;
      this.dialogRef.close(disputeLog);
    } else {
      disputeLog = this.data.transaction.disputeLog;
      disputeLog.canceledOrderDisputed = true;
      (disputeLog.canceledOrderDisputeReason = getCanceledOrderDisputeReason(
        this.data.transaction
      )),
        await this.afs
          .doc(
            `thirdPartyReportCanceledOrderDisputeLogs/${this.data.transaction.disputeLog.id}`
          )
          .update({
            canceledOrderDisputed: true,
            canceledOrderDisputeReason: getCanceledOrderDisputeReason(
              this.data.transaction
            ),
            dateUpdated: moment().toDate(),
          });
    }
    this.dialogRef.close(disputeLog);
    this.isSaving = false;
  }
  async undispute() {
    this.isSaving = true;
  }
  getLocationAddress() {
    return getLocationAddress(this.location);
  }
  getDisputeReason() {
    return getCanceledOrderDisputeReason(this.data.transaction);
  }
  getDisputeContact() {
    //@TODO configuration in ECDS settings
    return `${this.data.client.name} - Off-Premise Support Team`;
  }
}
