import { FormControl } from "@angular/forms";
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  ThirdParty,
  Location,
  usStates,
  Entity,
  Client,
  User,
  OrganizationRole,
} from "@deliver-sense-librarian/data-schema";
import { ConfirmDialogComponent } from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import { Store } from "@ngrx/store";
import {
  downloadDataAsFile,
  tableExpandAnimation,
} from "../../../../shared/ds-constant";
import { LocationUploadDialogComponent } from "../../../../dialogs/location-upload-dialog/location-upload-dialog.component";
import * as moment from "moment";
import { LoadingDialogService } from "../../../../services/loading-dialog.service";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatCheckboxChange } from "@angular/material/checkbox";
import * as _ from "lodash";
import { Papa } from "ngx-papaparse";

@Component({
  selector: "app-locations-table",
  templateUrl: "./locations-table.component.html",
  styleUrls: ["./locations-table.component.scss"],
  animations: tableExpandAnimation,
})
export class LocationsTableComponent implements OnInit, OnDestroy {
  @Input() locations: Location[];
  @Input() entities: Entity[];
  @Input() client: Client;
  @Input() user: User;
  @Input() thirdParties: ThirdParty[];
  @Input() locationRoles: OrganizationRole[];
  @Input() showActions = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<Location>;
  public mutableTableData: MatTableDataSource<any> &
    MatTableDataSource<Location>;
  public displayedColumns: string[] = [
    "active",
    "id",
    "name",
    "entityName",
    "addressLine1",
    "addressLine2",
    "city",
    "state",
    "zip",
    "totalTaxRate",
    "edit",
    "expandTrigger",
  ];
  expandedElement: any | null;
  filterState = new FormControl();
  filterEntity = new FormControl();
  locationSearchText = new FormControl();
  states = usStates;
  noLocations: boolean;
  onlyShowActive = false;
  rateToEdit: number;
  rateToEditPropertyName: string;
  rateToEditLocation: Location;
  activePopover: any;
  constructor(
    private store: Store<any>,
    private loadingService: LoadingDialogService,
    private dialog: MatDialog,
    private papa: Papa,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.initializationTemplate();
  }

  ngOnDestroy(): void {}
  private async initializationTemplate() {
    this.listenForStateFilter();
    this.sortLocations();
    this.mergeDataForTable();
  }

  listenForStateFilter() {
    this.filterState.valueChanges.subscribe(() => {
      this.locationSearchText.reset();
      setTimeout(() => {
        this.mergeDataForTable();
      });
    });
  }
  sortLocations() {
    this.locations.forEach((location) => {
      location["locationIdNum"] = +location.locationId;
    });
    this.locations.sort((a, b) =>
      a["locationIdNum"] < b["locationIdNum"] ? -1 : 1
    );
    this.locations.forEach((location) => {
      const locationEntity = this.entities.find(
        (e) => e.id === location.entity
      );
      location["entityName"] = locationEntity ? locationEntity.name : "";
    });
  }
  applyAllFilter(filterValue: string) {
    const tableFilters = [
      {
        id: "locationId",
        value: filterValue,
      },
      {
        id: "name",
        value: filterValue,
      },
      {
        id: "entityName",
        value: filterValue,
      },
      {
        id: "addressLine1",
        value: filterValue,
      },
      {
        id: "addressLine2",
        value: filterValue,
      },
      {
        id: "addressCity",
        value: filterValue,
      },
      {
        id: "addressPostalCode",
        value: filterValue,
      },
    ];
    this.tableData.filter = JSON.stringify(tableFilters);
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  toggleOnlyActive($event: MatSlideToggleChange) {
    if ($event.checked) {
      const tableFilters = [
        {
          id: "active",
          value: true,
        },
      ];
      this.tableData.filter = JSON.stringify(tableFilters);
      if (this.tableData.paginator) {
        this.tableData.paginator.firstPage();
      }
    } else {
      this.mergeDataForTable();
    }
  }

  private mergeDataForTable() {
    let locations = Object.assign(this.locations);
    if (this.filterState.value) {
      locations = this.locations.filter(
        (_location) => _location.addressState === this.filterState.value
      );
    }
    if (this.filterEntity.value) {
      locations = this.locations.filter(
        (_location) => _location.entity === this.filterEntity.value
      );
    }
    this.tableData = new MatTableDataSource<Location>(locations);
    this.mutableTableData = Object.assign(
      new MatTableDataSource(),
      this.tableData
    );
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.tableData.filterPredicate = (data: Location, filtersJson: string) => {
      const matchFilter = [];
      const filters = JSON.parse(filtersJson);
      filters.forEach((filter) => {
        const val = data[filter.id] === null ? "" : data[filter.id];
        if (filter.value === true) {
          // handle filter for active
          matchFilter.push(val === filter.value);
        } else {
          matchFilter.push(
            val.toLowerCase().includes(filter.value.toLowerCase())
          );
        }
      });
      return matchFilter.indexOf(true) > -1;
    };
    this.cdr.detectChanges();
  }

  exportReport() {
    const data = this.tableData.data.map((locationInfo) => {
      return {
        Active: locationInfo.active ? "TRUE" : "FALSE",
        "Location Number": locationInfo.locationId,
        Name: locationInfo.name,
        Entity: this.entities.find(
          (entity) => entity.id === locationInfo.entity
        )?.name,
        "Address 1": locationInfo.addressLine1,
        "Address 2": locationInfo.addressLine2,
        City: locationInfo.addressCity,
        State: locationInfo.addressState,
        "Postal Code": locationInfo.addressPostalCode,
        "State Tax Rate": locationInfo.stateTaxRate,
        "City Tax Rate": locationInfo.cityTaxRate,
        "County Tax Rate": locationInfo.countyTaxRate,
        "Special Tax Rate": locationInfo.specialTaxRate,
      };
    });
    const results = this.papa.unparse(data, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
    });
    const fileName = `${this.client.name}-locations-${moment().format(
      "M.D.YY-HH:mm:ss"
    )}`;
    downloadDataAsFile(results, fileName, "csv");
  }

  getLocationThirdParties(location: Location) {
    return location["thirdParties"] ? location["thirdParties"] : [];
  }

  isThirdPartyAvailableForLocation(location: any, thirdParty: ThirdParty) {
    const isDpAvailalbe = this.getLocationThirdParties(location).find(
      (ldp) => ldp.thirdParty === thirdParty.id
    );
    return isDpAvailalbe ? isDpAvailalbe.active : false;
  }
  getThirdPartyFee(
    thirdParty: ThirdParty,
    feeType: "delivery" | "pickup" | "catering" | "promo" | "custom",
    location: Location
  ) {
    const ldp = this.getLocationThirdParties(location).find(
      (_ldp) => _ldp.thirdParty === thirdParty.id
    );
    if (ldp) {
      return ldp[`${feeType}Fee`] ? ldp[`${feeType}Fee`] : 0;
    }
  }

  getTotalTaxRate(row: any) {
    return (
      +row.stateTaxRate +
      +row.cityTaxRate +
      +row.countyTaxRate +
      +row.specialTaxRate
    ).toFixed(2);
  }

  openLocationUploadDialog() {
    this.dialog.open(LocationUploadDialogComponent, {
      panelClass: "invisible-panel-dialog",
    });
  }

  async updateLocationActive($event: MatCheckboxChange, location: Location) {
    const switchTo = $event.checked ? "Activate" : "Deactivate";
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `Confirm Location ${switchTo}`,
        message: `Are you sure you want to ${switchTo} location ${location.locationId} - ${location.name}`,
        action: `Yes, ${switchTo}`,
      },
    });
    confirmDialog.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs
          .doc(`locations/${location.id}`)
          .update({ active: $event.checked });
        const message = $event.checked
          ? "Location activated successfully"
          : "Location has been deactivated";
        this.snackBar.open(message, "Dismiss", { duration: 50000 });
      } else {
        $event.source.checked = location.active;
      }
    });
  }
  isUserLocationAdmin(locationId) {
    if (this.user.internalRole > 1) {
      return true;
    }
    const locationRole = this.locationRoles.find(
      (locationRole) => locationRole.resource === locationId
    );
    return locationRole && locationRole.role >= 3;
  }
  getEntityName(location: Location) {
    return this.entities.find((entity) => entity.id === location.entity)?.name;
  }
}
