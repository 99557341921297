import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { FirebaseAuthService } from "../services/firebase-auth.service";
import { distinctUntilChanged, first, take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { LoadingDialogService } from "../../services/loading-dialog.service";
import { Store } from "@ngrx/store";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  error = "";
  loading = false;
  private redirectInstructions: {
    redirectTo: string;
    id?: string;
    shared?: boolean;
    downloadOnLoad?: boolean;
  };
  private destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store<any>,
    private fbAuth: FirebaseAuthService
  ) {}

  ngOnInit() {
    this.store
      .select((state) => state.uiState)
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((a, b) => {
          return a.authUser === b.authUser;
        })
      )
      .subscribe((uiState$) => {
        if (uiState$.authUser) {
          this.router.navigate(["client-selection"]);
        }
      });
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
    this.checkForRedirectInstructions();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngAfterViewInit() {
    document.body.classList.add("authentication-bg");
  }

  get form() {
    return this.loginForm.controls;
  }
  private async checkForRedirectInstructions() {
    const redirectTo = localStorage.getItem("redirectTo");
    if (redirectTo) {
      this.redirectInstructions = {
        redirectTo: redirectTo,
        id: localStorage.getItem("reportId"),
        shared: localStorage.getItem("shared") == "true",
      };
    }
  }
  async login() {
    if (!this.submitted) {
      this.submitted = true;
      if (this.loginForm.invalid) {
        return;
      }
      // this.loadingService.isLoading(true);
      try {
        await this.fbAuth.login(
          this.form.email.value,
          this.form.password.value
        );
        // if (this.redirectInstructions) {
        //   await this.router.navigate(["client-selection"], {
        //     queryParams: {
        //       redirectTo: this.redirectInstructions.redirectTo,
        //       id: this.redirectInstructions.id,
        //       shared: !!this.redirectInstructions.shared,
        //       downloadOnLoad: this.redirectInstructions.downloadOnLoad,
        //     },
        //   });
        // } else {
        await this.router.navigate(["client-selection"]);
        // }
      } catch (e) {
        this.submitted = false;
        this.snackBar.open("Invalid email and/or password", "Dismiss", {
          duration: 5000,
        });
      }
    }
  }
  async loginWithProvider(provider: "microsoft" | "google") {
    await this.fbAuth.authWithProvider(provider);
    await this.router.navigate(["client-selection"]);
  }
}
