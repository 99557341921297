import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../../../material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FileUploadModule } from "../../file-upload/file-upload.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TransactionLookupComponent } from "./transaction-lookup.component";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { TransactionsTableModule } from "../../transactions-table/transactions-table.module";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { TypeSearchModule } from "../../type-search/type-search.module";

@NgModule({
  declarations: [TransactionLookupComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    FileUploadModule,
    TypeSearchModule,
    PerfectScrollbarModule,
    TransactionsTableModule,
    ClipboardModule,
  ],
})
export class TransactionLookupModule {}
