import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "lodash";
import { Papa } from "ngx-papaparse";
import {
  ThirdPartyReconciliationLocationData,
  ThirdPartyReportFeesSummary,
} from "@deliver-sense-librarian/data-schema";
import { ReconciliationReportExportUtility } from "../../utilities/reconciliation-report-export.utility";
import { ReconciliationReportDatUtility } from "../../utilities/reconciliation-report-data.utility";
import { ReconciliationReportFilteringUtility } from "../../utilities/reconciliation-report-filtering.utility";
@Component({
  selector: "app-fees-summary-report",
  templateUrl: "./fees-summary-report.component.html",
  styleUrls: ["./fees-summary-report.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FeesSummaryReportComponent implements OnInit, AfterViewInit {
  @Input() exportUtility: ReconciliationReportExportUtility;
  @Input() dataUtility: ReconciliationReportDatUtility;
  @Input() filteringUtility: ReconciliationReportFilteringUtility;
  public feesSummaries: ThirdPartyReportFeesSummary[] = [];
  public loadingData = false;
  constructor() {}

  ngOnInit() {
    this.initializeTable();
  }
  ngAfterViewInit() {}
  private async initializeTable() {
    this.compileThirdPartyFeesSummary();
  }

  private compileThirdPartyFeesSummary() {
    this.feesSummaries = this.dataUtility.reportData.map((locationReport) => {
      return this.runFeeSummaryCalcs(locationReport);
    });
  }

  private runFeeSummaryCalcs(
    reconciliation: ThirdPartyReconciliationLocationData
  ) {
    const feeSummary = new ThirdPartyReportFeesSummary();
    feeSummary.location = reconciliation.locationId;
    feeSummary.thirdParty = reconciliation.thirdParty;
    feeSummary["thirdPartyName"] = reconciliation.thirdPartyName;
    feeSummary.deliveryFees = reconciliation.deliveryFees;
    feeSummary.pickupFees = reconciliation.pickupFees;
    feeSummary.promoFees = reconciliation.promoFees;
    feeSummary.cateringFees = reconciliation.cateringFees;
    feeSummary.customFees = reconciliation.customFees;
    feeSummary.totalFees =
      (reconciliation.deliveryFees ? reconciliation.deliveryFees : 0) +
      (reconciliation.pickupFees ? reconciliation.pickupFees : 0) +
      (reconciliation.promoFees ? reconciliation.promoFees : 0) +
      (reconciliation.cateringFees ? reconciliation.cateringFees : 0) +
      (reconciliation.customFees ? reconciliation.customFees : 0);
    feeSummary.misc = reconciliation.thirdPartyMisc;
    feeSummary.otherCharges = reconciliation.thirdPartyOtherCharges;
    return feeSummary;
  }
}
