import {
  ThirdParty,
  Location,
  PriorPeriodAdjustmentTransaction,
  ThirdPartyTransaction,
} from "@deliver-sense-librarian/data-schema";
import { Workbook, Worksheet } from "exceljs";
import {
  ReconciliationDrillDownReportTypes,
  ReconciliationReportDatUtility,
} from "../reconciliation-report-data.utility";
import moment from "moment";

const headerRow = [
  "Location ID",
  "Location Name",
  "ThirdParty",
  "Net Sales",
  "Refunds",
  "Promotions",
  "Payout Total",
  "Fullfilment %",
  "Partner Payout",
  "Unremitted Sales Tax",
  "Prior Period Refunds",
  "Prior Period Adjustments",
];
export async function compileVirturantCustomReports(
  recSummaryWorkbook: Workbook,
  dataUtility: ReconciliationReportDatUtility
) {
  const ppaTransactions = await dataUtility.fetchPriorPeriodAdjustments(
    dataUtility.locations
  );
  const analyticsSummaries =
    await dataUtility.compileAnalyticsBreakoutSummaries();
  const revenueRecoveryAdjustments: ThirdPartyTransaction[] =
    await fetchRevenueRecoveredAdjustments(dataUtility);

  const payoutSummary = recSummaryWorkbook.addWorksheet("Payout Voucher");
  compilePayoutVoucher(
    payoutSummary,
    dataUtility,
    ppaTransactions,
    analyticsSummaries,
    revenueRecoveryAdjustments
  );
}
async function compilePayoutVoucher(
  payoutSheet: Worksheet,
  dataUtility: ReconciliationReportDatUtility,
  ppaTransactions: PriorPeriodAdjustmentTransaction[],
  analyticsSummaries,
  revenueRecoveryAdjustments: ThirdPartyTransaction[]
) {
  payoutSheet.addRow(headerRow);

  analyticsSummaries
    .sort((a, b) => (+a.location > +b.location ? 1 : -1))
    .forEach((summary) => {
      const reconciliationData = dataUtility.reportData.find(
        (reconciliation) =>
          reconciliation.locationId === summary.location &&
          reconciliation.thirdParty === summary.thirdParty
      );
      const recoveryAdjustments = revenueRecoveryAdjustments
        .filter((adjustment) => {
          return (
            adjustment.location === summary.location &&
            adjustment.thirdParty === summary.thirdParty
          );
        })
        .reduce((sum, adjustment) => {
          return (sum += adjustment["saleAdjustment"]
            ? adjustment["saleAdjustment"]
            : 0);
        }, 0);
      const ppaRefunds = getPPaTransactionValues(
        dataUtility.locations.find(
          (location) => location.locationId === reconciliationData.locationId
        ),
        dataUtility.thirdParties.find(
          (dsp) => dsp.id === reconciliationData.thirdParty
        ),
        ppaTransactions,
        true
      );
      const adjustedNet =
        summary.netSales - recoveryAdjustments - summary.errorCharges;
      const total = adjustedNet + summary.errorCharges + summary.promoFees;
      payoutSheet.addRow([
        summary.location,
        dataUtility.locations.find(
          (location) => location.locationId === summary.location
        )?.name,
        summary["thirdPartyName"],
        adjustedNet,
        summary.errorCharges,
        summary.promoFees,
        total,
        0.55,
        total * 0.55,
        reconciliationData.clientTaxResponsibility,
        ppaRefunds,
      ]);
    });
  formatAllCellsInColumn(payoutSheet, "H", "percent");
}
async function fetchRevenueRecoveredAdjustments(
  dataUtility: ReconciliationReportDatUtility
) {
  try {
    let adjustmentTransactions =
      await dataUtility.fetchReconciliationReportDrillDownFragments(
        ReconciliationDrillDownReportTypes.adjustmentLogs
      );
    adjustmentTransactions = adjustmentTransactions
      .filter((transaction) => {
        return (
          transaction.saleAdjustment >= 0 &&
          ((transaction.status === "Miscellaneous Payment" &&
            transaction.description === "Restaurant refunds") || //DD
            transaction.transactionType === "Account Adjustment" || //Gh
            transaction.transactionType === "ADJUSTMENT") //DD
        );
      })
      .sort((a, b) => {
        return moment(a.payoutDate, "M/DD/YYYY").isBefore(
          moment(b.payoutDate, "M/DD/YYYY")
        )
          ? -1
          : 1;
      });

    return adjustmentTransactions;
  } catch (e) {
    console.error(e);
  }
}
function formatAllCellsInColumn(
  sheet: Worksheet,
  column: string,
  type: "percent" | "accounting"
) {
  let formatCode;
  switch (type) {
    case "accounting":
      formatCode = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
      break;
    case "percent":
      formatCode = "0.00%";
      break;
  }
  sheet.columns.forEach((c, i) => {
    const columnAlpha = numberToLetters(i);
    if (columnAlpha === column) {
      c["eachCell"]({ includeEmpty: true }, (cell) => {
        cell.numFmt = formatCode;
      });
    }
  });
}
function numberToLetters(num) {
  let letters = "";
  while (num >= 0) {
    letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[num % 26] + letters;
    num = Math.floor(num / 26) - 1;
  }
  return letters;
}
function getPPaTransactionValues(
  location: Location,
  thirdParty: ThirdParty,
  ppaTransactions: PriorPeriodAdjustmentTransaction[],
  refund?: boolean
) {
  return ppaTransactions
    .filter((ppat) => {
      return (
        ppat.location === location.locationId &&
        ppat.account === thirdParty.id &&
        (refund ? ppat.isErrorCharge : ppat.isAdjustment)
      );
    })
    .reduce((sum: number, ppat) => {
      // determine the amount of the cutomer refund (DoorDash in transaction.saleCorrection, all other DSPS in transaction.sale)
      const amount =
        ppat.transaction.sale !== 0
          ? ppat.transaction.sale
          : ppat.transaction.saleCorrection !== 0
          ? ppat.transaction.saleCorrection
          : 0;
      return (sum += amount);
    }, 0);
}
