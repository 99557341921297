import {
  Client,
  UserRoles,
  Location,
  ClientRole,
  Entity,
} from "@deliver-sense-librarian/data-schema";

export interface UiState {
  authUser: any;
  client: Client;
  clientRole: UserRoles;
  authorizedClients: ClientRole[];
  locations: any[];
  entities: any[];
  departments: any[];
  projects: any[];
  clientPosSystems: any[];
  clientThirdParties: any[];
  clientLocations: Location[];
  clientEntities: Entity[];
  sfaToken: string;
  showDataUpload: boolean;
  unfinishedProcesses: number;
}

export const INITIAL_UI_STATE: UiState = {
  authUser: JSON.parse(localStorage.getItem("user")),
  client: JSON.parse(localStorage.getItem("client")),
  clientRole: undefined,
  authorizedClients: [],
  locations: null,
  entities: [],
  departments: [],
  projects: [],
  clientPosSystems: [],
  clientThirdParties: null,
  clientLocations: [],
  clientEntities: [],
  sfaToken: localStorage.getItem("ssoToken"),
  showDataUpload: false,
  unfinishedProcesses: 0,
};
